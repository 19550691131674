import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { getDemoNetworkMap, getNetworkMap1 } from "../../actions/network_map";
import {
  Form,
  Select,
  DatePicker,
  Space,
  Button,
  Drawer,
  Switch,
  Collapse,
  Radio,
  Row,
  Col,
  Divider,
} from "antd";
import { useIntl } from "react-intl";
import { DemographicsContext } from "../../context/demographics-context";
import moment from "moment";
import { sortDemographics } from "../../utils/SortDemographics";
var centrality = require("ngraph.centrality");
var g = require("ngraph.graph")();

const { Option } = Select;
const { Panel } = Collapse;

function DemographicSwitchBlock({
  category,
  taxonomy,
  values,
  onChange,
  onResetFilters,
  missing,
  isGroupSelected,
  isAggregate
}) {
  const [toggledDemographics, setToggledDemographics] = useState({});
  const [allSelected, setAllSelected] = useState(true);

  const [deafultDemographics, setDeafultDemographics] = useState({});

  const intl = useIntl();
  const filterByNonePlaceholder = intl.formatMessage({
    id: "app.explorePage.filterByNone",
    defaultMessage: "None",
  });
  const filterByAllPlaceholder = intl.formatMessage({
    id: "app.explorePage.filterByAll",
    defaultMessage: "All",
  });
  useEffect(() => {
    let _toggledDemographics = { ...toggledDemographics };
    values.forEach((value) => {
      const key = [category, taxonomy, value].join("/");
      if (_toggledDemographics[key] === undefined) {
        _toggledDemographics[key] = true;
      }
    });
    setToggledDemographics(_toggledDemographics);
    setDeafultDemographics(_toggledDemographics);
  }, [values]);

  useEffect(() => {
    if (onChange) {
      onChange(toggledDemographics);
    }
  }, [toggledDemographics]);

  const toggleDemographic = (category, taxonomy, value) => {
    let newFilter = {};
    const key = [category, taxonomy, value].join("/");
    newFilter[key] = !toggledDemographics[key] ? true : false;
    setToggledDemographics({
      ...toggledDemographics,
      ...newFilter,
    });
  };

  const toggleSelected = () => {
    setAllSelected(!allSelected);

    let _toggledDemographics = { ...toggledDemographics };
    Object.keys(_toggledDemographics).forEach((key) => {
      _toggledDemographics[key] = !allSelected;
    });

    setToggledDemographics(_toggledDemographics);

    return true;
  };

  return (
    <section style={{ marginLeft: "-10px", marginTop: "-15px" }}>
      <Collapse ghost>
        <Panel
          extra={
            <Button
              type="link"
              size="small"
              disabled={!(isGroupSelected && isAggregate)}
              onClick={(e) => toggleSelected() && e.stopPropagation()}
            >
              {allSelected ? filterByNonePlaceholder : filterByAllPlaceholder}
            </Button>
          }
          header={
            <>
              {" "}
              {category}: {taxonomy}
            </>
          }
          key={category + "_" + taxonomy}
        >
          <section style={{ marginTop: "-15px" }}>
            {values.map((value, index) => (
              <div
                style={{
                  marginLeft: 10,
                  marginBottom: 5,
                  color:
                    missing.indexOf([category, taxonomy, value].join("/")) > -1
                      ? "#e5e3e3"
                      : "inherit",
                }}
                key={index}
              >
                <Space>
                  <Switch
                    disabled = {!(isGroupSelected && isAggregate)}
                    checked={
                      toggledDemographics[[category, taxonomy, value].join("/")]
                    }
                    onChange={() =>
                      toggleDemographic(category, taxonomy, value)
                    }
                  />
                  <small>{value}</small>
                </Space>
              </div>
            ))}
          </section>
        </Panel>
      </Collapse>
    </section>
  );
}

function DemographicBlocks({
  data,
  onChange,
  onNoneChanged,
  filteredDemographicKeys,
  onResetFilters,
  demographicBlocks,
  setDemographicBlocks,
  groupBy,
  isAggregate
}) {
  const [demographicIncludeNone, setDemographicIncludeNone] = useState(true);
  const [foundDemographicKeys, setFoundDemographicKeys] = useState({});
  const [missingDemographicKeys, setMissingDemographicKeys] = useState({});
  const [toggledDemographics, setToggledDemographics] = useState({});
  const { taxonomyMap } = useContext(DemographicsContext);
  const [isGroupSelected,setIsGroupSelected]= useState(false);
  let gCategory="";
  let gTaxonomy="";
  debugger;
  if(groupBy && groupBy !== "")
  {
    g = groupBy.split("/")
    gCategory = g[0]
    gTaxonomy = g[1]
  }


  useEffect(() => {
    let _foundDemographicKeys = {};
    data.nodes.forEach((node) => {
      if (node && node.demographics) {
        node.demographics.forEach((demographic) => {
          const key = [
            demographic.category,
            demographic.taxonomy,
            demographic.value,
          ].join("/");
          _foundDemographicKeys[key] = true;
        });
      }
    });
    setFoundDemographicKeys(_foundDemographicKeys);
    console.log(foundDemographicKeys);
  }, [data]);

  useEffect(() => {
    console.log(Object.keys(foundDemographicKeys));
    const missingKeys = _.difference(
      Object.keys(foundDemographicKeys),
      Object.keys(filteredDemographicKeys)
    );
    console.log("missing", missingKeys);

    setMissingDemographicKeys(missingKeys);
  }, [demographicBlocks, filteredDemographicKeys]);

  useEffect(() => {
    let blocks = [];
    _.uniq(Object.values(taxonomyMap).map((node) => node[0].category)).map(
      (category, index) =>
        _.uniq(
          _.flatten(Object.values(taxonomyMap))
            .filter((node) => node.category == category)
            .map((node) => node.taxonomy)
        ).map((taxonomy, index) => {
          let valuesArray = _.uniq(
            taxonomyMap[taxonomy]
              .filter((node) => node.taxonomy == taxonomy)
              .map((node) => node.value)
          )
            .map((value, index) => {
              const key = [category, taxonomy, value].join("/");
              return !foundDemographicKeys[key] ? null : value;
            })
            .filter((el) => el != null);
          let values = [];
          if (valuesArray.length != 0) {
            values = [...["Include Missing"], ...sortDemographics(valuesArray)];
          } else {
            values = valuesArray;
          }
          blocks.push({ category, taxonomy, values });
        })
    );
    setDemographicBlocks(blocks);
  }, [foundDemographicKeys]);

  useEffect(() => {
    if (onChange) {
      onChange(toggledDemographics);
    }
  }, [toggledDemographics]);

  useEffect(() => {
    if (onNoneChanged) {
      onNoneChanged(demographicIncludeNone);
    }
  }, [demographicIncludeNone]);

  return (
    <>
      {
        <>

          <>
            {demographicBlocks.map(
              ({ category, taxonomy, values }, index) =>
                values.length > 0 && (
                  <DemographicSwitchBlock
                    key={index}
                    missing={missingDemographicKeys}
                    values={values}
                    category={category}
                    taxonomy={taxonomy}
                    onChange={(blockDemographics) =>
                      setToggledDemographics({
                        ...toggledDemographics,
                        ...blockDemographics,
                      })
                    }
                    isGroupSelected={ gCategory ===category && gTaxonomy === taxonomy? true:false}
                    isAggregate={isAggregate}
                  />
                )
            )}
          </>
        </>
      }
    </>
  );
}

export default function Filters({ open, onClose, onChange, setIsGrouped }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const intl = useIntl();
  const startDatePlaceholder = intl.formatMessage({
    id: "app.strategyMap.config.startDate",
    defaultMessage: "Start Date",
  });
  const endDatePlaceholder = intl.formatMessage({
    id: "app.strategyMap.config.endDate",
    defaultMessage: "End Date",
  });
  const [isReset, setIsReset] = useState(false);
  const [demographicFilters, setDemographicFilters] = useState({});
  const [graph, setGraph] = useState({});
  const [demographicIncludeNone, setDemographicIncludeNone] = useState(true);
  const [filteredDemographicKeys, setFilteredDemographicKeys] = useState([]);
  const [demographicBlocks, setDemographicBlocks] = useState([]);
  const [groupbyBlocks, setGroupbyBlocks] = useState([]);
  const [isChecked, setIsChecked] = useState(false); // state variable to keep track of switch state
  const [initialGroupValue, setInitialGroupValue] = useState("");
  const [centralityGroup, setCentralityGroup] = useState("centrality_degree");
  const defaultValues = {
    startDate: "1997-06-01",
    endDate: moment().format("YYYY-MM-DD"),
    autoEndDate: "true",
    groupBy: "",
    aggregate: false,
  };
  const [tempForm, setTempForm] = useState({});
  const { taxonomyMap } = useContext(DemographicsContext);
  useEffect(() => {
    (async () => {
      let result = await loadGraphData(
        dispatch,
        setGraph,
        defaultValues.startDate,
        defaultValues.endDate,
        tempForm.groupBy,
        isChecked
      );

      setTempForm({
        ...defaultValues,
      });
    })();
  }, []);

  useEffect(() => {
    if (demographicBlocks.length > 0) {
      var selectOptions = [];
      selectOptions.push({
        category: '', 
        taxonomy: ''
      });
      selectOptions =selectOptions.concat(demographicBlocks);
      setGroupbyBlocks(selectOptions);
      let data = [
        selectOptions[0].category,
        selectOptions[0].taxonomy,
      ];
      setInitialGroupValue(data);
      if (tempForm.groupBy === "") {
        setTempForm({
          ...tempForm,
          groupBy: "",
        });
      }
    }
  }, [demographicBlocks]);

  useEffect(() => {
    if (graph && Object.keys(graph).length > 0) applyFilter();
  }, [graph]);

  const loadandApplyFilter = async () => {

    if (tempForm.groupBy !== undefined) {

      let startDate = moment(tempForm.startDate).format("YYYY-MM-DD");
      let endDate = tempForm.endDate
        ? moment(tempForm.endDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      await loadGraphData(
        dispatch,
        setGraph,
        startDate,
        endDate,
        tempForm.groupBy,
        isChecked
      );
    }
  };
  const applyFilter = async () => {
    form.validateFields().then(onFinish).catch(onFinishFailed);

    if (!isReset) {
      console.log("applyFilter->called");
      let missingKeys = [];
      missingKeys = Object.keys(demographicFilters).filter((rule) => {
        let retain = false;
        const [category, taxonomy, value] = rule.split("/");
        if (value === "Include Missing" && !demographicFilters[rule])
          retain = true;
        return retain;
      });
      let _processedData;
      let filteredLinks = graph.links;

      if (missingKeys.length > 0) {
        _processedData = graph.nodes.filter((node) => {
          return missingKeys.every((rule) => {
            let keep = false;
            const [category, taxonomy, value] = rule.split("/");
            if (node.demographics) {
              node.demographics.forEach((demographic) => {
                if (
                  demographic.category === category &&
                  demographic.taxonomy === taxonomy
                ) {
                  keep = true;
                  console.log(
                    "Removing from list due to demographic filter rule:",
                    rule,
                    node
                  );
                 
                }
              });
            }
            return keep;
          });
        });
      } else {
        _processedData = graph.nodes;
      }
      //Applying rest of the filters on top of include missing data for demographics
      let _filteredData = _processedData.filter((node) => {
        let keep = true;

        Object.keys(demographicFilters).forEach((rule) => {
          if (keep && node && node.demographics && !demographicFilters[rule]) {
            const [category, taxonomy, ...valueArr] = rule.split("/");
            //In cisco there is one case of Opportunity/Competitors/HPE/Aruba, so added it as an array to avoid any logical conflicts with such cases.
            const value = valueArr.join("/");
            //condition is triggered if any value switch is disabled
            if (value !== "Include Missing") {
              node.demographics.forEach((demographic) => {
                if (
                  demographic.category == category &&
                  demographic.taxonomy == taxonomy &&
                  demographic.value == value
                ) {
                  keep = false;
                  console.log(
                    "Removing from list due to demographic filter rule:",
                    rule,
                    node
                  );
                  filteredLinks = filteredLinks.filter(obj => obj.source !== value && obj.target !== value);
                }
              });
            }
          }
        });
        if (
          keep &&
          node &&
          node.demographics &&
          node.demographics.length == 0 &&
          !demographicIncludeNone
        ) {
          keep = false;
        }
        return keep;
      });

      let _filteredDataFoundDemographicKeys = {};
      _filteredData.forEach((node) => {
        if (node && node.demographics) {
          node.demographics.forEach((demographic) => {
            const key = [
              demographic.category,
              demographic.taxonomy,
              demographic.value,
            ].join("/");
            _filteredDataFoundDemographicKeys[key] = true;
          });
        }
      });
      setFilteredDemographicKeys(_filteredDataFoundDemographicKeys);

      //If user has selected the group, allocate group value to the node.group.
      if (tempForm.groupBy && tempForm.groupBy !== "") {
        setIsGrouped(true); // set isgroup to true which will calc the centroid of each group in graph.js
        _filteredData.forEach((node) => {
          if (node && node.demographics) {
            node.demographics.forEach((demographic) => {
              const key = [demographic.category, demographic.taxonomy].join(
                "/"
              );
              if (key === tempForm.groupBy) {
                node.group = demographic.value;
              }
            });
          }
        });
        if (isChecked) {
          let result = {};
          _filteredData.forEach((element) => {
            if (!result[element.group]) {
              result[element.group] = {
                id: element.group,
                uid: element.id,
                centralityValue: element[centralityGroup],
                color: element.color,
                name: element.name,
                group: element.group,
              };
            } else {
              result[element.group]["centralityValue"] +=
                element[centralityGroup];
            }
          });

          _filteredData = Object.values(result);
        }
      }

      let temp = {
        nodes: _filteredData,
        links: filteredLinks,
      };
      if (!isChecked) {
        onChange(temp, centralityGroup);
      } else {
        onChange(temp, "centralityValue");
      }
    }
  };

  const onFormChanged = (changedValues) => {
    setTempForm((current) => {
      return {
        ...defaultValues,
        ...current,
        ...changedValues,
      };
    });
  };

  const updateRecord = (values) => {
    const validKeys = Object.keys(values).filter((key) => {
      return values[key] !== undefined;
    });
    const validValues = validKeys.map((key) => {
      return values[key];
    });
    let validatedObject = _.zipObject(validKeys, validValues);
    if (typeof validatedObject.startDate !== "string") {
      validatedObject.startDate =
        validatedObject.startDate.format("YYYY-MM-DD");
    }
    if (
      typeof validatedObject.endDate !== "string" &&
      values.autoEndDate !== "true"
    ) {
      validatedObject.endDate = validatedObject.endDate.format("YYYY-MM-DD");
    }
    setTempForm((current) => {
      current = {
        ...defaultValues,
        ...current,
        ...validatedObject,
      };
      return current;
    });
  };
  const onFinishFailed = () => {};
  const onFinish = (values) => {
    updateRecord(values);
  };
  const handleGroupChange = (value) => {
    if(value ===""){
      setIsChecked(false);
    } // update selected option when user selects a new one
  };
  const onCentralityChange = (e) => {
    console.log("centralityGroup->", centralityGroup);
    setCentralityGroup(e.target.value);
  };

  return (
    <>
      <Drawer title="Filters" placement="right" onClose={onClose} open={open}>
        <Form
          form={form}
          initialValues={{
            layout: "horizontal",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChanged}
        >
          <Form.Item
            label={startDatePlaceholder}
            name="startDate"
            initialValue={moment(tempForm.startDate, "YYYY-MM-DD")}
          >
            <DatePicker
              format={"YYYY-MM-DD"}
              popupStyle={{
                zIndex: 3000,
              }}
            />
          </Form.Item>
          <Form.Item label={endDatePlaceholder}>
            <Space>
              <Form.Item
                name="autoEndDate"
                noStyle
                initialValue={tempForm.autoEndDate}
                rules={[
                  {
                    required: true,
                    message: "Please choose an option",
                  },
                ]}
              >
                <Select
                  style={{ width: 145 }}
                  dropdownStyle={{
                    zIndex: 3000,
                  }}
                > 
                <Option value="false">Manual Date</Option>
                <Option value="true">Automatic Today</Option>
                 
                </Select>
              </Form.Item>
              <Form.Item
                label={endDatePlaceholder}
                name= "endDate"
                noStyle
                initialValue={moment(tempForm.endDate, "YYYY-MM-DD")}
                rules={[
                  {
                    required: true,
                    message: "Please choose an option",
                  },
                ]}
              >
                {tempForm.autoEndDate === "false" && (
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    popupStyle={{
                      zIndex: 3000,
                    }}
                  />
                )}
              </Form.Item>
            </Space>
          </Form.Item>
          <Space>
            {initialGroupValue ? (
              <Form.Item label="Group By" name="groupBy">
                <Select
                  dropdownStyle={{
                    zIndex: 3000,
                  }}
                  style={{ width: 200 }}
                  onChange={handleGroupChange}
                  initialValues={initialGroupValue}
                >
                  {groupbyBlocks.map((demographic) => (
                    <Option
                      key={demographic.category ===""?"":[demographic.category, demographic.taxonomy].join(
                        "/"
                      )}
                      value={demographic.category ===""?"":[demographic.category, demographic.taxonomy].join(
                        "/"
                      )}
                    >
                      {demographic.category ===""?"":[demographic.category, demographic.taxonomy].join("/")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null}
          </Space>
          <Space>
            {tempForm.groupBy &&
            <Form.Item label="Aggregate group" name="aggregate">
              <Switch
                checked={isChecked}
                checkedChildren="true"
                unCheckedChildren="false"
                onChange={checked => setIsChecked(checked)}
              />
            </Form.Item>
            }
          </Space>
        </Form>
        { 
        <DemographicBlocks
          data={graph}
          onResetFilters={isReset}
          onChange={(newDemographicFilters) =>
            setDemographicFilters(newDemographicFilters)
          }
          onNoneChanged={(value) => setDemographicIncludeNone(value)}
          filteredDemographicKeys={filteredDemographicKeys}
          demographicBlocks={demographicBlocks}
          setDemographicBlocks={setDemographicBlocks}
          groupBy={tempForm.groupBy}
          isAggregate={isChecked}
        /> 
        }
        <Divider orientation="left">Centrality measures</Divider>
        <Radio.Group
          onChange={onCentralityChange}
          defaultValue={centralityGroup}
        >
          <Row>
            <Col style={{ margin: "5px 5px 5px" }}>
              <Radio.Button
                style={{ width: 150, textAlign: "center" }}
                value="centrality_articlerank"
              >
                Article Rank
              </Radio.Button>
            </Col>
            <Col style={{ margin: "5px 5px 5px" }}>
              <Radio.Button
                style={{ width: 150, textAlign: "center" }}
                value="centrality_betweenness"
              >
                Betweenness
              </Radio.Button>
            </Col>
          </Row>
          <Row>
            <Col style={{ margin: "5px 5px 5px" }}>
              <Radio.Button
                style={{ width: 150, textAlign: "center" }}
                value="centrality_closeness"
              >
                Closeness
              </Radio.Button>
            </Col>
            <Col style={{ margin: "5px 5px 5px" }}>
              <Radio.Button
                style={{ width: 150, textAlign: "center" }}
                value="centrality_eigenvector"
              >
                Eigen Vector
              </Radio.Button>
            </Col>
          </Row>
          <Row>
            <Col style={{ margin: "5px 5px 5px" }}>
              <Radio.Button
                style={{ width: 150, textAlign: "center" }}
                value="centrality_degree"
              >
                Degree
              </Radio.Button>
            </Col>
            <Col style={{ margin: "5px 5px 5px" }}>
              <Radio.Button
                style={{ width: 150, textAlign: "center" }}
                value="centrality_pagerank"
              >
                Page Rank
              </Radio.Button>
            </Col>
          </Row>
        </Radio.Group>
        <div style={{ textAlign: "right", marginTop: "5px" }}>
          <Button onClick={(e) => loadandApplyFilter()}>Apply Filters</Button>
        </div>
      </Drawer>
    </>
  );
}

async function loadGraphData(
  dispatch,
  setGraph,
  startDate,
  endDate,
  groupBy,
  isChecked
) {
  let result = await dispatch(getNetworkMap1(startDate, endDate));
  console.log("result", result);
  debugger;
  let linksbyGroupAgg = [];
  let linksArray=[];
  if (groupBy && groupBy !== "" && isChecked) {
    const groupByFilter = groupBy.split("/");

    result.payload.users.forEach((user) => {
      let sourceDemographics = new Set();

      user.demographics.forEach((demographics) => {
        if (
          demographics.category === groupByFilter[0] &&
          demographics.taxonomy === groupByFilter[1]
        )
          sourceDemographics.add(demographics);
      });

      let targetDemographics = new Set();
      user.incoming_communicationsConnection.edges.forEach((edge) => {
        edge.node.demographics.forEach((demographics) => {
          if (
            demographics.category === groupByFilter[0] &&
            demographics.taxonomy === groupByFilter[1]
          )
            targetDemographics.add(demographics);
        });
      });
      user.user_communicationsConnection.edges.forEach((edge) => {
        edge.node.demographics.forEach((demographics) => {
          if (
            demographics.category === groupByFilter[0] &&
            demographics.taxonomy === groupByFilter[1]
          )
            targetDemographics.add(demographics);
        });
      });

      sourceDemographics.forEach((sourceDemographics) => {
        let matchingTargetDemographics = Array.from(targetDemographics).filter(
          (targetDemographics) => {
            return (
              targetDemographics.category === sourceDemographics.category &&
              targetDemographics.taxonomy === sourceDemographics.taxonomy
            );
          }
        );

        if (matchingTargetDemographics.length > 0) {
          matchingTargetDemographics.forEach((matchingTargetDemographics) => {
            const source = sourceDemographics.value;
            const target = matchingTargetDemographics.value;
            if (
              source !== target &&
              !linksbyGroupAgg.some(
                (edge) => edge.source === source && edge.target === target
              ) &&
              !linksbyGroupAgg.some(
                (edge) => edge.source === target && edge.target === source
              )
            ) {
              linksbyGroupAgg.push({
                source,
                target,
              });
            }
          });
        }
      });
    });
  } 
  
    let outgoingLinks = result.payload.users
      .map((val) =>
        val.user_communicationsConnection.edges.map((e) => ({
          source: val.uid,
          target: e.node.uid,
        }))
      )
      .flat();
    let incomingLinks = result.payload.users
      .map((val) =>
        val.incoming_communicationsConnection.edges.map((e) => ({
          source: val.uid,
          target: e.node.uid,
        }))
      )
      .flat();
    let linksArrayTemp = incomingLinks.concat(outgoingLinks);

    linksArrayTemp.forEach(function (item) {
      if (
        !linksArray.some(
          (i) =>
            (i.source === item.source && i.target === item.target) ||
            (i.source === item.target && i.target === item.source)
        )
      ) {
        linksArray.push(item);
      }
    });
  let nodesArray = result.payload.users.map((val) => ({
    id: val.uid,
    client_id: val.client_id,
    connectionNode: val.user_communicationsConnection.edges,
    color: "#ec9b41",
    name: "Name",
    icon: "\u2927",
    demographics: val.demographics,
    group: "",
    centrality_degree: val.centrality_degree,
    centrality_pagerank: val.centrality_pagerank,
    centrality_articlerank: val.centrality_articlerank,
    centrality_betweenness: val.centrality_betweenness,
    centrality_eigenvector: val.centrality_eigenvector,
    centrality_closeness: val.centrality_closeness,
  }));

    let missingNodesId = linksArray
    .filter((val) => !nodesArray.some((node) => node.id === val.target))
    .map((x) => x.target);

  const missingNodes = result.payload.users
    .map((user) => user.user_communicationsConnection.edges)
    .flat()
    .filter((edge) => missingNodesId.includes(edge.node.uid))
    .map((data) => ({
      id: data.node.uid,
      client_id: data.node.client_id,
      connectionNode: "node",
      color: "#ec9b41",
      name: "Name",
      icon: "\u2927",
      demographics: data.node.demographics,
      group: "",
      centrality_degree: data.node.centrality_degree,
      centrality_pagerank: data.node.centrality_pagerank,
      centrality_articlerank: data.node.centrality_articlerank,
      centrality_betweenness: data.node.centrality_betweenness,
      centrality_eigenvector: data.node.centrality_eigenvector,
      centrality_closeness: data.node.centrality_closeness,
      firstCommunicationDate: data.first_communication_date,
      lastCommunicationDate: data.last_communication_date,
    }));

    nodesArray = nodesArray.concat(missingNodes);
 
 
  const uniqueNodes = Array.from(new Set(nodesArray.map((x) => x.id))).map((id) => {
    return nodesArray.find((x) => x.id === id);
  });

  let graphData = {
    nodes: uniqueNodes,
    links: (!isChecked)? linksArray:linksbyGroupAgg,
  };

  setGraph(graphData);
  return graphData;
}
