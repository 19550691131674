import React, { useState, useEffect } from 'react';
import { DragDropContext } from "react-beautiful-dnd";

import generateId from './private/generateId';
import TiledSet from './private/TiledSet';
import addNewDataFrame from './private/addNewDataFrame';
import addNewChart from './private/addNewChart';
import addNewRow from './private/addNewRow';
import draggableElWrapper_DataFrame from './private/draggableElWrapper_DataFrame';
import draggableElWrapper_Row from './private/draggableElWrapper_Row';
import draggableElWrapper_Chart from './private/draggableElWrapper_Chart';
import DashboardChartEditorBlock from './DashboardChartEditorBlock';
import DashboardDataSourceEditorBlock from './DashboardDataSourceEditorBlock';


function TiledDashboardEditor({ dataSources, onChange, forumDataSources }) {

  const [ dataSourcesCopy, setDataSourcesCopy ] = useState([]);
  const [ controllerId, setControllerId ] = useState(null);

  useEffect(() => {
    setControllerId(generateId());
  }, []);

  useEffect(() => {
    setDataSourcesCopy([ ... dataSources ]);
  }, [ dataSources ]);

  const setData = (values) => {
    values = [ ... values ];
    setDataSourcesCopy(values);
    if (onChange) {
      onChange(values);
    }
  };

  /**
   * Stop the plugin from spamming the console during dev
   */
  window['__react-beautiful-dnd-disable-dev-warnings'] = true;

  /**
   * Triggered when dragging an element has stopped. Determine if the action
   * is the correct action (a drop action) and then try to determine where
   * the new element moved from and to and update the data to match that action.
   */
  const onDragEnd = (context) => {
    if (context.reason == "DROP" && context.source && context.destination) {
      if (context.type == 'sources') {
        // dragged and dropped a data source container block
        let source = dataSourcesCopy[context.source.index];
        let destination = dataSourcesCopy[context.destination.index];
        dataSourcesCopy[context.destination.index] = source;
        dataSourcesCopy[context.source.index] = destination;
      }
      if (context.type == 'rows') {
        // dragged and dropped a data source row block
        let dataSource_sourceIndex = parseInt(context.source.droppableId.replace('droppable-', '').split('/')[1]);
        let dataSource_destinationIndex = parseInt(context.destination.droppableId.replace('droppable-', '').split('/')[1]);

        let [ source ] = dataSourcesCopy[dataSource_sourceIndex].rows.splice(context.source.index, 1);
        dataSourcesCopy[dataSource_destinationIndex].rows.splice(context.destination.index, 0, source);
      }
      if (context.type == 'columns') {
        // dragged and dropped a chart block
        let dataSource_sourceId = context.source.droppableId.replace('droppable-', '').split('/');
        let dataSource_destinationId = context.destination.droppableId.replace('droppable-', '').split('/');

        let dataSource_sourceIndex = parseInt(dataSource_sourceId[1]);
        let dataSource_row_sourceIndex = parseInt(dataSource_sourceId[2]);
        let dataSource_destinationIndex = parseInt(dataSource_destinationId[1]);
        let dataSource_row_destinationIndex = parseInt(dataSource_destinationId[2]);

        let source = dataSourcesCopy[dataSource_sourceIndex].rows[dataSource_row_sourceIndex].columns[context.source.index];
        dataSourcesCopy[dataSource_sourceIndex].rows[dataSource_row_sourceIndex].columns[context.source.index] = null;
        dataSourcesCopy[dataSource_destinationIndex].rows[dataSource_row_destinationIndex].columns.splice(context.destination.index, 0, source);

        let newSourceIndex = dataSourcesCopy[dataSource_sourceIndex].rows[dataSource_row_sourceIndex].columns.indexOf(null);
        dataSourcesCopy[dataSource_sourceIndex].rows[dataSource_row_sourceIndex].columns.splice(newSourceIndex, 1);
      }
      setData([ ... dataSourcesCopy ]);
    }
  };

  const onDragUpdate = () => {

  };

  /**
   * Triggers when the data frames have changed position
   *
   * @param values the new values
   */
  const onDsChanged = (values) => {
    setData([ ...  values ]);
  };

  /**
   * Triggers when the rows have changed position
   *
   * @param values the new values
   */
  const onRowChanged = (dsIndex) => (values) => {
    dataSources[dsIndex].rows = values;
    setData([ ...  dataSourcesCopy ]);
  };

  /**
   * Triggers when the charts have changed position
   *
   * @param values the new values
   */
  const onColChanged = (dsIndex, rowIndex) => (values) => {
    dataSources[dsIndex].rows[rowIndex].columns = values;
    setData([ ...  dataSourcesCopy ]);
  };

  return (
    <>
      { controllerId &&
        <DragDropContext
          onDragEnd={ onDragEnd }
          onDragUpdate={ onDragUpdate }
        >
          <TiledSet
            uniqId={ controllerId }
            dataSources={ dataSourcesCopy }
            typeId="sources"
            renderDraggerOuterItem={ draggableElWrapper_DataFrame() }
            renderPostArrayItem={ addNewDataFrame(setData, dataSourcesCopy) }
            onChange={ onDsChanged }
          >
            {
              (dataSourceItemProps) => (
                <DashboardDataSourceEditorBlock forumDataSources={ forumDataSources } { ... dataSourceItemProps }>
                  {
                    (dataSourceRecord, index) => (
                      <TiledSet
                        uniqId={ dataSourceRecord.id + '/' + index }
                        dataSources={ dataSourceRecord.rows }
                        typeId="rows"
                        renderDraggerOuterItem={ draggableElWrapper_Row() }
                        renderPostArrayItem={ addNewRow(setData, dataSourcesCopy, dataSourceItemProps.index) }
                        onChange={ onRowChanged(dataSourceItemProps.index) }
                      >
                        {
                          (dataSourceRowProps) => (
                            <TiledSet
                              uniqId={ dataSourceItemProps.id + '' + dataSourceRowProps.item.id + '/' + dataSourceItemProps.index + '/' + dataSourceRowProps.index }
                              dataSources={ dataSourceRowProps.item.columns }
                              direction="horizontal"
                              typeId="columns"
                              renderDraggerOuterItem={ draggableElWrapper_Chart() }
                              renderPostArrayItem={ addNewChart(setData, dataSourcesCopy, dataSourceItemProps.index, dataSourceRowProps.index) }
                              maxItems={ 3 }
                              onChange={ onColChanged(dataSourceItemProps.index, dataSourceRowProps.index) }
                            >
                              {
                                (rowRecordProps) => (
                                  <DashboardChartEditorBlock forumDataSources={ forumDataSources } { ... rowRecordProps } />
                                )
                              }
                            </TiledSet>
                          )
                        }
                      </TiledSet>
                    )
                  }
                </DashboardDataSourceEditorBlock>
              )
            }
          </TiledSet>
        </DragDropContext>
      }
    </>
  )
}

export default TiledDashboardEditor;