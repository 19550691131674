/**
 * React imports
 */
import React, { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {FormattedMessage} from "react-intl";
/**
 * Third-party imports
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import domtoimage from 'dom-to-image-more';
import { Menu, Dropdown, Card, Typography } from 'antd';
import { Button as AntdButton }  from 'antd';
/**
 * Local imports
 */
import FullScreenModal from '../components/FullScreenModal.jsx';
import { saveReportImage } from '../actions/reports';
import { ReportImgContext } from '../context/report-context';
import { ReportsContext } from '../Main/MainContainer';
import { useIntl } from "react-intl"; 

function ChartSelector({ children, ... props }) {

  const showMximizeOption = props.showMaximize === undefined? true: props.showMaximize;
  const { setIsShown } = useContext(ReportImgContext);
  const intl = useIntl();
  const savePlaceholder = intl.formatMessage({id: 'app.save',defaultMessage: 'Save'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  const okPlaceholder = intl.formatMessage({id: 'app.ok',defaultMessage: 'Ok'});
  const reports = useContext(ReportsContext);

  const chart = useRef();

  const [isOpen, setOpen] = useState(false);
  const [enlarged, setEnlarged] = useState(false);
  const [reportId, setReportId] = useState('');
  const [title, setTitle] = useState('');
  const [commentary, setCommentary] = useState('');

  const dispatch = useDispatch();
  const classes = useStyles();

  const close = () => {
    setOpen(false);
  }

  const handleAddToReportClick = () => {
    setOpen(true);
  }

  const handleReportChange = (event) => {
    setReportId(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleCommentaryChange = (event) => {
    setCommentary(event.target.value);
  };

  const handleSave = () => {
    setIsShown(false);
    domtoimage.toPng(chart.current)
      .then((dataUrl) => {
        const data = {
          report: reportId,
          data_url: dataUrl,
          title,
          commentary,
        };
        dispatch(saveReportImage(data));
        setOpen(false);
        setIsShown(true);
        setTitle('');
        setCommentary('');
        setReportId('');
      });
  };

  const handleEnlarge = () => {
    setEnlarged(true);
  };

  const menu = (
    <Menu>
      <Menu.Item key="add-to-report" onClick={handleAddToReportClick}>
        <i className="fas fa-plus-square"></i>&nbsp; <FormattedMessage
            id="app.explorePage.addToReport"
            defaultMessage="Add to report"
          />
      </Menu.Item>
      {showMximizeOption && (
        <Menu.Item key="maximize-report" onClick={handleEnlarge}>
          <i className="fas fa-window-maximize"></i>&nbsp;<FormattedMessage
            id="app.maximize"
            defaultMessage="Maximize"
          /> 
        </Menu.Item>
      )}
      {props.showExploreLink && (
        <Link to={props.exploreLink}>
          <Menu.Item key="explore-data">
            <i className="fas fa-search"></i>&nbsp;{" "}
            <FormattedMessage
              id="app.exploreData"
              defaultMessage="Explore Data"
            />
          </Menu.Item>
        </Link>
      )}
    </Menu>
  );

  return (
    <>
      <FullScreenModal
        title={props.title}
        open={enlarged}
        onOk={() => setEnlarged(false)}
        onCancel={() => setEnlarged(false)}
        okText={okPlaceholder}
        cancelText={cancelPlaceholder}
        okButtonProps={{ style:{width:"400px"}}}
      >
        {enlarged &&
          React.Children.map(children, (child) => {
            return React.cloneElement(child, {}, null);
          })}
      </FullScreenModal>
      <Card
        headStyle={{ borderBottom: 0, paddingRight: 5 }}
        actions={props.actions}
        title={
          <Typography
            type="secondary"
            style={{ color: "#777", fontSize: "20px" }}
          >
            {props.title}
          </Typography>
        }
        style={{ height: "calc(100% - 0px)" }}
        extra={
          <Dropdown
            overlay={menu}
            placement="bottomRight"
            trigger={["click"]}
            style={{ textAlign: "right" }}
          >
            <AntdButton type="text">
              <i className="fas fa-user-cog" style={{ color: "#777" }}></i>
            </AntdButton>
          </Dropdown>
        }
      >
        <div ref={chart} className={classes.chart}>
          {!enlarged && children}
          <div className="text-right">
            {props.showExploreLink && (
              <Link to={props.exploreLink}>
                <AntdButton className="eq-standard-button">
                  <FormattedMessage
                    id="app.exploreData"
                    defaultMessage="Explore Data"
                  />
                </AntdButton>
              </Link>
            )}
          </div>
        </div>
      </Card>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={close}
        TransitionComponent={DialogTransition}
        aria-labelledby="add-to-report-dialog-slide-title"
        aria-describedby="add-to-report-dialog-slide-description"
      >
        <DialogTitle id="add-to-report-dialog-slide-title">
        <FormattedMessage
            id="app.explorePage.addToReport"
            defaultMessage="Add to report"
          />
        </DialogTitle>
        <DialogContent>
          <ValidatorForm
            className={classes.form}
            autoComplete="off"
            onSubmit={handleSave}
            onError={(errors) => console.log(errors)}
          >
            <FormControl fullWidth>
              <SelectValidator
                fullWidth
                id="report-selector"
                label="Report"
                margin="normal"
                value={reportId}
                onChange={handleReportChange}
                required={true}
                validators={["required"]}
                errorMessages={["this field is required"]}
              >
                {Object.values(reports).map((rpt) => (
                  <MenuItem key={rpt.id} value={rpt.id}>
                    {rpt.title}
                  </MenuItem>
                ))}
              </SelectValidator>
              <TextValidator
                autoFocus
                fullWidth
                id="title-input"
                label="Title"
                margin="normal"
                rows="1"
                value={title}
                onChange={handleTitleChange}
                required={true}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
              <TextValidator
                autoFocus
                fullWidth
                id="commentary-input"
                label="Commentary"
                margin="normal"
                multiline={true}
                rows="3"
                value={commentary}
                onChange={handleCommentaryChange}
                required={true}
                validators={["required"]}
                errorMessages={["this field is required"]}
              />
            </FormControl>
            <div
              style={{
                display: "inline-block",
                marginLeft: "auto",
                marginRight: 0,
                marginTop: 25,
              }}
            >
              <Button color="secondary" onClick={close}>
                {cancelPlaceholder}
              </Button>
              <Button color="primary" type="submit">
                {savePlaceholder}
              </Button>
            </div>
          </ValidatorForm>
        </DialogContent>
      </Dialog>
      {/* <Button onClick={handleAddToReportClick}>
        Add to Report
      </Button> */}
    </>
  );
}

const DialogTransition = React.forwardRef((props, ref) =>
  <Slide direction="up" {...props} ref={ref} />
);

const useStyles = makeStyles(() => ({
  chart: {
    flex: 2,
  },
  chartWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
  },
}));

export default ChartSelector;