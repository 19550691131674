import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { fetchQuery } from '../actions/cube';
import { fetchTopicSentiment } from '../actions/topic_sentiment';
import ClusterAdvancedCard from '../components/Cards/ClusterAdvancedCard';
import ConversationCard from '../components/Cards/ConversationCard';
import MapCard from '../components/Cards/MapCard';
import MoodCard from '../components/Cards/MoodCard';
import ProblemListCard from '../components/Cards/ProblemListCard';
import UserStatisticCard from '../components/Cards/UserStatisticCard';
import ParticipationChatWidget from '../Overview/ParticipationChartWidget';
import {
  commentCountByConversation,
  commentCountBySentiment,
  commentCountByTopicAndSentiment,
  forumCommentCountByTopic,
  participantsByLocation,
  participantsByConversation,
  participationByRegisteredStatus,
  participationBySentStatus,
  participationByActiveStatus,
} from '../queries';
import { mapStateToCubeData } from '../utils';

function Overview({ forumId }) {

  const cubeData = useSelector((state) => mapStateToCubeData(state,
    commentCountByConversation,
    commentCountBySentiment,
    forumCommentCountByTopic,
    participantsByLocation,
    participantsByConversation,
    participationByRegisteredStatus,
    participationBySentStatus,
    participationByActiveStatus,
  ));

  const { data } = useSelector((state) => state.topicSentiment);
  const user = useSelector((state) => state.user);
  const date_range = useSelector((state) => state.date_range);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchQuery(commentCountByConversation, forumId));
    dispatch(fetchQuery(commentCountBySentiment, forumId));
    dispatch(fetchTopicSentiment(user.client_id, forumId, commentCountByTopicAndSentiment.query({
      clientUuid: user.client_id,
      forumUuid: forumId,
    }, date_range.data)));
    dispatch(fetchQuery(forumCommentCountByTopic, forumId));
    dispatch(fetchQuery(participantsByLocation, forumId));
    dispatch(fetchQuery(participantsByConversation, forumId));
    dispatch(fetchQuery(participationByRegisteredStatus, forumId));
    dispatch(fetchQuery(participationBySentStatus, forumId));
    dispatch(fetchQuery(participationByActiveStatus, forumId));
  }, [forumId, date_range]);


  return (
    <div className={classes.sectionContent}>
      <div className={classes.sectionName}>
        Participation summary
      </div>
      <div className={classes.clusterCardWrap}>
        <UserStatisticCard
          data={cubeData[participationBySentStatus.type].data}
          statistic="Invited (email)"
        />
        <UserStatisticCard
          data={cubeData[participationByRegisteredStatus.type].data}
          statistic="Registered"
        />
        <UserStatisticCard
          data={cubeData[participationByActiveStatus.type].data}
          statistic="Active Participation"
        />
      </div>
      {cubeData[participantsByConversation.type].data[0] && cubeData[participantsByConversation.type].data[0].length?
        cubeData[participantsByConversation.type].data.map((p, i) => (

        <div key={'r' + i} className={classes.conversationCardRow}>
          {p.map((p1, j) => (
            <div key={'c' + j}
              className={classes.conversationCardWrap + (j === p.length - 1 ? ' ' + classes.conversationCardWrapLast : '')}
            >
              <ConversationCard
                index={j + 1}
                commentCount={cubeData[commentCountByConversation.type].data[p1[0]]}
                participation={p1}
              />
            </div>
          ))}
        </div>

      )):
      <ConversationCard
        index= {0}
        commentCount= {0}
        participation= {null}
      />}
      <div className={classes.subSectionName}>
        Forum results summary
      </div>
      <div className={classes.sectionContent}>
        <div className={classes.multipleCardRow}>
          <div className={classes.moodCardWrap}>
            <MoodCard averageMood={cubeData[commentCountBySentiment.type].data} />
          </div>
          <div className={classes.clusterAdvancedCardWrap}>
            <ClusterAdvancedCard
              forumCommentCount={cubeData[forumCommentCountByTopic.type].data}
              forumId={forumId}
              topicClusters={data}
            />
          </div>
          <div className={classes.mapCardWrap}>
            <MapCard forumId={forumId} mapCardData={cubeData[participantsByLocation.type].data} />
          </div>
        </div>
        <div className={classes.doubleCardRow}>
          <div className={classes.specialListCardWrap}>
            <ProblemListCard />
          </div>
          <div className={classes.listCardWrap}>
            <ParticipationChatWidget />
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  clusterAdvancedCardWrap: {
    height: '100%',
    marginRight: '24px',
    width: '49%',
  },
  clusterCardWrap: {
    display: 'inline-block',
  },
  conversationCardRow: {
    display: 'flex',
    height: '286px',
    marginBottom: '24px',
    marginTop: '48px',
    width: '100%',
  },
  conversationCardWrap: {
    height: '100%',
    marginRight: '24px',
    width: '25%',
  },
  conversationCardWrapLast: {
    marginRight: 0,
  },
  doubleCardRow: {
    display: 'inline-flex',
    height: '450px',
    width: '100%',
  },
  listCardWrap: {
    height: '100%',
    width: '50%',
  },
  mapCardWrap: {
    height: '100%',
    width: '33%',
  },
  moodCardWrap: {
    height: '100%',
    marginRight: '24px',
    width: '17%',
  },
  multipleCardRow: {
    display: 'inline-flex',
    height: '286px',
    marginBottom: '40px',
    width: '100%',
  },
  sectionContent: {
    width: '100%',
  },
  sectionName: {
    color: '#516e88',
    fontSize: '34.5px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '41px',
    letterSpacing: '0.25px',
    lineHeight: '41px',
    marginBottom: '24px',
    marginTop: '40px',
  },
  specialListCardWrap: {
    height: '100%',
    marginRight: '24px',
    width: '50%',
  },
  subSectionName: {
    color: '#516e88',
    fontSize: '24.4px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: '41px',
    marginBottom: '24px',
    marginTop: '40px',
  },
}));

export default Overview;