import React, { createContext, useState } from 'react';

export const ForumIdContext = createContext();


// This context is used to preserve forum id and title in global scope when user choose a forum
const ForumIdContextProvider = (props) => {

  const [forumId, setForumId] = useState('');
  const [forumTitle, setForumTitle] = useState('');

  return (
    <ForumIdContext.Provider value={{ forumId, setForumId, forumTitle, setForumTitle  }}>
      {props.children}
    </ForumIdContext.Provider>
  );
}

export default ForumIdContextProvider;