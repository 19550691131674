import * as queries from '../queries';

function transform(key, data) {
  for (const q of Object.values(queries)) {
    if (q.type === key) {
      return q.transform(data) || q.default;
    }
  }
  return data;
};


export {
  transform
};