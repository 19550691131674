import { useMutation, gql } from "@apollo/client";

import { setupApi } from "../graphql/client";

export const FETCH_CULTUREMAP_LOADING = "FETCH_CULTUREMAP_LOADING";
export const FETCH_CULTUREMAP_SUCCESS = "FETCH_CULTUREMAP_SUCCESS";
export const FETCH_CULTUREMAP_FAILURE = "FETCH_CULTUREMAP_FAILURE";
export const FETCH_CULTURE_PHRASE_CHUNKS_LOADING = "FETCH_CULTURE_PHRASE_CHUNKS_LOADING";
export const FETCH_CULTURE_PHRASE_CHUNKS_SUCCESS = "FETCH_CULTURE_PHRASE_CHUNKS_SUCCESS";
export const FETCH_CULTURE_PHRASE_CHUNKS_FAILURE = "FETCH_CULTURE_PHRASE_CHUNKS_FAILURE";
export const FETCH_PHRASE_LDA_LOADING ="FETCH_PHRASE_LDA_LOADING";
export const FETCH_PHRASE_LDA_SUCCESS ="FETCH_PHRASE_LDA_SUCCESS";
export const FETCH_PHRASE_LDA_FAILURE ="FETCH_PHRASE_LDA_FAILURE";

export const CREATE_CULTUREMAP_LOADING = "CREATE_CULTUREMAP_LOADING";
export const CREATE_CULTUREMAP_SUCCESS = "CREATE_CULTUREMAP_SUCCESS";
export const CREATE_CULTUREMAP_FAILURE = "CREATE_CULTUREMAP_FAILURE";
export const DELETE_CULTUREMAP_FAILURE = "DELETE_CULTUREMAP_FAILURE";
export const DELETE_CULTUREMAP_SUCCESS = "DELETE_CULTUREMAP_SUCCESS";
export const DELETE_CULTUREMAP_PHRASE_FAILURE =
  "DELETE_CULTUREMAP_PHRASE_FAILURE";
export const DELETE_CULTUREMAP_PHRASE_SUCCESS =
  "DELETE_CULTUREMAP_PHRASE_SUCCESS";

const client = setupApi();

function fetchCultureMapLoading() {
  return {
    type: FETCH_CULTUREMAP_LOADING,
  };
}

function fetchCultureMapSuccess(payload) {
  return {
    type: FETCH_CULTUREMAP_SUCCESS,
    payload,
  };
}

function fetchCultureMapFailure(payload) {
  return {
    type: FETCH_CULTUREMAP_FAILURE,
    payload,
  };
}

function fetchCulturePhraseChunksLoading() {
  return {
    type: FETCH_CULTURE_PHRASE_CHUNKS_LOADING,
  };
}

function fetchCulturePhraseChunksSuccess(payload) {
  return {
    type: FETCH_CULTURE_PHRASE_CHUNKS_SUCCESS,
    payload,
  };
}

function fetchCulturePhraseChunksFailure(payload) {
  return {
    type: FETCH_CULTURE_PHRASE_CHUNKS_FAILURE,
    payload,
  };
}

function fetchPhraseLdaLoading() {
  return {
    type: FETCH_PHRASE_LDA_LOADING,
  };
}

function fetchPhraseLdaSuccess(payload) {
  return {
    type: FETCH_PHRASE_LDA_SUCCESS,
    payload,
  };
}

function fetchPhraseLdaFailure(payload) {
  return {
    type: FETCH_PHRASE_LDA_FAILURE,
    payload,
  };
}

export function getPhraseLDA(phrase_id, subType) {
  const subTypeParam = subType ? "$subType: String!" : "";
  const subTypeWhereParam = subType ? "filter: $subType" : "";

  return async (dispatch) => {
    try {
      dispatch(fetchPhraseLdaLoading);
      const preppedQuery = gql`
        query GetPhraseLda(
          $phrase_id: String!
          ${subTypeParam}
        ){
          ldas(
          where:{
          phrase_id:$phrase_id
          ${subTypeWhereParam}
        }){
          uid
          phrase_id
          client_id
          forum_id
          filter
          data
    }
  }
      `;
      console.log("preppedQuery", preppedQuery);
      const query = {
        query: gql`
          ${preppedQuery}
        `,
        variables: {
          phrase_id,
          subType
        }, 
    };
      const res = await client.query(query);
      return dispatch(fetchPhraseLdaSuccess(res.data));
    } catch (error) {
      dispatch(
        fetchPhraseLdaFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

export function getCultureMap(client_id, forum_id, culture_map_uid) {
  const forumParam = forum_id ? "$forum_id: String!" : "";
  const forumWhereParam = forum_id ? "forum_id: $forum_id" : "";

  return async (dispatch) => {
    try {
      dispatch(fetchCultureMapLoading);
      const preppedQuery = gql`
        query GetEntities(
          $client_id: String!
          ${forumParam}
  
        ){
          cultureMaps(
          where:{
          client_id:$client_id
          ${forumWhereParam}
        }){
      uid
      forum_id
      client_id
      conversation_id
      text
      start_date
      end_date
      table_dim1
      table_dim2
      culturePhrases{
        uid
        text
        status
        sequence
        avg_sentiment
        chunk_count
      }
    }
  }
      `;
      console.log("preppedQuery", preppedQuery);
      const query = {
        query: gql`
          ${preppedQuery}
        `,
        variables: {
          client_id,
          forum_id,
        },
        fetchPolicy: 'network-only',
       
    };
      const res = await client.query(query);
      return dispatch(fetchCultureMapSuccess(res.data));
    } catch (error) {
      dispatch(
        fetchCultureMapFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

export function getCulturePhraseChunks({
  client_id,
  forum_id,
  culturePhraseUid,
}) {
  return async (dispatch) => {
    try {
      dispatch(fetchCulturePhraseChunksLoading);
      const preppedQuery = gql`
        query GetEntities(
          $client_id: String!
          $forum_id: String!
          $culturePhraseUid: String!
        ) {
          cultureMaps(
            where: {
              client_id: $client_id
              forum_id: $forum_id
              culturePhrases: { uid: $culturePhraseUid }
            }
            options: { limit: 100 }
          ) {
            uid
            client_id
            forum_id
            text
            culturePhrases {
              uid
              text
              status
            }
          }
        }
      `;
      console.log("preppedQuery", preppedQuery);
      const query = {
        query: gql`
          ${preppedQuery}
        `,
        variables: {
          client_id,
          forum_id,
          culturePhraseUid,
        },
      };
      const res = await client.query(query);
      return dispatch(fetchCulturePhraseChunksSuccess(res.data));
    } catch (error) {
      dispatch(
        fetchCulturePhraseChunksFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

//Mutations

function createCultureMapLoading() {
  return {
    type: CREATE_CULTUREMAP_LOADING,
  };
}

function createCultureMapSuccess(payload) {
  return {
    type: CREATE_CULTUREMAP_SUCCESS,
    payload,
  };
}

function createCultureMapFailure(payload) {
  return {
    type: CREATE_CULTUREMAP_FAILURE,
    payload,
  };
}

function deleteCultureMapSuccess(payload) {
  return {
    type: DELETE_CULTUREMAP_SUCCESS,
    payload,
  };
}

function deleteCultureMapFailure(payload) {
  return {
    type: DELETE_CULTUREMAP_FAILURE,
    payload,
  };
}
function deleteCulturePhraseSuccess(payload) {
  return {
    type: DELETE_CULTUREMAP_SUCCESS,
    payload,
  };
}
function deleteCulturePhraseFailure(payload) {
  return {
    type: DELETE_CULTUREMAP_FAILURE,
    payload,
  };
}

export function createCultureMap({
  client_id,
  forum_id,
  text,
  start_date,
  end_date,
  table_dim1,
  table_dim2
}) {
  return async (dispatch) => {
    try {
      dispatch(createCultureMapLoading());
      
      const endDateParam = end_date !== null ? "$end_date: Date!" :"";
      const endDateWhereParam = end_date !== null? "end_date: $end_date" :"";
      const preppedQuery = gql`
        mutation CreateCultureMaps(
          $client_id: String!
          $forum_id: String!
          $text: String!
          $start_date: Date!
          ${endDateParam}
          $table_dim1: String
          $table_dim2: String
        ) {
          createCultureMaps(
            input: [
              {
                client_id: $client_id
                forum_id: $forum_id
                text: $text
                start_date: $start_date
                ${endDateWhereParam}
                table_dim1: $table_dim1
                table_dim2: $table_dim2
              }
            ]
          ) {
            info {
              nodesCreated
              relationshipsCreated
            }
            cultureMaps {
              uid
            }
          }
        }
      `;
      console.log("preppedQuery", preppedQuery);
      const gqlMutation = {
        mutation: gql`
          ${preppedQuery}
        `,
        variables: {
          client_id,
          forum_id,
          text,
          start_date,
          end_date,
          table_dim1,
          table_dim2
        },
      };
      const res = await client.mutate(gqlMutation);
      return dispatch(
        createCultureMapSuccess(
          res.data.createCultureMaps.cultureMaps.map((doc) => {
            return {
              ...doc,
              ...{
                uid: doc.uid,
              },
            };
          })
        )
      );
    } catch (error) {
      dispatch(
        createCultureMapFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

export function updateCultureMap({ uid, client_id, forum_id, text }) {
  return async (dispatch) => {
    try {
      const preppedQuery = gql`
        mutation updateCultureMaps(
          $uid: ID!
          $client_id: String!
          $forum_id: String!
          $text: String!
        ) {
          updateCultureMaps(
            where: { client_id: $client_id, uid: $uid }
            create: {
              culturePhrases: [
                {
                  node: {
                    client_id: $client_id
                    forum_id: $forum_id
                    text: $text
                  }
                }
              ]
            }
          ) {
            info {
              nodesCreated
              relationshipsCreated
            }
            cultureMaps {
              uid
              client_id
              forum_id
              text
              start_date
              end_date
              culturePhrases {
                uid
                text
                status
                sequence
                process_datetime
              }
            }
          }
        }
      `;
      console.log("preppedQuery", preppedQuery);
      const gqlMutation = {
        mutation: gql`
          ${preppedQuery}
        `,
        variables: {
          uid,
          client_id,
          forum_id,
          text,
        },
      };
      const res = await client.mutate(gqlMutation);
      return res;
    } catch (error) {
      dispatch(
        createCultureMapFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

export function deleteCultureMap({ uid, client_id }) {
  return async (dispatch) => {
    try {
      const preppedQuery = gql`
        mutation deleteCultureMaps($uid: ID!, $client_id: String!) {
          deleteCultureMaps(
            where: { client_id: $client_id, uid: $uid }
            delete: { culturePhrases: [{ where: {} }] }
          ) {
            nodesDeleted
            relationshipsDeleted
          }
        }
      `;

      const gqlMutation = {
        mutation: gql`
          ${preppedQuery}
        `,
        variables: {
          uid,
          client_id,
        },
      };
      const res = await client.mutate(gqlMutation);
      return dispatch(
        deleteCultureMapSuccess(
          res.data.createCultureMaps.cultureMaps.map((doc) => {
            return {
              ...doc,
              ...{
                uid: doc.uid,
              },
            };
          })
        )
      );
    } catch (error) {
      dispatch(
        deleteCultureMapFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

export function deleteCulturePhrases({ uid, client_id }) {
  return async (dispatch) => {
    try {
      const preppedQuery = gql`
        mutation deleteCulturePhrases($uid: ID!, $client_id: String!) {
          deleteCulturePhrases(where: { client_id: $client_id, uid: $uid }) {
            nodesDeleted
            relationshipsDeleted
          }
        }
      `;

      const gqlMutation = {
        mutation: gql`
          ${preppedQuery}
        `,
        variables: {
          uid,
          client_id,
        },
      };
      const res = await client.mutate(gqlMutation);
      return dispatch(
        deleteCulturePhraseSuccess(
          res.data.createCultureMaps.cultureMaps.map((doc) => {
            return {
              ...doc,
              ...{
                uid: doc.uid,
              },
            };
          })
        )
      );
    } catch (error) {
      dispatch(
        deleteCulturePhraseFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}
