import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash.isempty';
import { Chart } from '@antv/g2';
import DataSet from '@antv/data-set';
import { useHistory, useLocation } from 'react-router-dom';

const { DataView } = DataSet;

function EmojifyTopicChart({ data, isMain = true, name, axis, xscale, onPointClicked, additionalAxis, hideDefaultAxis }) {
  const [ chartId, setChartId ] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  let isSorted = false;

  useEffect(() => {
    setChartId(Math.random().toString(32).substr(2, 8));
  }, []);

  useEffect(() => {
    let view;

    //console.log('EmojifyTopicChart->data', data);
    if (!isEmpty(data) && chartId) {
      let option = createChart(data, isMain, chartId, axis, xscale, additionalAxis, hideDefaultAxis);
      view = option.view;
      view.on('annotation-image:click', (ev) => {
        if (isSorted) {
          isSorted = !isSorted;
          view.changeData(data);
        } else {
          isSorted = !isSorted;
          view.changeData(option.dv.rows);
        }
      });
      view.on('point:click', (ev) => {
        const callback = (onPointClicked) ? onPointClicked : _onPointClicked;
        const lineElement = ev.target.get('element');
        callback(ev, lineElement);
      });
    }

    return () => {
      // need to destroy the chart and view or we will get two charts when user navigates back
      if(view){
        view.destroy();
        view.parent.destroy();
      }
    };

  }, [ chartId, data ]);

  function _onPointClicked(ev) {
    history.push([
      '/explore/chunks?from=',
      encodeURIComponent(location.pathname.slice(1)),
      '&q=' + encodeURIComponent(ev.data.data[axis.y])
    ].join(''));
  }

  return (
    <Fragment>
      <div id={ chartId } className={classes.chart}></div>
    </Fragment>

  );
}

function createChart(data, isMain, name, axis, xscale, additionalAxis, hideDefaultAxis) {
  console.log('additionalAxis', additionalAxis);
  const dv = new DataView().source(data);
  // dv.transform({
  //   type: 'sort',
  //   callback(a, b) {
  //     return b[axis.x] - a[axis.x];
  //   },
  // });

  const chart = new Chart({
    container: name,
    theme: 'light',
    autoFit: true,
    padding: [0, 0],
    height: isMain ? Math.max(500, data.length * 33) : 500,
  });

  chart.tooltip({
    itemTpl: `
      <div>
        <div style="margin-bottom: 10px;list-style:none;">
          <span style="background-color:{color};" class="g2-tooltip-marker"></span>
          Chunk Count: {chunk_count}
        </div>
        <div style="margin-bottom: 10px;list-style:none;">
          <span style="background-color:{color};" class="g2-tooltip-marker"></span>
          Avg Chunk Sentiment: {chunk_avg_sentiment}
        </div>
      </div>
    `
  });

  const view = chart.createView({
    region: {
      start: { x: 0.25, y: 0 },
      end: { x: 0.9, y: 1 },
    },
    padding: [30, 0, 0, 0],
  });

  //default to be sorted
  view.data(isMain ? dv.rows: data);

  let scales = {
    [axis.x]: {
      min: xscale.start,
      max: xscale.end,
    },
  };
  view.axis(axis.y, {
    label: {
      style: {
        fill: '#000',
        fontSize: isMain ? 18 : 12,
        lineHeight: 10,
        autoEllipsis: true
      }
    },
    title: null,
    tickLine: null,
    line: null,
    offsetX: isMain ? -15 : -5,
    offsetY: 0,
    grid: {
      line: {
        style: {
          strokeOpacity: 0.3,
          stroke: '#000',
        }
      }
    }
  });

  view.axis(axis.x, {
    label: null,
    title: null,
    tickLine: null,
    color: '#000',
    grid: null,
  });

  if (additionalAxis && additionalAxis.length > 0) {
    additionalAxis.forEach((nAxis) => {
      scales[nAxis.x] = {
        min: xscale.start,
        max: xscale.end,
      };
      view.axis(nAxis.x, {
        label: null,
        title: null,
        tickLine: null,
        color: '#000',
        grid: null,
      });
    });
  }

  view.scale(scales);
  view.legend(false);
  view.coordinate().transpose();



  view.annotation().line({
    top: false,
    start: ['50%', '2%'],
    end: ['50%', '99%'],
    style: {
      stroke: '#000',
      strokeOpacity: 0.6,
      lineWidth: 2,
    }
  });

  view.annotation().line({
    top: false,
    start: ['55%', '-2'],
    end: ['99%', '-2'],
    style: {
      stroke: '#000',
      strokeOpacity: 0.3,
      lineWidth: 1.4,
    }
  });

  view.annotation().line({
    top: false,
    start: ['0%', '-2'],
    end: ['45%', '-2'],
    style: {
      stroke: '#000',
      strokeOpacity: 0.3,
      lineWidth: 1.4,
    }
  });

  view.annotation().dataMarker({
    position: ['50%', '1%'],
    offsetY: -33,
    line: null,
    point: null,
    text: {
      content: '0',
      style: { textAlign: 'center', fontSize: 30, fill: '#FFA500', opacity: 1 },
    }
  });

  view.annotation().dataMarker({
    position: ['106%', '1%'],
    offsetY: -40,
    line: null,
    point: null,
    text: {
      content: '+',
      style: { textAlign: 'right', fontSize: 50, fill: '#0aa30a', opacity: 1},
    },

    direction: 'upward'
  });

  view.annotation().dataMarker({
    position: ['-7%', '-.05%'],
    offsetY: -40,
    line: null,
    point: null,
    text: {
      content: '-',
      style: { textAlign: 'left', fontSize: 60, fill: '#FF0000', opacity: 1},
    },
    direction: 'upward'
  });

  // view.annotation().region({
  //   start: [0, 'min'],
  //   // the chart deduplicates rows with the same display value, which can throw off
  //   // the dimensions of the background region. Need to dedup ourselves to calc what
  //   // the correct display dimensions will be
  //   end: [ _.uniq(dv.rows.map((r) => r.name || r.text)).length - 1, 'max'],
  //   style: {
  //     fill: 'l(0) 0:#ff0000 0.25:#ff6a00 0.75:#fffe00 1:#00ff2c',
  //     fillOpacity: 0.2
  //   }
  // });

  if (!hideDefaultAxis) {
    view
      .point()
      .position(`${axis.y}*${axis.x}`)
      .size(10)
      .shape('circle')
      .color('#1890ff')
      .style({
        fillOpacity: 0.85
      })
      .tooltip('chunk_count*chunk_avg_sentiment', function (chunk_count, chunk_avg_sentiment) {
        return {
          chunk_count: chunk_count,
          chunk_avg_sentiment: chunk_avg_sentiment
        }
      });
  }

  if (additionalAxis && additionalAxis.length > 0) {
    additionalAxis.forEach((nAxis) => {
      view
        .point()
        .position(`${nAxis.y}*${nAxis.x}`)
        .size(10)
        .shape('circle')
        .color(nAxis.color)
        .style({
          fillOpacity: 0.85
        })
        .tooltip(`${nAxis.c}*${nAxis.x}`, function (chunk_count, chunk_avg_sentiment) {
          return {
            chunk_count: chunk_count,
            chunk_avg_sentiment: chunk_avg_sentiment
          }
        });
    });
  } else {
  }

  view.interaction('element-active');
  chart.render();
  return { view, dv };
}

const useStyles = makeStyles(() => ({
  chart: {
    padding: '0px',
    width: '100%',
  },
}));

export default EmojifyTopicChart;
