import React, { useState, useEffect } from 'react';
import { Space, Row, Col } from 'antd';

const TileBody = ({ children, provided, dataSource, index, onChange, onDelete, uniqId }) => {
  const [ item, setItemValue ] = useState(dataSource);

  useEffect(() => {
    setItemValue(dataSource);
  }, [ dataSource ]);

  const setItem = (value) => {
    onChange(value);
    setItemValue(value);
  };

  const updateItem = (value) => {
    setItemValue((current) => {
      current = {
        ... current,
        ... value
      };
      onChange(current);
      return current;
    });
  };

  const deleteItem = onDelete;

  return (!item) ? <></> : (
    <Row>
      <Col style={ { width: '30px', textAlign: 'center',  } }>
        <Space align="center" size={[8, 16]} wrap style={ { height: '100%' } }>
          <span {...provided.dragHandleProps}>
            <i
              className="fas fa-grip-vertical"
            ></i>
          </span>
        </Space>
      </Col>
      <Col style={ { width: 'calc(100% - 30px)' } }>
        { children && children({ item, setItem, updateItem, deleteItem, index, uniqId }) }
      </Col>
    </Row>
  );
};

export default TileBody;