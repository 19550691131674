import React, { useEffect, useState, Fragment } from 'react';

import CommentsList from './CommentsList';
import Filters from './Filters';

import { Layout } from 'antd';
const { Sider, Content } = Layout;

export default function CommentsAndFilter({ ... props }) {

  const [ filteredData, setFilteredData ] = useState([]);

  const onFiltersChange = (data) => {
    setFilteredData(data);
  };

  return (
    <Layout>
      <Sider style={{ background: '#fafafa', padding: '0 10px 0 10px' }} width={ 350 }>
        <Filters data={ props.data } onChange={ onFiltersChange } />
      </Sider>
      <Content style={{ background: '#fff', padding: '10px', width:"auto"}}>
        <CommentsList client_id={ props.client_id } data={ filteredData } title={props.title} forum_id={props.forum_id} />
      </Content>
    </Layout>
  )
}