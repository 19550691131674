import cubejs from '@cubejs-client/core';
import * as moment from 'moment';
import { transform } from '../transformers/utils';

export const FETCH_CUBE_DATA_LOADING = 'FETCH_CUBE_DATA_LOADING';
export const FETCH_CUBE_DATA_SUCCESS = 'FETCH_CUBE_DATA_SUCCESS';
export const FETCH_CUBE_DATA_FAILURE = 'FETCH_CUBE_DATA_FAILURE';

export const cubeapi = cubejs(
  process.env.REACT_APP_CUBEJS_API_KEY,
  { apiUrl: process.env.REACT_APP_CUBEJS_API_URL }
);

function fetchCubeDataLoading(payload) {
  return {
    type: FETCH_CUBE_DATA_LOADING,
    payload,
  };
}

function fetchCubeDataSuccess(payload) {
  return {
    type: FETCH_CUBE_DATA_SUCCESS,
    payload,
  };
}

function fetchCubeDataFailure(payload) {
  return {
    type: FETCH_CUBE_DATA_FAILURE,
    payload,
  }
}

function logCubeQuery(key, success = false) {
  const icon = success ? '\u2705' : '\u274C';
  console.log('='.repeat(40));
  console.log(`= ${icon} ${key.substring(0, 33)} ` + '='.repeat(34 - Math.min(key.length, 33)));
  console.log('='.repeat(40));
}

function transformDate(rawDate) {
  return moment(rawDate).format("YYYY-MM-DD");
}

export function fetchCubeData(key, query, dateRange) {
  return async (dispatch, getState) => {
    try {
      let filteredQuery = query;
      let zDateRange = (dateRange) ? dateRange : getState().date_range.data;
      if (zDateRange.startDate) {
        filteredQuery = {
          ...query,
          timeDimensions: [
            {
              ...query.timeDimensions[0],
              dateRange: [
                transformDate(zDateRange.startDate),
                transformDate(zDateRange.endDate),
              ],
            },
          ]
        }
      }
      dispatch(fetchCubeDataLoading({ key }));

      const rs = await cubeapi.load(filteredQuery);
      const result = {
        key,
        data: rs.loadResponse.data,
      };
      dispatch(fetchCubeDataSuccess(result));
      logCubeQuery(key, true);
      return result;
    } catch (error) {
      dispatch(fetchCubeDataFailure({
        key,
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }));
      logCubeQuery(key);
    }
  }
};

export function fetchQuery(queryObj, ...queryArgs) {
  return fetchCubeData(queryObj.type, queryObj.query(...queryArgs));
};

export function fetchQueryWithCustomDateRange(queryObj, dateRange, ...queryArgs) {
  return fetchCubeData(queryObj.type, queryObj.query(...queryArgs), dateRange);
};