import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DataSet from '@antv/data-set';
import { Chart } from '@antv/g2';
import isEmpty from 'lodash.isempty';

const { DataView } = DataSet;

function EmojifyChart({ data, onClick, scale = 1, fontSize = 13 }) {

  const [ chartId, setChartId ] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setChartId(Math.random().toString(32).substr(2, 8));
  }, []);

  useEffect(() => {
    let chart;
    if (!isEmpty(data) && chartId) {
      // setTimeout(() => {
        chart = createChart(data, scale, fontSize, chartId);

        chart.on('label:click', (ev) => {
          onClick(ev.data.data);
        });

        chart.on('point:click', (ev) => {
          onClick(ev.data.data);
        });

    }
    return () => {
      // need to destroy the chart or we will get two charts when user navigates back
      if(chart){
        chart.destroy();
      }
    };
  }, [data, chartId]);

  return (
    <div className={classes.chartContainer}>
      <div id={ chartId } className={classes.chart} />
    </div>
  );
}

function createChart(data, scale, fontSize, name) {
  const dv = new DataView().source(data);
  dv.transform({
    type: 'fold',
    fields: ['a', 'b'],
    key: 'user',
    value: 'score',
  });
  const chart = new Chart({
    container: name,
    autoFit: true,
    height: 500,
  });
  chart.data(dv.rows);
  chart.scale('score', {
    min: 0,
    max: 80,
  });
  chart.coordinate('polar', {
    radius: 0.8,
  });
  chart.axis('x', {
    line: null,
    tickLine: null,
    grid: {
      line: {
        style: {
          lineDash: null,
        },
      },
    },
    label: null,
  });
  chart.axis('score', {
    line: null,
    tickLine: null,
    grid: {
      line: {
        type: 'circle',
        style: {
          lineDash: null,
          strokeOpacity: 0,
        },
      },
    },
    label: null,
  });

  chart
    .point()
    .position('x*score')
    .tooltip('label*comment_count', (label, comment_count) => {
      return {
        title: label,
        name: "Count",
        value: comment_count
      };
    })
    .color('color', (val) => val)
    .shape('circle')
    .size('cropped_count', [0, 20 * scale])
    .style({
      stroke: '#fff',
      lineWidth: 1,
      fillOpacity: 1,
    })
    .label('label', {
      offset: 0,
      style: {
        fill: '#1890FF',
        stroke: '#fff',
        lineWidth: 1,
        fontSize,
      }
    });
  chart.legend(false);
  chart.render();

  return chart;
}

const useStyles = makeStyles((theme) => ({
  chart: {
    flex: 2,
    padding: '20px 20px 20px 0',
    width: '100%',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: 1,
  },
  title: {
    color: 'rgba(0,0,0,0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
  },
}));

export default EmojifyChart;