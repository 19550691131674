import React from 'react';
import { connect } from 'react-redux';

import { login, logout } from '../actions/authenticate';

const AuthContext = React.createContext();

function MyAuthProvider({ children, login, logout, user }) {

  return (
    <AuthContext.Provider value={{ login, logout, user, }}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => React.useContext(AuthContext);

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

const mapDispatchToProps = {
  login,
  logout,
};

const AuthProvider = connect(mapStateToProps, mapDispatchToProps)(MyAuthProvider);

export { AuthProvider, useAuth };