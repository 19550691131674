import _ from 'lodash';
import {
  FETCH_REPORTS_LOADING,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_FAILURE,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_IMAGES_SUCCESS,
  FETCH_REPORT_SECTIONS_SUCCESS,
  SAVE_REPORT_SUCCESS,
  DELETE_REPORT_SECTION_SUCCESS,
  DELETE_REPORT_SUCCESS,
  SAVE_REPORT_SECTION_SUCCESS,
  SAVE_REPORT_IMAGE_SUCCESS,
  SAVE_REPORT_CHUNK_SUCCESS,
  SAVE_REPORT_TEXT_ANALYSIS_SUCCESS,
  DELETE_REPORTS_SUCCESS
} from '../actions/reports';

const initialState = {
  data: {},
  error: null,
  loaded: false,
  loading: true,
};

export function reports(state = initialState, action) {
  let report;
  switch (action.type) {
    case FETCH_REPORTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_REPORTS_SUCCESS:
      return {
        data: action.payload.reduce((a, rpt) => {
          const rptId = '' + rpt.id;
          if (a[rptId]) {
            a[rptId] = {
              ...a[rptId],
              ...rpt
            };
          } else {
            a[rptId] = rpt;
          }
          return a;
        }, state.data),
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_REPORTS_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };

    case FETCH_REPORT_SUCCESS:
      report = action.payload;
      const rptId = '' + report.id;
      if (state[rptId]) {
        return {
          data: {
            ...state.data,
            [rptId]: {
              ...state.data[rptId],
              ...report
            }           
          },
          error: null,
          loaded: true,
          loading: false,
        };
      } else {
        return {
          data: {
            ...state.data,
            [rptId]: report
          },
          error: null,
          loaded: true,
          loading: false,
        };
      }

    case SAVE_REPORT_IMAGE_SUCCESS: 
      const image = action.payload;
      const report_id = "" + image.report;
      
      return {
        data: {
          ...state.data,
          [report_id]: {
            ...state.data[report_id],
            images: (state.data[report_id].images || []).concat([image])
          }
        },
        error: null,
        loaded: true,
        loading: false,
      }
    case SAVE_REPORT_CHUNK_SUCCESS:
      const chunk = action.payload;
      const r_id = "" + chunk.report;
      return {
        data: {
          ...state.data,
          [r_id]: {
            ...state.data[r_id],
            chunks: (state.data[r_id].chunks || []).concat([chunk])
          }
        },
        error: null,
        loaded: true,
        loading: true,
      }
      case SAVE_REPORT_TEXT_ANALYSIS_SUCCESS:
        const textAnalysis = action.payload;
        const t_id = "" + textAnalysis.report;
        return {
          data: {
            ...state.data,
            [t_id]: {
              ...state.data[t_id],
              textAnalysis: (state.data[t_id].textAnalysis || []).concat([textAnalysis])
            }
          },
          error: null,
          loaded: true,
          loading: true,
        }
    case FETCH_REPORT_IMAGES_SUCCESS:
      return {
        data: action.payload.reduce((a, rptImg) => {
          const rptId = '' + rptImg.report;
          if (a[rptId]) {
            a[rptId] = {
              ...a[rptId],
              images: (a[rptId].images || []).concat([rptImg])
            };
          } else {
            a[rptId] = {
              images: [rptImg]
            };
          }
          return a;
        }, state.data),
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_REPORT_SECTIONS_SUCCESS:
      return {
        data: action.payload.reduce((a, rptSect) => {
          const rptId = '' + rptSect.report;
          if (a[rptId]) {
            a[rptId] = {
              ...a[rptId],
              sections: (a[rptId].sections || []).concat([rptSect])
            };
          } else {
            a[rptId] = {
              sections: [rptSect]
            };
          }
          return a;
        }, state.data),
        error: null,
        loaded: true,
        loading: false,
      };

    case SAVE_REPORT_SUCCESS:
      report = action.payload;
      return {
        data: {
          ...state.data,
          ['' + report.id]: report,
        },
        error: null,
        loaded: true,
        loading: false,
      };

      case DELETE_REPORTS_SUCCESS:
        report = action.payload;
        return {
          data: _.omit(state.data, report),
          error: null,
          loaded: true,
          loading: false,
        };

    case DELETE_REPORT_SECTION_SUCCESS:
      const { reportId, sectionId } = action.payload;
      return {
        data: {
          ...state.data,
          [reportId]: {
            ...state.data[reportId],
            sections: (state.data[reportId].sections || []).filter((s) => s.id !== sectionId)
          },
        },
        error: null,
        loaded: true,
        loading: false,
      };

    case SAVE_REPORT_SECTION_SUCCESS:
      const { report_Id, new_section } = action.payload;
      let isNew = true;
      const updated_sections = state.data[report_Id].sections.map((section) => {
        if (section.id === new_section.id) {
          isNew = false;
          return new_section;
        }
        return section;
      })
      
      if (isNew) {
        updated_sections.push(new_section);
      }

      return {
        data: {
          ...state.data,
          [report_Id]: {
            ...state.data[report_Id],
            sections: updated_sections
          },
        },
        error: null,
        loaded: true,
        loading: false,
      }

    case DELETE_REPORT_SUCCESS:
      return {
        data: _.omit(state.data, action.payload),
        error: null,
        loaded: true,
        loading: false,
      };

    default:
      return state;
  }
}