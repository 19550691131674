import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  Form,
  Input,
  Select,
  Modal,
  DatePicker,
  Space,
  Button,
  Drawer,
  Switch,
  Collapse,
  Col,
  Row,
} from "antd";
import _ from "lodash";
import * as d3 from "d3";
import FiltersforDemo2 from "./FiltersforDemo2";
import "./style.css";

export default ({ graphData }) => {
  console.log(graphData);
  const ref = useRef(null);
  const legendRef = useRef(null);
  const [graph, setGraph] = useState([]);
  const [open, setOpen] = useState(false);
  const [bgColor, setBgColor] = useState(false);
  const [isGrouped, setIsGrouped] = useState(false);
  const [centralityGroup, setCentralityGroup] = useState("");

  let maxRadius = graphData.nodes && graphData.nodes.reduce((max, node) => {
    return node.cDegree > max ? node.cDegree : max;
  }, 0);
  let minRadius = graphData.nodes && graphData.nodes.reduce((min, node) => {
    return node.cDegree < min ? node.cDegree : min;
  }, graphData.nodes[0].cDegree);
  // let minRadius = Math.min(...nodeRadii);
  let scalingFactor = maxRadius - minRadius;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const hideBackgroundColor = () => {
    setBgColor(!bgColor);
    console.log(bgColor);
    createSVG();
  };

  const onFiltersChange = (data, centralityGroup) => {
    setCentralityGroup(centralityGroup);
    setGraph(data);
  };

  const scaleRadius=(r)=> {
    console.log(10 + (r - 1) / (10000 - 1) * (100));
    return 10 + (r - 1) / (10000 - 1) * 100;
  }
  const tooltipRef = useRef();

  // useEffect(() => {
  //   const dataStep2 = {
  //     nodes: [
  //       { id: 1, name: "HR", color: "#ec9b41", icon: "\u2927", depth: 1 },
  //       {
  //         id: 2,
  //         name: "Prodcut Manager",
  //         color: "#ec9b41",
  //         icon: "\u2720",
  //         group: 2,
  //       },
  //       {
  //         id: 3,
  //         name: "Technical Manager",
  //         color: "#ec9b41",
  //         icon: "\u039C",
  //         group: 1,
  //       },
  //       {
  //         id: 4,
  //         name: "Marketing Manager",
  //         color: "#ec9b41",
  //         icon: "\u25b2",
  //         group: 2,
  //       },
  //       {
  //         id: 5,
  //         name: "Finance Manager",
  //         color: "#ec9b41",
  //         icon: "\u2744",
  //         group: 2,
  //       },
  //       {
  //         id: 6,
  //         name: "Production Manager",
  //         color: "#ec9b41",
  //         icon: "\u2734",
  //         group: 2,
  //       },
  //       {
  //         id: 7,
  //         name: "Marketing Manager",
  //         color: "#ec9b41",
  //         icon: "\u25b2",
  //         group: 3,
  //       },
  //       {
  //         id: 8,
  //         name: "Finance Manager",
  //         color: "#ec9b41",
  //         icon: "\u2744",
  //         group: 3,
  //       },
  //       {
  //         id: 9,
  //         name: "Production Manager",
  //         color: "#ec9b41",
  //         icon: "\u2734",
  //         group: 2,
  //       },
  //     ],
  //     links: [
  //       { source: 1, target: 2, value: 1 },
  //       { source: 2, target: 3, value: 1 },
  //       { source: 2, target: 3, value: 2 },
  //       { source: 3, target: 4, value: 1 },
  //       { source: 4, target: 5, value: 3 },
  //       { source: 2, target: 7, value: 2 },
  //       { source: 3, target: 9, value: 2 },
  //       { source: 4, target: 8, value: 3 },
  //       { source: 1, target: 9, value: 2 },
  //       { source: 3, target: 8, value: 3 },
  //       { source: 4, target: 6, value: 3 },
  //     ],
  //   };
  //   //setGraph(dataStep2);
  //   setGraph(data);
  // }, [data]);

  const createSVG = () => {
    const svgEl = d3.select(ref.current);
    const svgE2 = d3.select(legendRef.current);
    svgEl.selectAll("*").remove(); // Clear svg content before adding new elements
    svgE2.selectAll("*").remove(); // Clear svg content before adding new elements
    // Creates the SVG
    var svg = svgEl
        .append("svg")
        .attr("width", ref.current.clientWidth)
        .attr("height", ref.current.clientHeight)
        .attr("border", 1),
      width = +svg.attr("width"),
      height = +svg.attr("height"),
      color = d3.scaleOrdinal(d3.schemeCategory10);

    var voronoi = d3
      .voronoi()
      .x(function (d) {
        return d.x;
      })
      .y(function (d) {
        return d.y;
      })
      .extent([
        [-1, -1],
        [width + 1, height + 1],
      ]);

    var simulation = d3
      .forceSimulation()
      .force(
        "link",
        d3
          .forceLink()
          .id(function (d) {
            return d.id;
          })
          .strength(function (link) {
            if (link.source.group === link.source.target) {
              return 1;
            } else {
              return 0.1;
            }
          })
      )
      .force("collide", d3.forceCollide().radius(function(d) {
        return 50; // to ensure nodes doesn't overlap each other.
       }))
      .force("charge", d3.forceManyBody().strength(-100))
      .force("center", d3.forceCenter(width / 2, height / 2));

    var link = svg
      .append("g")
      .attr("class", "links")
      .selectAll("line")
      .data(graph.links)
      .enter()
      .append("line")
      // .attr("stroke-width", function (d) {
      //   return d.weight < 10 ? d.weight : 10;
      // });

    var node = svg
      .append("g")
      .attr("class", "nodes")
      .selectAll("circle")
      .data(graph.nodes)

      .enter()
      .append("circle")
      .attr("r", (d)=>{
        //  if(d.cDegree>15) 
        //  return d.cDegree
        //  else
        return scaleRadius(d[centralityGroup])})
        //return scaleRadius(d[centrality_degree])})
        //return ((d[centralityGroup] - minRadius)/ scalingFactor)+20})
        // if(d[centralityGroup] < 1)
        //   return Math.floor(d[centralityGroup]) + 10
        // else if(d[centralityGroup] > 1 && d[centralityGroup] < 10)
        //   return 10
        // else if(d[centralityGroup] > 30)
        // {
        //   if(d[centralityGroup] > 100)
        //   {
        //     return d[centralityGroup]/100 + 30
        //   }
        //   return 30
        // }
      //   else
         // return d[centralityGroup]/10})
      .attr("fill", function (d) {
        return color(d.value);
      })

      .call(
        d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      );

    // node.append("text").text(function (d) {
    //   return d.id;
    // });
    // node.append("text").text((node) => node.group)
    // .attr("class", "networkMap-font")
    // .style("font-family", "Arial")
    // .style("font-size", 15);

    // Labels for the nodes
    let labelNode = svg
      .append("g")
      .attr("class", "labelNodes")
      .selectAll("text.label")
      .data(graph.nodes)
      .enter()
      .append("text")
      .attr("text-anchor", "middle")
      .text((node) => node.value)
      //.text((node) => node.group)
      .classed("networkMap-font", true)
      .style("font-family", "Arial")
      .style("font-size", 15)
      .style("pointer-events", "none"); // to prevent mouseover/drag capture

    //Initialize legend
    var legendItemSize = 12;
    var legendSpacing = 50;
    var xOffset = 10;
    var yOffset = 10;
    // Get Unique legend values
    let nodesgroup = [...new Set(graph.nodes.map((node) => node.value))];

    svgE2
    .append("text")
    .attr("x", xOffset)
    .attr("y", yOffset)
    .text("Legend")
    .style("font-weight", "bold");

    var legend = svgE2
      .append("svg")
      .attr("width", legendRef.current.clientWidth)
      .attr("height", legendRef.current.clientHeight)
      //.select("#legend")
      .selectAll(".legendItem")
      .data(nodesgroup);

    //Create legend items
    legend
      .enter()
      .append("rect")
      .attr("class", "legendItem")
      .attr("width", legendItemSize)
      .attr("height", legendItemSize)
      .attr("fill", function (d) {
        return color(d);
      })
      .attr("transform", (d, i) => {
        var x = xOffset;
        var y = yOffset + (legendItemSize + legendSpacing) * i/2;
        // var x = xOffset + (legendItemSize + legendSpacing) * i;
        // var y = yOffset;
        return `translate(${x}, ${y})`;
      });

    legend
      .enter()
      .append("text")
      .attr("x", xOffset + legendItemSize + 5)
      .attr("y", (d, i) => yOffset + (legendItemSize + legendSpacing) * i/2 + 12)
      // .attr("x", (d, i) => xOffset + (legendItemSize + legendSpacing) * i)
      // .attr("y", (d, i) => yOffset + legendItemSize + 20)
      .text((d) => d);
    simulation.nodes(graph.nodes).on("tick", ticked);

    simulation.force("link").links(graph.links).distance(200);

    // append voronoi
    var cells = svg
      .selectAll()
      .data(simulation.nodes())
      .enter()
      .append("g")
      .attr("fill", function (d) {
        return color(d.group);
      })
      .attr("class", function (d) {
        return d.group;
      });

    var cell = cells.append("path").data(voronoi.polygons(simulation.nodes()));
    function ticked() {
      var alpha = this.alpha();

      var coords = {};
      var groups = [];

      // // sort the nodes into groups:

      node.each(function (d) {
        if (groups.indexOf(d.group) === -1) {
          groups.push(d.group);
          coords[d.group] = [];
        }

        coords[d.group].push({ x: d.x, y: d.y });
      });

      // get the centroid of each group:
      var centroids = {};

      for (var group in coords) {
        var groupNodes = coords[group];

        var n = groupNodes.length;
        var cx = 0;
        var tx = 0;
        var cy = 0;
        var ty = 0;

        groupNodes.forEach(function (d) {
          tx += d.x;
          ty += d.y;
        });

        cx = tx / n;
        cy = ty / n;

        centroids[group] = { x: cx, y: cy };
      }

      // don't modify points close the the group centroid:
      var minDistance = 20;

      if (alpha < 0.1) {
        minDistance = 10 + 3000 * (0.2 - alpha);// change this to increase the distance between nodes
      }

      if (isGrouped) {
        // adjust each point if needed towards group centroid:
        node.each(function (d) {
          var cx = centroids[d.group].x;
          var cy = centroids[d.group].y;
          var x = d.x;
          var y = d.y;
          var dx = cx - x;
          var dy = cy - y;

          var r = Math.sqrt(dx * dx + dy * dy);

          if (r > minDistance) {
            d.x = x * 0.9 + cx * 0.1;
            d.y = y * 0.9 + cy * 0.1;
          }
        });
      } else {
        node.attr("cx", (node) => node["x"]).attr("cy", (node) => node["y"]);
      }

      if (bgColor) {
        // update voronoi:
        cell = cell
          .data(voronoi.polygons(simulation.nodes()))
          .attr("d", renderCell);
      }

      // update links:
      link
        .attr("x1", function (d) {
          return d.source.x;
        })
        .attr("y1", function (d) {
          return d.source.y;
        })
        .attr("x2", function (d) {
          return d.target.x;
        })
        .attr("y2", function (d) {
          return d.target.y;
        });

      // update nodes:
      node
        .attr("cx", function (d) {
          return d.x;
        })
        .attr("cy", function (d) {
          return d.y;
        });

      // Should be calculated based of collision detection
      labelNode
        .attr("x", (node) => node["x"])
        .attr("y", (node) => node["y"] + 40);
    }

    // drag nodes
    function dragstarted(d) {
      if (!d3.event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function dragended(d) {
      if (!d3.event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }
  };

  function renderCell(d) {
    return d == null ? null : "M" + d.join("L") + "Z";
  }
  useEffect(() => {
    if (graph && Object.keys(graph).length > 0) createSVG();
  }, [graph, bgColor]);

  return (
    <>
      <div style={{ margin: "20px 0 20px 0", width: "100%", height: "80%" }}>
        <FiltersforDemo2
          open={open}
          onClose={onClose}
          onChange={onFiltersChange}
          setIsGrouped={setIsGrouped}
        />

        <Button id="zoom_in"> Zoom In</Button>
        <Button id="zoom_out"> Zoom Out</Button>
        <Button id="reset"> Reset</Button>
        <Button id="filters" onClick={showDrawer}>
          {" "}
          Filters
        </Button>
        {bgColor && (
          <Button id="hideColor" onClick={(e) => setBgColor(false)}>
            {" "}
            Hide BG Color
          </Button>
        )}
        {!bgColor && (
          <Button id="hideColor" onClick={(e) => setBgColor(true)}>
            {" "}
            Show BG Color
          </Button>
        )}
        <div className="tooltip" ref={tooltipRef} />
        <Row>
          <Col span={20}>
            <div
              ref={ref}
              style={{
                margin: "20px 0 20px 0",
                width: "90%",
                minHeight: "800px",
              }}
            ></div>
          </Col>
          <Col span={4}>
            {" "}
            <div
              id="legend"
              ref={legendRef}
              style={{ minHeight: "1000px" }}
            ></div>
          </Col>
        </Row>
      </div>
    </>
  );
};
