import React, { createContext, useState } from 'react';

export const DashboardContext = createContext();


// This context is used to preserve forum id and title in global scope when user choose a forum
const DashboardContextProvider = (props) => {

  const [ dashboardConfig, setDashboardConfig ] = useState({});

  return (
    <DashboardContext.Provider value={{ dashboardConfig, setDashboardConfig }}>
      { props.children }
    </DashboardContext.Provider>
  );
}

export default DashboardContextProvider;