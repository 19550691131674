import {
  FETCH_GRAPH_LOADING,
  FETCH_GRAPH_SUCCESS,
  FETCH_GRAPH_FAILURE,
  FETCH_CONNECTED_LOADING,
  FETCH_CONNECTED_SUCCESS,
  FETCH_CONNECTED_FAILURE,
} from '../actions/knowledge-graph';

const initialState = {
  data: {},
  error: null,
  loaded: false,
  loading: true,
};

function graph(state = initialState, action) {
  switch(action.type) {
    case FETCH_GRAPH_LOADING:
    case FETCH_CONNECTED_LOADING:
        return {
        ...state,
        loading: true,
      };

    case FETCH_GRAPH_SUCCESS:
    case FETCH_CONNECTED_SUCCESS:
        return {
        data: action.payload,
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_GRAPH_FAILURE:
    case FETCH_CONNECTED_FAILURE:
        return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };

    default:
      return state;
  }
};

export {
  graph
};
