import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import ClusterChart from '../../charts/ClusterChart';
import { ReportImgContext } from '../../context/report-context';

function ClusterCard({ data }) {

  const classes = useStyles();

  return (
    <div className={classes.centerContent}>
      <ClusterChart data={data} id="cluster" />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonLine: {
    flex: 0.5,
    textAlign: 'right',
  },
  centerContent: {
    flex: 3,
    textAlign: 'center',
    width: '100%',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  mainButton: {
    color: '#516e88',
    display: 'inline-block',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    height: '36px',
    letterSpacing: 1.25,
    lineHeight: 1.12,
    width: '153px',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
  },
}));

export default ClusterCard;