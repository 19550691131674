import md5 from 'crypto-js/md5';
import { CacheLayerActions } from '../../actions/cache_layer';

export default async (dispatch, chartType, clientId, forumId, date_range, props) => {
  if (!props) {
    return {};
  }

  let _title = props.title;
  let _forumId = props.forumId ? props.forumId : (props.useContextRules !== false) ? forumId : 'all';
  _forumId = (_forumId == 'all') ? '' : _forumId;

  let _dateRange = (props.startDate) ? {
    startDate: props.startDate,
    endDate: props.endDate
  } : date_range.data;

  let _chartParams = (props.chartId) ? await dispatch(CacheLayerActions.getData(props.chartId + '-params', true)) : null;
  _chartParams = _chartParams || {
    title: _title,
    chartType: chartType,
    forumId: _forumId,
    clientId: clientId,
    dateRange: _dateRange,
    filters: props.filters || {}
  };

  const urlParams = new URLSearchParams(window.location.search);
  const client_id = urlParams.get('client_id');
  if (client_id !== null) {
    clientId = client_id;
  }

  const forum_id = urlParams.get('forum_id');
  if (forum_id !== null) {
    _forumId = forum_id;
  }

  const startDate = urlParams.get('startDate');
  if (startDate !== null) {
    _dateRange.startDate = startDate;
  }

  const endDate = urlParams.get('endDate');
  if (endDate !== null) {
    _dateRange.endDate = endDate;
  }

  const title = urlParams.get('title');
  if (title !== null) {
    _title = title;
  }

  let _chartId = props.chartId || md5(JSON.stringify(_chartParams)).toString();

  return {
    useCache: props.chartId !== undefined,
    title: _title,
    chartType: _chartParams.chartType,
    chartId: _chartId,
    clientId: clientId,
    forumId: _forumId,
    dateRange: _dateRange,
    filters: _chartParams.filters
  };
};