import { gql } from '@apollo/client';

import { setupApi } from '../graphql/client';

export const FETCH_EMOJI_LABELS_LOADING = 'FETCH_EMOJI_LABELS_LOADING';
export const FETCH_EMOJI_LABELS_SUCCESS = 'FETCH_EMOJI_LABELS_SUCCESS';
export const FETCH_EMOJI_LABELS_FAILURE = 'FETCH_EMOJI_LABELS_FAILURE';

const client = setupApi();

function fetchEmojiLabelsLoading() {
  return {
    type: FETCH_EMOJI_LABELS_LOADING,
  };
}

function fetchEmojiLabelsSuccess(payload) {
  return {
    type: FETCH_EMOJI_LABELS_SUCCESS,
    payload,
  };
}

function fetchEmojiLabelsFailure(payload) {
  return {
    type: FETCH_EMOJI_LABELS_FAILURE,
    payload,
  }
}

export function fetchEmojiLabels(forum_id, client_id, startDate = '', endDate = '') {
  return async (dispatch) => {
    try {
      dispatch(fetchEmojiLabelsLoading());
      forum_id = forum_id == '' ? undefined : forum_id;
      const forumParam = forum_id ? '$forum_id: String!' : '';
      const forumWhereParam = forum_id ? 'forum_id: $forum_id' : '';

      const dateTimeParam = startDate != '' && endDate != '' ? '$startDate: DateTime!\n$endDate: DateTime!' : '';
      const dateTimeWhereParam  = startDate != '' && endDate != '' ? 'date_filter: { create_datetime_GTE: $startDate, create_datetime_LTE: $endDate }' : '';
      const dateTimeSubWhereParam  = startDate != '' && endDate != '' ? '(date_filter: { create_datetime_GTE: $startDate, create_datetime_LTE: $endDate })' : '';

      const preppedQuery = `query GetPlutchikCategory(
        $client_id: String!
        ${forumParam}
        ${dateTimeParam}
      ) {
        plutchik_category_summary(
          client_id: $client_id
          ${forumWhereParam}
          ${dateTimeWhereParam}
        ) {
          plutchik_category
          comment_count
          cropped_count
        }
      }`;

      console.log('gqlQuery', preppedQuery);
      const query = {
        query:  gql`${preppedQuery}`,
        variables: {
          forum_id,
          client_id,
          startDate,
          endDate
        }
      };
      console.log('gqlQuery', JSON.stringify(query.variables, null, 2));

      const res = await client.query(query);

      return dispatch(fetchEmojiLabelsSuccess(res.data.plutchik_category_summary));

    } catch (error) {
      console.error(error);
      dispatch(fetchEmojiLabelsFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }));
    }
  }
}
