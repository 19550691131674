import React, { useContext, useEffect, useState, useRef } from "react";

import { useDispatch } from "react-redux";
import Graph from "./Graph";
import Test from "./Test";
import Demo2 from "./Demo2";
import NetworkGraph from "./NetworkGraph";
import NetworkGraph1 from "./NetworkGraph1";

import "./style.css";
import { getNetworkMap } from "../../actions/network_map";

export default ({ children }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  
  // useEffect(() => {
  //   (async () => {
  //     let result = await dispatch(getNetworkMap());
  //     let outgoingLinks = result.payload.users.map((val) =>
  //       val.user_communicationsConnection.edges.map((e) => ({
  //         source: val.uid,
  //         target: e.node.uid
  //       }))
  //     ).flat();
  //     let incomingLinks = result.payload.users.map((val) =>
  //     val.incoming_communicationsConnection.edges.map((e) => ({
  //       source: val.uid,
  //       target: e.node.uid
  //     }))
  //   ).flat();
  //   let linksArrayTemp = incomingLinks.concat(outgoingLinks)
  //   let linksArray = [];
  //   linksArrayTemp.forEach(function (item) {
  //     if (!linksArray.some(
  //       (i) => (i.source === item.source && i.target === item.target) ||
  //         (i.source === item.target && i.target === item.source)
  //     )) {
  //       linksArray.push(item);
  //     }
  //   });
  //     let nodesArray = result.payload.users.map((val) =>({
  //       id: val.uid,
  //       client_id: val.client_id,
  //       connectionNode: val.user_communicationsConnection.edges,
  //       color: "#ec9b41", 
  //       name: "Name",
  //       icon: "\u2927",
  //       demographics: val.demographics,
  //       // group: Math.floor((Math.random() * 7) + 1)
  //       group: "",
  //       cDegree: val.centrality_degree,
  //       cPagerank: val.centrality_pagerank,
  //       cArticlerank: val.centrality_articlerank,
  //       cBetweenness: val.centrality_betweenness,
  //       cEigenvector:val.centrality_eigenvector,
  //       cCloseness:val.centrality_closeness
  //     }))

  //     let missingNodesId = linksArray.filter((val) => !nodesArray.some((node) => node.id === val.target)).map(x => x.target)
      
  //     const missingNodes = result.payload.users.map(user => user.user_communicationsConnection.edges)
  //     .flat()
  //     .filter(edge => missingNodesId.includes(edge.node.uid)).map((data)=>({
  //       id: data.node.uid,
  //       client_id: data.node.client_id,
  //       connectionNode: "node",
  //       color: "#ec9b41", 
  //       name: "Name",
  //       icon: "\u2927",
  //       demographics: data.node.demographics,
  //       group: "",
  //       cDegree: data.node.centrality_degree,
  //       cPagerank: data.node.centrality_pagerank,
  //       cArticlerank: data.node.centrality_articlerank,
  //       cBetweenness: data.node.centrality_betweenness,
  //       cEigenvector:data.node.centrality_eigenvector,
  //       cCloseness:data.node.centrality_closeness,
  //       // firstCommunicationDate:data.first_communication_date,
  //       // lastCommunicationDate:data.last_communication_date,
  //     }))
  //     // console.log(missingNodesTemp);
  //     // let missingNodes = linksArray.filter((val) => !nodesArray.some((node) => node.id === val.target)).map((node)=>({
  //     //   id: node.target,
  //     //   client_id: "",
  //     //   connectionNode: "",
  //     //   color: "#ec9b41", 
  //     //   name: "Name",
  //     //   icon: "\u2927",
  //     //   demographics: node.demographics,
  //     //  // group: Math.floor((Math.random() * 7) + 1)
  //     //  group: ""
  //     // }))
      
  //     // let n = nodesArray.concat(missingNodes);
  //     let n = nodesArray.concat(missingNodes);
  //     const uniqueNodes = Array.from(new Set(n.map(x => x.id))).map(id => {
  //       return n.find(x => x.id === id);
  //     });

  //     let graphData ={
  //       nodes: uniqueNodes,
  //       links: linksArray
  //     }
    
  //     setData(graphData);
  //   })();
  // }, []);

  return (
    <>
      {/* <Graph data={data}></Graph> */}
      {/* <Test graphData={data}></Test> */}
      {/* <Demo2 graphData={data}></Demo2> */}
      <NetworkGraph></NetworkGraph>
      {/* <NetworkGraph1></NetworkGraph1> */}
    </>
  );
};
