import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl"; 
import * as moment from "moment";
import _ from "lodash";
import {
  Button,
  Tooltip,
  Modal,
  Form,
  Input,
  Tabs,
  Card,
  Tag,
  Col,
  Divider,
  Row,
  notification,
} from "antd";
import { SentimentToEmojiFace } from "../../components/SentimentToEmojiFace";

import {
  AreaChartOutlined,
  PieChartOutlined,
  BarChartOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
} from "@ant-design/icons";

import StrategyMapDataSourceCreatorBlock from "../StrategyMapDataSourceCreatorBlock";
import {openNotification} from '../../utils/Notifications'
import {
  getCultureMap,
  createCultureMap,
  updateCultureMap,
  deleteCultureMap,
  deleteCulturePhrases,
} from "../../actions/strategy_map";
import DataTable from "./DataTable";
import { useHistory, useLocation } from "react-router-dom";
const { TabPane } = Tabs;
const { confirm } = Modal;

const StrategyMapPage = ({ forumDataSources }) => {
  const dispatch = useDispatch();
  const moodColorChart = {
    1: "#ed2f2f",
    1.1: "#ef3b2d",
    1.2: "#f1462b",
    1.3: "#f25029",
    1.4: "#f45927",
    1.5: "#f56226",
    1.6: "#f66a25",
    1.7: "#f77224",
    1.8: "#f87a23",
    1.9: "#f98223",
    2: "#F98924",
    2.1: "#fb9222",
    2.2: "#fd9c20",
    2.3: "#ffa51f",
    2.4: "#ffae1f",
    2.5: "#ffb720",
    2.6: "#ffc122",
    2.7: "#ffca26",
    2.8: "#ffd32a",
    2.9: "#ffdd30",
    3: "#ffe636",
    3.1: "#f2e233",
    3.2: "#e6dd30",
    3.3: "#dad82e",
    3.4: "#ced42c",
    3.5: "#c2cf2a",
    3.6: "#b6ca29",
    3.7: "#abc529",
    3.8: "#9fc028",
    3.9: "#94bb28",
    4: "#89B628",
    4.1: "#81b428",
    4.2: "#78b128",
    4.3: "#70af29",
    4.4: "#67ac2a",
    4.5: "#5fa92a",
    4.6: "#55a72b",
    4.7: "#4ca42c",
    4.8: "#41a12e",
    4.9: "#369f2f",
    5: "#289c30",
  };
  const [editingRow, setEditingRow] = useState(null);
  const [form] = Form.useForm();
  const [activeKey, setActiveKey] = useState(0);
  const [panes, setPanes] = useState([]);
  const [addNewRow, setAddNewRow] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [editingPhrase, setEditingPhrase] = useState("");
  const user = useSelector((state) => state.user);
  const client_id = user.client_id;
  const intl = useIntl();
  const phrasePlaceholder = intl.formatMessage({id: 'app.strategyMap.phrases',defaultMessage: 'Phrases'});
  const actionsPlaceholder = intl.formatMessage({id: 'app.strategyMap.actions',defaultMessage: 'Actions'});
  const analysisPlaceholder = intl.formatMessage({id: 'app.strategyMap.analysis',defaultMessage: 'Analysis'});

  const dataSourcePlaceholder = intl.formatMessage({id: 'app.strategyMap.dataSource',defaultMessage: 'Data Source'})
  const startDatePlaceholder = intl.formatMessage({id: 'app.strategyMap.startDate',defaultMessage: 'Start Date'})
  const endDatePlaceholder = intl.formatMessage({id: 'app.strategyMap.endDate',defaultMessage: 'End Date'})
  const phraseDeletePlaceholder = intl.formatMessage({id: 'app.strategyMap.phraseDeleteConfirmation',defaultMessage: 'Are you sure you want to delete this phrase?'})
  const tabDeletePlaceholder = intl.formatMessage({id: 'app.strategyMap.tabDeleteConfirmation',defaultMessage: 'Are you sure you want to delete this culture map and all phrases?'})
  const avgSentimentPlaceholder = intl.formatMessage({id: 'app.strategyMap.avgSentiment',defaultMessage: 'Average Sentiment: '})
  const totalDocPlaceholder = intl.formatMessage({id: 'app.strategyMap.totalDocs',defaultMessage: 'Total Documents: '})
  const noDataPlaceholder = intl.formatMessage({id: 'app.strategyMap.noData',defaultMessage: 'No data available'})
  const addNewElementPlaceholder= intl.formatMessage({id: 'app.strategyMap.addNewElement',defaultMessage: 'Add New Element'})
  const editPlaceholder = intl.formatMessage({id: 'app.edit',defaultMessage: 'Edit'})
  const deletePlaceholder= intl.formatMessage({id: 'app.delete',defaultMessage: 'Delete'})
  const savePlaceholder = intl.formatMessage({id: 'app.save',defaultMessage: 'Save'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  const okPlaceholder = intl.formatMessage({id: 'app.ok',defaultMessage: 'Ok'});

  useEffect(() => {
    (async () => {
      let result = await dispatch(getCultureMap(client_id));
      console.log("Strategy Map data=>", result);
      if (result && result.payload.cultureMaps.length != 0) {
        const cultureMapPanes = result.payload.cultureMaps.map(
          (item, index) => ({
            forum_id: item.forum_id,
            uid: item.uid,
            text: item.text,
            key: index,
            culturePhrases: item.culturePhrases,
            startDate: moment(item.start_date).format("DD/MM/YYYY"),
            endDate: item.end_date? moment(item.end_date).format("DD/MM/YYYY"):  moment().format("DD/MM/YYYY"),
            table_dim1: item.table_dim1,
            table_dim2: item.table_dim2,
          })
        );
        setPanes(cultureMapPanes);
        setActiveKey(cultureMapPanes[0].key);
      }
    })();
  }, []);

  const handleAddRow = (cultureMap) => {
    console.log({ cultureMap });
    const newCultureMapPhrase = {
      text: "",
      status: 0,
    };
    const index = cultureMap.culturePhrases.length;
    const updatedPhrases = [...cultureMap.culturePhrases, newCultureMapPhrase];

    const key = cultureMap.key;

    setPanes((panes) => {
      const copy = [...panes];
      copy[key].culturePhrases = updatedPhrases ? updatedPhrases : [];
      console.log(copy);
      return copy;
    });
    setAddNewRow(true);
    setEditingRow(index);
  };

  const deletePhrase = (phraseId, idx) => {
    const updatedDataSource = _.cloneDeep([...panes]);
    updatedDataSource[activeKey].culturePhrases.splice(idx, 1);
    setPanes(updatedDataSource);

    const phraseData = {
      uid: phraseId,
      client_id: client_id,
    };
    deleteCultureMapPhrase(phraseData);
    setEditingRow(null);
  };

  const handleRemoveSpecificRow = (phrase, idx) => {
    confirm({
      title: phraseDeletePlaceholder,
      icon: <ExclamationCircleOutlined />,
      content: null,
      onOk: () => {
        deletePhrase(phrase.uid, idx);
      },
      onCancel() {},
      okText:okPlaceholder,
      cancelText:cancelPlaceholder
    });
  };

  const onSavePhrase = (text, idx) => {
    const updatedDataSource = _.cloneDeep([...panes]);
    let isTextExist = false;
    updatedDataSource[activeKey].culturePhrases.map((phrase,i) =>{
      if(phrase.text === text.trim())
      {
        isTextExist = true;
      }
    })
    if(!isTextExist)
    {
      if (!addNewRow) {
        const phraseID =
          updatedDataSource[activeKey].culturePhrases[editingRow].uid;
        setAddNewRow(false);
        deletePhrase(phraseID, idx);
      }
      updatedDataSource[activeKey].culturePhrases[editingRow].text = text.trim();
      updatedDataSource[activeKey].culturePhrases[editingRow].status = 0;
  
      setPanes(updatedDataSource);
      const phraseData = {
        cultureMapUid: updatedDataSource[activeKey].uid,
        client_id: client_id,
        forum_id: updatedDataSource[activeKey].forum_id,
        text: text,
        status: 0,
      };
      updateCultureMapPhrase(phraseData);
      setEditingRow(null);
      setEditingPhrase("");
    }
    else{
      openNotification("The phrase already exists","Please enter a different phrase","error");
    }
  };

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      // add();
    } else {
      remove(targetKey);
    }
  };

  const addConfig = async (data) => {
    const newActiveKey = panes.length;
    const newPanes = [...panes];
    const cultureMapId = await createCultureMapPane(data);
    newPanes.push({
      culturePhrases: [],
      forum_id: data.dataSourceId,
      key: newActiveKey,
      text: data.text,
      uid: cultureMapId,
      startDate: moment(data.startDate).format("DD/MM/YYYY"),
      endDate: data.endDate? moment(data.endDate).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY"),
      table_dim1: data.table_dim1,
      table_dim2: data.table_dim2,
    });
    setPanes(newPanes);
    setActiveKey(newActiveKey);
    setShowConfig(false);
  };

  const createCultureMapPane = async (data) => {
    let response = await dispatch(
      createCultureMap({
        client_id: client_id,
        forum_id: data.dataSourceId,
        text: data.text,
        start_date: data.startDate,
        end_date: data.endDate? data.endDate: null,
        table_dim1: data.table_dim1,
        table_dim2: data.table_dim2,
      })
    );
    return response.payload[0].uid;
  };

  const updateCultureMapPhrase = async (data) => {
    let result = await dispatch(
      updateCultureMap({
        uid: data.cultureMapUid,
        forum_id: data.forum_id,
        client_id: data.client_id,
        text: data.text,
      })
    );
  };
  const deleteCultureMapPhrase = async (data) => {
    let result = await dispatch(
      deleteCulturePhrases({
        uid: data.uid,
        client_id: data.client_id,
      })
    );
  };
  const remove = (key) => {
    confirm({
      title: tabDeletePlaceholder,
      icon: <ExclamationCircleOutlined />,
      content: null,
      onOk() {
        deletePane(key);
      },
      onCancel() {},
      okText:okPlaceholder,
      cancelText:cancelPlaceholder,
    });
  };

  const deletePane = (key) => {
    const targetKey = parseInt(key);
    let newActiveKey = activeKey;
    let lastIndex = -1;
    panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const deletedPane = panes.filter((pane) => pane.key === targetKey);
    const newPanes = panes.filter((pane) => pane.key !== targetKey);

    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    newPanes.forEach((pane, i) => {
      pane.key = i;
    });
    setPanes(newPanes);
    setActiveKey(newActiveKey);
    deleteCultureMapPane(...deletedPane);
  };

  const deleteCultureMapPane = async (data) => {
    let result = await dispatch(
      deleteCultureMap({
        uid: data.uid,
        client_id: client_id,
      })
    );
  };
  const addNewPane = () => {
    setShowConfig(true);
    console.log(showConfig);
  };
  const drawerOnClose = () => {
    setShowConfig(false);
  };

  const onTabChange = (key) => {
    
    if(editingRow !== null)
    {
      cancelEditing(editingRow);
    }
    setEditingRow(null);
    setActiveKey(key);
  };

  const cancelEditing = (idx) => {
    if(addNewRow)
    {
    const updatedDataSource = _.cloneDeep([...panes]);
    updatedDataSource[activeKey].culturePhrases.splice(idx, 1);
    setPanes(updatedDataSource);
    }
    setAddNewRow(false);
    setEditingRow(null);
    setEditingPhrase("");
  };

  const getTabToolTip = (id, startDate, endDate) => {
    if (id === "all") {
      return (
        <div>
          <p>{dataSourcePlaceholder} All</p>
          <p>{startDatePlaceholder} {startDate}</p>
          <p> {endDatePlaceholder} {endDate}</p>
        </div>
      );
    } else {
      let dataSource = forumDataSources.find((x) => x.id === id);
      let title = "";
      if (dataSource) title = dataSource.title;
      return (
        <div>
          <p>{dataSourcePlaceholder} {title}</p>
          <p>{startDatePlaceholder} {startDate}</p>
          <p> {endDatePlaceholder} {endDate}</p>
        </div>
      );
    }
  };
  const columns = [
    {
      title: phrasePlaceholder,
      dataIndex: "text",
      width: "50%",
      render: (text, record, index) => {
        if (editingRow === index) {
          return (
            <Input value={editingPhrase} onChange={(e) => {
              setEditingPhrase(e.target.value);
            }}/>

          );
        } else {
          return <p style={{ fontSize: "14px", fontWeight: "450" }}>{text}</p>;
        }
      },
    },
    {
      title: actionsPlaceholder,
      render: (_, record, index) => {
        return (
          <>
            {editingRow !== index && (
              <>
                {record.status > 0  && (
                  <>
                    <Tooltip placement="topLeft" title={editPlaceholder}>
                      <EditOutlined
                        onClick={() => {
                          setEditingRow(index);
                          // form.setFieldsValue({
                          //   text: record.text,
                          // });
                        }}
                      />
                    </Tooltip>
                    <Tooltip placement="topLeft" title={deletePlaceholder}>
                      <DeleteOutlined
                        style={{ color: "red", marginLeft: 12 }}
                        onClick={() => handleRemoveSpecificRow(record, index)}
                      />
                    </Tooltip>
                  </>
                )}
                {record.status === 0 && (
                  <Button type="primary" size="small" loading>
                    Processing
                  </Button>
                )}
              </>
            )}
            {editingRow === index && (
              <>
                <Button type="primary" onClick={() => onSavePhrase(editingPhrase,index)} >
                  {savePlaceholder}
                </Button>
                <Button onClick={() => cancelEditing(index)} style={{ marginLeft: "10px" }}>
                  {cancelPlaceholder}
                </Button>
              </>
            )}
          </>
        );
      },
    },
    {
      title: analysisPlaceholder,
      render: (_, record, index) => {
        return (
          <>
            {record.status > 0 && (
              <>
                <Row gutter={16} style={{minWidth:"300px"}}>
                  <Col span={8}>
                    <Tooltip
                      title={avgSentimentPlaceholder + record.avg_sentiment}
                    >
                      <div
                        style={{
                          height: "30px",
                          width: "30px",
                          borderRadius: "50%",
                          fontSize: "32px",
                          backgroundColor:
                            moodColorChart[
                              Math.round(record.avg_sentiment * 10) / 10
                            ],
                          paddingRight: "40px",
                        }}
                      ></div>
                    </Tooltip>
                  </Col>
                  <Col span={8}>
                    <Tooltip title={totalDocPlaceholder + record.chunk_count}>
                      <div
                        style={{
                          height: "30px",
                          fontSize: "20px",
                          textAlign: "center",
                          // borderStyle: "solid",
                          borderBottom:
                            "3px solid" +
                            moodColorChart[
                              Math.round(record.avg_sentiment * 10) / 10
                            ],
                        }}
                      >
                        {" "}
                        <p style={{ paddingBottom: "10px" }}>
                          {" "}
                          {record.chunk_count}
                        </p>{" "}
                      </div>
                    </Tooltip>
                  </Col>
                </Row>
              </>
            )}

            {record.status === 0 && (
              <>
                <p>{noDataPlaceholder}</p>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {
        <div style={{ margin: "20px 0 20px 0" }}>
          {
            <StrategyMapDataSourceCreatorBlock
              panes={panes}
              addConfig={addConfig}
              forumDataSources={forumDataSources}
              drawerOnClose={drawerOnClose}
              showConfig={showConfig}
            />
          }
          <div style={{ marginBottom: 16 }}>
            <Button type="primary"  
            style={{ borderRadius: "8px", minWidth:"140px" }}
            onClick={addNewPane}>
              <FormattedMessage
                id="app.strategyMap.addNewConfig"
                defaultMessage="Add New Config"
              />
            </Button>
          </div>
          {panes.length !== 0 && (
            <Tabs
              hideAdd
              type="editable-card"
              onEdit={onEdit}
              defaultActiveKey="0"
              onChange={onTabChange}
            >
              {panes.map((pane) => (
                <TabPane
                  tab={
                    <Tooltip
                      title={getTabToolTip(
                        pane.forum_id,
                        pane.startDate,
                        pane.endDate
                      )}
                    >
                      <span>{pane.text}</span>
                    </Tooltip>
                  }
                  key={pane.key}
                  closable={pane.closable}
                >
                  <DataTable
                    dataSource={pane.culturePhrases}
                    columns={columns}
                    onSavePhrase={onSavePhrase}
                    forumId={pane.forum_id}
                    client_id={client_id}
                    table_dim1={pane.table_dim1}
                    table_dim2={pane.table_dim2}
                    startDate={pane.startDate}
                    endDate={pane.endDate}
                  />
                  {editingRow === null && (
                    <Button
                      onClick={() => handleAddRow(pane)}
                      className="btn btn-primary"
                      type="primary"  
                      style={{ borderRadius: "8px", minWidth:"140px", marginTop: "40px"}}
                    >
                     {addNewElementPlaceholder}
                    </Button>
                  )}
                </TabPane>
              ))}
            </Tabs>
          )}
          {panes.length === 0 && (
            <Card style={{ width: "100%", borderStyle: "dashed" }}>
              {noDataPlaceholder}
              <a onClick={addNewPane}>Configure</a> it now.
            </Card>
          )}
        </div>
      }
    </>
  );
};

export default StrategyMapPage;
