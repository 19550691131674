import {
  FETCH_DASHBOARD_LOADING,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  SAVE_DASHBOARD_LOADING,
  SAVE_DASHBOARD_SUCCESS,
  SAVE_DASHBOARD_FAILURE,
} from "../actions";

const initialState = {
  data: [],
  error: null,
  loaded: false,
  loading: false,
  saving: false,
};

export function dashboards(state = initialState, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SAVE_DASHBOARD_LOADING:
      return {
        ...state,
        saving: true,
      };

    case FETCH_DASHBOARD_SUCCESS:
    case SAVE_DASHBOARD_SUCCESS:
      return {
        data: action.payload,
        error: null,
        loaded: true,
        loading: false,
        saving: false,
      };

    case FETCH_DASHBOARD_FAILURE:
    case SAVE_DASHBOARD_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
        saving: false
      };

    default:
      return state;
  }
}