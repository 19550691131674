import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Space, Button } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { fetchQueryWithCustomDateRange } from '../actions/cube';
import MoodBarChart from '../charts/MoodBarChart';
import Loading from '../components/Loading';
import NoData from '../components/NoData';
import Widget from '../components/Widget';
import { commentCountByMonthDayAndSentiment } from '../queries';
import { ForumIdContext } from '../context/forumId-context';
import { DemographicsContext } from '../context/demographics-context';
import { transform } from '../transformers/utils';
import useInterval from '../components/useInterval';
import localPropUtil from './utils/localPropUtil';
import { fetchMoodTable } from '../actions/mood_table';
import { fetchMoodByDemographic } from '../actions/demographics';
import { CacheLayerActions } from '../actions/cache_layer';
import DataCompareTable from '../charts/DataCompareTable';
import { formatMoodTable } from '../transformers/comment_topics';
import UnitSelector from '../components/Selectors/UnitSelector';
import PageHeading from '../components/PageHeading';


function MoodTableWidget(props ) {

  // const { data, loaded } = useSelector((state) =>
  //   state.cube[commentCountByMonthDayAndSentiment.type]
  // );
  const [ localProps, setLocalProps ] = useState({});
  const [ data, setData ] = useState([]);
  const [ tdata, setTableData ] = useState([]);
  const [ forumUuid, setForumUuid ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ loaded, setLoaded ] = useState(false);
  const { forumId } = useContext(ForumIdContext);
  const { taxonomies } = useContext(DemographicsContext);
  const [ userComparisonChanged, setUserComparisonChanged ] = useState(false);
  const [ dim1, setDim1 ] = useState(null);
  const [ dim2, setDim2 ] = useState(null);
  const [ startDate, setStartDate] = useState("");
  const [ endDate, setEndDate] = useState("");
  const [ xLength, setXLength ] = useState(0);
  const [ yHeight, setYHeight ] = useState(600);
  const [ initialized, setInitialized ] = useState(false);

  const [ showPagination, setShowPagination ] = useState(false);
  const [ pageNum, setPageNum ] = useState(1);
  const [ pagedData, setPagedData ] = useState([]);
  const [ pagesTotal, setPagesTotal ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);

  const user = useSelector((state) => state.user);
  const date_range = useSelector((state) => state.date_range);
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultDim1 = 'Gender Identity';
  const defaultDim2 = 'age_group';

  useEffect(() => {
    (async () => {
      if(props.type && props.type ==="Table")
      {

        setDim1(props.dim1);
        setDim2(props.dim2);
        setStartDate(props.startDate);
        setEndDate(props.endDate);
        setLocalProps(props);
        setInitialized(true);
      }
      else
      {
        let _localProps = await localPropUtil(dispatch, 'mood-table', user.client_id, forumId, date_range, props);
        setLocalProps(_localProps);
        setForumUuid(_localProps.forumId);
        console.log('_localProps', _localProps);
        const filterMemoryKey = _localProps.chartId + '-filter-memory';
  
        let localDim1 = _localProps.filters.comparisonA ? _localProps.filters.comparisonA : defaultDim1;
        let localDim2 = _localProps.filters.comparisonB ? _localProps.filters.comparisonB : defaultDim2;
  
        if (userComparisonChanged) {
          localDim1 = dim1;
          localDim2 = dim2;
        } else {
          const rememberedFilters = await dispatch(CacheLayerActions.getData(filterMemoryKey));
          if (rememberedFilters) {
            localDim1 = rememberedFilters.comparisonA ? rememberedFilters.comparisonA : localDim1;
            localDim2 = rememberedFilters.comparisonB ? rememberedFilters.comparisonB : localDim2;
          }
        }
  
        localDim1 = localDim1 || defaultDim1;
        localDim2 = localDim2 || defaultDim2;
  
        setDim1(localDim1);
        setDim2(localDim2);
  
        setInitialized(true);
      }
    })();
  }, [ ]);

  useEffect(() => {
    if (!localProps) {
      return;
    }

    const filterMemoryKey = localProps.chartId + '-filter-memory';
    dispatch(CacheLayerActions.setData(filterMemoryKey, {
      comparisonA: dim1,
      comparisonB: dim2,
    }));
  }, [ dim1, dim2 ]);

  useEffect(() => {
    (async () => {
      if (!user || !initialized) {
        return;
      }

      setLoading(true);
      if(props.type === "Table")
      {
        const result = await dispatch(fetchMoodByDemographic({
          dim1: dim1,
          dim2: dim2,
          topic: props.topic,
          client_id: user.client_id,
          forum_id: props.forumId,
          date_range: date_range.data,
          startDate: props.startDate,
          endDate: props.endDate,
          uid: props.uid,
          sentimentType: props.subType
        }));
        if (result) {
          setData(result);
        }
      }
      else{
        const cacheData = (localProps.useCache) ? await dispatch(CacheLayerActions.getData(localProps.chartId)) : null;
        if (!localProps.useCache || !cacheData) {
          const result = await dispatch(fetchMoodByDemographic({
            dim1: dim1,
            dim2: dim2,
            topic: props.topic,
            client_id: user.client_id,
            forum_id: localProps.forumId,
            date_range: date_range.data,
            startDate: localProps.dateRange.startDate,
            endDate: localProps.dateRange.endDate
          }));
          if (result) {
            setData(result);
          }
        } else {
          setData(cacheData);
        }
      }
      setLoading(false);
    })();
  }, [ initialized, user, date_range, dim1, dim2, forumId, props.forumId, props.startDate, props.endDate ]);

  //TODO: Revisit this block- need to make seperate component 
 // check if row/col is starting with number, if yes, split based on ",_:- " and sort it
 const sortKeys = (a, b) => {
  const keyA = a.split(/[-_:, ]+/)[0];
  const keyB = b.split(/[-_:, ]+/)[0];
  //Check if one of the string is empty, put empty column at the end
  if (keyA === "" || keyA === null) return 1;
  if (keyB === "" || keyB === null) return -1;
  if (keyA === keyB) return 0;
   //check if string is type number  
  if (!isNaN(keyA) && !isNaN(keyB)) {
    return Number(keyA) < Number(keyB) ? -1 : 1;
  }
   //sort as a string if row/column is not type number
  return keyA.toUpperCase() < keyB.toUpperCase() ? -1 : 1;
};

  useEffect(() => {
    let _tableData = data.map((doc) => {
      return {
        ... doc,
        x: doc.dim1,
        y: doc.dim2,
        xTitle: doc.dim1,
        yTitle: doc.dim2,
        value: doc.chunk_count,
        avg_mood: doc.avg_sentiment
      };
    });

    console.group();
    console.log('localProps', localProps);
    console.log('_tableData', _tableData);

    const columns = _.uniq(data.map((doc) => doc.dim1));
    const rows = _.uniq(data.map((doc) => doc.dim2));
    const xLen = columns.length;
    const yLen = rows.length;
    const pagedColumns = columns.sort(sortKeys).slice((pageNum - 1) * pageSize, pageNum * pageSize);
    const _pagesTotal = Math.ceil(columns.length / pageSize);
    const rowAggregates = {};
    _tableData.forEach((doc) => {
      if (!rowAggregates[doc.dim2]) {
        rowAggregates[doc.dim2] = 0;
      }
      rowAggregates[doc.dim2] += doc.chunk_count;
    });
    _tableData = _tableData.map((doc) => {
      return {
        ... doc,
        ... {
          percentage: Math.round(((doc.chunk_count / rowAggregates[doc.dim2]) * 100) * 100) / 100
        }
      }
    });

    console.log('columns', columns);
    console.log('xLen', xLen);
    console.log('yLen', yLen);
    console.log('pagedColumns', pagedColumns);
    console.log('_pagesTotal', _pagesTotal);
    console.log('_tableData', _tableData);

    setShowPagination(columns.length > pageSize);
    setPagesTotal(_pagesTotal);
    setPagedData(_tableData.filter((doc) => pagedColumns.indexOf(doc.xTitle) > -1));
    setXLength(xLen);
    setLoaded(true);
    setYHeight(Math.max((yLen * 60), 250) + 'px');
    console.groupEnd();
  }, [ data, pageNum ]);

  useEffect(() => {
    setTableData(pagedData);
  }, [ pagedData ]);

  useEffect(() => {
    setPageNum(1);
  }, [ loading ]);

  const handleDimChange = (dimNumber) => (ev) => {
    setUserComparisonChanged(true);
    if (dimNumber === 1) {
      // setDim1(taxonomies[ev.target.value]);
      setDim1(taxonomies[ev]);
    } else {
      // setDim2(taxonomies[ev.target.value]);
      setDim2(taxonomies[ev]);
    }
  };

  const onPointClicked = (e) => {
    const item = e.target.column._dataItem._dataContext;
    //history.push('/explore/comments?from=' +  encodeURIComponent(location.pathname.slice(1)) + '&q=' + encodeURIComponent(topic));
    if(props.type === "Table")
    {

      let updatedValue = {};
      updatedValue = {
        client_id: user.client_id,
        forumId: props.forumId,
        uid: props.uid,
        startDate:props.startDate,
        endDate:props.endDate,
        comparisonType:"demographic",
        taxonomyA:dim1,
        taxonomyB: dim2,  
        comparisonA: (item.xTitle),
        comparisonB:(item.yTitle),
        subType: props.subType,
        subTitle: "Table View -> " + props.subType + " -> List View"
      };
      props.setMoodTableProps(updatedValue);
      props.nextStep();
   
    }
    else{
      history.push(
        '/explore/data-layer/'+ localProps.chartId + '/chunks?' + [
          'from=explore/data-layer/'+ localProps.chartId +'/mood-table/',
          'q=',
          'client_id=' + localProps.clientId,
          'forum_id=' +  localProps.forumId,
          'startDate=' + localProps.dateRange.startDate,
          'endDate=' + localProps.dateRange.endDate,
          'comparisonType=demographic',
          'taxonomyA=' + encodeURIComponent(dim1),
          'taxonomyB=' + encodeURIComponent(dim2),
          'comparisonA=' + encodeURIComponent(item.xTitle),
          'comparisonB=' + encodeURIComponent(item.yTitle),
        ].join('&'));
    }
  };

  const paginationButtons = () => {
    return (
      <div style={{ textAlign: 'right', marginTop: '10px', marginRight: '20px' }}>
        <Space size={ 2 }>
          <span>
            Page { pageNum } of { pagesTotal }
            &nbsp;&nbsp;
          </span>
          <Button size="small" onClick={ () => setPageNum(Math.max(1, pageNum - 1)) }>
            <CaretLeftOutlined />
          </Button>
          <Button size="small" onClick={ () => setPageNum(Math.min(pagesTotal, pageNum + 1)) }>
            <CaretRightOutlined />
          </Button>
        </Space>
      </div>
    );
  }

  return (
    <>
      {
        initialized &&
        !props.noWidget &&
          <Widget title={props.title ? props.title : "Mood table" } actions={ props.actions }>
          <UnitSelector
            options={taxonomies}
            dim1Index={taxonomies.indexOf(dim1)}
            dim2Index={taxonomies.indexOf(dim2)}
            handleDimChange={handleDimChange}
          />
          {!loading && loaded ? (tdata && tdata.length > 0 ?
            <>
              {
                showPagination &&
                paginationButtons()
              }
              <div style={{ height: yHeight }}>
                <DataCompareTable
                  data={tdata}
                  forumUuid={forumUuid}
                  topic={props.topic}
                  xLength={xLength}
                  onClick={onPointClicked}
                />
              </div>
            </>
            :
            <NoData />
          ):
            <Loading />
          }
        </Widget>
      }
      {
        initialized &&
        props.noWidget &&
        <>
          {
            !loading && loaded ?
              <>
                <PageHeading>{ localProps.title ? localProps.title : "Mood Table" }</PageHeading>
               {props.type === "Table" && <h5 style={{ color: '#607d8b',
          marginLeft: '5px',
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'"
    }}>Table View -&gt; {props.subType}</h5>}
                <UnitSelector
                  options={taxonomies}
                  dim1Index={taxonomies.indexOf(dim1)}
                  dim2Index={taxonomies.indexOf(dim2)}
                  handleDimChange={handleDimChange}
                />
                <>
                  {
                    showPagination &&
                    paginationButtons()
                  }
                  <div style={{ height: yHeight }}>
                    <DataCompareTable
                      data={tdata}
                      forumUuid={forumUuid}
                      topic={props.topic}
                      xLength={setXLength}
                      onClick={onPointClicked}
                    />
                  </div>
                </>
              </>
            :
              <Loading />
          }
        </>
      }
    </>
  );
}

export default MoodTableWidget;