import React from "react";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash.isempty';

import MapChart from "../../charts/MapChart";
import NoData from '../NoData';

function MapCard({ forumId, mapCardData }) {

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Participant locations
        </Typography>
        {isEmpty(mapCardData) ?
          <NoData />
          :
          <div className={classes.centerContent}>
            <MapChart mapCardData={mapCardData} id="mapCard" />
          </div>
        }
        <div className={classes.buttonLine}>
          <Link to={`/forums/${forumId}/participation/location`}>
            <Button
              className={classes.mainButton}
              color="primary"
              size="small"
              variant="outlined"
            >
              See breakdown
            </Button>
          </Link>
        </div>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles(() => ({
  buttonLine: {
    position: 'absolute',
    bottom: '12px',
    right: '20px',
    textAlign: 'right',
  },
  card: {
    height: '100%',
    padding: '4px',
  },
  cardContent: {
    height: '100%',
    position: 'relative',
  },
  centerContent: {
    height: '100%',
    paddingBottom: '52px',
    paddingTop: '12px',
    textAlign: 'center',
    width: '100%',
  },
  mainButton: {
    color: '#516e88',
    display: 'inline-block',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    height: '36px',
    letterSpacing: 1.25,
    lineHeight: 1.12,
    width: '172px',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
  },
}));

export default MapCard;