import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import PanToolIcon from '@material-ui/icons/PanTool';
import UpdateIcon from '@material-ui/icons/Update';
import { makeStyles } from '@material-ui/core/styles';

import { combineStyles } from '../utils';
import commonStyles from './common_styles';
import BookMark from '../components/BookMark';
import { useIntl } from "react-intl"; 

function ReportAnalyse({
  chunks,
  textAnalysis,
  index,
  section,
  onChange,
  onDelete,
  onSectionDown,
  onSectionUp,
}) {

  const titles = textAnalysis && [...new Set(textAnalysis.map(data => data.title))];
  const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
 // const [sectionTitle, setSectionTitle] = useState(section.content && JSON.parse(section.content).Title || titles[0]);
  const [sectionTitle, setSectionTitle] = useState("");
  const [isOpenUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectedAnalysedText,setSelectedAnalysedText] = useState(textAnalysis.filter(data => data.title === sectionTitle) || []);
  const intl = useIntl();
  const deletePlaceholder = intl.formatMessage({id: 'app.delete',defaultMessage: 'Delete'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  const activeSetPlaceholder = intl.formatMessage({id: 'app.reports.activeSet',defaultMessage: 'Active Set:'});
  const sortingPlaceholder = intl.formatMessage({id: 'app.reports.sorting',defaultMessage: 'Sorting:'});
  const sentimentPlaceholder = intl.formatMessage({id: 'app.reports.sentiment',defaultMessage: 'Average Sentiment:'});
  const chunksPlaceholder = intl.formatMessage({id: 'app.reports.chunks',defaultMessage: 'Chunks Count:'});
  const summaryPlaceholder =intl.formatMessage({id: 'app.reports.summary',defaultMessage: 'Summary:'});

  const classes = combinedStyles();

  useEffect(() => {
    if(section.content)
    {
      setSectionTitle(JSON.parse(section.content).Title);
    }
    else{
      setSectionTitle (titles[0]);
      const data = textAnalysis.filter(data => data.title === titles[0])[0];
      setSelectedAnalysedText(data);
      setTextAnalysis(data);
    } 
  }, []);

  const openDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const openUploadDialog = () => {
    setOpenUploadDialog(true);
  };

  const closeUploadDialog = () => {
    setOpenUploadDialog(false);
  };

  const handleDelete = () => {
    onDelete(index);
  };


  const handleSectionDown = () => {
    onSectionDown(index);
  };

  const handleSectionUp = () => {
    onSectionUp(index);
  };



  const setTextAnalysis = (data) => {
    if(data){
      onChange(index, {
        Title: data.title,
        ActiveSet : data.active_set,
        AvgSentiment: data.avg_sentiment,
        Sorting : data.sorting,
        ChunksLength : data.chunksId.length,
        //Summary: data.summarizedText,
        AbstractText: data.abstract_text,
        KeypointsText: data.keypoints_text
      });
      setOpenUploadDialog(false);
    }
  }

  const handleTitleChange = (event) => {
    setSectionTitle(event.target.value);
    const data = textAnalysis.filter(data => data.title === event.target.value)[0];
    setSelectedAnalysedText(data);
    setTextAnalysis(data);
  };

  

  console.log("chunks in ", sectionTitle,chunks.filter(chunk => chunk.title === sectionTitle));
  
  return (
    <div id={section.id} className={classes.sectionWrap}>
      <div className={classes.sectionGreyWrap}>
        <span className={classes.sectionChunkAnalysisTitle}>Chunks Summary</span>
        <div className={classes.topButtonArea}>
          <div id="drag"
            className={classes.buttonWrap}
          >
            <PanToolIcon className={classes.iconStyle} />
            <span>Drag</span>
          </div>
          <div className={classes.buttonWrap} onClick={handleSectionUp}>
            <ArrowUpwardIcon className={classes.iconStyle} />
            <span>Up</span>
          </div>
          <div className={classes.buttonWrap} onClick={handleSectionDown}>
            <ArrowDownwardIcon className={classes.iconStyle} />
            <span>Down</span>
          </div>
          <div className={classes.buttonWrap} onClick={openDeleteDialog}>
            <DeleteIcon className={classes.iconStyle} />
            <span>{deletePlaceholder}</span>
          </div>
          <Dialog
            open={isOpenDeleteDialog}
            onClose={closeDeleteDialog}
            aria-labelledby={'alert-dialog-title-' + index}
            aria-describedby={'alert-dialog-description-' + index}
          >
            <DialogTitle id={'alert-dialog-title-' + index}>
              Confirm delete
            </DialogTitle>
            <DialogContent>
              <DialogContentText id={'alert-dialog-description-' + index}>
                Are you sure you want to delete this section?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={closeDeleteDialog}>
                {cancelPlaceholder}
              </Button>
              <Button
                autoFocus
                color="primary"
                variant="contained"
                onClick={handleDelete}
              >
                {deletePlaceholder}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <div className={classes.chunkTextWrap}>
        {textAnalysis.length>0 ? textAnalysis.filter(data => data.title === sectionTitle).map(data => (
            <div key={data.id} className={classes.chunkTextWrap}>
              <Typography className={classes.textStyle} >
                <b>{activeSetPlaceholder} </b>{data.active_set}
              </Typography>
              <Typography className={classes.textStyle} >
              <b>{sortingPlaceholder} </b> {data.sorting}
              </Typography>
              <Typography className={classes.textStyle} >
              <b>{sentimentPlaceholder} </b>{data.avg_sentiment}
              </Typography>
              <Typography className={classes.textStyle} >
              <b>{chunksPlaceholder} </b>{data.chunksId ? data.chunksId.length: 0}
              </Typography>
              <Typography className={classes.textStyle} >
              <b>Abstract Summary </b>{data.abstract_text}
              </Typography>
              <Typography className={classes.textStyle} >
              <b>Keypoints Summary </b> {data.keypoints_text.split("\n").map((line, index) => (
                <span key={index}>{line}<br /></span>
              ))}
              </Typography>
              <Divider style={{ margin: '10px 0' }} />
            </div>
         )) : null}
      </div>
      
      <InputLabel shrink className={classes.bootstrapFormLabel}>
        Title
      </InputLabel>
      <Select
          fullWidth
          id="title-selector"
          label="ChunkTitle"
          value={sectionTitle}
          onChange={handleTitleChange}
        >
          {titles.map((title, index) => (
            <MenuItem key={index} value={title}>
              {title}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}

const styles = {
  buttonWrap: {
    backgroundColor: '#fff',
    borderRadius: '2px',
    display: 'flex',
    marginRight: '4px',
    padding: '2px 4px',
    textAlign: 'center',
  },
  divhover: {
    '&:hover': {
      background: '#efefef',
      border: 'solid #efefef',
    },
  },
  iconStyle: {
    fontSize: '18px',
    paddingRight: '2px',
    opacity: 0.6,
  },
  imageMenuItem: {
    cursor: 'pointer',
    width: '75%',
  },
  imageMenuWrap: {
    //textAlign: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  chunkTextWrap: {
    marginBottom: '15px',
    marginTop: '30px',
    padding: 0,
    position: 'relative',
    textAlign: 'left',
    width: '100%',
  },
  imageWrap: {
    marginBottom: '15px',
  },
  sectionGreyWrap: {
    padding: '10px',
    height: '100%',
  },
  sectionWrap: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    marginBottom: '15px',
    marginTop: '40px',
    padding: '10px 10px 0 10px',
    position: 'relative',
  },
  textFieldInputStyle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 1.41,
    overflowY: 'hidden',
  },
  textFieldWrap: {
    //marginTop: '15px',
    marginBottom: '15px',
    width: '100%',
  },
  topButtonArea: {
    color: '#516e88',
    display: 'flex',
    fontSize: '12.2px',
    position: 'absolute',
    top: '-12px',
    right: '10px',
    textAlign: 'center',
    zIndex: 2,
  },
  textStyle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 1.41,
    marginBottom: '12px',
  },
  markWrap: {
    alignItems: 'center',
    color: '#516e88',
    marginLeft: '1em',
    opacity: 0.38,
    width: '4%'
  },
  mainText: {
    width: '96%'
  },
  checkboxWrap: {
    width: '8%',
    margin: '0em auto 0.5em 0em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  checkbox: {
    color: '#8da4bc',
    padding: 0,
    '&$checked': {
      color: '#8da4bc',
    },
  },
  checked: {

  },
  bootstrapFormLabel: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.4,
  },
};

const combinedStyles = makeStyles(combineStyles(commonStyles, styles));

export default ReportAnalyse;