import {
  FETCH_MOOD_TABLE_LOADING,
  FETCH_MOOD_TABLE_SUCCESS,
  FETCH_MOOD_TABLE_FAILURE,
} from "../actions/mood_table";
import { formatMoodTable } from '../transformers/comment_topics';

const initialState = {
  data: {},
  error: null,
  loaded: false,
  loading: false,
};

export function moodTable(state = initialState, action) {
  switch (action.type) {
    case FETCH_MOOD_TABLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_MOOD_TABLE_SUCCESS:
      const { counts, dim1, dim1Data, dim2, dim2Data, topic } = action.payload;
      const dim1List = dim1Data.map((d) => d[dim1] || 'Unknown');
      const dim2List = dim2Data.map((d) => d[dim2] || 'Unknown');
      
      return {
        data: {
          dim1,
          dim1List,
          dim2,
          dim2List,
          tableData: formatMoodTable(counts, dim1List, dim2List, dim1, dim2),
          topic,
        },
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_MOOD_TABLE_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };

    default:
      return state;
  }
}

