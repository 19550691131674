import React, { useState, useEffect } from 'react';
import { Col } from 'antd';
import { Draggable } from "react-beautiful-dnd";
import TileBody from './TileBody';


export const getDraggableStyles = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // // change background colour if dragging
    // background: isDragging ? "lightgreen" : "inherit",

    // styles we need to apply on draggables
    ...draggableStyle
  };
};


const TileBlock = ({ children, uniqId, dataSource, index, direction, setItem, deleteItem, ... props }) => {

  const [ item, setItemValue ] = useState(null);

  useEffect(() => {
    setItemValue(dataSource);
  }, [ dataSource ]);

  const onChange = (value) => {
    setItemValue(value);
    setItem(value);
  };

  const onDelete = deleteItem;

  return !(item && uniqId && index !== undefined) ? <>
    </> : (
    <Draggable
      key={ uniqId }
      draggableId={ uniqId }
      index={ index }
      isDragDisabled={ false }
    >
      {
        (provided, snapshot) => (
          <>
            <Col
              span={ props.span || 8 }
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={
                {
                  ... getDraggableStyles(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  ),
                  ... ( props.draggableStyle || {})
                }
              }
            >
              {
                !props.renderDraggerOuterItem &&
                <TileBody
                  uniqId={ uniqId }
                  provided={ provided }
                  dataSource={ item }
                  index={ index }
                  children={ children }
                  onChange={ onChange }
                  onDelete={ onDelete }
                />
              }
              {
                props.renderDraggerOuterItem &&
                typeof props.renderDraggerOuterItem == 'function' &&
                props.renderDraggerOuterItem({
                  uniqId: uniqId,
                  item: item,
                  index: index,
                  parentId: uniqId,
                  blockEl: <TileBody
                              provided={ provided }
                              dataSource={ item }
                              index={ index }
                              children={ children }
                              onChange={ onChange }
                              onDelete={ onDelete }
                            />,
                  isDragging: snapshot.isDragging
                })
              }
            </Col>
          </>
        )
      }
    </Draggable>
  )
};

export default TileBlock;