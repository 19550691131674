import localforage from "localforage";

export const CACHE_SET_DATA = 'CACHE_SET_DATA';
export const CACHE_MERGE_DATA = 'CACHE_SET_DATA';
export const CACHE_DEL_DATA = 'CACHE_DEL_DATA';
export const CACHE_LS_CHANGED = 'CACHE_LS_CHANGED';

function cacheSetData(key, value) {
  return {
    type: CACHE_SET_DATA,
    payload: {
      key,
      value
    }
  };
};

function cacheMergeData(key, value) {
  return {
    type: CACHE_MERGE_DATA,
    payload: {
      key,
      value
    }
  };
};

function cacheDelData(key) {
  return {
    type: CACHE_DEL_DATA,
    payload: {
      key
    }
  };
};

function cacheLSChanged() {
  return {
    type: CACHE_LS_CHANGED,
    payload: {}
  };
};

export class CacheLayerActions {

  static setData = (key, value, useLocalStorage = false) => {
    return async (dispatch) => {
      if (useLocalStorage) {
        await localforage.setItem(key, value);
        return dispatch(cacheLSChanged());
      }
      return dispatch(cacheSetData(key, value));
    };
  };

  static resetData = (key, value, useLocalStorage = false) => {
    return async (dispatch) => {
      if (useLocalStorage) {
        await localforage.setItem(key, value);
        return dispatch(cacheLSChanged());
      }
      return dispatch(cacheSetData(key, value, useLocalStorage));
    };
  };

  static mergeData = (key, value, useLocalStorage = false) => {
    return async (dispatch) => {
      if (useLocalStorage) {
        const currentData = await localforage.getItem(key);
        await localforage.setItem(key, {
          ... currentData,
          ... value
        });
        return dispatch(cacheLSChanged());
      }
      return dispatch(cacheMergeData(key, value, useLocalStorage));
    };
  };

  static delData = (key, useLocalStorage = false) => {
    return async (dispatch) => {
      if (useLocalStorage) {
        await localforage.removeItem(key);
        return dispatch(cacheLSChanged());
      }
      return dispatch(cacheDelData(key, useLocalStorage));
    };
  };

  static getData = (key, useLocalStorage = false) => {
    return async (dispatch, getState) => {
      await dispatch(cacheLSChanged());
      if (useLocalStorage) {
        return await localforage.getItem(key);
      }
      const state = getState();
      console.log(key, state.cacheLayer);
      return state.cacheLayer[key];
    };
  };

}
