import { nanoid } from 'nanoid';
import { enumerate } from '../utils';

function get_or_create_node(nodeType, sourceId, text) {
  const label = text.substring(0, 20);
  return {
    id: nanoid(10),  // all nodes in a G6 chart must be unique
    sourceId,
    label,
    nodeType,
    text,
    children: [],
  };
}

export function transformTopicClusters(rs) {
  const combos = [];

  for (const q of rs) {
    const combo = get_or_create_node('Question', q.uid, q.text);
    combos.push(combo);
    for (const [i, head] of enumerate(q.cluster_heads)) {
      if (i > 9) break;
      const subCombo = get_or_create_node('ClusterHead', head.uid, head.text);
      combo.children.push(subCombo);
      for (const [j, elem] of enumerate(head.cluster_elements)) {
        if (j > 9) break;
        const node = get_or_create_node('ClusterElement', elem.uid, elem.text);
        subCombo.children.push(node);
      }
    }
  }
  return {
      id: 'root',
      children: combos,
  };
}