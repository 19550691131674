import {
  FETCH_PARTICIPATION_LOADING,
  FETCH_PARTICIPATION_SUCCESS,
  FETCH_PARTICIPATION_FAILURE,
} from "../actions/participation";
import {
  participationsByAgeAndActive,
  participationsByGenderAndActive,
  participationsByPositionAndActive,
} from '../queries';

const initialState = {
  data: [],
  error: null,
  loaded: false,
  loading: false,
};

export function participation(state = initialState, action) {
  switch (action.type) {
    case FETCH_PARTICIPATION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_PARTICIPATION_SUCCESS:
      let { gender, age, position } = action.payload;

      gender = participationsByGenderAndActive.transform(gender);
      age = participationsByAgeAndActive.transform(age);
      position = participationsByPositionAndActive.transform(position);

      return {
        data: [gender, age, position],
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_PARTICIPATION_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };

    default:
      return state;
  }
}
