// To generate a pre-signed URL, and return that URL in the response:

import axios from '../http';

export const FETCH_S3_URL_LOADING = 'FETCH_S3_URL_LOADING';
export const FETCH_S3_URL_SUCCESS = 'FETCH_S3_URL_SUCCESS';
export const FETCH_S3_URL_FAILURE = 'FETCH_S3_URL_FAILURE';
export const BUCKET_NAME = 'data-ingestion';

//const client = setupApi();
function fetchS3URLLoading() {
  return {
    type: FETCH_S3_URL_LOADING,
  };
}

function fetchS3URLSuccess(payload) {
  return {
    type: FETCH_S3_URL_SUCCESS,
    payload,
  };
}

function fetchS3URLFailure(payload) {
  return {
    type: FETCH_S3_URL_FAILURE,
    payload,
  }
}

export function getPresignedUploadParams(files, client_id) {
    console.log('getPresignedUploadParams_with_dispatch....', client_id, files)
    return async (dispatch) => {
      dispatch(fetchS3URLLoading());
      try {
        const res = await Promise.all(files.map(async (f) => {
            const req = {"bucket_name": BUCKET_NAME, "object_name": [client_id, f.name].join('/')}
            const res = await axios.post('/api/v1/s3/presignedurl/', req);
            if (res.status !== 200) {
              throw Error(res.statusText);
            }
            
            f.presignedUrl = res.data.presignedUrl
            //f.presignedUrl = await client.presignedPutObject('data-ingestion', [client_id, f.name].join('/'));
            return f
          }));
        console.log('in getPresignedUploadParams_with_dispatch, res:', res)
        
        return dispatch(fetchS3URLSuccess(res));
      } catch (error) {
        dispatch(fetchS3URLFailure(error));
      }
    }
}
