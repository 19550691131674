import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import PanToolIcon from '@material-ui/icons/PanTool';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from "react-intl"; 
import { combineStyles } from '../utils';
import commonStyles from './common_styles';

function ReportSection({
  index,
  onChange,
  onDelete,
  onSectionDown,
  onSectionUp,
  section,
  sectionId,
}) {
  const intl = useIntl();
  const savePlaceholder = intl.formatMessage({id: 'app.save',defaultMessage: 'save'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'cancel'});
  const deletePlaceholder = intl.formatMessage({id: 'app.delete',defaultMessage: 'delete'});
  
  const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);

  const classes = combinedStyles();

  const openDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    onDelete(index);
    setOpenDeleteDialog(false);
  };

  const handleSectionDown = () => {
    onSectionDown(index);
  };

  const handleSectionUp = () => {
    onSectionUp(index);
  };

  const handleChange = (ev) => {
    onChange(index, ev.target.value);
  };

  const value = section.content!=="{}"? JSON.parse(section.content || '""'): JSON.parse('""');

  return (
    <div id={sectionId} className={classes.sectionWrap}>
      <div className={classes.topButtonArea}>
        <div id="drag"
          className={classes.buttonWrap}
        >
          <PanToolIcon className={classes.iconStyle} />
          <span>Drag</span>
        </div>
        <div className={classes.buttonWrap2} onClick={handleSectionUp}>
          <ArrowUpwardIcon className={classes.iconStyle} />
          <span>Up</span>
        </div>
        <div className={classes.buttonWrap2} onClick={handleSectionDown}>
          <ArrowDownwardIcon className={classes.iconStyle} />
          <span>Down</span>
        </div>
        <div className={classes.buttonWrap2} onClick={openDeleteDialog}>
          <DeleteIcon className={classes.iconStyle} />
          <span>{deletePlaceholder}</span>
        </div>
        <Dialog
          open={isOpenDeleteDialog}
          onClose={closeDeleteDialog}
          aria-labelledby={'alert-dialog-title-' + index}
          aria-describedby={'alert-dialog-description-' + index}
        >
          <DialogTitle id={'alert-dialog-title-' + index}>
            Confirm delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText id={'alert-dialog-description-' + index}>
              Are you sure you want to delete this section?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={closeDeleteDialog}>
              {cancelPlaceholder}
            </Button>
            <Button
              autoFocus
              color="primary"
              variant="contained"
              onClick={handleDelete}
            >
              {deletePlaceholder}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <TextField
        id={'outlined-heading-' + index}
        className={classes.textFieldWrap}
        label={labels[section.section_type]}
        multiline={section.section_type !== 'section-heading'}
        variant="outlined"
        value={value}
        onChange={handleChange}
        InputProps={{
          classes: {
            input: classes.textFieldInputStyle,
          },
        }}
        inputProps={{
          className: classes.textFieldInputWrap
        }}
      />
    </div>
  );
}

const labels = {
  'section-heading': 'Heading',
  'body-text': 'Body',
  'image': 'Image',
};

const styles = {
  buttonWrap: {
    backgroundColor: '#fff',
    borderRadius: '2px',
    display: 'flex',
    marginRight: '4px',
    padding: '2px 4px',
    textAlign: 'center',
  },
  buttonWrap2: {
    backgroundColor: '#fff',
    borderRadius: '2px',
    cursor: 'pointer',
    display: 'flex',
    marginRight: '4px',
    padding: '2px 4px',
    textAlign: 'center',
  },
  iconStyle: {
    fontSize: '18px',
    paddingRight: '2px',
    opacity: 0.6,
  },
  sectionWrap: {
    marginTop: '40px',
    position: 'relative',
    width: '100%',
  },
  textFieldInputStyle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 1.41,
    overflowY: 'hidden',
  },
  textFieldWrap: {
    marginBottom: '15px',
    width: '100%',
  },
  topButtonArea: {
    color: '#516e88',
    display: 'flex',
    fontSize: '12.2px',
    position: 'absolute',
    top: '-12px',
    right: '10px',
    textAlign: 'center',
    zIndex: 2,
  },
};

const combinedStyles = makeStyles(combineStyles(commonStyles, styles));

export default ReportSection;