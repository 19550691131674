import isEmpty from 'lodash.isempty';
import moment from 'moment';

import { enumerate } from '../utils';

export function transformCommentCountByConversationResultSet(rs) {
  const data = {};
  if (rs) {
    for (const item of rs) {
      data[item['CommentsDenorm.conversation']] = item['CommentsDenorm.count'];
    }
  }
  return data;
}

export function transformCommentCountByMonthAndDayResultSet(rs) {
  if (!rs) {
    return {};
  }
  const data = {};
  for (const item of rs) {
    if (!data[item['CommentsDenorm.month']]) {
      data[item['CommentsDenorm.month']] = {};
    }
    data[item['CommentsDenorm.month']][item['CommentsDenorm.day']] = item['CommentsDenorm.count'];
  }
  return data;
}

const labels = [
  'negative1',
  'negative2',
  'negative3',
  'negative4',
  'negative5',
  'positive1',
  'positive2',
  'positive3',
  'positive4',
  'positive5',
];

export function transformCommentCountByMonthDayAndSentimentResultSet(rs) {
  let categories = {};

  // get month range
  let months = rs.map((row) => {
    const month = moment().month(row['CommentsDenorm.month']).format('MM');
    return `2020-${month}`;
  });

  // dedup
  months = new Set(months);
  months = [...months];
  months.sort();

  // build default categories
  categories = months.reduce((a, m) => {
    const eom = moment(`${m}-1`).endOf('month');
    const lastWeekOfMonth = getWeekOfMonth(eom.format('YYYY-MM-DD'));
    for (const w of Array(lastWeekOfMonth).keys()) {
      const category = `${m}-W${w + 1}`;
      a[category] = {
        'category': category,
        'month': eom.format('MMM').toUpperCase() + eom.format('YY'),
        ...labels.reduce((ac, l) => {
          ac[l] = [];
          return ac;
        }, {})
      };
    }
    return a;
  }, {});

  // populate categories
  for (let row of rs) {
    if (row["CommentsDenorm.sentiment"]) {
      const day = parseInt(row["CommentsDenorm.day"], 10);
      const month = moment().month(row["CommentsDenorm.month"]).format('MM')
      const datestr = `2020-${month}-${day}`
      const category = `2020-${month}-W${getWeekOfMonth(datestr)}`;
      const idx = parseInt(row["CommentsDenorm.sentiment"], 10);
      if (!isNaN(idx)) {
        const label = labels[idx - 1];
        let count = row["CommentsDenorm.count"];
   
        if (idx < 6) {
          count = -count;
        }
        categories[category][label].push(count);
      }
    }
  }

  const data = Object.values(categories).map((c) => ({
    ...c,
    ...labels.reduce((a, l) => {
      a[l] = Math.ceil(average(c[l]));
      return a;
    }, {})
  }));

  data.sort((a, b) => {
    if (a.category < b.category) {
      return -1;
    };
    if (a.category > b.category) {
      return 1;
    }
    return 0;
  });

  return data;
}

function getWeekOfMonth(datestr) {
  const dt = moment(datestr);
  return Math.ceil(dt.date() / 7);
}

function sum(arr) {
  if (arr && arr.length) {
    return arr.reduce((a, k) => a + k, 0);
  }
  return 0;
}

function average(arr) {
  if (arr && arr.length) {
    return sum(arr) / arr.length;
  }
  return 0;
}

export function transformCommentCountBySentimentResultSet(rs) {
  let avgMood = 0;
  if (rs) {
    let total = 0;
    const sentiments = [];
    for (let item of rs) {
      const count = item['CommentsDenorm.count'];
      
      let sentiment;
      try {
        sentiment = parseInt(item['CommentsDenorm.sentiment'], 10);
      } catch (e) {
        sentiment = 0;
      }
      if (sentiment > 0) {
        sentiments.push([sentiment, count]);
        total += count;
      }
    }
    avgMood = sentiments.reduce((a, k) => {
 
      return a + (k[0] * k[1] / total);
    }, 0);
  }
  return avgMood;
}

export function combineCountData(...counts) {
  const data = {};
  for (const [i, count] of enumerate(counts)) {
    for (const [k, v] of Object.entries(count)) {
      if (!data[k]) {
        data[k] = {};
      }
      for (const [k1, v1] of Object.entries(v)) {
        if (!data[k][k1]) {
          data[k][k1] = [];
        }
        data[k][k1].push({ type: 'data' + i, count: v1 });
      }
    }
  }
  return data;
}

export function transformCombinedCounts(combinedCounts) {
  if (isEmpty(combinedCounts)) {
    return [];
  }

  const data = [];
  let maxValue = 10;

  for (const [k, v] of Object.entries(combinedCounts)) {
    // change to 'by month' for now
    const temp = {
      // TODO
      // change hard coding of year
      category: moment(new Date('2020', moment().month(k).format('M') - 1), '1').format('YYYY-MM-DD'),
      month: k.toUpperCase(),
    };
    const count = { data0: 0, data1: 0 };

    // calculate total counts based on data1 and data2
    for (const val of Object.values(v)) {
      for (const day of val) {
        // TODO
        // will this break if `day.type` not set on `count`
        count[day.type] += day.count;
      }
    }
    for (const key of Object.keys(count)) {
      if (count[key] > 0) {
        temp[key] = count[key];
      }
      if (count[key] > maxValue) {
        maxValue = count[key];
      }
    }
    data.push(temp);
  }

  data.sort((a, b) => {
    const dateA = new Date(a.category);
    const dateB = new Date(b.category);
    return dateA > dateB ? 1 : -1;
  });

  return {
    data,
    maxValue,
  };
};
