import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CommentIcon from '@material-ui/icons/ModeComment';
import { makeStyles } from '@material-ui/core/styles';

function ProblemListCard() {

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Top 5 problems
        </Typography>
        <div className={classes.centerContent}>
          <div className={classes.contentItem}>
            <div className={classes.itemNum}> #1</div>
            <div className={classes.itemText}>
              BFS Co likes to speak about the values of ethics and integrity,
              but the fact that staff are overworked, tired, face increasing
              pressure and have very little morale.
            </div>
            <CommentIcon className={classes.itemIcon} />
          </div>
          <div className={classes.contentItem}>
            <div className={classes.itemNum}> #2</div>
            <div className={classes.itemText}>
              Majority of below AM level staff made more money at their
              part-time jobs during uni than they do now, so talk to us about
              how we make a difference and highlight our achievements and
              hard work.
            </div>
            <CommentIcon className={classes.itemIcon} />
          </div>
          <div className={classes.contentItem}>
            <div className={classes.itemNum}> #3</div>
            <div className={classes.itemText}>
              BFS Co says they take CPI into account when setting salaries,
              yet talking to others it is clear that salaries for a number
              of positions have not changed for years.
            </div>
            <CommentIcon className={classes.itemIcon} />
          </div>
          <div className={classes.contentItem}>
            <div className={classes.itemNum}> #4</div>
            <div className={classes.itemText}>
              More than often, people who are in the leadership position do
              not exemplify leadership qualities. These are people from Manager
              level to Director level.
            </div>
            <CommentIcon className={classes.itemIcon} />
          </div>
          <div className={classes.contentItem}>
            <div className={classes.itemNum}> #5</div>
            <div className={classes.itemText}>
              I came from another BFS country RA team and I have never had to
              handhold directors like I need to in Sg.
            </div>
            <CommentIcon className={classes.itemIcon} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    padding: '4px',
  },
  centerContent: {
    marginTop: '10px',
  },
  contentItem: {
    alignItems: 'center',
    borderBottom: '1px solid #efefef',
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    fontSize: 14.2,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.25,
    lineHeight: '1.41',
    padding: '14px 0',
  },
  itemIcon: {
    color: 'rgba(84, 110, 122, 0.8)',
    display: 'inline-block',
    marginLeft: '32px',
    opacity: 0.6,
  },
  itemNum: {
    color: '#00a4bd',
    display: 'inline-block',
    fontSize: '24.4px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    marginRight: '20px',
    opacity: 0.38,
  },
  itemText: {
    display: 'inline-block',
    width: '90%',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
  },
}));

export default ProblemListCard;