import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchReports } from '../actions/reports';
import { ReportsContext } from '../Main/MainContainer';

export default ({ children }) => {

  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports.data);

  useEffect(() => {
    dispatch(fetchReports());
  }, []);

  return (
    <ReportsContext.Provider value={reports}>
      <div style={
        {
          borderTop: '1px solid #f5f5f5',
          padding: '0 30px',
          backgroundColor: 'white',
          display: 'flex',
          flex: 2,
          flexDirection: 'column',
          width: '100%',
          height: '100%'
        }
      }>
        { children }
      </div>
    </ReportsContext.Provider>
  )
}