import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { updateDateRange } from '../actions/date_range';

function SimpleDateRangePicker({isOpen, closeDateRangeMenu}) {
  const date_range = useSelector((state) => state.date_range);

  const [selectedDates, setSelectedDates] = React.useState([{
    startDate: date_range.data.startDate? date_range.data.startDate: new Date(2020, 6, 1),
    endDate: date_range.data.endDate? date_range.data.endDate: addDays(new Date(), 7),
    key: 'selection',
  }]);

  const dispatch = useDispatch();

  const classes = useStyles();

  const handleSetDateRange = () => {
    dispatch(updateDateRange(selectedDates[0]));
    closeDateRangeMenu();
  }

  const handleResetDateRange = () => {
    setSelectedDates([{
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    }]);
  }

  const handleAllDateRange = () => {
    setSelectedDates([{
      startDate: null,
      endDate: null,
      key: 'selection',
    }]);
  }


  return (
    <Fragment>
      {isOpen?
        <div className={classes.dateRangeContainer}>
          <DateRangePicker
            editableDateInputs={true}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            ranges={selectedDates}
            onChange={item => setSelectedDates([item.selection]) }
            color='#455a64'
           />
          <div className={classes.buttonWrapper}>
          <Button className={classes.button} color="secondary" variant="contained" onClick={handleAllDateRange}>
              all range
          </Button>
          <Button className={classes.button} variant="contained" onClick={handleResetDateRange}>
              reset
          </Button>
          <Button className={classes.button} color="primary" variant="contained" onClick={handleSetDateRange}>
              confirm
          </Button>
          </div>
        </div>
      :
      null
      }
    </Fragment>



  );
}

const useStyles = makeStyles(() => ({
  dateRangeContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  button: {
    width: '50%'
  }
}));

export default SimpleDateRangePicker;








