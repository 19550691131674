import React from 'react';

export function sortDemographics (data) {
   // check if row/col is starting with number, if yes, split based on ",_:- " and sort it
   return data.sort((a, b) => {
    const keyA = a.split(/[-_:, ]+/)[0];
    const keyB = b.split(/[-_:, ]+/)[0];
    //Check if one of the string is empty, put empty column at the end
    if (keyA === "" || keyA === null) return 1;
    if (keyB === "" || keyB === null) return -1;
    if (keyA === keyB) return 0;
     //check if string is type number  
    if (!isNaN(keyA) && !isNaN(keyB)) {
      return Number(keyA) < Number(keyB) ? -1 : 1;
    }
     //sort as a string if row/column is not type number
    return keyA.toUpperCase() < keyB.toUpperCase() ? -1 : 1;
  });
  
}