import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchQueryWithCustomDateRange } from '../actions/cube';
import MoodBarChart from '../charts/MoodBarChart';
import Loading from '../components/Loading';
import NoData from '../components/NoData';
import Widget from '../components/Widget';
import { commentCountByMonthDayAndSentiment } from '../queries';
import { ForumIdContext } from '../context/forumId-context';
import { transform } from '../transformers/utils';

function MoodWidget(props) {

  // const { data, loaded } = useSelector((state) =>
  //   state.cube[commentCountByMonthDayAndSentiment.type]
  // );
  const [ data, setData ] = useState([]);
  const [ loaded, setLoaded ] = useState(false);
  const user = useSelector((state) => state.user);
  const date_range = useSelector((state) => state.date_range);
  const dispatch = useDispatch();
  const {forumId } = useContext(ForumIdContext);

  useEffect(() => {
    (async () => {
      if (user.client_id) {
        let zForumId = props.forumId ? props.forumId : (props.useContextRules !== false) ? forumId : 'all';
        zForumId = (zForumId == 'all') ? '' : zForumId;
        let zDateRange = (props.startDate) ? { startDate: props.startDate, endDate: props.endDate } : date_range.data;
        let result = await dispatch(fetchQueryWithCustomDateRange(commentCountByMonthDayAndSentiment, zDateRange, user.client_id, zForumId));
        if (result) {
          result.data = transform(commentCountByMonthDayAndSentiment.type, result.data);
          setData(result.data);
        }
        setLoaded(true);
      }
    })();
  }, [user, date_range, forumId, props.forumId, props.startDate, props.endDate]);

  return (
    <Widget title={props.title ? props.title : "Mood over the last 6 months" } actions={ props.actions }>
      {loaded ? (data.length?
        <MoodBarChart data={data} />:
        <NoData />
      ):
        <Loading />
      }
    </Widget>
  );
}

export default MoodWidget;