import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import NoData from '../NoData';

function ConversationCard({
  classes,
  commentCount,
  index,
  participation,
}) {


  return (
    <Card className={classes.card}>
      {participation?
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Conversation {index}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {participation[0]}
        </Typography>
        <div className={classes.centerContent}>

        </div>
      </CardContent>:
      <NoData/>
      }
      <div className={classes.bottomWrap}>
        <div className={classes.bottomItem}>
          <label className={classes.bottomNum}>
            {participation?participation[1]:0}
          </label>
          <label className={classes.bottomText}>
            Participants
          </label>
        </div>
        <div className={classes.bottomItem}>
          <label className={classes.bottomNum}>
            {commentCount}
          </label>
          <label className={classes.bottomText}>
            Comments
          </label>
        </div>
      </div>
    </Card>
  );
}

const styles = {
  blue: {
    opacity: 0.6,
  },
  lightBlue: {
    opacity: 0.3,
  },
  bottomItem: {
    borderTop: '1px solid #efefef',
    borderRight: '1px solid #efefef',
    flexGrow: 1,
    paddingTop: '9px',
    paddingBottom: '6px',
    paddingLeft: '32px',
  },
  bottomNum: {
    color: '#00a4bd',
    display: 'block',
    fontSize: '34.5px',
    lineHeight: 0.875,
    opacity: 1,
    // paddingBottom: '6px',
  },
  bottomText: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14.2px',
  },
  bottomWrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 4px',
    transform: 'scale(0.8)',
  },
  card: {
    height: '100%',
  },
  centerContent: {
    // height: '118px',
    height: '111px',
    overflow: 'auto',
    paddingTop: '12px',
  },
  contentItem: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.25,
    lineHeight: 1.41,
    marginBottom: '8px',
  },
  pos: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '16.3px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '50px',
    letterSpacing: 0.5,
    lineHeight: 1.72,
  },
  title: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
  },
}
export default withStyles(styles)(ConversationCard);