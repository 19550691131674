import React from "react";
import "./magicWindow.css";
import { List } from "antd";
export default function Source({ ...props }) {
  return (
    <>
      <List
        dataSource={props.chunks}
        pagination={{
          pageSize: 10,
        }}
        renderItem={(item) => <List.Item>{item.text}</List.Item>}
      />
    </>
  );
}
