import React from 'react';
import ChartSelector from './ChartSelector';

function Widget({ children, ... props}) {

  return (
    <>
      <ChartSelector {... props }>
        { children }
      </ChartSelector>
    </>
  );
}

export default Widget;