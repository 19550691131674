import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { getDemoNetworkMap } from "../../actions/network_map";
import {
  Form,
  Select,
  DatePicker,
  Space,
  Button,
  Drawer,
  Switch,
  Collapse,
  Radio,
  Row,
  Col,
  Divider
} from "antd";
import { useIntl } from "react-intl";
import { DemographicsContext } from "../../context/demographics-context";
import moment from "moment";
import { sortDemographics } from "../../utils/SortDemographics";
const { Option } = Select;
const { Panel } = Collapse;

function DemographicSwitchBlock({
  category,
  taxonomy,
  values,
  onChange,
  onResetFilters,
  missing,
}) {
  const [toggledDemographics, setToggledDemographics] = useState({});
  const [allSelected, setAllSelected] = useState(true);
  //const [ sortedValues, setSortedValues ] = useState([]);
  const [deafultDemographics, setDeafultDemographics] = useState({});
 
  const intl = useIntl();
  const filterByNonePlaceholder = intl.formatMessage({
    id: "app.explorePage.filterByNone",
    defaultMessage: "None",
  });
  const filterByAllPlaceholder = intl.formatMessage({
    id: "app.explorePage.filterByAll",
    defaultMessage: "All",
  });
  useEffect(() => {
    let _toggledDemographics = { ...toggledDemographics };
    values.forEach((value) => {
      const key = [category, taxonomy, value].join("/");
      if (_toggledDemographics[key] === undefined) {
        _toggledDemographics[key] = true;
      }
    });
    setToggledDemographics(_toggledDemographics);
    setDeafultDemographics(_toggledDemographics);
  }, [values]);

  useEffect(() => {
    if (onChange) {
      onChange(toggledDemographics);
    }
  }, [toggledDemographics]);

  const toggleDemographic = (category, taxonomy, value) => {
    let newFilter = {};
    const key = [category, taxonomy, value].join("/");
    newFilter[key] = !toggledDemographics[key] ? true : false;
    setToggledDemographics({
      ...toggledDemographics,
      ...newFilter,
    });
  };

  const toggleSelected = () => {
    setAllSelected(!allSelected);

    let _toggledDemographics = { ...toggledDemographics };
    Object.keys(_toggledDemographics).forEach((key) => {
      _toggledDemographics[key] = !allSelected;
    });

    setToggledDemographics(_toggledDemographics);

    return true;
  };

  return (
    <section style={{ marginLeft: "-10px", marginTop: "-15px" }}>
      <Collapse ghost>
        <Panel
          extra={
            <Button
              type="link"
              size="small"
              onClick={(e) => toggleSelected() && e.stopPropagation()}
            >
              {allSelected ? filterByNonePlaceholder : filterByAllPlaceholder}
            </Button>
          }
          header={
            <>
              {" "}
              {category}: {taxonomy}
            </>
          }
          key={category + "_" + taxonomy}
        >
          <section style={{ marginTop: "-15px" }}>
            {values.map((value, index) => (
              <div
                style={{
                  marginLeft: 10,
                  marginBottom: 5,
                  color:
                    missing.indexOf([category, taxonomy, value].join("/")) > -1
                      ? "#e5e3e3"
                      : "inherit",
                }}
                key={index}
              >
                {/* <div style={ { marginLeft: 10, marginBottom: 5, color: '#e5e3e3' } } key={ index }> */}
                <Space>
                  <Switch
                    checked={
                      toggledDemographics[[category, taxonomy, value].join("/")]
                    }
                    onChange={() =>
                      toggleDemographic(category, taxonomy, value)
                    }
                  />
                  <small>{value}</small>
                </Space>
              </div>
            ))}
          </section>
        </Panel>
      </Collapse>
    </section>
  );
}

function DemographicBlocks({
  data,
  onChange,
  onNoneChanged,
  filteredDemographicKeys,
  onResetFilters,
  demographicBlocks,
  setDemographicBlocks,
}) {
  const [demographicIncludeNone, setDemographicIncludeNone] = useState(true);
  const [foundDemographicKeys, setFoundDemographicKeys] = useState({});
  const [missingDemographicKeys, setMissingDemographicKeys] = useState({});
  const [toggledDemographics, setToggledDemographics] = useState({});
  const { taxonomyMap } = useContext(DemographicsContext);

  useEffect(() => {
    let _foundDemographicKeys = {};
    data.nodes.forEach((node) => {
      if (node && node.demographics) {
        node.demographics.forEach((demographic) => {
          const key = [
            demographic.category,
            demographic.taxonomy,
            demographic.value,
          ].join("/");
          _foundDemographicKeys[key] = true;
        });
      }
    });
    setFoundDemographicKeys(_foundDemographicKeys);
    console.log(foundDemographicKeys);
  }, [data]);

  useEffect(() => {
    console.log(Object.keys(foundDemographicKeys));
    const missingKeys = _.difference(
      Object.keys(foundDemographicKeys),
      Object.keys(filteredDemographicKeys)
    );
    console.log("missing", missingKeys);

    setMissingDemographicKeys(missingKeys);

  }, [demographicBlocks, filteredDemographicKeys]);

  useEffect(() => {
    let blocks = [];
    _.uniq(Object.values(taxonomyMap).map((node) => node[0].category)).map(
      (category, index) =>
        _.uniq(
          _.flatten(Object.values(taxonomyMap))
            .filter((node) => node.category == category)
            .map((node) => node.taxonomy)
        ).map((taxonomy, index) => {
          let valuesArray = _.uniq(
            taxonomyMap[taxonomy]
              .filter((node) => node.taxonomy == taxonomy)
              .map((node) => node.value)
          )
            .map((value, index) => {
              const key = [category, taxonomy, value].join("/");
              return !foundDemographicKeys[key] ? null : value;
            })
            .filter((el) => el != null);
          let values = [];
          if (valuesArray.length != 0) {
            values = [...["Include Missing"], ...sortDemographics(valuesArray)];
          } else {
            values = valuesArray;
          }
          blocks.push({ category, taxonomy, values });
        })
    );
    setDemographicBlocks(blocks);
  }, [foundDemographicKeys]);


  useEffect(() => {
    if (onChange) {
      onChange(toggledDemographics);
    }
  }, [toggledDemographics]);

  useEffect(() => {
    if (onNoneChanged) {
      onNoneChanged(demographicIncludeNone);
    }
  }, [demographicIncludeNone]);

  return (
    <>
      {
        <>
          {/* <section style={{ marginLeft: "-10px", marginTop: "-5px" }}>
            <Collapse defaultActiveKey={["missing"]} ghost>
              <Panel header="Missing demographic data" key={"missing"}>
                <section style={{ marginLeft: 10, marginTop: "-15px" }}>
                  <Space>
                    <Switch
                      checked={demographicIncludeNone}
                      onChange={() =>
                        setDemographicIncludeNone(!demographicIncludeNone)
                      }
                    />
                    <small>Include</small>
                  </Space>
                </section>
              </Panel>
            </Collapse>
          </section> */}

          <>
            {demographicBlocks.map(
              ({ category, taxonomy, values }, index) =>
                values.length > 0 && (
                  <DemographicSwitchBlock
                    key={index}
                    missing={missingDemographicKeys}
                    values={values}
                    category={category}
                    taxonomy={taxonomy}
                    onChange={(blockDemographics) =>
                      setToggledDemographics({
                        ...toggledDemographics,
                        ...blockDemographics,
                      })
                    }
                  />
                )
            )}
          </>
        </>
      }
    </>
  );
}

export default function Filters({ open, onClose, onChange,setIsGrouped }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const intl = useIntl();
  const startDatePlaceholder = intl.formatMessage({
    id: "app.strategyMap.config.startDate",
    defaultMessage: "Start Date",
  });
  const endDatePlaceholder = intl.formatMessage({
    id: "app.strategyMap.config.endDate",
    defaultMessage: "End Date",
  });
  const [isReset, setIsReset] = useState(false);
  const [demographicFilters, setDemographicFilters] = useState({});
  const [graph, setGraph] = useState({});
  const [demographicIncludeNone, setDemographicIncludeNone] = useState(true);
  const [filteredDemographicKeys, setFilteredDemographicKeys] = useState([]);
  const [demographicBlocks, setDemographicBlocks] = useState([]);
  const [initialGroupValue,setInitialGroupValue] = useState("");
  const [centralityGroup,setCentralityGroup]= useState("centrality_degree");
  const defaultValues = {
    startDate: "2020-06-01",
    endDate: moment().format("YYYY-MM-DD"),
    autoEndDate: "false",
    groupBy: ""
  };
  const [tempForm, setTempForm] = useState({});
  const { taxonomyMap } = useContext(DemographicsContext);
  useEffect(() => {
    (async () => {
      if(Object.values(taxonomyMap).length>0)
      {
        const category = Object.values(taxonomyMap)[0][0].category;
        const taxonomy = Object.values(taxonomyMap)[0][0].taxonomy;
    await loadGraphData(dispatch, category, taxonomy, setGraph);
    setTempForm({
      ...defaultValues,
    })
  }
    })();
  }, [taxonomyMap]);

  useEffect(() => {
    if(demographicBlocks.length > 0)
    {

     let data= [demographicBlocks[0].category, demographicBlocks[0].taxonomy].join("/");
     setInitialGroupValue(data);
     if(tempForm.groupBy === "")
     {
      setTempForm({
        ...defaultValues,
        groupBy: data
      })
     }
    }
  }, [demographicBlocks]);

  useEffect(() => {
    if (graph && Object.keys(graph).length > 0) applyFilter();
  }, [graph]);

  const loadandApplyFilter = async() => {
    if(tempForm.groupBy !== undefined)
    {
      const groupByFilter = tempForm.groupBy.split("/");
      await loadGraphData(dispatch, groupByFilter[0], groupByFilter[1], setGraph);
    }
  }
  const applyFilter = async() => {  

    form.validateFields().then(onFinish).catch(onFinishFailed);

    if (!isReset) {
      console.log("applyFilter->called");
      let missingKeys = [];
      missingKeys = Object.keys(demographicFilters).filter((rule) => {
        let retain = false;
        const [category, taxonomy, value] = rule.split("/");
        if (value === "Include Missing" && !demographicFilters[rule])
          retain = true;
        return retain;
      });
      let _processedData;

      if (missingKeys.length > 0) {
        _processedData = graph.nodes.filter((node) => {
          return missingKeys.every((rule) => {
            let keep = false;
            const [category, taxonomy, value] = rule.split("/");
            if(node.demographics)
            {
            node.demographics.forEach((demographic) => {
              if (
                demographic.category === category &&
                demographic.taxonomy === taxonomy
              ) {
                keep = true;
                console.log(
                  "Removing from list due to demographic filter rule:",
                  rule,
                  node
                );
              }
              
            });
          }
            return keep;
          });
        });
      } else {
        _processedData = graph.nodes;
      }
      //Applying rest of the filters on top of include missing data for demographics
      let _filteredData = _processedData.filter((node) => {
        let keep = true;

        Object.keys(demographicFilters).forEach((rule) => {
          if (keep && node && node.demographics && !demographicFilters[rule]) {
            const [category, taxonomy, ...valueArr] = rule.split("/");
            //In cisco there is one case of Opportunity/Competitors/HPE/Aruba, so added it as an array to avoid any logical conflicts with such cases.
            const value = valueArr.join("/");
            //condition is triggered if any value switch is disabled
            if (value !== "Include Missing") {
              node.demographics.forEach((demographic) => {
                if (
                  demographic.category == category &&
                  demographic.taxonomy == taxonomy &&
                  demographic.value == value
                ) {
                  keep = false;
                  console.log(
                    "Removing from list due to demographic filter rule:",
                    rule,
                    node
                  );
                }
              });
            }
          }
        });
        if (
          keep &&
          node &&
          node.demographics &&
          node.demographics.length == 0 &&
          !demographicIncludeNone
        ) {
          keep = false;
        }
        return keep;
      });

      let _filteredDataFoundDemographicKeys = {};
      _filteredData.forEach((node) => {
        if (node && node.demographics) {
          node.demographics.forEach((demographic) => {
            const key = [
              demographic.category,
              demographic.taxonomy,
              demographic.value,
            ].join("/");
            _filteredDataFoundDemographicKeys[key] = true;
          });
        }
      });
      setFilteredDemographicKeys(_filteredDataFoundDemographicKeys);
      
      //If user has selected the group, allocate group value to the node.group.
      if (tempForm.groupBy !== "none") {
        setIsGrouped(true); // set isgroup to true which will calc the centroid of each group in graph.js
        _filteredData.forEach((node) => {
          if (node && node.demographics) {
            node.demographics.forEach((demographic) => {
              const key = [demographic.category, demographic.taxonomy].join(
                "/"
              );
              if (key === tempForm.groupBy) {
                node.group = demographic.value;
              }
            });
          }
        });
      }
      let temp = {
        nodes: _filteredData,
        links: graph.links,
      };
      onChange(temp, centralityGroup);
    }
  };

  const onFormChanged = (changedValues) => {
    setTempForm((current) => {
      return {
        ...defaultValues,
        ...current,
        ...changedValues,
      };
    });
  };

  const updateRecord = (values) => {
    const validKeys = Object.keys(values).filter((key) => {
      return values[key] !== undefined;
    });
    const validValues = validKeys.map((key) => {
      return values[key];
    });
    let validatedObject = _.zipObject(validKeys, validValues);
    if (typeof validatedObject.startDate !== "string") {
      validatedObject.startDate =
        validatedObject.startDate.format("YYYY-MM-DD");
    }
    if (
      typeof validatedObject.endDate !== "string" &&
      values.autoEndDate !== "true"
    ) {
      validatedObject.endDate = validatedObject.endDate.format("YYYY-MM-DD");
    }
    setTempForm((current) => {
      current = {
        ...defaultValues,
        ...current,
        ...validatedObject,
      };
      // addConfig(current);
      return current;
    });
  };
  const onFinishFailed = () => {};
  const onFinish = (values) => {
    // drawerOnClose();
    updateRecord(values);
  };

  const onCentralityChange = (e) => {
    console.log("centralityGroup->",centralityGroup);
    setCentralityGroup(e.target.value);
  };
  
  return (
    <>
      <Drawer title="Filters" placement="right" onClose={onClose} open={open}>
        <Form
          form={form}
          initialValues={{
            layout: "horizontal",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChanged}
        >
          <Form.Item
            label={startDatePlaceholder}
            name="startDate"
            initialValue={moment(tempForm.startDate, "YYYY-MM-DD")}
          >
            <DatePicker
              format={"YYYY-MM-DD"}
              popupStyle={{
                zIndex: 3000,
              }}
            />
          </Form.Item>
          <Form.Item label={endDatePlaceholder}>
            <Space>
              <Form.Item
                name="autoEndDate"
                noStyle
                initialValue={tempForm.autoEndDate}
                rules={[
                  {
                    required: true,
                    message: "Please choose an option",
                  },
                ]}
              >
                <Select
                  style={{ width: 145 }}
                  dropdownStyle={{
                    zIndex: 3000,
                  }}
                >
                  <Option value="true">Automatic Today</Option>
                  <Option value="false">Manual Date</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={endDatePlaceholder}
                // name= "endDate"
                noStyle
                initialValue={moment(tempForm.endDate, "YYYY-MM-DD")}
                rules={[
                  {
                    required: true,
                    message: "Please choose an option",
                  },
                ]}
              >
                {tempForm.autoEndDate === "false" && (
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    popupStyle={{
                      zIndex: 3000,
                    }}
                  />
                )}
              </Form.Item>
            </Space>
          </Form.Item>
          <Space>
          {initialGroupValue ? (
  <Form.Item label="Group By" name="groupBy">
    <Select
      dropdownStyle={{
        zIndex: 3000,
      }}
      style={{ width: 200 }}
      defaultValue={initialGroupValue}
    >
      {demographicBlocks.map((demographic) => (
        <Option
          key={[demographic.category, demographic.taxonomy].join("/")}
          value={[demographic.category, demographic.taxonomy].join("/")}
        >
          {[demographic.category, demographic.taxonomy].join("/")}
        </Option>
      ))}
    </Select>
  </Form.Item>
) : null}
          </Space>
        </Form>

        <DemographicBlocks
          data={graph}
          onResetFilters={isReset}
          onChange={(newDemographicFilters) =>
            setDemographicFilters(newDemographicFilters)
          }
          onNoneChanged={(value) => setDemographicIncludeNone(value)}
          filteredDemographicKeys={filteredDemographicKeys}
          demographicBlocks={demographicBlocks}
          setDemographicBlocks={setDemographicBlocks}
        />
        <Divider orientation="left">Centrality measures</Divider>
        <Radio.Group onChange={onCentralityChange} defaultValue={centralityGroup}>
        <Row>
          <Col style={{ margin: "5px 5px 5px" }}>
          <Radio.Button style={{ width: 150, textAlign: "center" }} value="centrality_articlerank">Article Rank</Radio.Button>
          </Col>
          <Col style={{ margin: "5px 5px 5px" }}>
          <Radio.Button style={{ width: 150, textAlign: "center" }} value="centrality_betweenness">Betweenness</Radio.Button>
          </Col>
        </Row>
        <Row>
        <Col style={{ margin: "5px 5px 5px" }}>
          <Radio.Button style={{ width: 150, textAlign: "center" }} value="centrality_closeness" >Closeness</Radio.Button>
          </Col>
          <Col style={{ margin: "5px 5px 5px" }}>
          <Radio.Button style={{ width: 150, textAlign: "center" }} value="centrality_eigenvector">Eigen Vector</Radio.Button>
          </Col>
        </Row>
        <Row>
        <Col style={{ margin: "5px 5px 5px" }}>
          <Radio.Button style={{ width: 150, textAlign: "center" }} value="centrality_degree">Degree</Radio.Button>
          </Col>
          <Col style={{ margin: "5px 5px 5px" }}>
          <Radio.Button style={{ width: 150 , textAlign: "center" }} value="centrality_pagerank">Page Rank</Radio.Button>
          </Col>
        </Row>
        </Radio.Group>
        <div style={{ textAlign: "right", marginTop:"5px"}}>
          <Button onClick={(e) => loadandApplyFilter()}>Apply Filters</Button>
        </div>
      </Drawer>
    </>
  );
}

async function loadGraphData(dispatch, category, taxonomy, setGraph) {
  let result = await dispatch(getDemoNetworkMap(category, taxonomy));
  console.log("result", result);

  let linksArrayTemp = result.payload.userDemographics.map((val) => val.outgoing_communicationsConnection.edges.map((e) => ({
    source: val.uid,
    target: e.node.uid
  }))
  ).flat().filter(obj => obj.source !== obj.target);

  let linksArray = [];
  linksArrayTemp.forEach(function (item) {
    if (!linksArray.some(
      (i) => (i.source === item.source && i.target === item.target) ||
        (i.source === item.target && i.target === item.source)
    )) {
      linksArray.push(item);
    }
  });

  let modifiedArray = result.payload.userDemographics.map(obj => {
    return { ...obj, id: obj.uid };
  });

  let graphData = {
    nodes: modifiedArray,
    links: linksArray
  };
  setGraph(graphData);
}

