import React, { useEffect, useState, useContext, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Collapse } from 'antd';
import { Button, Input, Select, Modal, Radio, Space, List, Switch, message,Form } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useIntl } from "react-intl"; 
import { DemographicsContext } from '../../../context/demographics-context';
import { moodList, SentimentToEmojiFace } from '../../../components/SentimentToEmojiFace';

import {sortDemographics} from '../../../utils/SortDemographics'
const { Option } = Select;
const { confirm } = Modal;
const { Panel } = Collapse;


const classes = {
  container: {
    paddingTop: '30px'
  },
  moodList: {
    border: 'solid 1px #efefef',
    borderRadius: '6px',
    display: 'inline-flex',
    margin: '0 17px',
  },
  filterName: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '15.3px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.24px',
    lineHeight: 'normal',
    padding: '0px 17px 5px 0px',
  },
  moodSizeControl: {
    height: '25px',
    width: '25px',
  },
  buttonGroupItem: {
    padding: '5px 5px 7px 5px',
    height: '37px'
  },
  radioChecked: {
    border: '1px solid rgb(21, 95, 163)',
    background: '#1890ff',
    '&::before': {
      backgroundColor: '#1890ff',
    }
  },
  radioUnchecked: {
    border: '1px solid rgb(21, 95, 163)',
    background: '#fff',
    '&::before': {
      backgroundColor: '#fff',
    }
  },
  radioDisabled: {
    border: '1px solid rgb(21, 95, 163)',
    background: '#eee',
    '&::before': {
      backgroundColor: '#eee',
    }
  },
  spacer: {
    paddingBottom: '15px'
  }
};

function DemographicSwitchBlock({ category, taxonomy, values, onChange, missing,onResetFilters,setIsReset}) {

  const [ toggledDemographics, setToggledDemographics ] = useState({});
  const [ allSelected, setAllSelected ] = useState(true);
  //const [ sortedValues, setSortedValues ] = useState([]);
  const [deafultDemographics, setDeafultDemographics]=  useState({});
  const intl = useIntl();
  const filterByNonePlaceholder = intl.formatMessage({id: 'app.explorePage.filterByNone',defaultMessage: 'None'});
  const filterByAllPlaceholder = intl.formatMessage({id: 'app.explorePage.filterByAll',defaultMessage: 'All'});
  useEffect(() => {
    let _toggledDemographics = {  ... toggledDemographics };
    values.forEach((value) => {
      const key = [ category, taxonomy, value ].join('/');
      if (_toggledDemographics[key] === undefined) {
        _toggledDemographics[key] = true;
      }
    });
    setToggledDemographics(_toggledDemographics);
    setDeafultDemographics(_toggledDemographics);
  }, [ values ]);

  // useEffect(() => {
  //   let _sortedValues = [].concat(values.filter((value) => {
  //     const key = [ category, taxonomy, value ].join('/');
  //     return missing.indexOf(key) == -1;
  //   })).concat(values.filter((value) => {
  //     const key = [ category, taxonomy, value ].join('/');
  //     return missing.indexOf(key) > -1;
  //   }));
  //   setSortedValues(_sortedValues);
  // }, [ values, missing ]);

  useEffect(() => {
    if (onChange) {
      onChange(toggledDemographics);
    }
  }, [ toggledDemographics ]);


  useEffect(() => {
    if (onResetFilters) {
      setToggledDemographics(deafultDemographics);
       setIsReset(false);
    }
  }, [ onResetFilters ]);

  const toggleDemographic = (category, taxonomy, value) => {
    let newFilter = {};
    const key = [ category, taxonomy, value ].join('/');
    newFilter[key] = !toggledDemographics[key] ? true : false;
    setToggledDemographics({
      ... toggledDemographics,
      ... newFilter
    });
  };

  const toggleSelected = () => {
    setAllSelected(!allSelected);

    let _toggledDemographics = { ... toggledDemographics };
    Object.keys(_toggledDemographics).forEach((key) => {
      _toggledDemographics[key] = !allSelected;
    });

    setToggledDemographics(_toggledDemographics);

    return true;
  };

  return (
    <section style={ { marginLeft: '-10px', marginTop: '-15px' } }>
      <Collapse ghost>
        <Panel
          extra={ <Button type="link" size="small" onClick={ (e) => toggleSelected() && e.stopPropagation() }>{ allSelected ? filterByNonePlaceholder : filterByAllPlaceholder }</Button>}
          header={ <> { category }: { taxonomy}</> }
          key={ category + '_' + taxonomy }
        >
          <section style={ { marginTop: '-15px' } }>
            {
              values.map((value, index) => (
                <div style={ { marginLeft: 10, marginBottom: 5, color: (missing.indexOf([ category, taxonomy, value ].join('/')) > -1) ? '#e5e3e3' : 'inherit' } } key={ index }>
                  <Space>
                    <Switch
                      checked={ toggledDemographics[ [ category, taxonomy, value ].join('/') ] }
                      onChange={ () => toggleDemographic(category, taxonomy, value) }
                    />
                    <small>{ value }</small>
                  </Space>
                </div>
              ))
            }
          </section>
        </Panel>
      </Collapse>
    </section>
  );
};

function EmotionSwitchBlock({values, onChange,onResetFilters,setIsReset}) {

  const [ toggledEmotion, setToggledEmotion ] = useState({});
  const [ allSelected, setAllSelected ] = useState(true);
  const [deafultEmotion, setDeafultEmotion]=  useState({});
  const intl = useIntl();
  const emotionPlaceholder = intl.formatMessage({id:"app.explorePage.emotion",defaultMessage:"Emotion"});
  const filterByNonePlaceholder = intl.formatMessage({id: 'app.explorePage.filterByNone',defaultMessage: 'None'});
  const filterByAllPlaceholder = intl.formatMessage({id: 'app.explorePage.filterByAll',defaultMessage: 'All'});
   
  useEffect(() => {
    let _toggledEmotion = {  ... toggledEmotion };
    values.forEach((value) => {
      if (_toggledEmotion[value] === undefined) {
        _toggledEmotion[value] = true;
      }
    });
    setToggledEmotion(_toggledEmotion);
    setDeafultEmotion(_toggledEmotion);
  }, [ values ]);


  useEffect(() => {
    if (onChange) {
      onChange(toggledEmotion);
    }
  }, [ toggledEmotion ]);


  useEffect(() => {
    if (onResetFilters) {
      setToggledEmotion(deafultEmotion);
       setIsReset(false);
    }
  }, [ onResetFilters ]);

  const toggleEmotion = (value) => {
    let newFilter = {};

    newFilter[value] = !toggledEmotion[value] ? true : false;
    setToggledEmotion({
      ... toggledEmotion,
      ... newFilter
    });
  };

  const toggleSelected = () => {
    setAllSelected(!allSelected);

    let _toggledEmotion = { ... toggledEmotion };
    Object.keys(_toggledEmotion).forEach((key) => {
      _toggledEmotion[key] = !allSelected;
    });

    setToggledEmotion(_toggledEmotion);

    return true;
  };

  return (
    <section style={ { marginLeft: '-10px', marginTop: '-15px' } }>
      <Collapse ghost>
        <Panel
          key="1"
          extra={ <Button type="link" size="small" onClick={ (e) => toggleSelected() && e.stopPropagation() }>{ allSelected ? filterByNonePlaceholder : filterByAllPlaceholder }</Button>}
          header={ <> {emotionPlaceholder}</> }
        >
          <section style={ { marginTop: '-15px' } }>
            {
              values.map((value, index) => (
                <div style={ { marginLeft: 10, marginBottom: 5, color:'inherit' } } key={ index }>
                  <Space>
                    <Switch
                      checked={ toggledEmotion[value] }
                      onChange={ () => toggleEmotion(value) }
                    />
                    <small>{ value }</small>
                  </Space>
                </div>
              ))
            }
          </section>
        </Panel>
      </Collapse>
    </section>
  );
};


function EmotionBlocks({ data, filteredSubProjectKeys, onChange, onNoneChanged, onResetFilters,setIsReset }) {

  const [ foundEmotionKeys, setFoundEmotionKeys ] = useState({});
  const [ emotionBlocks, setEmotionBlocks ] = useState([]);
  const [ toggledEmotion, setToggledEmotion ] = useState({});

  useEffect(() => {
    let _foundEmotionKeys = {};
    data.forEach((doc) => {
      _foundEmotionKeys[doc.subproject] = true;
    });
    setFoundEmotionKeys(_foundEmotionKeys);
  }, [ data ]);

  useEffect(() => {
    let valuesArray = _.uniq(data.map((doc) => doc.plutchik_category? doc.plutchik_category: "Include Missing"));
    //to sort the emotions and always keep include missing at the top
    valuesArray.sort((a, b) => {
      if (a === "Include Missing") {
        return -1;
      }
      if (b === "Include Missing") {
        return 1;
      }
      return a.localeCompare(b);
    });
    setEmotionBlocks(valuesArray);
    
  }, [ foundEmotionKeys ]);

  useEffect(() => {
    if (onChange) {
      onChange(toggledEmotion);
    }
  }, [ toggledEmotion ]);

  return (
    <>
      {
        <>
            {
                <EmotionSwitchBlock values={ emotionBlocks } 
                onChange={ (blockEmotion) => setToggledEmotion({ ... toggledEmotion, ... blockEmotion } )} 
                onResetFilters={onResetFilters} setIsReset={setIsReset} />
            }
        </>
      }
    </>
  );
};
function SubProjectSwitchBlock({values, onChange,onResetFilters,setIsReset}) {

  const [ toggledSubProjects, setToggledSubProjects ] = useState({});
  const [ allSelected, setAllSelected ] = useState(true);
  //const [ sortedValues, setSortedValues ] = useState([]);
  const [deafultSubProjects, setDeafultSubProjects]=  useState({});
  const intl = useIntl();
  const subProjectPlaceholder = intl.formatMessage({id:"app.explorePage.subProject",defaultMessage:"Sub-Project"});
  const filterByNonePlaceholder = intl.formatMessage({id: 'app.explorePage.filterByNone',defaultMessage: 'None'});
  const filterByAllPlaceholder = intl.formatMessage({id: 'app.explorePage.filterByAll',defaultMessage: 'All'});
   
  useEffect(() => {
    let _toggledSubProjects = {  ... toggledSubProjects };
    values.forEach((value) => {
      if (_toggledSubProjects[value] === undefined) {
        _toggledSubProjects[value] = true;
      }
    });
    setToggledSubProjects(_toggledSubProjects);
    setDeafultSubProjects(_toggledSubProjects);
  }, [ values ]);


  useEffect(() => {
    if (onChange) {
      onChange(toggledSubProjects);
    }
  }, [ toggledSubProjects ]);


  useEffect(() => {
    if (onResetFilters) {
      setToggledSubProjects(deafultSubProjects);
       setIsReset(false);
    }
  }, [ onResetFilters ]);

  const toggleSubProjects = (value) => {
    let newFilter = {};

    newFilter[value] = !toggledSubProjects[value] ? true : false;
    setToggledSubProjects({
      ... toggledSubProjects,
      ... newFilter
    });
  };

  const toggleSelected = () => {
    setAllSelected(!allSelected);

    let _toggledSubProjects = { ... toggledSubProjects };
    Object.keys(_toggledSubProjects).forEach((key) => {
      _toggledSubProjects[key] = !allSelected;
    });

    setToggledSubProjects(_toggledSubProjects);

    return true;
  };

  return (
    <section style={ { marginLeft: '-10px', marginTop: '-15px' } }>
      <Collapse ghost>
        <Panel
          key="1"
          extra={ <Button type="link" size="small" onClick={ (e) => toggleSelected() && e.stopPropagation() }>{ allSelected ? filterByNonePlaceholder : filterByAllPlaceholder }</Button>}
          header={ <> {subProjectPlaceholder}</> }
        >
          <section style={ { marginTop: '-15px' } }>
            {
              values.map((value, index) => (
                <div style={ { marginLeft: 10, marginBottom: 5, color:'inherit' } } key={ index }>
                  <Space>
                    <Switch
                      checked={ toggledSubProjects[value] }
                      onChange={ () => toggleSubProjects(value) }
                    />
                    <small>{ value }</small>
                  </Space>
                </div>
              ))
            }
          </section>
        </Panel>
      </Collapse>
    </section>
  );
};

function SubProjectBlocks({ data, filteredSubProjectKeys, onChange, onNoneChanged, onResetFilters,setIsReset }) {

  const [ foundSubProjectKeys, setFoundSubProjectKeys ] = useState({});
  const [ subProjectBlocks, setSubProjectBlocks ] = useState([]);
  const [ toggledSubProject, setToggledSubProject ] = useState({});

  useEffect(() => {
    let _foundSubProjectKeys = {};
    data.forEach((doc) => {
      _foundSubProjectKeys[doc.subproject] = true;
    });
    setFoundSubProjectKeys(_foundSubProjectKeys);
  }, [ data ]);

  useEffect(() => {
    let blocks = [];
    let valuesArray = _.uniq(data.map((doc) => doc.comment.conversation? doc.comment.conversation.text: ""));
    let values = [];
    if(valuesArray.length != 0)
    {
      values = [...['Include Missing'], ...(valuesArray)];
    }
    else{
      values = valuesArray
    }
    setSubProjectBlocks(values);
    
  }, [ foundSubProjectKeys ]);

  useEffect(() => {
    if (onChange) {
      onChange(toggledSubProject);
    }
  }, [ toggledSubProject ]);

  return (
    <>
      {
        <>
            {
                <SubProjectSwitchBlock values={ subProjectBlocks } onChange={ (blockSubProject) => setToggledSubProject({ ... toggledSubProject, ... blockSubProject } )} onResetFilters={onResetFilters} setIsReset={setIsReset} />
            }
        </>
      }
    </>
  );
};


function DemographicBlocks({ data, filteredDemographicKeys, onChange, onNoneChanged, onResetFilters,setIsReset }) {

  const [ demographicIncludeNone, setDemographicIncludeNone ] = useState(true);
  const [ foundDemographicKeys, setFoundDemographicKeys ] = useState({});
  const [ missingDemographicKeys, setMissingDemographicKeys ] = useState({});
  const [ demographicBlocks, setDemographicBlocks ] = useState([]);
  const [ toggledDemographics, setToggledDemographics ] = useState({});

  const { taxonomies } = useContext(DemographicsContext);
  const { taxonomyMap } = useContext(DemographicsContext);

  useEffect(() => {
    let _foundDemographicKeys = {};
    data.forEach((doc) => {
      if (doc.comment && doc.comment.demographics) {
        doc.comment.demographics.forEach((demographic) => {
          const key = [ demographic.category, demographic.taxonomy, demographic.value ].join('/');
          _foundDemographicKeys[key] = true;
        });
      }
    });
    setFoundDemographicKeys(_foundDemographicKeys);
  }, [ data ]);

  useEffect(() => {

    let blocks = [];
    _.uniq(Object.values(taxonomyMap).map((doc) => doc[0].category)).map((category, index) => (
      _.uniq(_.flatten(Object.values(taxonomyMap)).filter((doc) => doc.category == category).map((doc) => doc.taxonomy)).map((taxonomy, index) => {
        let valuesArray = _.uniq(taxonomyMap[taxonomy].filter((doc) => doc.taxonomy == taxonomy).map((doc) => doc.value)).map((value, index) => {
          const key = [ category, taxonomy, value ].join('/');
          return (
            !foundDemographicKeys[key] ? null : value
          );
        }).filter((el) => el != null);
        let values = [];
        if(valuesArray.length != 0)
        {
          values = [...['Include Missing'], ...sortDemographics(valuesArray)];
        }
        else{
          values = valuesArray
        }
        blocks.push({ category, taxonomy, values });
      })
    ));
    setDemographicBlocks(blocks);
  }, [ foundDemographicKeys, taxonomyMap ]);

  useEffect(() => {
    const missingKeys = _.difference(Object.keys(foundDemographicKeys), Object.keys(filteredDemographicKeys));
    console.log('missing', missingKeys);

    setMissingDemographicKeys(missingKeys);
  }, [ demographicBlocks, filteredDemographicKeys ]);

  useEffect(() => {
    if (onChange) {
      onChange(toggledDemographics);
    }
  }, [ toggledDemographics ]);

  useEffect(() => {
    if (onNoneChanged) {
      onNoneChanged(demographicIncludeNone);
    }
  }, [ demographicIncludeNone ]);

  return (
    <>
      {
        <>
          <section style={ { marginLeft: '-10px', marginTop: '-5px' } }>
            <Collapse defaultActiveKey={ ['missing'] } ghost>
              <Panel
                header="Missing demographic data"
                key={ "missing" }
              >
                <section style={ { marginLeft: 10, marginTop: '-15px' } }>
                  <Space>
                    <Switch checked={ demographicIncludeNone } onChange={ () => setDemographicIncludeNone(!demographicIncludeNone) } />
                    <small>Include</small>
                  </Space>
                </section>
              </Panel>
            </Collapse>
          </section>

          <>
            {
              demographicBlocks.map(({ category, taxonomy, values }, index) => (
                values.length > 0 &&
                <DemographicSwitchBlock key={index} missing={ missingDemographicKeys } values={ values } category={ category } taxonomy={ taxonomy } onChange={ (blockDemographics) => setToggledDemographics({ ... toggledDemographics, ... blockDemographics } )} onResetFilters={onResetFilters} setIsReset={setIsReset} />
              ))
            }
          </>
        </>
      }
    </>
  );
};

export default function Filters({ data, onChange }) {

  const [ emojisInData, setEmojisInData ] = useState([]);
  const [ filteredDemographicKeys, setFilteredDemographicKeys ] = useState([]);

  const [ filteredSubProjectKeys, setFilteredSubProjectKeys ] = useState([]);
  const [ subProjectIncludeNone, setSubProjectIncludeNone ] = useState(true);
  const [ subProjectFilters, setSubProjectFilters ] = useState({});

  const [ filteredEmotionKeys, setFilteredEmotionKeys ] = useState([]);
  const [ emotionIncludeNone, setEmotionIncludeNone ] = useState(true);
  const [ emotionFilters, setEmotionFilters ] = useState({});

  const [ initFilterApplied, setInitFilterApplied ] = useState(false);
  const [ demographicIncludeNone, setDemographicIncludeNone ] = useState(true);
  const [ showingFilterChangedMessage, setShowingFilterChangedMessage ] = useState(false);
  const [ demographicFilters, setDemographicFilters ] = useState({});
  const [ foundDemographicKeys, setFoundDemographicKeys ] = useState({});
  const [isReset, setIsReset] = useState(false);
  const [ filterRules, setFilterRules ] = useState({
    search: '',
    emoji: [ true, true, true, true, true, true, true ]
  });
  const [form] = Form.useForm();
  const intl = useIntl();
  const filterResultsPlaceholder = intl.formatMessage({id: 'app.explorePage.filterResults',defaultMessage: 'Filter by search'});
  const resetFiltersPlaceholder = intl.formatMessage({id: 'app.explorePage.resetFilters',defaultMessage: 'Reset Filters'});
  const filterBySentimentPlaceholder = intl.formatMessage({id: 'app.explorePage.filterBySentiment',defaultMessage: 'Filter by sentiment'});
  const filterByDemographicPlaceholder = intl.formatMessage({id: 'app.explorePage.filterByDemographic',defaultMessage: 'Filter by demographics'});
	const filterBySubProjectPlaceholder = intl.formatMessage({id: 'app.explorePage.filterBySubProject',defaultMessage: 'Filter by sub-project'});
  const filterByEmotionPlaceholder = intl.formatMessage({id: 'app.explorePage.filterByEmotion',defaultMessage: 'Filter by emotion'});
  
  useEffect(() => {
    console.log('Filters->data', data);
    console.log('emojis', _.uniq(data.map((doc) => Math.ceil(doc.sentiment) )));
    setEmojisInData(_.uniq(data.map((doc) => Math.ceil(doc.sentiment) )));

  }, [ data ]);

  useEffect(() => {
    console.log('applyFilter();');
    applyFilter();
    // if (initFilterApplied && data.length > 200) {
    //   filterChangedMessage();
    // } else {
    //   applyFilter();
    //   setInitFilterApplied(true);
    // }
  }, [ filterRules, demographicFilters, demographicIncludeNone, subProjectFilters, emotionFilters ]);

  const filterChangedMessage = () => {
    if (showingFilterChangedMessage) {
      return;
    }
    message.success({
      content: (
        <>
          When you're finished changing filters click apply to update
          &nbsp;&nbsp;&nbsp;
          <Button type="primary" onClick={ () => applyFilter }>Apply</Button>
          <Button type="link">Undo</Button>
        </>
      ),
      className: 'custom-class',
      style: {
        position: 'fixed',
        marginBottom: '40px',
        left: 'calc(50% + 200px)',
        width: 600,
        bottom: 0,
        marginLeft: '-300px',
      },
      duration: 60 * 10 * 1000,
    }).then(() => {
      setShowingFilterChangedMessage(false);
    });
    setShowingFilterChangedMessage(true);
  };
  const isSubProjectIncludeMissing = (_processedData) => {
    let missingSubProjectKeys = Object.keys(subProjectFilters).filter(value => {
      let retain = false;
      if (value === "Include Missing" && !subProjectFilters[value])
        retain = true;
      return retain;
    });
    let _subProjectProcessedData;

    if (missingSubProjectKeys.length > 0) {
      _subProjectProcessedData = data.filter((doc) => {

        return missingSubProjectKeys.every((value) => {
          let keep = false;
          if (doc.comment.conversation && doc.comment.conversation.text === null) {
            keep = true;
            console.log('Removing from list due to demographic filter rule:', value, doc);
          }
          return keep;
        });
      });
    }
    else {
      _subProjectProcessedData = _processedData;
    }
    return _subProjectProcessedData;
  }

  const applyFilter = () => {
    if(!isReset){
    console.log('applyFilter->called')
    let missingKeys=[];
    missingKeys = Object.keys(demographicFilters).filter(rule => {
      let retain = false
      const [ category, taxonomy, value ] = rule.split('/');
      if(value === "Include Missing" && !demographicFilters[rule])
      retain = true;
      return retain
    })
    let _processedData;

    if(missingKeys.length > 0)
    {
      _processedData = data.filter((doc) => {
    
    return missingKeys.every((rule) =>{
      let keep = false;
      const [ category, taxonomy, value ] = rule.split('/');
      doc.comment.demographics.forEach((demographic) => {
        if (demographic.category === category && demographic.taxonomy === taxonomy) {
          keep = true;
          console.log('Removing from list due to demographic filter rule:', rule, doc)
        }
      });
      return keep;
    })
  });
  }
  else{
    _processedData = data
  }
  //Check and cleaning the data for sub project type Include missing"
    let _subProjectProcessedData = isSubProjectIncludeMissing(_processedData);
  
 //Applying rest of the filters on top of include missing data for sub project and demographics
    const _filteredData = _subProjectProcessedData.filter((doc) => {
      let keep = true;

      if (keep && !filterRules.emoji[Math.ceil(doc.sentiment - 1)]) {
        keep = false;
      }

      if (keep && filterRules.search != '') {
        keep = doc.text.toLowerCase().indexOf(filterRules.search.toLowerCase()) > -1;
      }

      Object.keys(emotionFilters).forEach((rule) =>{
        if(keep && doc && !emotionFilters[rule])
        {
            if(doc.plutchik_category === rule)
            {
              keep =false;
            }
            if(rule === "Include Missing" && doc.plutchik_category=== "")
            {
              keep =false;
            }
        }
      })

      Object.keys(subProjectFilters).forEach((rule) =>{
        if(keep && doc.comment && doc.comment.conversation && !subProjectFilters[rule])
        {
            if(doc.comment.conversation.text === rule)
            {
              keep =false;
            }
        }
      })

      Object.keys(demographicFilters).forEach((rule) => {
        if (keep && doc.comment && doc.comment.demographics && !demographicFilters[rule]) {
          const [ category, taxonomy, ...valueArr ] = rule.split('/');
         //In cisco there is one case of Opportunity/Competitors/HPE/Aruba, so added it as an array to avoid any logical conflicts with such cases. 
          const value = valueArr.join('/');
         //condition is triggered if any value switch is disabled
          if(value !== "Include Missing")
          {
          doc.comment.demographics.forEach((demographic) => {            
             if (demographic.category == category && demographic.taxonomy == taxonomy && demographic.value == value) {
              keep = false;
              console.log('Removing from list due to demographic filter rule:', rule, doc)
            }
          });
         }
        }
      });
      if (keep && doc.comment && doc.comment.demographics && doc.comment.demographics.length == 0 && !demographicIncludeNone) {
        keep = false;
      }
      return keep;
    });

    let _filteredDataFoundDemographicKeys = {};
    _filteredData.forEach((doc) => {
      if (doc.comment && doc.comment.demographics) {
        doc.comment.demographics.forEach((demographic) => {
          const key = [ demographic.category, demographic.taxonomy, demographic.value ].join('/');
          _filteredDataFoundDemographicKeys[key] = true;
        });
      }
    });
    setFilteredDemographicKeys(_filteredDataFoundDemographicKeys)
    onChange(_filteredData);
  }
  else{
    setIsReset(false);
  }

  };

  const toggleMoodSelection = (idx) => () => {
    let _emojiRules = filterRules.emoji;
    _emojiRules[idx] = !_emojiRules[idx];

    setFilterRules({
      ... filterRules,
      ... {
        emoji: _emojiRules
      }
    });
  };

  const keywordSearchChanged = (e) => {
    setFilterRules({
      ... filterRules,
      ... {
        search: e.target.value
      }
    });
  };

  const resetFilters = () => {
    setIsReset(true);
    let _filteredDataFoundDemographicKeys = {};
    data.forEach((doc) => {
      if (doc.comment && doc.comment.demographics) {
        doc.comment.demographics.forEach((demographic) => {
          const key = [ demographic.category, demographic.taxonomy, demographic.value ].join('/');
          _filteredDataFoundDemographicKeys[key] = true;
        });
      }
    });
    setFilteredDemographicKeys(_filteredDataFoundDemographicKeys)
    setDemographicFilters(_filteredDataFoundDemographicKeys);
    onChange(data);
    form.setFieldsValue({ search: '' });
    setFilterRules({
      ... filterRules,
      ... {
      search: '',
      emoji: [ true, true, true, true, true, true, true ]
      }
    });
  };

  return (
    <div style={ classes.container }>
      <section style={ classes.spacer }>
    <Button type="primary" style={{ borderRadius: "8px", minWidth:"140px" }} onClick={ (e) => resetFilters() }>{resetFiltersPlaceholder}</Button>
    </section>
    <Collapse defaultActiveKey={['1']}>
    <Panel header= {filterResultsPlaceholder} key="1">     
        <Form form={form} initialValues={{search: ''}}>
          <Form.Item name="search">
          <Input placeholder="" onChange={ keywordSearchChanged } />
          </Form.Item>
        </Form>
      </Panel>
      <Panel header= {filterBySentimentPlaceholder} key="2">
      <section style={ classes.spacer }>
        {/* <div style={classes.filterName}>Filter by sentiment</div> */}
        <Radio.Group>
          {moodList.map((item, index) => (
            <Radio.Button
              key={ index }
              value="horizontal"
              style={ {
                ... classes.buttonGroupItem,
                ... (filterRules.emoji[index] ? classes.radioChecked : classes.radioUnchecked ),
                ... (emojisInData.indexOf(index + 1) == -1 ? classes.radioDisabled : {})
              } }
              onClick={ toggleMoodSelection(index) }
              checked={ filterRules.emoji[index] }
              disabled={ emojisInData.indexOf(index + 1) == -1 }
            >
              <div className={ item.image } style={classes.moodSizeControl} />
            </Radio.Button>
          ))}
        </Radio.Group>
      </section>
      </Panel>
      <Panel header= {filterByEmotionPlaceholder} key="3" >
      <EmotionBlocks data={ data } filteredEmotionKeys={ filteredEmotionKeys } onChange={ (newEmotionFilters) => setEmotionFilters(newEmotionFilters) } onNoneChanged={ (value) => setEmotionIncludeNone(value) } onResetFilters={isReset} setIsReset={setIsReset} />
      </Panel>
      <Panel header= {filterBySubProjectPlaceholder} key="4" >
      <SubProjectBlocks data={ data } filteredSubProjectKeys={ filteredSubProjectKeys } onChange={ (newSubProjectFilters) => setSubProjectFilters(newSubProjectFilters) } onNoneChanged={ (value) => setSubProjectIncludeNone(value) } onResetFilters={isReset} setIsReset={setIsReset} />
      </Panel>
      <Panel header={filterByDemographicPlaceholder} key="5">
      <DemographicBlocks data={ data } filteredDemographicKeys={ filteredDemographicKeys } onChange={ (newDemographicFilters) => setDemographicFilters(newDemographicFilters) } onNoneChanged={ (value) => setDemographicIncludeNone(value) } onResetFilters={isReset} setIsReset={setIsReset} />
      </Panel>
      </Collapse>
    </div>
  );
};