
import axios from '../http';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

function loginSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    data,
  };
}

function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error,
  };
}

export function login(data) {
  return async (dispatch) => {
    try {

      const res = await axios.post('/api/v1/users/login/', data);

      if (res.status !== 200) {
        throw Error(res.statusText);
      }

      dispatch(loginSuccess(res.data));

    } catch (error) {

      dispatch(loginFailure(error));
    }
  }
}

function logoutSuccess(data) {
  return {
    type: LOGOUT_SUCCESS,
    data,
  };
}

function logoutFailure(error) {
  return {
    type: LOGOUT_FAILURE,
    error,
  };
}

export function logout() {
  return async (dispatch) => {
    try {
      const res = await axios.get('/gateway/logout/');
      if (res.status !== 200) {
        throw Error(res.statusText);
      }
      dispatch(logoutSuccess(res.data));
    } catch (error) {
      dispatch(logoutFailure(error))
    }

  }
}