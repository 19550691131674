import React, { useState } from 'react';
import Graph from 'react-graph-vis';
import { v4 as uuidv4 } from 'uuid';
import { notEmpty } from '../../../utils';

export default function KnowledgeGraph({ colors, graph, onReset, onSelect }) {

  const [selectedNode, setSelectedNode] = useState({});

  const resetGraph = () => {
    setSelectedNode({});
    onReset();
  }

  const options = {
    // layout: {
    //   hierarchical: true
    // },
    edges: {
      color: "#000000"
    },
    height: "640px"
  };

  const events = {
    select: function(event) {
      const { nodes, edges } = event;
      console.log('nodes', nodes)
      const selected = graph.nodes.find((n) => n.id === nodes[0]);
      console.log('selected', selected)
      setSelectedNode(selected);
      onSelect({ nodes: [selected.id], edges:[] });
    }
  };

  return (
    <>
      <div style={{ marginBottom: '20px'}}>
        <Graph key={uuidv4()} graph={graph} options={options} events={events} />
      </div>
      {notEmpty(selectedNode) ?
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>&nbsp;</div>
          <div style={{backgroundColor: selectedNode.color, marginRight: '10px' }}>{selectedNode.node_type}:</div>
          <div>{selectedNode.title}</div>
          <div style={{ flex: 1 }}>&nbsp;</div>
        </div>
        : null
      }
      <fieldset style={{ width: '250px', marginTop: '20px'}}>
        <legend>Legend</legend>
        {Object.entries(colors).map(([k, v]) =>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
            <div style={{ backgroundColor: v, width: '20px', marginRight: '10px' }} />
            <div>{k}</div>
          </div>
        )}
      </fieldset>
      <button onClick={resetGraph}>Reset</button>
    </>
  );
};