import React, { useState, useEffect } from 'react';
import { NavLink, BrowserRouter as Router } from 'react-router-dom';

import TopBar from './components/TopBar';
import Routes from './routes';
import ForumIdContextProvider from './context/forumId-context';
import DemographicsContextProvider from './context/demographics-context';

import { Layout } from 'antd';
import { Menu } from 'antd';
import { AppstoreOutlined, DotChartOutlined, SettingOutlined, RadarChartOutlined,LineChartOutlined } from '@ant-design/icons';
import {FormattedMessage} from "react-intl";

const { Header, Footer, Sider, Content } = Layout;
const { SubMenu } = Menu;
  // The current width of the viewport
  const width = window.innerWidth;
  // The width below which the mobile view should be rendered
  const breakpoint = 768;
const platosDataMenuOptions = (forums = []) => {
  forums.sort((a, b) => {
    if (a.forumTitle < b.forumTitle) {
      return -1;
    }
    if (a.forumTitle > b.forumTitle) {
      return 1;
    }
    return 0;
  });
  const dataMenu = forums.map((f) => ({
    id: f.forumId,
    path: `/forums/${f.forumId}/overview`,
    title: f.forumTitle,
  }));
  return dataMenu;
};

function AuthenticatedApp({ user }) {

  const [ collapsed, setCollapsed ] = useState(false);
  const [ forumDataSources, setForumDataSources ] = useState([]);
  const [ selectedKeys, setSelectedKeys ] = useState(['all-forums']);
  const year = (new Date()).getFullYear();

  useEffect(() => {
    setForumDataSources(platosDataMenuOptions(user.info.forums || []));
  }, [ user ]);

  /**d
   * Determine which menu item to select in the sidebar based on the current
   * location pathname.
   */
  const makeSelectedKeys = () => {
    const pathname = window.location.pathname;
    const keys = [
      (pathname == '/') ? 'all-forums' : null,
      (pathname == '/dashboard') ? 'dashboard' : null,
    ].concat(
      forumDataSources.map((forum) =>
        (pathname.indexOf(`/forums/${forum.id}`) > -1) ? forum.id : null
      )
    ).filter((val) => val != null);
    setSelectedKeys(keys);
  };

  return (
    <ForumIdContextProvider>
      <DemographicsContextProvider>
        <Router>
          <Layout>
            <Sider collapsible onCollapse={ (value) => setCollapsed(value) }>
              <NavLink to="/" className={ "logo-image " + ((collapsed) ? 'collapsed' : '') }>
                <img src='/static/images/icons/PlaetosEQ_logo-primary_rgb-rev.png' alt='PlaetosEQ' width="170" style={ { margin: '5px 0 0 10px' } } />
              </NavLink>
              <Menu
                onSelect={ makeSelectedKeys }
                selectedKeys={ selectedKeys }
                defaultOpenKeys={['sources-menu']}
                theme="dark"
                mode="inline"
              >
                <Menu.Item key="dashboard" icon={ <DotChartOutlined /> }>
                  <NavLink to="/dashboard">
                    <FormattedMessage
                    id="app.navSlider.dashboard"
                    defaultMessage="My Dashboard"/>                    
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="taxonomy-explorer" icon={ <RadarChartOutlined /> }>
                  <NavLink to="/taxonomy-explorer">
                    <FormattedMessage
                    id="app.navSlider.taxonomyExplorer"
                    defaultMessage="Taxonomy Explorer"/> 
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="strategy-maps" icon={ <LineChartOutlined /> }>
                  <NavLink to="/strategy-maps">
                    <FormattedMessage
                    id="app.navSlider.startegyMap"
                    defaultMessage="Strategy Maps"/>
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="network-map" icon={ <LineChartOutlined /> }>
                  <NavLink to="/network-map">
                              Graph
                  </NavLink>
                </Menu.Item>
                {/* <SubMenu key="sources-menu" icon={<AppstoreOutlined />} title="Data Sources">
                  <Menu.ItemGroup key="g1" title="Forums">
                    <Menu.Item key="all-forums">
                      <NavLink to="/">
                        All Forums
                      </NavLink>
                    </Menu.Item>
                    {
                      forumDataSources.map((forum) => (
                        <Menu.Item key={ forum.id }>
                          <NavLink to={ forum.path }>
                            { forum.title }
                          </NavLink>
                        </Menu.Item>
                      ))
                    }
                  </Menu.ItemGroup>
                </SubMenu> */}
              </Menu>
            </Sider>
            <Layout style={{overflow:"auto"}}>
            <Layout className= "eqApp-width">
              <Header>
                <TopBar homePath="/"
                  platosDataMenuOptions={ forumDataSources }
                />
              </Header>
              <Content style={{ height:'100%', minHeight: '1024px', backgroundColor: '#fff' }}>
                <Routes forumDataSources={ forumDataSources } />
              </Content>
              <Footer>
                &copy; Plaetos Group, { year }
              </Footer>
            </Layout>
            </Layout>
          </Layout>
        </Router>
      </DemographicsContextProvider>
    </ForumIdContextProvider>
  );
}

export default AuthenticatedApp;