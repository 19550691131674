export default (theme) => ({
  centerMain: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    marginBottom: '10px',
    padding: '2px 0px 2px 0px',
  },
  centerSub: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
    marginBottom: '15px',
  },
  centerTextWrap: {
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    lineHeight: 1.31,
    textAlign: 'center',
  },
  contentWrap: {
    flex: 2,
    margin: '0 15%',
    marginTop: '40px',
    marginBottom: '70px',
    width: '70%',
  },
  imageStyle: {
    height: 'auto',
    maxWidth: '100%',
  },
  imageWrap: {
    border: 'solid 1px #efefef',
    marginBottom: '15px',
    padding: '15px',
  },
  rightExpansionDrawerWrap: {
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 4px 1px rgba(0, 0, 0, 0.3)',
    height: '112%',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '36px',
    zIndex: 101,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  sectionImageTitle: {
    backgroundColor: '#fff',
    borderRadius: '2px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12.2px',
    height: '19px',
    position: 'absolute',
    top: '-10px',
    left: '10px',
    textAlign: 'center',
    width: '50px',
  },
  sectionChunkAnalysisTitle: {
    backgroundColor: '#fff',
    borderRadius: '2px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12.2px',
    height: '19px',
    position: 'absolute',
    top: '-10px',
    left: '10px',
    textAlign: 'center',
    width: '110px',
  },
  sectionName: {
    color: '#516e88',
    fontSize: '34.5px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '41px',
    letterSpacing: '0.25px',
    lineHeight: '41px',
    marginBottom: '24px',
    marginTop: '40px',
    width: '361px',
  },
  textFieldInputWrap: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 1.41,
  },
  updateButtonWrap: {
    border: '1px solid #516e88',
    borderRadius: '2px',
    color: '#516e88',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '12.2px',
    padding: '2px 4px',
    position: 'absolute',
    top: '24px',
    right: '10px',
    textAlign: 'center',
    zIndex: 2,
  },
  updateIconStyle: {
    fontSize: '18px',
    opacity: 0.6,
  },
});