import React from "react";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {FormattedMessage} from "react-intl";
import ClusterChart from "../../charts/ClusterChart";
import NoData from '../NoData';

function ClusterAdvancedCard({ forumCommentCount, forumId, topicClusters }) {

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Most discussed topics
        </Typography>
        <div className={classes.centerContent}>
          <div className={classes.leftContent}>
            {topicClusters.nodes && topicClusters.nodes.length ? (
              <ClusterChart
                data={topicClusters}
                id="advancedCluster"
                forumId={forumId}
              />
            ) : (
              <NoData />
            )}
          </div>
          <div className={classes.rightContent}>
            <div className={classes.mainTextArea}>
              {forumCommentCount.map((topic, i) => (
                <div key={i}>
                  <Typography component="p" className={classes.contentItem}>
                    <span className={classes.bullet}>•</span>
                    <span className={classes.textStyle}>{topic.label}</span>
                  </Typography>
                </div>
              ))}
            </div>
            <div className={classes.buttonLine}>
              <Link to={`/forums/${forumId}/explore`}>
                <Button
                  className={classes.mainButton}
                  color="primary"
                  size="small"
                  variant="outlined"
                >
                  <FormattedMessage
                    id="app.exploreData"
                    defaultMessage="Explore Data"
                  />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles(() => ({
  bullet: {
    display: 'inline-block',
    margin: '0 4px',
  },
  buttonLine: {
    position: 'absolute',
    bottom: '12px',
    right: '20px',
    textAlign: 'right',
  },
  card: {
    height: '100%',
    padding: '4px',
  },
  cardContent: {
    height: '100%',
    position: 'relative'
  },
  centerContent: {
    display: 'flex',
    height: '100%',
    paddingBottom: 12,
    paddingTop: 12,
    textAlign: 'center',
    width: '100%',
  },
  contentItem: {
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'inline-flex',
    fontSize: '16.3px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.5,
    lineHeight: 'normal',
    marginBottom: '10px',
  },
  leftContent: {
    width: '65%',
  },
  mainButton: {
    color: '#516e88',
    display: 'inline-block',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    height: '36px',
    letterSpacing: 1.25,
    lineHeight: 1.12,
    width: '153px',
  },
  mainTextArea: {
    paddingTop: '32px',
    textAlign: 'left',
  },
  rightContent: {
    width: '35%'
  },
  textStyle: {

  },
  title: {
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
    color: 'rgba(0, 0, 0, 0.38)',
  },
}));

export default ClusterAdvancedCard;