import { gql } from "@apollo/client";

import { setupApi } from "../graphql/client";

export const FETCH_DESCRIPTION_LOADING = "FETCH_DESCRIPTION_LOADING";
export const FETCH_DESCRIPTION_SUCCESS = "FETCH_DESCRIPTION_SUCCESS";
export const FETCH_DESCRIPTION_FAILURE = "FETCH_DESCRIPTION_FAILURE";

export const FETCH_CHUNKS_LIST_LOADING = "FETCH_CHUNKS_LIST_LOADING";
export const FETCH_CHUNKS_LIST_SUCCESS = "FETCH_CHUNKS_LIST_SUCCESS";
export const FETCH_CHUNKS_LIST_FAILURE = "FETCH_CHUNKS_LIST_FAILURE";

const client = setupApi();

function fetchDescriptionLoading() {
  return {
    type: FETCH_DESCRIPTION_LOADING,
  };
}

function fetchDescriptionSuccess(payload) {
  return {
    type: FETCH_DESCRIPTION_SUCCESS,
    payload,
  };
}

function fetchDescriptionFailure(payload) {
  return {
    type: FETCH_DESCRIPTION_FAILURE,
    payload,
  };
}

export function getTokensDescription(client_id, uids, nodeLabel) {
  return async (dispatch) => {
    try {
      let gqlQuery;
      dispatch(fetchDescriptionLoading());
      gqlQuery = {
        query: gql`
          query GetTokensSummary(
            $client_id: String!
            $uids: [String!]!
            $nodeLabel: String!
          ) {
            tokens_summary(
              node_label: $nodeLabel
              client_id: $client_id
              uids: $uids
              exclude_tokens: ["is", "are"]
              limit: 15
            ) {
              token
              pos_tag
              frequency
            }
          }
        `,
        variables: {
          client_id,
          uids,
          nodeLabel,
        },
      };
      const res = await client.query(gqlQuery);
      return dispatch(fetchDescriptionSuccess(res.data.tokens_summary));
    } catch (error) {
      dispatch(
        fetchDescriptionFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}
function fetchChunksListLoading() {
  return {
    type: FETCH_CHUNKS_LIST_LOADING,
  };
}

function fetchChunksListSuccess(payload) {
  return {
    type: FETCH_CHUNKS_LIST_SUCCESS,
    payload,
  };
}

function fetchChunksListFailure(payload) {
  return {
    type: FETCH_CHUNKS_LIST_FAILURE,
    payload,
  };
}
export function getChunksList(client_id, uids) {
  return async (dispatch) => {
    try {
      let gqlQuery;
      dispatch(fetchChunksListLoading());
      gqlQuery = {
        query: gql`
          query GetChunksList($client_id: String!, $uids: [String!]!) {
            chunks(where: { client_id: $client_id, uid_IN: $uids }) {
              uid
              text
              sentiment {
                sentiment
              }
            }
          }
        `,
        variables: {
          client_id,
          uids,
        },
      };
      const res = await client.query(gqlQuery);
      dispatch(fetchChunksListSuccess(res.data.chunks.payload));
      return res.data.chunks;
    } catch (error) {
      dispatch(
        fetchChunksListFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

export function getCommentsList(client_id, uids) {
  return async (dispatch) => {
    try {
      let gqlQuery;
      dispatch(fetchChunksListLoading());
      gqlQuery = {
        query: gql`
          query GetCommentsList($client_id: String!, $uids: [String!]!) {
            comments(where: { client_id: $client_id, uid_IN: $uids }) {
              uid
              text
              sentiment {
                sentiment
              }
            }
          }
        `,
        variables: {
          client_id,
          uids,
        },
      };
      const res = await client.query(gqlQuery);
      dispatch(fetchChunksListSuccess(res.data.comments));
      return res.data.comments;
    } catch (error) {
      dispatch(
        fetchChunksListFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}


export function getTokensSummary(chunksText) {
  return async (dispatch) => {
    try {
    
      let gqlQuery;
      dispatch(fetchChunksListLoading());
      gqlQuery = {
        query: gql`
          query gpt_summarize($chunksText: String! ) {
            gpt_summarize(text: $chunksText) 
          }
        `,
        variables: {
          chunksText
        },
      };
      const resp = await client.query(gqlQuery);
      dispatch(fetchDescriptionSuccess(resp));
      return resp.data.gpt_summarize;
    } catch (error) {
      dispatch(
        fetchDescriptionFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}