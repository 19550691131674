// This mapping is used to decide whether a click from Home to Commendview is belong to Sentimented Topics
// The mapping is temporary fixed unless more topics are added to the model
export const topicMapping = [
  "Family, Friends & Workmates",
  "News",
  "Tech & Internet",
  "Work",
  "Education & Training",
  "Health & Wellbeing",
  "Travel",
  "Finance",
  "Beliefs & Religion",
  "Agriculture & Animals",
  "entertainment",
  "Politics",
  "Laws & Justice",
  "Business",
  "Crime",
  "Immigration",
  "Harassment",
  "Climate",
  "Sports",
  "Technology",
  "Transport",
  "Energy",
  "Conflict & War",
  "Art",
  "Economy",
  "Food",
  "Globalized world",
  "Racism",
  "Science"
]