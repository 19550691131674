import {
  FETCH_PROBSOL_LOADING,
  FETCH_PROBSOL_SUCCESS,
  FETCH_PROBSOL_FAILURE
} from '../actions/problem-solution';

const initialState = {
  data: {},
  error: null,
  loaded: false,
  loading: true,
};

function probsol(state = initialState, action) {
  switch(action.type) {
    case FETCH_PROBSOL_LOADING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_PROBSOL_SUCCESS:
      return {
        data: action.payload,
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_PROBSOL_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };

    default:
      return state;
  }
};

export {
  probsol
};
