// no need for success / failure events because this update is synchronous
// and an error would be a programming issue, not a runtime issue such as
// unexpected data input.

export const UPDATE_DATE_RANGE = 'UPDATE_DATE_RANGE';

export function updateDateRange({ startDate, endDate }) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_DATE_RANGE,
      payload: {
        startDate: startDate,
        endDate: endDate,
      }
    });
}