import {
  SEARCH_LOADING,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
} from '../actions/search';

const initialState = {
  data: [],
  error: null,
  loaded: false,
  loading: true,
};

export function search(state = initialState, action) {
  switch (action.type) {
    case SEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SEARCH_SUCCESS:
      return {
        data: action.payload || [],
        error: null,
        loaded: true,
        loading: false,
      };

    case SEARCH_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };

    default:
      return state;
  }
}