import React from 'react';
import { Spin } from 'antd';

function UnauthenticatedApp() {

  window.location.href = '/gateway/';

  return (
    <div style={{ margin: '50px', textAlign: 'center' }}>
      <Spin />
    </div>
  );
}

export default UnauthenticatedApp;