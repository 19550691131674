import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';

function ParticipationCard({ data }) {

  const { ageGroupStats, genderStats } = data;
  const { female, male } = genderStats;

  let genderRelation;
  let highest;
  let highestPercent;
  if (female.count > male.count) {
    genderRelation = 'higher than';
    highest = ['Women', 'Men'];
    highestPercent = [formatPercent(female.percent), formatPercent(male.percent)];
  } else if (female.count < male.count) {
    genderRelation = 'less than';
    highest = ['Men', 'Women'];
    highestPercent = [formatPercent(male.percent), formatPercent(female.percent)];
  } else {
    genderRelation = 'the same as';
  }

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Participation
        </Typography>
        <div className={classes.centerContent}>
          <div className={classes.contentItem}>
            <div className={classes.itemText}>
              Highest participation across all Platos forums was from
              the {ageGroupStats.highest.group} Age Group.
            </div>
            <KeyboardArrowRightIcon className={classes.itemIcon} />
          </div>
          <div className={classes.contentItem}>
            <div className={classes.itemText}>
              Participation by women at {formatPercent(female.percent)} was {genderRelation} men
              at {formatPercent(male.percent)} across all Platos forums.
            </div>
            <KeyboardArrowRightIcon className={classes.itemIcon} />
          </div>
          <div className={classes.contentItem}>
            <div className={classes.itemText}>
              Active participation was highest amongst the {ageGroupStats.highest.group} Age Group
              at {formatPercent(ageGroupStats.highest.percent)} of registered participants.
            </div>
            <KeyboardArrowRightIcon className={classes.itemIcon} />
          </div>
          <div className={classes.contentItem}>
            {highest ?
              <div className={classes.itemText}>
                Active participation was highest amongst {highest[0]} at {highestPercent[0]} compared
                to {highest[1]} at {highestPercent[1]} of registered participants.
              </div>
              :
              <div className={classes.itemText}>
                Active participation was equal amongst Women at {formatPercent(female.percent)} and
                Men at {formatPercent(male.percent)} of registered participants.
              </div>
            }
            <KeyboardArrowRightIcon className={classes.itemIcon} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function formatPercent(number) {
  return parseFloat(number * 100).toFixed(0) + '%';
}

const useStyles = makeStyles(() => ({
  card: {
    padding: '4px',
    height: '100%',
  },
  centerContent: {
    marginTop: '10px',
    maxHeight: '440px',
    overflow: 'auto',
  },
  contentItem: {
    alignItems: 'center',
    borderBottom: '1px solid #efefef',
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.25,
    lineHeight: '1.41',
    padding: '16px 0',
  },
  itemIcon: {
    color: 'rgba(84, 110, 122, 0.8)',
    display: 'inline-block',
    marginLeft: '18px',
  },
  itemText: {
    display: 'inline-block',
    width: '90%',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
  },
}));

export default ParticipationCard;