import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { makeStyles } from '@material-ui/core/styles';
import clonedeep from 'lodash.clonedeep';
import equal from 'fast-deep-equal';
import { useIntl } from "react-intl";
import {
  deleteReport,
  deleteReportSection,
  saveReport,
  saveReportSection,
} from '../actions/reports';
import { combineStyles } from '../utils';
import ReportEdit from './ReportEdit';
import commonStyles from './common_styles';
import reportActions from '../actions/reports';


export function ReportItem({ classes, section, isOutline = false }) {
  // To deal with saving empty image section
  let content;
  const intl = useIntl();
  const activeSetPlaceholder = intl.formatMessage({id: 'app.reports.activeSet',defaultMessage: 'Active Set:'});
  const sortingPlaceholder = intl.formatMessage({id: 'app.reports.sorting',defaultMessage: 'Sorting:'});
  const sentimentPlaceholder = intl.formatMessage({id: 'app.reports.sentiment',defaultMessage: 'Average Sentiment:'});
  const chunksPlaceholder = intl.formatMessage({id: 'app.reports.chunks',defaultMessage: 'Chunks Count:'});
  const summaryPlaceholder =intl.formatMessage({id: 'app.reports.summary',defaultMessage: 'Summary:'});

  if(section.section_type === 'image' || section.section_type === 'chunk'){
    content = section.content ? JSON.parse(section.content) : null;
    
  }else{
    content = section.content && section.content !=="{}" ? JSON.parse(section.content): null;
  }
  
  if (section.section_type === 'image') {
    return (
      <React.Fragment>
        {content ?
          <div id={section.id} className={classes.sectionWrap} data-test="component-image">
            {!isOutline ?
              <div className={classes.imageWrap}data-test="image-tag" >
                <img className={classes.imageStyle} src={content.data_url} alt={content.title} />
              </div>
              : null
            }
            <div className={classes.centerTextWrap}>
              {isOutline ?
                <a className={classes.outlineTitle} href={`#${section.id}`}>
                  {content.title}
                </a>
                :
                <h3 className={classes.imageTitle} id={section.id}>
                  {content.title}
                </h3>
              }
              {!isOutline ?
                <div className={classes.imageContent} data-test="image-content">
                  {content.commentary}
                </div>
                : null
              }
            </div>
          </div>
          : null
        }
      </React.Fragment>
    );
  } else if (section.section_type === 'section-heading') {
    return (
     
        <div id={section.id} className={classes.sectionWrap} data-test="component-heading">
          {isOutline ?
            <a id={section.id} href={`#${section.id}`} className={classes.outlineTitle} data-test="heading-link">{content}</a>
            :
            <div id={section.id} className={classes.sectionTitle}>{content}</div>
          }
        </div>
    );
  } else if (section.section_type === 'body-text') {
    return (
        <div id={section.id} className={classes.sectionWrap} data-test="component-body">
          {isOutline ?
            <a id={section.id} href={`#${section.id}`} className={classes.outlineContent} data-test="heading-link">{content}</a>
            :
            <div id={section.id} className={classes.sectionContent}>{content}</div>
          }
        </div>
    );
  } else if(section.section_type === 'chunk'){
    const chunks = content.chunks && content.chunks.map(chunk=> (
      <Fragment key={chunk.chunk_id}>
        {isOutline ? (
          <Fragment>
            <a id={section.id} href={`#${section.id}`} className={classes.outlineChunk}>{chunk.content.substring(0,60)+ "..."}</a>
          </Fragment>
        ):(
          <li>
            <Typography className={classes.textStyle} >
              {chunk.content}
            </Typography>
            <Divider style={{ margin: '10px 0' }} />
          </li>
        )
        }
       </Fragment>
    ));
    return (
      <div id={section.id} className={classes.sectionWrap}>
        {content.chunks && content.chunks.length && (
          isOutline ? 
            <a id={section.id} href={`#${section.id}`} className={classes.outlineTitle}>{content.chunks[0].title}</a>
            :
            <h3 className={classes.chunkTitle}>{content.chunks[0].title}</h3>
        )}
        <ol key={section.id}>
        {chunks}
        </ol>
      </div>
    );
  } 
  else if (section.section_type === 'text-analysis') {
    return (
      <div id={section.id} className={classes.sectionWrap}>
        {content &&
        <>
            <h3 className={classes.chunkTitle}>{content.Title}</h3>
            <Typography className={classes.textStyle} >
                <b>{activeSetPlaceholder} </b>{content.ActiveSet}
              </Typography>
              <Typography className={classes.textStyle} >
              <b>{sortingPlaceholder} </b> {content.Sorting}
              </Typography>
              <Typography className={classes.textStyle} >
              <b>{sentimentPlaceholder} </b> {content.AvgSentiment}
              </Typography>
              <Typography className={classes.textStyle} >
              <b>{chunksPlaceholder} </b>{content.ChunksLength }
              </Typography>
              <Typography className={classes.textStyle} >
              <b>Abstract Summary </b> {content.AbstractText}
              </Typography>
              <Typography className={classes.textStyle} >
              <b>Keypoints Summary </b> 
              {content.KeypointsText.split("\n").map((line, index) => (
                <span key={index}>{line}<br /></span>
              ))}
              </Typography>
              </>
      }
      </div>
    );
  } 
  else {
    return <div data-test="component-default"></div>
  }
}


function ReportView() {

  const { reportId } = useParams();
  const history = useHistory();
  const [anchor, setAnchor] = React.useState(null);
  const [isEdit, setEdit] = React.useState(false);
  const [data, setData] = React.useState({});
  const [isOpenDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const intl = useIntl();
  const savePlaceholder = intl.formatMessage({id: 'app.save',defaultMessage: 'Save'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  const deletePlaceholder = intl.formatMessage({id: 'app.delete',defaultMessage: 'Delete'});
  const editPlaceholder = intl.formatMessage({id: 'app.reports.edit',defaultMessage: 'Edit'});
  const shareReportPlaceholder = intl.formatMessage({id: 'app.reports.shareReport',defaultMessage: 'Share Report'});
  const discardChangesPlaceholder = intl.formatMessage({id: 'app.reports.discardChanges',defaultMessage: 'DISCARD CHANGES'});
  
  


  const reports = useSelector((state) => state.reports.data, shallowEqual);

  const dispatch = useDispatch();
  const classes = combinedStyles();

  const report = reports[reportId] || {};
  // get the unique titles of chunks
  const titles = report.chunks && [...new Set(report.chunks.map(chunk => chunk.title))];
  const [sectionTitle, setSectionTitle] = useState('');

  //This is not required as fetch report is fecthing entire data
  // Todo: Fetch report should get only id's and other relevant data for list view.
  //Based on the ID, fetch by ID should be called.
  
  // useEffect(() => {
  //   dispatch(reportActions.fetchReport(reportId));
  // }, [reportId]);


  useEffect(() => {
    if(!sectionTitle || sectionTitle===''){
      setSectionTitle(titles && titles[0]);
    }
      
  }, [titles]);

  useEffect(() => {
    setData(clonedeep(report) || {});
  }, [report]);

  const handleBackClick = () => {

  };

  const handleChange = (value) => {
    setData({
      ...data,
      ...value
    });
  }

  const handleMenuClick = (ev) => {
    setAnchor(ev.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  }

  const openDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const cancel = () => {

  }

  const edit = () => {
    setEdit(true);
  }

  const hangdleDeleteReport = () => {
    dispatch(deleteReport(reportId)).then(() => {
      history.push('/reports');
    });

  }

  const save = () => {
    const sections = report.sections || [];
    const changedSections = data.sections || [];
    const changedIds = [];
    for (const s of changedSections) {
      if (s.id) {
        changedIds.push(s.id);
      }
    }
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (changedIds.indexOf(section.id) === -1) {
        dispatch(deleteReportSection(reportId, section.id));
      }
    }
    for (let i = 0; i < changedSections.length; i++) {
      const s = changedSections[i];
      if (!s.id || !equal(s, sections[i]) || s.index !== i) {  
          dispatch(saveReportSection(reportId, {
            ...s,
            index: i,
            report: reportId,
          }));     
      }
    }
    if (data.title !== report.title) {
      dispatch(saveReport({ ...report, title: data.title }));
    }
    setEdit(false);
  }

  const sections = data.sections || [];
  sections.sort((a, b) => {
    if (a.index < b.index) {
      return -1;
    }
    if (a.index > b.index) {
      return 1;
    }
    return 0;
  });
  console.log('sections:', sections)

  return (
    <React.Fragment>
      <div className={classes.editMenuOutWrap}>
        <Link to="/reports">
          <div
            className={classes.backBtn}
            onClick={handleBackClick}
          >
            <ArrowBackIcon />
          </div>
        </Link>
        <div
          className={classes.editMenuWrap}
          onClick={handleMenuClick}
          aria-haspopup="true"
          aria-owns={anchor ? 'outline-menu' : undefined}
        >
          <ListAltIcon />
        </div>
        <Menu
          id="outline-menu"
          anchorEl={anchor}
          classes={{
            list: classes.editListWrap,
            paper: classes.editMenuListWrap,
          }}
          open={!!anchor}
          onClose={closeMenu}
        >
          <MenuItem className={classes.drawerHeader} onClick={closeMenu}>
            <ChevronLeftIcon />
            <span className={classes.drawerHeaderText}>Outline</span>
          </MenuItem>
          <Divider />
          {sections.map((section, i) => (
            section.section_type === 'section-heading' ?
              <MenuItem key={i}>
                <ReportItem key={i}
                  classes={classes}
                  section={section}
                  isOutline={true}
                />
              </MenuItem>
              : null
          ))}
        </Menu>
      </div>
      {isEdit ?
        <ReportEdit value={data} onChange={handleChange} setSectionTitle={setSectionTitle} titles={titles} sectionTitle={sectionTitle}/>
        :
        <div className={classes.contentWrap} data-test="component-report-view">
          <div className={classes.sectionWrap}>
            <div className={classes.reportTitle}>{data.title}</div>
          </div>
          {sections.map((section, i) => (
            <ReportItem key={i}
              classes={classes}
              section={section}
              sectionTitle={sectionTitle}
            />
          ))}
        </div>
      }
      <div className={classes.bottomBar}>
        {isEdit ?
          <div className={classes.bottomContainer} data-test="report-view-edit">
            {/* Todo: Need to come up with different way to discard changes and go back. Also pop up to confirm their action */}
            <Link to="/reports">
            <Button
              color="secondary"
              className={classes.bottomButton}
              onClick={cancel}
            >
              {discardChangesPlaceholder}
            </Button>
            </Link>
            <Button
              color="primary"
              variant="contained"
              className={classes.bottomButton}
              onClick={()=> save()}
            >
              {savePlaceholder}
            </Button>
          </div>
          :
          <div className={classes.bottomContainer} data-test="report-view-non-edit">
            <Button
              color="primary"
              variant="outlined"
              className={classes.bottomButton}
              onClick={()=> openDeleteDialog()}
            >
              {deletePlaceholder}
            </Button>
            <Dialog
              open={isOpenDeleteDialog}
              onClose={()=>closeDeleteDialog()}
              aria-labelledby={'alert-dialog-title-' + reportId}
              aria-describedby={'alert-dialog-description-' + reportId}
            >
              <DialogTitle id={'alert-dialog-title-' + reportId}>
                Confirm delete
              </DialogTitle>
              <DialogContent>
                <DialogContentText id={'alert-dialog-description-' + reportId}>
                  Are you sure you want to delete this report?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={()=> closeDeleteDialog()}>
                  {cancelPlaceholder}
                </Button>
                <Button
                  autoFocus
                  color="primary"
                  variant="contained"
                  onClick={()=>hangdleDeleteReport()}
                >
                  {deletePlaceholder}
                </Button>
              </DialogActions>
            </Dialog>
            <Button
              color="primary"
              variant="outlined"
              className={classes.bottomButton}
              onClick={()=>edit()}
            >
              {editPlaceholder}
            </Button>
            <a
              href={`/api/v1/reports/${reportId}/?output=word`}
              target="_blank"
            >
              <Button color="primary" variant="outlined">
                {shareReportPlaceholder}
              </Button>
            </a>
          </div>
        }
      </div>
    </React.Fragment>
  );
}

const useStyles = (theme) => ({
  backBtn: {
    backgroundColor: 'rgb(245, 245, 245)',
    borderRadius: '20px',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '8px 8px 3px 8px',
  },
  bottomBar: {
    backgroundColor: '#eceff1',
    height: '48px',
    width: '100%',
    zIndex: 100,
  },
  bottomButton: {
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    height: '36px',
    letterSpacing: '1.25px',
    lineHeight: 1.12,
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  bottomContainer: {
    lineHeight: '48px',
    height: '100%',
    marginRight: '5%',
    textAlign: 'right',
  },
  drawHeader: {
    padding: '10px 2px',
  },
  drawHeaderText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '24.4px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },
  drawMainText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    paddingLeft: '26px',
  },
  drawSubText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    paddingLeft: '24px',
    whiteSpace: 'normal',
  },
  editListWrap: {
    padding: 0,
  },
  editMenuListWrap: {
    top: '225px !important',
    left: '48px !important',
    width: '350px',
  },
  editMenuOutWrap: {
    position: 'fixed',
  },
  editMenuWrap: {
    backgroundColor: '#90a4ae',
    borderRadius: '4px',
    color: '#fff',
    marginTop: '20px',
    padding: '8px 8px 3px 8px',
  },
  reportTitle: {
    color: '#516e88',
    fontSize: '32px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: '600',
    height: '37px',
    letterSpacing: '0.5px',
    lineHeight: '37px',
    textAlign: 'center',
    width: '100%',
    marginBottom: '15px',
  },
  sectionContent: {
    display: 'block',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '1.1rem',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 1.5,
    margin: '15px auto',
    width: '100%',
    textAlign: 'left',
  },
  sectionTitle: {
    color: '#516e88',
    // fontSize: '16.3px',
    fontSize: '28px',
    fontStretch: 'normal',
    fotnStyle: 'normal',
    fontWeight: '500',
    height: '37px',
    letterSpacing: '0.5px',
    lineHeight: '37px',
    margin: '30px auto 15px',
    width: '100%',
    textAlign: 'left',
  },
  sectionWrap: {
    width: '100%',
    height: 'auto'
  },
  imageTitle: {
    color: '#516e88',
    fontSize: '22px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    height: '22px',
    letterSpacing: '0.5px',
    lineHeight: '37px',
    margin: '15px auto 15px',
    width: '100%',
    textAlign: 'left',
  },
  chunkTitle: {
    color: '#516e88',
    fontSize: '22px',
    margin: '15px auto 20px',
    textAlign: 'left',
  },
  outlineTitle: {
    display: 'block',
    color: '#516e88',
    fontSize: '18px',
    fontStretch: 'normal',
    fotnStyle: 'normal',
    fontWeight: 'normal',
    height: '18px',
    letterSpacing: '0.5px',
    lineHeight: '37px',
    marginBottom: '20px',
    width: '100%',
    textAlign: 'left',
  },
  outlineContent: {
    display: 'block',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '18px',
    letterSpacing: '0.25px',
    lineHeight: 1.5,
    marginBottom: '20px',
    width: '100%',
    textAlign: 'left',
  },
  outlineChunk: {
    display: 'block',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '10px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '10px',
    marginBottom: '20px',
    width: '100%',
    textAlign: 'left',
  },
  imageContent: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '1rem',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 1.5,
    margin: '20px auto',
    width: '100%',
    textAlign: 'left',
  }
});

const combinedStyles = makeStyles(combineStyles(commonStyles, useStyles));

export default ReportView;