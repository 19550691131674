import { gql } from '@apollo/client';
import { setupApi } from '../../graphql/client';
const client = setupApi();

export const FETCH_PROBSOL_LOADING = 'FETCH_PROBSOL_LOADING';
export const FETCH_PROBSOL_SUCCESS = 'FETCH_PROBSOL_SUCCESS';
export const FETCH_PROBSOL_FAILURE = 'FETCH_PROBSOL_FAILURE';

function fetchProbSolLoading() {
  return {
    type: FETCH_PROBSOL_LOADING,
  };
}

function fetchProbSolSuccess(payload) {
  return {
    type: FETCH_PROBSOL_SUCCESS,
    payload,
  };
}

function fetchProbSolFailure(payload) {
  return {
    type: FETCH_PROBSOL_FAILURE,
    payload,
  }
}

export function fetchProbSol(variables) {
  return async (dispatch) => {
    try {
      dispatch(fetchProbSolLoading());
      const forum_id = variables.forum_id == '' ? undefined : variables.forum_id;
      const forumParam = forum_id ? '$forum_id: String!' : '';
      const forumWhereParam = forum_id ? 'forum_id: $forum_id' : '';
      const forumSubWhereParam = forum_id ? 'forum_id: $forum_id' : 'forum_id: null';

      const startDate = variables.startDate ? variables.startDate : '';
      const endDate = variables.endDate ? variables.endDate : '';
      const dateTimeParam = startDate != '' && endDate != '' ? '$startDate: DateTime!\n$endDate: DateTime!' : '';
      const dateTimeWhereParam  = startDate != '' && endDate != '' ? 'comment: { AND: { create_datetime_GTE: $startDate, create_datetime_LTE: $endDate } }' : '';
      const dateTimeSubWhereParam  = startDate != '' && endDate != '' ? 'date_filter: { create_datetime_GTE: $startDate, create_datetime_LTE: $endDate }' : '';
      
      const argumentParam = variables.type === 'Solutions' ? 'argument: {argument_type: "Solution", pro_con_flag: "NA"}': variables.type === 'Positive Situations'? 'argument: {argument_type: "Situation", pro_con_flag: "Pros"}':'argument: {argument_type: "Situation", pro_con_flag: "Cons"}';      
      const displayLimit = variables.displayLimit;
      const preppedQuery = `
      query GetProblemsSolutions(
        $client_id: String
        $limit: Int
        $offset:Int
        ${forumParam}
        ${dateTimeParam}
      ){
        problemSolutions(
          where: {
            chunks:{
              client_id: $client_id,
              ${forumWhereParam}
              ${dateTimeWhereParam}
            
            }
          }
        ) {
          argument_type
          pro_con_flag
          chunks(
            options: {limit: $limit,sort:{weighted_score: DESC}, offset:$offset},
            where: {
              client_id: $client_id
              weighted_score_GT:0
              ${forumWhereParam}
              ${dateTimeWhereParam}
              ${argumentParam}
            }
          ) {
            uid
            text
            weighted_score
          }
          chunk_avg_sentiment(
            client_id: $client_id
            ${forumSubWhereParam}
            ${dateTimeSubWhereParam}
          )
          chunk_count(
            client_id: $client_id
            ${forumSubWhereParam}
            ${dateTimeSubWhereParam}
          )
        }
      }
      `;

      console.log('gqlQuery', preppedQuery);
      const query = {
        query:  gql`${preppedQuery}`,
        variables: {
          client_id: variables.client_id,
          limit: displayLimit,
          offset: 0,
          forum_id,
          startDate,
          endDate,
        }
      };
      console.log('gqlQuery', JSON.stringify(query.variables, null, 2));

      // let gqlQuery;
      // if (variables.forum_id == '') {
      //   gqlQuery = {
      //     query:  gql`
      //     query GetProblemsSolutions($client_id: String, $limit: Int, $offset:Int){
      //       problemSolutions(where: {chunks:{client_id: $client_id}}) {
      //         argument_type
      //         pro_con_flag
      //         chunks(options: {limit: $limit, offset:$offset}, where: {client_id: $client_id}) {
      //           uid
      //           text
      //         }
      //         chunk_avg_sentiment(client_id: $client_id, forum_id: null)
      //         chunk_count(client_id: $client_id, forum_id: null)
      //       }
      //     }
      //     `,
      //     variables: {
      //       client_id: variables.client_id,
      //       limit: 50,
      //       offset: 0
      //     }
      //   };
      // } else {
      //   gqlQuery = {
      //     query:  gql`
      //     query GetProblemsSolutions($client_id: String, $forum_id: String, $limit: Int, $offset:Int){
      //       problemSolutions(where: {chunks:{client_id: $client_id, forum_id: $forum_id}}) {
      //         argument_type
      //         pro_con_flag
      //         chunks(options: {limit: $limit, offset:$offset}, where: {client_id: $client_id, forum_id: $forum_id}) {
      //           uid
      //           text
      //         }
      //         chunk_avg_sentiment(client_id: $client_id, forum_id: $forum_id)
      //         chunk_count(client_id: $client_id, forum_id: $forum_id)
      //       }
      //     }
      //     `,
      //     variables: {
      //       client_id: variables.client_id,
      //       forum_id: variables.forum_id,
      //       limit: 50,
      //       offset: 0
      //     }
      //   }
      // }

      const res = await client.query(query);
      const data = res.data.problemSolutions;
      console.log('prob-sol->data', data);
      if (Array.isArray(data)) {
        const results =[];
        for (const x of data) {
          if (x.argument_type === 'Situation' && x.pro_con_flag == 'Pros' && variables.type === 'Positive Situations') {
            if (Array.isArray(x.chunks)) {
              for (const chunk of x.chunks) {
                if (chunk.text) {
                  results.push(chunk);
                }
              }
            }
          }
          if (x.argument_type === 'Situation' && x.pro_con_flag == 'Cons'&& variables.type === 'Negative Situations') {
            if (Array.isArray(x.chunks)) {
              for (const chunk of x.chunks) {
                if (chunk.text) {
                  results.push(chunk);
                }
              }
            }
          }
          if (x.argument_type === 'Solution' && variables.type === 'Solutions') {
            if (Array.isArray(x.chunks)) {
              for (const chunk of x.chunks) {
                if (chunk.text) {
                  results.push(chunk);
                }
              }
            }
          }
        }
        return dispatch(fetchProbSolSuccess({
          results: results,
        }));
      }
    } catch (error) {
      dispatch(fetchProbSolFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        }
      }));
    }
  }
};
