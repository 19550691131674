import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useIntl } from "react-intl"; 
import { Form, Input, Select, Modal, DatePicker, Space,Button, Drawer,Switch,Collapse  } from "antd";
import _ from 'lodash';
import { DemographicsContext } from "../../context/demographics-context";
import {sortDemographics} from '../../utils/SortDemographics'
import * as d3 from "d3";
import {nest} from 'd3-collection';
import Filters from './Filters';
import moment from "moment";
import "./style.css";
// import {
//     event, select, drag, zoomIdentity
// } from 'd3';
// import {
//   event, select, zoom, drag, zoomIdentity
// } from 'd3';
import { iconToUnicode } from "./fontAwesomeFunc";
import {
  forceSimulation,
  forceManyBody,
  forceX,
  forceY,
  forceLink,
  alpha,
} from "d3-force";
import { zoom } from "d3-zoom";
import { drag } from "d3-drag";

const { Panel } = Collapse;

export default ({ data }) => {
 
  const dataStep1 = data;
  const ref = useRef(null);
  const [graphData, setGraphData] = useState([]);
  const [isNetworkDiagramMode, setIsNetworkDiagramMode] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedNodeValue, setSelectedNodeValue] = useState(""); // To change in future based on incoming data
  
  const [form] = Form.useForm();
 
  // const { Option } = Select;
  const intl = useIntl();
  // const startDatePlaceholder = intl.formatMessage({id: 'app.strategyMap.config.startDate',defaultMessage: 'Start Date'})
  // const endDatePlaceholder = intl.formatMessage({id: 'app.strategyMap.config.endDate',defaultMessage: 'End Date'})
  // const defaultValues = {
  //   startDate: "2020-06-01",
  //   endDate: moment().format("YYYY-MM-DD"),
  //   autoEndDate: "false",
  // };
  // const [tempForm, setTempForm] = useState({});
  // useEffect(() => {
  //   setTempForm({
  //     ...defaultValues,
  //   });
  // }, []);

  useEffect(() => {
      setGraphData(data);
  }, [data]);

  const graphMode = () => {
    setIsNetworkDiagramMode(true);
  };
  const hierarchyMode = () => {
    setIsNetworkDiagramMode(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const tooltipRef = useRef();
    const dataStep2 = {
      nodes: [
        { id: 1, name: "HR", color: "#ec9b41", icon: "\u2927", depth: "2" },
        {
          id: 2,
          name: "Prodcut Manager",
          color: "#ec9b41",
          icon: "\u2720",
          depth: "2",
        },
        {
          id: 3,
          name: "Technical Manager",
          color: "#ec9b41",
          icon: "\u039C",
          depth: "2",
        },
        {
          id: 4,
          name: "Marketing Manager",
          color: "#ec9b41",
          icon: "\u25b2",
          depth: "2",
        },
        {
          id: 5,
          name: "Finance Manager",
          color: "#ec9b41",
          icon: "\u2744",
          depth: "2",
        },
        {
          id: 6,
          name: "Production Manager",
          color: "#ec9b41",
          icon: "\u2734",
          depth: "2",
        },
      ],
      links: [
        { source: 1, target: 2 , weight: 5},
        { source: 2, target: 3 , weight: 5},
        { source: 2, target: 3 , weight: 5},
        { source: 3, target: 4 , weight: 5},
        { source: 4, target: 5 , weight: 5},
        { source: 4, target: 6 , weight: 5},
      ],
    };

  const createSVG = () => {
    let options = graphData || {};

    let networkMapBindingObject = options.networkMapParams;
    if (networkMapBindingObject === null) return;

    let networkMapParams = options;
    let selectedNode = 1;

    // Node constants
    const radius = 25;
    const selectedNodeRadius = 35;

    // Goes through all the nodes, creates a object which contain the total number of depths,
    // the number of nodes in that depth, and current -> which is used to calculate the y position in a depth.
    const depths = networkMapParams.nodes
      .reduce((acc, curr) => {
        const depthExists = acc.find((n) => n.depth === curr.depth);
        if (!depthExists) {
          acc.push({ depth: curr.depth, count: 1, current: 1 });
        } else {
          acc[acc.indexOf(depthExists)] = {
            depth: depthExists.depth,
            count: depthExists.count + 1,
            current: 1,
          };
        }

        return acc;
      }, [])
      .sort((a, b) => a.depth - b.depth);

    const margin = {
      top: 5,
      right: 5,
      bottom: 5,
      left: 5,
    };

    // Dimensions of the element
    let dimensions = {
      width: ref.current.clientWidth - margin.left - margin.right,
      height: ref.current.clientHeight - margin.top - margin.bottom,
      svgWidth: ref.current.clientWidth,
      svgHeight: ref.current.clientHeight,
    };
    // var xScale = d3.scaleOrdinal()
    // .domain([1, 2, 3])
    // .range([400, 500, 600])
    
      //  // The largest node for each cluster.
      //  var clusters = new Array(100);
      //  networkMapParams.nodes.forEach(function(d) { clusters[d.cluster] = d; });
   
      //   // Use the pack layout to initialize node positions.
      //   d3.pack()
      //   .size([dimensions.width, dimensions.height])
      //   //.children(function(d) { return d.values; })
      //   .value(function(d) { return d.radius * d.radius; })
  
        // .nodes({values: nest()
        //   .key(function(d) { return d.cluster; })
        //   .entries(networkMapParams.nodes)});
          
      //  console.log(node);


    // Allows the SVG to be resizeable
    const resize = () => {
      const w = ref.current.clientWidth;
      const h = ref.current.clientHeight;
      svg.attr("width", w);
      svg.attr("height", h);

      dimensions = {
        width: w - margin.left - margin.right,
        height: h - margin.top - margin.bottom,
        svgWidth: w,
        svgHeight: h,
      };

      if (graphLayout !== undefined) {
        graphLayout.alpha(1).restart();
        if (isNetworkDiagramMode) {
          graphLayout.force("x", d3.forceX(w / 2)).force("y", d3.forceY(h / 2));
        } else {
          hierarchicalDiagramToggle();
        }
      }
    };

    // Identifies the distance between each depth given the current SVG width.
    const globalDifference = dimensions.svgWidth / depths.length / 2;
    const svgEl = d3.select(ref.current);
    svgEl.selectAll("*").remove(); // Clear svg content before adding new elements
    // Creates the SVG
    let svg = svgEl
      .append("svg")
      .attr("width", dimensions.svgWidth)
      .attr("height", dimensions.svgHeight)
      .attr("border", 1)
      .call(() => window.addEventListener("resize", resize));

    // //Draws SVG border
    let border = svg
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("height", "100%")
      .attr("width", "100%")
      .classed("boxContainer", true)
      .style("fill", "none")
      .style("stroke-width", 1);

    const delay = 100;
    let container = svg.append("g");
    let clicks = 0;
    let timer = null;
    let isDragStart = false;
    //let isNetworkDiagramMode = false;
    let currentScale = 1;
    let fixedNodes = [];

    // Method to close the slide out, remove selected outline, and unfocus and selection.
    const unselect = () => {
      container.selectAll(".selectedCircle").remove();
      graphLayout.alphaTarget(0);
      unfocus();
    };

    //Displays the info for the selected node
    svg.on("click", (event) => {
      var srcElement = event.srcElement;
      clicks++;
      timer = setTimeout(() => {
        if (clicks === 1) {
          //perform single-click action
          const tagName = srcElement.tagName;
          if (tagName !== "circle") {
            unselect();
          }

          clicks = 0; //after action performed, reset counter
        } else {
          clearTimeout(timer); //prevent single-click action
          clicks = 0; //after action performed, reset counter
        }
      }, delay);
    });
    // Display the direction(arrow) on the links
    container
      .append("marker")
      .attr("id", "arrow")
      // .attr("viewBox", "0 -5 10 10")
      .attr("viewBox", "0 0 300 300")
      .style('max-width', '80%')
      .style('height', 'auto')
      .attr("refX", 33)
      .attr("refY", 0.2)
      .attr("markerWidth", 15)
      .attr("markerHeight", 15)
      .attr("orient", "auto")
      .append("svg:path")
      .classed("networkMap-edge-marker", true)
      .attr("d", "M0,-5L10,0L0,5");

    // Arrow for the select node, since its bigger
    container
      .append("marker")
      .attr("id", "selected-arrow")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", selectedNodeRadius - 5)
      .attr("refY", 0.2)
      .attr("markerWidth", 15)
      .attr("markerHeight", 15)
      .attr("orient", "auto")
      .append("svg:path")
      .classed("networkMap-edge-marker", true)
      .attr("d", "M0,-5L10,0L0,5");

    // Links between the Nodes
    let link = container
      .append("g")
      .attr("class", "links")
      .selectAll("link")
      .data(networkMapParams.links)
      .enter()
      .append("line")
      .classed("networkMap-edge", true)
      .style("fill", "none")
      .style("stroke-width", (link) => (link.weight < 10 ? link.weight : 10))
     // .style("padding", "30px");
    // .attr("marker-end", (node) =>
    //   node.target === selectedNode ? "url(#selected-arrow)" : "url(#arrow)"
    // );

    // The nodes
    let node = container
      .append("g")
      .attr("class", "nodes")
      .selectAll("circle")
      .data(networkMapParams.nodes)
      .enter()
      .append("circle")
      .attr("r", (node) =>
        node.id === selectedNode ? selectedNodeRadius : radius
      )
      .style("stroke", (node) => node.color)
      .attr("stroke-width", (node) =>
        node.id === selectedNode ? "5px" : "4px"
      )
      .classed("networkMap-nodeBackground", (node) => node.id !== selectedNode) // if selected node, node colour is {n.colour}
      // .attr("transform", function(event,d) {
      //   event.x = Math.max(radius, Math.min(dimensions.svgWidth - radius, event.x));
      //   event.y = Math.max(radius, Math.min(dimensions.svgHeight - radius, event.y));
      //   return "translate(" + event.x + "," + event.y + ")";
      // })
      .style("fill", (n) => (n.id === selectedNode ? n.color : "white"));

    // The icons inside the node
    let nodeIcon = container
      .append("g")
      .attr("class", "nodeIcons")
      .selectAll("text.icon")
      .data(networkMapParams.nodes)
      .enter()
      .append("text")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "central") //align the box’s text and inline-level contents.
      .style("font-family", "FontAwesome")
      .style("pointer-events", "none")
      .style("font-size", "30px")
      .classed("networkMap-nodeBackground", (node) => node.id === selectedNode) // if selected node, set icon to the background colour.
      .attr("fill", (n) => (n.id !== selectedNode ? n.color : "white"))
      .text((node) => node.icon);
    // .text((node) => iconToUnicode(node.icon));

    // Labels for the nodes
    let labelNode = container
      .append("g")
      .attr("class", "labelNodes")
      .selectAll("text.label")
      .data(networkMapParams.nodes)
      .enter()
      .append("text")
      .attr("text-anchor", "middle")
      // .text((node) => node.name)
      .text((node) => node.group)
      .classed("networkMap-font", true)
      .style("font-family", "Arial")
      .style("font-size", 15)
      .style("pointer-events", "none"); // to prevent mouseover/drag capture

    //Initially as well as new position of node due to window resized will be calculated below
    //It ensure that node cannot be dragged outside given svg boundary.
    const ticked = () => {

      node.attr("cx", (node) => node["x"]).attr("cy", (node) => node["y"]);
      // node
      // .attr("transform", function(d) {
      //   d.x = Math.max(radius, Math.min(width - radius, d.x));
      //   d.y = Math.max(radius, Math.min(height - radius, d.y));
      //   return "translate(" + d.x + "," + d.y + ")";
      // });

      link
        .attr("x1", (link) => link.source["x"])
        .attr("y1", (link) => link.source["y"])
        .attr("x2", (link) => link.target["x"])
        .attr("y2", (link) => link.target["y"]);

      // Should be calculated based of collision detection
      labelNode
        .attr("x", (node) => node["x"])
        .attr("y", (node) =>
          node["id"] === selectedNode ? node["y"] + 65 : node["y"] + 55
        );

      nodeIcon.attr("x", (node) => node["x"]).attr("y", (node) => node["y"]);
    };

    // Recursive Algorithm to find all linked nodes given a node.
    const identifyRelatedNodes = (nodeId) => {
      let sourceQueue = [];
      let targetQueue = [];
      let completedQueue = [];

      // Populates relevant 1st level nodes
      networkMapParams.links.forEach((l) => {
        if (l.source["id"] === nodeId) {
          sourceQueue.push(l.target["id"]);
        } else if (l.target["id"] === nodeId) {
          targetQueue.push(l.source["id"]);
        }
      });

      completedQueue.push(nodeId);

      for (let i = 0; i < sourceQueue.length; i++) {
        networkMapParams.links.forEach((li) => {
          if (li.source["id"] === sourceQueue[i]) {
            if (!sourceQueue.includes(li.target["id"])) {
              sourceQueue.push(li.target["id"]);
            }
          }
        });

        completedQueue.push(sourceQueue[i]);
      }

      for (let i = 0; i < targetQueue.length; i++) {
        networkMapParams.links.forEach((li) => {
          if (li.target["id"] === targetQueue[i]) {
            if (!targetQueue.includes(li.source["id"])) {
              targetQueue.push(li.source["id"]);
            }
          }
        });

        completedQueue.push(targetQueue[i]);
      }

      return completedQueue;
    };

    // Selected (aka Focused) node
    let focusedNode = {};

    // Unpin node(s)
    const unpinNodes = (selectedNode) => {
      if (selectedNode === undefined) {
        node.each((n) => (n["fx"] = n["fy"] = null));
        svg.selectAll("circle").classed("networkMap-highlightedNode", false);
      } else {
        // If the node is fixed, don't release
        if (!fixedNodes.includes(selectedNode["id"])) {
          node.each((n) => {
            if (selectedNode["id"] === n["id"]) {
              n["fx"] = n["fy"] = null;
            }
          });
        }
      }

      graphLayout.alpha(0.3).restart();
    };

    // Given a node, creates the 'focus' illusion
    const focus = (currentNode) => {
      let relatedNodes = identifyRelatedNodes(currentNode["id"]);

      node
        .transition()
        .style("opacity", (n) => (relatedNodes.includes(n.id) ? 1 : 0.3));

      nodeIcon
        .transition()
        .style("opacity", (n) => (relatedNodes.includes(n.id) ? 1 : 0.5));

      labelNode
        .transition()
        .style("opacity", (n) => (relatedNodes.includes(n.id) ? 1 : 0.15));

      link
        .transition()
        .style("opacity", (n) =>
          relatedNodes.includes(n.source["id"]) &&
          relatedNodes.includes(n.target["id"])
            ? 1
            : 0.1
        );

      focusedNode = currentNode;
    };

    // Removes the 'focus' illusion
    const unfocus = () => {
      labelNode.transition().style("opacity", 1);

      node.transition().style("opacity", 1);

      link.transition().style("opacity", 1);

      nodeIcon.transition().style("opacity", 1);

      if (focusedNode !== undefined) {
        unpinNodes(focusedNode);
        focusedNode = undefined;
      }
    };

    // Initial Force Simulation setup for all the SVG
    let graphLayout = forceSimulation(networkMapParams.nodes)
      .force("charge", forceManyBody().strength(-500))
      .force("x", forceX(dimensions.width / 2))
     // .force("x", forceX( function(d){ return xScale(d.group) } ))
      .force("y", forceY(dimensions.height / 2))
      .force(
        "link",
        forceLink(networkMapParams.links)
          .id((link) => link["id"])
          .distance(5)
      )
      .on("tick", ticked)
      .on("end", () => (isDragStart = false));

    // Resets the 'current' property in the depths object
    const resetDepthCurrent = () => depths.forEach((d) => (d.current = 1));

    let networkDiagramToggleCount = 1;

    // Method which applies the forces to transform the nodes into a network diagram
    const networkDiagramToggle = (scale) => {
      unselect();
      fixedNodes = [];
      node.each((n) => (n["fx"] = n["fy"] = null));

     let newScale = scale || 1;


      graphLayout
        .force("charge", forceManyBody().strength(-500))
        .force("x", forceX(dimensions.width / 2))
        // .force("x", forceX( function(d){ return xScale(d.group) } ))
        .force("y", forceY(dimensions.height / 2))
        .force(
          "link",
          forceLink(networkMapParams.links)
            .id((link) => link["id"])
            .distance(200 / newScale)
        );

      graphLayout.alpha(0.5).restart();
      resetDepthCurrent();
      svg.selectAll("circle").classed("networkMap-highlightedNode", false);
    };

    // Method which applies the forces to transform the nodes into a hierarchical diagram
    const hierarchicalDiagramToggle = () => {
      unselect();
      fixedNodes = [];
      node.each((n) => (n["fx"] = n["fy"] = null));

      const newScale = !isNetworkDiagramMode ? currentScale : 1;

      // Threshold for screen resolution && uses the new scale value to calculate the node position in the screen accordingly
      const width =
        dimensions.svgWidth <= 708 ? 1820 : dimensions.svgWidth / newScale;
      const height =
        dimensions.svgHeight <= 529 ? 740 : dimensions.svgHeight / newScale;

      graphLayout
        .force("charge", forceManyBody().strength(-1500))
        .force(
          "x",
          forceX()
            .x(
              (node) =>
                (width / depths.length) *
                  (depths.indexOf(
                    depths.find((d) => d.depth === node["depth"])
                  ) +
                    1) -
                globalDifference
            ) //ForceX Algorithm which calculates the x position for the nodes
            .strength(1)
        )
        .force(
          "y",
          forceY((node) => {
            const relatedDepth = depths.find((d) => d.depth === node["depth"]);
            depths[depths.indexOf(relatedDepth)] = {
              depth: relatedDepth.depth,
              count: relatedDepth.count,
              current: relatedDepth.current + 1,
            };
            return (
              (height / relatedDepth.count) * relatedDepth.current -
              height / relatedDepth.count / 2
            ); // ForceY Algorithm which calculates the y position for the nodes.
          }).strength(1)
        )
        .force(
          "link",
          forceLink(networkMapParams.links)
            .id((link) => link["id"])
            .distance(300)
        )
        .alphaDecay(0.02);
    //   graphLayout
    //   .force("charge", forceManyBody().strength(-100))
    //   .on("tick", function(e) {
    //     var k = 6 * e.alpha;
    //   link.forEach(function(d, i) {
    //     d.source.y -= k;
    //     d.target.y += k;
    //   });
    //   link.attr("x1", function(d) { return d.source.x; })
    //       .attr("y1", function(d) { return d.source.y; })
    //       .attr("x2", function(d) { return d.target.x; })
    //       .attr("y2", function(d) { return d.target.y; });
  
    //   node.attr("transform", function(d) { return "translate(" + d.x + "," + d.y + ")"; });
    // })
     // graphLayout.alpha(0.3).restart();
     // resetDepthCurrent();

      svg.selectAll("circle").classed("networkMap-highlightedNode", false);
    };

    if (!isNetworkDiagramMode) {
      hierarchicalDiagramToggle();
    } else {
      networkDiagramToggle(1);
    }

    // This is the scale (magic wand) method
    // Applies the relevant newScale values to effect the diagrams accordingly
    const scaleToggle = (toReset) => {
      if (isNetworkDiagramMode) {
        let newScale = 0;
        if (!toReset) {
          newScale =
            currentScale / (networkDiagramToggleCount + 1) / currentScale;
          networkDiagramToggleCount += 1;
        } else {
          networkDiagramToggleCount = 1;
        }

        networkDiagramToggle(newScale);
      } else {
        hierarchicalDiagramToggle();
      }
    };

    // DRAG Start
    // Modifies the node FixedX||Y (fx, fy) value
    const dragstarted = (event, node) => {
      event.sourceEvent.stopPropagation();
      if (!event.active) {
        graphLayout.alphaTarget(0.1).restart();
      }

      node.fx = node.x;
      node.fy = node.y;
      console.log("drag success");
    };

    // DRAGing?
    const dragged = (event, node) => {
      isDragStart = true;

      container.selectAll(".removeableCircle").remove();
      container.selectAll(".selectedCircle").remove();

      node.fx = event.x;
      node.fy = event.y;
    };

    // DRAG end
    const dragended = (event, currentNode) => {
      if (isDragStart) {
        fixedNodes.push(currentNode["id"]);
        node.classed("networkMap-highlightedNode", (n) =>
          fixedNodes.includes(n.id)
        ); //Highlight the dragged node stroke with dash pattern using hte passed in node id
        if (!event.active) {
          graphLayout.alphaTarget(0);
        }
      }

      isDragStart = false;
    };
    // const zoomOut = () => {
    //   setZoom.scaleBy(svg.transition(), 0.8);
    // };
    // const zoomIn = () => {
    //   setZoom.scaleBy(svg.transition(), 1.2);
    // };
    // const reset = () => {
    //   currentScale = 1;
    //   svg.call(setZoom.transform, d3.zoomIdentity);
    //   unpinNodes(undefined);
    //   unselect();
    //   scaleToggle(true);
    // };

    // Double Click release on a node
    const dblclick = (event, node) => {
      if (!event.active) {
        graphLayout.alphaTarget(0);
      }

      //Reset the position of clicked node and remove highlighting
      node.fx = null;
      node.fy = null;

      d3.select(event.srcElement).classed("networkMap-highlightedNode", false);
      container.selectAll(".removeableCircle").remove();
      fixedNodes = fixedNodes.filter((n) => n !== node["id"]);
      unselect();
    };

    // Initiate the methods based on the event
    svg
      .selectAll("circle")
      .call(
        drag().on("start", dragstarted).on("drag", dragged).on("end", dragended)
      );

    // Highlight specific node(s)
    const highlight = (isHighlighted, node, onSelection = false) => {
      if (isHighlighted) {
        if (onSelection) {
          container.selectAll(".selectedCircle").remove();

          container
            .append("circle")
            .classed("selectedCircle", true)
            .attr("cx", () => node["x"])
            .attr("cy", () => node["y"])
            .attr("r", () =>
              node.id === selectedNode ? selectedNodeRadius * 1.2 : radius * 1.2
            )
            .style("stroke", node["color"])
            .style("stroke-width", "2px")
            .style("fill", "none")
            .style("pointer-events", "none");
        } else {
          container
            .append("circle")
            .classed("removeableCircle", true)
            .attr("cx", () => node["x"])
            .attr("cy", () => node["y"])
            .attr("r", () =>
              node.id === selectedNode ? selectedNodeRadius * 1.2 : radius * 1.2
            )
            .style("stroke", node["color"])
            .style("fill", "none")
            .style("pointer-events", "none");
        }
      } else {
        container.selectAll(".removeableCircle").remove();
      }
    };
    // var tooltip = d3.select(".tooltip-area").style("opacity", 0);
    // const mouseover = (event, d) => {
    //   tooltip.style("opacity", 1);
    // };
    // var tooltip = d3
    //   .select(tooltipRef.current)
    //  // .append("div")
    //   .style("opacity", 0)
    //   .attr("class", "tooltip")
    //   .style("background-color", "red")
    //   .style("border", "solid")
    //   .style("border-width", "2px")
    //   .style("border-radius", "5px")
    //   .style("position","absolute")
    //   .style("padding", "5px");

    // // Highlight specific nodes
    // link
    //   .on("mouseover", (event, d) => {
    //     const tooltipDiv = tooltipRef.current;
    //   if (tooltipDiv) {
    //     d3.select(tooltipDiv).style("opacity", 1);
    //     d3.select(tooltipDiv)
    //     .html("Number of comunications: "+ d.weight)
    //     .style("left", event.pageX + "px")
    //     .style("top", event.pageY - 28 + "px");
    //   }})
    //   .on("mouseout", (event,d) => {
    //     const tooltipDiv = tooltipRef.current;
    //     if (tooltipDiv) {
    //     //  d3.select(tooltipDiv).transition().duration(10000).style("opacity", 0);
    //       d3.select(tooltipDiv).style("opacity", 0);
    //     };
    //   });
    var tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .style("color", "black");
      

    link
      .on("mouseover", (event,d) =>{
        return tooltip.style("visibility", "visible").text("Number of comunications: "+ d.weight);;
      })
      .on("mousemove", (event,d) =>{
        return tooltip
          .style("top", event.pageY - 10 + "px")
          .style("left", event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        return tooltip.style("visibility", "hidden");
      });
    // Highlight specific nodes
    node
      .on("mouseover", (node) => highlight(true, node))
      .on("mouseout", (node) => highlight(false, node));

    var timeout = null;

    // Node click events
    node
      .on("click", (event, node) => {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
          // ko.postbox.publish(PostboxTopics.selectedEntity, node.id);
          container.selectAll(".removeableCircle").remove();
          setGraphData(dataStep2);
          // showDrawer();
          setSelectedNodeValue(node.name);
          focus(node);
          highlight(true, node, true);
        }, 300);
      })
      .on("dblclick", (event, node) => {
        dblclick(event, node);
        clearTimeout(timeout);
      });

    // Base zoom transformation
    const zoomed = (event) => {
      currentScale = event.transform.k;
      return container.attr("transform", event.transform);
    };
    const setZoom = zoom().on("zoom", zoomed).scaleExtent([0, 2]);

    // Applies the zoop features to the SVG
    svg.call(setZoom);

    // Disable the zoom in property on double click
    svg.on("dblclick.zoom", null);

    // Button Zoom in and out
    d3.select("#zoom_out").on("click", () =>
      setZoom.scaleBy(svg.transition(), 0.8)
    );
    d3.select("#zoom_in").on("click", () =>
      setZoom.scaleBy(svg.transition(), 1.2)
    );

    // Resets everything on the screen
    d3.select("#reset").on("click", () => {
      currentScale = 1;
      svg.call(setZoom.transform, d3.zoomIdentity);
      unpinNodes(undefined);
      unselect();
      scaleToggle(true);
    });

    // // Network diagram mode
    // d3.select("#network_diagram").on("click", () => {
    //   networkDiagramToggleCount = 1;
    //   networkDiagramToggle(1);
    // });

    // // Hierarchical diagram mode
    // d3.select("#hierarchical_diagram").on("click", () =>
    //   hierarchicalDiagramToggle()
    // );

    // scale (aka magic wand) method
    d3.select("#scale").on("click", () => scaleToggle(false));
  };
  useEffect(() => {
    if (graphData && Object.keys(graphData).length > 0) createSVG();
  }, [graphData]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) createSVG();
  }, [isNetworkDiagramMode]);

  // const onFormChanged = (changedValues) => {

  //   setTempForm((current) => {
  //     return {
  //       ...defaultValues,
  //       ...current,
  //       ...changedValues,
  //     };
  //   });
  // };
  // const { taxonomyMap } = useContext(DemographicsContext);
  // const [filteredTaxonomyMap, setFilteredTaxonomyMap] = useState({});
  // const [taxonomyGroups, setTaxonomyGroups] = useState([]);
  // const [ filteredDemographicKeys, setFilteredDemographicKeys ] = useState([]);
  // const [ demographicIncludeNone, setDemographicIncludeNone ] = useState(true);
  // const [ demographicFilters, setDemographicFilters ] = useState({});
  // useEffect(() => {

  //   let tempTaxonomyMap = {};
  //   Object.keys(taxonomyMap).map((index) => {
  //     tempTaxonomyMap[index] = [];

  //     const values = taxonomyMap[index];
  //     values.forEach((value) => {
  //       tempTaxonomyMap[index].push(value);
  //     });
  //   });
  //   setFilteredTaxonomyMap(tempTaxonomyMap);

  // }, [taxonomyMap]);

  // useEffect(() => {
  //   let tempTaxonomyGroups = {};
  //   Object.values(filteredTaxonomyMap).map((values) => {
  //     values.forEach((value) => {
  //       const key = value.category + ":" + value.taxonomy;
  //       if (!tempTaxonomyGroups[key]) {
  //         tempTaxonomyGroups[key] = [];
  //       }
  //       tempTaxonomyGroups[key].push(value);
  //     });
  //   });
  //   setTaxonomyGroups(tempTaxonomyGroups);

  // }, [filteredTaxonomyMap]);


  return (
    <>
      <div style={{ margin: "20px 0 20px 0", width: "100%", height: "80%" }}>

        <Filters open={open} onClose={onClose} />
        
        <Button onClick={() => graphMode()}>Graph View</Button>
        <Button onClick={() => hierarchyMode()}>Hierarchical View</Button>
        <Button id="zoom_in"> Zoom In</Button>
        <Button id="zoom_out"> Zoom Out</Button>
        <Button id="reset"> Reset</Button>
        <Button id="filters" onClick={showDrawer}>
          {" "}
          Filters
        </Button>

        <div className="tooltip" ref={tooltipRef} />
        <div
          ref={ref}
          //id={"mysvg"}
          style={{ margin: "20px 0 20px 0", width: "90%", minHeight: "800px" }}
        ></div>
      </div>
    </>
  );
};
