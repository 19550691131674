import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider, Button, Table, Modal } from "antd";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useIntl } from "react-intl";
import * as reportActions from "../actions/reports";
import { combineStyles } from "../utils";
import commonStyles from "./common_styles";
import {
  fetchReports,
  deleteReports
} from '../actions/reports';
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";
function ReportsList({ forums }) {
  const history = useHistory();
  const { confirm } = Modal;
  const [isForumSelectorOpen, setForumSelectorOpen] = React.useState(false);
  const [forumId, setForumId] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [selectedReportIds, setSelectedReportIds] = React.useState({});
  const reports = useSelector(
    (state) => state.reports.data && Object.values(state.reports.data));
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const classes = combinedStyles();

  const intl = useIntl();
  const reportsPlaceholder = intl.formatMessage({
    id: "app.reports.reports",
    defaultMessage: "Reports",
  });
  const createReportPlaceholder = intl.formatMessage({
    id: "app.reports.createReport",
    defaultMessage: "Create New Report",
  });
  const titlePlaceholder = intl.formatMessage({
    id: "app.reports.title",
    defaultMessage: "Title",
  });
  const createdPlaceholder = intl.formatMessage({
    id: "app.reports.created",
    defaultMessage: "Created",
  });
  const createdByPlaceholder = intl.formatMessage({
    id: "app.reports.createdBy",
    defaultMessage: "Created by",
  });
  const lastEditedPlaceholder = intl.formatMessage({
    id: "app.reports.lastEdited",
    defaultMessage: "Last edited",
  });
  const cancelPlaceholder = intl.formatMessage({
    id: "app.cancel",
    defaultMessage: "Cancel",
  });
  const okPlaceholder = intl.formatMessage({id: 'app.ok',defaultMessage: 'Ok'});
  const savePlaceholder = intl.formatMessage({
    id: "app.save",
    defaultMessage: "Save",
  });
  const deletePlaceholder = intl.formatMessage({
    id: "app.delete",
    defaultMessage: "Delete",
  });
  const viewPlaceholder = intl.formatMessage({
    id: "app.view",
    defaultMessage: "View",
  });
  const reportsDeletePlaceholder= intl.formatMessage({
    id: "app.reports.delete",
    defaultMessage: "Are you sure you want to delete the selected Reports?",
  });
  React.useEffect(() => {
    reportActions.fetchReports();
  }, []);

  const openForumSelector = () => {
    setForumSelectorOpen(true);
  };

  const closeForumSelector = () => {
    setForumSelectorOpen(false);
  };

  const selectReport = (reportId) => {
    history.push(`/reports/${reportId}`);
  };

  const handleForumChange = (ev) => {
    setForumId(ev.target.value);
  };

  const handleTitleChange = (ev) => {
    setTitle(ev.target.value);
  };
  const handleDelete = () => {
    //get Report IDs
    confirm({
      title: reportsDeletePlaceholder,
      icon: <ExclamationCircleOutlined />,
      content: null,
      onOk: () => {
        dispatch(deleteReports(selectedReportIds));
      },
      onCancel() {},
      okText:okPlaceholder,
      cancelText:cancelPlaceholder
    });
 
  };
  const createReport = () => {
    // dispatch(reportActions.saveReport({ forumId, title, clientId: user.client_id }));
    dispatch(reportActions.saveReport({ title, clientId: user.client_id }));
    setForumId("");
    setTitle("");
    setForumSelectorOpen(false);
  };
  const columns = [
    {
      title: titlePlaceholder,
      dataIndex: "title",
      key: "title",
    },
    {
      title: createdPlaceholder,
      dataIndex: "created_date",
      key: "created_date",
      render: (created_date) => (
        <div>{moment(created_date).format("Do MMMM YYYY, hA")}</div>
      ),
    },
    {
      title: lastEditedPlaceholder,
      dataIndex: "updated_date",
      key: "updated_date",
      render: (updated_date) => <div>{moment(updated_date).fromNow()}</div>,
    },
    {
      title: "Action",
      render: (data) => (
        <Button
          type="primary"
          style={{ borderRadius: "8px" }}
          onClick={() => selectReport(data.id)}
        >
          {viewPlaceholder}
        </Button>
      ),
    },
  ];
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedReportIds(selectedRows.map(x => x.id));
    },
  };
  return (
    <div className={classes.sectionContent} data-test="component-report-list">
      <div className={classes.sectionName}>{reportsPlaceholder}</div>
      <div className={classes.buttonLine}>
        <div >
          <Button
            type="primary"
            style={{ borderRadius: "8px", minWidth: "150px", marginRight:"10px"}}
            onClick={openForumSelector}
          >
            {createReportPlaceholder}
          </Button>
          <Button
            type="primary"
            disabled={Object.keys(selectedReportIds).length == 0}
            style={{ borderRadius: "8px", minWidth: "150px" }}
            onClick={handleDelete}
          >
            {deletePlaceholder}
          </Button>
        </div>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={isForumSelectorOpen}
          onClose={closeForumSelector}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {createReportPlaceholder}
          </DialogTitle>
          <DialogContent>
            <form
              className={classes.form}
              noValidate
              autoComplete="off"
              data-test="dialog-form"
            >
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  data-test="report-title-input"
                  name={titlePlaceholder}
                  fullWidth
                  label={titlePlaceholder}
                  value={title}
                  onChange={handleTitleChange}
                  inputProps={{
                    id: "title",
                    name: "title",
                  }}
                />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button type="secondary" onClick={closeForumSelector}>
              {cancelPlaceholder}
            </Button>
            <Button
              type="primary"
              onClick={createReport}
              data-test="save-button"
            >
              {savePlaceholder}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Table
        rowSelection={rowSelection}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={reports}
      />
    </div>
  );
}

const useStyles = (theme) => ({
  buttonLine: {
    marginBottom: "6px",
    textAlign: "right",
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    marginBottom: "25px",
    width: "100%",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  mainBtn: {
    color: "#516e88",
    display: "inline-block",
    fontSize: "14.2px",
    fontStretch: "normal",
    fontWeight: 500,
    height: "36px",
    letterSpacing: 1.25,
    lineHeight: 1.12,
    width: "204px",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  sectionContent: {
    width: "100%",
  },
  table: {
    marginBottom: "24px",
    minWidth: "400px",
  },
});

const combinedStyles = makeStyles(combineStyles(commonStyles, useStyles));

export default ReportsList;