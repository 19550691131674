import {
  FETCH_CUBE_DATA_LOADING,
  FETCH_CUBE_DATA_SUCCESS,
  FETCH_CUBE_DATA_FAILURE,
} from '../actions/cube';
import * as queries from '../queries';
import { transform } from '../transformers/utils';

const initialState = Object.values(queries).reduce((a, q) => {
  a[q.type] = {
    data: q.default,
    error: null,
    loaded: false,
    loading: false,
  };
  return a;
}, {});

export function cube(state = initialState, action) {
  let key;
  switch (action.type) {
    case FETCH_CUBE_DATA_LOADING:
      key = action.payload.key;
      return {
        ...state,
        [key]: {
          ...(state[key] || {}),
          error: null,
          loading: true,
        }
      };

    case FETCH_CUBE_DATA_SUCCESS:
      key = action.payload.key;

      return {
        ...state,
        [key]: {
          data: transform(key, action.payload.data),
          error: null,
          loaded: true,
          loading: false,
        }
      };

    case FETCH_CUBE_DATA_FAILURE:
      key = action.payload.key;
      return {
        ...state,
        [key]: {
          data: (state[key] || {}).data || [],
          error: action.payload.error,
          loaded: true,
          loading: false,
        }
      };

    default:
      return state;
  }
}