import React from 'react';

import { Card } from 'antd';

import NoData from '../components/NoData';
import ClusterChartWidget from '../Overview/ClusterChartWidget';
import CommentWidget from '../Overview/CommentWidget';
import EmojifyWidget from '../Overview/EmojifyWidget';
import EmojifyTopicWidget from '../Overview/EmojifyTopicWidget';
import SentimentedEntitiesWidget from '../Overview/SentimentedEntitiesWidget';
import MoodWidget from '../Overview/MoodWidget';
import LDAWidget from '../Overview/LDAWidget';
import KnowledgeGraphWidget from '../Overview/KnowledgeGraphWidget';
import ProblemSolutionWidget from '../Overview/ProblemSolutionWidget';
import ParticipationChartWidget from '../Overview/ParticipationChartWidget';
import CommentView from '../Explore/CommentView';
import ChunksView from '../Explore/ChunksView';
import MoodTableView from '../Explore/MoodTableView';
import ReportImgContextProvider from '../context/report-context';
import PowerfulStatementsWidget from '../Overview/PowerfulStatementsWidget';

export default ({ id, chartType, ... props }) => {

  // Allow this component to be used as a Route or directly.
  if (props.match) {
    if (!id) {
      id = props.match.params.id;
    }
    if (!chartType) {
      chartType = props.match.params.chartType;
    }
  }

  const renderChart = () => {
    const customProps = {
      chartId: id,
      truncated: false,
      noWidget: true
    };

    switch(chartType) {
      case 'sentimented-topics':
        return <EmojifyTopicWidget { ... customProps } />
      case 'sentimented-entities':
        return <SentimentedEntitiesWidget { ... customProps } />
      case 'most-discussed-topics':
        return <ClusterChartWidget { ... customProps } />
      case 'emojify':
        return <EmojifyWidget { ... customProps } />
      case 'emoji':
        return <EmojifyWidget { ... customProps } />
      case 'mood':
        return <MoodWidget { ... customProps } />;
      case 'data-analysed':
        return <CommentWidget { ... customProps } />;
      case 'topic-ldavis':
        return <LDAWidget { ... customProps } />;
      case 'problem-solution':
        return <ProblemSolutionWidget { ... customProps } />;
        case 'powerful-statements':
          return <PowerfulStatementsWidget { ... customProps } />;
      case 'knowledge-graph':
        return <KnowledgeGraphWidget { ... customProps } />;
      case 'comments':
        return <CommentView { ... customProps } />;
      case 'chunks':
        return <ChunksView { ... customProps } />;
    }

    return (
      <Card
        style={ { width: '100%' }}
      >
        no chart type defined.
      </Card>
    )
  };

  return (
    <ReportImgContextProvider>
      { id && renderChart() }
      {
        !id &&
        <NoData />
      }
    </ReportImgContextProvider>
  );
};