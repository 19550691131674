import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import { useIntl } from "react-intl";

function AddSectionMenu({
  classes,
  index,
  report,
  nextOpen,
  onInsertSection,
}) {

  const [isExpanded, setExpanded] = useState(nextOpen === index);
  const intl = useIntl();
  const insertPlaceholder = intl.formatMessage({id: 'app.reports.edit.insert',defaultMessage: 'Insert'});
  const headingPlaceholder = intl.formatMessage({id: 'app.reports.edit.heading',defaultMessage: 'Heading'});
  const bodyTextPlaceholder = intl.formatMessage({id: 'app.reports.edit.bodyText',defaultMessage: 'Body Text'});
  const imagePlaceholder = intl.formatMessage({id: 'app.reports.edit.image',defaultMessage: 'Image'});
  const chunksPlaceholder = intl.formatMessage({id: 'app.reports.edit.chunks',defaultMessage: 'Chunks'});
  const chunksSummaryPlaceholder = intl.formatMessage({id: 'app.reports.edit.chunksSummary',defaultMessage: 'Chunks Summary'});
  
  useEffect(() => {
    setExpanded(nextOpen === index);
  }, [index, nextOpen]);

  const handleInsertHeading = () => {
    onInsertSection('section-heading', index);
  }

  const handleInsertImage = () => {
    onInsertSection('image', index);
  }

  const handleInsertText = () => {
    onInsertSection('body-text', index);
  }

  const handleInsertChunk = () => {
    onInsertSection('chunk', index);
    
  }
 const handleInsertChunksSummary= () => {
    onInsertSection('text-analysis', index);
  }

  const toggleMenu = () => {
    setExpanded(!isExpanded);
  }

  return (
    <div>
      {isExpanded ?
        <div className={classes.menu}>
          <span className={classes.title}>{insertPlaceholder}</span>
          <Button
            className={classes.menuButton}
            color="primary"
            variant="outlined"
            onClick={handleInsertHeading}
          >{headingPlaceholder}</Button>
          <Button
            className={classes.menuButton}
            color="primary"
            variant="outlined"
            onClick={handleInsertText}
          >{bodyTextPlaceholder}</Button>
          <Button
            className={classes.menuButton}
            color="primary"
            variant="outlined"
            onClick={handleInsertImage}
          >{imagePlaceholder}</Button>
          {report.chunks.length > 0 &&
          <Button
            className={classes.menuButton}
            color="primary"
            variant="outlined"
            onClick={handleInsertChunk}
          >{chunksPlaceholder}</Button>
          }
          {report.textAnalysis.length>0 &&
           <Button
            className={classes.menuButton}
            color="primary"
            variant="outlined"
            onClick={handleInsertChunksSummary}
          >{chunksSummaryPlaceholder}</Button>
          }
          <ClearIcon className={classes.clearIcon} onClick={toggleMenu} />
        </div>
        :
        <div className={classes.iconWrap}>
          <AddCircleIcon className={classes.icon} onClick={toggleMenu} />
        </div>
      }
    </div>
  );
}

const styles = (theme) => ({
  clearIcon: {
    color: 'rgba(84, 110, 122, 0.8)',
    fontSize: '18px',
    fontWeight: 500,
    position: 'absolute',
    top: '50%',
    right: '16px',
    transform: 'translate(0, -50%)',
  },
  icon: {
    backgroundColor: '#fff',
    color: 'rgba(84, 110, 122, 0.8)',
    position: 'absolute',
    top: '-11px',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  iconWrap: {
    border: '1px dashed #90a4ae',
    margin: '20px 0',
    position: 'relative',
  },
  menu: {
    border: '1px dashed #90a4ae',
    borderRadius: '4px',
    padding: '12px 16px',
    position: 'relative',
    textAlign: 'center',
  },
  menuButton: {
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    height: '36px',
    letterSpacing: '1.25px',
    lineHeight: 1.12,
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    lineHeight: 1.31,
    marginRight: '16px',
  },
});

export default withStyles(styles, { withTheme: true })(AddSectionMenu);