import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import NoData from '../NoData';

import './emoticons.css';

function MoodCard({ classes, averageMood }) {

  const roundedMood = Math.round(averageMood);
  const moodLabel = getMoodLabel(roundedMood);
  const emoticon = 'emoticons-large-face' + roundedMood;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Average mood
        </Typography>
        
        <div className={classes.centerContent}>
          {roundedMood?
          <Fragment>
          <div className={classes.centerContent}>
            <div className={emoticon}></div>
          </div>
          <div className={classes.itemText}>
            {moodLabel}
          </div>
          </Fragment>
          :
          <NoData/>}
        </div>
         
      </CardContent>
     
    </Card>
  );
}

function getMoodLabel(roundedMood) {
  let moodLabel = '';
  if (roundedMood < 3) {
    moodLabel = 'Unhappy';
  } else if (roundedMood < 4) {
    moodLabel = 'Slightly unhappy';
  } else if (roundedMood <= 5) {
    moodLabel = 'Neutral';
  } else if (roundedMood < 6) {
    moodLabel = 'Somewhat happy';
  } else {
    moodLabel = 'Happy';
  }
  return moodLabel;
}

const styles = {
  card: {
    height: '100%',
    padding: '4px',
  },
  centerContent: {
    paddingTop: '20px',
    marginBottom: '10px',
    minHeight: '100px',
    textAlign: 'center',
  },
  itemIcon: {
    transform: 'scale(1.6)',
  },
  itemText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '20.3px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    fontWeight: 500,
    letterSpacing: 0.25,
    lineHeight: 'normal',
    textAlign: 'center',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
  },
}

export default withStyles(styles)(MoodCard);