import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {FormattedMessage,useIntl} from "react-intl";
import { saveReportChunck } from '../actions/reports';
import { ReportsContext } from '../Main/MainContainer';

function CommentSelector({ defaultTitle='', selectedChunks, isOpen, setIsOpen }) {

  const reports = useContext(ReportsContext);
  const intl = useIntl();
  const savePlaceholder = intl.formatMessage({id: 'app.save',defaultMessage: 'Save'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  const [reportId, setReportId] = useState('');
  const [title, setTitle] = useState(defaultTitle);

  useEffect(()=> {
    setTitle(defaultTitle);
  },[defaultTitle])

  const dispatch = useDispatch();
  const classes = useStyles();

  const close = () => {
    setIsOpen(false);
  }

  const handleAddToReportClick = () => {
    setIsOpen(true);
  }

  const handleReportChange = (event) => {
    setReportId(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSave = () => {
    const chunks = reports[reportId].chunks;
    const chunk_uid_array = chunks.map(chunk => chunk.chunk_id);
    selectedChunks.forEach(comment => {

      // temporary check both chunk_id and chunk_uuid because we're using two source of API calls
      // don't execute save if there's the same chunk_uid in db or with different title
      if(!chunk_uid_array.includes(comment.comment_id|| comment.comment_uuid)
       || (chunks.find(c => c.chunk_id === comment.comment_id) && chunks.find(c => c.chunk_id === comment.comment_id).title !== title)
       || (chunks.find(c => c.chunk_id === comment.comment_uuid) && chunks.find(c => c.chunk_id === comment.comment_uuid).title !== title))
       {
        const data = {
          report: reportId,
          chunk_id: comment.comment_id,
          title,
          content: comment.text
        };
        dispatch(saveReportChunck(data));
      }
    })
    setIsOpen(false);
    setReportId('');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={close}
      TransitionComponent={DialogTransition}
      aria-labelledby="add-to-report-dialog-slide-title"
      aria-describedby="add-to-report-dialog-slide-description"
    >
      <DialogTitle id="add-to-report-dialog-slide-title">
        <FormattedMessage
          id="app.explorePage.addToReport"
          defaultMessage="Add to report"
        />
      </DialogTitle>
      <DialogContent>
        <ValidatorForm
          className={classes.form}
          autoComplete="off"
          onSubmit={handleSave}
          onError={(errors) => console.log(errors)}
        >
          <FormControl fullWidth>
            <SelectValidator
              fullWidth
              id="report-selector"
              label="Report"
              margin="normal"
              value={reportId}
              onChange={handleReportChange}
              required={true}
              validators={["required"]}
              errorMessages={["this field is required"]}
            >
              {Object.values(reports).map((rpt) => (
                <MenuItem key={rpt.id} value={rpt.id}>
                  {rpt.title}
                </MenuItem>
              ))}
            </SelectValidator>
            <TextValidator
              autoFocus
              fullWidth
              id="title-input"
              label="Title"
              margin="normal"
              rows="1"
              value={title}
              onChange={handleTitleChange}
              required={true}
              validators={["required"]}
              errorMessages={["this field is required"]}
            />
          </FormControl>
          <div
            style={{
              display: "inline-block",
              marginLeft: "auto",
              marginRight: 0,
              marginTop: 25,
            }}
          >
            <Button color="secondary" onClick={close}>
              {cancelPlaceholder}
            </Button>
            <Button color="primary" type="submit">
              {savePlaceholder}
            </Button>
          </div>
        </ValidatorForm>
      </DialogContent>
    </Dialog>
  );
}

const DialogTransition = React.forwardRef((props, ref) =>
  <Slide direction="up" {...props} ref={ref} />
);

const useStyles = makeStyles(() => ({
  chart: {
    flex: 2,
  },
  chartWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    border: '0.5px solid black',
    borderRadius: '5px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
  },
}));

export default CommentSelector;