import React from 'react';
import { Card } from 'antd';

/**
 * Wrapper around a chart draggable element.
 *
 * @param blockEl The component element to be wrapped
 * @param isDragging Bool indicating whether the element is being dragged
 */
export default () => ({ blockEl, isDragging }) => (
  <Card
    style={
      {
        background: isDragging ? "lightgreen" : "white",
      }
    }
    bodyStyle={
      {
        padding: '10px'
      }
    }
  >
    { blockEl }
  </Card>
);