import React, { useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from "antd";
import TopicVis from "../../../components/ChartVisualisations/TopicVis";
import Loading from '../../../components/Loading';
import NoData from '../../../components/NoData';
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import {getPhraseLDA} from "../../../actions/strategy_map";
import { useIntl } from "react-intl"; 
function ClusterModal({
  customProps,
  dateRange,
  isModalVisible,
  closeExploreModal,
  nextStep,
  setLdaProps,
  type,
}) {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const phrase_id = customProps.uid;
  const subType = customProps.subType;
  const subTitle = customProps.type + "->" + customProps.subType;

  const dispatch = useDispatch();
  const [modalWidth, setModalWidth] = useState("80%");
  const [modalHeight, setModalHeight] = useState("70vh");
  const [modalTop, setModalTop] = useState("100px");
  const [isMaxModal, setIsMaxModal] = useState(false);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const intl = useIntl();
  const okPlaceholder = intl.formatMessage({id: 'app.ok',defaultMessage: 'Ok'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  const maximise = () => {
    setIsMaxModal(true);
    setModalWidth("100%");
    setModalHeight("89vh");
    setModalTop("0px");
  };
  const minimise = () => {
    setIsMaxModal(false);
    setModalWidth("80%");
    setModalHeight("70vh");
    setModalTop("100px");
  };
  useEffect(() => {
    (async () => {
      let result = await dispatch(
        getPhraseLDA(phrase_id, subType.toLowerCase())
      );
      if (result) {
        setData(JSON.parse(result.payload.ldas[0].data ))
      }
      setLoaded(true);
    })();
  }, []);

  const handleSelect = (topicNum) => {
    let updatedValue = {};
    updatedValue = {
      client_id: user.client_id,
      forumId: customProps.forumId,
      uid: phrase_id,
      topicNum: topicNum,
      startDate:customProps.startDate,
      endDate:customProps.endDate,
      title: "Topic Number "+ topicNum,
      subType: subType,
      subTitle: "Cluster View -> " + subType + " -> List View"
    };
    setLdaProps(updatedValue);
    nextStep();
  };


  return (
    <Modal
      visible={isModalVisible}
      title={
        isMaxModal ? (
          <ShrinkOutlined onClick={minimise} />
        ) : (
          <ArrowsAltOutlined onClick={maximise} />
        )
      }
      width={modalWidth}
      style={{ top: modalTop, maxWidth: modalWidth }}
      bodyStyle={{
        minHeight: modalHeight,
        maxHeight: modalHeight,
        overflow: "auto",
        animationDuration: "0s !important",
      }}
      onOk={closeExploreModal}
      onCancel={closeExploreModal}
      okText={okPlaceholder}
      cancelText={cancelPlaceholder}
      transitionName="none"
      maskTransitionName="none"
      footer={[
        <Button key="1" onClick={closeExploreModal}>
          Close
        </Button>,
      ]}
    >
      {loaded ? (
        data ? (
          <TopicVis
            data={data}
            onSelect={handleSelect}
            title={customProps.title}
            subTitle={subTitle}
          />
        ) : (
          <NoData />
        )
      ) : (
        <Loading />
      )}
    </Modal>
  );
}

export default ClusterModal;
