import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const initialState = {};

const enableOptionals = false;
export const middlewares = [ ... [ thunk ], ... (enableOptionals ? [ logger ] : [])];

const enhancer = applyMiddleware(...middlewares);

const store = createStore(
  rootReducer,
  initialState,
  enhancer,
);

export default store;