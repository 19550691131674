import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchTopicSentiment } from '../actions/topic_sentiment';
import ClusterCard from '../components/Cards/ClusterCard';
import Loading from '../components/Loading';
import NoData from '../components/NoData';
import Widget from '../components/Widget';
import { ForumIdContext } from '../context/forumId-context';
import { ReportImgContext } from '../context/report-context';

const getQuery = (clientUuid, forumUuid, date_range) => {
  const timeDimensions = {
    "dimension": "CommentTopicsDenorm.time"
  };
  if(date_range.startDate){
    timeDimensions.dateRange = [date_range.startDate, date_range.endDate];
  }
  const filters = [
    {
      "dimension": "CommentTopicsDenorm.clientUuid",
      "operator": "equals",
      "values": [clientUuid]
    }

  ];
  if (forumUuid) {
    filters.push({
      "dimension": "CommentTopicsDenorm.forumUuid",
      "operator": "equals",
      "values": [forumUuid]
    });
  }
  return {
  "measures": [
    "CommentTopicsDenorm.count"
  ],
  "timeDimensions": [
    timeDimensions
  ],
  "dimensions": [
    "CommentTopicsDenorm.word",
    "CommentTopicsDenorm.sentiment"
  ],
  "filters": filters
}
};

function ClusterChartWidget(props) {

  const { isShown } = useContext(ReportImgContext);
  const { data, loaded } = useSelector((state) => state.topicSentiment);
  // const [ data, setData ] = useState(null);
  // const [ loaded, setLoaded ] = useState(false);
  const user = useSelector((state) => state.user);
  const date_range = useSelector((state) => state.date_range);
  const dispatch = useDispatch();
  const { forumId } = useContext(ForumIdContext);

  useEffect(() => {
    let zForumId = props.forumId ? props.forumId : (props.useContextRules !== false) ? forumId : 'all';
    zForumId = (zForumId == 'all') ? '' : zForumId;
    let zDateRange = (props.startDate) ? { startDate: props.startDate, endDate: props.endDate } : date_range.data;
    dispatch(fetchTopicSentiment(user.client_id, zForumId, getQuery(user.client_id, zForumId, zDateRange)));
  }, [date_range, forumId, props.forumId, props.startDate, props.endDate]);

  return (
    <Widget title={ props.title ? props.title : "Most discussed topics" } actions={ props.actions } showExploreLink={ isShown } exploreLink="/explore">
      {loaded && data ?  <ClusterCard data={data} /> :
        <Loading />
      }
    </Widget>
  );
}

export default ClusterChartWidget;