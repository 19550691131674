import React, { useState, useEffect, useContext } from "react";
import { useIntl } from "react-intl"; 
import _ from "lodash";
import { Button, Drawer, Divider } from "antd";
import { Form, Input, Select, Modal, DatePicker, Space } from "antd";
import { DemographicsContext } from "../context/demographics-context";
import moment from "moment";
const { Option } = Select;

const StrategyMapDataSourceCreatorBlock = ({
  panes,
  addConfig,
  forumDataSources,
  drawerOnClose,
  showConfig,
}) => {
  const [form] = Form.useForm();
  const [tempForm, setTempForm] = useState({});
  const { taxonomies, taxonomyMap } = useContext(DemographicsContext);
  const [chartTempFilterOptions, setChartTempFilterOptions] = useState({});
  const intl = useIntl();
  const headerPlaceholder = intl.formatMessage({id: 'app.strategyMap.config.header',defaultMessage: 'Strategy Map Configuration'})
  const titlePlaceholder = intl.formatMessage({id: 'app.strategyMap.config.title',defaultMessage: 'Title'})
  const dataSourcePlaceholder = intl.formatMessage({id: 'app.strategyMap.config.dataSource',defaultMessage: 'Data Source'})
  const startDatePlaceholder = intl.formatMessage({id: 'app.strategyMap.config.startDate',defaultMessage: 'Start Date'})
  const endDatePlaceholder = intl.formatMessage({id: 'app.strategyMap.config.endDate',defaultMessage: 'End Date'})
  const columnsPlaceholder = intl.formatMessage({id: 'app.strategyMap.config.columns',defaultMessage: 'Columns'})
  const rowsPlaceholder = intl.formatMessage({id: 'app.strategyMap.config.rows',defaultMessage: 'Rows'})
  const filterOptionsPlaceholder = intl.formatMessage({id: 'app.strategyMap.config.filterOptions',defaultMessage: 'Filter Options'})
  const savePlaceholder = intl.formatMessage({id: 'app.save',defaultMessage: 'Save'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  
  const defaultValues = {
    text: "Untitled",
    dataSourceId: "all",
    startDate: "2020-06-01",
    endDate: moment().format("YYYY-MM-DD"),
    autoEndDate: "true",
  };

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  useEffect(() => {
    setTempForm({
      ...defaultValues,
    });
  }, []);

  const updateRecord = (values) => {
    const validKeys = Object.keys(values).filter((key) => {
      return values[key] !== undefined;
    });
    const validValues = validKeys.map((key) => {
      return values[key];
    });
    let validatedObject = _.zipObject(validKeys, validValues);
    if (typeof validatedObject.startDate !== "string") {
      validatedObject.startDate =
        validatedObject.startDate.format('YYYY-MM-DD');
    }
    if (typeof validatedObject.endDate !== "string" && values.autoEndDate !== 'true') {
      validatedObject.endDate = validatedObject.endDate.format('YYYY-MM-DD');
    }
    if (Object.keys(chartTempFilterOptions).length > 0) {
      validatedObject.table_dim1 = chartTempFilterOptions.table_dim1;
      validatedObject.table_dim2 = chartTempFilterOptions.table_dim2;
    }
    if (values.autoEndDate === "true") validatedObject.endDate = null;
    setTempForm((current) => {
      current = {
        ...defaultValues,
        ...current,
        ...validatedObject,
      };
      addConfig(current);
      return current;
    });
  };

  const save = () => {
    form.validateFields().then(onFinish).catch(onFinishFailed);
  };

  const onFinish = (values) => {
    drawerOnClose();
    updateRecord(values);
  };

  const onFinishFailed = () => {};
  const onFormChanged = (changedValues) => {
    setTempForm((current) => {
      return {
        ...defaultValues,
        ...current,
        ...changedValues,
      };
    });
  };
  return !tempForm ? (
    <></>
  ) : (
    <>
      <Drawer
        destroyOnClose
        title={headerPlaceholder}
        width={520}
        closable={true}
        onClose={() => drawerOnClose()}
        visible={showConfig}
        getContainer={false}
        style={{
          zIndex: 2000,
        }}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={() => drawerOnClose()} style={{ marginRight: 8 }}>
              {cancelPlaceholder}
            </Button>
            <Button onClick={save} type="primary">
              {savePlaceholder}
            </Button>
          </div>
        }
      >
        <Form
          {...formItemLayout}
          layout="horizontal"
          form={form}
          initialValues={{
            layout: "horizontal",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChanged}
        >
          <Form.Item
            label={titlePlaceholder}
            name="text"
            initialValue={tempForm.text}
            rules={[
              {
                required: true,
                message: "Please provide a title for this data source",
              },
              {
                min: 3,
                message:
                  "You must provide a title greater than or equal to 3 characters",
              },
              {
                max: 255,
                message:
                  "You must provide a title less than or equal to 255 characters",
              },
            ]}
          >
            <Input placeholder="Provide text here ..." />
          </Form.Item>

          <Form.Item
            label={dataSourcePlaceholder}
            name="dataSourceId"
            initialValue={tempForm.dataSourceId}
            rules={[
              {
                required: true,
                message: "Please choose a chart type",
              },
            ]}
          >
            <Select
              dropdownStyle={{
                zIndex: 3000,
              }}
            >
              <Option key={"all"} value={"all"}>
                All
              </Option>
              {forumDataSources.map((forum) => (
                <Option key={forum.id} value={forum.id}>
                  {forum.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={startDatePlaceholder}
            name="startDate"
            initialValue={moment(tempForm.startDate, "YYYY-MM-DD")}
          >
            <DatePicker
              format={"YYYY-MM-DD"}
              popupStyle={{
                zIndex: 3000,
              }}
            />
          </Form.Item>
          <Form.Item label={endDatePlaceholder}>
            <Space>
              <Form.Item
                name="autoEndDate"
                noStyle
                initialValue={tempForm.autoEndDate}
                rules={[
                  {
                    required: true,
                    message: "Please choose an option",
                  },
                ]}
              >
                <Select
                  style={{ width: 145 }}
                  dropdownStyle={{
                    zIndex: 3000,
                  }}
                >
                  <Option value="false">Manual Date</Option>
                  <Option value="true">Automatic Today</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={endDatePlaceholder}
                name= "endDate"
                noStyle
                initialValue={moment(tempForm.endDate, "YYYY-MM-DD")}
                rules={[
                  {
                    required: true,
                    message: "Please choose an option",
                  },
                ]}
              >
                {tempForm.autoEndDate === "false" && (
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    popupStyle={{
                      zIndex: 3000,
                    }}
                  />
                )}
              </Form.Item>
            </Space>
          </Form.Item>

          <Divider plain>{filterOptionsPlaceholder}</Divider>
          <Form.Item
            label={columnsPlaceholder}
            rules={[
              {
                required: true,
                message: "Please choose a value",
              },
            ]}
          >
            <Select
              dropdownStyle={{
                zIndex: 3000,
              }}
              defaultValue={
                tempForm.filters ? tempForm.filters.comparisonA : null
              }
              onChange={(value) =>
                setChartTempFilterOptions({
                  ...chartTempFilterOptions,
                  ...{ table_dim1: value },
                })
              }
            >
              {taxonomies
                .filter((title) => title != chartTempFilterOptions.comparisonB)
                .map((title, index) => (
                  <Option key={index} value={title}>
                    {title}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={rowsPlaceholder}
            rules={[
              {
                required: true,
                message: "Please choose a value",
              },
            ]}
          >
            <Select
              dropdownStyle={{
                zIndex: 3000,
              }}
              defaultValue={
                tempForm.filters ? tempForm.filters.comparisonB : null
              }
              onChange={(value) =>
                setChartTempFilterOptions({
                  ...chartTempFilterOptions,
                  ...{ table_dim2: value },
                })
              }
            >
              {taxonomies
                .filter((title) => title != chartTempFilterOptions.comparisonA)
                .map((title, index) => (
                  <Option key={index} value={title}>
                    {title}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default StrategyMapDataSourceCreatorBlock;
