import axios from '../http';

export const FETCH_REPORTS_LOADING = 'FETCH_REPORTS_LOADING';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';

export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';

export const SAVE_REPORT_SUCCESS = 'SAVE_REPORT_SUCCESS'
export const SAVE_REPORT_FAILURE = 'SAVE_REPORT_FAILURE'

export const FETCH_REPORT_SECTIONS_SUCCESS = 'FETCH_REPORT_SECTIONS_SUCCESS';
export const FETCH_REPORT_SECTIONS_FAILURE = 'FETCH_REPORT_SECTIONS_FAILURE';

export const SAVE_REPORT_SECTION_SUCCESS = 'SAVE_REPORT_SECTION_SUCCESS'
export const SAVE_REPORT_SECTION_FAILURE = 'SAVE_REPORT_SECTION_FAILURE'

export const DELETE_REPORT_SECTION_SUCCESS = 'DELETE_REPORT_SECTION_SUCCESS'
export const DELETE_REPORT_SECTION_FAILURE = 'DELETE_REPORT_SECTION_FAILURE'

export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS'
export const DELETE_REPORT_FAILURE = 'DELETE_REPORT_FAILURE'

export const DELETE_REPORTS_SUCCESS = 'DELETE_REPORTS_SUCCESS'
export const DELETE_REPORTS_FAILURE = 'DELETE_REPORTS_FAILURE'

export const FETCH_REPORT_IMAGES_SUCCESS = 'FETCH_REPORT_IMAGES_SUCCESS';
export const FETCH_REPORT_IMAGES_FAILURE = 'FETCH_REPORT_IMAGES_FAILURE';

export const SAVE_REPORT_IMAGE_SUCCESS = 'SAVE_REPORT_IMAGE_SUCCESS';
export const SAVE_REPORT_IMAGE_FAILURE = 'SAVE_REPORT_IMAGE_FAILURE';

export const SAVE_REPORT_CHUNK_SUCCESS = 'SAVE_REPORT_CHUNK_SUCCESS';
export const SAVE_REPORT_CHUNK_FAILURE = 'SAVE_REPORT_CHUNK_FAILURE';

export const SAVE_REPORT_TEXT_ANALYSIS_SUCCESS = 'SAVE_REPORT_TEXT_ANALYSIS_SUCCESS';
export const SAVE_REPORT_TEXT_ANALYSIS_FAILURE = 'SAVE_REPORT_TEXT_ANALYSIS_FAILURE';

function fetchReportsLoading() {
  return {
    type: FETCH_REPORTS_LOADING,
  };
}

function fetchReportsSuccess(payload) {
  return {
    type: FETCH_REPORTS_SUCCESS,
    payload,
  };
}

function fetchReportsFailure(payload) {
  return {
    type: FETCH_REPORTS_FAILURE,
    payload,
  }
}

export function fetchReports() {
  return async (dispatch) => {
    try {
      dispatch(fetchReportsLoading());
      const res = await axios.get('/api/v1/reports/')
      if (res.status >= 400) {
        throw Error(res.statusText);
      }
      return(dispatch(fetchReportsSuccess(res.data)));
    } catch (error) {
      dispatch(fetchReportsFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }));
    }
  };
}


function fetchReportSuccess(payload) {
  return {
    type: FETCH_REPORT_SUCCESS,
    payload,
  };
}

function fetchReportFailure(payload) {
  return {
    type: FETCH_REPORT_FAILURE,
    payload,
  }
}

export function fetchReport(reportId) {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/api/v1/reports/${reportId}/`);
      if (res.status < 200 || res.status > 399) {
        throw Error(res.statusText);
      }
      dispatch(fetchReportSuccess(res.data));
    } catch (error) {
      dispatch(fetchReportFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }))
    }
  }
}

function saveReportSuccess(payload) {
  return {
    type: SAVE_REPORT_SUCCESS,
    payload,
  };
}

function saveReportFailure(payload) {
  return {
    type: SAVE_REPORT_FAILURE,
    payload,
  };
}

export function saveReport(data) {
  return async (dispatch) => {
    const payload = {
     // forum_uuid: data.forumId,
      title: data.title,
    };
    try {
      let url;
      let res;
      if (data.id) {
        url = `/api/v1/reports/${data.id}/`;
        res = await axios.patch(url, payload);
      } else {
        url = '/api/v1/reports/';
        res = await axios.post(url, payload);
      }
      if (res.status >= 400) {
        throw Error(res.statusText);
      }
      dispatch(saveReportSuccess(res.data));
    } catch (error) {
      console.error('Error saving report:', error);
      return dispatch(saveReportFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }));
    }
  };
}

function fetchReportSectionsSuccess(payload) {
  return {
    type: FETCH_REPORT_SECTIONS_SUCCESS,
    payload,
  };
}

function fetchReportSectionsFailure(payload) {
  return {
    type: FETCH_REPORT_SECTIONS_FAILURE,
    payload,
  }
}

export function fetchReportSections(reportId) {
  return async (dispatch) => {
    try {
      const res =  await axios.get(`/api/v1/reports/${reportId}/sections/`);
      if (res.status < 200 || res.status > 399) {
        throw Error(res.statusText);
      }
      dispatch(fetchReportSectionsSuccess(res.data));
    } catch (error) {
      dispatch(fetchReportSectionsFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }))
    }
  }
}

function saveReportSectionSuccess(payload) {
  return {
    type: SAVE_REPORT_SECTION_SUCCESS,
    payload,
  };
}

function saveReportSectionFailure(payload) {
  return {
    type: SAVE_REPORT_SECTION_FAILURE,
    payload,
  };
}

export function saveReportSection(report_Id, data) {
  return async (dispatch) => {
    try {
      let url;
      let res;
      if (data.id) {
        url = `/api/v1/report-sections/${data.id}/`;
        res = await axios.patch(url, data);
      } else {
        url = '/api/v1/report-sections/';
        res = await axios.post(url, data);
      }
      if (res.status >= 400) {
        throw Error(res.statusText);
      }
      dispatch(saveReportSectionSuccess({ report_Id, new_section: res.data }));
    } catch (error) {
      console.error('Error saving report section:', error);
      dispatch(saveReportSectionFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }));
    }
  };
}

function deleteReportSectionSuccess(payload) {
  return {
    type: DELETE_REPORT_SECTION_SUCCESS,
    payload,
  };
}

function deleteReportSectionFailure(payload) {
  return {
    type: DELETE_REPORT_SECTION_FAILURE,
    payload,
  };
}

export function deleteReportSection(reportId, sectionId) {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`/api/v1/report-sections/${sectionId}/`);
      if (res.status < 200 || res.status > 399) {
        throw Error(res.statusText);
      }
      dispatch(deleteReportSectionSuccess({ reportId, sectionId }));
    } catch (error) {
      dispatch(deleteReportSectionFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }))
    }
  };
}

function deleteReportSuccess(payload) {
  return {
    type: DELETE_REPORT_SUCCESS,
    payload,
  };
}

function deleteReportFailure(payload) {
  return {
    type: DELETE_REPORT_FAILURE,
    payload,
  };
}

export function deleteReport(reportId) {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`/api/v1/reports/${reportId}/`);
      if (res.status >= 400) {
        throw Error(res.statusText);
      }
      return dispatch(deleteReportSuccess(reportId));
    } catch (error) {
      return dispatch(deleteReportFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        }
      }));
    }
  }
}


function deleteReportsSuccess(payload) {
  return {
    type: DELETE_REPORTS_SUCCESS,
    payload,
  };
}

function deleteReportsFailure(payload) {
  return {
    type: DELETE_REPORTS_FAILURE,
    payload,
  };
}

export function deleteReports(reportIds) {
  return async (dispatch) => {
    try {
      const res = await axios.post(`/api/v1/deleteReports/`,{reportIds} );
      if (res.status >= 400) {
        throw Error(res.statusText);
      }
      return dispatch(deleteReportsSuccess(reportIds));
    } catch (error) {
      return dispatch(deleteReportsFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        }
      }));
    }
  }
}


function fetchReportImagesSuccess(payload) {
  return {
    type: FETCH_REPORT_IMAGES_SUCCESS,
    payload,
  };
}

function fetchReportImagesFailure(payload) {
  return {
    type: FETCH_REPORT_IMAGES_FAILURE,
    payload,
  }
}

export function fetchReportImages(reportId) {
  return async(dispatch) => {
    try {
      const res = await axios.get(`/api/v1/reports/${reportId}/images/`);
      if (res.status < 200 || res.status > 399) {
        throw Error(res.statusText);
      }
      dispatch(fetchReportImagesSuccess(res.data));
    } catch (error) {
      dispatch(fetchReportImagesFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }))
    }
  }
}

function saveReportImageSuccess(payload) {
  return {
    type: SAVE_REPORT_IMAGE_SUCCESS,
    payload,
  };
}

function saveReportImageFailure(payload) {
  return {
    type: SAVE_REPORT_IMAGE_FAILURE,
    payload,
  };
}

export function saveReportImage(data) {
  return async (dispatch) => {
    try {
      const res = await axios.post('/api/v1/report-images/', data);
      if (res.status < 200 || res.status > 399) {
        throw Error(res.statusText);
      }
      dispatch(saveReportImageSuccess(res.data));
    } catch (error) {
      dispatch(saveReportImageFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }))
    }
  }
}

function saveReportChunckSuccess(payload) {
  return {
    type: SAVE_REPORT_CHUNK_SUCCESS,
    payload,
  };
}

function saveReportChunckFailure(payload) {
  return {
    type: SAVE_REPORT_CHUNK_FAILURE,
    payload,
  };
}

export function saveReportChunck(data) {
  return async (dispatch) => {
    try {
      const res = await axios.post('/api/v1/report-chunks/', data);
      if (res.status < 200 || res.status > 399) {
        throw Error(res.statusText);
      }
      dispatch(saveReportChunckSuccess(res.data));
    } catch (error) {
      dispatch(saveReportChunckFailure({
        error: {
          type: typeof (error),
          message: error.toString(),
        },
      }))
    }
  }
}

function saveReportTextAnalysisSuccess(payload) {
  return {
    type: SAVE_REPORT_TEXT_ANALYSIS_SUCCESS,
    payload,
  };
}

function saveReportTextAnalysisFailure(payload) {
  return {
    type: SAVE_REPORT_TEXT_ANALYSIS_FAILURE,
    payload,
  }
}

export function saveReportTextAnalysis(data) {
  return async (dispatch) => {
    try {
      const res = await axios.post('/api/v1/report-text-analysis/', data);
      if (res.status < 200 || res.status > 399) {
        throw Error(res.statusText);
      }
      dispatch(saveReportTextAnalysisSuccess(res.data));
    } catch (error) {
      dispatch(saveReportTextAnalysisFailure({
        error: {
          type: typeof (error),
          message: error.toString(),
        },
      }))
    }
  }
}

// default export for mocking convenience
export default {
  fetchReports,
  saveReport,
  fetchReport
}