import React, { useState } from "react";
import { Button, Modal } from "antd";
import MoodTableWidget from "../../../Overview/MoodTableWidget";
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";

function TwoWayTableModal({
  customProps,
  dateRange,
  infoModal,
  setInfoModal,
  nextStep,
  setMoodTableProps,
}) {
  const [modalWidth, setModalWidth] = useState("80%");
  const [modalHeight, setModalHeight] = useState("70vh");
  const [modalTop, setModalTop] = useState("100px");
  const [isMaxModal, setIsMaxModal] = useState(false);
  const maximise = () => {
    setIsMaxModal(true);
    setModalWidth("100%");
    setModalHeight("89vh");
    setModalTop("0px");
  };
  const minimise = () => {
    setIsMaxModal(false);
    setModalWidth("80%");
    setModalHeight("70vh");
    setModalTop("100px");
  };
  return (
    <Modal
      visible={infoModal}
      title={isMaxModal? <ShrinkOutlined onClick={minimise}/> : <ArrowsAltOutlined onClick={maximise}/>} 
      width={modalWidth}
      style={{top: modalTop,maxWidth:modalWidth}}
      bodyStyle={{
        minHeight: modalHeight,
        maxHeight: modalHeight,
        overflow: "auto",
        animationDuration: "0s !important",
      }}
      onOk={() => setInfoModal(false)}
      onCancel={() => setInfoModal(false)}
      transitionName="none"
      maskTransitionName="none"
      footer={[
        <Button key="1" onClick={() => setInfoModal(false)}>
          Close
        </Button>,
      ]}
    >
      {
        <MoodTableWidget
          {...customProps}
          {...dateRange}
          nextStep={nextStep}
          setMoodTableProps={setMoodTableProps}
          noWidget={true}
        />
      }
    </Modal>
  );
}

export default TwoWayTableModal;
