import { useMutation, gql } from "@apollo/client";

import { setupApi } from "../graphql/client";

export const FETCH_NETWORKMAP_LOADING = "FETCH_NETWORKMAP_LOADING";
export const FETCH_NETWORKMAP_SUCCESS = "FETCH_NETWORKMAP_SUCCESS";
export const FETCH_NETWORKMAP_FAILURE = "FETCH_NETWORKMAP_FAILURE";

const client = setupApi();

function fetchNetworkMapLoading() {
  return {
    type: FETCH_NETWORKMAP_LOADING,
  };
}

function fetchNetworkMapSuccess(payload) {
  return {
    type: FETCH_NETWORKMAP_SUCCESS,
    payload,
  };
}

function fetchNetworkMapFailure(payload) {
  return {
    type: FETCH_NETWORKMAP_FAILURE,
    payload,
  };
}

export function getNetworkMap() {
  return async (dispatch) => {
    try {
      dispatch(fetchNetworkMapLoading);

      const preppedQuery = gql`
        query {
          users {
            uid
            user_name
            client_id
            demographics {
              category
              taxonomy
              value
            }
            centrality_degree
            centrality_pagerank
            centrality_articlerank
            centrality_betweenness
            centrality_eigenvector
            centrality_closeness
        
              incoming_communicationsConnection {
                edges{
             first_communication_date
             last_communication_date
             number_of_comunications
             node{
               uid
               centrality_degree
               centrality_pagerank
               centrality_articlerank
               centrality_betweenness
               centrality_eigenvector
               centrality_closeness
               demographics{
               category
              taxonomy
              value
            }
             }
             }
            }
            user_communicationsConnection{
              edges {
                first_communication_date
                last_communication_date
                number_of_comunications
                node {
                  uid
                  client_id
                  user_name
                  demographics {
                    category
                    taxonomy
                    value
                  }
                  centrality_degree
                  centrality_pagerank
                  centrality_articlerank
                  centrality_betweenness
                  centrality_eigenvector
                  centrality_closeness
                }
              }
            }
          }
        }
      `;

      console.log("preppedQuery", preppedQuery);
      const query = {
        query: gql`
          ${preppedQuery}
        `,
      };
      const res = await client.query(query);
      return dispatch(fetchNetworkMapSuccess(res.data));
    } catch (error) {
      dispatch(
        fetchNetworkMapFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

export function getNetworkMap1(
  startDate,
  endDate,
  demographicFiltersQuery = {}
) {
  return async (dispatch) => {
    try {
      console.log(demographicFiltersQuery);
 
      dispatch(fetchNetworkMapLoading);

      const demographicFiltersQueryParam = Object.keys(demographicFiltersQuery).length > 0 ? '$demographicFilterWhere: [UserWhere!]' : '';
      const demographicFiltersQueryWhere = Object.keys(demographicFiltersQuery).length > 0 ? 'AND: $demographicFilterWhere' : '';

      const connectedDemographicFiltersQueryParam = Object.keys(demographicFiltersQuery).length > 0 ? '$cDemographicFilterWhere: [UserWhere!]' : '';
      const connectedDemographicFiltersQueryWhere = Object.keys(demographicFiltersQuery).length > 0 ? 'AND: $cDemographicFilterWhere' : '';

      const userConnectedDemographicFiltersQueryParam = Object.keys(demographicFiltersQuery).length > 0 ? '$ucDemographicFilterWhere: [UserWhere!]' : '';
      const userConnectedDemographicFiltersQueryWhere = Object.keys(demographicFiltersQuery).length > 0 ? 'AND: $ucDemographicFilterWhere' : '';

      const temp = `AND:{OR:{demographics_SOME:{category_IN:["org_unit"],taxonomy_IN:["staff_level"],value_IN:["Director"]}}}`;
      const preppedQuery = gql`
        query getNodes($startDate: DateTime!, $endDate: DateTime!,${demographicFiltersQueryParam},${connectedDemographicFiltersQueryParam},${userConnectedDemographicFiltersQueryParam})
        {
          users(where: {${demographicFiltersQueryWhere},
            user_communicationsConnection_SOME: { edge: {first_communication_date_GT:$startDate,first_communication_date_LT:$endDate }}})
            {
            uid
            user_name
            client_id
            demographics {
              category
              taxonomy
              value
            }
            centrality_degree
            centrality_pagerank
            centrality_articlerank
            centrality_betweenness
            centrality_eigenvector
            centrality_closeness
        
              incoming_communicationsConnection (where: {node:{${connectedDemographicFiltersQueryWhere}},edge:{first_communication_date_GT:$startDate,first_communication_date_LT:$endDate}}){
                edges{
             first_communication_date
             last_communication_date
             number_of_comunications
             node{
               uid
               centrality_degree
               centrality_pagerank
               centrality_articlerank
               centrality_betweenness
               centrality_eigenvector
               centrality_closeness
               demographics{
               category
              taxonomy
              value
            }
             }
             }
            }
            user_communicationsConnection(where: {node:{${userConnectedDemographicFiltersQueryWhere}},edge:{first_communication_date_GT:$startDate,first_communication_date_LT:$endDate}}){
              edges {
                first_communication_date
                last_communication_date
                number_of_comunications
                node {
                  uid
                  client_id
                  user_name
                  demographics {
                    category
                    taxonomy
                    value
                  }
                  centrality_degree
                  centrality_pagerank
                  centrality_articlerank
                  centrality_betweenness
                  centrality_eigenvector
                  centrality_closeness
                }
              }
            }
          }
        }
      `;

      console.log("preppedQuery", preppedQuery);
      const query = {
        query: gql`
          ${preppedQuery}
        `,
        variables: {
          startDate,
          endDate,
          demographicFilterWhere: Object.keys(demographicFiltersQuery).length > 0 ? demographicFiltersQuery : undefined,
          cDemographicFilterWhere: Object.keys(demographicFiltersQuery).length > 0 ? demographicFiltersQuery : undefined,
          ucDemographicFilterWhere: Object.keys(demographicFiltersQuery).length > 0 ? demographicFiltersQuery : undefined,
        },
        fetchPolicy: 'network-only',
      };
      const res = await client.query(query);
      //dispatch(fetchNetworkMapSuccess(res.data));
      return dispatch(fetchNetworkMapSuccess(res.data));
    } catch (error) {
      dispatch(
        fetchNetworkMapFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

export function getDemoNetworkMap(category,taxonomy) {
  return async (dispatch) => {
    try {
      dispatch(fetchNetworkMapLoading);
      const preppedQuery = 
        `query getNodes($category: String!, $taxonomy: String!)
        {
          userDemographics(where:{category:$category, taxonomy:$taxonomy})
            {
            client_id
            uid
            category
            taxonomy
            value
            centrality_degree
            centrality_pagerank
            centrality_articlerank
            centrality_betweenness
            centrality_eigenvector
            centrality_closeness
            outgoing_communicationsConnection 
            {
              edges 
              {
                number_of_comunications
                node 
                {
                  uid
                  client_id
                  category
                  taxonomy
                  value
                  centrality_degree
                  centrality_pagerank
                  centrality_articlerank
                  centrality_betweenness
                  centrality_eigenvector
                  centrality_closeness
                }
              }
            }
          }
        }
      `
      console.log("preppedQuery", preppedQuery);
      const query = {
        query: gql`${preppedQuery}`,
        variables: {
          category,
          taxonomy
        }
      };
      // const query = {
      //   query: gql`
      //     ${preppedQuery}
      //   `,
      // };
      const res = await client.query(query);
      return dispatch(fetchNetworkMapSuccess(res.data));
    } catch (error) {
      dispatch(
        fetchNetworkMapFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}
