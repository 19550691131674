import React from 'react';
import { Typography } from 'antd';
const { Title } = Typography;

function PageHeading({ children, style }) {
  return (
    <Title style={
      {
        ... {
          color: '#607d8b',
          marginTop: '20px',
          marginBottom: '20px',
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'"
        },
        ... ( style ? style : {} )
      }
    }>
      { children }
    </Title>
  )
};

export default PageHeading;