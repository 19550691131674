import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { fetchQuery } from '../../actions/cube';
import { fetchParticipation } from '../../actions/participation';
import MapChart from '../../charts/MapChart';
import ParticipantCountryBarChart from '../../charts/ParticipantCountryBarChart';
import Loading from '../../components/Loading';
import SimpleSelector from '../../components/Selectors/SimpleSelector';
import { participationsByLocationAndActive } from '../../queries';

import ExpansionChartPanel from './ExpansionChartPanel';

const optionURL = ['/segment', '/location'];

function Participation({ forumId }) {

  const { optionId } = useParams();
  const history = useHistory();
  const optionValue = optionURL.indexOf('/' + optionId);

  const [selectedConversationValue, setSelectedConversationValue] = useState(0);
  const [selectedConversationLabel, setSelectedConversationLabel] = useState('all conversations');
  const [selectedTypeValue, setSelectedTypeValue] = useState(optionValue !== -1 ? optionValue : 0);

  const conversationOptions = useSelector((state) => {
    const forum = state.user.info.forums.find((f) => f.forumId === forumId);
    return forum.conversations.map((conv) => ({
      id: conv.conversationId,
      title: conv.conversationTitle,
    }))
  });
  const locations = useSelector((state) => state.cube[participationsByLocationAndActive.type]);
  const data = useSelector((state) => state.participation.data);
  const user = useSelector((state) => state.user);
  const date_range = useSelector((state) => state.date_range);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchQuery(
      participationsByLocationAndActive,
      user.client_id,
      selectedConversationLabel,
      'surveyLocation'
    ));
    dispatch(fetchParticipation(user.client_id, selectedConversationLabel));
  }, [selectedConversationLabel, date_range])

  // TODO
  // By logging the location data, we can see that firstly it fetched 
  // 5 data instances from cube.js, which is normal. However, it will 
  // fetch another 252 irrelevant data appended after our initial 5.
  // console.log('locations.data', locations);

  const handleOptionChange = (optionType) => (event) => {
    const value = event.target.value;
    let label = 'all conversations';
    switch (optionType) {
      case 'conversation':
        if (value !== 0) {
          label = conversationOptions[value - 1].title;
        }
        setSelectedConversationValue(value);
        setSelectedConversationLabel(label);
        break;

      case 'type':
        setSelectedTypeValue(value);
        history.push(`/forums/${forumId}/participation` + optionURL[value]);
        break;

      default:
        console.error('Invalid option type:', optionType);
    }
  }

  return (
    <React.Fragment>
      <div className={classes.sectionName}>
        Participation breakdown
      </div>
      <div className={classes.sectionFilter}>
        <SimpleSelector
          conversationOptions={conversationOptions}
          selectedconversationValue={selectedConversationValue}
          selectedTypeValue={selectedTypeValue}
          handleOptionChange={handleOptionChange}
        />
      </div>
      {optionId !== 'location' ?
        <div className={classes.firstTabWrap}>
          <div className={classes.keySectionWrap}>
            <span>Key</span>
            <div className={classes.blueSection}></div>
            <span>Registered</span>
            <div className={classes.darkSection}></div>
            <span>Actively participated</span>
          </div>
          {data.map((d, i) =>
            <ExpansionChartPanel key={i} title={d.title} chartData={d.chartData} id={i} />
          )}
        </div>
        :
        <div className={classes.secondTabWrap}>
          {locations.loaded ?
            (
              locations.data.length ?
                <div>
                  <div className={classes.mapChartWrap}>
                    <MapChart mapCardData={locations.data} id="mapChart" />
                  </div>
                  <div className={classes.simpleBarChartWrap}>
                    <ParticipantCountryBarChart chartData={locations.data.slice(0, 5)} />
                  </div>
                </div>
                :
                <div className={classes.noData}>No data</div>
            )
            :
            <Loading />
          }
        </div>
      }
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  blueSection: {
    backgroundColor: '#dff6fa',
    height: '24px',
    marginLeft: '40px',
    marginRight: '8px',
    width: '24px',
  },
  darkSection: {
    backgroundColor: '#01b4d0',
    height: '24px',
    marginLeft: '40px',
    marginRight: '8px',
    width: '24px',
  },
  firstTabWrap: {
    width: '100%',
  },
  imageStyle: {
    backgroundImage: 'url("../../images/icons/hint.png")',
    backgroundSize: 'cover',
    display: 'inline-block',
    height: '50px',
    marginRight: '10px',
    width: '70px',
  },
  keySectionWrap: {
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    fontSize: '14px',
    justifyContent: 'center',
    marginBottom: '28px',
    marginTop: '42px',
  },
  mapChartWrap: {
    height: '660px',
    marginTop: '20px',
    width: '100%',
  },
  noData: {
    padding: '5%',
    textAlign: 'center',
    width: 'auto',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  secondTabWrap: {
    width: '100%',
  },
  sectionFilter: {
    border: 'solid 1px #efefef',
    marginBottom: '16px',
    minHeight: '100px',
    width: '100%',
    verticalAlign: 'middle',
  },
  sectionName: {
    color: '#516e88',
    fontSize: '34.5px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '41px',
    letterSpacing: '0.25px',
    lineHeight: '41px',
    marginBottom: '24px',
    marginTop: '40px',
  },
  simpleBarChartWrap: {
    border: 'solid 1px #efefef',
    height: 'auto',
    margin: '40px auto',
    width: '70%',
  },
  tipText: {
    color: '#ffffff',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.25,
    verticalAlign: 'middle',
  },
  tipWrap: {
    alignItems: 'center',
    backgroundColor: '#607d8b',
    borderRadius: '8px',
    display: 'flex',
    height: '68px',
    padding: '10px',
    width: '100%',
  },
}));

export default Participation;