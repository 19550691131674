import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import English from "../languages/english.json";
import Hindi from "../languages/hindi.json";
import Japanese from "../languages/japanese.json";

export const Context = React.createContext();

// const local = navigator.language;
const local = "english";
let lang;
if (local === "english") {
  lang = English;
} else if (local === "hindi") {
  lang = Hindi;
} else if (local === "japanese") {
  lang = Japanese;
} else {
  lang = English;
}

const LanguageWrapper = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);

  const selectLang = (lang) => {
    const newLocale = lang;
    setLocale(newLocale);
    if (newLocale === "hindi") {
      setMessages(Hindi);
    } else if (newLocale === "japanese") {
      setMessages(Japanese);
    } else {
      setMessages(English);
    }
  };
  return (
    <Context.Provider value={{ locale, selectLang }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};
export default LanguageWrapper;