import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';

import { fetchMoodTable } from '../actions/mood_table';
import DataCompareTable from '../charts/DataCompareTable';
import Loading from '../components/Loading';
import UnitSelector from '../components/Selectors/UnitSelector';

// TODO: fetch dimKeys according to data source
const dimKeys = [
  'CommentTopicsDenorm.surveyGender',
  'CommentTopicsDenorm.surveyPosition',
  'CommentTopicsDenorm.surveyAgeGroup',
  // 'CommentTopicsDenorm.surveyLocation',
  'CommentTopicsDenorm.sentiment',
  'CommentTopicsDenorm.surveyCandidature',
  // 'CommentTopicsDenorm.surveyFaculty',
  'CommentTopicsDenorm.surveyEmployment',
  // 'CommentTopicsDenorm.surveyDivision',
];

const dimTitles = [
  'Gender Identity',
  'Job Position',
  'Age Group',
  // 'Location',
  'Sentiment',
  'Candidature',
  // 'Faculty',
  'Employment',
  // 'Division',
];

function MoodTableView({ match, ... props }) {

  const topic = (match) ? decodeURIComponent(match.params.topic) : null;
  const forumUuid = (match) ? match.params.forumId : props.forumId;
  const backLink = forumUuid ? `/forums/${forumUuid}/explore` : '/explore';

  // temporarily change the default dim to `Sentiment` and `Candidature`
  const [dim1, setDim1] = useState(dimKeys[2]);
  const [dim2, setDim2] = useState(dimKeys[3]);

  const { data, loaded } = useSelector((state) => state.moodTable);
  const date_range = useSelector((state) => state.date_range);
  const { tableData = [], dim1List = [], dim2List = [] } = data;
  const xs = {}
  const ys = {}
  for (let obj of tableData) {
    if (!xs[obj.x]) {
      xs[obj.x] = 0;
    }
    if (!ys[obj.y]) {
      ys[obj.y] = 0;
    }
    xs[obj.x] += obj.value;
    ys[obj.y] += obj.value;
  }

  let tdata = [...tableData];
  for (let [k, v] of Object.entries(xs)) {
    if (v === 0) {
      tdata = tdata.filter((obj) => obj.x !== k);
    }
  }
  for (let [k, v] of Object.entries(ys)) {
    if (v === 0) {
      tdata = tdata.filter((obj) => obj.y !== k);
    }
  }

  const dispatch = useDispatch();
  const classes = useStyles();

  const height = Math.max((dim2List.length * 60), 250) + 'px';

  useEffect(() => {
    dispatch(fetchMoodTable(dim1, dim2, topic, forumUuid, date_range.data));
  }, [dim1, dim2, topic, forumUuid, date_range]);

  const handleDimChange = (dimNumber) => (ev) => {
    if (dimNumber === 1) {
      setDim1(dimKeys[ev.target.value]);
    } else {
      setDim2(dimKeys[ev.target.value]);
    }
  };

  return (
    <React.Fragment>
      <Link className={classes.topSubNav} to={backLink}>
        <KeyboardArrowLeftOutlinedIcon />
        <div className={classes.link}> Explore topics </div>
      </Link>
      <div className={classes.subSectionName}>
        Comments about “{topic}”
      </div>
      <div className={classes.sectionFilter}>
        <UnitSelector
          options={dimTitles}
          dim1Index={dimKeys.indexOf(dim1)}
          dim2Index={dimKeys.indexOf(dim2)}
          handleDimChange={handleDimChange}
        />
      </div>
      <div className={classes.tableWrap} style={{ height }}>
        {loaded ?
          <DataCompareTable
            data={tdata}
            forumUuid={forumUuid}
            topic={topic}
            xLength={dim1List.length}
          />
          :
          <Loading />
        }
      </div>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  link: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  sectionFilter: {
    border: 'solid 1px #efefef',
    height: '134px',
    marginBottom: '16px',
    width: '100%',
    verticalAlign: 'middle',
  },
  subSectionName: {
    color: '#516e88',
    fontSize: '24.4px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    marginBottom: '20px',
    marginTop: '22px',
  },
  tableWrap: {
    height: '340px',
  },
  topSubNav: {
    color: 'rgba(0,0,0,0.38)',
    display: 'flex',
    height: '24px',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    lineHeight: '24px',
    marginTop: '10px',
    width: '100%',
  },
}));

export default MoodTableView;