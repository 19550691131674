import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { computeBetweennessCentrality, computeClosenessCentrality, computeEigenvectorCentrality, computeDegreeCentrality, computePageRank } from 'ngraph.centrality';
// import { createGraph } from "ngraph.centrality";
import {
  Form,
  Select,
  DatePicker,
  Space,
  Button,
  Drawer,
  Switch,
  Collapse,
  Radio,
  Row,
  Col,
  Divider
} from "antd";
import { useIntl } from "react-intl";
import { DemographicsContext } from "../../context/demographics-context";
import moment from "moment";
import { sortDemographics } from "../../utils/SortDemographics";
var centrality = require('ngraph.centrality');
var g = require('ngraph.graph')();



const { Option } = Select;
const { Panel } = Collapse;

function DemographicSwitchBlock({
  category,
  taxonomy,
  values,
  onChange,
  onResetFilters,
  missing,
}) {
  const [toggledDemographics, setToggledDemographics] = useState({});
  const [allSelected, setAllSelected] = useState(true);
  //const [ sortedValues, setSortedValues ] = useState([]);
  const [deafultDemographics, setDeafultDemographics] = useState({});
 
  const intl = useIntl();
  const filterByNonePlaceholder = intl.formatMessage({
    id: "app.explorePage.filterByNone",
    defaultMessage: "None",
  });
  const filterByAllPlaceholder = intl.formatMessage({
    id: "app.explorePage.filterByAll",
    defaultMessage: "All",
  });
  useEffect(() => {
    let _toggledDemographics = { ...toggledDemographics };
    values.forEach((value) => {
      const key = [category, taxonomy, value].join("/");
      if (_toggledDemographics[key] === undefined) {
        _toggledDemographics[key] = true;
      }
    });
    setToggledDemographics(_toggledDemographics);
    setDeafultDemographics(_toggledDemographics);
  }, [values]);

  useEffect(() => {
    if (onChange) {
      onChange(toggledDemographics);
    }
  }, [toggledDemographics]);

  // useEffect(() => {
  //   if (onResetFilters) {
  //     setToggledDemographics(deafultDemographics);
  //      setIsReset(false);
  //   }
  // }, [ onResetFilters ]);

  const toggleDemographic = (category, taxonomy, value) => {
    let newFilter = {};
    const key = [category, taxonomy, value].join("/");
    newFilter[key] = !toggledDemographics[key] ? true : false;
    setToggledDemographics({
      ...toggledDemographics,
      ...newFilter,
    });
  };

  const toggleSelected = () => {
    setAllSelected(!allSelected);

    let _toggledDemographics = { ...toggledDemographics };
    Object.keys(_toggledDemographics).forEach((key) => {
      _toggledDemographics[key] = !allSelected;
    });

    setToggledDemographics(_toggledDemographics);

    return true;
  };

  return (
    <section style={{ marginLeft: "-10px", marginTop: "-15px" }}>
      <Collapse ghost>
        <Panel
          extra={
            <Button
              type="link"
              size="small"
              onClick={(e) => toggleSelected() && e.stopPropagation()}
            >
              {allSelected ? filterByNonePlaceholder : filterByAllPlaceholder}
            </Button>
          }
          header={
            <>
              {" "}
              {category}: {taxonomy}
            </>
          }
          key={category + "_" + taxonomy}
        >
          <section style={{ marginTop: "-15px" }}>
            {values.map((value, index) => (
              <div
                style={{
                  marginLeft: 10,
                  marginBottom: 5,
                  color:
                    missing.indexOf([category, taxonomy, value].join("/")) > -1
                      ? "#e5e3e3"
                      : "inherit",
                }}
                key={index}
              >
                {/* <div style={ { marginLeft: 10, marginBottom: 5, color: '#e5e3e3' } } key={ index }> */}
                <Space>
                  <Switch
                    checked={
                      toggledDemographics[[category, taxonomy, value].join("/")]
                    }
                    onChange={() =>
                      toggleDemographic(category, taxonomy, value)
                    }
                  />
                  <small>{value}</small>
                </Space>
              </div>
            ))}
          </section>
        </Panel>
      </Collapse>
    </section>
  );
}

function DemographicBlocks({
  data,
  onChange,
  onNoneChanged,
  filteredDemographicKeys,
  onResetFilters,
  demographicBlocks,
  setDemographicBlocks,
}) {
  const [demographicIncludeNone, setDemographicIncludeNone] = useState(true);
  const [foundDemographicKeys, setFoundDemographicKeys] = useState({});
  const [missingDemographicKeys, setMissingDemographicKeys] = useState({});
  const [toggledDemographics, setToggledDemographics] = useState({});
  const { taxonomyMap } = useContext(DemographicsContext);

  useEffect(() => {
    let _foundDemographicKeys = {};
    data.nodes.forEach((node) => {
      if (node && node.demographics) {
        node.demographics.forEach((demographic) => {
          const key = [
            demographic.category,
            demographic.taxonomy,
            demographic.value,
          ].join("/");
          _foundDemographicKeys[key] = true;
        });
      }
    });
    setFoundDemographicKeys(_foundDemographicKeys);
    console.log(foundDemographicKeys);
  }, [data]);

  useEffect(() => {
    console.log(Object.keys(foundDemographicKeys));
    const missingKeys = _.difference(
      Object.keys(foundDemographicKeys),
      Object.keys(filteredDemographicKeys)
    );
    console.log("missing", missingKeys);

    setMissingDemographicKeys(missingKeys);
  }, [demographicBlocks, filteredDemographicKeys]);

  useEffect(() => {
    let blocks = [];
    _.uniq(Object.values(taxonomyMap).map((node) => node[0].category)).map(
      (category, index) =>
        _.uniq(
          _.flatten(Object.values(taxonomyMap))
            .filter((node) => node.category == category)
            .map((node) => node.taxonomy)
        ).map((taxonomy, index) => {
          let valuesArray = _.uniq(
            taxonomyMap[taxonomy]
              .filter((node) => node.taxonomy == taxonomy)
              .map((node) => node.value)
          )
            .map((value, index) => {
              const key = [category, taxonomy, value].join("/");
              return !foundDemographicKeys[key] ? null : value;
            })
            .filter((el) => el != null);
          let values = [];
          if (valuesArray.length != 0) {
            values = [...["Include Missing"], ...sortDemographics(valuesArray)];
          } else {
            values = valuesArray;
          }
          blocks.push({ category, taxonomy, values });
        })
    );
    setDemographicBlocks(blocks);
  }, [foundDemographicKeys]);

  // useEffect(() => {
  //   const missingKeys = _.difference(Object.keys(foundDemographicKeys), Object.keys(filteredDemographicKeys));
  //   console.log('missing', missingKeys);

  //   setMissingDemographicKeys(missingKeys);
  // }, [ demographicBlocks, filteredDemographicKeys ]);

  useEffect(() => {
    if (onChange) {
      onChange(toggledDemographics);
    }
  }, [toggledDemographics]);

  useEffect(() => {
    if (onNoneChanged) {
      onNoneChanged(demographicIncludeNone);
    }
  }, [demographicIncludeNone]);

  return (
    <>
      {
        <>
          <section style={{ marginLeft: "-10px", marginTop: "-5px" }}>
            <Collapse defaultActiveKey={["missing"]} ghost>
              <Panel header="Missing demographic data" key={"missing"}>
                <section style={{ marginLeft: 10, marginTop: "-15px" }}>
                  <Space>
                    <Switch
                      checked={demographicIncludeNone}
                      onChange={() =>
                        setDemographicIncludeNone(!demographicIncludeNone)
                      }
                    />
                    <small>Include</small>
                  </Space>
                </section>
              </Panel>
            </Collapse>
          </section>

          <>
            {demographicBlocks.map(
              ({ category, taxonomy, values }, index) =>
                values.length > 0 && (
                  <DemographicSwitchBlock
                    key={index}
                    missing={missingDemographicKeys}
                    values={values}
                    category={category}
                    taxonomy={taxonomy}
                    onChange={(blockDemographics) =>
                      setToggledDemographics({
                        ...toggledDemographics,
                        ...blockDemographics,
                      })
                    }
                  />
                )
            )}
          </>
        </>
      }
    </>
  );
}

export default function Filters({ open, onClose, graph, onChange,setIsGrouped }) {
  const [form] = Form.useForm();
  const intl = useIntl();
  const startDatePlaceholder = intl.formatMessage({
    id: "app.strategyMap.config.startDate",
    defaultMessage: "Start Date",
  });
  const endDatePlaceholder = intl.formatMessage({
    id: "app.strategyMap.config.endDate",
    defaultMessage: "End Date",
  });
  const [isReset, setIsReset] = useState(false);
  const [demographicFilters, setDemographicFilters] = useState({});
  const [demographicIncludeNone, setDemographicIncludeNone] = useState(true);
  const [filteredDemographicKeys, setFilteredDemographicKeys] = useState([]);
  const [demographicBlocks, setDemographicBlocks] = useState([]);
  const [centralityGroup,setCentralityGroup]= useState("cDegree");
  const defaultValues = {
    startDate: "2020-06-01",
    endDate: moment().format("YYYY-MM-DD"),
    autoEndDate: "false",
    groupBy: "none",
    aggregate: false
  };
  const [tempForm, setTempForm] = useState({});

  useEffect(() => {
    setTempForm({
      ...defaultValues,
    });
  }, []);

  useEffect(() => {
    console.log("applyFilter();");
    if (graph && Object.keys(graph).length > 0) applyFilter();
  }, [graph]);

  const applyFilter = () => {
    
    form.validateFields().then(onFinish).catch(onFinishFailed);

    // Create a new graph to calc centrality measures

    if (!isReset) {
      console.log("applyFilter->called");
      let missingKeys = [];
      missingKeys = Object.keys(demographicFilters).filter((rule) => {
        let retain = false;
        const [category, taxonomy, value] = rule.split("/");
        if (value === "Include Missing" && !demographicFilters[rule])
          retain = true;
        return retain;
      });
      let _processedData;

      if (missingKeys.length > 0) {
        _processedData = graph.nodes.filter((node) => {
          return missingKeys.every((rule) => {
            let keep = false;
            const [category, taxonomy, value] = rule.split("/");
            if(node.demographics)
            {
            node.demographics.forEach((demographic) => {
              if (
                demographic.category === category &&
                demographic.taxonomy === taxonomy
              ) {
                keep = true;
                console.log(
                  "Removing from list due to demographic filter rule:",
                  rule,
                  node
                );
              }
              
            });
          }
            return keep;
          });
        });
      } else {
        _processedData = graph.nodes;
      }

      //Applying rest of the filters on top of include missing data for demographics
      let _filteredData = _processedData.filter((node) => {
        let keep = true;

        Object.keys(demographicFilters).forEach((rule) => {
          if (keep && node && node.demographics && !demographicFilters[rule]) {
            const [category, taxonomy, ...valueArr] = rule.split("/");
            //In cisco there is one case of Opportunity/Competitors/HPE/Aruba, so added it as an array to avoid any logical conflicts with such cases.
            const value = valueArr.join("/");
            //condition is triggered if any value switch is disabled
            if (value !== "Include Missing") {
              node.demographics.forEach((demographic) => {
                if (
                  demographic.category == category &&
                  demographic.taxonomy == taxonomy &&
                  demographic.value == value
                ) {
                  keep = false;
                  console.log(
                    "Removing from list due to demographic filter rule:",
                    rule,
                    node
                  );
                }
              });
            }
          }
        });
        if (
          keep &&
          node &&
          node.demographics &&
          node.demographics.length == 0 &&
          !demographicIncludeNone
        ) {
          keep = false;
        }
        return keep;
      });

      let _filteredDataFoundDemographicKeys = {};
      _filteredData.forEach((node) => {
        if (node && node.demographics) {
          node.demographics.forEach((demographic) => {
            const key = [
              demographic.category,
              demographic.taxonomy,
              demographic.value,
            ].join("/");
            _filteredDataFoundDemographicKeys[key] = true;
          });
        }
      });
      setFilteredDemographicKeys(_filteredDataFoundDemographicKeys);
      
      //If user has selected the group, allocate group value to the node.group.
      if (tempForm.groupBy !== "none") {
        setIsGrouped(true); // set isgroup to true which will calc the centroid of each group in graph.js
        _filteredData.forEach((node) => {
          if (node && node.demographics) {
            node.demographics.forEach((demographic) => {
              const key = [demographic.category, demographic.taxonomy].join(
                "/"
              );
              if (key === tempForm.groupBy) {
                node.group = demographic.value;
              }
            });
          }
        });
        if(tempForm.aggregate)
        {
          let result = {};
          _filteredData.forEach(element => {
            if (!result[element.group]) {
              result[element.group] = { id: element.group, uid: element.id, cDegree: element.cDegree, color: element.color, name:element.name, group:element.group };
            } else {
              result[element.group]["cDegree"] += element.cDegree;
            }
          });
           console.log(Object.values(result)); 
           _filteredData = Object.values(result);
        }
      }
      let filteredLinks = [];
      graph.links.forEach((link) => {
        if(tempForm.aggregate){
          if(link.source.id && link.target.id){
          let isExist = 
            // _filteredData.some((node) => link.source.group === node.group) &&
            // _filteredData.some((node) => link.target.group === node.group);
             _filteredData.find((node) => link.source.demographics.some((d) => d.value=== node.group)) &&
             _filteredData.find((node) => link.target.demographics.some((d) => d.value=== node.group));
           
          if (isExist ) {
            let s = _filteredData.find((node) => link.source.demographics.some((d) => d.value=== node.group))
            let t = _filteredData.find((node) => link.target.demographics.some((d) => d.value=== node.group));
            let l = {source: s.group,
            target: t.group,
            }
            const found = filteredLinks.find(el => el.source === link.source.group && el.target === link.target.group);
            if(!found)
            filteredLinks.push(l);
          }
        }
        }
        else{
        //d3 will update the link properties
        if (link.source.id || link.target.id) {
          //This part will run second time onwards
          let isExist =
            _filteredData.some((node) => link.source.id === node.id) &&
            _filteredData.some((node) => link.target.id === node.id);
          if (isExist) {
            let l = {
              index:link.index,
              source: link.source.id,
              target: link.target.id,
              }
            filteredLinks.push(l);
          }
        } else {
          //This condition will met first time only
          let isExist =
            _filteredData.some((node) => link.source === node.id) &&
            _filteredData.some((node) => link.target === node.id);
          if (isExist) {
            filteredLinks.push(link);
          }
        }
      }
      });
      const filteredOutput = filteredLinks.filter(entry => entry.source !== entry.target);

      const uniqueEntries = [];
      filteredOutput.forEach(entry => {
        const match = uniqueEntries.find(e => e.source === entry.target && e.target === entry.source);
        if (!match) {
          uniqueEntries.push(entry);
        }
      });
        // // Add nodes to the graph for calc centrality measures
        // _filteredData.nodes.forEach(node => graph.addNode(node));

      // Add edges to the graph for calc centrality measures
      let centralityLinks= uniqueEntries.forEach((link) => g.addLink(link.source, link.target));
      var centralityValues;
      if(centralityGroup === "cDegree")
      {      // Calculate degree centrality
        centralityValues = centrality.degree(g);
        console.log(centralityValues);
      }
      else if(centralityGroup === "cArticlerank")
      {      // Calculate degree centrality
        centralityValues = centrality.degree(g);
        console.log(centralityValues);
      }
      else if(centralityGroup === "cBetweenness")
      {      // Calculate betweenness centrality
        centralityValues = centrality.betweenness(g);
        console.log(centralityValues);
      }
      else if(centralityGroup === "cCloseness")
      {      // Calculate closeness centrality
        centralityValues = centrality.closeness(g);
        console.log(centralityValues);
      }
      else if(centralityGroup === "cEigenvector")
      {      // Calculate eigenvector centrality
        centralityValues = centrality.pagerank(g, { algorithm: 'eigenvector' });
        console.log(centralityValues);
      }
      else if(centralityGroup === "cPagerank")
      {      // Calculate pagerank centrality
        centralityValues = centrality.pagerank(g);
        console.log(centralityValues);
      }
 
      _filteredData = _filteredData.map(item => {
        const centralityValue = centralityValues[item.id] ? centralityValues[item.id]: 0;
        return centralityValue !== undefined ? { ...item, centralityValue } : item;
      });

      let temp = {
        nodes: _filteredData,
        links: uniqueEntries,
      };
      onChange(temp, centralityGroup);
    }
  };

  const onFormChanged = (changedValues) => {
    setTempForm((current) => {
      return {
        ...defaultValues,
        ...current,
        ...changedValues,
      };
    });
  };

  const updateRecord = (values) => {
    const validKeys = Object.keys(values).filter((key) => {
      return values[key] !== undefined;
    });
    const validValues = validKeys.map((key) => {
      return values[key];
    });
    let validatedObject = _.zipObject(validKeys, validValues);
    if (typeof validatedObject.startDate !== "string") {
      validatedObject.startDate =
        validatedObject.startDate.format("YYYY-MM-DD");
    }
    if (
      typeof validatedObject.endDate !== "string" &&
      values.autoEndDate !== "true"
    ) {
      validatedObject.endDate = validatedObject.endDate.format("YYYY-MM-DD");
    }
    // if (Object.keys(chartTempFilterOptions).length > 0) {
    //   validatedObject.table_dim1 = chartTempFilterOptions.table_dim1;
    //   validatedObject.table_dim2 = chartTempFilterOptions.table_dim2;
    // }
    // if (values.autoEndDate === "true") validatedObject.endDate = null;
    setTempForm((current) => {
      current = {
        ...defaultValues,
        ...current,
        ...validatedObject,
      };
      // addConfig(current);
      return current;
    });
  };
  const onFinishFailed = () => {};
  const onFinish = (values) => {
    // drawerOnClose();
    updateRecord(values);
  };

  const onCentralityChange = (e) => {
    console.log("centralityGroup->",centralityGroup);
    setCentralityGroup(e.target.value);
  };
  
  return (
    <>
      <Drawer title="Filters" placement="right" onClose={onClose} open={open}>
        <Form
          form={form}
          initialValues={{
            layout: "horizontal",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChanged}
        >
          <Form.Item
            label={startDatePlaceholder}
            name="startDate"
            initialValue={moment(tempForm.startDate, "YYYY-MM-DD")}
          >
            <DatePicker
              format={"YYYY-MM-DD"}
              popupStyle={{
                zIndex: 3000,
              }}
            />
          </Form.Item>
          <Form.Item label={endDatePlaceholder}>
            <Space>
              <Form.Item
                name="autoEndDate"
                noStyle
                initialValue={tempForm.autoEndDate}
                rules={[
                  {
                    required: true,
                    message: "Please choose an option",
                  },
                ]}
              >
                <Select
                  style={{ width: 145 }}
                  dropdownStyle={{
                    zIndex: 3000,
                  }}
                >
                  <Option value="true">Automatic Today</Option>
                  <Option value="false">Manual Date</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={endDatePlaceholder}
                // name= "endDate"
                noStyle
                initialValue={moment(tempForm.endDate, "YYYY-MM-DD")}
                rules={[
                  {
                    required: true,
                    message: "Please choose an option",
                  },
                ]}
              >
                {tempForm.autoEndDate === "false" && (
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    popupStyle={{
                      zIndex: 3000,
                    }}
                  />
                )}
              </Form.Item>
            </Space>
          </Form.Item>
          <Space>
            <Form.Item label="Group By" name="groupBy">
              <Select
                dropdownStyle={{
                  zIndex: 3000,
                }}
                style={{ width: 200 }}
              >
                {demographicBlocks.map((demographic) => (
                  <>
                    <Option
                      value={[demographic.category, demographic.taxonomy].join(
                        "/"
                      )}
                    >
                      {[demographic.category, demographic.taxonomy].join("/")}
                    </Option>
                  </>
                ))}
              </Select>
            </Form.Item>
          </Space>
          <Space>
          <Form.Item label="Aggregate group" name="aggregate">
              <Switch checkedChildren="true" unCheckedChildren="false"/>
          </Form.Item>
          </Space>
        </Form>

        <DemographicBlocks
          data={graph}
          onResetFilters={isReset}
          onChange={(newDemographicFilters) =>
            setDemographicFilters(newDemographicFilters)
          }
          onNoneChanged={(value) => setDemographicIncludeNone(value)}
          filteredDemographicKeys={filteredDemographicKeys}
          demographicBlocks={demographicBlocks}
          setDemographicBlocks={setDemographicBlocks}
        />
        <Divider orientation="left">Centrality measures</Divider>
        <Radio.Group onChange={onCentralityChange} defaultValue={centralityGroup}>
        <Row>
          <Col style={{ margin: "5px 5px 5px" }}>
          <Radio.Button style={{ width: 150, textAlign: "center" }} value="cArticlerank">Article Rank</Radio.Button>
          </Col>
          <Col style={{ margin: "5px 5px 5px" }}>
          <Radio.Button style={{ width: 150, textAlign: "center" }} value="cBetweenness">Betweenness</Radio.Button>
          </Col>
        </Row>
        <Row>
        <Col style={{ margin: "5px 5px 5px" }}>
          <Radio.Button style={{ width: 150, textAlign: "center" }} value="cCloseness" >Closeness</Radio.Button>
          </Col>
          <Col style={{ margin: "5px 5px 5px" }}>
          <Radio.Button style={{ width: 150, textAlign: "center" }} value="cEigenvector">Eigen Vector</Radio.Button>
          </Col>
        </Row>
        <Row>
        <Col style={{ margin: "5px 5px 5px" }}>
          <Radio.Button style={{ width: 150, textAlign: "center" }} value="cDegree">Degree</Radio.Button>
          </Col>
          <Col style={{ margin: "5px 5px 5px" }}>
          <Radio.Button style={{ width: 150 , textAlign: "center" }} value="cPagerank">Page Rank</Radio.Button>
          </Col>
        </Row>
        </Radio.Group>
        <div style={{ textAlign: "right", marginTop:"5px"}}>
          <Button onClick={(e) => applyFilter()}>Apply Filters</Button>
        </div>
      </Drawer>
    </>
  );
}

