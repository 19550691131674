import React, { useState, useEffect } from 'react';
import {
  BookmarkBorderOutlined as BookmarkBorderOutlinedIcon,
  Bookmark as BookmarkIcon,
} from '@material-ui/icons';

function BookMark({isSelectAll, relatedChunk, selectedChunks, setSelectedChunks}){

  const [marked, setMarked] = useState(false);

  useEffect(()=> {
    if(isSelectAll){
      setMarked(true);
    }else{
      setMarked(false);
    }
   
  }, [isSelectAll]);

  useEffect(()=> {
    if(selectedChunks.length===0){
        setMarked(false);
    }
  },[selectedChunks])

  
  const handleClick = () => {
    // temporary check both chunk_id and chunk_uuid because we're using two source of API calls
    const isSelected = selectedChunks.length >0 && selectedChunks.map(chunk => chunk.chunk_id || chunk.chunk_uuid ).includes(relatedChunk.chunk_id || relatedChunk.chunk_uuid );
 
    if(marked){
      
      if(isSelected){
        const curChunks = [...selectedChunks];   
        setSelectedChunks(curChunks.filter(chunk => chunk.chunk_id !== relatedChunk.chunk_id || chunk.chunk_uuid !== relatedChunk.chunk_uuid));
      }
      
    }else{
      
      if(!isSelected){
        setSelectedChunks([...selectedChunks, relatedChunk]);
      }
      
    }   
    setMarked(!marked);
    
  };
  // I don't know how to set the color of the BookmarkIcon
  return (
    <div onClick={handleClick} style={{color: '#516e88'}}>
      {marked?     
      <BookmarkIcon fontSize="small" color = 'inherit'/>:
      <BookmarkBorderOutlinedIcon fontSize="small"/>
      }
    </div>
  )
}

export default BookMark;