import {
  FETCH_EMOJI_LABELS_LOADING,
  FETCH_EMOJI_LABELS_SUCCESS,
  FETCH_EMOJI_LABELS_FAILURE,
} from "../actions/emoji_labels";
import { transformEmojiLabelsResultSet } from '../transformers/emoji_labels';

const initialState = {
  data: [],
  error: null,
  loaded: false,
  loading: false,
};

export function emojiLabels(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMOJI_LABELS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_EMOJI_LABELS_SUCCESS:
      return {
        data: transformEmojiLabelsResultSet(action.payload),
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_EMOJI_LABELS_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };
  
    default:
      return state;
  }
}