import { gql } from '@apollo/client';

import { setupApi } from '../graphql/client';

export const FETCH_TOPIC_CLUSTERS_LOADING = 'FETCH_TOPIC_CLUSTERS_LOADING';
export const FETCH_TOPIC_CLUSTERS_SUCCESS = 'FETCH_TOPIC_CLUSTERS_SUCCESS';
export const FETCH_TOPIC_CLUSTERS_FAILURE = 'FETCH_TOPIC_CLUSTERS_FAILURE';

const client = setupApi();
function fetchTopicClustersLoading() {
  return {
    type: FETCH_TOPIC_CLUSTERS_LOADING,
  };
}

function fetchTopicClustersSuccess(payload) {
  return {
    type: FETCH_TOPIC_CLUSTERS_SUCCESS,
    payload,
  };
}

function fetchTopicClustersFailure(payload) {
  return {
    type: FETCH_TOPIC_CLUSTERS_FAILURE,
    payload,
  }
}

export function fetchTopicClusters(client_id, forum_id, forums) {
  return async (dispatch) => {
    try {
      const conversations = forums.filter(forum => forum.forumId === forum_id)[0].conversations.map(conv => conv.conversationTitle)

      dispatch(fetchTopicClustersLoading());
      const res = await client.query({
        query: gql`
          query GetQuestions($client_id: String!, $forum_id: String, $text: [String]) {
            questions(client_id: $client_id, forum_id: $forum_id, text: $text){
              text
              uid
              cluster_heads{
                uid
                text
                cluster_elements{
                  uid
                  text
                }
              }
            }

          }
        `,

        variables: {
          client_id,
          forum_id,
          text: conversations,
        }
      });


      return dispatch(fetchTopicClustersSuccess(res.data.questions));
    } catch (error) {
      dispatch(fetchTopicClustersFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }));
    }
  };
}
