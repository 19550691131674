import React from "react";
import { Button, Row, Col, Card, Spin } from "antd";
import { useIntl } from "react-intl";
import ActiveSetInfo from "./ActiveSetInfo";
import "./magicWindow.css";
export default function Summarize({ ...props }) {
  const intl = useIntl();
  const summarizePlaceholder = intl.formatMessage({
    id: "app.explorePage.analyzeChunks.summarize",
    defaultMessage: "Summarize",
  });
  const savePlaceholder = intl.formatMessage({
    id: "app.save",
    defaultMessage: "Save",
  });

  return (
    <>
      <Spin spinning={props.summaryLoading} tip="Loading...">
        <Row>
          <Col span="14">
            <ActiveSetInfo
              averageSentiment={props.averageSentiment}
              totalChunks={props.totalChunks}
              title={props.title}
              sorter={props.sorter}
            />
          </Col>
          <Col span="10">
            <Row style={{ marginTop: "100px" }}>
              <Col>
                {(props.abstractText === "" && props.keypointsText === "") && (
                  <Button
                    type="primary"
                    style={{ borderRadius: "8px", minWidth: "100px" }}
                    onClick={() => props.showSummary()}
                  >
                    {summarizePlaceholder}
                  </Button>
                )}
                {(props.abstractText !== "" && props.keypointsText !== "") && (
                  <Button
                    type="primary"
                    style={{ borderRadius: "8px", minWidth: "100px" }}
                    disabled
                  >
                    {summarizePlaceholder}
                  </Button>
                )}
              </Col>
              <Col>
                <Button
                  type="primary"
                  style={{
                    marginLeft: "20px",
                    borderRadius: "8px",
                    minWidth: "100px",
                  }}
                  onClick={() => props.saveAnalysis()}
                >
                  {savePlaceholder}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {props.abstractText !== "" && props.keypointsText !== "" && (
            <>
              <Card
                title="Abstract Summary"
                style={{ marginTop: "20px", width: "100%" }}
              >
                {/* Splitting in case if api returns result in keypoints*/}
                {props.abstractText.split("\n").map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </Card>
              <Card
                title="Keypoints Summary"
                style={{ marginTop: "20px", width: "100%" }}
              >
                {props.keypointsText.split("\n").map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </Card>
            </>
          )}
        </Row>
      </Spin>
    </>
  );
}
