import { cubeapi } from './cube';
import {
  participationsByAgeAndActive,
  participationsByGenderAndActive,
  participationsByPositionAndActive
} from '../queries';

export const FETCH_PARTICIPATION_LOADING = 'FETCH_PARTICIPATION_LOADING';
export const FETCH_PARTICIPATION_SUCCESS = 'FETCH_PARTICIPATION_SUCCESS';
export const FETCH_PARTICIPATION_FAILURE = 'FETCH_PARTICIPATION_FAILURE';

function fetchParticipationLoading() {
  return {
    type: FETCH_PARTICIPATION_LOADING,
  };
}

function fetchParticipationSuccess(payload) {
  return {
    type: FETCH_PARTICIPATION_SUCCESS,
    payload,
  };
}

function fetchParticipationFailure(payload) {
  return {
    type: FETCH_PARTICIPATION_FAILURE,
    payload,
  };
}

export function fetchParticipation(clientUuid, convUuid) {
  return async (dispatch) => {
    try {
      dispatch(fetchParticipationLoading());
      const gender_req = cubeapi.load(participationsByGenderAndActive.query(clientUuid, convUuid, 'surveyCandidature'));
      const age_req = cubeapi.load(participationsByAgeAndActive.query(clientUuid, convUuid, 'surveyFaculty'));
      const position_req = cubeapi.load(participationsByPositionAndActive.query(clientUuid, convUuid, 'surveyDepartment'));
      const [gender_rs, age_rs, position_rs] = await Promise.all([gender_req, age_req, position_req]);
      if (gender_rs.status < 200 || gender_rs.status > 399) {
        throw Error('Error fetching gender: ' + gender_rs.statusText);
      }
      if (age_rs.status < 200 || age_rs.status > 399) {
        throw Error('Error fetching age: ' + age_rs.statusText);
      }
      if (position_rs.status < 200 || position_rs.status > 399) {
        throw Error('Error fetching position: ' + position_rs.statusText);
      }
      
      dispatch(fetchParticipationSuccess({
        gender: gender_rs.loadResponse.data,
        age: age_rs.loadResponse.data,
        position: position_rs.loadResponse.data,
      }));
    } catch (error) {
      dispatch(fetchParticipationFailure({
        error: {
          type: typeof(error),
          message: error.toString(),
        },
      }));
    }
  }
}
