import React from 'react';
import { Modal } from 'antd';

function FullScreenModal({ children, ... props }) {
  return (
    <Modal
      className="ant-full-screen-modal"
      width={ "auto" }
      { ... props }
    >
      { children }
      <style>{`
    .ant-modal-footer button {
      width: 140px;
    }
  `}</style>
  </Modal>
  )
}

export default FullScreenModal;