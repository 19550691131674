import React, { createContext, useState } from 'react';

export const ReportImgContext = createContext();

// This context is used to hide `Explore Data` when saving image to report

const ReportImgContextProvider = ({ children }) => {

  const [isShown, setIsShown] = useState(true);

  return (
    <ReportImgContext.Provider value={{ isShown, setIsShown }}>
      {children}
    </ReportImgContext.Provider>
  );
}

export default ReportImgContextProvider;