import React, { useEffect, useState } from "react";
import { Tabs, Card, Table, List, Button, Row, Col, Tag,Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl"; 
import {
  deleteAnalyzedChunks,
} from "../../actions/text_analysis";
import { openNotification } from "../../utils/Notifications";
import ChunkSelector from '../../components/ChunkSelector';

export default function History({ ...props }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const namePlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.name',defaultMessage: 'Name'});
  const viewPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.action.view',defaultMessage: 'View'});
  const deletePlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.action.delete',defaultMessage: 'Delete'});
  const creationDatePlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.creationDate',defaultMessage: 'Creation Date'});
  const actionPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.action',defaultMessage: 'Action'});
  const describedPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.tag.described',defaultMessage: 'Described'});
  const summarizedPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.tag.summarized',defaultMessage: 'Summarized'});
  const savetoReportPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.saveToReport',defaultMessage: 'Save to Report'});
  const [showChunkSelector, setShowChunkSelector] = useState(false);


  const deleteRecord = (id) => {
    (async () => {
      const result = await dispatch(deleteAnalyzedChunks(id));
      if(result.payload && result.payload.error)
      {
        openNotification("", result.payload.error.message, "error");
      }
      else{
        openNotification("", "Successfuly deleted", "success");
        props.setHistoryData((data) => data.filter((item) => item.id !== id));
    }
    })();
  };

  useEffect(() => {
    props.setDisableDragging(showChunkSelector);

  }, [showChunkSelector]);

  useEffect(() => {
    if(Object.keys(props.textAnalysis).length > 0)
      setShowChunkSelector(true);
  }, [props.textAnalysis]);

  const handleSaveReport = (id) => {
    (async () => {
      props.loadSelectedDataToReport(id);
    })();
  };

  const columns = [
    {
      title: {namePlaceholder},
      dataIndex: ["name", "isDescribe", "isSummary"],
      key: "name",
      width:250,
      render: (text, { name, isDescribe, isSummary }) => (
        <>
          <Col style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            <Row >{name}</Row>
            <Row >
              {isDescribe && (
                <Tag color="blue" >
                  {describedPlaceholder}
                </Tag>
              )}
              {isSummary && (
                <Tag color="blue" >
                  {summarizedPlaceholder}
                </Tag>
              )}
            </Row>
          </Col>
        </>
      ),
    },
    {
      title: {actionPlaceholder},
      key: "id",
      dataIndex: "id",
      width: 300,
      render: (text, record) => (
        <>
          <Button
            type="primary" 
            style={{ borderRadius: "8px", minWidth:"90px" }}
            onClick={() => props.loadUserSelectedData(record.id)}
          >
            {viewPlaceholder}
          </Button> 
         <Button
            type="primary" 
            style={{ marginLeft: "5px", borderRadius: "8px", minWidth:"90px"}}
            onClick={() => deleteRecord(record.id)}
          >
            {deletePlaceholder}
          </Button> 

           <Button
            type="primary" 
            disabled={!record.isSummary}
            style={{ marginLeft: "5px", borderRadius: "8px", minWidth:"90px"}}
            onClick={() => handleSaveReport(record.id)}
          >
            {savetoReportPlaceholder}
          </Button>
        </>
      ),
    },
  ];
  return (
  <>
    <ChunkSelector
        defaultTitle={props.textAnalysis.name}
        selectedChunks={[]}
        isOpen={showChunkSelector}
        textAnalysis={props.textAnalysis}
        setIsOpen={setShowChunkSelector}
      />
  <Table dataSource={props.historyData} columns={columns} rowKey="created_date" style={{tableLayout: "fixed"}} />
  </>
)}
