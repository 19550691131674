import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ForumIdContext } from '../context/forumId-context';
import { fetchLdaData } from './actions/lda';
import Loading from '../components/Loading';
import NoData from '../components/NoData';
import Widget from '../components/Widget';
import MathTableView from '../Explore/MathTableView';

function MathTableWidget(props) {

  const [ data, setData ] = useState([]);
  const [ loaded, setLoaded ] = useState(false);

  const { forumId } = useContext(ForumIdContext);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Widget title={ props.title ? props.title : "Emojify View" } actions={ props.actions } showExploreLink={ false }>
      <MathTableView { ... props } />
    </Widget>
  );
}

export default MathTableWidget;