import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import md5 from 'crypto-js/md5';

/**
 * Utils
 */
import Loading from '../components/Loading';
import NoData from '../components/NoData';
import Widget from '../components/Widget';
import { CacheLayerActions } from '../actions/cache_layer';
import { ForumIdContext } from '../context/forumId-context';
import { fetchGraph, fetchConnected } from './actions/knowledge-graph';
import { notEmpty } from '../utils';

/**
 * Local
 */
import KnowledgeGraph from '../components/ChartVisualisations/KnowledgeGraph';

function getRandomColor() {
  var letters = 'BCDEF'.split('');
  var color = '#';
  for (var i = 0; i < 6; i++ ) {
      color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
}

const colors = {};

function getColor(node_type) {
  if (!colors[node_type]) {
    colors[node_type] = getRandomColor();
  }
  return colors[node_type];
}

function KnowledgeGraphWidget(props) {

  const [ data, setData ] = useState([]);
  const [ chartId, setChartId ] = useState(null);
  const [ loaded, setLoaded ] = useState(false);
  const [ graph, setGraph ] = useState({ nodes: [], edges: [] });
  const [ selectedNode, setSelectedNode ] = useState({});

  const { forumId } = useContext(ForumIdContext);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const getData = async () => {
    const result = await dispatch(fetchGraph({ node_type: 'Question' }));
    if (result) {
      setData(result.payload);
    }
  };

  const getConnected = async () => {
    const { node_type, uid } = selectedNode;
    const result = await dispatch(fetchConnected({ n1_type: node_type, uid }));

    if (result) {
      setData(result.payload);
    }
  };

  useEffect(() => {
    if (!props.chartId) {
      let zForumId = props.forumId ? props.forumId : (props.useContextRules !== false) ? forumId : 'all';
      zForumId = (zForumId == 'all') ? '' : zForumId;
      //let zDateRange = (props.startDate) ? { startDate: props.startDate, endDate: props.endDate } : date_range.data;
      setChartId(md5(JSON.stringify({
        chartType: 'knowledge-graph',
        forumId: zForumId,
        client_id: user.client_id
      })).toString());
      getData();
    } else {
      (async () => {
        setChartId(props.chartId);
        setData(await dispatch(CacheLayerActions.getData(props.chartId)));
      })();
    }
  }, [user && user.client_id, forumId, props.forumId]);

  useEffect(() => {
    if (notEmpty(selectedNode)) {
      getConnected();
    }
  }, [selectedNode]);

  useEffect(() => {
    if (notEmpty(data)) {
      const nodes = [...graph.nodes];
      const edges = [...graph.edges];
      for (const d of data) {
        if (nodes.find((n) => n.uid === d.uid)) {
          continue;
        }
        nodes.push({
          node_type: d.type,
          uid: d.uid,
          id: d.uid,
          label: d.text.slice(0, 15),
          title: d.text,
          color: getColor(d.type),
        });
        if (notEmpty(selectedNode)) {
          edges.push({
            from: selectedNode.uid,
            to: d.uid,
          });
        }
      }
      setGraph({ nodes, edges });
    }
    setLoaded(true);
    if (!props.chartId) {
      dispatch(CacheLayerActions.setData(chartId, data));
    }
  }, [ data ]);

  const handleSelect = (event) => {
    const { nodes, edges } = event;
    console.log('nodes', nodes)
    const selected = graph.nodes.find((n) => n.id === nodes[0]);
    console.log('selected', selected)
    setSelectedNode(selected);
  };

  const resetGraph = () => {
    setGraph({ nodes: [], edges: [] });
    setSelectedNode({});
    getData();
  };

  return (
    <Widget title={ props.title ? props.title : "Knowledge Graph" } actions={ props.actions } showExploreLink={ false }>
      {loaded ? (
        graph ?
          <KnowledgeGraph colors={ colors } graph={ graph } onReset={ resetGraph } onSelect={ handleSelect } />
          :
          <NoData />
        )
        :
        <Loading />
      }
    </Widget>
  );
}

export default KnowledgeGraphWidget;
