import {
  FETCH_S3_URL_LOADING,
  FETCH_S3_URL_SUCCESS,
  FETCH_S3_URL_FAILURE,
} from "../actions/s3";

const initialState = {
  data: [],
  error: null,
  loaded: false,
  loading: false,
};

export function fileUploads(state = initialState, action) {
  switch (action.type) {
    case FETCH_S3_URL_LOADING:
      //console.log('Reducer FETCH_S3_URL_LOADING','<<<<');
      return {
        ...state,
        loading: true,
      };

    case FETCH_S3_URL_SUCCESS:
      //console.log('Reducer FETCH_S3_URL_SUCCESS <<<<', action.payload);
      return {
        ...state,
        data: action.payload,
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_S3_URL_FAILURE:
      //console.log('Reducer FETCH_S3_URL_FAILURE')
      return {
        ...state,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };

    default:
      return state;
  }
}