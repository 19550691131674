import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ClusterChartView from './ClusterChartView';
import CommentView from './CommentView';
import ChunksView from './ChunksView';
import ChunksByView from './ChunksByView'
import EmojifyChartView from './EmojifyChartView'
import EmojifyTopicChartView from './EmojifyTopicChartView';
import SentimentedEntitiesView from './SentimentedEntitiesView';
import MoodTableView from './MoodTableView';
import SearchResults from './SearchResults';
import DataLayer from './DataLayer';

function Explore() {

  return (
    <Switch>
      <Route path="/explore/data-layer/:id/:chartType" component={ DataLayer } />
      <Route exact path="/forums/:forumId/explore" component={ClusterChartView} />
      <Route path="/forums/:forumId/explore/topic/:topic" component={MoodTableView} />
      <Route path="/forums/:forumId/explore/comments" component={CommentView} />
      <Route path="/forums/:forumId/explore/chunks" component={ChunksView} />
      <Route exact path="/explore" component={ClusterChartView} />
      <Route path="/explore/topics/:topic" component={MoodTableView} />
      <Route path="/explore/chunks" component={ChunksView} />
      <Route path="/explore/chunksBy/demographics/:category/:taxonomy/:value/" render={ (props) => <ChunksByView filterType="demographics" { ... props } /> } />
      <Route path="/explore/chunksBy/:filterType/:typeId/"  render={ (props) => <ChunksByView filterType={ props.match.params.filterType } { ... props } /> } />
      <Route path="/explore/search-results" component={SearchResults} />
      <Route path="/explore/emoji" component={EmojifyChartView} />
      <Route path="/explore/emojify-topic" component={EmojifyTopicChartView} />
      <Route path="/explore/sentimented-entities" component={SentimentedEntitiesView} />
    </Switch>
  );
}

export default Explore;