import React from 'react';

function NoData() {
  return (
    <div style={ {
      padding: '10%',
      textAlign: 'center',
      width: 'auto',
    }}>No Data...</div>
  );
}

export default NoData;