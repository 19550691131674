import {
  CACHE_SET_DATA,
  CACHE_MERGE_DATA,
  CACHE_DEL_DATA,
  CACHE_LS_CHANGED
} from "../actions/cache_layer";

export function cacheLayer(state = {}, action) {
  switch (action.type) {
    case CACHE_SET_DATA:
      state[action.payload.key] = action.payload.value;
      return state;

    case CACHE_MERGE_DATA:
      state[action.payload.key] = {
        ... state[action.payload.key],
        ... action.payload.value
      };
      return state;

    case CACHE_DEL_DATA:
      delete state[action.payload.key];
      return state;

    case CACHE_LS_CHANGED:
    default:
      return state;
  }

};