import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../actions/authenticate';
import { FETCH_PLATFORM_SUCCESS } from '../actions/platform';

const initialState = {
  info: {},
};

export function user(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...(action.data.data || {}),
        info: {
          ...(state.info || {}),
          ...((action.data.data || {}).info || {})
        }
      }
      

    case LOGOUT_SUCCESS:
      return initialState;

    case FETCH_PLATFORM_SUCCESS:
      return {
        ...state,
        ...(action.data.user || {}),
        info: {
          ...(state.info || {}),
          ...((action.data.user || {}).info || {})
        }
      }

    default:
      return state;
  }
}