import { gql } from '@apollo/client';

import { setupApi } from '../graphql/client';

export const FETCH_EMOTION_LOADING = 'FETCH_EMOTION_LOADING';
export const FETCH_EMOTION_SUCCESS = 'FETCH_EMOTION_SUCCESS';
export const FETCH_EMOTION_FAILURE = 'FETCH_EMOTION_FAILURE';

const client = setupApi();

function fetchEmotionLoading() {
  return {
    type: FETCH_EMOTION_LOADING,
  };
}

function fetchEmotionSuccess(payload) {
  return {
    type: FETCH_EMOTION_SUCCESS,
    payload,
  };
}

function fetchEmotionFailure(payload) {
  return {
    type: FETCH_EMOTION_FAILURE,
    payload,
  }
}

export function fetchEmotion(variables) {
  return async (dispatch) => {
    try {
      dispatch(fetchEmotionLoading());
      const res = await client.query({
        query: gql`query Emotion($texts: [String!]!) {
          emotion(texts: $texts)
        }`,
        variables
      });
      return dispatch(fetchEmotionSuccess(res.data.emotion));
    } catch (error) {
      console.error(error);
      dispatch(fetchEmotionFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }));
    }
  }
}
