export function transformSurveyCountByMonthAndDayResultSet(rs) {
  if (!rs) {
    return {};
  }
  const data = {};
  for (const item of rs) {
    if (!data[item['SurveysDenorm.month']]) {
      data[item['SurveysDenorm.month']] = {};
    }
    data[item['SurveysDenorm.month']][item['SurveysDenorm.day']] = item['SurveysDenorm.count'];
  }
  return data;
}
