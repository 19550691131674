import React, { useEffect } from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

am4core.useTheme(am4themes_animated);

function CommentBarChart({ classes, data, maxValue }) {

  useEffect(() => {
    const chart = createChart(data, maxValue);
    chart.data = data;

    return function cleanup() {
      chart.dispose();
    }
  }, [data, maxValue]);

  return (
    <div id="comment-bar-chart" className={classes.chart} />
  );
}

const labels = [
  ['data0', '#00a4bd'],
  ['data1', '#9c27b0'],
];

function createChart(data, maxValue) {
  const chart = am4core.create('comment-bar-chart', am4charts.XYChart);
  chart.numberFormatter.numberFormat = '#.#s';
  chart.background.fill = 'rgba(255,255,255,1)';
  const categoryAxis = addCategoryAxis(chart);
  addValueAxis(chart, maxValue);
  for (let [label, color] of labels) {
    addSeries(chart, label, am4core.color(color));
  }
  const ranges = createRanges(data);
  for (let [key, value] of Object.entries(ranges)) {
    addRange(categoryAxis, key, value[0], value[value.length - 1]);
  }
  chart.cursor = new am4charts.XYCursor();

  return chart;
}

function createRanges(data) {
  if (!data) return {};
  return data.reduce((a, d) => {
    if (!a[d.month]) {
      a[d.month] = [d.category];
    } else {
      a[d.month].push(d.category);
    }
    return a;
  }, {});
}

function addCategoryAxis(chart) {
  const axis = chart.xAxes.push(new am4charts.CategoryAxis());
  axis.dataFields.category = 'category';
  const renderer = axis.renderer;
  renderer.minGridDistance = 20;
  const fills = renderer.axisFills.template;
  fills.disabled = true;
  fills.fillOpacity = 0.05;
  const grid = renderer.grid.template;
  grid.location = 0;
  grid.strokeOpacity = 0;
  grid.strokeWidth = 0;
  renderer.labels.template.fontSize = 0;

  return axis;
}

function addValueAxis(chart, maxValue) {
  const axis = chart.yAxes.push(new am4charts.ValueAxis());
  axis.min = 0;
  axis.max = maxValue;
  const renderer = axis.renderer;
  renderer.minGridDistance = 50;
  const grid = renderer.grid.template;
  grid.strokeOpacity = 0;
  grid.strokeWidth = 0;
  const labels = renderer.labels.template;
  labels.adapter.add('text', (text) => text);
  labels.fontSize = 0;
  const line = renderer.line;
  line.strokeOpacity = 0;
  line.strokeWidth = 0;
  const ticks = axis.renderer.ticks.template;
  ticks.disabled = true;
  ticks.length = 5;
  ticks.strokeOpacity = 0.4;
  const title = axis.title;
  title.fill = 'rgba(0,0,0,0.38)';
  title.text = 'Numbers of Comments';

  return axis;
}

function addSeries(chart, field, color) {
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueY = field;
  series.dataFields.categoryX = 'category';
  series.fill = color;
  series.stacked = true;
  series.stroke = color;
  series.strokeWidth = 3;
}

function addRange(categoryAxis, label, start, end) {
  const range = categoryAxis.axisRanges.create();
  range.category = start;
  range.endCategory = end;
  const rangeLabel = range.label;
  rangeLabel.disabled = false;
  rangeLabel.dy = 12;
  rangeLabel.fontSize = 12;
  rangeLabel.location = 0.5;
  rangeLabel.text = label;
  range.axisFill.fill = am4core.color('#ffffff');
}

const styles = {
  chart: {
    flex: 2,
    // The padding will affect cursor accuracy
    // padding: '50px 20px 20px 0px',
    width: '100%',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: 14.2,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
  },
};

export default withStyles(styles)(CommentBarChart);