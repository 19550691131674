import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
 //import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import FormControl from '@material-ui/core/FormControl';
// import MenuItem from '@material-ui/core/MenuItem';
// import Slide from '@material-ui/core/Slide';
// import { makeStyles } from '@material-ui/core/styles';
// import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {FormattedMessage} from "react-intl";
import { saveReportChunck, saveReportTextAnalysis } from '../actions/reports';
import { ReportsContext } from '../Main/MainContainer';
import { useIntl } from "react-intl"; 
import { Modal, Form, Select, Input, Button } from 'antd';
import "../StrategyMap/pages/style.css";
const { Option } = Select;

function ChunkSelector({ defaultTitle='', selectedChunks, textAnalysis, isOpen, setIsOpen }) {

  const reports = useContext(ReportsContext);

  const [reportId, setReportId] = useState('');
  const [title, setTitle] = useState(defaultTitle);
  const intl = useIntl();
  const savePlaceholder = intl.formatMessage({id: 'app.save',defaultMessage: 'Save'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  
  useEffect(()=> {
    setTitle(defaultTitle);
  },[defaultTitle])

  const dispatch = useDispatch();
  //const classes = useStyles();

  const close = () => {
    setIsOpen(false);
  }

  const handleAddToReportClick = () => {
    setIsOpen(true);
  }

  const handleReportChange = (value) => {
    setReportId(value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSave = (values) => {
    if(textAnalysis && Object.keys(textAnalysis).length > 0)
    {
      const data = {
        report: values.report,
        title: values.title,
        active_set: textAnalysis.activeSet,
        sorting: textAnalysis.sorting,
        sorting_order: textAnalysis.sortOrder,
        avg_sentiment: textAnalysis.avgSentiment,
        source_type: textAnalysis.sourceType,
        described_text: textAnalysis.describedText,
      //  summarizedText: textAnalysis.summarizedText,
        abstract_text: textAnalysis.abstractText,
        keypoints_text: textAnalysis.keypointsText,
        chunksId: textAnalysis.chunksId,
      };
      dispatch(saveReportTextAnalysis(data));
    }
    else{
      const chunks = reports[reportId].chunks;
      const chunk_uid_array = chunks.map(chunk => chunk.chunk_id);
        selectedChunks.forEach(chunk => {
  
          // temporary check both chunk_id and chunk_uuid because we're using two source of API calls
          // don't execute save if there's the same chunk_uid in db or with different title
          if(!chunk_uid_array.includes(chunk.chunk_id || chunk.chunk_uuid) || (chunks.find(c => c.chunk_id === chunk.chunk_id) && chunks.find(c => c.chunk_id === chunk.chunk_id).title !== title) || (chunks.find(c => c.chunk_id === chunk.chunk_uuid) && chunks.find(c => c.chunk_id === chunk.chunk_uuid).title !== title)){
            const data = {
              report: values.report,
              chunk_id: chunk.chunk_id || chunk.chunk_uuid ,
              title: values.title,
              content: chunk.text
            };
            dispatch(saveReportChunck(data));
          }
        })
    }
    setIsOpen(false);
    setReportId('');
  };

  return (
    <Modal
    open={isOpen}
    onCancel={close}
    zIndex={3000}
    maskClosable={false}
    title={
      <FormattedMessage
        id="app.explorePage.addToReport"
        defaultMessage="Add to report"
      />
    }
    footer={
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={close}>{cancelPlaceholder}</Button>
        <Button type="primary" htmlType="submit" form="add-to-report-form">
          {savePlaceholder}
        </Button>
      </div>
    }
  >
    <Form
      id="add-to-report-form"
      onFinish={handleSave}
      onFinishFailed={(errors) => console.log(errors)}
    >
      <Form.Item
        label="Report"
        name="report"
        labelCol={{ span: 4 }}
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Select value={reportId} onChange={handleReportChange}>
          {Object.values(reports).map((rpt) => (
            <Option key={rpt.id} value={rpt.id}>
              {rpt.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Title"
        name="title"
        labelCol={{ span: 4 }}
        rules={[{ required: true, message: 'This field is required' }]}
        initialValue= {title}
      >
        <Input
          autoFocus
          onChange={handleTitleChange}
        />
      </Form.Item>
    </Form>
  </Modal>
  );
}

export default ChunkSelector;