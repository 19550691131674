import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import PanToolIcon from '@material-ui/icons/PanTool';
import UpdateIcon from '@material-ui/icons/Update';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from "react-intl";
import { combineStyles } from '../utils';
import commonStyles from './common_styles';

function ReportImage({
  images,
  index,
  section,
  onChange,
  onDelete,
  onSectionDown,
  onSectionUp,
  
}) {
  const intl = useIntl();
  const savePlaceholder = intl.formatMessage({id: 'app.save',defaultMessage: 'save'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'cancel'});
  const deletePlaceholder = intl.formatMessage({id: 'app.delete',defaultMessage: 'delete'});
  
  const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isOpenUploadDialog, setOpenUploadDialog] = useState(false);

  const classes = combinedStyles();

  const openDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const openUploadDialog = () => {
    setOpenUploadDialog(true);
  };

  const closeUploadDialog = () => {
    setOpenUploadDialog(false);
  };

  const handleDelete = () => {
    onDelete(index);
  };



  const handleSectionDown = () => {
    onSectionDown(index);
  };

  const handleSectionUp = () => {
    onSectionUp(index);
  };

  
  const handleChange = (ev) => {
    onChange(index, {
      [ev.target.name]: ev.target.value,
    });
  };


  const setImage = (value) => {
    onChange(index, value);
    setOpenUploadDialog(false);
  }

  const value = JSON.parse(section.content || '{}');
  const id = value.id;
  let imgsrc;
  for (const im of images) {
    if (im.id === id) {
      imgsrc = im.data_url;
      break;
    }
  }

  return (
    <div id={section.id} className={classes.sectionWrap}>
      <div className={classes.sectionGreyWrap}>
        <span className={classes.sectionImageTitle}>Image</span>
        <div className={classes.topButtonArea}>
          <div id="drag"
            className={classes.buttonWrap}
          >
            <PanToolIcon className={classes.iconStyle} />
            <span>Drag</span>
          </div>
          <div className={classes.buttonWrap} onClick={handleSectionUp}>
            <ArrowUpwardIcon className={classes.iconStyle} />
            <span>Up</span>
          </div>
          <div className={classes.buttonWrap} onClick={handleSectionDown}>
            <ArrowDownwardIcon className={classes.iconStyle} />
            <span>Down</span>
          </div>
          <div className={classes.buttonWrap} onClick={openDeleteDialog}>
            <DeleteIcon className={classes.iconStyle} />
            <span>{deletePlaceholder}</span>
          </div>
          <Dialog
            open={isOpenDeleteDialog}
            onClose={closeDeleteDialog}
            aria-labelledby={'alert-dialog-title-' + index}
            aria-describedby={'alert-dialog-description-' + index}
          >
            <DialogTitle id={'alert-dialog-title-' + index}>
              Confirm delete
            </DialogTitle>
            <DialogContent>
              <DialogContentText id={'alert-dialog-description-' + index}>
                Are you sure you want to delete this section?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={closeDeleteDialog}>
              {cancelPlaceholder}
              </Button>
              <Button
                autoFocus
                color="primary"
                variant="contained"
                onClick={handleDelete}
              >
                {deletePlaceholder}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className={classes.updateButtonWrap} onClick={openUploadDialog}>
          <UpdateIcon className={classes.iconStyle} />
          <span>Update Image</span>
        </div>
        <Dialog
          fullWidth
          maxWidth="md"
          open={isOpenUploadDialog}
          onClose={closeUploadDialog}
          aria-labelledby={'image-dialog-title-' + index}
          aria-describedby={'image-dialog-description-' + index}
        >
          <DialogTitle id={'image-dialog-title-' + index}>
            Choose an image
          </DialogTitle>
          <DialogContent>
            {images.map((v, i) => (
              <React.Fragment key={i}>
                <div className={classes.divHover}>
                  <DialogContentText>
                    <span style={{ fontWeight: 500 }}>Image title: </span>
                    {v.title}
                  </DialogContentText>
                  <div className={classes.imageMenuWrap}>
                    <img
                      alt={v.title}
                      className={classes.imageMenuItem}
                      src={v.data_url}
                      onClick={() => setImage(v)}
                    />
                  </div>
                </div>
                <Divider style={{ margin: '10px 0' }} />
              </React.Fragment>
            ))}
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={closeUploadDialog}>
              {cancelPlaceholder}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className={classes.imageTextWrap}>
        {imgsrc ? <img className={classes.imageStyle} src={imgsrc} alt="" /> : null}
      </div>
      <TextField
        id={'outlined-heading-' + index}
        className={classes.textFieldWrap}
        label="Title"
        name="title"
        variant="outlined"
        value={value.title || ''}
        onChange={handleChange}
        InputProps={{
          classes: {
            input: classes.textFieldInputStyle,
          },
        }}
        inputProps={{
          className: classes.textFieldInputWrap
        }}
      />
      <TextField
        id={'outlined-text-' + index}
        className={classes.textFieldWrap}
        label="Commentary"
        name="commentary"
        multiline
        variant="outlined"
        value={value.commentary || ''}
        onChange={handleChange}
        InputProps={{
          classes: {
            input: classes.textFieldInputStyle,
          },
        }}
      />
    </div>
  );
}

const styles = {
  buttonWrap: {
    backgroundColor: '#fff',
    borderRadius: '2px',
    display: 'flex',
    marginRight: '4px',
    padding: '2px 4px',
    textAlign: 'center',
  },
  divhover: {
    '&:hover': {
      background: '#efefef',
      border: 'solid #efefef',
    },
  },
  iconStyle: {
    fontSize: '18px',
    paddingRight: '2px',
    opacity: 0.6,
  },
  imageMenuItem: {
    cursor: 'pointer',
    width: '75%',
  },
  imageMenuWrap: {
    textAlign: 'center',
  },
  imageTextWrap: {
    marginBottom: '15px',
    marginTop: '30px',
    padding: 0,
    position: 'relative',
    textAlign: 'center',
    width: '100%',
  },
  imageWrap: {
    marginBottom: '15px',
  },
  sectionGreyWrap: {
    padding: '10px',
    height: '100%',
  },
  sectionWrap: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    marginBottom: '15px',
    marginTop: '40px',
    padding: '10px 10px 0 10px',
    position: 'relative',
  },
  textFieldInputStyle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 1.41,
    overflowY: 'hidden',
  },
  textFieldWrap: {
    marginBottom: '15px',
    width: '100%',
  },
  topButtonArea: {
    color: '#516e88',
    display: 'flex',
    fontSize: '12.2px',
    position: 'absolute',
    top: '-12px',
    right: '10px',
    textAlign: 'center',
    zIndex: 2,
  },
};

const combinedStyles = makeStyles(combineStyles(commonStyles, styles));

export default ReportImage;