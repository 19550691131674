import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import useInterval from '../components/useInterval';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Spin, Space, Card } from 'antd';
import { fetchTableData } from '../actions/math_table';
import { DashboardContext } from '../TiledDashboard/context/DashboardContextProvider';


function MathTableView({ filters, ... props }) {

  const { dashboardConfig } = useContext(DashboardContext);;
  const [ working, setWorking ] = useState(true);
  const [ status, setStatus ] = useState('Checking status ...');
  const [ dataSource, setDataSource ] = useState([]);
  const [ columns, setColumns ] = useState([]);

  const dispatch = useDispatch()

  useEffect(() => {
    if (!filters || !filters.columns) {
      return;
    }

    fetchData();
  }, [ filters ]);

  useInterval(() => {
    if (working) {
      fetchData();
    }
  }, 1000);

  const fetchData = async () => {
    const response = await dispatch(fetchTableData(
      {
        ... {
          dashboardId: dashboardConfig.id,
          tableSpec: filters
        },
        ... props
      }
    ));
    if (response && response.completed) {
      setWorking(false);
      const rows = response.data;
      setDataSource(rows.map((row) => {
        let flatRow = {};
        row.columns.forEach((col) => {
          flatRow[col.title] = col.value;
        });
        return flatRow;
      }));
      setColumns(rows[0].columns.map((col, index) => {
        let flat = {
          key: col.title,
          title: col.title,
          dataIndex: col.title
        };
        if (index == 0) {
          flat['sorter'] = true;
        }
        return flat;
      }));
    } else if (response) {
      setStatus(response.status);
    }
    console.log('response', response)
  }

  return (
    <React.Fragment>
      {
        working &&
        <Card>
          <Space direction="vertical" align="center" style={{ width: '100%', textAlign: 'center' }}>
            { status }
            <Spin />
          </Space>
        </Card>
      }
      {
        !working &&
        <Table
          dataSource={dataSource}
          columns={columns}
        />
      }
    </React.Fragment>
  );
}


export default MathTableView;