import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import ParticipantBarChart from '../../charts/ParticipantBarChart';

function ExpansionChartPanel({ id, title, chartData = [] }) {

  const [expanded, setExpanded] = useState(true);
  const classes = useStyles();

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <ExpansionPanel square expanded={expanded} onChange={toggleExpanded}>
        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
          {expanded ?
            <KeyboardArrowDownIcon className={classes.headingIcon} />
            :
            <KeyboardArrowRightIcon className={classes.headingIcon} />
          }
          <Typography className={classes.headingText}>{title}</Typography>
        </ExpansionPanelSummary>
        {chartData.length ?
          <ExpansionPanelDetails>
            <ParticipantBarChart id={title} chartData={chartData} />
          </ExpansionPanelDetails>
          :
          <div key={id} className={classes.noData}>No data</div>
        }
      </ExpansionPanel>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  headingIcon: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  headingText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '16.3px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.5px',
  },
  noData: {
    padding: '5%',
    textAlign: 'center',
    width: 'auto',
  }
}));

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const ExpansionPanelSummary = withStyles({
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    height: '36px',
    marginBottom: '-1px',
    minHeight: '36px',
    '&$expanded': {
      minHeight: '36px',
    },
  },
  expanded: {},
})(AccordionSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
  },
}))(AccordionDetails);

export default ExpansionChartPanel;