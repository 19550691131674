import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { fetchQuery } from '../actions/cube';
import { forumsList } from '../queries';

import commonStyles from './common_styles';
import ReportsList from './ReportsList';
import ReportView from './ReportView';

function Reports({ forumId }) {

  const { data } = useSelector((state) => state.cube[forumsList.type]);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const classes = useStyles();

  const reportListURL = forumId ? `/forums/${forumId}/reports` : '/reports';
  const reportViewURL = forumId ? `/forums/${forumId}/reports/:reportId` : '/reports/:reportId';

  // useEffect(() => {
  //   dispatch(fetchQuery(forumsList, user.info.clientId));
  // }, []);

  return (
    <div className={classes.sectionContent}>
      <Switch>
        <Route exact path={reportListURL}>
          <ReportsList forums={data} />
        </Route>
        <Route exact path={reportViewURL}>
          <ReportView />
        </Route>
      </Switch>
    </div>
  );
}

const useStyles = makeStyles(commonStyles);

export default Reports;