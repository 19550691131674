import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchQueryWithCustomDateRange } from '../actions/cube';
import CommentBarChart from '../charts/CommentBarChart';
import Loading from '../components/Loading';
import Widget from '../components/Widget';
import NoData from '../components/NoData';
import {
  commentCountByMonthAndDay,
  surveyCountByMonthAndDay,
} from '../queries';
import {
  combineCountData,
  transformCombinedCounts,
} from '../transformers/comments';
import { ForumIdContext } from '../context/forumId-context';
import { transform } from '../transformers/utils';

function CommentWidget(props) {

  // const { data, loaded, maxValue } = useSelector((state) => {
  //   const commentCounts = state.cube[commentCountByMonthAndDay.type];
  //   const surveyCounts = state.cube[surveyCountByMonthAndDay.type];

  //   // normally in reducer but here for efficiency
  //   const combinedCounts = combineCountData(commentCounts.data, surveyCounts.data);
  //   const { data, maxValue } = transformCombinedCounts(combinedCounts);
  //   return {
  //     data,
  //     loaded: commentCounts.loaded && surveyCounts.loaded,
  //     maxValue,
  //   };
  // });
  const [ data, setData ] = useState([]);
  const [ loaded, setLoaded ] = useState(false);
  const [ maxValue, setMaxValue ] = useState(0);
  const { forumId } = useContext(ForumIdContext);
  const user = useSelector((state) => state.user);
  const date_range = useSelector((state) => state.date_range);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const clientUuid = user.client_id;
      if (clientUuid) {
        let zForumId = props.forumId ? props.forumId : (props.useContextRules !== false) ? forumId : 'all';
        zForumId = (zForumId == 'all') ? '' : zForumId;
        let zDateRange = (props.startDate) ? { startDate: props.startDate, endDate: props.endDate } : date_range.data;
        let commentCounts = await dispatch(fetchQueryWithCustomDateRange(commentCountByMonthAndDay, zDateRange, clientUuid, zForumId));
        let surveyCounts =  await dispatch(fetchQueryWithCustomDateRange(surveyCountByMonthAndDay, zDateRange, clientUuid, zForumId));
        if (commentCounts) {
          commentCounts.data = transform(commentCountByMonthAndDay.type, commentCounts.data);
        }
        if (surveyCounts) {
          surveyCounts.data = transform(surveyCountByMonthAndDay.type, surveyCounts.data);
        }
        if (surveyCounts && commentCounts) {
          const combinedCounts = combineCountData(commentCounts.data, surveyCounts.data);
          const transformed = transformCombinedCounts(combinedCounts);
          setMaxValue(transformed.maxValue);
          setData(transformed.data);
        }
        setLoaded(true);
      }
    })();
  }, [user, date_range, forumId, props.forumId, props.startDate, props.endDate]);

  return (
    <Widget title={ props.title ? props.title : "Data analysed in the last 6 months"} actions={ props.actions }>
      {loaded ?
        data ?
          <CommentBarChart data={data} maxValue={maxValue} />
          :
          <NoData />
        :
        <Loading />
      }
    </Widget>

  );
}

export default CommentWidget;