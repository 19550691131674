import React from 'react';
import { Card } from 'antd';

/**
 * Wrapper around the data frame draggable element
 *
 * @param blockEl The component element to be wrapped
 * @param isDragging Bool indicating whether the element is being dragged
 */
export default () => ({ blockEl, isDragging }) => (
  <Card
    bodyStyle={
      {
        background: isDragging ? "lightgreen" : "white",
        padding: "5px",
      }
    }
  >
    { blockEl }
  </Card>
);