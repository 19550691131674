import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

function FullPageSpinner({ classes }) {
  return (
    <div className={classes.spinnerContainer}>
      <CircularProgress size="100px" />
    </div>
  )
}

const styles = {
  spinnerContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
  },
};

export default withStyles(styles)(FullPageSpinner);