import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

function ParticipantBarChart({ id, chartData }) {

  useEffect(() => {
    const chart = createChart(id);
    chart.data = chartData;

    return function cleanup() {
      chart.dispose();
    }
  }, [id, chartData]);

  return (
    <div id={id} style={{ width: '100%', height: '450px' }}></div>
  );
}

function createChart(id) {
  const chart = am4core.create(id, am4charts.XYChart);
  addValueAxis(chart);
  addCategoryAxis(chart);
  const series_registered = addSeries(chart, 'value1', '#dff6fa', 0, 20);
  const series_active = addSeries(chart, 'value2', '#01b4d0', 20, 30);
  addLabel(series_registered, 0);
  addLabel(series_active, 20);
  addCursor(chart);
  return chart;
}

function addValueAxis(chart) {
  const axis = chart.xAxes.push(new am4charts.ValueAxis());
  const renderer = axis.renderer;
  renderer.grid.template.strokeOpacity = 0;
  renderer.grid.template.strokeWidth = 0;
  renderer.labels.template.fontSize = 0;
}

function addCategoryAxis(chart) {
  const axis = chart.yAxes.push(new am4charts.CategoryAxis());
  axis.dataFields.category = 'filed';
  const renderer = axis.renderer;
  renderer.minGridDistance = 10;
  renderer.inversed = true;
  const template = renderer.grid.template;
  template.location = 0;
  template.strokeOpacity = 0;
  template.strokeWidth = 0;
}

function addSeries(chart, value, color, offset, height) {
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueX = value;
  series.dataFields.categoryY = 'filed';
  series.stroke = am4core.color(color);
  series.fill = am4core.color(color);
  series.clustered = false;
  series.columns.template.dy = offset;
  series.columns.template.height = height;
  series.tooltipText = '{categoryY} : {valueX}';
  return series;
}

function addLabel(series, offset) {
  const valueLabel = series.bullets.push(new am4charts.LabelBullet());
  const label = valueLabel.label;
  label.text = '{valueX}';
  label.horizontalCenter = 'left';
  label.dx = 6;
  label.dy = offset;
  label.hideOversized = false;
  label.truncate = false;
  label.fill = am4core.color('rgba(0, 0, 0, 0.38)');
  label.fontSize = '12px';
}

function addCursor(chart) {
  chart.cursor = new am4charts.XYCursor();
  chart.cursor.lineX.disabled = true;
  chart.cursor.lineY.disabled = true;
}

export default ParticipantBarChart;