const TAG_COLORS = {
  'JJ': 'red',
  'JJR': 'red',
  'JJS': 'red',
  'RB': 'cyan',
  'RBR': 'cyan',
  'RBS': 'cyan',
  'PRP': 'magenta',
  'PRP$': 'magenta',
};

export function transformEmotionResultSet(rs) {
  const chunks = (rs || []).map((chunk) => {
    const tokens = [];
    const value = [];
    let j = 0;
    for (const [token, tag] of chunk.tags) {
      tokens.push(token);
      if (TAG_COLORS[tag]) {
        value.push({
          start: j,
          end: j + 1,
          tag: tag,
          color: TAG_COLORS[tag] || '#fff',
        });
      }
      j += 1;
    }
    return { tokens, value };
  });
  return chunks;
}
