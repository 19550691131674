import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import ProblemSolutionList from "../components/ChartVisualisations/ProblemSolutionList";

import { ForumIdContext } from "../context/forumId-context";
import { fetchProbSol } from "./actions/problem-solution";
import Loading from "../components/Loading";
import NoData from "../components/NoData";
import Widget from "../components/Widget";
import localPropUtil from "./utils/localPropUtil";


function ProblemSolutionWidget(props) {
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [exploreLink, setExploreLink] = useState("");

  const { forumId } = useContext(ForumIdContext);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const date_range = useSelector((state) => state.date_range);

  useEffect(() => {
    (async () => {
      let zForumId = props.forumId
        ? props.forumId
        : props.useContextRules !== false
        ? forumId
        : "all";
      zForumId = zForumId == "all" ? "" : zForumId;
      //let zDateRange = (props.startDate) ? { startDate: props.startDate, endDate: props.endDate } : date_range.data;
      let result = await dispatch(
        fetchProbSol({
          client_id: user.client_id,
          forum_id: zForumId,
          startDate: props.startDate,
          endDate: props.endDate,
          type: props.type,
          displayLimit: props.filters.displayLimit ? props.filters.displayLimit : 5
        })
      );
      if (result) {
        setData(result.payload);
        console.log("result.payload", result.payload);
      }
      setLoaded(true);
      let localProps = await localPropUtil(
        dispatch,
        "problem-solution",
        user.client_id,
        forumId,
        date_range,
        props
      );

      setExploreLink(
        '/explore/data-layer/'+ localProps.chartId + '/chunks?' + [
          'from=explore/data-layer/'+ localProps.chartId +'/problem-solution/',
          'q=' + encodeURI(props.type),
          'client_id=' + localProps.clientId,
          'forum_id=' +  localProps.forumId,
          'startDate=' + localProps.dateRange.startDate,
          'endDate=' + localProps.dateRange.endDate,
        ].join('&')
      );
    })();
  }, [user && user.client_id, forumId, props.forumId]);

  const [boxState, setBoxState] = useState({ width: 200, height: 200 });

  const onResize = (event, { element, size }) => {
    setBoxState({ width: size.width, height: size.height });
  };

  return (
    <Widget
      title={props.title ? props.title : "Problem Solution"}
      actions={props.actions}
      showExploreLink={true}
      exploreLink={exploreLink}
    >
            {loaded ? (
              data && data.results ? (
                <ProblemSolutionList data={data.results} />
              ) : (
                <NoData />
              )
            ) : (
              <Loading />
            )}       
    </Widget>

  );
}

export default ProblemSolutionWidget;
