import React, { useState } from "react";
import { Button, Modal } from "antd";
import StrategyMapChunksView from "../../../Explore/StrategyMapChunksView";
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";

function ExploreModal({
  customProps,
  dateRange,
  isModalVisible,
  closeExploreModal,
  prevStep,
  type,
}) {
  const [modalWidth, setModalWidth] = useState("80%");
  const [modalHeight, setModalHeight] = useState("70vh");
  const [modalTop, setModalTop] = useState("100px");
  const [isMaxModal, setIsMaxModal] = useState(false);
  const maximise = () => {
    setIsMaxModal(true);
    setModalWidth("100%");
    setModalHeight("89vh");
    setModalTop("0px");
  };
  const minimise = () => {
    setIsMaxModal(false);
    setModalWidth("80%");
    setModalHeight("70vh");
    setModalTop("100px");
  };
  return (
    <Modal
      visible={isModalVisible}
      title={isMaxModal? <ShrinkOutlined onClick={minimise}/> : <ArrowsAltOutlined onClick={maximise}/>} 
      width={modalWidth}
      style={{top: modalTop, maxWidth:modalWidth}}
      bodyStyle={{
        minHeight: modalHeight,
        maxHeight: modalHeight,
        overflow: "auto",
        animationDuration: "0s !important",
      }}
      onOk={closeExploreModal}
      onCancel={closeExploreModal}
      transitionName="none"
      maskTransitionName="none"
      footer={
        type !== "List"
          ? [
              <Button key="1" onClick={prevStep}>
                Back
              </Button>,
              <Button key="2" onClick={closeExploreModal}>
                Close
              </Button>,
            ]
          : [
              <Button key="1" onClick={closeExploreModal}>
                Close
              </Button>,
            ]
      }
    >
      {<StrategyMapChunksView {...customProps} {...dateRange} />}
    </Modal>
  );
}

export default ExploreModal;
