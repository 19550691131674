import { gql } from '@apollo/client';

import { setupApi } from '../graphql/client';

export const FETCH_COMMENTS_LOADING = 'FETCH_COMMENTS_LOADING';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE';


const client = setupApi();

function fetchCommentsLoading() {
  return {
    type: FETCH_COMMENTS_LOADING,
  };
}

function fetchCommentsSuccess(payload) {
  return {
    type: FETCH_COMMENTS_SUCCESS,
    payload,
  };
}

function fetchCommentsFailure(payload) {
  return {
    type: FETCH_COMMENTS_FAILURE,
    payload,
  }
}

export function getComment(client_id, uid) {
  return async (dispatch) => {
    try {
      let gqlQuery;
      gqlQuery = {
        query: gql`
          query GetComment($client_id: String!, $uid: String!) {
            comments(where:{client_id:$client_id, uid: $uid}) {
              uid
              text
            }
          }
        `,
        variables: {
          client_id,
          uid
        }
      };
      const res = await client.query(gqlQuery);

      return (res.data.comments && res.data.comments.length) ? res.data.comments[0] : {};
    } catch (error) {
      dispatch(fetchCommentsFailure({
        error: {
          type: typeof(error),
          message: error.toString(),
        },
      }));
    }
  }
}

export function getChunksMultiFilter({ client_id, forum_id, startDate = '', endDate = '', commentQuery = {}, emojiQuery = {}, entityQuery = {}, topicQuery={},argumentQuery={}, subtopic=false }) {
  return async (dispatch) => {
    try {
      dispatch(fetchCommentsLoading());
      forum_id = forum_id == '' ? undefined : forum_id;
      if (!commentQuery.AND && startDate != '' && endDate != '') {
        commentQuery['AND'] = [];
      }

      if (startDate != '' && endDate != '') {
        commentQuery['AND'].push({
          create_datetime_GTE: startDate,
          create_datetime_LTE: endDate,
        });
      }

      const commentParam = Object.keys(commentQuery).length > 0 ? '$commentWhere: CommentWhere' : '';
      const commentWhereParam = Object.keys(commentQuery).length > 0 ? 'comment: $commentWhere' : '';

      const forumParam = forum_id ? '$forum_id: String!' : '';
      const forumWhereParam = forum_id ? 'forum_id: $forum_id' : '';

      const topicParam = Object.keys(topicQuery).length > 0 && !subtopic  ? '$topicsWhere: TopicWhere!' : '';
      const topicWhereParam = Object.keys(topicQuery).length > 0 && !subtopic  ? 'topics: $topicsWhere' : '';

      const subtopicParam = Object.keys(topicQuery).length > 0 && subtopic  ? '$subtopicsWhere: SubTopicWhere!' : '';
      const subtopicWhereParam = Object.keys(topicQuery).length > 0 && subtopic ? 'subtopics: $subtopicsWhere' : '';

      const entityParam = Object.keys(entityQuery).length > 0  ? '$entitiesWhere: EntityWhere!' : '';
      const entityWhereParam = Object.keys(entityQuery).length > 0  ? 'entities: $entitiesWhere' : '';

      const emojiParam = Object.keys(emojiQuery).length > 0  ? '$emojiWhere: EmojiWhere' : '';
      const emojiWhereParam = Object.keys(emojiQuery).length > 0  ? 'emoji: $emojiWhere' : '';

      const argumentParam = Object.keys(argumentQuery).length > 0  ? '$argumentWhere: ProblemSolutionWhere' : '';
      const argumentWhereParam = Object.keys(argumentQuery).length > 0  ? 'argument: $argumentWhere' : '';

      const preppedQuery = `
        query GetChunks(
          $client_id: String!
          ${forumParam}
          ${commentParam}
          ${topicParam}
          ${subtopicParam}
          ${entityParam}
          ${emojiParam}
          ${argumentParam}
          $limit: Int
          $offset: Int
        ) {
          chunks(
            options: { limit: $limit, offset: $offset, sort:{weighted_score:DESC} }
            where: {
              client_id: $client_id
              
              ${forumWhereParam}
              ${commentWhereParam}
              ${topicWhereParam}
              ${subtopicWhereParam}
              ${entityWhereParam}
              ${emojiWhereParam}
              ${argumentWhereParam}
            }
          ) {
            uid
            text
            weighted_score
            tokens
            process_datetime
            comment {
              uid
              text
              create_datetime
              conversation {
                uid
                text
              }
              user {
                uid
                __typename
              }
              demographics {
                category
                taxonomy
                value
              }
              __typename
            }
            clusterElement {
              text
              clusterHead {
                text
                question {
                  text
                  __typename
                }
                __typename
              }
              __typename
            }
            sentiment {
              sentiment
              __typename
            }
            emoji {
              plutchik_category
              __typename
            }
            argument {
              argument_type
              pro_con_flag
              __typename
            }
            __typename
          }
        }
      `;
      console.log('gqlQuery', preppedQuery);
      const gqlQuery = {
        query: gql`${preppedQuery}`,
        variables: {
          client_id,
          forum_id,
          commentWhere: Object.keys(commentQuery).length > 0 ? commentQuery : undefined,
          topicsWhere: Object.keys(topicQuery).length > 0 && !subtopic ? topicQuery : undefined,
          subtopicsWhere: Object.keys(topicQuery).length > 0 && subtopic ? topicQuery : undefined,
          entitiesWhere: Object.keys(entityQuery).length > 0 ? entityQuery : undefined,
          emojiWhere: Object.keys(emojiQuery).length > 0 ? emojiQuery : undefined,
          argumentWhere:Object.keys(argumentQuery).length > 0 ? argumentQuery : undefined,
          offset: 0,
          limit: 1000
        }
      };
      console.log('gqlQuery', JSON.stringify(gqlQuery.variables, null, 2));

      const res = await client.query(gqlQuery);
      console.log('gqlQuery response:',res);
      return dispatch(fetchCommentsSuccess(res.data.chunks.map((doc) => {
        return {
          ... doc,
          ... {
            chunk_id: doc.uid,
            chunk_uuid: doc.uid,
            sentiment: (doc.sentiment && doc.sentiment.sentiment) ? doc.sentiment.sentiment : doc.sentiment
          }
        }
      })));
    } catch (error) {
      console.error(error);
      dispatch(fetchCommentsFailure({
        error: {
          type: typeof(error),
          message: error.toString(),
        },
      }));
    }
  }
}
export function getCommentsMultiFilter({
  client_id,
  forum_id,
  startDate = "",
  endDate = "",
  commentQuery = {},
  emojiQuery = {},
  entityQuery = {},
  topicQuery = {},
  argumentQuery = {},
  subtopic = false,
}) {
  return async (dispatch) => {
    try {
      dispatch(fetchCommentsLoading());
      forum_id = forum_id == "" ? undefined : forum_id;

      if (!commentQuery.AND && startDate != "" && endDate != "") {
        commentQuery["AND"] = [];
      }

      if (startDate != "" && endDate != "") {
        commentQuery["AND"].push({
          create_datetime_GTE: startDate,
          create_datetime_LTE: endDate,
        });
      }

      const forumParam = forum_id ? "$forum_id: String!" : "";
      const forumWhereParam = forum_id ? "forum_id: $forum_id" : "";

      const topicParam =
        Object.keys(topicQuery).length > 0 && !subtopic
          ? "$topicsWhere: TopicWhere!"
          : "";
      const topicWhereParam =
        Object.keys(topicQuery).length > 0 && !subtopic
          ? "topics: $topicsWhere"
          : "";

      const subtopicParam =
        Object.keys(topicQuery).length > 0 && subtopic
          ? "$subtopicsWhere: SubTopicWhere!"
          : "";
      const subtopicWhereParam =
        Object.keys(topicQuery).length > 0 && subtopic
          ? "subtopics: $subtopicsWhere"
          : "";

      const entityParam =
        Object.keys(entityQuery).length > 0
          ? "$entitiesWhere: EntityWhere!"
          : "";
      const entityWhereParam =
        Object.keys(entityQuery).length > 0 ? "entities: $entitiesWhere" : "";

      const emojiParam =
        Object.keys(emojiQuery).length > 0 ? "$emojiWhere: EmojiWhere" : "";
      const emojiWhereParam =
        Object.keys(emojiQuery).length > 0 ? "emoji: $emojiWhere" : "";

      const argumentParam =
        Object.keys(argumentQuery).length > 0
          ? "$argumentWhere: ProblemSolutionWhere"
          : "";
      const argumentWhereParam =
        Object.keys(argumentQuery).length > 0 ? "argument: $argumentWhere" : "";

      const dateTimeParam =
        startDate != "" && endDate != ""
          ? "$startDate: DateTime!\n$endDate: DateTime!"
          : "";
      const dateTimeWhereParam =
        startDate != "" && endDate != ""
          ? "create_datetime_GTE: $startDate, create_datetime_LTE: $endDate "
          : "";

      const preppedQuery = `
        query GetComments(
          $client_id: String!
          ${forumParam}
          ${dateTimeParam}
          ${topicParam}
          ${subtopicParam}
          ${entityParam}
          ${emojiParam}
          $limit: Int
          $offset: Int
        ) {
          comments(
            options: {limit: $limit, offset: $offset, sort:[{weighted_score:DESC}, {uid:ASC}]}
            where: {
              client_id: $client_id
              weighted_score_GT:0
              ${forumWhereParam}
              ${topicWhereParam}
              ${subtopicWhereParam}
              ${entityWhereParam}
              ${emojiWhereParam}
              ${dateTimeWhereParam}      
            }
          ) {
            uid
            text
            weighted_score
            tokens
            process_datetime
            create_datetime
            conversation {
              uid
              text
            }
            user {
              uid
              __typename
            }
            demographics {
              category
              taxonomy
              value
            }
            chunks {
              uid
              text
              __typename
              clusterElement {
                text
                clusterHead {
                  text
                  question {
                    text
                    __typename
                  }
                  __typename
                }
                __typename
              }
            }
            sentiment {
              sentiment
              __typename
            }
            emoji {
              plutchik_category
              __typename
            }
            __typename
          }
        }
      `;
      console.log("gqlQuery", preppedQuery);
      const gqlQuery = {
        query: gql`
          ${preppedQuery}
        `,
        variables: {
          client_id,
          forum_id,
          topicsWhere:
            Object.keys(topicQuery).length > 0 && !subtopic
              ? topicQuery
              : undefined,
          subtopicsWhere:
            Object.keys(topicQuery).length > 0 && subtopic
              ? topicQuery
              : undefined,
          entitiesWhere:
            Object.keys(entityQuery).length > 0 ? entityQuery : undefined,
          emojiWhere:
            Object.keys(emojiQuery).length > 0 ? emojiQuery : undefined,
          startDate,
          endDate,
          offset: 0,
          limit: 1000,
        },
      };
      console.log("gqlQuery", JSON.stringify(gqlQuery.variables, null, 2));

      const res = await client.query(gqlQuery);
      console.log("gqlQuery response:", res);
      return dispatch(
        fetchCommentsSuccess(
          res.data.comments.map((doc) => {
            return {
              ...doc,
              ...{
                comment_id: doc.uid,
                comment_uuid: doc.uid,
                sentiment:
                  doc.sentiment && doc.sentiment.sentiment
                    ? doc.sentiment.sentiment
                    : doc.sentiment,
              },
            };
          })
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(
        fetchCommentsFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}


export function getStrategyMapChunksMultiFilter({ client_id, forum_id, uid, sentimentType, startDate = '', endDate = '', topic_number='',commentQuery = {} }) {
  return async (dispatch) => {
    try {
      dispatch(fetchCommentsLoading());
      if (!commentQuery.AND && startDate != '' && endDate != '') {
        commentQuery['AND'] = [];
      }
      if (startDate != '' && endDate != '') {
        commentQuery['AND'].push({
          create_datetime_GTE: startDate,
          create_datetime_LTE: endDate,
        });
      }

      const commentParam = Object.keys(commentQuery).length > 0 ? '$commentWhere: CommentWhere' : '';
      const commentWhereParam = Object.keys(commentQuery).length > 0 ? 'comment: $commentWhere' : '';

      forum_id = forum_id == '' ? undefined : forum_id;

      const forumParam = forum_id ? '$forum_id: String!' : '';
      const forumWhereParam = forum_id ? 'forum_id: $forum_id' : '';

      const uidParam = uid ? '$uid: ID!' : '';
      const uidWhereParam = uid ? 'uid:$uid' : '';
      const sentimentParam = sentimentType ? '$sentiment: String!' : '';

      //Using the phrase_id, since one chunk can be linked to different topics belonging to different phrases
     //without filter on the LDATopic object, there are chances to get the others belonging to different phrases 
     const phrase_id = uid.toString();
     const phraseIdParam = phrase_id ? '$phrase_id: String!' : '';
     const phraseIdWhereParam = phrase_id ? 'phrase_id: $phrase_id' : '';

     const topicNumParam = topic_number ? '$topic_number: Int!' : '';
     const topicNumWhereParam = topic_number ? 'topic_number: $topic_number' : '';

      let filterByPowerfulChunks = "";
      let sentimentWhereParam ="";
      if(sentimentType==="All")
        sentimentWhereParam = 'sentiment: {sentiment_IN:[1, 2, 3, 4, 5]}'
      else if(sentimentType==="Positive")
      sentimentWhereParam = 'sentiment: {sentiment_IN:[ 4, 5]}'
      else if(sentimentType==="Negative")
      sentimentWhereParam = 'sentiment: {sentiment_IN:[1, 2]}'
      else if (sentimentType==="Neutral")
      sentimentWhereParam = 'sentiment: {sentiment_IN:[3]}'
      else if(sentimentType==="Good Quotes")
      filterByPowerfulChunks= "weighted_score_GTE:3"
     
      const preppedQuery = `
      query
         GetCulturePhrases(
          $client_id: String!
          ${forumParam}
          ${uidParam}
          ${commentParam}
          ${topicNumParam}
        ) 
        {
          culturePhrases(
            where: {
              client_id: $client_id,
              ${uidWhereParam},
              ${forumWhereParam}
            }
          ) {
              uid
              text
              status
              client_id
              forum_id
              chunksConnection(where:{node:
                {   

                  ${sentimentWhereParam}, ${filterByPowerfulChunks}, ${commentWhereParam}
              }
            }, sort:{edge:{relevance_rank:DESC}},)
              {
                totalCount
                edges
                {
                  relevance_rank
                  node
                  { 
                    uid
                    text
                    weighted_score
                    tokens
                    process_datetime
                    comment 
                    {
                      uid
                      text
                      create_datetime
                      conversation 
                      {
                        uid
                        text
                      }
                      user {
                        uid
                        __typename
                      }
                      demographics {
                        category
                        taxonomy
                        value
                      }
                      __typename
                    }
                    clusterElement 
                    {
                      text
                      clusterHead {
                        text
                        question {
                          text
                          __typename
                        }
                        __typename
                      }
                      __typename
                    }
                    sentiment 
                    {
                      sentiment
                      __typename
                    }
                    emoji 
                    {
                      plutchik_category
                      __typename
                    }
                    argument 
                    {
                      argument_type
                      pro_con_flag
                      __typename
                    }
                    __typename
                   }
              }
            }
          }
        }
      `;
      console.log('gqlQuery', preppedQuery);
      const gqlQuery = {
        query: gql`${preppedQuery}`,
        variables: {
          client_id,
          forum_id,
          uid,
          topic_number,
          commentWhere: Object.keys(commentQuery).length > 0 ? commentQuery : undefined
        }
      };
     const res = await client.query(gqlQuery);

      return dispatch(fetchCommentsSuccess(res.data.culturePhrases[0].chunksConnection.edges.map((doc) => {
      return {
        ... doc.node,
        ... {
          chunk_id: doc.node.uid,
          chunk_uuid: doc.node.uid,
          sentiment: (doc.node.sentiment && doc.node.sentiment.sentiment) ? doc.node.sentiment.sentiment : doc.node.sentiment
        }
      }
      })));
    } catch (error) {
      dispatch(fetchCommentsFailure({
        error: {
          type: typeof(error),
          message: error.toString(),
        },
      }));
    }
  }
}
