import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MuiThemeProvider,
  // https://github.com/mui-org/material-ui/issues/13394
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles';

import 'antd/dist/antd.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './App.css';

import { fetchPlatform } from './actions/platform';
import AuthenticatedApp from './AuthenticatedApp';
import FullPageSpinner from './components/FullPageSpinner';
import UnauthenticatedApp from './UnauthenticatedApp';

// const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'));
// const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

function App() {

  const { loaded } = useSelector((state) => state.platform);
  const user = useSelector((state) => state.user);
  const loggedIn = !!(user || {}).id;

  const dispatch = useDispatch();

  useEffect(() => {
    if(!loggedIn)
    dispatch(fetchPlatform());
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      {loaded ?
        loggedIn ? <AuthenticatedApp user={user} /> : <UnauthenticatedApp />
        :
        <FullPageSpinner />
      }
    </MuiThemeProvider>
  );
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#607d8b',
    },
    secondary: {
      main: '#868aa8',
      light: '#868aa8',
      dark: '#008a73',
      contrastText: '#fafafa',
    },
  }
});

export default App;