import axios from 'axios';

export const FETCH_TOPICS_SUCCESS = 'FETCH_TOPICS_SUCCESS';
export const FETCH_TOPICS_FAILURE = 'FETCH_TOPICS_FAILURE';

function fetchTopicsSuccess(data) {
  return {
    type: FETCH_TOPICS_SUCCESS,
    data,
  };
}

function fetchTopicsFailure(error) {
  return {
    type: FETCH_TOPICS_FAILURE,
    error,
  }
}

export function fetchTopics() {
  return async (dispatch) => {
    try {
      const res = await axios.get('/data-api/api/v1/topics');
      if (res.status !== 200) {
        throw Error(res.statusText);
      }
      return dispatch(fetchTopicsSuccess(res.data));
    } catch (err) {
      dispatch(fetchTopicsFailure(err));
    }
  }
}
