const cityCountryMap = {
  "Dallas": {
    "id": "US",
    "name": "United States"
  },
  "Frankfurt": {
    "id": "DE",
    "name": "Germany"
  },
  "London": {
    "id": "UK",
    "name": "United Kingdom"
  },
  "New York": {
    "id": "US",
    "name": "United States"
  },
  "San Francisco": {
    "id": "US",
    "name": "United States"
  },
  "Singapore": {
    "id": "SG",
    "name": "Singapore"
  },
  // TODO for demo purposes
  "Unknown": {
    "id": "AU",
    "name": "Australia"
  }
};

export function transformParticipantsByLocationResultSet(rs) {
  const data = {};
  if (rs) {
    for (let item of rs) {
      if (item['ParticipantsDenorm.surveyLocation']) {
        const country = cityCountryMap[item['ParticipantsDenorm.surveyLocation']];
        if (country) {
          data[country.id] = {
            ...country,
            value: item['ParticipantsDenorm.count']
          };
        }
      } else {
        const country = cityCountryMap[item['ParticipationsDenorm.surveyLocation']];
        if (country) {
          data[country.id] = {
            ...country,
            value: item['ParticipationsDenorm.count']
          };
        }
      }
    }
  }
  return Object.values(data);
}
