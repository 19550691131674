import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.options.autoSetClassName = true;
//am4core.useTheme(am4themes_animated);

const moodColorChart = {
  '0': '#FFFFFF',
  '1': '#ed2f2f',
  '1.1': '#ef3b2d',
  '1.2': '#f1462b',
  '1.3': '#f25029',
  '1.4': '#f45927',
  '1.5': '#f56226',
  '1.6': '#f66a25',
  '1.7': '#f77224',
  '1.8': '#f87a23',
  '1.9': '#f98223',
  '2': '#F98924',
  '2.1': '#fb9222',
  '2.2': '#fd9c20',
  '2.3': '#ffa51f',
  '2.4': '#ffae1f',
  '2.5': '#ffb720',
  '2.6': '#ffc122',
  '2.7': '#ffca26',
  '2.8': '#ffd32a',
  '2.9': '#ffdd30',
  '3': '#ffe636',
  '3.1': '#f2e233',
  '3.2': '#e6dd30',
  '3.3': '#dad82e',
  '3.4': '#ced42c',
  '3.5': '#c2cf2a',
  '3.6': '#b6ca29',
  '3.7': '#abc529',
  '3.8': '#9fc028',
  '3.9': '#94bb28',
  '4': '#89B628',
  '4.1': '#81b428',
  '4.2': '#78b128',
  '4.3': '#70af29',
  '4.4': '#67ac2a',
  '4.5': '#5fa92a',
  '4.6': '#55a72b',
  '4.7': '#4ca42c',
  '4.8': '#41a12e',
  '4.9': '#369f2f',
  '5': '#289c30',
};

function DataCompareTable({ data, forumUuid, topic, xLength, ...props }) {

  const [chartDivId, setChartDivId] = useState(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setChartDivId('mood-chart-' + Math.random().toString(32).substr(2, 12));
  }, []);

  useEffect(() => {
    if (!chartDivId) {
      return;
    }

    const chart = createChart(chartDivId, history, topic, forumUuid, location, props.onClick, xLength);

    let chartData = addIconURL(data).map((record) => {
      return {
        ...record,
        ... {
          percentage: Math.round(record.percentage),
          // avg_mood: Math.round(record.avg_mood)
          avg_mood: Math.round(record.avg_mood * 10) / 10
        }
      };
    })


    // check if row/col is starting with number, if yes, split based on ",_:- " and sort it
    // const sortAxis =(chartData, axis)=>{
    //    return chartData.sort((a, b) => {  
    //     let keyA = '';
    //     let keyB = '';
    //     if(axis ==='x-axis')
    //     {
    //       keyA = (a.y.split(/[-_:, ]+/))[0];
    //       keyB = (b.y.split(/[-_:, ]+/))[0];
    //     } 
    //     else if(axis==='y-axis')
    //     {
    //        keyA = (a.y.split(/[-_:, ]+/))[0];
    //        keyB = (b.y.split(/[-_:, ]+/))[0];
    //     }         
    //      //Check if one of the string is empty, put empty column at the end 
    //     if(keyA === "" || keyA === null) return 1;
    //     if(keyB === "" || keyB === null) return -1;
    //     if(keyA === keyB) return 0;
    //     if(a.y.length !== 0 && b.y.length !==0 )
    //     {  
    //     //check if string is type number  
    //      if(!isNaN(keyA) && !isNaN(keyB))
    //      {
    //       const numA = Number(keyA);
    //       const numB = Number(keyB);
    //       if (numA > numB) return 1;
    //       if (numA < numB) return -1;
    //         return 0;
    //      }
    //     }
    //     //sort as a string if column is not type number
    //     return keyA.toUpperCase() < keyB.toUpperCase() ? -1 : 1;  
    //   });
    // }


    // let xAxisLabels = [];
    // let yAxisLabels =[];
    // const xAxis = sortAxis(chartData,'x-axis');
    // const yAxis = sortAxis(chartData,'y-axis');
    // yAxis.forEach((y) =>{
    //   if (!(yAxisLabels.includes(y.y))) {
    //     yAxisLabels.push(y.y)
    //   }
    // })
    // xAxis.forEach((x) =>{
    //   if (!(xAxisLabels.includes(x.x))) {
    //     xAxisLabels.push(x.x)
    //   }
    // })
    const lookup = {};
    const ys = {};
    for (const record of chartData) {
      let { x, y } = record;
      if (!(x in lookup)) {
        lookup[x] = {};
      }
      lookup[x][y] = record;
      ys[y] = 1;
    }
    // check if row/col is starting with number, if yes, split based on ",_:- " and sort it
    const sortKeys = (a, b) => {
      const keyA = a.split(/[-_:, ]+/)[0];
      const keyB = b.split(/[-_:, ]+/)[0];
      //Check if one of the string is empty, put empty column at the end
      if (keyA === "" || keyA === null) return 1;
      if (keyB === "" || keyB === null) return -1;
      if (keyA === keyB) return 0;
       //check if string is type number  
      if (!isNaN(keyA) && !isNaN(keyB)) {
        return Number(keyA) < Number(keyB) ? -1 : 1;
      }
       //sort as a string if row/column is not type number
      return keyA.toUpperCase() < keyB.toUpperCase() ? -1 : 1;
    };

    const xAxisLabels = Object.keys(lookup);
    xAxisLabels.sort(sortKeys);

    const yAxisLabels = Object.keys(ys);
    yAxisLabels.sort(sortKeys);

    let sortedChartData = [];
    xAxisLabels.forEach((x) => {
      yAxisLabels.forEach((y) => {
        if (lookup[x][y] !== undefined) {
          sortedChartData.push(lookup[x][y])
        } else {
          sortedChartData.push({
            x: x,
            y: y,
            avg_mood: 0,
            avg_sentiment: 0,
            chunk_count: 0,
            color: am4core.color('#FFFFFF')
          });
        }
      });
    });
    chart.data = sortedChartData;
    return function cleanup() {
      chart.dispose();
    };
  }, [chartDivId, data, history, topic]);

  return (
    <>
      {chartDivId && <div id={chartDivId} style={{ width: '100%', height: '100%' }}></div>}
    </>
  );
}

function createChart(id, history, topic, forumUuid, location, onClick, xLength) {
  const chart = am4core.create(id, am4charts.XYChart);
  chart.hiddenState.properties.opacity = 0;  // this creates the initial fade-in
  chart.maskBullets = false;

  const { yAxis, xAxis } = addCategoryAxis(chart);
  const series = addSeries(chart, yAxis, xAxis);
  setColAppearance(series, xLength);
  //const circleBullet = setCircleBullet(series);
  //setImage(circleBullet);
  setLabelBullet(series);
  addEventListener(series, history, topic, forumUuid, location, onClick);
  return chart;
}

function addCategoryAxis(chart) {
  const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

  xAxis.dataFields.category = 'x';
  yAxis.dataFields.category = 'y';

  const xRenderer = xAxis.renderer;
  const yRenderer = yAxis.renderer;

  xRenderer.opposite = true;
  xRenderer.grid.template.disabled = true;
  xRenderer.minGridDistance = 40;

  yRenderer.labels.template.wrap = true;
  yRenderer.labels.template.maxWidth = 200;
  yRenderer.labels.template.height = 30;
  yRenderer.grid.template.disabled = true;
  yRenderer.inversed = true;
  yRenderer.minGridDistance = 30;

  return { yAxis, xAxis };
}

function addSeries(chart, yAxis, xAxis) {
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.categoryX = 'x';
  series.dataFields.categoryY = 'y';
  series.dataFields.value = 'value';
  series.sequencedInterpolation = true;
  series.defaultState.transitionDuration = 0;
  series.showOnInit = false;

  yAxis.sortBySeries = series;
  xAxis.sortBySeries = series;
  return series;
}

function setColAppearance(series) {
  // Set up column appearance
  const column = series.columns.template;
  column.strokeWidth = 2;
  column.strokeOpacity = 1;
  column.stroke = am4core.color('#ffffff');
  column.tooltipText = "{xTitle}, {yTitle}\n Sentiment: {avg_mood}"
  column.adapter.add('tooltipText', (text, target) => {
    var data = target.tooltipDataItem.dataContext;
    if (data.chunk_count === 0)
      return "";
    else
      return "{xTitle}, {yTitle}\n Sentiment: {avg_mood}"
  });
  column.width = am4core.percent(100);
  column.height = am4core.percent(100);
  //column.height = 30;
  column.column.cornerRadius(6, 6, 6, 6);
  column.propertyFields.fill = 'color';
}

function setCircleBullet(series) {
  const circleBullet = series.bullets.push(new am4charts.CircleBullet());
  circleBullet.circle.radius = 5;
  circleBullet.isMeasured = true;
  circleBullet.fill = am4core.color('#ffffff');
  circleBullet.circle.strokeWidth = 0;
  circleBullet.interactionsEnabled = false;
  return circleBullet;
}

function setLabelBullet(series) {
  const labelBullet = series.bullets.push(new am4charts.LabelBullet());
  labelBullet.label.text = '{value} ({percentage}%)';
  labelBullet.label.adapter.add('text', (text, target) => {
    var data = target.dataItem.dataContext;
    if (data.chunk_count === 0)
      return '';
    else
      return '{value} ({percentage}%)'
  });
  labelBullet.label.fill = 'rgba(0, 0, 0, 1)';
  labelBullet.zIndex = 1;
  labelBullet.fontSize = 15;
  //labelBullet.dy = 20;
  labelBullet.interactionsEnabled = false;
}

function setImage(bullet) {
  const image = bullet.createChild(am4core.Image);
  image.width = 30;
  image.height = 30;
  image.dy = -10;
  image.horizontalCenter = 'middle';
  image.verticalCenter = 'middle';
  image.propertyFields.href = 'href';
}

function addEventListener(series, history, topic, forumUuid, location, onClick) {
  series.columns.template.events.on('hit', (e) => {
    if (onClick) {
      if (e.target.column._dataItem._dataContext.chunk_count !== 0)
        onClick(e, series, history, topic, forumUuid, location);
    }
  }, this);
}

function addIconURL(chartData, xLength) {
  let data = [];
  const maxWidth = Math.ceil(1200 / 7 / xLength);
  for (let i = 0; i < chartData.length; i++) {
    let cell = chartData[i];
    if (cell.x.length > 13) {
      cell.x = cell.x.substring(0, 13) + '...';
    }

    let emotion_average = _.round(cell.avg_mood, 1);
    // if (cell.comment_count === 0) {
    //   cell = Object.assign(cell, { color: am4core.color('#ffffff') });
    // } else if (emotion_average === 1) { //10
    //   cell = Object.assign(cell, {
    //     color: am4core.color('#dff6fa'), href: '/static/images/icons/sentiment/face_1.png'
    //   });
    // } else if (emotion_average === 2) { //10
    //   cell = Object.assign(cell, {
    //     color: am4core.color('#dff6fa'), href: '/static/images/icons/sentiment/face_2.png'
    //   });
    // // } else if (emotion_average === 3) { //10
    // //   cell = Object.assign(cell, {
    // //     color: am4core.color('#dff6fa'), href: '/static/images/icons/sentiment/face_3.png'
    // //   });
    // } else if (emotion_average === 3) { //20
    //   cell = Object.assign(cell, {
    //     color: am4core.color('#dff6fa'), href: '/static/images/icons/sentiment/face_4.png'
    //   });
    // // } else if (emotion_average === 5) { //20
    // //   cell = Object.assign(cell, {
    // //     color: am4core.color('#dff6fa'), href: '/static/images/icons/sentiment/face_5.png'
    // //   });
    // } else if (emotion_average === 4) { //20
    //   cell = Object.assign(cell, {
    //     color: am4core.color('#dff6fa'), href: '/static/images/icons/sentiment/face_6.png'
    //   });
    // } else if (emotion_average === 5) { //80
    //   cell = Object.assign(cell, {
    //     color: am4core.color('#dff6fa'), href: '/static/images/icons/sentiment/face_7.png'
    //   });
    // } else {
    //   cell = Object.assign(cell, { color: am4core.color('#ffffff') });
    // }
    cell = Object.assign(cell, { color: am4core.color(moodColorChart[emotion_average]) });
    data.push(cell);
  }

  return data;
}

export default DataCompareTable;