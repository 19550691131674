import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import PowerfulStatementList from "../components/ChartVisualisations/PowerfulStatementList";

import { ForumIdContext } from "../context/forumId-context";
import { fetchPowerfulStatements } from "./actions/powerful-statements";
import Loading from "../components/Loading";
import NoData from "../components/NoData";
import Widget from "../components/Widget";
import localPropUtil from "./utils/localPropUtil";


function PowerfulStatementsWidget(props) {
  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [exploreLink, setExploreLink] = useState("");

  const { forumId } = useContext(ForumIdContext);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const date_range = useSelector((state) => state.date_range);

// Commented code is for coloring the tokens
// const adj_tags =['JJ','JJR','JJS'];
// const adv_tags =['RB', 'RBR', 'RBS'];
// const prp_tags =['PRP','PRP$'];
//   const tags = [
//     { value: 'JJ', color: 'red'},
//     { value: 'JJR', color: 'red'},
//     { value: 'JJS', color: 'red'},
//     { value: 'RB', color: 'cyan'},
//     { value: 'RBR', color: 'cyan'},
//     { value: 'RBS', color: 'cyan'},
//     { value: 'PRP', color: 'magenta'},
//     { value: 'PRP$', color: 'magenta'},
//     { value: 'None', color: 'off'}
// ];

//   function findColor(value) {
//     const tag = tags.find(tag=> tag.value == value);
//     return tag && tag.color;
// }


useEffect(() => {
    (async () => {
      let zForumId = props.forumId
        ? props.forumId
        : props.useContextRules !== false
        ? forumId
        : "all";
      zForumId = zForumId == "all" ? "" : zForumId;
      let result = await dispatch(
        fetchPowerfulStatements({
          client_id: user.client_id,
          forum_id: zForumId,
          startDate: props.startDate,
          endDate: props.endDate,
          type: props.type,
          displayLimit: props.filters.displayLimit ? props.filters.displayLimit : 5,
        //  minWordLimit: props.filters.minWordLimit ?props.filters.minWordLimit: 5
        })
      );
      if (result) {
        setData(result.payload);
        console.log("result.payload", result.payload);
      }
      setLoaded(true);
      let localProps = await localPropUtil(
        dispatch,
        "powerful-statements",
        user.client_id,
        forumId,
        date_range,
        props
      );

      setExploreLink(
        '/explore/data-layer/'+ localProps.chartId + '/comments?' + [
         'from=explore/data-layer/'+ localProps.chartId +'/powerful-statements/',
          'q=' + encodeURI('Powerful Statements'),
          'client_id=' + localProps.clientId,
          'forum_id=' +  localProps.forumId,
          'startDate=' + localProps.dateRange.startDate,
          'endDate=' + localProps.dateRange.endDate,
        ].join('&')
      );
    })();
  }, [user && user.client_id, forumId, props.forumId]);

  const [boxState, setBoxState] = useState({ width: 200, height: 200 });

  const onResize = (event, { element, size }) => {
    setBoxState({ width: size.width, height: size.height });
  };

  return (
    <Widget
      title={props.title ? props.title : "Powerful Statements"}
      actions={props.actions}
      showExploreLink={true}
      exploreLink={exploreLink}
    >
            {loaded ? (
              data && data.results ? (
                <PowerfulStatementList data={data.results} /*findColor ={findColor}*/ />
              ) : (
                <NoData />
              )
            ) : (
              <Loading />
            )}       
    </Widget>

  );
}

export default PowerfulStatementsWidget;
