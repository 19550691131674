import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect
} from 'react-router-dom';
// import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Row, Col } from 'antd';

import { fetchReports } from '../actions/reports';
import Explore from '../Explore';
import ForumOverview from '../ForumsBoard/Overview';
import Participation from '../ForumsBoard/Participation/Participation';
import KnowledgeGraph from '../KnowledgeGraph';
import Search from '../Search';
import Reports from '../Reports';
import LoadData from '../LoadData';
//import UserStats from '../UserStats';
import { ForumIdContext } from '../context/forumId-context';
import { TiledDashboardPage } from '../TiledDashboard';
import { StrategyMapPage } from '../StrategyMap';
import {FormattedMessage} from "react-intl";

const tabs = [ 'search', 'explore', 'reports', 'load' ];
const forumTabs = ['overview', 'graph', 'participation', 'explore', 'reports'];
const strategyMapsTabs = ['strategymaps', 'reports', 'load'];
// make the reports list available as global state for every ChartSelector
// instance to avoid repeat calls to `fetchReports`
export const ReportsContext = React.createContext([]);
ReportsContext.displayName = 'reports';

function MainContainer({ forumDataSources }) {

  const { forumId, setForumId, forumTitle } = useContext(ForumIdContext);

  const history = useHistory();
  const location = useLocation();

  // so no tab is selected until after the effect,
  // which avoids unnecessary data loads
  const [tabIndex, setTabIndex] = useState(-1);
  const [forumTabIndex, setForumTabIndex] = useState(-1);
  const [board, setBoard] = useState('');
  const [smDashboardTabIndex, setsmDashboardTabIndex] = useState(-1);
  const [isReportFetched, setIsReportFetched] = useState(false);
  const reports = useSelector((state) => state.reports.data);
  const user = useSelector((state) => state.user);
  const pathname = location.pathname;

  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    const parts = pathname.split('/');
    let index;
    if (parts[1] === 'forums') {
      setBoard('forum');
      setForumId(parts[2]);
      index = forumTabs.indexOf(parts[3]);
      if (index === -1) {
        index = 0;
      }
      setForumTabIndex(index);
    }
    else if(parts[1] === 'strategy-maps'){
      setBoard('strategymaps');
      index = strategyMapsTabs.indexOf(parts[1]);
      if (index === -1) {
        index = 1;
      }
      setsmDashboardTabIndex(index);
    } 
    else {
      index = tabs.indexOf(parts[1]);
      if (index === -1) {
        index = 0;
      }
      // to avoid being unable to navigate back to homepage after go to forumsboard
      setBoard('');
      setTabIndex(index);
    }
  }, [pathname]);

  useEffect(() => {
    (async () => {
       const data = await dispatch(fetchReports());
       if(data){
        setIsReportFetched(true); 
        //If this is not set, Report component will be called multiple times 
        //until useSelector can access fetched value
       }
    })();
  }, []);

  const handleTabChange = (ev, value) => {
    history.push('/' + tabs[value]);
  };

  const handleForumTabChange = (ev, value) => {
    history.push(`/forums/${forumId}/${forumTabs[value]}`);
  };
  const explorePlaceholder =  <FormattedMessage
  id="app.exploreData"
  defaultMessage="Explore Data"/>
  const searchPlaceholder =  <FormattedMessage
  id="app.top.search"
  defaultMessage="Search"/>
  const reportsPlaceholder =  <FormattedMessage
  id="app.top.reports"
  defaultMessage="Reports"/>
  const loadDataPlaceholder =  <FormattedMessage
  id="app.top.loadData"
  defaultMessage="Load Data"/>
  const strategyMapPlaceholder= <FormattedMessage
  id="app.top.strategyMap"
  defaultMessage="Strategy Map"/>
  // breaking the view into separate sets of tabs simplifies understanding
  // of the logic I think
  // TODO separate components altogether would be even better
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static" color="default">
        {/* <div className={classes.topName}>{user.info.clientTitle}</div> */}
        <Row>
          <Col span={ 6 }>
            <div className={classes.topName}>
              { forumTitle? forumTitle: user.info.clientTitle || 'All'}
            </div>
          </Col>
          <Col span={ 18 }>
            {
              board === 'forum' ?
              <Tabs
                className={classes.topTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                value={forumTabIndex}
                onChange={handleForumTabChange}
              >
                <Tab className={classes.tabItem} value={ 0 } label="Overview" />
                <Tab className={classes.tabItem} value={ 1 } label="Knowledge Graph" />
                <Tab className={classes.tabItem} value={ 2 } label="Participation" />
                {forumTabIndex === 3 && <Tab className={classes.tabItem} index={ 3 } label={explorePlaceholder} /> }
                <Tab className={classes.tabItem} value={ 4 } label="Reports" />
              </Tabs>
              :
              board === 'strategymaps' ?
              <Tabs
              className={classes.topTabs}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              value={smDashboardTabIndex}
              onChange={handleTabChange}
            >
              {smDashboardTabIndex === 1 && <Tab className={classes.tabItem} value={ 1 } label={strategyMapPlaceholder} /> }
              <Tab className={classes.tabItem} value={ 2 } label={reportsPlaceholder} />
              <Tab className={classes.tabItem} value={ 3 } label={loadDataPlaceholder} />
            </Tabs>
            :
              <Tabs
                className={classes.topTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                value={tabIndex}
                onChange={handleTabChange}
              >
                {/* <Tab className={classes.tabItem} value={ 0 } label="Dashboard" /> */}
                {/* <Tab className={classes.tabItem} value={ 0 } label={searchPlaceholder} /> */}
                {/* <Tab className={classes.tabItem} value={ 1 } label="Overview" /> */}
                {/* <Tab className={classes.tabItem} label="Knowledge Graph" />
                <Tab className={classes.tabItem} label="User stats" /> */}
                {tabIndex === 1 && <Tab className={classes.tabItem} value={ 1 } label={explorePlaceholder} /> }
                <Tab className={classes.tabItem} value={ 2 }label={reportsPlaceholder} />
                <Tab className={classes.tabItem} value={ 3 } label={loadDataPlaceholder} />
              </Tabs>
            }
          </Col>
        </Row>
      </AppBar>
      {board === 'forum' ?
        <ReportsContext.Provider value={reports}>
          <TabContainer classes={classes} dir={theme.direction} value={forumTabIndex} index={0}>
            {/* The purpose of the condition test is to avoid loading data
                in the hidden component */}
            {forumTabIndex === 0 && <ForumOverview forumId={forumId} />}
          </TabContainer>
          <TabContainer classes={classes} dir={theme.direction} value={forumTabIndex} index={1}>
            {forumTabIndex === 1 && <KnowledgeGraph />}
          </TabContainer>
          <TabContainer classes={classes} dir={theme.direction} value={forumTabIndex} index={2}>
            {forumTabIndex === 2 &&
              <Switch>
                <Route path="/forums/:forumId/participation/:optionId">
                  <Participation forumId={forumId} />
                </Route>
                <Route path="/forums/:forumId/participation">
                  <Participation forumId={forumId} />
                </Route>
              </Switch>
            }
          </TabContainer>
          <TabContainer classes={classes} dir={theme.direction} value={forumTabIndex} index={3}>
            {forumTabIndex === 3 && <Explore />}
          </TabContainer>
          <TabContainer classes={classes} dir={theme.direction} value={forumTabIndex} index={4}>
            {forumTabIndex === 4 && <Reports forumId={forumId} />}
          </TabContainer>
        </ReportsContext.Provider>
        :
        board === 'strategymaps' ?
        <ReportsContext.Provider value={reports}>
          <TabContainer classes={classes} dir={theme.direction} value={smDashboardTabIndex} index={1}>
            { smDashboardTabIndex === 1 && <StrategyMapPage forumDataSources={ forumDataSources } /> }
          </TabContainer>
          <TabContainer classes={classes} dir={theme.direction} value={smDashboardTabIndex} index={2}>
            {smDashboardTabIndex === 2 && <Reports />}
          </TabContainer>
          <TabContainer classes={classes} dir={theme.direction} value={smDashboardTabIndex} index={3}>
            {smDashboardTabIndex === 3 && <LoadData />}
          </TabContainer>
        </ReportsContext.Provider>
        :
        <ReportsContext.Provider value={reports}>
          {/* <TabContainer classes={classes} dir={theme.direction} value={tabIndex} index={0}>
            {tabIndex === 0 && <TiledDashboardPage reports={reports} forumDataSources={ forumDataSources } />}
          </TabContainer>
          <TabContainer classes={classes} dir={theme.direction} value={tabIndex} index={1}>
            {tabIndex === 1 && <Overview reports={reports} />}
          </TabContainer> */}

          {/* <TabContainer classes={classes} dir={theme.direction} value={tabIndex} index={2}>
            {tabIndex === 2 && <UserStats />}
          </TabContainer> */}
          <TabContainer classes={classes} dir={theme.direction} value={tabIndex} index={0}>
            {/* {tabIndex === 0 && <Search reports={reports} forumDataSources={ forumDataSources } />} */}
            { tabIndex == 0 && <Redirect to="/dashboard" /> }
          </TabContainer>
          <TabContainer classes={classes} dir={theme.direction} value={tabIndex} index={1}>
            {tabIndex === 1 && <Explore />}
          </TabContainer>
          <TabContainer classes={classes} dir={theme.direction} value={tabIndex} index={2}>
            {tabIndex === 2 && isReportFetched && <Reports />}
          </TabContainer>
          <TabContainer classes={classes} dir={theme.direction} value={tabIndex} index={3}>
            {tabIndex === 3 && <LoadData />}
          </TabContainer>
        </ReportsContext.Provider>
      }
    </div >
  );
}

function TabContainer({ children, classes, dir, value, index }) {
  const hidden = (value !== index);
  return (
    <Typography
      id={`full-width-tabpanel-${index}`}
      className={classes.tabContainer}
      component="div"
      dir={dir}
      hidden={hidden}
      role="tabpanel"
      style={{ display: hidden ? 'none' : 'flex' }}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {!hidden && children}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: 'block',
    flexDirection: 'row',
    boxShadow: 'none',
    borderBottom: '1px solid #eee',
    zIndex: 1,
    width: '100%',
    height: '48px',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    width: '100%',
  },
  tabContainer: {
    // flex: 2,
    flexDirection: 'column',
    padding: '16px 48px'
  },
  tabItem: {
    maxWidth: '180px',
    whiteSpace: 'nowrap',
  },
  topName: {
    color: 'rgba(0,0,0,0.38)',
    display: 'inline-block',
    fontSize: '24.4px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: '48px',
    minHeight: '48px',
    paddingLeft: '48px',
  },
  topTabs: {
    '&:last-child': {
      position: 'absolute',
      right: '0'
    }
  },
}));

export default MainContainer;