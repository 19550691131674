import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Main from './Main';
import GenericPageContainer from './GenericPageContainer';
import { TiledDashboardPage } from './TiledDashboard';
import { TaxonomyExporerPage } from './TaxonomyExplorer';
import {NetworkMapPage} from './NetworkMap';

export default (props) => (
  <Switch>
    <Route path="/dashboard" exact render={ (renderProps) =>
      <GenericPageContainer>
        <TiledDashboardPage { ... props } { ... renderProps } />
      </GenericPageContainer>
    } />
    <Route path="/taxonomy-explorer" exact render={ (renderProps) =>
      <GenericPageContainer>
        <TaxonomyExporerPage { ... props } { ... renderProps } />
      </GenericPageContainer>
    } />
     <Route path="/strategy-maps" exact render={ (renderProps) =>
      <GenericPageContainer>
        <Main { ... props } { ... renderProps } />
      </GenericPageContainer>
    } />
         <Route path="/network-map" exact render={ (renderProps) =>
      <GenericPageContainer>
         <NetworkMapPage { ... props } { ... renderProps } />
      </GenericPageContainer>
    } />
    <Route path="/" render={ (renderProps) => <Main { ... props } { ... renderProps } /> } />
  </Switch>
)