import { gql } from '@apollo/client';
import { setupApi } from '../graphql/client';
import _ from 'lodash';

export const FETCH_SENTIMENTED_TOPICS_LOADING = 'FETCH_SENTIMENTED_TOPICS_LOADING';
export const FETCH_SENTIMENTED_TOPICS_SUCCESS = 'FETCH_SENTIMENTED_TOPICS_SUCCESS';
export const FETCH_SENTIMENTED_TOPICS_FAILURE = 'FETCH_SENTIMENTED_TOPICS_FAILURE';

const client = setupApi();

function fetchSentimentedTopicsLoading() {
  return {
    type: FETCH_SENTIMENTED_TOPICS_LOADING,
  };
};

function fetchSentimentedTopicsSuccess(payload) {
  return {
    type: FETCH_SENTIMENTED_TOPICS_SUCCESS,
    payload,
  };
};

function fetchSentimentedTopicsFailure(payload) {
  return {
    type: FETCH_SENTIMENTED_TOPICS_FAILURE,
    payload,
  }
};

export function fetchSentimentedTopics(client_id, forum_id, startDate = '', endDate = '',demographicsWhere) {
  return async (dispatch) => {
    try {
      dispatch(fetchSentimentedTopicsLoading());
      forum_id = forum_id == '' ? undefined : forum_id;
      const forumParam = forum_id ? '$forum_id: String!' : '';
      const forumWhereParam = forum_id ? 'forum_id: $forum_id' : '';
      const subForumValue = forum_id ? '$forum_id' : 'null';

      const dateTimeParam = startDate != '' && endDate != '' ? '$startDate: DateTime!\n$endDate: DateTime!' : '';
      const dateTimeWhereParam  = startDate != '' && endDate != '' ? 'create_datetime_GTE: $startDate, create_datetime_LTE: $endDate ' : '';
      const dateTimeSubWhereParam  = startDate != '' && endDate != '' ? ', date_filter: { create_datetime_GTE: $startDate, create_datetime_LTE: $endDate }' : '';
      const demographicsParam = '$demographicsWhere: UserDemographicsWhere';
      const demographicWhereParam = 'demographics: $demographicsWhere';

      const topicsPreppedQuery = `
          topics(where: {
            comments: {
              client_id: $client_id
              ${forumWhereParam}
              AND: {
                ${dateTimeWhereParam}
                ${demographicWhereParam}
              }
            }
          }) {
            text
            chunk_count(client_id: $client_id, forum_id: ${subForumValue} ${dateTimeSubWhereParam}chunkWhere: {comment: {demographics: $demographicsWhere}})
            chunk_avg_sentiment(client_id: $client_id, forum_id: ${subForumValue} ${dateTimeSubWhereParam}chunkWhere: {comment: {demographics: $demographicsWhere}})
          }
      `;
      console.log('gqlQuery', topicsPreppedQuery);

      const subtopicsPreppedQuery = `
          subTopics(where: {
            comments: {
              client_id: $client_id
              ${forumWhereParam}
              AND: {
                      ${dateTimeWhereParam}
                      ${demographicWhereParam}
                    }
            }
          }) {
            text
            chunk_count(client_id: $client_id, forum_id: ${subForumValue} ${dateTimeSubWhereParam} chunkWhere: {comment: {demographics: $demographicsWhere}})
            chunk_avg_sentiment(client_id: $client_id, forum_id: ${subForumValue} ${dateTimeSubWhereParam} chunkWhere: {comment: {demographics: $demographicsWhere}})
          }
      `;
      console.log('gqlQuery', subtopicsPreppedQuery);

      const queryTopics = {
        query: gql`
          query GetTopicsAndSubtopics(
            $client_id: String
            ${forumParam}
            ${dateTimeParam}
            ${demographicsParam}
          ) {
              ${topicsPreppedQuery}
          }
        `,
        variables: {
          client_id,
          forum_id,
          startDate,
          endDate,
          demographicsWhere
        }
      };

      const querySubTopics = {
        query: gql`
          query GetTopicsAndSubtopics(
            $client_id: String
            ${forumParam}
            ${dateTimeParam}
            ${demographicsParam}
          ) {
              ${subtopicsPreppedQuery}
          }
        `,
        variables: {
          client_id,
          forum_id,
          startDate,
          endDate,
          demographicsWhere
        }
      };
      //Calling seperately to avoid timeout error in Neo4j
      const resTopics = await client.query(queryTopics);
      const resSubTopics = await client.query(querySubTopics);

      let data = _.unionWith(
        resTopics.data.topics.map((record) => ({
          ... record,
          topic: record.text,
          count: record.chunk_count,
          avg: record.chunk_avg_sentiment
        })),
        resSubTopics.data.subTopics.map((record) => ({
          ... record,
          topic: record.text,
          count: record.chunk_count,
          avg: record.chunk_avg_sentiment,
          subtopic: true
        })),
        (a,b) => {
          return a.topic == b.topic;
        }
      );

      data.sort((a ,b)=> {
        if(a.topic > b.topic){
          return -1;
        }else if(a.topic < b.topic){
          return 1;
        }else {
          return 0;
        }
      });
     
      return dispatch(fetchSentimentedTopicsSuccess(data));

    } catch (error) {
      console.error(error);
      dispatch(fetchSentimentedTopicsFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }));
    }
  };
}
