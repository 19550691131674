import React, { useState, useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

am4core.useTheme(am4themes_animated);

function MoodBarChart({ data }) {

  const [ chartId, setChartId ] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setChartId(Math.random().toString(32).substr(2, 8));
  }, []);

  useEffect(() => {
    let chart;

    if (chartId) {
      chart = createChart(data, chartId);
      chart.data = data;
    }

    return function cleanup() {
      if (chart) {
        chart.dispose();
      }
    }
  }, [data, chartId]);

  return (
    <div id={ chartId } className={classes.chart} />
  );
}

const labels = [
  ['negative1', '#ffd54f'],
  ['negative2', '#ffa726'],
  ['negative3', '#ff5722'],
  ['negative4', '#ff5722'],
  ['negative5', '#ff5722'],
  ['positive1', '#ffd54f'],
  ['positive2', '#fff176'],
  ['positive3', '#dce775'],
  ['positive4', '#fff176'],
  ['positive5', '#dce775'],
];

function createChart(data, chartId) {
  console.log('ChartData', data);
  const chart = am4core.create(chartId, am4charts.XYChart);
  chart.numberFormatter.numberFormat = '#.#s';
  chart.background.fill = 'rgba(255,255,255,1)';
  const categoryAxis = addCategoryAxis(chart);
  addValueAxis(chart);
  for (let [label, color] of labels) {
    addSeries(chart, label, am4core.color(color));
  }
  const ranges = createRanges(data);
  for (let [key, value] of Object.entries(ranges)) {
    value.sort();
    addRange(categoryAxis, key.slice(0, 3), value[0], value[value.length - 1]);
  }
  chart.cursor = new am4charts.XYCursor();
  return chart;
}

function createRanges(data) {
  return data.reduce((a, d) => {
    if (!a[d.month]) {
      a[d.month] = [d.category];
    } else {
      a[d.month].push(d.category);
    }
    return a;
  }, {});
}

function addCategoryAxis(chart) {
  const axis = chart.xAxes.push(new am4charts.CategoryAxis());
  axis.dataFields.category = 'category';
  const renderer = axis.renderer;
  renderer.minGridDistance = 20;
  const fills = renderer.axisFills.template;
  fills.disabled = true;
  fills.fillOpacity = 0.05;
  const grid = renderer.grid.template;
  grid.fontSize = 0;
  grid.location = 0;
  grid.strokeOpacity = 0;
  grid.strokeWidth = 0;
  renderer.labels.template.disabled = true;

  return axis;
}

function addValueAxis(chart) {
  const axis = chart.yAxes.push(new am4charts.ValueAxis());
  const renderer = axis.renderer;
  renderer.minGridDistance = 50;
  const grid = renderer.grid.template;
  grid.strokeOpacity = 0;
  grid.strokeWidth = 0;
  const labels = renderer.labels.template;
  labels.adapter.add('text', (text) => text);
  labels.fontSize = 0;
  const line = renderer.line;
  line.strokeOpacity = 0;
  line.strokeWidth = 0;
  const ticks = axis.renderer.ticks.template;
  ticks.disabled = true;
  ticks.length = 5;
  ticks.strokeOpacity = 0.4;
  const title = axis.title;
  title.fill = 'rgba(0,0,0,0.38)';
  title.text = 'Number Comments';
  return axis;
}

function addSeries(chart, field, color) {
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueY = field;
  series.dataFields.categoryX = 'category';
  series.fill = color;
  series.stacked = true;
  series.stroke = color;
  series.strokeWidth = 3;
}

function addRange(categoryAxis, label, start, end) {
  const range = categoryAxis.axisRanges.create();
  range.category = start;
  range.endCategory = end;
  const rangeLabel = range.label;
  rangeLabel.disabled = false;
  rangeLabel.dy = 12;
  rangeLabel.fontSize = 12;
  rangeLabel.location = 0.5;
  rangeLabel.text = label;
  range.axisFill.fill = am4core.color('#ffffff');
}

const useStyles = makeStyles((theme) => ({
  chart: {
    // issue: padding will affect cursor accuracy
    // padding: '20px 20px 20px 0',
    width: '100%',
    height: '575px',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    color: 'rgba(0,0,0,0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
  },
}));

export default MoodBarChart;