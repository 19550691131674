import { FETCH_TOPICS_SUCCESS } from "../actions/topics";

export function topics(state = {}, action) {
  switch (action.type) {
    case FETCH_TOPICS_SUCCESS:
      return action.data.data.reduce((a, x) => {
        a[x.topic] = x;
        return a;
      }, state);

    default:
      return state;
  }
}