import { LOGOUT_SUCCESS } from '../actions/authenticate';
import {
  FETCH_PLATFORM_LOADING,
  FETCH_PLATFORM_SUCCESS,
  FETCH_PLATFORM_FAILURE,
} from '../actions/platform';

export function platform(state = {
  loaded: false,
  loading: false,
}, action) {
  switch (action.type) {
    case FETCH_PLATFORM_LOADING:
      return {
        ...state,
        loading: true
      };

    case FETCH_PLATFORM_SUCCESS:
    case FETCH_PLATFORM_FAILURE:
      return {
        ...state,
        loaded: true,
        loading: false
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        loaded: false
      };

    default:
      return state;
  }
}