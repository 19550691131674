import React from 'react';
import { Spin } from 'antd';

function Loading(props) {
  return (
    <div>
      { (props.loading || (!props.loading && !props.children)) &&
        <div  style={ {
          padding: '5%',
          textAlign: 'center',
          width: 'auto',
        }}>
          <Spin />
        </div>
      }
      { !props.loading && props.children }
    </div>
  );
}

export default Loading;