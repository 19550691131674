import { gql } from '@apollo/client';
import { setupApi } from '../../graphql/client';
const client = setupApi();

export const FETCH_GRAPH_LOADING = 'FETCH_GRAPH_LOADING';
export const FETCH_GRAPH_SUCCESS = 'FETCH_GRAPH_SUCCESS';
export const FETCH_GRAPH_FAILURE = 'FETCH_GRAPH_FAILURE';

export const FETCH_CONNECTED_LOADING = 'FETCH_CONNECTED_LOADING';
export const FETCH_CONNECTED_SUCCESS = 'FETCH_CONNECTED_SUCCESS';
export const FETCH_CONNECTED_FAILURE = 'FETCH_CONNECTED_FAILURE';

function fetchGraphLoading() {
  return {
    type: FETCH_GRAPH_LOADING,
  };
}

function fetchGraphSuccess(payload) {
  return {
    type: FETCH_GRAPH_SUCCESS,
    payload,
  };
}

function fetchGraphFailure(payload) {
  return {
    type: FETCH_GRAPH_FAILURE,
    payload,
  }
}

export function fetchGraph(variables) {
  return async (dispatch) => {
    try {
      dispatch(fetchGraphLoading());
      const res = await client.query({
        query:  gql`query GetInitialGraph($node_type: String!) {
          initial_graph(node_type: $node_type)
        }`,
        variables
      });
      const data = res.data.initial_graph || [];
      return dispatch(fetchGraphSuccess(data.map((d) => ({
        ...d,
        type: 'Question',
      }))));
    } catch (error) {
      dispatch(fetchGraphFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        }
      }));
    }
  }
};

function fetchConnectedLoading() {
  return {
    type: FETCH_CONNECTED_LOADING,
  };
}

function fetchConnectedSuccess(payload) {
  return {
    type: FETCH_CONNECTED_SUCCESS,
    payload,
  };
};

function fetchConnectedFailure(payload) {
  return {
    type: FETCH_CONNECTED_FAILURE,
    payload,
  }
};

export function fetchConnected(variables) {
  return async (dispatch) => {
    try {
      dispatch(fetchConnectedLoading());
      const res = await client.query({
        query:  gql`query GetConnected($n1_type: String!, $uid: String!) {
          connected(n1_type: $n1_type, uid: $uid)
        }`,
        variables
      });
      const data = res.data.connected;
      return dispatch(fetchConnectedSuccess(data));
    } catch (error) {
      dispatch(fetchConnectedFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        }
      }));
    }
  }
};