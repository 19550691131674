import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import { Select } from 'antd';
import { FormattedMessage } from "react-intl"; 
const { Option } = Select;
function UnitSelector({
  classes,
  dim1Index,
  dim2Index,
  handleDimChange,
  options,
}) {
  return (
    <React.Fragment>
      <div className={classes.bootstrapFormLabel}>
        <FormattedMessage
          id="app.twoWayTable.helpText"
          defaultMessage="I want to see how different groups compared in these categories..."
        />
      </div>
      <form className={classes.root} autoComplete="off">
        <FormControl className={classes.margin}>
          <Select
            value={dim2Index >= 0 ? dim2Index : ""}
            onChange={handleDimChange(2)}
            dropdownStyle={{ zIndex: 2000 }}
            style={{ width: 120 }}
            input={
              <BootstrapInput
                name="secondOption"
                id="second-option-select"
                className={classes.secondOptionStyle}
              />
            }
          >
            {options.map(
              (item, index) =>
                index !== dim1Index && (
                  <Option key={index} value={index}>
                    {item}
                  </Option>
                )
            )}
          </Select>
        </FormControl>
        <span className={classes.unitText}> AND </span>
        <FormControl className={classes.margin}>
          <Select
            value={dim1Index >= 0 ? dim1Index : ""}
            onChange={handleDimChange(1)}
            dropdownStyle={{ zIndex: 2000 }}
            style={{ width: 120 }}
            input={
              <BootstrapInput
                name="firstOption"
                id="first-option-select"
                className={classes.firstOptionStyle}
              />
            }
          >
            {options.map(
              (item, index) =>
                index !== dim2Index && (
                  <Option key={index} value={index}>
                    {item}
                  </Option>
                )
            )}
          </Select>
        </FormControl>
      </form>
    </React.Fragment>
  );
}

const BootstrapInput = withStyles((theme) => ({
  input: {
    color: "rgba(0, 0, 0, 0.6)",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    borderRadius: "4px",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: "14.2px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: 0.25,
    padding: "8px",
    position: "relative",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    width: "200px",
    "&:focus": {
      borderColor: "#80bdff",
      borderRadius: "4px",
      boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.25)",
    },
  },
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
}))(InputBase);

const styles = (theme) => ({
  bootstrapFormLabel: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: "12.2px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: 0.4,
    padding: "0px 16px 0px 16px",
  },
  bottomTips: {
    padding: "14px 16px 16px 16px",
  },
  margin: {
    margin: theme.spacing(0.5),
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    height: "40px",
    padding: "0 12px",
  },
  firstOptionStyle: {
    width: "200px",
  },
  secondOptionStyle: {
    width: "200px",
  },
  tipLabel: {
    backgroundColor: "#78909c",
    borderRadius: "12px",
    color: "#ffffff",
    display: "inline-block",
    fontSize: "12.2px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "normal",
    height: "24px",
    letterSpacing: 0.4,
    margin: "0 4px",
    paddingTop: "2px",
    textAlign: "center",
    width: "160px",
  },
  tipName: {
    color: "rgba(0, 0, 0, 0.38)",
    display: "inline-block",
    fontSize: "12.2px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: 0.4,
    marginRight: "4px",
  },
  unitText: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: "12.2px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: 0.4,
    paddingLeft: "4px",
    paddingRight: "4px",
  },
});

export default withStyles(styles)(UnitSelector);
