import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { CacheLayerActions } from '../actions/cache_layer';
import { fetchEmojiLabels } from '../actions/emoji_labels';
import EmojifyChart from '../charts/EmojifyChart';
import PageHeading from '../components/PageHeading';
import Loading from '../components/Loading';
import NoData from '../components/NoData';
import Widget from '../components/Widget';
import localPropUtil from './utils/localPropUtil';
import { ReportImgContext } from '../context/report-context';
import { ForumIdContext } from '../context/forumId-context';
import { transformEmojiLabelsResultSet } from '../transformers/emoji_labels';

function EmojifyWidget(props) {

  const { isShown } = useContext(ReportImgContext);

  const [ data, setData ] = useState([]);
  const [ loaded, setLoaded ] = useState(false);
  const [ localProps, setLocalProps ] = useState(null);
  const [ exploreLink, setExploreLink ] = useState("");
  const user = useSelector((state) => state.user);
  const date_range = useSelector((state) => state.date_range);

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const {forumId } = useContext(ForumIdContext);

  useEffect(() => {
    (async () => {
      if (!user) {
        return;
      }

      let _localProps = await localPropUtil(dispatch, 'emojify-chart', user.client_id, forumId, date_range, props);
      setLocalProps(_localProps);

      const cacheData = (_localProps.useCache) ? await dispatch(CacheLayerActions.getData(_localProps.chartId)) : null;
      if (!_localProps.useCache || !cacheData) {
        const result = await dispatch(fetchEmojiLabels(_localProps.forumId, user.client_id, _localProps.dateRange.startDate, _localProps.dateRange.endDate));
        if (result && result.payload) {
          setData(transformEmojiLabelsResultSet(result.payload));
        }
      } else {
        setData(cacheData);
      }

      setLoaded(true);
      setExploreLink("/explore/data-layer/"+ _localProps.chartId +"/emoji/?" + [
        'client_id=' + _localProps.clientId,
        'forum_id=' +  _localProps.forumId,
        'startDate=' + _localProps.dateRange.startDate,
        'endDate=' + _localProps.dateRange.endDate,
        'title=' + _localProps.title,
      ].join('&'));
    })();
  }, [ user && user.client_id, date_range, forumId, props.forumId, props.startDate, props.endDate ]);

  function onPointClicked(data) {
    history.push(
      '/explore/data-layer/'+ localProps.chartId + '/chunks?' + [
        'from=explore/data-layer/'+ localProps.chartId +'/emoji/',
        'q=' + encodeURI(data.label.toLowerCase()),
        'client_id=' + localProps.clientId,
        'forum_id=' +  localProps.forumId,
        'startDate=' + localProps.dateRange.startDate,
        'endDate=' + localProps.dateRange.endDate,
      ].join('&'));
  }

  return (
    <>
      {
        !props.noWidget &&
        <Widget title={ props.title ? props.title : "Emojify View" } actions={ props.actions } showExploreLink={ isShown } exploreLink={ exploreLink }>
          {loaded ?
            data ?
              <EmojifyChart data={data} onClick={ onPointClicked } />
              :
              <NoData />
            :
            <Loading />
          }
        </Widget>
      }
      {
        props.noWidget &&
        <>
          {
            loaded ?
              <>
                <PageHeading>{ localProps.title ? localProps.title : "Emojify View" }</PageHeading>
                <EmojifyChart data={data} onClick={ onPointClicked } />
              </>
            :
              <Loading />
          }
        </>
      }
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonLine: {
    flex: 0.5,
    textAlign: 'right',
    marginTop: '-40px',
  },
  centerContent: {
    flex: 3,
    textAlign: 'center',
    width: '100%',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  mainButton: {
    color: '#516e88',
    display: 'inline-block',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    height: '36px',
    letterSpacing: 1.25,
    lineHeight: 1.12,
    width: '153px',
  }
}));

export default EmojifyWidget;