import { cubeapi } from './cube';

import { commentCountByDimPairAndSentiment, dimensionsList } from '../queries';

export const FETCH_MOOD_TABLE_LOADING = 'FETCH_MOOD_TABLE_LOADING';
export const FETCH_MOOD_TABLE_SUCCESS = 'FETCH_MOOD_TABLE_SUCCESS';
export const FETCH_MOOD_TABLE_FAILURE = 'FETCH_MOOD_TABLE_FAILURE';

function fetchMoodTableLoading() {
  return {
    type: FETCH_MOOD_TABLE_LOADING,
  };
}

function fetchMoodTableSuccess(payload) {
  return {
    type: FETCH_MOOD_TABLE_SUCCESS,
    payload,
  };
}

function fetchMoodTableFailure(payload) {
  return {
    type: FETCH_MOOD_TABLE_FAILURE,
    payload,
  };
}

export function fetchMoodTable(dim1, dim2, topic, clientUuid, forumUuid, date_range) {
  return async (dispatch) => {
    try {
      dispatch(fetchMoodTableLoading());
      const dim1_req = cubeapi.load(dimensionsList.query(dim1, clientUuid));
      const dim2_req = cubeapi.load(dimensionsList.query(dim2, clientUuid));
      const counts_req = cubeapi.load(commentCountByDimPairAndSentiment.query(dim1, dim2, topic, clientUuid, forumUuid, date_range));
      const [dim1_rs, dim2_rs, counts_rs] = await Promise.all([dim1_req, dim2_req, counts_req]);
      if (dim1_rs.status < 200 || dim1_rs.status > 399) {
        throw Error('Error fetching dim1: ' + dim1_rs.statusText);
      }
      if (dim2_rs.status >= 400) {
        throw Error('Error fetching dim2: ' + dim2_rs.statusText);
      }
      if (counts_rs.status >= 400) {
        throw Error('Error fetching counts: ' + counts_rs.statusText);
      }
      return dispatch(fetchMoodTableSuccess({
        counts: counts_rs.loadResponse.data,
        dim1,
        dim1Data: dim1_rs.loadResponse.data,
        dim2,
        dim2Data: dim2_rs.loadResponse.data,
        topic,
      }));
    } catch (error) {
      dispatch(fetchMoodTableFailure({
        error: {
          type: typeof (error),
          message: error.toString(),
        },
      }));
    }
  }
}
