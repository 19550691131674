import {
  transformCommentTopicCountByTopicResultSet,
  transformCommentTextResultSet,
  transformEmojifyByTopicAndSentimentResultSet
} from './transformers/comment_topics';
import {
  transformCommentCountByConversationResultSet,
  transformCommentCountByMonthAndDayResultSet,
  transformCommentCountByMonthDayAndSentimentResultSet,
  transformCommentCountBySentimentResultSet,
} from './transformers/comments';
import { transformForumsListResultSet } from './transformers/forums';
import { transformParticipantsByLocationResultSet } from './transformers/participants';
import { transformParticipationsByDimAndActiveResultSet } from './transformers/participations';
import {
  transformParticipationByAgeGroupResultSet,
  transformParticipationByConversationResultSet,
  transformParticipationByGenderResultSet,
  transformUserStatisticResultSet,
} from './transformers/participations';
import { transformSurveyCountByMonthAndDayResultSet } from './transformers/surveys';

// default no transform
const transform = (data) => data;


export const commentCountByConversation = {
  type: 'commentCountByConversation',
  query: (forumUuid) => ({
    "measures": [
      "CommentsDenorm.count"
    ],
    "timeDimensions": [
      {
        "dimension": "CommentsDenorm.time"
      }
    ],
    "dimensions": [
      "CommentsDenorm.conversation"
    ],
    "filters": [
      {
        "dimension": "CommentsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      }
    ]
  }),
  transform: transformCommentCountByConversationResultSet,
  default: {},
};


export const commentCountByDimPairAndSentiment = {
  type: 'commentCountByDimPairAndSentiment',
  // Added forumUuid to deal with moodtable view module in forumsboard view
  query: (dim1, dim2, topic, clientUuid, forumUuid, date_range) => {
    const timeDimensions = {
      "dimension": "CommentTopicsDenorm.time"
    };
    if(date_range.startDate){
      timeDimensions.dateRange = [date_range.startDate, date_range.endDate];
    };
    const filters = [];

    if (topic !== null && topic !== undefined) {
      filters.push(
        {
          "dimension": "CommentTopicsDenorm.word",
          "operator": "equals",
          "values": [topic]
        }
      );
    }

    if (clientUuid) {
      filters.push({
        "dimension": "CommentTopicsDenorm.clientUuid",
        "operator": "equals",
        "values": [ clientUuid ]
      });
    }

    if (forumUuid) {
      filters.push({
        "dimension": "CommentTopicsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      });
    }

    let query = {
      "measures": [
        "CommentTopicsDenorm.count"
      ],
      "timeDimensions": [
        timeDimensions
      ],
      "dimensions": [
        dim1,
        dim2,
        "CommentTopicsDenorm.sentiment"
      ],
      "filters": filters,
    };

    //console.log('commentCountByDimPairAndSentiment->query', query);
    return query;
  },
  transform,
  default: [],
};


export const commentCountByMonthAndDay = {
  type: 'commentCountByMonthAndDay',
  query: (clientUuid, forumUuid) => {
    const filters = [
      {
        "dimension": "CommentsDenorm.clientUuid",
        "operator": "equals",
        "values": [clientUuid]
      }
    ];
    if (forumUuid) {
      filters.push({
        "dimension": "CommentsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      });
    }
    return ({
      "measures": [
        "CommentsDenorm.count"
      ],
      "timeDimensions": [
        {
          "dimension": "CommentsDenorm.time"
        }
      ],
      "dimensions": [
        "CommentsDenorm.month",
        "CommentsDenorm.day"
      ],
      "filters": filters
    });

  },
  transform: transformCommentCountByMonthAndDayResultSet,
  default: {},
};


export const commentCountByMonthDayAndSentiment = {
  type: 'commentCountByMonthDayAndSentiment',
  query: (clientUuid, forumUuid) => {
    const filters = [
      {
        "dimension": "CommentsDenorm.clientUuid",
        "operator": "contains",
        "values": [clientUuid]
      }
    ];
    if (forumUuid) {
      filters.push({
        "dimension": "CommentsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      });
    }

    return ({
    "measures": [
      "CommentsDenorm.count"
    ],
    "dimensions": [
      "CommentsDenorm.month",
      "CommentsDenorm.day",
      "CommentsDenorm.sentiment"
    ],
    "timeDimensions": [
      {
        "dimension": "CommentsDenorm.time",
        "granularity": "day",
        "dateRange": "Last 180 days"
      }
    ],
    "filters": filters
    });
  },
  transform: transformCommentCountByMonthDayAndSentimentResultSet,
  default: [],
};


export const commentCountBySentiment = {
  type: 'commentCountBySentiment',
  query: (forumUuid) => ({
    "measures": [
      "CommentsDenorm.count"
    ],
    "timeDimensions": [
      {
        "dimension": "CommentsDenorm.time"
      }
    ],
    "dimensions": [
      "CommentsDenorm.sentiment"
    ],
    "filters": [
      {
        "dimension": "CommentsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      }
    ]
  }),
  transform: transformCommentCountBySentimentResultSet,
  default: null,
};


export const commentCountByTopic = {
  type: 'commentCountByTopic',
  query: (clientUuid) => ({
    "measures": [
      "CommentTopicsDenorm.count"
    ],
    "timeDimensions": [],
    "dimensions": [
      "CommentTopicsDenorm.wordUuid",
      "CommentTopicsDenorm.word"
    ],
    "filters": [
      {
        "dimension": "CommentTopicsDenorm.clientUuid",
        "operator": "equals",
        "values": [clientUuid]
      }
    ]
  }),
  transform: transformCommentTopicCountByTopicResultSet,
  default: [],
};


export const commentCountByTopicAndSentiment = {
  type: 'commentCountByTopicAndSentiment',
  query: (dimUuidMap, date_range) => {
    const timeDimensions = {
      "dimension": "CommentTopicsDenorm.time"
    };
    if(date_range.startDate){
      timeDimensions.dateRange = [date_range.startDate, date_range.endDate];
    };
    const filters = [];
    for (const [key, uuid] of Object.entries(dimUuidMap)) {
      if (uuid && uuid.indexOf('all') === -1) {
        filters.push({
          "dimension": "CommentTopicsDenorm." + key,
          "operator": "equals",
          "values": [uuid]
        })
      }
    }

    return {
      "measures": [
        "CommentTopicsDenorm.count"
      ],
      "timeDimensions": [
        timeDimensions
      ],
      "dimensions": [
        "CommentTopicsDenorm.word",
        "CommentTopicsDenorm.sentiment"
      ],
      "filters": filters
    };
  },
  transform,
  default: [],
};


export const commentText = {
  type: 'commentText',
  query: (topic, sentiments, primarySort, limit, forumUuid) => {
    const _limit = limit || 50;
    const filters = [];
    if (forumUuid) {
      filters.push({
        "dimension": "CommentTopicsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      });
    }
    if (topic) {
      filters.push({
        "dimension": "CommentTopicsDenorm.word",
        "operator": "equals",
        "values": [topic]
      })
    }
    if (sentiments && sentiments.length) {
      filters.push({
        "dimension": "CommentTopicsDenorm.sentiment",
        "operator": "equals",
        "values": sentiments
      })
    }
    return {
      "measures": [
        "CommentTopicsDenorm.numberLikes",
        "CommentTopicsDenorm.numberDislikes",
        "CommentTopicsDenorm.numberThanksForSharing",
        "CommentTopicsDenorm.numberGoodRating"
      ],
      "timeDimensions": [
        {
          "dimension": "CommentTopicsDenorm.time"
        }
      ],
      "dimensions": [
        "CommentTopicsDenorm.client",
        "CommentTopicsDenorm.forum",
        "CommentTopicsDenorm.sentiment",
        "CommentTopicsDenorm.text",
        "CommentTopicsDenorm.word"
      ],
      "filters": filters,
      "limit": _limit,
      "order": {
        [primarySort]: "desc"
      }
    }
  },
  transform: transformCommentTextResultSet,
  default: [],
};


export const dailyCommentCount = {
  type: 'dailyCommentCount',
  query: (clientUuid) => ({
    "timeDimensions": [
      {
        "dimension": "CommentsDenorm.time",
        "granularity": "day"
      }
    ],
    "measures": [
      "CommentsDenorm.count"
    ],
    "filters": [
      {
        "dimension": "CommentsDenorm.clientUuid",
        "operator": "equals",
        "values": [clientUuid]
      }
    ]
  }),
  transform,
  default: [],
};


export const dailyRegistrations = {
  type: 'dailyRegistrations',
  query: (clientUuid) => ({
    "dimensions": [
      "ParticipationsDenorm.registered"
    ],
    "timeDimensions": [
      {
        "dimension": "ParticipationsDenorm.time",
        "granularity": "day"
      }
    ],
    "measures": [
      "ParticipationsDenorm.count"
    ],
    "filters": [
      {
        "dimension": "ParticipationsDenorm.registered",
        "operator": "equals",
        "values": [
          "1"
        ]
      },
      {
        "dimension": "ParticipationsDenorm.clientUuid",
        "operator": "equals",
        "values": [clientUuid]
      }
    ]
  }),
  transform,
  default: [],
};


export const dimensionsList = {
  type: 'dimensionsList',
  // Added forumUuid to deal with explore data module in forumsboard view
  query: (dim, clientUuid, forumUuid) => {
    const filters = [];
    if (clientUuid) {
      filters.push({
        "dimension": "CommentTopicsDenorm.clientUuid",
        "operator": "equals",
        "values": [clientUuid]
      })
    }
    if (forumUuid) {
      filters.push({
        "dimension": "CommentTopicsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      })
    }
    return ({
      "dimensions": [
        dim,
      ],
      "filters": filters,
    });
  },
  transform,
  default: [],
};


export const forumCommentCountByTopic = {
  type: 'forumCommentCountByTopic',
  query: (forumUuid) => ({
    "measures": [
      "CommentTopicsDenorm.count"
    ],
    "timeDimensions": [
      {
        "dimension": "CommentTopicsDenorm.time"
      }
    ],
    "dimensions": [
      "CommentTopicsDenorm.wordUuid",
      "CommentTopicsDenorm.word"
    ],
    "filters": [
      {
        "dimension": "CommentTopicsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      }
    ]
  }),
  transform: transformCommentTopicCountByTopicResultSet,
  default: [],
};

export const emojifyByTopicAndSentiment = {
  type: 'emojifyByTopicAndSentiment',
  query: (clientUuid, forumUuid) => {
    const filters = [];
    if (clientUuid) {
      filters.push({
        "dimension": "CommentTopicsDenorm.clientUuid",
        "operator": "equals",
        "values": [clientUuid]
      });
    }
    if (forumUuid) {
      filters.push({
        "dimension": "CommentTopicsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      });
    }

    return {
      "measures": [
        "CommentTopicsDenorm.count"
      ],
      "timeDimensions": [
        {
          "dimension": "CommentTopicsDenorm.time"
        }
      ],
      "dimensions": [
        "CommentTopicsDenorm.word",
        "CommentTopicsDenorm.sentiment"
      ],
      "filters": filters
    }
  },
  transform: transformEmojifyByTopicAndSentimentResultSet,
  default: []

};



export const forumsList = {
  type: 'forumsList',
  query: (clientUuid) => ({
    "dimensions": [
      "CommentsDenorm.forumUuid",
      "CommentsDenorm.forum"
    ],
    "timeDimensions": [],
    "filters": [
      {
        "dimension": "CommentsDenorm.clientUuid",
        "operator": "equals",
        "values": [clientUuid]
      }
    ]
  }),
  transform: transformForumsListResultSet,
  default: [],
};


export const participantsByLocation = {
  type: 'participantsByLocation',
  query: (forumUuid) => ({
    "measures": [
      "ParticipantsDenorm.count"
    ],
    "timeDimensions": [
      {
        "dimension": "ParticipantsDenorm.time"
      }
    ],
    "dimensions": [
      "ParticipantsDenorm.surveyLocation"
    ],
    // TODO: currently there's no "ParticipantsDenorm.forumUuid" or "ParticipantsDenorm.clientUuid" dimension
    "filters": [
      // {
      //   "dimension": "ParticipantsDenorm.forumUuid",
      //   "operator": "equals",
      //   "values": [forumUuid]
      // },
    ]
  }),
  transform: transformParticipantsByLocationResultSet,
  default: [],
};



const getParticipationsByDimAndActiveQuery = (clientUuid, convUuid, dim) => {
  const filters = [
    {
      "dimension": "ParticipationsDenorm.clientUuid",
      "operator": "equals",
      "values": [clientUuid]
    }
  ];
  if (convUuid && (convUuid.indexOf('all') === -1)) {
    filters.push({
      "dimension": "ParticipationsDenorm.conversation",
      "operator": "contains",
      "values": [
        convUuid
      ]
    });
  }
  return {
    "measures": [
      "ParticipationsDenorm.count"
    ],
    "timeDimensions": [
      {
        "dimension": "ParticipationsDenorm.time"
      }
    ],
    "dimensions": [
      "ParticipationsDenorm." + dim,
      "ParticipationsDenorm.active"
    ],
    "filters": filters
  };
}


export const participationsByGenderAndActive = {
  type: 'participationsByGenderAndActive',
  query: getParticipationsByDimAndActiveQuery,
  transform: transformParticipationsByDimAndActiveResultSet,
  default: [],
};


export const participationsByAgeAndActive = {
  type: 'participationsByAgeAndActive',
  query: getParticipationsByDimAndActiveQuery,
  transform: transformParticipationsByDimAndActiveResultSet,
  default: [],
};


export const participationsByPositionAndActive = {
  type: 'participationsByPositionAndActive',
  query: getParticipationsByDimAndActiveQuery,
  transform: transformParticipationsByDimAndActiveResultSet,
  default: [],
};


export const participationsByLocationAndActive = {
  type: 'participationsByLocationAndActive',
  query: getParticipationsByDimAndActiveQuery,
  transform: transformParticipantsByLocationResultSet,
  default: [],
};


export const participationByAgeGroup = {
  type: 'participationByAgeGroup',
  query: (clientUuid, forumUuid) => {
    const filters = [
      {
        "dimension": "ParticipationsDenorm.clientUuid",
        "operator": "equals",
        "values": [clientUuid]
      }
    ];
    if (forumUuid) {
      filters.push({
        "dimension": "ParticipationsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      });
    };
    return ({
    "measures": [
      "ParticipationsDenorm.count"
    ],
    "timeDimensions": [
      {
        "dimension": "ParticipationsDenorm.time"
      }
    ],
    "dimensions": [
      "ParticipationsDenorm.surveyAgeGroup"
    ],
    "filters": filters
  });
  },
  transform: transformParticipationByAgeGroupResultSet,
  default: [],
};


export const participantsByConversation = {
  type: 'participantsByConversation',
  query: (forumUuid) => ({
    "measures": [
      "ParticipationsDenorm.count"
    ],
    "timeDimensions": [
      {
        "dimension": "ParticipationsDenorm.time"
      }
    ],
    "dimensions": [
      "ParticipationsDenorm.conversation"
    ],
    "filters": [
      {
        "dimension": "ParticipationsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      }
    ]
  }),
  // Wait until `ParticipantsDenorm.conversation` and `ParticipantsDenorm.forumUuid` can be queried
  // query: (forumUuid) => ({
  //   "measures": [
  //     "ParticipantsDenorm.count"
  //   ],
  //   "timeDimensions": [
  //     {
  //       "dimension": "ParticipantsDenorm.time"
  //     }
  //   ],
  //   "dimensions": [
  //     "ParticipantsDenorm.conversation"
  //   ],
  //   "filters": [
  //     {
  //       "dimension": "ParticipantsDenorm.forumUuid",
  //       "operator": "equals",
  //       "values": [forumUuid]
  //     }
  //   ]
  // }),
  transform: transformParticipationByConversationResultSet,
  default: [],
};


export const participationByGender = {
  type: 'participationByGender',
  query: (clientUuid, forumUuid) => {
    const filters = [
      {
        "dimension": "ParticipationsDenorm.clientUuid",
        "operator": "equals",
        "values": [clientUuid]
      }
    ];
    if (forumUuid) {
      filters.push({
        "dimension": "ParticipationsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      });
    }
    return ({
    "measures": [
      "ParticipationsDenorm.count"
    ],
    "timeDimensions": [
      {
        "dimension": "ParticipationsDenorm.time"
      }
    ],
    "dimensions": [
      "ParticipationsDenorm.surveyGender"
    ],
    "filters": filters
    });
  },
  transform: transformParticipationByGenderResultSet,
  default: [],
};


export const participationByRegisteredStatus = {
  type: 'participationByRegisteredStatus',
  query: (forumUuid) => ({
    "measures": [
      "ParticipationsDenorm.count"
    ],
    "timeDimensions": [
      {
        "dimension": "ParticipationsDenorm.time"
      }
    ],
    "dimensions": [
      "ParticipationsDenorm.registered"
    ],
    "filters": [
      {
        "dimension": "ParticipationsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      }
    ]
  }),
  transform: transformUserStatisticResultSet('registered'),
  default: {},
};

export const participationByActiveStatus = {
  type: 'participationByActiveStatus',
  query: (forumUuid) => ({
    "measures": [
      "ParticipationsDenorm.count"
    ],
    "timeDimensions": [
      {
        "dimension": "ParticipationsDenorm.time"
      }
    ],
    "dimensions": [
      "ParticipationsDenorm.active"
    ],
    "filters": [
      {
        "dimension": "ParticipationsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      }
    ]
  }),
  transform: transformUserStatisticResultSet('active'),
  default: {},
};

export const participationBySentStatus = {
  type: 'participationBySentStatus',
  query: (forumUuid) => ({
    "measures": [
      "ParticipationsDenorm.count"
    ],
    "timeDimensions": [
      {
        "dimension": "ParticipationsDenorm.time"
      }
    ],
    "dimensions": [
      "ParticipationsDenorm.sent"
    ],
    "filters": [
      {
        "dimension": "ParticipationsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      }
    ]
  }),
  transform: transformUserStatisticResultSet('sent'),
  default: {},
};


export const participationByValidatedStatus = {
  type: 'participationByValidatedStatus',
  query: (forumUuid) => ({
    "measures": [
      "ParticipationsDenorm.count"
    ],
    "timeDimensions": [
      {
        "dimension": "ParticipationsDenorm.time"
      }
    ],
    "dimensions": [
      "ParticipationsDenorm.validated"
    ],
    "filters": [
      {
        "dimension": "ParticipationsDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      }
    ]
  }),
  transform: transformUserStatisticResultSet('validated'),
  default: {},
};


export const surveyCountByMonthAndDay = {
  type: 'surveyCountByMonthAndDay',
  query: (clientUuid, forumUuid) => {
    const filters = [
      {
        "dimension": "SurveysDenorm.clientUuid",
        "operator": "contains",
        "values": [clientUuid]
      }
    ];
    if (forumUuid) {
      filters.push({
        "dimension": "SurveysDenorm.forumUuid",
        "operator": "equals",
        "values": [forumUuid]
      });
    }
    return ({
      "measures": [
        "SurveysDenorm.count"
      ],
      "timeDimensions": [
        {
          "dimension": "SurveysDenorm.time"
        }
      ],
      "dimensions": [
        "SurveysDenorm.month",
        "SurveysDenorm.day"
      ],
      "filters": filters
    })
  },
  transform: transformSurveyCountByMonthAndDayResultSet,
  default: {},
};
