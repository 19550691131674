import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  Form,
  Input,
  Select,
  Modal,
  DatePicker,
  Space,
  Button,
  Drawer,
  Switch,
  Collapse,
  Col,
  Row,
} from "antd";
import _ from "lodash";
import * as d3 from "d3";
import { zoom } from "d3-zoom";
import NetworkGraphFilters from "./NetworkGraphFilters";
import "./style.css";

export default () => {
  const ref = useRef(null);
  const legendRef = useRef(null);
  const [graph, setGraph] = useState([]);
  const [open, setOpen] = useState(false);
  const [bgColor, setBgColor] = useState(false);
  const [isGrouped, setIsGrouped] = useState(false);
  const [centralityGroup, setCentralityGroup] = useState("");

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const hideBackgroundColor = () => {
    setBgColor(!bgColor);
    console.log(bgColor);
    createSVG();
  };

  const onFiltersChange = (data, centralityGroup) => {
    setCentralityGroup(centralityGroup);
    setGraph(data);
  };

  const scaleRadius = (r) => {
    console.log(10 + ((r - 1) / (10000 - 1)) * 100);
    return 10 + ((r - 1) / (10000 - 1)) * 100;
  };
  const tooltipRef = useRef();

  const createSVG = () => {
    const svgE1 = d3.select(ref.current);
    const svgE2 = d3.select(legendRef.current);
    svgE1.selectAll("*").remove(); // Clear svg content before adding new elements
    svgE2.selectAll("*").remove(); // Clear svg content before adding new elements

    const margin = {
      top: 5,
      right: 5,
      bottom: 5,
      left: 5,
    };

    const w = ref.current.clientWidth;
    const h = ref.current.clientHeight;
    // Dimensions of the element
    let dimensions = {
      width: w - margin.left - margin.right,
      height: h - margin.top - margin.bottom,
      svgWidth: w,
      svgHeight: h,
    };

    // Creates the SVG
    var svg = svgE1
        .append("svg")
        .attr("width", dimensions.svgWidth)
        .attr("height", dimensions.svgHeight)
        .attr("border", 1),
      width = +svg.attr("width"),
      height = +svg.attr("height"),
      color = d3.scaleOrdinal(d3.schemeCategory10);

    var voronoi = d3
      .voronoi()
      .x(function (d) {
        return d.x;
      })
      .y(function (d) {
        return d.y;
      })
      .extent([
        [-1, -1],
        [width + 1, height + 1],
      ]);

    let container = svg.append("g");
    let isDragStart = false;

    let currentScale = 1;
    let fixedNodes = [];

    var simulation = d3
      .forceSimulation()
      .force("x", d3.forceX(dimensions.width / 2))
      .force("y", d3.forceY(dimensions.height / 2))
      .force(
        "link",
        d3
          .forceLink()
          .id(function (d) {
            return d.id;
          })
          .strength(function (link) {
            if (link.source.group === link.source.target) {
              return 1;
            } else {
              return 0.1;
            }
          })
      )
      .force("charge", d3.forceManyBody().strength(-100))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .on("end", () => (isDragStart = false));

    var link = svg
      .append("g")
      .attr("class", "links")
      .selectAll("line")
      .data(graph.links)
      .enter()
      .append("line")
      .attr("stroke-width", function (d) {
        return 1;
      });

    var node = svg
      .append("g")
      .attr("class", "nodes")
      .selectAll("circle")
      .data(graph.nodes)
      .enter()
      .append("circle")
      .attr("r", (d) => {
        return scaleRadius(d[centralityGroup]);
      })
      .attr("fill", function (d) {
        return color(d.group);
      })

      .call(
        d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      );

    // Labels for the nodes
    let labelNode = svg
      .append("g")
      .attr("class", "labelNodes")
      .selectAll("text.label")
      .data(graph.nodes)
      .enter()
      .append("text")
      .attr("text-anchor", "middle")
      .text((node) => node.group)
      .classed("networkMap-font", true)
      .style("font-family", "Arial")
      .style("font-size", 15)
      .style("pointer-events", "none"); // to prevent mouseover/drag capture

    //  Base zoom transformation
    const zoomRect = svg
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .style("fill", "none");
    // .style("pointer-events", "all");

    const zoomed = (d) => {
      currentScale = d3.event.transform.k;
      node.attr("transform", d3.event.transform);
      link.attr("transform", d3.event.transform);
      labelNode.attr("transform", d3.event.transform);
      return zoomRect.attr("transform", d3.event.transform);
    };

    // set the minimum and maximum zoom levels with .scaleExtent([0.3, 2]).
    const setZoom = zoom().on("zoom", zoomed).scaleExtent([0.3, 2]);

    // Applies the zoop features to the SVG
    svg.call(setZoom);

    // Disable the zoom in property on double click
    svg.on("dblclick.zoom", null);

    // Button Zoom in and out
    d3.select("#zoom_out").on("click", () =>
      setZoom.scaleBy(svg.transition(), 0.8)
    );
    d3.select("#zoom_in").on("click", () => {
      setZoom.scaleBy(svg.transition(), 1.2);
    });

    // Resets everything on the screen
    d3.select("#reset").on("click", () => {
      currentScale = 1;
      svg.call(setZoom.transform, d3.zoomIdentity);
      unpinNodes(undefined);
      unselect();
    });

    //Initialize legend
    var legendItemSize = 12;
    var legendSpacing = 50;
    var xOffset = 10;
    var yOffset = 100;
    // Get Unique legend values
    let nodesgroup = [...new Set(graph.nodes.map((node) => node.group))];

    var legend = svgE2
      .append("svg")
      .attr("width", legendRef.current.clientWidth)
      .attr("height", legendRef.current.clientHeight)
      //.select("#legend")
      .selectAll(".legendItem")
      .data(nodesgroup);

    //Create legend items
    legend
      .enter()
      .append("rect")
      .attr("class", "legendItem")
      .attr("width", legendItemSize)
      .attr("height", legendItemSize)
      .attr("fill", function (d) {
        return color(d);
      })
      .attr("transform", (d, i) => {
        var x = xOffset;
        var y = yOffset + ((legendItemSize + legendSpacing) * i) / 2;
        return `translate(${x}, ${y})`;
      });

    //Create legend labels
    legend
      .enter()
      .append("text")
      .attr("x", xOffset + legendItemSize + 5)
      .attr(
        "y",
        (d, i) => yOffset + ((legendItemSize + legendSpacing) * i) / 2 + 12
      )

      .text((d) => d);
    simulation.nodes(graph.nodes).on("tick", ticked);

    simulation.force("link").links(graph.links).distance(200);

    // append voronoi
    var cells = svg
      .selectAll()
      .data(simulation.nodes())
      .enter()
      .append("g")
      .attr("fill", function (d) {
        return color(d.group);
      })
      .attr("class", function (d) {
        return d.group;
      });

    var cell = cells.append("graphPath").data(voronoi.polygons(simulation.nodes()));
    function ticked() {
      var alpha = this.alpha();

      var coords = {};
      var groups = [];

      // // sort the nodes into groups:

      node.each(function (d) {
        if (groups.indexOf(d.group) === -1) {
          groups.push(d.group);
          coords[d.group] = [];
        }

        coords[d.group].push({ x: d.x, y: d.y });
      });

      // get the centroid of each group:
      var centroids = {};

      for (var group in coords) {
        var groupNodes = coords[group];

        var n = groupNodes.length;
        var cx = 0;
        var tx = 0;
        var cy = 0;
        var ty = 0;

        groupNodes.forEach(function (d) {
          tx += d.x;
          ty += d.y;
        });

        cx = tx / n;
        cy = ty / n;

        centroids[group] = { x: cx, y: cy };
      }

      // don't modify points close the the group centroid:
      var minDistance = 20;

      if (alpha < 0.1) {
        minDistance = 10 + 1000 * (0.2 - alpha); // change this to increase the distance between nodes
      }

      if (isGrouped) {
        // adjust each point if needed towards group centroid:
        node.each(function (d) {
          var cx = centroids[d.group].x;
          var cy = centroids[d.group].y;
          var x = d.x;
          var y = d.y;
          var dx = cx - x;
          var dy = cy - y;

          var r = Math.sqrt(dx * dx + dy * dy);

          if (r > minDistance) {
            d.x = x * 0.9 + cx * 0.1;
            d.y = y * 0.9 + cy * 0.1;
          }
        });
      } else {
        node.attr("cx", (node) => node["x"]).attr("cy", (node) => node["y"]);
      }

      if (bgColor) {
        // update voronoi:
        cell = cell
          .data(voronoi.polygons(simulation.nodes()))
          .attr("d", renderCell);
      }

      // update links:
      link
        .attr("x1", function (d) {
          var x = d.source.x;
          var y = d.source.y;
          let radius = 30;

          // Constrain the link's start point so that it cannot go outside the SVG boundary
          x = Math.max(radius, Math.min(width - radius, x));
          y = Math.max(radius, Math.min(height - radius, y));

          return x;
        })
        .attr("y1", function (d) {
          var x = d.source.x;
          var y = d.source.y;
          let radius = 30;

          // Constrain the link's start point so that it cannot go outside the SVG boundary
          x = Math.max(radius, Math.min(width - radius, x));
          y = Math.max(radius, Math.min(height - radius, y));

          return y;
        })
        .attr("x2", function (d) {
          var x = d.target.x;
          var y = d.target.y;
          let radius = 30;

          // Constrain the link's end point so that it cannot go outside the SVG boundary
          x = Math.max(radius, Math.min(width - radius, x));
          y = Math.max(radius, Math.min(height - radius, y));

          return x;
        })
        .attr("y2", function (d) {
          var x = d.target.x;
          var y = d.target.y;
          let radius = 30;

          // Constrain the link's end point so that it cannot go outside the SVG boundary
          x = Math.max(radius, Math.min(width - radius, x));
          y = Math.max(radius, Math.min(height - radius, y));

          return y;
        });

      // update nodes:
      node
        .attr("cx", function (d) {
          var x = d.x;
          var y = d.y;
          let radius = 30;

          // Constrain the node's position so that it cannot be dragged outside the SVG boundary
          x = Math.max(radius, Math.min(width - radius, x));
          y = Math.max(radius, Math.min(height - radius, y));

          return x;
        })
        .attr("cy", function (d) {
          var x = d.x;
          var y = d.y;
          let radius = 30;

          // Constrain the node's position so that it cannot be dragged outside the SVG boundary
          x = Math.max(radius, Math.min(width - radius, x));
          y = Math.max(radius, Math.min(height - radius, y));

          return y;
        });

      // Should be calculated based of collision detection
      labelNode
        .attr("x", function (d) {
          var x = d.x;
          let offset = 40;

          // Constrain the labelNode's x position so that it cannot go outside the SVG boundary
          x = Math.max(offset, Math.min(width - offset, x));

          return x;
        })
        .attr("y", function (d) {
          var y = d.y;
          let offset = 40;

          // Constrain the labelNode's y position so that it cannot go outside the SVG boundary
          y = Math.max(offset, Math.min(height - offset, y + offset));

          return y;
        });
    }

    //drag nodes
    function dragstarted(d) {
      if (!d3.event.active) simulation.alphaTarget(0.3).restart();
      var x = d.x;
      var y = d.y;
      let radius = 30;

      // Constrain the node's position so that it cannot be dragged outside the SVG boundary
      x = Math.max(radius, Math.min(width - radius, x));
      y = Math.max(radius, Math.min(height - radius, y));

      d.cx = x;
      d.cy = y;
    }

    function dragged(d) {
      var x = d3.event.x;
      var y = d3.event.y;
      let radius = 30;

      // Constrain the node's position so that it cannot be dragged outside the SVG boundary
      x = Math.max(radius, Math.min(width - radius, x));
      y = Math.max(radius, Math.min(height - radius, y));

      d.fx = x;
      d.fy = y;
    }

    function dragended(d) {
      if (!d3.event.active) simulation.alphaTarget(0);
    }

    // Method to close the slide out, remove selected outline, and unfocus and selection.
    const unselect = () => {
      //container.selectAll(".selectedCircle").remove();
      simulation.alphaTarget(0);
      //unfocus();
    };

    // Unpin node(s)
    const unpinNodes = (selectedNode) => {
      if (selectedNode === undefined) {
        node.each((n) => (n["fx"] = n["fy"] = null));
        // svg.selectAll("circle").classed("networkMap-highlightedNode", false);
      }
      // else {
      //   // If the node is fixed, don't release
      //   if (!fixedNodes.includes(selectedNode["id"])) {
      //     node.each((n) => {
      //       if (selectedNode["id"] === n["id"]) {
      //         n["fx"] = n["fy"] = null;
      //       }
      //     });
      //   }
      // }

      simulation.alpha(0.3).restart();
    };
  };

  function renderCell(d) {
    return d == null ? null : "M" + d.join("L") + "Z";
  }
  useEffect(() => {
    if (graph && Object.keys(graph).length > 0) createSVG();
  }, [graph, bgColor]);

  return (
    <>
      <div style={{ margin: "20px 0 20px 0", width: "100%", height: "80%" }}>
        <NetworkGraphFilters
          open={open}
          onClose={onClose}
          onChange={onFiltersChange}
          setIsGrouped={setIsGrouped}
        />
        <Space>
          <Button id="zoom_in"> Zoom In</Button>
          <Button id="zoom_out"> Zoom Out</Button>
          <Button id="reset"> Reset</Button>
          <Button id="filters" onClick={showDrawer}>
            {" "}
            Filters
          </Button>
          {bgColor && (
            <Button id="hideColor" onClick={(e) => setBgColor(false)}>
              {" "}
              Hide BG Color
            </Button>
          )}
          {!bgColor && (
            <Button id="hideColor" onClick={(e) => setBgColor(true)}>
              {" "}
              Show BG Color
            </Button>
          )}
        </Space>
        <div className="tooltip" ref={tooltipRef} />
        <Row>
          <Col span={3}>
            {" "}
            <div
              id="legend"
              ref={legendRef}
              style={{ minHeight: "800px" }}
            ></div>
          </Col>
          <Col span={21}>
            <div
              ref={ref}
              style={{
                margin: "20px 0 20px 0",
                width: "100%",
                minHeight: "800px",
              }}
            ></div>
          </Col>
        </Row>
      </div>
    </>
  );
};
