import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import TopicVis from '@markmo/ldavis';

import { ForumIdContext } from '../context/forumId-context';
import { fetchLdaData } from './actions/lda';
import Loading from '../components/Loading';
import NoData from '../components/NoData';
import Widget from '../components/Widget';
import TopicVis from "../components/ChartVisualisations/TopicVis";
function LDAWidget(props) {

  const [ data, setData ] = useState([]);
  const [ loaded, setLoaded ] = useState(false);

  const { forumId } = useContext(ForumIdContext);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      let zForumId = props.forumId ? props.forumId : (props.useContextRules !== false) ? forumId : 'all';
      zForumId = (zForumId == 'all') ? '' : zForumId;
      //let zDateRange = (props.startDate) ? { startDate: props.startDate, endDate: props.endDate } : date_range.data;

      let result = await dispatch(fetchLdaData({ clientId: user.client_id, forumId: zForumId }));
      if (result) {
        setData(result.payload);
      }
      setLoaded(true);
    })();
  }, [user && user.client_id, forumId, props.forumId]);

  const handleSelect = (topicNum) => {
    const texts = data.texts[topicNum].map((text) => ({
      chunk_uuid: '',
      comment_uuid: '',
      emoji_label: '',
      sentiment: '',
      text,
    }));
    history.push('/explore/search-results?from=lda&topicNum=' + topicNum);
  };

  return (
    <Widget title={ props.title ? props.title : "Emojify View" } actions={ props.actions } showExploreLink={ false }>
      {loaded ? (
        data.data ?
          <TopicVis data={data.data} onSelect={handleSelect} />
          :
          <NoData />
        )
        :
        <Loading />
      }
    </Widget>
  );
}

export default LDAWidget;