import axios from '../../http';

export const FETCH_DASHBOARD_LOADING = 'FETCH_DASHBOARD_LOADING';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';
export const SAVE_DASHBOARD_LOADING = 'SAVE_DASHBOARD_LOADING';
export const SAVE_DASHBOARD_SUCCESS = 'SAVE_DASHBOARD_SUCCESS';
export const SAVE_DASHBOARD_FAILURE = 'SAVE_DASHBOARD_FAILURE';

function fetchDashboardLoading() {
  return {
    type: FETCH_DASHBOARD_LOADING,
  };
};

function fetchDashboardSuccess(payload) {
  return {
    type: FETCH_DASHBOARD_SUCCESS,
    payload,
  };
};

function fetchDashboardFailure(payload) {
  return {
    type: FETCH_DASHBOARD_FAILURE,
    payload,
  };
};

export function fetchDashboardData() {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(fetchDashboardLoading());
        const request = await axios.get('/api/v1/dashboards/');
        if (request.status !== 200) {
          throw Error('Error fetching dashboards: ' + request.statusText);
        }
        let result = request.data.data;
        dispatch(fetchDashboardSuccess(result));
        resolve(result);
      } catch (error) {
        let result = {
          error: {
            type: typeof error,
            message: error.toString(),
          },
        };
        dispatch(fetchDashboardFailure(result));
        reject(result);
      }
    });
  };
};


function saveDashboardLoading() {
  return {
    type: SAVE_DASHBOARD_LOADING,
  };
};

function saveDashboardSuccess(payload) {
  return {
    type: SAVE_DASHBOARD_SUCCESS,
    payload,
  };
};

function saveDashboardFailure(payload) {
  return {
    type: SAVE_DASHBOARD_FAILURE,
    payload,
  };
};


export function saveDashboardData(payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(saveDashboardLoading());
        const url = (payload.created_date) ? `/api/v1/dashboards/${payload.id}/` : '/api/v1/dashboards/';
        const request = axios[(payload.created_date) ? 'patch' : 'post'];
        const response = request(url, payload);
        if (response.status !== 200) {
          throw Error('Error saving dashboards: ' + response.statusText);
        }
        let result = {
          data: response.data.data,
        };
        dispatch(saveDashboardSuccess(result));
        resolve(result);
      } catch (error) {
        let result = {
          error: {
            type: typeof error,
            message: error.toString(),
          },
        };
        dispatch(saveDashboardFailure(result));
        reject(result);
      }
    });
  };
};