import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Card, Row, Col } from "antd";

function PowerfulStatementList({ data, findColor }) {
  const classes = useStyles();

  return (
    <>
      <Row>
        <Col style={{ paddingBottom: "25px",width:"100%" }}>
          <Card bodyStyle={{ maxHeight: "500px", overflow: "auto" }}>
            <div className={classes.centerContent}>
              {data.map((chunk, index) => (
                <div key={index} className={classes.contentItem}>
                  <div className={classes.itemText}>
                    {chunk.text}
                    {/* {JSON.parse("[" + chunk.tokens + "]")[0].map(
                      (value, index) => {
                        return (
                          <React.Fragment key={index}>
                            {value[1] !== "NN" ? (
                              <span style={{ color: findColor(value[1]) }}>
                                {value[0]}{" "}
                              </span>
                            ) : (
                              <span>{value[0]} </span>
                            )}{" "}
                          </React.Fragment>
                        );
                      }
                    )} */}
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const useStyles = makeStyles(() => ({
  card: {
    height: "100%",
    padding: "4px",
  },
  centerContent: {
    marginTop: "10px",
  },
  contentItem: {
    alignItems: "center",
    borderBottom: "1px solid #efefef",
    color: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    fontSize: 14.2,
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: 0.25,
    lineHeight: "1.41",
    padding: "14px 0",
  },
  itemIcon: {
    color: "rgba(84, 110, 122, 0.8)",
    display: "inline-block",
    marginLeft: "32px",
    opacity: 0.6,
  },
  itemNum: {
    color: "#00a4bd",
    display: "inline-block",
    fontSize: "24.4px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "normal",
    lineHeight: "normal",
    marginRight: "20px",
    opacity: 0.38,
  },
  itemText: {
    width: "90%",
    /*Shows top 5 lines and truncate rest to ellipsis*/
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  title: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: "14.2px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
  },
}));

export default PowerfulStatementList;
