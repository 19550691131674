import React, { useEffect, useState } from "react";
import _ from "lodash";
import {Rnd} from 'react-rnd';
import { Link } from "react-router-dom";
import {
  Modal,
  Button,
  Table,
  Select,
  Divider,
  Collapse,
  Input,
  Col,
  Row,
  Form,
} from "antd";
import { Descriptions } from "antd";
import {
  FileSearchOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
} from "@ant-design/icons";
import CommentSelector from "../../CommentSelector";
import EmotionViewer from "../../EmotionViewer";
import Loading from "../../Loading";
import NoData from "../../NoData";
import { SentimentToEmojiFace } from "../../SentimentToEmojiFace";

import { facets } from "../../../transformers/emoji_labels";
import "../../../components/Cards/emoticons.css";
import MainMagicWindow from '../../MagicWindow/MainMagicWindow';
import { FormattedMessage,useIntl } from "react-intl";

const { Option } = Select;
const { Panel } = Collapse;

const facetsMap = facets.reduce((a, f) => {
  a[f.label] = f.color;
  return a;
}, {});

export default function CommentList({ ...props }) {
  const [data, setData] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showChunkSelector, setShowChunkSelector] = useState(false);
  const [showEmotionViewer, setShowEmotionViewer] = useState(false);

  const [averageSentiment, setAverageSentiment] = useState(0);
  const [paginationPage, setPaginationPage] = useState(1);
  const [wordSearch, setWordSearch] = useState(0);
  const [isShowResizableModal, setIsShowResizableModal]= useState(false);
  
  const [tableSortOrder, setTableSortOrder]= useState({});

  const [form] = Form.useForm();
  const onPaginationChange = (page) => {
    setPaginationPage(page);
  };

  const intl = useIntl();
  const itemsPlaceholder = intl.formatMessage({id: 'app.explorePage.items',defaultMessage: 'items'})
  const analyzeChunksPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks',defaultMessage: 'Analyze Chunks'});
  const analyzeChunksChunkTextPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.chunkText',defaultMessage: 'Chunk text:'});
  const analyzeChunksTitlePlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.chunks',defaultMessage: 'Chunks'});
  const analyzeChunksDateSortNewest = intl.formatMessage({id: 'app.explorePage.dateSortNewest',defaultMessage: 'Newest'});
  const analyzeChunksDateSortOldest = intl.formatMessage({id: 'app.explorePage.dateSortOldest',defaultMessage: 'Oldest'});
  const analyzeChunksDateSortNone = intl.formatMessage({id: 'app.explorePage.dateSortNone',defaultMessage: 'None'});
  const okPlaceholder = intl.formatMessage({id: 'app.ok',defaultMessage: 'Ok'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  const [selectValue,setSelectValue]= useState(analyzeChunksDateSortNone);
  
  const onChange = (pagination, filters, sorter, extra) => {
    setTableSortOrder(sorter);
  };

  const selectComponentClass = () => {
    return (
      <div style={{ float: "right" }}>
              <label style={{ marginRight: "15px" }}>{dateSortPlaceholder}</label>
                <Select
                  defaultValue={analyzeChunksDateSortNone}
                  style={{ width: 120 }}
                  onChange={onSortChange}
                  value={selectValue}
                  dropdownStyle={{ zIndex: 2000 }}
                >
                  <Option value="newest">
                    {analyzeChunksDateSortNewest}
                  </Option>
                  <Option value="oldest">
                    {analyzeChunksDateSortOldest}
                  </Option>
                </Select>
      </div>
    );
  };
  //Need to pass option property to avoid a warning of invalid type option passed.
  selectComponentClass.Option = (props) => {
    return (
      <></>
    );
  };
  const paginationOptions = {
    position: ["topRight", "bottomRight"],
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} of ${total}  ${itemsPlaceholder}`,
    current: paginationPage,
    onChange: onPaginationChange,
    showSizeChanger: true,
    selectComponentClass,
    defaultPageSize: 50,
    style:{height: "55px" , background:"#f5f5f5", padding:"10px 10px"}
  };

  const sortDemographics = (list) => {
    if (list.length > 0) {
      list = [...list];
      list.sort((a, b) => {
        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;
        return 0;
      });
      list.sort((a, b) => {
        if (a.taxonomy > b.taxonomy) return 1;
        if (a.taxonomy < b.taxonomy) return -1;
        return 0;
      });
      list.sort((a, b) => {
        if (a.category > b.category) return 1;
        if (a.category < b.category) return -1;
        return 0;
      });
    }
    return list;
  };

  useEffect(() => {
    console.log("props.data", [...props.data]);

    const _data = props.data.map((doc) => {
      return {
        ...doc,
        ...{
          key: doc.comment_id || doc.comment_uuid,
          text: (
            <p>
              {doc.text.length > 300
                ? doc.text.slice(0, 300) + " ... "
                : doc.text}
            </p>
          ),
          original_text: doc.text,
        },
      };
    });
    setDataCopy(_data);
    setData(_data);
    setWordSearch(0);
    form.setFieldsValue({
      wordSearch: 0,
    });
    setAverageSentiment(
      _data
        .map((doc) => doc.sentiment)
        .reduce((partial_sum, n) => partial_sum + n, 0) / _data.length
    );
  }, [props.data]);

  const classes = {
    moodSizeControl: {
      height: "28px",
      width: "28px",
      marginTop: "5px",
    },
    tagIcon: {
      color: "#90a4ae",
    },
    tagText: {
      color: "#fff",
      fontSize: "11px",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "700",
      letterSpacing: "0.25px",
    },
    emojiWrap: {
      borderRadius: "10px",
      padding: "5px",
      textAlign: "center",
      width: "100px",
    },
  };
  // Commented code is for coloring the tokens
  //   const tags = [
  //     { value: 'JJ', color: 'red'},
  //     { value: 'JJR', color: 'red'},
  //     { value: 'JJS', color: 'red'},
  //     { value: 'RB', color: 'cyan'},
  //     { value: 'RBR', color: 'cyan'},
  //     { value: 'RBS', color: 'cyan'},
  //     { value: 'PRP', color: 'magenta'},
  //     { value: 'PRP$', color: 'magenta'},
  //     { value: 'None', color: 'off'}
  // ];

  //   const findColor =(value) =>{
  //     const tag = tags.find(tag=> tag.value == value);
  //     return tag && tag.color;
  // }
  const showResizableModal= () =>{
    setIsShowResizableModal(!isShowResizableModal)
  }

  const handleCommentInspectClick = (item, recurred) => {
    (async () => {
      setModalIsVisible(true);
      if (item.original_text) {
        const cleanCommentText = item.original_text
          .replaceAll("\t\t", "\t")
          .replaceAll("\t ", "\t")
          .replace(/\s+/gi, " ")
          .replace(/\s+/gi, " ");
        setModalBody(
          <p style={{ whiteSpace: "pre-line" }}>{cleanCommentText}</p>
        );
      } else {
        setModalBody(<NoData />);
      }
    })();
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(
        props.data.filter(
          (doc) =>
            selectedRowKeys.indexOf(doc.comment_id || doc.comment_uuid) > -1
        )
      );
      console.log(
        props.data.filter(
          (doc) =>
            selectedRowKeys.indexOf(doc.comment_id || doc.comment_uuid) > -1
        )
      );
    },
  };

  const chunkIdPlaceholder = (
    <FormattedMessage id="app.explorePage.chunkId" defaultMessage="chunk id" />
  );
  const commentPlaceholder = (
    <FormattedMessage
      id="app.explorePage.commentId"
      defaultMessage="comment id"
    />
  );

  const commentDatePlaceholder = (
    <FormattedMessage
      id="app.explorePage.commentDate"
      defaultMessage="comment date"
    />
  );

  const subProjectPlaceholder = (
    <FormattedMessage
      id="app.explorePage.subProject"
      defaultMessage="Sub-Project"
    />
  );

  const userIdPlaceholder = (
    <FormattedMessage id="app.explorePage.userId" defaultMessage="user id" />
  );

  const noDemographicsPlaceholder = (
    <FormattedMessage
      id="app.explorePage.noDemographics"
      defaultMessage="No demographics attached"
    />
  );
  const emotionPlaceholder =<FormattedMessage
  id="app.explorePage.emotions"
  defaultMessage="Emotion"/>

  const sentimentPlaceholder=<FormattedMessage
  id="app.explorePage.sentiment"
  defaultMessage="Sentiment"/>

  const columns = [
    {
      key: "text",
      title: () => (
        <Button.Group>
          <Button
            size="small"
            disabled={selectedRowKeys.length == 0}
            type='primary'
            style={{borderRadius: "8px", minWidth:"140px", minHeight:"32px"}}
            onClick={() => setShowChunkSelector(true)}
          >
            <FormattedMessage
              id="app.explorePage.addToReport"
              defaultMessage="Add to report"
            />
          </Button>
          <Button
            size="small"
            disabled={selectedRowKeys.length == 0}
            type='primary'
            style={{borderRadius: "8px", minWidth:"140px", minHeight:"32px", marginLeft:"15px"}}
            onClick={() => {
              showResizableModal(true);
            }}
          >
           {analyzeChunksPlaceholder}
          </Button>
          <span>
            &nbsp;&nbsp;
            {selectedRowKeys.length > 0
              ? `Selected ${selectedRowKeys.length} ${itemsPlaceholder}`
              : ""}
          </span>
        </Button.Group>
      ),
      dataIndex: "text",
    },
    {
      title: emotionPlaceholder,
      key:"emotion",
      render: (record) => (
        <div
          style={{
            ...classes.emojiWrap,
            ...{
              backgroundColor: facetsMap[record.plutchik_category] || "#fff",
            },
          }}
        >
          <span style={classes.tagText}>{record.plutchik_category}</span>
        </div>
      ),
      sorter: (a, b) => {
        if (a.plutchik_category < b.plutchik_category) {
          return -1;
        }
        if (a.plutchik_category > b.plutchik_category) {
          return 1;
        }
        return 0;
      },
      sortOrder: tableSortOrder.columnKey === "emotion" ? tableSortOrder.order : null,
      width: 120,
    },
    {
      title: sentimentPlaceholder,
      key:"sentiment",
      render: (record) => <SentimentToEmojiFace sentiment={record.sentiment} />,
      sorter: (a, b) => a.sentiment - b.sentiment,
      sortOrder: tableSortOrder.columnKey === "sentiment" ? tableSortOrder.order : null,
      width: 80,
    },
    {
      title: "",
      render: (record) => (
        <div style={{ width: "20px", textAlign: "center" }}>
          <FileSearchOutlined
            onClick={() => handleCommentInspectClick(record)}
          />
        </div>
      ),
      width: 20,
    },
  ];

  const onExpandChild = {
    expandedRowRender: (doc, index) => (
      <>
        {!(doc.text && doc.demographics)
          ? [
              {
                key: "title",
                text: (
                  <ul>
                    <li>comment id: {doc.comment_id}</li>
                    {doc.comment_uuid && (
                      <li>comment id: {doc.comment_uuid}</li>
                    )}
                    <li>{noDemographicsPlaceholder}</li>
                  </ul>
                ),
              },
            ]
          : [
              <Collapse
                expandIcon={({ isActive }) =>
                  isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />
                }
                bordered={false}
              >
                <Panel header={analyzeChunksTitlePlaceholder} key="1">
                  {doc.chunks.map((chunk, i) => {
                    return (
                      <>
                        <ul key={i}>
                          <li key={chunk.uid}>
                            {chunkIdPlaceholder} {chunk.uid}
                          </li>
                          <li key={chunk.text}>{analyzeChunksChunkTextPlaceholder} {chunk.text}</li>
                        </ul>
                      </>
                    );
                  })}
                </Panel>
              </Collapse>,

              <ul key={"title"}>
                <li>
                  {commentPlaceholder}: {doc.comment_id}
                </li>
                <li>
                  {commentDatePlaceholder}: {doc.create_datetime}
                </li>
                {doc.user && (
                  <li>
                    {userIdPlaceholder}:{" "}
                    <Link to={"/explore/chunksBy/userId/" + doc.user.uid + "/"}>
                      {doc.user.uid}
                    </Link>
                  </li>
                )}
                {doc.conversation && (
                  <li>
                    {subProjectPlaceholder}:{" "}
                    <Link
                      to={
                        "/explore/chunksBy/subProjectId/" +
                        doc.conversation.uid +
                        "/"
                      }
                    >
                      {doc.conversation.text}
                    </Link>
                  </li>
                )}
                {doc.demographics.length == 0 ? (
                  <li>No demographics attached</li>
                ) : (
                  sortDemographics(doc.demographics).map((demo, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={
                            "/explore/chunksBy/demographics/" +
                            encodeURIComponent(demo.category) +
                            "/" +
                            encodeURIComponent(demo.taxonomy) +
                            "/" +
                            encodeURIComponent(demo.value) +
                            "/"
                          }
                        >
                          {demo.category +
                            ":" +
                            demo.taxonomy +
                            ":" +
                            demo.value}
                        </Link>
                      </li>
                    );
                  })
                )}
              </ul>,
            ]}
      </>
    ),
  };

  const onSortChange = (value) => {
    console.log(value);
    setSelectValue(value);
    setData([
      ...data.sort((a, b) => {
        if (value == "newest") {
          return new Date(a.create_datetime) < new Date(b.create_datetime)
            ? 1
            : -1;
        } else if (value == "oldest") {
          return new Date(a.create_datetime) > new Date(b.create_datetime)
            ? 1
            : -1;
        }
        return 0;
      }),
    ]);
  };

  const onWordCount = (e) => {
    let minWords = 1;
    if (e.target.value !== "" && e.target.value > 0) minWords = e.target.value;
    form.setFieldsValue({
      wordSearch: e.target.value,
    });
    setWordSearch(e.target.value);
    applyWordFilter(minWords);
  };

  const applyWordFilter = (minWord) => {
    const new_array = dataCopy.filter((chunk) => {
      return chunk.original_text.split(" ").length > minWord;
    });
    setData(new_array);
  };

  const resetFilters = () => {
    setTableSortOrder({});
    setPaginationPage(1);
    setWordSearch(0);
    form.setFieldsValue({
      wordSearch: 1,
    });
    setSelectValue(analyzeChunksDateSortNone);
    applyWordFilter(1);
  }


  const filterTextLengthPlaceholder = (
    <FormattedMessage
      id="app.explorePage.filterByTextLength"
      defaultMessage="Filter by minimum # words"
    />
  );
  const avgSentimentPlaceholder = (
    <FormattedMessage
      id="app.explorePage.averageSentiment"
      defaultMessage="Average sentiment"
    />
  );

  const dateSortPlaceholder = (
    <FormattedMessage
      id="app.explorePage.dateSort"
      defaultMessage="Date Sort"
    />
  );

  return (
    <>
      {isShowResizableModal && (
        <MainMagicWindow
          setIsShowResizableModal={setIsShowResizableModal}
          selectedChunks={selectedRows}
          client_id={props.client_id}
          title={props.title}
          forum_id={props.forum_id}
          sorter={tableSortOrder}
          source={"comment"}
        />
      )}
      <Row style={{ height: "55px", background: "#f5f5f5", padding: "10px" }}>
        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
          <Form form={form}>
            <Form.Item
              name="wordSearch"
              onChange={onWordCount}
              label={filterTextLengthPlaceholder}
              onClick={(e) => {
                if (wordSearch === 0) {
                  form.setFieldsValue({
                    wordSearch: "",
                  });
                }
              }}
            >
              <Input
                type="number"
                style={{
                  width: "60px",
                }}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
          <Row style={{ float: "right" }}>
            <Col style={{ marginTop: "5px", wordWrap: "break-word" }}>
              <label>{avgSentimentPlaceholder}</label>
            </Col>
            <Col style={{ marginLeft: "20px" }}>
              <SentimentToEmojiFace sentiment={averageSentiment} />
            </Col>
            <Col>
              <p style={{ marginLeft: "10px", marginTop: "7px" }}>
                {!Number.isNaN(averageSentiment) &&
                  Math.round(averageSentiment * 100) / 100}
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Button
            type="primary"
            style={{ float: "right", borderRadius: "8px", minWidth: "140px" }}
            onClick={(e) => resetFilters()}
          >
            <FormattedMessage
              id="app.explorePage.resetSortFilters"
              defaultMessage="Reset Sort Filters"
            />
          </Button>
        </Col>
      </Row>
      <Table
        pagination={paginationOptions}
        columns={columns}
        dataSource={data}
        expandable={onExpandChild}
        rowSelection={rowSelection}
        onChange={onChange}
      />
      <CommentSelector
        selectedChunks={selectedRows}
        isOpen={showChunkSelector}
        setIsOpen={setShowChunkSelector}
      />
      <EmotionViewer
        selectedChunks={selectedRows}
        isOpen={showEmotionViewer}
        setIsOpen={setShowEmotionViewer}
      />
      <Modal
        title="Comment"
        visible={modalIsVisible}
        onOk={() => setModalIsVisible(false)}
        onCancel={() => setModalIsVisible(false)}
        okText={okPlaceholder}
        cancelText={cancelPlaceholder}
      >
        {modalBody}
      </Modal>
    </>
  );
}
