import axios from 'axios';

export const SEARCH_LOADING = 'SEARCH_LOADING';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';

function searchLoading() {
  return {
    type: SEARCH_LOADING,
  };
}

function searchSuccess(payload) {
  return {
    type: SEARCH_SUCCESS,
    payload,
  };
}

function searchFailure(payload) {
  return {
    type: SEARCH_FAILURE,
    payload,
  }
}

export function search(q, sourceId, algo) {
  return async (dispatch) => {
    let nostem = false;
    let algorithm = algo;
    if (algo === 'bm25nostem') {
      algorithm = 'bm25';
      nostem = true;
    }
    if (algo === 'bm25wqenostem') {
      algorithm = 'bm25wqe';
      nostem = true;
    }
    let url = '/search-api/api/v1/search?algo=' + algorithm;
    if (nostem) {
      url += '&nostem=true';
    }
    try {
      dispatch(searchLoading());
      const res = await axios.post(url, {
        text: [q],
        sourceId,
        n: 1000,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (res.status !== 200 || res.data.status !== 'ok') {
        throw Error(res.statusText);
      }

      return dispatch(searchSuccess(res.data.result));
    } catch (error) {

      dispatch(searchFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }));
    }
  };
}
