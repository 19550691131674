import React, { createContext, useState } from 'react';

export const DemographicsContext = createContext();


// This context is used to preserve forum id and title in global scope when user choose a forum
const DemographicsContextProvider = (props) => {

  const [ taxonomies, setTaxonomies ] = useState([]);
  const [ taxonomyMap, setTaxonomyMap ] = useState({});

  return (
    <DemographicsContext.Provider value={{ taxonomies, setTaxonomies, taxonomyMap, setTaxonomyMap  }}>
      {props.children}
    </DemographicsContext.Provider>
  );
}

export default DemographicsContextProvider;