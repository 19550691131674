import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';

am4core.useTheme(am4themes_animated);

function MapChart({ mapCardData, id = 'mapChart' }) {

  useEffect(() => {
    const chart = createChart(id);
    const worldSeries = addSeries(chart)

    // Add expectancy data
    worldSeries.data = mapCardData;
    chart.data = chart;

    return function cleanup() {
      chart.dispose();
    }

  }, [mapCardData, id])

  return (
    <div id={id} style={{ width: '100%', height: '100%' }}></div>
  );
}

function createChart(id) {
  const chart = am4core.create(id, am4maps.MapChart);

  // Set map definition
  chart.geodata = am4geodata_worldLow;

  // Set projection
  chart.projection = new am4maps.projections.Miller();

  // Center on Africa
  chart.deltaLongitude = -10;

  return chart;
}

function addSeries(chart) {
  const worldSeries = chart.series.push(new am4maps.MapPolygonSeries());

  // Remove Antarctica
  worldSeries.exclude = ['AQ'];

  // Make map load polygon (like country names) data from GeoJSON
  worldSeries.useGeodata = true;

  // Configure series [backgroud color]
  const polygonTemplate = worldSeries.mapPolygons.template;
  polygonTemplate.tooltipText = '{name}: {value}';
  polygonTemplate.fill = am4core.color('#efefef');
  polygonTemplate.nonScalingStroke = true;

  // Create hover state and set alternative fill color
  let hs = polygonTemplate.states.create('hover');
  hs.properties.fill = am4core.color('#607d8b');

  // Add heat rule
  worldSeries.heatRules.push({
    property: 'fill',
    target: worldSeries.mapPolygons.template,
    min: am4core.color('#b1e8f2'),
    max: am4core.color('#23bfd7'),
  });

  return worldSeries;
}

export default MapChart;