import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { fetchEntities } from '../actions/entities';
import EmojifyTopicChart from '../charts/EmojifyTopicChart';
import Loading from '../components/Loading';
import NoData from '../components/NoData';
import { ForumIdContext } from '../context/forumId-context';

function SentimentedEntitiesView() {

  const classes = useStyles();

  const { data, loaded } = useSelector((state) => state.entities);
  const [ sortedData, setSortedData ] = useState([]);

  const user = useSelector((state) => state.user);
  const date_range = useSelector((state) => state.date_range);

  const dispatch = useDispatch();
  const { forumId } = useContext(ForumIdContext);

  useEffect(() => {
    dispatch(fetchEntities(forumId, user.client_id));
  }, [date_range, forumId, user.client_id]);

  useEffect(() => {
    setSortedData(sortBy(data));
  }, [data]);

  const sortBy = (data, by='chunk_count') => {
    return [...data].sort((a,b) => {
      if(a[by] > b[by]){
        return 1;
      }else if(a[by] < b[by]){
        return -1;
      }else {
        return 0;
      }
    })
  }

  return (
    <div className={classes.sectionContent}>
      <div className={classes.sectionName}>
        Sentimented Entities
      </div>
      <span>Click the point to see related comments.</span>
      <div className={classes.graphWrap}>
        {loaded ? ( sortedData.length?
          <EmojifyTopicChart data={sortedData.slice(sortedData.length-31, sortedData.length)} axis={{x:'chunk_avg_sentiment', y: 'name'}} name='sentimented-entities-chart' xscale={{start:0, end: 5}}/>: <NoData/>)
          :
          <Loading />
        }
      </div>

    </div>
  );
}

const useStyles = makeStyles(() => ({
  graphWrap: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    height: '100%',
    width: '100%',

  },

  sectionContent: {
    width: '80%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  sectionName: {
    color: '#516e88',
    fontSize: '34.5px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '41px',
    letterSpacing: '0.25px',
    lineHeight: '41px',
    marginBottom: '24px',
    marginTop: '40px',
    width: '100%',

  }

}));

export default SentimentedEntitiesView;