import React from 'react';
import { Card } from 'antd';

/**
 * Wrapper around a row draggable element.
 *
 * Note: A row shouldn't contain more than 3 chart children.
 *
 * @param blockEl The component element to be wrapped
 * @param isDragging Bool indicating whether the element is being dragged
 * @param item The row data item record
 */
export default () => ({ blockEl, isDragging, item }) => (
  <Card
    bodyStyle={
      {
        ... {
          background: isDragging ? "lightgreen" : "white",
          padding: "5px",
          overflowX: 'auto',
        },
        ... ((item.columns.length > 3) ? {
          background: '#faeda7',
          border: "2px solid #ffe552",
        } : {})
      }
    }
  >
    { blockEl }
  </Card>
);