import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from "react-intl"; 
import { getPresignedUploadParams, getPresignedUploadParams_with_dispatch } from '../actions/s3';

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

const UploadButton = (props) => {
    const {
        className,
        buttonClassName,
        style,
        buttonStyle,
        disabled,
        content,
        onSubmit,
        files,
    } = props
    
    const _disabled =
        files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) ||
        !files.some(f => ['headers_received', 'done', 'ready'].includes(f.meta.status))
    
    const handleSubmit = () => {
        onSubmit(files.filter(f => ['ready'].includes(f.meta.status)))
    }

    return (
        <div className={className} style={style}>
            <button
                className={buttonClassName}
                style={buttonStyle}
                onClick={handleSubmit}
                disabled={disabled || _disabled}
             >
                {content}
            </button>
        </div>
      )
}

function S3DropZone() {
  const user = useSelector((state) => state.user);
  
  const statefiles = useSelector((state) => state.fileUploads.data);
  const dispatch = useDispatch();
  const [uploadFileList, setFiles] = useState([]);
  const intl = useIntl();
  const dragDropPlaceholder = intl.formatMessage({id: 'app.loadData.dragFiles',defaultMessage: 'Drag files here or Click to Browse (CSV files accepted only)'});

  useEffect(() => {
    dispatch(getPresignedUploadParams(uploadFileList, user.client_id));
  }, [uploadFileList]);
  
  // specify upload params and url for your files
  const getUploadParams = async ({ file, meta: { id, name } }) => {
    const { presignedUrl } = statefiles.find(f => f.id === id);
    console.log('getUploadParams for file:', name, ', presignedUrl:', presignedUrl);
    return { method: 'PUT', body: file, meta: { presignedUrl }, url: presignedUrl }
  }
  
  // called every time a file's `status` changes... used to sync state uploadFileList
  const handleChangeStatus = ({ meta, file }, status) => { 
    console.log('File Status Change', status, meta, file)
    let tempUpdatedFiles = [];
    if (status === 'ready' || status === 'removed'){
      if (status === 'ready'){
        tempUpdatedFiles = [...uploadFileList, meta]
      }
      else if (status === 'removed'){
        tempUpdatedFiles = uploadFileList.filter(f => {
              return f.id !== meta.id;
            });
      }
      setFiles(tempUpdatedFiles)
    }
  }
  
  // receives array of files that are ready to upload when submit button is clicked
  const handleUpload = (files, allFiles) => { 
    console.log(files.map(file => file.meta)) 
    // file.restart will trigger calling getUploadParams and upload the file to the returned url
    files.forEach((file) => file.restart());
  }
  
  const handleValidation = ({ meta }) => {
    console.log('handleValidation for file: ', meta)
    // reject duplicate file names
    if (meta.name === undefined)
      return 'Invalid File'
    if (uploadFileList.some(e => e.name === meta.name))
      return 'Duplicate File Name'
  }

  return (
          <Dropzone
            autoUpload={false}
            canRestart={false}
            validate={handleValidation}
            SubmitButtonComponent={UploadButton}
            submitButtonContent='Upload'
            onSubmit={handleUpload}
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            submitButtonDisabled={files => files.every(f => ['done'].includes(f.meta.status))}
            accept=".csv"
            inputContent= {dragDropPlaceholder}
            styles={{
              dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
              inputLabel: (files, extra) => (extra.rejected_file_type ? { color: 'red' } : {}),
              dropzone: { minHeight: 200},
            }}
            disabled={files => files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status))}
          />
  )
}

export default S3DropZone;