import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { DemographicsContext } from "../context/demographics-context";
import { DatePicker, Popconfirm } from "antd";
import { Slider, Card, Button, Select, Switch, Row, Col,Descriptions,Space, Tooltip } from "antd";
import { Form, Input } from "antd";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  InfoCircleOutlined ,
  CaretLeftOutlined,
  CaretRightOutlined,
  BackwardOutlined,
  ForwardOutlined,
} from "@ant-design/icons";

import { CacheLayerActions } from "../actions/cache_layer";
import { fetchEntities } from "../actions/entities";
import EmojifyTopicChart from "../charts/EmojifyTopicChart";
import PageHeading from "../components/PageHeading";
import Loading from "../components/Loading";
import NoData from "../components/NoData";
import Widget from "../components/Widget";
import useInterval from "../components/useInterval";
import localPropUtil from "./utils/localPropUtil";
import { ReportImgContext } from "../context/report-context";
import { ForumIdContext } from "../context/forumId-context";
import { v4 as uuidv4 } from "uuid";
import { FormattedMessage,useIntl } from "react-intl"; 

const { RangePicker } = DatePicker;
const { Option } = Select;
const seriesColorMap = [
  "blue",
  "green",
  "red",
  "orange",
  "purple",
  "pink",
  "black",
];

function ApplyAugmentationsButton(props) {
  const [visible, setVisible] = useState(false);
  const intl = useIntl();
  const applyPlaceholder = intl.formatMessage({id: 'app-sentimentedChart-applyDataSeries',defaultMessage: 'Click to apply'})
  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Popconfirm
      title="This may take a while. Are you sure?"
      open={visible}
      onConfirm={props.handleOk}
      onCancel={handleCancel}
    >
      <Button onClick={showPopconfirm}>{applyPlaceholder}</Button>
    </Popconfirm>
  );
}

function SentimentedEntitiesWidget(props) {
  const { isShown } = useContext(ReportImgContext);
  const { forumId } = useContext(ForumIdContext);

  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [concatenatedData, setConcatenatedData] = useState([]);
  const [localProps, setLocalProps] = useState({});
  const [exploreLink, setExploreLink] = useState("");

  const [showAugmentedChart, setShowAughmentedChart] = useState(false);
  const [augmentationHideDefaultAxis, setAugmentationHideDefaultAxis] =
    useState(false);
  const [isDateAugmentation, setIsDateAugmentation] = useState(false);
  const [augmentedRawData, setAugmentedRawData] = useState([]);
  const [augmentedData, setAugmentedData] = useState([]);
  const [augmentedAxis, setAugmentedAxis] = useState([]);
  const [augmentations, setAugmentations] = useState([]);
  const [augmentationType, setAugmentationType] = useState("");
  const [augmentationSubType, setAugmentationSubType] = useState(null);
  const [runAugmentationApply, setRunAugmentationApply] = useState(false);
  const { taxonomyMap } = useContext(DemographicsContext);
  const [filteredTaxonomyMap, setFilteredTaxonomyMap] = useState({});
  const [taxonomyGroups, setTaxonomyGroups] = useState([]);

  const [filters, setFilters] = useState({
    rangeStart: 1,
    rangeEnd: 10000,
    sortDirection: false,
    distinctEntityTypes: [],
    entityType: null,
    sortByField: "chunk_avg_sentiment",
  });
  const [appliedMemoryFilters, setAppliedMemoryFilters] = useState(false);
  const [memoryFiltersExists, setMemoryFilterExists] = useState(false);
  const [defaultRangeStart, setDefaultRangeStart] = useState(20);
  const [defaultRangeEnd, setDefaultRangeEnd] = useState(60);
  const [applySummaryValue, setApplySummaryValue] = useState(0);
  const [formChanged, setFormChanged] = useState(false);

  const [sliderTempRange, setSliderTempRange] = useState([20, 60]);
  const [sliderNextValue, setSliderNextValue] = useState(0);
  const [sliderPreValue, setSliderPreValue] = useState(0);
  const [sortedTempVal, setSortedTempVal] = useState(false);
  const [sortByTempField, setSortByTempField] = useState(false);
  const [entityTypeTempVal, setEntityTypeTempVal] = useState(null);

  const history = useHistory();
  const user = useSelector((state) => state.user);
  const date_range = useSelector((state) => state.date_range);

  const dispatch = useDispatch();
  const classes = useStyles();
  const axis = { x: "chunk_avg_sentiment", y: "name" };
  const [form] = Form.useForm();

  const intl = useIntl();
  const chartSetupPlaceholder = intl.formatMessage({id: 'app-sentimentedChart-chartSetup',defaultMessage: 'Chart setup'})

  const chunksPlaceholder = intl.formatMessage({id: 'app-sentimentedChart-chunks',defaultMessage: 'Number of chunks'})

  const entityTypePlaceholder = intl.formatMessage({id: 'app-sentimentedChart-entityType',defaultMessage: 'Entity Type'})

  const sortByPlaceholder = intl.formatMessage({id: 'app-sentimentedChart-sortBy',defaultMessage: 'Sort By'})
  const sortDirPlaceholder = intl.formatMessage({id: 'app-sentimentedChart-sortDirection',defaultMessage: 'Sort Direction'})

  const addDataSeriesPlaceholder = intl.formatMessage({id: 'app-sentimentedChart-addDataSeries',defaultMessage: 'Add data series'})
  const seriesTypePlaceholder = intl.formatMessage({id: 'app-sentimentedChart-seriesType',defaultMessage: 'Series type:'})
  const allDataPlaceholder = intl.formatMessage({id: 'app-sentimentedChart-allData',defaultMessage: 'Show “all data” series'})
  const seriesPlaceholder = intl.formatMessage({id: 'app-sentimentedChart-series',defaultMessage: 'Series'})

  const typePlaceholder = intl.formatMessage({id: 'app-sentimentedChart-type',defaultMessage: 'Select a type'})
  const clicktoAddPlaceholder = intl.formatMessage({id: 'app-sentimentedChart-clicktoAdd',defaultMessage: 'Click to add'})

  const subTypePlaceholder = intl.formatMessage({id: 'app-sentimentedChart-subType',defaultMessage: 'Select a sub type'})
  const startDatePlaceholder = intl.formatMessage({id: 'app-sentimentedChart-startDate',defaultMessage: 'Start date'})

  const endDatePlaceholder = intl.formatMessage({id: 'app-sentimentedChart-endDate',defaultMessage: 'End date'})

  const applyPlaceholder = intl.formatMessage({id: 'app-sentimentedChart-apply',defaultMessage: 'Click to apply'})
  const numofTopicsPlaceholder = intl.formatMessage({id: 'app-sentimentedChart-numofTopics',defaultMessage: 'Number of topics'})

  const helpTextDataSeries1Placeholder = intl.formatMessage({
    id: "app.sentimentedChart.dataSeries1",
    defaultMessage: "You can show results for date ranges or demographic data series on the chart.",
  });
  const helpTextDataSeries2Placeholder = intl.formatMessage({
    id: "app.sentimentedChart.dataSeries2",
    defaultMessage: "This allows you to compare results for different times or for different employee groups.",
  });
  const helpTextDataSeries3Placeholder = intl.formatMessage({
    id: "app.sentimentedChart.dataSeries3",
    defaultMessage: "You can click through to the actual text chunks.",
  });

  const helpTextchartSetup1Placeholder = intl.formatMessage({
    id: "app.sentimentedChart.chartSetup1",
    defaultMessage: "As you adjust the top slider the number of topics to be shown in the chart will change.",
  });
  const helpTextchartSetup2Placeholder = intl.formatMessage({
    id: "app.sentimentedChart.chartSetup2",
    defaultMessage: "Click the button when you reach the desired number of topics you want to display.",
  });
  const helpTextchartSetup3Placeholder = intl.formatMessage({
    id: "app.sentimentedChart.chartSetup3",
    defaultMessage: "You can sort by sentiment or by the number of chunks in each topic. ",
  });
  useEffect(() => {
    (async () => {
      if (!user) {
        console.log("user", user);
        return;
      }

      let _localProps = await localPropUtil(
        dispatch,
        "sentimented-entities",
        user.client_id,
        forumId,
        date_range,
        props
      );

      const _filters = await dispatch(
        CacheLayerActions.getData(
          _localProps.chartId + "-sentimented-entities-filters",
          true
        )
      );
      if (_filters && props.noWidget) {
        setFilters(deserializeFilters(_filters));
        setMemoryFilterExists(true);
      }

      let _data = [];
      const cacheData = _localProps.useCache
        ? await dispatch(CacheLayerActions.getData(_localProps.chartId))
        : null;
      if (!_localProps.useCache || !cacheData) {
        const result = await dispatch(
          fetchEntities(
            _localProps.forumId,
            user.client_id,
            _localProps.dateRange.startDate,
            _localProps.dateRange.endDate
          )
        );
        if (result && result.payload) {
          _data = result.payload;
        }
      } else {
        _data = cacheData;
      }

      setLocalProps(_localProps);
      setData(_data);
    })();
  }, [
    user && user.client_id,
    date_range,
    forumId,
    props.forumId,
    props.startDate,
    props.endDate,
  ]);

  useEffect(() => {
    if (!localProps.chartId) {
      return;
    }

    setLoaded(true);
    setExploreLink(
      "/explore/data-layer/" +
        localProps.chartId +
        "/sentimented-entities/?" +
        [
          "client_id=" + localProps.clientId,
          "forum_id=" + localProps.forumId,
          "startDate=" + localProps.dateRange.startDate,
          "endDate=" + localProps.dateRange.endDate,
          "title=" + localProps.title,
        ].join("&")
    );

    let _augmentations = [];
    seriesColorMap.forEach((color, i) => {
      const urlParams = new URLSearchParams(window.location.search);
      const startDate = urlParams.get("aug" + i + "_startDate");
      const endDate = urlParams.get("aug" + i + "_endDate");
      console.log("startDate", startDate);
      console.log("endDate", endDate);

      if (startDate && endDate) {
        _augmentations.push({
          startDate: moment(startDate, "YYYY-MM-DD"),
          endDate: moment(endDate, "YYYY-MM-DD"),
          color: color,
        });
      }
    });

    if (
      _augmentations.length == 0 &&
      filters.augmentations &&
      filters.augmentations.length > 0
    ) {
      _augmentations = filters.augmentations;
      setAugmentationHideDefaultAxis(filters.augmentationHideDefaultAxis);
    }
    setIsDateAugmentation(filters.isDateAugmentation);

    if (_augmentations.length > 0) {
      setAugmentations(_augmentations);
      setRunAugmentationApply(true);
    }
  }, [localProps]);

  useEffect(() => {
    console.log("concatenatedData", concatenatedData);
    if (
      augmentations.length > 0 &&
      runAugmentationApply &&
      concatenatedData.length > 0
    ) {
      onAugmentApply();
      setRunAugmentationApply(false);
      //console.log('augmentations done');
    }
  }, [runAugmentationApply, augmentations, concatenatedData]);

  useEffect(() => {
    if (!loaded || !localProps || data.length == 0) {
      return;
    }

    const _sortedData = sortBy(data);
    let _max = 0;
    _sortedData.forEach((value) => {
      _max = Math.max(_max, value.chunk_count);
    });

    console.log("memoryFiltersExists", memoryFiltersExists);
    console.log("appliedMemoryFilters", appliedMemoryFilters);
    console.log("filters", filters);
    let _min = _sortedData.slice(_sortedData.length - 13, _sortedData.length);
    _min = _min[0].chunk_count;

    _min =
      !memoryFiltersExists || appliedMemoryFilters ? _min : filters.rangeStart;
    _max =
      !memoryFiltersExists || appliedMemoryFilters ? _max : filters.rangeEnd;

    setDefaultRangeStart(_min);
    setDefaultRangeEnd(_max);
    setSliderTempRange([_min, _max]);
    setSortByTempField(
      !memoryFiltersExists || appliedMemoryFilters
        ? sortByTempField
        : filters.sortByField == "chunk_count"
    );
    setSortedTempVal(
      !memoryFiltersExists || appliedMemoryFilters
        ? sortByTempField
        : filters.sortDirection
    );
    setEntityTypeTempVal(
      !memoryFiltersExists || appliedMemoryFilters
        ? entityTypeTempVal
        : filters.entityType
    );

    let _distinctEntityTypes = [];
    data.forEach((value) => {
      value.entityTypes.forEach((eValue) => {
        if (_distinctEntityTypes.indexOf(eValue.text) == -1) {
          _distinctEntityTypes.push(eValue.text);
        }
      });
    });

    setAppliedMemoryFilters(true);
    setFilters((current) => {
      return {
        ...current,
        ...{
          distinctEntityTypes:
            !memoryFiltersExists || appliedMemoryFilters
              ? _distinctEntityTypes
              : filters.distinctEntityTypes,
          rangeStart: _min,
          rangeEnd: _max,
        },
      };
    });

    setInitialized(true);

    dispatch(CacheLayerActions.setData(localProps.chartId, _sortedData));
  }, [loaded, data]);

  useEffect(() => {
    console.log("filters", filters);
    storeFilters();
    setSortedData(
      sortBy(filterData(data), filters.sortByField, filters.sortDirection)
    );
    setAugmentedData(
      sortBy(
        filterData(augmentedRawData),
        filters.sortByField,
        filters.sortDirection
      )
    );
  }, [initialized, loaded, filters, data]);

  useEffect(() => {
    setConcatenatedData(group(sortedData));
  }, [sortedData]);

  const findSliderNavValues = ()=>{
    var next = sliderTempRange[0] + 1;
    var pre = sliderTempRange[0] - 1;
    while(next <= sliderTempRange[1])
    {
      const found = data.find(element => element.chunk_count === next);
      if(found === undefined)
      {
        next++;
      }
      else
      {
        setSliderNextValue(found.chunk_count);
        break;
      }
    }
    while(pre >= 0)
    {
      const found = data.find(element => element.chunk_count === pre);
      if(found === undefined)
      {
        pre--;
      }
      else
      {
        setSliderPreValue(found.chunk_count);
        break;
      }
    }
}

  useInterval(() => {
    setApplySummaryValue(
      group(
        data.filter((value) => {
          return (
            value.chunk_count >= sliderTempRange[0] &&
            value.chunk_count <= sliderTempRange[1] &&
            (!entityTypeTempVal ||
              entityTypeTempVal == "" ||
              value.entityTypes.map((v) => v.text).indexOf(entityTypeTempVal) >
                -1)
          );
        })
      ).length
    );
    findSliderNavValues();
  }, 500);

  const makeUrl = () => {
    let uri =
      "/explore/data-layer/" + localProps.chartId + "/sentimented-entities?";
    let parameters = [
      "client_id=" + localProps.clientId,
      "forum_id=" + localProps.forumId,
      "startDate=" + localProps.dateRange.startDate,
      "endDate=" + localProps.dateRange.endDate,
    ];

    augmentations.forEach((a, i) => {
      console.log(a);
      if (a.startDate)
        parameters.push(
          "aug" + i + "_startDate=" + a.startDate.format("YYYY-MM-DD")
        );
      if (a.endDate)
        parameters.push(
          "aug" + i + "_endDate=" + a.endDate.format("YYYY-MM-DD")
        );
    });

    return uri + parameters.join("&").replace("&&", "&");
  };

  const onPointClicked = (ev, el) => {
    if(props.noWidget)
    {
    let seriesIndex = seriesColorMap.indexOf(el.model.color);
    let startDate =
      seriesIndex !== -1 && augmentations[seriesIndex].startDate
        ? augmentations[seriesIndex].startDate.format("YYYY-MM-DD")
        : localProps.dateRange.startDate;
    let endDate =
      seriesIndex !== -1 && augmentations[seriesIndex].endDate
        ? augmentations[seriesIndex].endDate.format("YYYY-MM-DD")
        : localProps.dateRange.endDate;
    let taxonomy =
      seriesIndex !== -1 && augmentations[seriesIndex].type
        ? augmentations[seriesIndex].type.split(":")[1]
        : "";
    let value =
      seriesIndex !== -1 && augmentations[seriesIndex].subType
        ? augmentations[seriesIndex].subType
        : "";

    history.push(
      "/explore/data-layer/" +
        localProps.chartId +
        "/chunks?" +
        [
          "from=explore/data-layer/" +
            localProps.chartId +
            "/sentimented-entities/",
          ev ? "q=" + encodeURIComponent(ev.data.data[axis.y]) : "",
          "client_id=" + localProps.clientId,
          "forum_id=" + localProps.forumId,
          "startDate=" + startDate,
          "endDate=" + endDate,
          "taxonomy=" + taxonomy,
          "value=" + value,
        ]
          .join("&")
          .replace("&&", "&")
    );
    }
  };

  useEffect(() => {
    let tempTaxonomyMap = {};
    Object.keys(taxonomyMap).map((index) => {
      tempTaxonomyMap[index] = [];

      const values = taxonomyMap[index];
      values.forEach((value) => {
        tempTaxonomyMap[index].push(value);
      });
    });
    setFilteredTaxonomyMap(tempTaxonomyMap);
  }, [taxonomyMap]);
  useEffect(() => {
    let tempTaxonomyGroups = {};
    Object.values(filteredTaxonomyMap).map((values) => {
      values.forEach((value) => {
        const key = value.category + ":" + value.taxonomy;
        if (!tempTaxonomyGroups[key]) {
          tempTaxonomyGroups[key] = [];
        }
        tempTaxonomyGroups[key].push(value);
      });
    });
    setTaxonomyGroups(tempTaxonomyGroups);
  }, [filteredTaxonomyMap]);

  const serializeFilters = (_filters) => {
    _filters = _.cloneDeep(_filters);
    if (_filters.augmentations) {
      let _safeAugmentations = _filters.augmentations.map((aug) => {
        if (aug.startDate) {
          aug.startDate = aug.startDate.format("YYYY-MM-DD");
        }
        if (aug.endDate) {
          aug.endDate = aug.endDate.format("YYYY-MM-DD");
        }
        return aug;
      });
      _filters.augmentations = _safeAugmentations;
    }
    return _filters;
  };

  const deserializeFilters = (_filters) => {
    _filters = _.cloneDeep(_filters);
    if (_filters.augmentations) {
      _filters.augmentations = _filters.augmentations.map((aug) => {
        if (aug.startDate) {
          aug.startDate = moment(aug.startDate, "YYYY-MM-DD");
        }
        if (aug.endDate) {
          aug.endDate = moment(aug.endDate, "YYYY-MM-DD");
        }
        return aug;
      });
    }

    return _filters;
  };

  const storeFilters = () => {
    if (props.noWidget && initialized && loaded && localProps) {
      console.log(
        "Storing filters",
        localProps.chartId + "-sentimented-entities-filters",
        filters
      );
      dispatch(
        CacheLayerActions.setData(
          localProps.chartId + "-sentimented-entities-filters",
          serializeFilters(filters),
          true
        )
      );
    }
  };

  const filterData = (records) => {
    return [...records].filter((value) => {
      return (
        value.chunk_count > 0 &&
        value.chunk_count >= filters.rangeStart &&
        value.chunk_count <= filters.rangeEnd &&
        (!filters.entityType ||
          filters.entityType == "" ||
          value.entityTypes.map((v) => v.text).indexOf(filters.entityType) > -1)
      );
    });
  };

  const sortBy = (records, by = "chunk_count", direction = true) => {
    let result;
    if (!records) {
      return [];
    }
    result = [...records].sort((a, b) => {
      if (a[by] > b[by]) {
        return 1;
      } else if (a[by] < b[by]) {
        return -1;
      } else {
        return 0;
      }
    });
    if (!direction) {
      result = result.reverse();
    }

    console.log("truncated", props.truncated);
    console.log("data", data);
    console.log("sortBy", result);
    console.log("sortBy->records", records);
    console.log("sortBy->by", by);
    console.log("sortBy->direction", direction);
    return result;
  };

  const group = (records) => {
    let _groupedKeys = {};
    records.forEach((record) => {
      if (!_groupedKeys[record.name]) {
        _groupedKeys[record.name] = record;
      }
    });
    return Object.values(_groupedKeys);
  };

  const onFilterApply = () => {
    setFormChanged(false);
    setFilters((current) => {
      return {
        ...current,
        ...{
          rangeEnd:
            sliderTempRange.length > 0 ? sliderTempRange[1] : current.rangeEnd,
          rangeStart:
            sliderTempRange.length > 0
              ? sliderTempRange[0]
              : current.rangeStart,
          sortDirection: sortedTempVal,
          entityType: entityTypeTempVal,
          sortByField: sortByTempField ? "chunk_count" : "chunk_avg_sentiment",
        },
      };
    });
  };

  const onRangeChange = (value) => {
    setSliderTempRange(value);
  };

  const onAugmentApply = () => {
    (async () => {
      setInitialized(false);
      setShowAughmentedChart(false);
      console.log("augmentations", augmentations);

      let _data = [...data];
      await Promise.all(
        augmentations.map((augmentation, i) => {
          return new Promise(async (resolve) => {
            let startDate = "";
            let endDate = "";
            let category = "";
            let taxonomy = "";
            let subType = "";
            if (
              isDateAugmentation &&
              augmentation.startDate &&
              augmentation.endDate
            ) {
              startDate = augmentation.startDate;
              endDate = augmentation.endDate;
            } else if (
              !isDateAugmentation &&
              augmentation.type &&
              augmentation.subType
            ) {
              category = augmentation.type.split(":")[0];
              taxonomy = augmentation.type.split(":")[1];
              subType = augmentation.subType;
            }
            let demographicWhere = {};
            if (
              taxonomy.length > 0 &&
              category.length > 0 &&
              subType.length > 0
            )
              demographicWhere = {
                taxonomy: taxonomy,
                category: category,
                value: subType,
              };
            let augData = [];
            let result = await dispatch(
              fetchEntities(
                localProps.forumId,
                user.client_id,
                startDate,
                endDate,
                demographicWhere
              )
            );
            if (result && result.payload) {
              augData = result.payload;
            }

            augData.forEach((record) => {
              let dIndex = _.findIndex(_data, (r) => r.name == record.name);
              let chunk_avg_sentiment_key = "aug" + i + "_chunk_avg_sentiment";
              let chunk_count_key = "aug" + i + "_chunk_count";

              _data[dIndex] = {
                ..._data[dIndex],
                ...{
                  augmented: true,
                  [chunk_avg_sentiment_key]: _.round(
                    record.chunk_avg_sentiment,
                    2
                  ),
                  [chunk_count_key]: record.chunk_count,
                },
              };
            });
            resolve();
          });
        })
      );

      const rawData = _data.filter((record) => record.augmented);
      setAugmentedRawData(rawData);
      setAugmentedData(
        sortBy(filterData(rawData), filters.sortByField, filters.sortDirection)
      );
      setAugmentedAxis(
        augmentations.map((a, i) => {
          return {
            x: "aug" + i + "_chunk_avg_sentiment",
            c: "aug" + i + "_chunk_count",
            y: "name",
            color: a.color,
          };
        })
      );
      setShowAughmentedChart(augmentations.length > 0);
      setFilters((current) => {
        return {
          ...current,
          ...{
            isDateAugmentation: isDateAugmentation,
            augmentationHideDefaultAxis: augmentationHideDefaultAxis,
            augmentations: augmentations,
          },
        };
      });
      setInitialized(true);
      history.push(makeUrl());
    })();
  };

  const onAddAugmentation = () => {
    setAugmentations((current) => {
      return [
        ...current,
        { 
          id: uuidv4(),
          color: seriesColorMap[current.length],
        },
      ];
    });
  };

  const onRemoveAugmentation = (index) => {
    setAugmentations((list) => list.filter((el) => el.id !== index));
  };

  return (
    <>
      {!props.noWidget && (
        <Widget
          title={localProps.title ? localProps.title : "Sentimented Entities"}
          actions={props.actions}
          showExploreLink={initialized && isShown}
          exploreLink={exploreLink}
          showMaximize={false}
        >
          {initialized && concatenatedData ? (
            concatenatedData.length ? (
              <EmojifyTopicChart
                // data={concatenatedData.slice(
                //   concatenatedData.length - 13,
                //   concatenatedData.length
                // )}
                data={concatenatedData.slice(
                  0,
                  15
                )}
                isMain={false}
                axis={axis}
                name="sentimented-entities-chart"
                xscale={{ start: 1, end: 5 }}
                onPointClicked={onPointClicked}
              />
            ) : (
              <NoData />
            )
          ) : (
            <Loading />
          )}
        </Widget>
      )}
      {props.noWidget && (
        <>
          {initialized ? (
            <>
              <PageHeading>
                {localProps.title ? localProps.title : "Sentimented Entities"}
              </PageHeading>
              <Row style={{minWidth: "1500px"}}>
                <Col span={16}>
                  {!runAugmentationApply && (
                    <>
                      {!showAugmentedChart ? (
                        <EmojifyTopicChart
                          data={
                            props.truncated
                              ? 
                              // concatenatedData.slice(
                              //     concatenatedData.length - 13,
                              //     concatenatedData.length
                              //   )
                              concatenatedData.slice(
                                0,
                                15
                              )
                              : concatenatedData
                          }
                          isMain={true}
                          axis={axis}
                          name="sentimented-entities-chart"
                          xscale={{ start: 1, end: 5 }}
                          onPointClicked={onPointClicked}
                        />
                      ) : (
                        <EmojifyTopicChart
                          data={augmentedData}
                          isMain={true}
                          axis={axis}
                          name="augmented-sentimented-entities-chart"
                          xscale={{ start: 1, end: 5 }}
                          onPointClicked={onPointClicked}
                          hideDefaultAxis={augmentationHideDefaultAxis}
                          additionalAxis={augmentedAxis}
                        />
                      )}
                    </>
                  )}
                </Col>
                <Col span={8}>
                  <Card title={chartSetupPlaceholder} style={{ minWidth:"450px" }} extra={
                      <Space>
                        <Tooltip
                          placement="left"
                          title={
                            <ul>
                              <li key="1">{helpTextchartSetup1Placeholder}</li>
                              <li key="2">{helpTextchartSetup2Placeholder}</li>
                              <li key="3">{helpTextchartSetup3Placeholder}</li>
                            </ul>
                          }
                          zIndex="3000"
                        >
                          <InfoCircleOutlined style={{ fontSize: "22px"}} />
                        </Tooltip>
                      </Space>
                    }>
                    <Form
                      name="basic"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={{ remember: true }}
                      autoComplete="off"
                      style={{ marginTop: "30px" }}
                      onFieldsChange={() => setFormChanged(true)}
                    >
                      <Form.Item label={chunksPlaceholder}>
                        <Space size={4}>
                        <Row>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                          <Space size={4}>
                            <Button
                              size="small"
                              style={{ padding: 0 }}
                              onClick={() =>
                                setSliderTempRange([
                                  sliderPreValue,
                                  sliderTempRange[1],
                                ])
                              }
                            >
                              <BackwardOutlined />
                            </Button>
                            <Button
                              size="small"
                              style={{ padding: 0 }}
                              onClick={() =>
                                setSliderTempRange([
                                  Math.max(1, sliderTempRange[0] - 1),
                                  sliderTempRange[1],
                                ])
                              }
                            >
                              <CaretLeftOutlined />
                            </Button>
                          </Space>
                          <Space size={4}>
                            <Button
                              size="small"
                              style={{ padding: 0 }}
                              onClick={() =>
                                setSliderTempRange([
                                  Math.min(
                                    defaultRangeEnd,
                                    sliderTempRange[0] + 1
                                  ),
                                  sliderTempRange[1],
                                ])
                              }
                            >
                              <CaretRightOutlined />
                            </Button>
                            <Button
                              size="small"
                              style={{ padding: 0 }}
                              onClick={() =>
                                setSliderTempRange([
                                  sliderNextValue,
                                  sliderTempRange[1]
                                 ]
                                )
                              }
                            >
                              <ForwardOutlined />
                            </Button>
                          </Space>
                          </Col>
                          <Col xs={24} sm={24} md={16} lg={16} xl={16} >
                          <Slider
                            range
                            max={defaultRangeEnd}
                            defaultValue={[defaultRangeStart, defaultRangeEnd]}
                            value={sliderTempRange}
                            onChange={onRangeChange}
                            tooltip={{ open: true }}
                            style={{ width: "180px" }}
                          />
                        </Col>
                       </Row>                        
                       </Space>
                      </Form.Item>
                      <Form.Item label={entityTypePlaceholder}>
                        <Select
                          placeholder="Any"
                          style={{ width: 120 }}
                          value={entityTypeTempVal}
                          onChange={(value) => setEntityTypeTempVal(value)}
                        >
                          {filters.distinctEntityTypes.map((value) => (
                            <Option key={value} value={value}>{value}</Option>
                          ))}
                        </Select>
                        {(filters.entityType || entityTypeTempVal) && (
                          <Button onClick={() => setEntityTypeTempVal(null)}>
                            Clear
                          </Button>
                        )}
                      </Form.Item>
                      <Form.Item label={sortByPlaceholder}>
                        <Switch
                          checkedChildren="Chunk Count"
                          unCheckedChildren="Avg Sentiment"
                          defaultChecked={sortByTempField}
                          onClick={() => setSortByTempField(!sortByTempField)}
                        />
                      </Form.Item>
                      <Form.Item label={sortDirPlaceholder}>
                        <Switch
                          checkedChildren="Highest - Lowest"
                          unCheckedChildren="Lowest - Highest"
                          defaultChecked={sortedTempVal}
                          onClick={() => setSortedTempVal(!sortedTempVal)}
                        />
                      </Form.Item>
                      <div style={{ textAlign: "right" }}>
                        <Space>
                      {numofTopicsPlaceholder}
                        <Button onClick={onFilterApply}>
                        {applyPlaceholder} ({applySummaryValue})
                        </Button>
                        </Space>
                      </div>
                    </Form>
                  </Card>

                  <Card
                    title={addDataSeriesPlaceholder}
                    actions={[
                      <ApplyAugmentationsButton handleOk={onAugmentApply} />,
                    ]}
                    style={{ minWidth:"450px" }}
                    extra={
                      <Space>
                        <Tooltip
                          placement="left"
                          title={
                            <ul>
                              <li key="1">{helpTextDataSeries1Placeholder}</li>
                              <li key="2">{helpTextDataSeries2Placeholder}</li>
                              <li key="3">{helpTextDataSeries3Placeholder}</li>
                            </ul>
                          }
                          zIndex="3000"
                        >
                          <InfoCircleOutlined style={{ fontSize: "22px"}} />
                        </Tooltip>
                      </Space>
                    }
                  >
                    <Form
                      name="basic"
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      initialValues={{ remember: true }}
                      autoComplete="off"
                      onFieldsChange={() => setFormChanged(true)}
                    >
                      {
                        <>
                          <Form.Item label={seriesTypePlaceholder}>
                            <Space>
                              <Switch
                                checked={isDateAugmentation}
                                checkedChildren="Date"
                                unCheckedChildren="Demographic"
                                style={{
                                  width: "120px",
                                }}
                                onChange={() => {
                                  setIsDateAugmentation(!isDateAugmentation);
                                  setAugmentations([]);
                                }}
                              />
                            </Space>
                          </Form.Item>
                          {augmentations.length > 0 && (
                            <Form.Item 
                              labelAlign="left"
                              labelWrap
                              label={
                                <>
                                  <div
                                    style={{
                                      display: "inline",
                                      backgroundColor: "#1890ff",
                                      borderRadius: "15px",
                                      height: "15px",
                                      width: "15px",
                                      marginRight: "22px",
                                    }}
                                  ></div>
                                  <div>{allDataPlaceholder}</div>
                                  
                                </>
                              }
                            >
                              <Space>
                                <Switch
                                  checked={!augmentationHideDefaultAxis}
                                  onChange={() =>
                                    setAugmentationHideDefaultAxis(
                                      !augmentationHideDefaultAxis
                                    )
                                  }
                                />
                              </Space>
                            </Form.Item>
                          )}
                        </>
                      }

                      {!isDateAugmentation && (
                           augmentations.map((augmentation, index) => (
                            <>
                                  <Descriptions key={augmentation.id}>
                                    <Descriptions.Item
                                      label={
                                        <>
                                          <div
                                            style={{
                                              display: "inline",
                                              backgroundColor: augmentation.color,
                                              borderRadius: "15px",
                                              height: "15px",
                                              width: "15px",
                                              marginRight: "22px",
                                            }}
                                          ></div>
                                          {seriesPlaceholder} {index + 1}
                                        </>
                                      }
                                    >
                                      <Col style={{ width: 260 }}>
                                        <Select
                                          value={augmentation.type}
                                          onChange={(data) => {
                                            augmentation.type = data;
                                            augmentation.subType = null;
                                            setAugmentationType(data);
                                          }}
                                          dropdownStyle={{ zIndex: 2000 }}
                                          style={{ width: 200 }}
                                          placeholder={typePlaceholder}
                                        >
                                          {Object.keys(taxonomyGroups)?.map(
                                            (item, index) => (
                                              <Option key={item} value={item}>
                                                {item}
                                              </Option>
                                            )
                                          )}
                                        </Select>
                                        <Select
                                          value={augmentation.subType}
                                          onChange={(demographic) => {
                                            augmentation.subType = demographic;
                                            setAugmentationSubType(demographic);
                                          }}
                                          onDropdownVisibleChange={() => {
                                            setAugmentationType(
                                              augmentation.type
                                            );
                                          }}
                                          dropdownStyle={{ zIndex: 2000 }}
                                          style={{
                                            width: 200,
                                            paddingTop: 10,
                                            marginRight: 10,
                                          }}
                                          placeholder={subTypePlaceholder}
                                        >
                                          {augmentationType?.length !== 0 &&
                                            taxonomyGroups[augmentationType]?.map(
                                              (demographic, idx) => (
                                                <Option
                                                  key={demographic?.value}
                                                  value={demographic?.value}
                                                >
                                                  {demographic.value}
                                                </Option>
                                              )
                                            )}
                                        </Select>
                                        <Button
                                          size="small"
                                          danger
                                          onClick={() => {
                                            onRemoveAugmentation(augmentation.id);
                                            setAugmentationSubType(null);
                                          }}
                                        >
                                          <DeleteOutlined />
                                        </Button>
                                      </Col>
                                    </Descriptions.Item>
                                  </Descriptions>
                            </>
                          )))}
                       {isDateAugmentation &&
                        augmentations.map((augmentation, index) => (
                          <Descriptions key={augmentation.id}           labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}>
                          <Descriptions.Item
                            label={
                              <>
                                <div
                                  style={{
                                    display: "inline",
                                    backgroundColor: augmentation.color,
                                    borderRadius: "15px",
                                    height: "15px",
                                    width: "15px",
                                    marginRight: "22px",
                                  }}
                                ></div>
                                Series {index + 1}
                              </>
                            }
                          >
                            <Space>
                              <RangePicker
                                onChange={(dates) => {
                                  augmentation.startDate = dates[0];
                                  augmentation.endDate = dates[1];
                                }}
                                defaultValue={[
                                  augmentation.startDate,
                                  augmentation.endDate,
                                ]}
                              />
                              <Button
                                size="small"
                                danger
                                onClick={() => onRemoveAugmentation(augmentation.id)}
                              >
                                <DeleteOutlined />
                              </Button>
                            </Space>
                            </Descriptions.Item>
                            </Descriptions>
                         
                        ))}

                      {augmentations.length < seriesColorMap.length && (
                        <div style={{ textAlign: "center" }}>
                          <Button onClick={onAddAugmentation}>
                            {clicktoAddPlaceholder}
                          </Button>
                        </div>
                      )}
                 </Form>
                  </Card>               
                </Col>
              </Row>
            </>
          ) : (
            <Loading />
          )}
        </>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonLine: {
    textAlign: "right",
    flex: 0.5,
    marginTop: "1%",
  },
  centerContent: {
    flex: 3,
    textAlign: "left",
    width: "100%",
  },
  chartContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  mainButton: {
    color: "#516e88",
    display: "inline-block",
    fontSize: "14.2px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: 500,
    height: "36px",
    letterSpacing: 1.25,
    lineHeight: 1.12,
    width: "153px",
  },
  title: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: "14.2px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
  },
}));

export default SentimentedEntitiesWidget;
