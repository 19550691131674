import React, { useState, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/graph';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';

function ClusterChart({ data, id, match, forumId }) {

  const [url, setUrl] = useState(match.url);

  useEffect(() => {
    const chart = renderChart(data, id, setUrl, forumId);

    return function cleanup() {
      chart.dispose();
    }
  }, [data, id]);

  return (
    <React.Fragment>
      {match.url !== url ? <Redirect to={url} /> : ''}
      <div id={id || 'main'} style={{ height: id ? '100%' : '100vh' }}></div>
    </React.Fragment>
  );
}

function renderChart(data, id, setUrl, forumId) {
  const { categories, nodes, sData1 } = data;
  const options = {
    animation: false,
    color: ['#ff5722', '#dce775', '#ffa726'],
    legend: [
      {
        data: categories,
      },
    ],
    series: [
      {
        animation: false,
        categories: categories.map((name) => ({ name })),
        center: [0, 0],
        data: nodes,
        emphasis: {
          lineStyle: {
            width: 10,
          },
        },
        focusNodeAdjacency: false,
        itemStyle: {
          normal: {
            color: '#fff',
            borderColor: '#fff',
            borderWidth: 2,
          },
        },
        layout: 'none',
        lineStyle: {
          color: 'source',
          curveness: 0.3,
        },
        roam: true,
        type: 'graph',
      }
    ],
    title: {
      top: 'bottom',
      left: 'right',
    },
    tooltip: {
      formatter: '{b}',
      trigger: 'item',
    },
  };

  // based on dom in preparation， init instance of ClusterChart
  const myChart = echarts.init(document.getElementById(id || 'main'));
  myChart.setOption(options);

  build(data, id, options);

  let zoomEffect = 1;
  let zoomValue = 1;

  // control zoom in and out
  myChart.on('graphRoam', (params) => {
    let zooming = false;
    if (params.zoom !== undefined) {
      zooming = true;
      zoomEffect = zoomEffect * params.zoom;
      zoomValue = params.zoom;
    }

    let series;
    if (zooming) {
      if (zoomValue < 1.05) {
        series = [{ data: nodes }];
      } else {
        series = [
          {
            symbolSize: 1,
            // use JSON to drop references to maintain integrity of original data
            data: JSON.parse(JSON.stringify(nodes)).map((doc) => {
              doc.symbolSize = 1;
              return doc;
            }),
          }
        ];
      }
    } else {
      series = [{}];
    }

    let len = sData1.length;
    while (len--) {
      const coords = myChart.convertToPixel({ seriesIndex: 0 }, [sData1[len][0], sData1[len][1]]);
      const x = coords[0];
      const y = coords[1];
      series.push({
        center: [x, y],
        radius: [sData1[len][4] * zoomEffect * 30, sData1[len][4] * 20 * zoomEffect]
      });
    }

    myChart.setOption({
      series: series
    });
  });

  myChart.on('click', (handler) => {
    // get id of clicked array
    let arrayIndex = 0;
    if (sData1.length !== 1) {
      arrayIndex = sData1.length - handler.componentIndex;
    }
    if (forumId) {
      setUrl(`/forums/${forumId}/explore/topic/${encodeURIComponent(sData1[arrayIndex][7])}`);
    } else {
      setUrl(`/explore/topics/${encodeURIComponent(sData1[arrayIndex][7])}`);
    }
  });

  return myChart;
}

// build chart
function build(data, id, option) {
  const { sData1, sData2, sData3, nodes } = data;
  const myChart = echarts.init(document.getElementById(id || 'main'));
  const options = Object.assign(option, myChart.getOption());

  options.legend = {
    data: ['positive', 'negative', 'neutral'],
  };

  options.animationDuration = 50;
  options.animationEasingUpdate = 'quinticInOut';
  options.series = [options.series[0]];

  let len = sData1.length;
  // deal with only one filtered topic
  if (len === 1) {
    len -= 1;
    const coords = myChart.convertToPixel({ seriesIndex: 0 }, [sData1[len][0], sData1[len][1]]);
    const x = coords[0];
    const y = coords[1];
    const label = sData1[len][3];
    let color = options.color[2];

    if (sData1[len][2] > sData2[len][2] && sData1[len][2] > sData3[len][2]) {
      color = options.color[0];
    } else if (sData2[len][2] > sData3[len][2] && sData2[len][2] > sData1[len][2]) {
      color = options.color[1];
    }

    nodes[len].itemStyle = {
      normal: {
        borderColor: color,
      }
    };

    options.series[0].data = nodes;

    options.series.push({
      // shifting and resizing the pixel to ensure we show the topic
      center: [x / 100 + 400, y / 100 + 400],
      data: [
        { name: 'negative', value: sData1[len][2] },
        { name: 'positive', value: sData2[len][2] },
        { name: 'neutral', value: sData3[len][2] },
      ],
      hoverAnimation: false,
      itemStyle: {
        normal: {
          borderColor: '#fff',
          borderWidth: 1,
          label: {
            align: 'right',
            color: '#000',
            fontFamily: 'Arial',
            fontSize: 15,
            fontWeight: 'bold',
            formatter: (param) => param.seriesName,
            padding: [sData1[len][2], 0, 0, 0],
            position: 'center',
            show: true,
            textBorderColor: '#eee',
            textBorderWidth: '2px',
          },
          labelLine: {
            show: false,
          },
        },
      },
      name: label,
      radius: [sData1[len][4] * 30, sData1[len][4] * 20],
      selectedMode: 'single',
      tooltip: {
        formatter: '{b}: {c} ({d}%)',
        trigger: 'item',
      },
      type: 'pie',
    });
  }

  while (len--) {
    const coords = myChart.convertToPixel({ seriesIndex: 0 }, [sData1[len][0], sData1[len][1]]);
    const x = coords[0];
    const y = coords[1];
    const label = sData1[len][3];
    let color = options.color[2];

    if (sData1[len][2] > sData2[len][2] && sData1[len][2] > sData3[len][2]) {
      color = options.color[0];
    } else if (sData2[len][2] > sData3[len][2] && sData2[len][2] > sData1[len][2]) {
      color = options.color[1];
    }

    nodes[len].itemStyle = {
      normal: {
        borderColor: color,
      }
    };

    options.series[0].data = nodes;

    options.series.push({
      center: [x, y],
      data: [
        { name: 'negative', value: sData1[len][2] },
        { name: 'positive', value: sData2[len][2] },
        { name: 'neutral', value: sData3[len][2] },
      ],
      hoverAnimation: false,
      itemStyle: {
        normal: {
          borderColor: '#fff',
          borderWidth: 1,
          label: {
            align: 'right',
            color: '#000',
            fontFamily: 'Arial',
            fontSize: 15,
            fontWeight: 'bold',
            formatter: (param) => param.seriesName,
            padding: [sData1[len][2] + 35, 0, 0, 0],
            position: 'center',
            show: false,
            textBorderColor: '#eee',
            textBorderWidth: '2px',
          },
          labelLine: {
            show: false,
          },
        },
      },
      name: label,
      radius: [sData1[len][4] * 30, sData1[len][4] * 20],
      selectedMode: 'single',
      tooltip: {
        formatter: '{b}: {c} ({d}%)',
        trigger: 'item',
      },
      type: 'pie',
    });
  }
  options.animation = true;
  myChart.setOption(options, true);
}

export default withRouter(ClusterChart);