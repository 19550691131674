import axios from '../../http';

export const FETCH_LDA_DATA_LOADING = 'FETCH_LDA_DATA_LOADING';
export const FETCH_LDA_DATA_SUCCESS = 'FETCH_LDA_DATA_SUCCESS';
export const FETCH_LDA_DATA_FAILURE = 'FETCH_LDA_DATA_FAILURE';

function fetchLdaDataLoading() {
  return {
    type: FETCH_LDA_DATA_LOADING,
  };
}

function fetchLdaDataSuccess(payload) {
  return {
    type: FETCH_LDA_DATA_SUCCESS,
    payload,
  };
}

function fetchLdaDataFailure(payload) {
  return {
    type: FETCH_LDA_DATA_FAILURE,
    payload,
  }
}

export function fetchLdaData({clientId, forumId}) {
  return async (dispatch) => {
    try {
      dispatch(fetchLdaDataLoading());
     // const res = await axios.post('/lda/predict',{source_id: '7c71e242-699b-423c-ba9e-adb1b00f3d1d'});
      const res = await axios.post('/lda/predict', (forumId && forumId != '') ? {
        source_id: forumId
      } : {
        client_id: clientId
      });
      if (res.status !== 200 || res.data == null) {
        throw Error(res.statusText);
      }
      return dispatch(fetchLdaDataSuccess(res.data));
    } catch (error) {
      dispatch(fetchLdaDataFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        }
      }));
    }
  }
};