import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { fetchCubeData, fetchQuery } from '../actions/cube';
import { fetchTopicSentiment } from '../actions/topic_sentiment';
import ClusterChart from '../charts/ClusterChart';
import Loading from '../components/Loading';
import NoData from '../components/NoData';
import ComplexSelector from '../components/Selectors/ComplexSelector';
import {
  commentCountByTopic,
  commentCountByTopicAndSentiment,
  dimensionsList,
} from '../queries';

const CONVERSATION_DIM_KEY = 'CommentTopicsDenorm.conversation';
const DIM1_KEY = 'CommentTopicsDenorm.surveyGender';
const DIM2_KEY = 'CommentTopicsDenorm.surveyPosition';

function ClusterChartView() {

  const { forumId } = useParams();

  const [selectedConversationValue, setSelectedConversationValue] = useState('all');
  const [selectedTopicValue, setSelectedTopicValue] = useState('all');
  const [selectedDim1Value, setSelectedDim1Value] = useState('all');
  const [selectedDim2Value, setSelectedDim2Value] = useState('all');

  const { data, loaded } = useSelector((state) => state.topicSentiment);
  const topicOptions = useSelector((state) => state.cube[commentCountByTopic.type].data);
  const conversationOptions = useSelector((state) => formatOptions(state, CONVERSATION_DIM_KEY));
  const dim1Options = useSelector((state) => formatOptions(state, DIM1_KEY));
  const dim2Options = useSelector((state) => formatOptions(state, DIM2_KEY));
  const user = useSelector((state) => state.user);
  const date_range = useSelector((state) => state.date_range);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchCubeData(CONVERSATION_DIM_KEY, dimensionsList.query(CONVERSATION_DIM_KEY, forumId)));
    dispatch(fetchCubeData(DIM1_KEY, dimensionsList.query(DIM1_KEY, forumId)));
    dispatch(fetchCubeData(DIM2_KEY, dimensionsList.query(DIM2_KEY, forumId)));
    dispatch(fetchQuery(commentCountByTopic, user.client_id));
  }, [])


  useEffect(() => {
    const query = commentCountByTopicAndSentiment.query({
      conversationUuid: selectedConversationValue,
      clientUuid: user.client_id,
      forumUuid: forumId,
      wordUuid: selectedTopicValue,
      surveyGender: selectedDim1Value,
      surveyPosition: selectedDim2Value,
    }, date_range.data);
    dispatch(fetchTopicSentiment(user.client_id, forumId, query));
  }, [
    selectedConversationValue,
    selectedTopicValue,
    selectedDim1Value,
    selectedDim2Value,
    date_range
  ]);

  const selectedConversationLabel = getOptionLabel(conversationOptions, selectedConversationValue);

  // Most discussed topics across {thirdLabel} {fourthLabel} participants {firstLabel}
  let heading = 'Most discussed topics';
  if (selectedConversationValue.indexOf('all') === -1) {
    heading += ' about ' + selectedConversationLabel;
  } else {
    heading += ' across all conversations';
  }
  if (selectedDim1Value.indexOf('all') === -1) {
    heading += ', where gender identity is ' + getOptionLabel(dim1Options, selectedDim1Value);
  } else {
    heading += ', across all gender identities';
  }
  if (selectedDim2Value.indexOf('all') === -1) {
    heading += ', and where job position is ' + getOptionLabel(dim2Options, selectedDim2Value);
  } else {
    heading += ', and across all positions';
  }

  const handleOptionChange = (optionType) => (event) => {
    const value = event.target.value;
    console.log("$$value", value);
    switch (optionType) {
      case 'conversation':
        setSelectedConversationValue(value);
        break;

      case 'topic':
        setSelectedTopicValue(value);
        break;

      case 'dim1':
        setSelectedDim1Value(value);
        break;

      case 'dim2':
        setSelectedDim2Value(value);
        break;

      default:
        console.error('Invalid option type:', optionType);
    }
  }

  return (
    <React.Fragment>
      <div className={classes.sectionFilter}>
        <ComplexSelector
          conversationOptions={conversationOptions}
          topicOptions={topicOptions}
          dim1Options={dim1Options}
          dim2Options={dim2Options}
          selectedConversationValue={selectedConversationValue}
          selectedTopicValue={selectedTopicValue}
          selectedDim1Value={selectedDim1Value}
          selectedDim2Value={selectedDim2Value}
          handleOptionChange={handleOptionChange}
        />
      </div>
      <div className={classes.subSectionName}>
        {heading}
      </div>
      <div className={classes.aidSectionName}>
        All topics {selectedConversationLabel ? <span>&gt; {selectedConversationLabel}</span> : ''}
      </div>
      <div className={classes.clusterChartWrap}>
        {loaded ? (
          data.nodes.length ?
            <ClusterChart id="mainCluster" data={data} forumId={forumId} />
            :
            <NoData />
        )
          :
          <Loading />
        }
      </div>
    </React.Fragment>
  );
}

function getOptionLabel(options, value) {
  return (options.find((opt) => opt.value === value) || {}).label;
}

function formatOptions(state, key) {
  const data = (state.cube[key] || {}).data || [];
  return data.map((d) => ({
    label: d[key],
    value: d[key],
  }));
}

const useStyles = makeStyles(() => ({
  aidSectionName: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    lineHeight: 'normal',
    marginBottom: '18px',
  },
  clusterChartWrap: {
    height: '600px',
    textAlign: 'center',
    width: '100%',
  },
  sectionFilter: {
    backgroundColor: '#eceff1',
    marginBottom: '16px',
    minHeight: '108px',
    width: '100%',
    verticalAlign: 'middle',
  },
  subSectionName: {
    color: '#516e88',
    fontSize: '16.3px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.15px',
    lineHeight: 'normal',
    marginBottom: '8px',
    marginTop: '18px',
  },
}));

export default ClusterChartView;