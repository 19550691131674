import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import PanToolIcon from '@material-ui/icons/PanTool';
import UpdateIcon from '@material-ui/icons/Update';
import { makeStyles } from '@material-ui/core/styles';

import { combineStyles } from '../utils';
import commonStyles from './common_styles';
import BookMark from '../components/BookMark';
import { useIntl } from "react-intl"; 

function ReportChunk({
  chunks,
  index,
  section,
  onChange,
  onDelete,
  onSectionDown,
  onSectionUp,
}) {

  const titles = chunks && [...new Set(chunks.map(chunk => chunk.title))];
  const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isOpenUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [sectionTitle, setSectionTitle] = useState(section.content && JSON.parse(section.content).chunks && JSON.parse(section.content).chunks.length && JSON.parse(section.content).chunks[0].title || titles[0]);
  const [selectedChunks, setSelectedChunks] = useState(section.content && JSON.parse(section.content).chunks && JSON.parse(section.content).chunks.filter(chunk => chunk.title === sectionTitle) || []);
  const intl = useIntl();
  const deletePlaceholder = intl.formatMessage({id: 'app.delete',defaultMessage: 'delete'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'cancel'});
  const savePlaceholder = intl.formatMessage({id: 'app.save',defaultMessage: 'save'});
  const dragPlaceholder = intl.formatMessage({id: 'app.reports.edit.drag',defaultMessage: 'Drag'});
  const upPlaceholder = intl.formatMessage({id: 'app.reports.edit.up',defaultMessage: 'Up'});
  const downPlaceholder = intl.formatMessage({id: 'app.reports.edit.down',defaultMessage: 'Down'});
  const updateChunksPlaceholder = intl.formatMessage({id: 'app.reports.edit.updateChunks',defaultMessage: 'Update Chunks'});
  

  const classes = combinedStyles();

  const openDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const openUploadDialog = () => {
    setOpenUploadDialog(true);
  };

  const closeUploadDialog = () => {
    setOpenUploadDialog(false);
  };

  const handleDelete = () => {
    onDelete(index);
  };


  const handleSectionDown = () => {
    onSectionDown(index);
  };

  const handleSectionUp = () => {
    onSectionUp(index);
  };



  const setChunk = (value) => {
    onChange(index, {chunks: value });
    setOpenUploadDialog(false);
  }

  const handleTitleChange = (event) => {
    
    setSectionTitle(event.target.value);
  };

  

  console.log("chunks in ", sectionTitle,chunks.filter(chunk => chunk.title === sectionTitle));
  
  return (
    <div id={section.id} className={classes.sectionWrap}>
      <div className={classes.sectionGreyWrap}>
        <span className={classes.sectionImageTitle}>Chunks</span>
        <div className={classes.topButtonArea}>
          <div id="drag"
            className={classes.buttonWrap}
          >
            <PanToolIcon className={classes.iconStyle} />
            <span>{dragPlaceholder}</span>
          </div>
          <div className={classes.buttonWrap} onClick={handleSectionUp}>
            <ArrowUpwardIcon className={classes.iconStyle} />
            <span>{upPlaceholder}</span>
          </div>
          <div className={classes.buttonWrap} onClick={handleSectionDown}>
            <ArrowDownwardIcon className={classes.iconStyle} />
            <span>{downPlaceholder}</span>
          </div>
          <div className={classes.buttonWrap} onClick={openDeleteDialog}>
            <DeleteIcon className={classes.iconStyle} />
            <span>{deletePlaceholder}</span>
          </div>
          <Dialog
            open={isOpenDeleteDialog}
            onClose={closeDeleteDialog}
            aria-labelledby={'alert-dialog-title-' + index}
            aria-describedby={'alert-dialog-description-' + index}
          >
            <DialogTitle id={'alert-dialog-title-' + index}>
              Confirm delete
            </DialogTitle>
            <DialogContent>
              <DialogContentText id={'alert-dialog-description-' + index}>
                Are you sure you want to delete this section?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={closeDeleteDialog}>
                {cancelPlaceholder}
              </Button>
              <Button
                autoFocus
                color="primary"
                variant="contained"
                onClick={handleDelete}
              >
                {deletePlaceholder}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className={classes.updateButtonWrap} onClick={openUploadDialog}>
          <UpdateIcon className={classes.iconStyle} />
          <span>{updateChunksPlaceholder}</span>
        </div>
        <Dialog
          fullWidth
          maxWidth="md"
          open={isOpenUploadDialog}
          onClose={closeUploadDialog}
          aria-labelledby={'chunk-dialog-title-' + index}
          aria-describedby={'chunk-dialog-description-' + index}
        >
          <DialogTitle id={'chunk-dialog-title-' + index}>
            {`Choose chunks in ${sectionTitle}` }
          </DialogTitle>
          <DialogContent>
            <div className={classes.checkboxWrap}>
              <Checkbox
                classes={{
                  checked: classes.checked,
                  root: classes.checkbox,
                }}
                checked={selectAll}
                onChange={() => {
                  if(selectAll){
                    setSelectedChunks([]);
                  }else{
                    setSelectedChunks(chunks.filter(chunk => chunk.title === sectionTitle));
                  }
                  setSelectAll(!selectAll);
                  
                }}
              />
              <span className={classes.optionText}>Select All</span>
            </div>
            {chunks.filter(chunk => chunk.title === sectionTitle).map((v, i) => (
              <React.Fragment key={i}>
                <div className={classes.divHover}>                  
                    <div className={classes.imageMenuWrap}>
                      <div className={classes.markWrap}>
                        <BookMark isSelectAll={selectAll} relatedChunk={v} selectedChunks={selectedChunks} setSelectedChunks={setSelectedChunks}/>
                      </div>
                      <div className={classes.mainText}>
                        <Typography className={classes.textStyle}>
                        {v.content}
                        </Typography>
                      </div>
                    </div>
                
                </div>
                <Divider style={{ margin: '10px 0' }} />
                
              </React.Fragment>
            ))}
          </DialogContent>
          <DialogActions>
            
            <Button color="secondary" onClick={closeUploadDialog}>
              {cancelPlaceholder}
            </Button>
            <Button color="primary" onClick={() => setChunk(selectedChunks)}>
              {savePlaceholder}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className={classes.chunkTextWrap}>
        {selectedChunks.length>0 ? selectedChunks.filter(chunk => chunk.title === sectionTitle).map(chunk => (
            <div key={chunk.chunk_id} className={classes.chunkTextWrap}>
              <Typography className={classes.textStyle} >
                {chunk.content}
              </Typography>
              <Divider style={{ margin: '10px 0' }} />
            </div>
         )) : null}
      </div>
      
      <InputLabel shrink className={classes.bootstrapFormLabel}>
        Title
      </InputLabel>
      <Select
          fullWidth
          id="title-selector"
          label="ChunkTitle"
          value={sectionTitle}
          onChange={handleTitleChange}
        >
          {titles.map((title, index) => (
            <MenuItem key={index} value={title}>
              {title}
            </MenuItem>
          ))}
      </Select>
       
      
    </div>
  );
}

const styles = {
  buttonWrap: {
    backgroundColor: '#fff',
    borderRadius: '2px',
    display: 'flex',
    marginRight: '4px',
    padding: '2px 4px',
    textAlign: 'center',
  },
  divhover: {
    '&:hover': {
      background: '#efefef',
      border: 'solid #efefef',
    },
  },
  iconStyle: {
    fontSize: '18px',
    paddingRight: '2px',
    opacity: 0.6,
  },
  imageMenuItem: {
    cursor: 'pointer',
    width: '75%',
  },
  imageMenuWrap: {
    //textAlign: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  chunkTextWrap: {
    marginBottom: '15px',
    marginTop: '30px',
    padding: 0,
    position: 'relative',
    textAlign: 'left',
    width: '100%',
  },
  imageWrap: {
    marginBottom: '15px',
  },
  sectionGreyWrap: {
    padding: '10px',
    height: '100%',
  },
  sectionWrap: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    marginBottom: '15px',
    marginTop: '40px',
    padding: '10px 10px 0 10px',
    position: 'relative',
  },
  textFieldInputStyle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 1.41,
    overflowY: 'hidden',
  },
  textFieldWrap: {
    //marginTop: '15px',
    marginBottom: '15px',
    width: '100%',
  },
  topButtonArea: {
    color: '#516e88',
    display: 'flex',
    fontSize: '12.2px',
    position: 'absolute',
    top: '-12px',
    right: '10px',
    textAlign: 'center',
    zIndex: 2,
  },
  textStyle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 1.41,
    marginBottom: '12px',
  },
  markWrap: {
    alignItems: 'center',
    color: '#516e88',
    marginLeft: '1em',
    opacity: 0.38,
    width: '4%'
  },
  mainText: {
    width: '96%'
  },
  checkboxWrap: {
    width: '8%',
    margin: '0em auto 0.5em 0em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  checkbox: {
    color: '#8da4bc',
    padding: 0,
    '&$checked': {
      color: '#8da4bc',
    },
  },
  checked: {

  },
  bootstrapFormLabel: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.4,
  },
};

const combinedStyles = makeStyles(combineStyles(commonStyles, styles));

export default ReportChunk;