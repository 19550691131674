import { gql } from '@apollo/client';
import { setupApi } from '../../graphql/client';
const client = setupApi();

export const FETCH_POWERFUL_STATEMENTS_LOADING = 'FETCH_POWERFUL_STATEMENTS_LOADING';
export const FETCH_POWERFUL_STATEMENTS_SUCCESS = 'FETCH_POWERFUL_STATEMENTS_SUCCESS';
export const FETCH_POWERFUL_STATEMENTS_FAILURE = 'FETCH_POWERFUL_STATEMENTS_FAILURE';

function fetchPowerfulStatementsLoading() {
  return {
    type: FETCH_POWERFUL_STATEMENTS_LOADING,
  };
}

function fetchPowerfulStatementsSuccess(payload) {
  return {
    type: FETCH_POWERFUL_STATEMENTS_SUCCESS,
    payload,
  };
}

function fetchPowerfulStatementsFailure(payload) {
  return {
    type: FETCH_POWERFUL_STATEMENTS_FAILURE,
    payload,
  }
}

export function fetchPowerfulStatements(variables) {
  return async (dispatch) => {
    try {
      dispatch(fetchPowerfulStatementsLoading());
      const forum_id = variables.forum_id == '' ? undefined : variables.forum_id;
      const forumParam = forum_id ? '$forum_id: String!' : '';
      const forumWhereParam = forum_id ? 'forum_id: $forum_id' : '';
      const forumSubWhereParam = forum_id ? 'forum_id: $forum_id' : 'forum_id: null';
      const topicQuery={};
      const subtopic=false;
      const topicParam = Object.keys(topicQuery).length > 0 && !subtopic  ? '$topicsWhere: TopicWhere!' : '';
      const topicWhereParam = Object.keys(topicQuery).length > 0 && !subtopic  ? 'topics: $topicsWhere' : '';

      const startDate = variables.startDate ? variables.startDate : '';
      const endDate = variables.endDate ? variables.endDate : '';
      const dateTimeParam = startDate != '' && endDate != '' ? '$startDate: DateTime!\n$endDate: DateTime!' : '';
      const dateTimeWhereParam  = startDate != '' && endDate != '' ? 'create_datetime_GTE: $startDate, create_datetime_LTE: $endDate ' : ''; 
      const displayLimit = variables.displayLimit;
    //  const minWordLimit = variables.minWordLimit;
      const preppedQuery = `
      query GetPowerfulStatements(
        $client_id: String
        ${forumParam}
        ${dateTimeParam}
        $limit: Int
        $offset:Int
      )
      {
        comments(
          options: {limit: $limit, offset: $offset, sort:[{weighted_score:DESC}, {uid:ASC}]}
          where:{
                  
                    client_id: $client_id
                    weighted_score_GT:0
                    ${forumWhereParam}
                    ${dateTimeWhereParam}             
                }
          )
         {
          uid
          text
          weighted_score
          tokens
          process_datetime
          create_datetime
          conversation {
            uid
            text
           }
         }
      }
      `;

      console.log('gqlQuery', preppedQuery);
      const query = {
        query:  gql`${preppedQuery}`,
        variables: {
          client_id: variables.client_id,
          limit: displayLimit,
          offset: 0,
          forum_id,
          startDate,
          endDate,
        }
      };


      const res = await client.query(query);
      const data = res.data.comments;
      console.log('powerStatements->data', data);
      if (Array.isArray(data)) {
        const results =[];
        for (const comments of data) {
            if (comments.text) {
              results.push(comments);
            }
          }
        return dispatch(fetchPowerfulStatementsSuccess({
          results: results,
        }));
      }
    } catch (error) {
      dispatch(fetchPowerfulStatementsFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        }
      }));
    }
  }
};
