import {
  FETCH_SENTIMENTED_TOPICS_LOADING,
  FETCH_SENTIMENTED_TOPICS_SUCCESS,
  FETCH_SENTIMENTED_TOPICS_FAILURE,
} from '../actions/sentimented_topics';

const initialState = {
  data: [],
  error: null,
  loaded: false,
  loading: true,
};

export function sentimented_topics(state = initialState, action) {
  switch (action.type) {
    case FETCH_SENTIMENTED_TOPICS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_SENTIMENTED_TOPICS_SUCCESS:
      return {
        data: action.payload,
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_SENTIMENTED_TOPICS_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };

    default:
      return state;
  }
}