import { makeStyles } from '@material-ui/core/styles';

import ReportImgContextProvider from '../context/report-context';
import React from 'react';
import S3DropZone from '../components/S3DropZone'
import { useIntl } from "react-intl"; 

import 'react-dropzone-uploader/dist/styles.css'


function LoadData() {
  const classes = useStyles();
  const intl = useIntl();
  const titlePlaceholder = intl.formatMessage({id: 'app.loadData.title',defaultMessage: 'Load data'});
 
  return (
    <ReportImgContextProvider>
      <div>
        <div className={classes.sectionName}>
          {titlePlaceholder}
        </div>
        <div className={classes.sectionContent}>
          <S3DropZone />
        </div>
      </div>
    </ReportImgContextProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  analysedCardWrap: {
    height: '178px',
    marginBottom: '24px',
  },
  cardsWrap: {
    height: '87.5%',
    marginRight: '24px',
    width: '35%',
  },
  commentBarChartWrap: {
    height: '100%',
    width: '65%',
  },
  clusterChartWrap: {
    height: '100%',
    width: '33%',
  },
  forumCardWrap: {
    height: '190px',
  },
  listCardWrap: {
    height: '100%',
    marginRight: '1.5%',
    width: '32%',
    minWidth: '200px',
  },
  moodBarChartWrap: {
    height: '100%',
    marginRight: '24px',
    width: '55%',
  },
  emojifyChartWrap: {
    height: '100%',
    width: '45%',
  },
  sectionContent: {
    width: '100%',
  },
  sectionFirstRow: {
    display: 'inline-flex',
    height: '450px',
    width: '100%',
  },
  sectionName: {
    color: '#516e88',
    fontSize: '34.5px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '41px',
    letterSpacing: '0.25px',
    lineHeight: '41px',
    marginBottom: '24px',
    marginTop: '40px',
    width: '361px',
  },
  sectionSecondRow: {
    display: 'inline-flex',
    height: '650px',
    marginTop: '24px',
    width: '100%',
  },
  sectionThirdRow: {
    display: 'inline-flex',
    height: '400px',
    width: '100%',
  },
}));

export default LoadData;