import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import G6 from '@antv/g6';
import isEmpty from 'lodash.isempty';
import { ForumIdContext } from '../context/forumId-context';

import '@antv/graphin/dist/index.css';
import '@antv/graphin-components/dist/index.css';

import { fetchTopicClusters } from '../actions/topic_clusters';

const BaseConfig = {
  nameFontSize: 12,
  childCountWidth: 22,
  countMarginLeft: 0,
  itemPadding: 16,
  nameMarginLeft: 4,
  rootPadding: 18,
};

function KnowledgeGraph() {

  const graphRef = React.createRef(null);
  const history = useHistory();
  const location = useLocation();
  const forumId = location.pathname.split('/')[2];
  const { setForumId, setForumTitle } = useContext(ForumIdContext);
  
  const user = useSelector((state) => state.user);
  const clusters = useSelector((state) => state.topicClusters.data);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    let graph = null;

    const handleNodeClick = (ev) => {
      if (ev.item._cfg.model.nodeType === "ClusterElement") {
        history.push('/explore/chunks?from=' + location.pathname.slice(1) + '&q=' + encodeURIComponent(ev.item._cfg.model.sourceId));
      }

    };


    if (!isEmpty(clusters)) {
      registerCustomStyles(BaseConfig);
      graph = createGraph(graphRef.current, clusters, BaseConfig);
      graph.on('node:click', handleNodeClick);
    }
    return () => {
      if (graph) {
        graph.off('node:click', handleNodeClick);
        graph.destroy();
      }

    };
  }, [clusters])


  useEffect(() => {
    if (user.info && user.info.forums.length) {
    dispatch(fetchTopicClusters(user.client_id, forumId, user.info.forums));
    }
    setForumId(forumId);
    setForumTitle(user.info.forums.filter(forum => forum.forumId === forumId)[0].forumTitle);
  }, []);

  return (
    <div className={classes.sectionContent}>
      <div className={classes.sectionName}>
        Knowledge Graph
      </div>
      <div className={classes.graphWrap}>
        <div ref={graphRef} id="container" style={{ height: '100%' }}></div>
      </div>
      <p>Click to contract/expand</p>
    </div>
  );
}

function createGraph(container, data, BaseConfig) {
  const tooltip = new G6.Tooltip({
    offsetX: -150,
    offsetY: 150,
    // the types of items that allow the tooltip show up
    itemTypes: ['node'],
    // custom the tooltip's content
    getContent: (e) => {
      const outDiv = document.createElement('div');
      outDiv.style.width = 'fit-content';
      outDiv.style.height = 'fit-content';

      const model = e.item.getModel();
      if (e.item.getType() === 'node') {
        outDiv.innerHTML = model.text ? `${model.text}` : 'Root';
      } else {
        // if edge info needs to be shown
        // const source = e.item.getSource();
        // outDiv.innerHTML = `Parent：${source.getModel().text}}`;
      }
      return outDiv;
    },
  });

  const width = container.scrollWidth;
  const height = container.scrollHeight || 500;
  const graph = new G6.TreeGraph({
    container: 'container',
    width,
    height,
    plugins: [tooltip],
    modes: {
      default: [
        {
          type: 'collapse-expand',
          onChange: function onChange(item, collapsed) {
            const data = item.getModel();
            data.collapsed = collapsed;
            return true;
          },
        },
        'drag-canvas',
        'zoom-canvas',
      ],
    },
    defaultNode: {
      type: 'treeNode',
      size: 26,
      anchorPoints: [
        [0, 0.5],
        [1, 0.5],
      ],
    },
    defaultEdge: {
      type: 'smooth',
    },
    layout: {
      type: 'dendrogram',
      direction: 'LR',
      getId: function getId(d) {
        return d.id;
      },
      getHeight: function getHeight() {
        return 50;
      },
      getWidth: function getWidth(d) {
        if (d.nodeType === 'Question') {
          return 5;
        }
        const labelWidth = d.label ? G6.Util.getTextSize(d.label, BaseConfig.nameFontSize)[0] : 0;
        const width =
          BaseConfig.itemPadding +
          BaseConfig.nameMarginLeft +
          labelWidth +
          BaseConfig.rootPadding +
          BaseConfig.childCountWidth;
        return width;
      },
      getVGap: function getVGap() {
        return 15;
      },
      getHGap: function getHGap() {

        return 30;
      },
    },
  });



  graph.data(data);
  graph.render();
  graph.fitView();

  return graph;
}

function registerCustomStyles(BaseConfig) {

  const minWidth = 60;

  G6.registerNode('treeNode', {
    draw: (cfg, group) => {
      const { label, collapsed, selected, children, depth } = cfg;
      const rootNode = depth === 0;
      const hasChildren = children && children.length !== 0;

      const {
        childCountWidth,
        countMarginLeft,
        itemPadding,
        nameMarginLeft,
        rootPadding,
      } = BaseConfig;

      let width = 0;
      const height = 28;
      const x = 0;
      const y = -height / 2;

      // base text setting to get the width of each text
      const text = group.addShape('text', {
        attrs: {
          text: label,
          x: x * 2,
          y,
          textAlign: 'left',
          textBaseline: 'top',
          fontFamily: 'PingFangSC-Regular',
        },
        cursor: 'pointer',
        name: 'name-text-shape',
      });
      const textWidth = text.getBBox().width;
      width = textWidth + itemPadding + nameMarginLeft;

      width = width < minWidth ? minWidth : width;

      if (!rootNode && hasChildren) {
        width += countMarginLeft;
        width += childCountWidth;
      }
      // set the leaf node edge width to fixed number
      if (!rootNode && !hasChildren) {
        width = 200;
      }
      if (rootNode) {
        width = 100;
      }

      const keyShapeAttrs = {
        x,
        y,
        width,
        height,
        radius: 4,
      };

      // keyShape style of root
      if (rootNode && selected) {
        keyShapeAttrs.fill = '#e8f7ff';
        keyShapeAttrs.stroke = '#e8f7ff';
      }
      const keyShape = group.addShape('rect', {
        attrs: keyShapeAttrs,
        name: 'root-key-shape-rect-shape',
      });

      if (!rootNode) {
        // set node path
        group.addShape('path', {
          attrs: {
            path: [
              ['M', x - 1, 0],
              ['L', width, 0],
            ],
            stroke: '#AAB7C4',
            lineWidth: 1,
          },
          name: 'node-path-shape',
        });
      }

      const mainX = x - 10;
      const mainY = -height + 15;

      // node shape
      if (rootNode) {
        group.addShape('rect', {
          attrs: {
            x: mainX + 50,
            y: mainY,
            width: 50 + 12,
            height,
            radius: 14,
            fill: '#e8f7ff',
            cursor: 'pointer',
          },
          name: 'main-shape',
        });
      }

      let nameColor = 'rgba(0, 0, 0, .65)';
      if (selected) {
        nameColor = '#40A8FF';
      }

      // node text
      if (rootNode) {
        group.addShape('text', {
          attrs: {
            text: 'Root',
            x: mainX + rootPadding + 50,
            y: 1,
            textAlign: 'left',
            textBaseline: 'middle',
            fill: nameColor,
            fontSize: 12,
            fontFamily: 'PingFangSC-Regular',
            cursor: 'pointer',
          },
          name: 'root-text-shape',
        });
      } else {
        group.addShape('text', {
          attrs: {
            text: label,
            x: depth === 1 ? mainX - 30 : (hasChildren ? mainX + 20 : mainX + 50),
            y: y - 5,
            textAlign: 'start',
            textBaseline: 'top',
            fill: nameColor,
            fontSize: 12,
            fontFamily: 'PingFangSC-Regular',
            cursor: 'pointer',
          },
          name: 'not-root-text-shape',
        });
      }

      // show children nodes count if it's not leaf node
      if (!rootNode) {
        const childCountHeight = 12;
        const childCountX = hasChildren ? width - childCountWidth : width - childCountWidth / 2;
        const childCountY = -childCountHeight / 2;

        group.addShape('rect', {
          attrs: {
            width: hasChildren ? childCountWidth : childCountWidth / 2,
            height: 12,
            stroke: collapsed ? '#1890ff' : '#5CDBD3',
            fill: collapsed ? '#fff' : '#E6FFFB',
            x: childCountX,
            y: childCountY,
            radius: 6,
            cursor: 'pointer',
          },
          name: 'child-count-rect-shape',
        });
        group.addShape('text', {
          attrs: {
            text: hasChildren ? `${children?.length}` : '',
            fill: 'rgba(0, 0, 0, .65)',
            x: childCountX + childCountWidth / 2,
            y: childCountY + 12,
            fontSize: 10,
            width: childCountWidth,
            textAlign: 'center',
            cursor: 'pointer',
          },
          name: 'child-count-text-shape',
        });
      }

      return keyShape;
    },
  });

  G6.registerEdge('smooth', {
    draw(cfg, group) {
      const { startPoint, endPoint } = cfg;
      const hgap = Math.abs(endPoint.x - startPoint.x);

      const path = [
        ['M', startPoint.x, startPoint.y],
        [
          'C',
          startPoint.x + hgap / 4,
          startPoint.y,
          endPoint.x - hgap / 2,
          endPoint.y,
          endPoint.x,
          endPoint.y,
        ],
      ];

      const shape = group.addShape('path', {
        attrs: {
          stroke: '#AAB7C4',
          path,
        },
        name: 'smooth-path-shape',
      });
      return shape;
    },
  });
}

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    border: 0,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 800,
  },
  drawerTopSpace: {
    height: '217px',
  },
  graphWrap: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRight: 'none',
    //height: 'calc(100vh - 273px)',
    height: '800px',
    width: 'calc(100% + 48px)',
  },
  panel: {
    height: 'calc(100vh - 273px)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRight: 'none',
    width: '400px',
  },
  sectionContent: {
    width: '100%',
  },
  sectionName: {
    color: '#516e88',
    fontSize: '34.5px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '41px',
    letterSpacing: '0.25px',
    lineHeight: '41px',
    marginBottom: '24px',
    marginTop: '40px',
    width: '100%',
  },
}));

export default KnowledgeGraph;