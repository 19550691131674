import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button, Row, Col, Select } from 'antd';

const { Option } = Select;

const algoOptions = [
  {
    label: 'bm25',
    value: 'bm25',
  },
  {
    label: 'bm25wqe',
    value: 'bm25wqe',
  },
  {
    label: 'bm25-no-stemmer',
    value: 'bm25nostem',
  },
  {
    label: 'bm25wqe-no-stemmer',
    value: 'bm25wqenostem',
  },
  {
    label: 'elasticsearch',
    value: 'es',
  },
  {
    label: 'w2v',
    value: 'w2v',
  },
];

export default () => {

  const [searchInput, setSearchInput] = useState('');
  const [selectedAlgo, setSelectedAlgo] = useState('w2v');

  const history = useHistory();

  const handleChange = (ev) => {
    setSearchInput(ev.target.value);
  };

  const handleSearch = () => {
    history.push('/explore/search-results?from=' + encodeURI(window.location.pathname.slice(1)) + '&q=' + encodeURI(searchInput) + '&algo=' + encodeURI(selectedAlgo));
    setSearchInput('');
  };

  return (
    <Row>
      <Col span={12} offset={6}>
        <div style={{ padding: '50px', textAlign: 'center' }}>
          <img src="/static/images/science.gif" width="400" style={{ margin: '20px 0' }} />
          <Input
            placeholder="Search"
            onPressEnter={handleSearch}
            onChange={handleChange}
          />
          <Select
            dropdownStyle={
              {
                zIndex: 3000,
              }
            }
            style={{
              marginTop: 8,
              minWidth: 150,
            }}
            onChange={setSelectedAlgo}
          >
            {algoOptions.map((o) =>
              <Option key={o.value} value={o.value}>{o.label}</Option>
            )}
          </Select>
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}