import axios from "../http";

export const FETCH_ANALYZED_TEXT_LOADING = "FETCH_ANALYZED_TEXT_LOADING";
export const FETCH_ANALYZED_TEXT_SUCCESS = "FETCH_ANALYZED_TEXT_SUCCESS";
export const FETCH_ANALYZED_TEXT_FAILURE = "FETCH_ANALYZED_TEXT_FAILURE";

export const SAVE_ANALYZED_TEXT_SUCCESS = "SAVE_ANALYZED_TEXT_SUCCESS";
export const SAVE_ANALYZED_TEXT_FAILURE = "SAVE_ANALYZED_TEXT_FAILURE";

export const DELETE_ANALYZED_TEXT_SUCCESS = "DELETE_ANALYZED_TEXT_SUCCESS";
export const DELETE_ANALYZED_TEXT_FAILURE = "DELETE_ANALYZED_TEXT_FAILURE";

function fetchAnalyzedTextLoading() {
  return {
    type: FETCH_ANALYZED_TEXT_LOADING,
  };
}

function fetchAnalyzedTextSuccess(payload) {
  return {
    type: FETCH_ANALYZED_TEXT_SUCCESS,
    payload,
  };
}

function fetchAnalyzedTextFailure(payload) {
  return {
    type: FETCH_ANALYZED_TEXT_FAILURE,
    payload,
  };
}

export function fetchAnalyzedChunks() {
  return async (dispatch) => {
    try {
      dispatch(fetchAnalyzedTextLoading());
      const res = await axios.get("/api/v1/analyseChunks/");
      if (res.status >= 400) {
        throw Error(res.statusText);
      }
      dispatch(fetchAnalyzedTextSuccess(res.data));
      return res.data;
    } catch (error) {
      dispatch(
        fetchAnalyzedTextFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

export function fetchAnalyzedData(ID) {
  return async (dispatch) => {
    try {
      dispatch(fetchAnalyzedTextLoading());
      const res = await axios.get(`/api/v1/getAnalysedData/${ID}/`);
      if (res.status >= 400) {
        throw Error(res.statusText);
      }
      dispatch(fetchAnalyzedTextSuccess(res.data));
      return res.data[0];
    } catch (error) {
      dispatch(
        fetchAnalyzedTextFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

function saveAnalyzedTextSuccess(payload) {
  return {
    type: SAVE_ANALYZED_TEXT_SUCCESS,
    payload,
  };
}

function saveAnalyzedTextFailure(payload) {
  return {
    type: SAVE_ANALYZED_TEXT_FAILURE,
    payload,
  };
}

export function saveAnalyzedChunks(data) {
  return async (dispatch) => {
    try {
      let url;
      let res;

      url = "/api/v1/analyseChunks/";
      res = await axios.post(url, data);
      if (res.status >= 400) {
        throw Error(res.statusText);
      }
      return dispatch(saveAnalyzedTextSuccess(res.data));
    } catch (error) {
      console.error("Error saving report:", error);
      return dispatch(
        saveAnalyzedTextFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

export function updateAnalyzedChunks(ID, data) {
  return async (dispatch) => {
    try {
      let url;
      let res;

      url = (`/api/v1/getAnalysedData/${ID}/`);
      res = await axios.put(url, data);
      if (res.status >= 400) {
        throw Error(res.statusText);
      }
      dispatch(saveAnalyzedTextSuccess(res.data));
      return res.data
    } catch (error) {
      console.error("Error saving report:", error);
      return dispatch(
        saveAnalyzedTextFailure({
          error: {
            type: typeof error,
            message: error.toString(),
          },
        })
      );
    }
  };
}

function deleteAnalyzedTextSuccess(payload) {
  return {
    type: DELETE_ANALYZED_TEXT_SUCCESS,
    payload,
  };
}

function deleteAnalyzedTextFailure(payload) {
  return {
    type: DELETE_ANALYZED_TEXT_FAILURE,
    payload,
  };
}

export function deleteAnalyzedChunks(ID) {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`/api/v1/getAnalysedData/${ID}/`);
      if (res.status < 200 || res.status > 399) {
        throw Error(res.statusText);
      }
      return dispatch(deleteAnalyzedTextSuccess({ID}));
    } catch (error) {
      dispatch(deleteAnalyzedTextFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }))
    }
  };
}


export default {
  fetchAnalyzedChunks,
  saveAnalyzedChunks,
  updateAnalyzedChunks,
  deleteAnalyzedChunks
};
