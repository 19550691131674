import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { useIntl } from "react-intl";
import { Collapse, Popconfirm } from "antd";
import {
  Space,
  Button,
  Drawer,
  Divider,
  Card,
  Radio,
  Tooltip,
  Row,
  Col,
} from "antd";
import { Form, Input, Select, Modal, DatePicker, InputNumber } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import UnitSelector from "../../components/Selectors/UnitSelector";
import { DemographicsContext } from "../../context/demographics-context";

const { Option } = Select;
const { confirm } = Modal;
const { Panel } = Collapse;

const MathTableColumn = ({
  index,
  groupedBy,
  defaultForm,
  columnTitles,
  onChange,
  onDelete,
}) => {
  const { taxonomies, taxonomyMap } = useContext(DemographicsContext);
  const [form, setForm] = useState(
    defaultForm || {
      typeSpec: {},
      functionSpec: {},
    }
  );

  useEffect(() => {
    onChange(form);
  }, [form]);

  return (
    <Collapse
      defaultActiveKey={[form.title && form.title.length > 0 ? null : index]}
    >
      <Panel
        header={form.title}
        key={index}
        extra={
          <a
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <DeleteOutlined />
          </a>
        }
      >
        <Form.Item
          label="Title"
          rules={[
            {
              required: true,
              message: "Please choose a value",
            },
          ]}
        >
          <Input
            defaultValue={defaultForm.title}
            onChange={(e) => setForm({ ...form, ...{ title: e.target.value } })}
            placeholder="Column title"
          />
        </Form.Item>
        <Form.Item
          label="Unique by"
          rules={[
            {
              required: true,
              message: "Please choose a value",
            },
          ]}
        >
          <Select
            dropdownStyle={{
              zIndex: 3000,
            }}
            defaultValue={defaultForm.distinctOn || "none"}
            onChange={(value) => setForm({ ...form, ...{ distinctOn: value } })}
          >
            <Option key={"none"} value={"none"}>
              None
            </Option>
            {taxonomies
              .sort((a, b) => {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
              })
              .map((title, index) => (
                <Option key={index} value={title}>
                  {title}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Function"
          rules={[
            {
              required: true,
              message: "Please choose a value",
            },
          ]}
        >
          <Select
            dropdownStyle={{
              zIndex: 3000,
            }}
            defaultValue={defaultForm.function}
            onChange={(value) => setForm({ ...form, ...{ function: value } })}
          >
            <Option key={0} value={"sum"}>
              Sum
            </Option>
            <Option key={1} value={"mean"}>
              Mean
            </Option>
            <Option key={2} value={"count"}>
              Count
            </Option>
            {columnTitles.length > 2 && (
              <Option key={3} value={"ratio"}>
                Ratio
              </Option>
            )}
          </Select>
        </Form.Item>
        {columnTitles && form.function == "ratio" && (
          <>
            <Form.Item
              label="Ratio Column A"
              rules={[
                {
                  required: true,
                  message: "Please choose a value",
                },
              ]}
            >
              <Select
                showSearch
                dropdownStyle={{
                  zIndex: 3000,
                }}
                defaultValue={
                  defaultForm.functionSpec
                    ? defaultForm.functionSpec.columnPointerA
                    : null
                }
                onChange={(value) =>
                  setForm({
                    ...form,
                    ...{
                      functionSpec: {
                        ...form.functionSpec,
                        ...{ columnPointerA: value },
                      },
                    },
                  })
                }
              >
                {columnTitles
                  .filter((doc) => doc != form.title)
                  .map((title, index) => (
                    <Option key={index} value={title}>
                      {title}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Ratio Column B"
              rules={[
                {
                  required: true,
                  message: "Please choose a value",
                },
              ]}
            >
              <Select
                showSearch
                dropdownStyle={{
                  zIndex: 3000,
                }}
                defaultValue={
                  defaultForm.functionSpec
                    ? defaultForm.functionSpec.columnPointerB
                    : null
                }
                onChange={(value) =>
                  setForm({
                    ...form,
                    ...{
                      functionSpec: {
                        ...form.functionSpec,
                        ...{ columnPointerB: value },
                      },
                    },
                  })
                }
              >
                {columnTitles
                  .filter(
                    (doc) =>
                      (form.functionSpec
                        ? doc != form.functionSpec.columnPointerA
                        : true) && doc != form.title
                  )
                  .map((title, index) => (
                    <Option key={index} value={title}>
                      {title}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </>
        )}

        {form.function && form.function != "ratio" && (
          <Form.Item
            label="Source Column"
            rules={[
              {
                required: true,
                message: "Please choose a value",
              },
            ]}
          >
            <Select
              showSearch
              dropdownStyle={{
                zIndex: 3000,
              }}
              defaultValue={defaultForm.pointer}
              onChange={(value) => setForm({ ...form, ...{ pointer: value } })}
            >
              {taxonomies
                .filter((doc) => doc != groupedBy)
                .sort((a, b) => {
                  if (a < b) return -1;
                  if (a > b) return 1;
                  return 0;
                })
                .map((title, index) => (
                  <Option key={index} value={title}>
                    {title}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}

        {form.pointer && form.function && form.function != "ratio" && (
          <Form.Item
            label="Filter Type"
            rules={[
              {
                required: true,
                message: "Please choose a value",
              },
            ]}
          >
            <Select
              dropdownStyle={{
                zIndex: 3000,
              }}
              defaultValue={defaultForm.type}
              onChange={(value) => setForm({ ...form, ...{ type: value } })}
            >
              <Option key={0} value={"none"}>
                Filter: None
              </Option>
              <Option key={1} value={"filter"}>
                Filter: Value
              </Option>
              <Option key={2} value={"filterMulti"}>
                Filter: Multi-Value
              </Option>
              <Option key={3} value={"filterNumericBetween"}>
                Filter: Number Between
              </Option>
              <Option key={4} value={"comparisonFilter"}>
                Filter: Comparison
              </Option>
            </Select>
          </Form.Item>
        )}

        {form.pointer && form.type == "filter" && (
          <Form.Item
            label="Value"
            rules={[
              {
                required: true,
                message: "Please choose a value",
              },
            ]}
          >
            <Select
              showSearch
              dropdownStyle={{
                zIndex: 3000,
              }}
              defaultValue={
                defaultForm.typeSpec ? defaultForm.typeSpec.value : null
              }
              onChange={(value) =>
                setForm({
                  ...form,
                  ...{ typeSpec: { ...form.typeSpec, ...{ value } } },
                })
              }
            >
              {taxonomyMap[form.pointer]
                .sort((a, b) => {
                  if (a.value < b.value) return -1;
                  if (a.value > b.value) return 1;
                  return 0;
                })
                .map((record, index) => (
                  <Option key={index} value={record.value}>
                    {record.value}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}

        {form.pointer && form.type == "filterMulti" && (
          <Form.Item
            label="Value"
            rules={[
              {
                required: true,
                message: "Please choose a value",
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              dropdownStyle={{
                zIndex: 3000,
              }}
              defaultValue={
                defaultForm.typeSpec ? defaultForm.typeSpec.value : null
              }
              onChange={(value) =>
                setForm({
                  ...form,
                  ...{ typeSpec: { ...form.typeSpec, ...{ value } } },
                })
              }
            >
              {taxonomyMap[form.pointer]
                .sort((a, b) => {
                  if (a.value < b.value) return -1;
                  if (a.value > b.value) return 1;
                  return 0;
                })
                .map((record, index) => (
                  <Option key={index} value={record.value}>
                    {record.value}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}

        {form.pointer && form.type == "filterNumericBetween" && (
          <>
            <Form.Item
              label="Between"
              rules={[
                {
                  required: true,
                  message: "Please choose a value",
                },
              ]}
            >
              <Space>
                <Input
                  defaultValue={
                    defaultForm.typeSpec ? defaultForm.typeSpec.valueA : null
                  }
                  onChange={(e) =>
                    setForm({
                      ...form,
                      ...{
                        typeSpec: {
                          ...form.typeSpec,
                          ...{ valueA: e.target.value },
                        },
                      },
                    })
                  }
                />
                <span> - </span>
                <Input
                  defaultValue={
                    defaultForm.typeSpec ? defaultForm.typeSpec.valueB : null
                  }
                  onChange={(e) =>
                    setForm({
                      ...form,
                      ...{
                        typeSpec: {
                          ...form.typeSpec,
                          ...{ valueB: e.target.value },
                        },
                      },
                    })
                  }
                />
              </Space>
            </Form.Item>
          </>
        )}

        {form.pointer && form.type == "comparisonFilter" && (
          <Card
            style={{ marginBottom: "20px" }}
            bodyStyle={{ paddingBottom: 0 }}
          >
            <Form.Item
              label="Filter Column"
              rules={[
                {
                  required: true,
                  message: "Please choose a value",
                },
              ]}
            >
              <Select
                showSearch
                dropdownStyle={{
                  zIndex: 3000,
                }}
                defaultValue={
                  defaultForm.typeSpec ? defaultForm.typeSpec.pointer : null
                }
                onChange={(value) =>
                  setForm({ ...form, ...{ typeSpec: { pointer: value } } })
                }
              >
                {taxonomies
                  .filter((doc) => doc != groupedBy)
                  .sort((a, b) => {
                    if (a < b) return -1;
                    if (a > b) return 1;
                    return 0;
                  })
                  .map((title, index) => (
                    <Option key={index} value={title}>
                      {title}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            {form.typeSpec && form.typeSpec.pointer && (
              <Form.Item
                label="Filter Type"
                rules={[
                  {
                    required: true,
                    message: "Please choose a value",
                  },
                ]}
              >
                <Select
                  dropdownStyle={{
                    zIndex: 3000,
                  }}
                  defaultValue={
                    defaultForm.typeSpec ? defaultForm.typeSpec.type : null
                  }
                  onChange={(value) =>
                    setForm({
                      ...form,
                      ...{ typeSpec: { ...form.typeSpec, ...{ type: value } } },
                    })
                  }
                >
                  <Option key={1} value={"filter"}>
                    Filter: Value
                  </Option>
                  <Option key={2} value={"filterMulti"}>
                    Filter: Multi-Value
                  </Option>
                  <Option key={3} value={"filterNumericBetween"}>
                    Filter: Number Between
                  </Option>
                </Select>
              </Form.Item>
            )}
            {form.typeSpec &&
              form.typeSpec.pointer &&
              form.typeSpec.type == "filter" && (
                <Form.Item
                  label="Filter Value"
                  rules={[
                    {
                      required: true,
                      message: "Please choose a value",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    dropdownStyle={{
                      zIndex: 3000,
                    }}
                    defaultValue={
                      defaultForm.typeSpec ? defaultForm.typeSpec.value : null
                    }
                    onChange={(value) =>
                      setForm({
                        ...form,
                        ...{ typeSpec: { ...form.typeSpec, ...{ value } } },
                      })
                    }
                  >
                    {taxonomyMap[form.typeSpec.pointer]
                      .sort((a, b) => {
                        if (a.value < b.value) return -1;
                        if (a.value > b.value) return 1;
                        return 0;
                      })
                      .map((record, index) => (
                        <Option key={index} value={record.value}>
                          {record.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            {form.typeSpec &&
              form.typeSpec.pointer &&
              form.typeSpec.type == "filterMulti" && (
                <Form.Item
                  label="Filter Value"
                  rules={[
                    {
                      required: true,
                      message: "Please choose a value",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    dropdownStyle={{
                      zIndex: 3000,
                    }}
                    defaultValue={
                      defaultForm.typeSpec ? defaultForm.typeSpec.value : null
                    }
                    onChange={(value) =>
                      setForm({
                        ...form,
                        ...{ typeSpec: { ...form.typeSpec, ...{ value } } },
                      })
                    }
                  >
                    {taxonomyMap[form.typeSpec.pointer]
                      .sort((a, b) => {
                        if (a.value < b.value) return -1;
                        if (a.value > b.value) return 1;
                        return 0;
                      })
                      .map((record, index) => (
                        <Option key={index} value={record.value}>
                          {record.value}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
          </Card>
        )}

        {form.typeSpec &&
          form.typeSpec.pointer &&
          form.typeSpec.type == "filterNumericBetween" && (
            <>
              <Form.Item
                label="Between"
                rules={[
                  {
                    required: true,
                    message: "Please choose a value",
                  },
                ]}
              >
                <Space>
                  <Input
                    defaultValue={
                      defaultForm.typeSpec ? defaultForm.typeSpec.valueA : null
                    }
                    onChange={(e) =>
                      setForm({
                        ...form,
                        ...{
                          typeSpec: {
                            ...form.typeSpec,
                            ...{ valueA: e.target.value },
                          },
                        },
                      })
                    }
                  />
                  <span> - </span>
                  <Input
                    defaultValue={
                      defaultForm.typeSpec ? defaultForm.typeSpec.valueB : null
                    }
                    onChange={(e) =>
                      setForm({
                        ...form,
                        ...{
                          typeSpec: {
                            ...form.typeSpec,
                            ...{ valueB: e.target.value },
                          },
                        },
                      })
                    }
                  />
                </Space>
              </Form.Item>
            </>
          )}

        {form.function && (
          <>
            <Form.Item
              label="Formatting"
              rules={[
                {
                  required: true,
                  message: "Please choose a value",
                },
              ]}
            >
              <Select
                dropdownStyle={{
                  zIndex: 3000,
                }}
                defaultValue={defaultForm.formatting}
                onChange={(value) =>
                  setForm({ ...form, ...{ formatting: value } })
                }
              >
                <Option value="">None</Option>
                <Option value={"currency"}>Currency</Option>
                <Option value={"percentage"}>Percentage</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Rounding"
              rules={[
                {
                  required: true,
                  message: "Please choose a value",
                },
              ]}
            >
              <Select
                dropdownStyle={{
                  zIndex: 3000,
                }}
                defaultValue={defaultForm.rounding}
                onChange={(value) =>
                  setForm({ ...form, ...{ rounding: value } })
                }
              >
                <Option value="">None</Option>
                <Option value={"rounded-integer"}>Rounded Integer</Option>
                <Option value={"rounded-2-decimals"}>
                  Rounded to 2 decimals
                </Option>
                <Option value={"rounded-3-decimals"}>
                  Rounded to 3 decimals
                </Option>
                <Option value={"rounded-5-decimals"}>
                  Rounded to 5 decimals
                </Option>
              </Select>
            </Form.Item>
          </>
        )}
      </Panel>
    </Collapse>
  );
};

const DashboardChartEditorBlock = ({
  item,
  setItem,
  deleteItem,
  forumDataSources,
}) => {
  const { taxonomies, taxonomyMap } = useContext(DemographicsContext);
  const [showConfig, setShowConfig] = useState(false);
  const [form] = Form.useForm();
  const [tempForm, setTempForm] = useState({});
  const [sourceItem, setSourceItem] = useState(null);
  const [selectedChartType, setSelectedChartType] = useState(null);
  const [chartTempFilterOptions, setChartTempFilterOptions] = useState({});
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const intl = useIntl();
  const deleteChartPlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.deleteChart",
    defaultMessage: "Are you sure you want to delete this chart?",
  });
  const titlePlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.title",
    defaultMessage: "Chart editor",
  });

  const helpText1Placeholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.chartHelpText1",
    defaultMessage: "Select the chart type  from the drop-down list above",
  });
  const helpText2Placeholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.chartHelpText2",
    defaultMessage: "Remember to name this chart",
  });
  const helpText3Placeholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.chartHelpText3",
    defaultMessage: "You can override the frame data source if you wish",
  });
  const helpText4Placeholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.chartHelpText4",
    defaultMessage: "Set the start date for the data",
  });

  const helpTextProbSol1Placeholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.chartProbSolHelpText1",
    defaultMessage:
      "Problem Solution chart has three parts - Positive Situations, Negative Situations, and Solutions",
  });
  const helpTextProbSol2Placeholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.chartProbSolHelpText2",
    defaultMessage:
      "You insert each of these as separate charts in the dashboard (3 across one row is normal)",
  });
  const helpTextProbSol3Placeholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.chartProbSolHelpText3",
    defaultMessage: "You can show up to 10 text chunks for each of these",
  });

  const helpTextPowerStat1Placeholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.chartPowerStatHelpText1",
    defaultMessage:
      "Powerful statements chart will prioritise text chunks with the strongest or most emotional language",
  });

  const chartTitlePlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.chartTitle",
    defaultMessage: "Chart Title:",
  });
  const chartTypePlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.chartType",
    defaultMessage: "Chart Type:",
  });
  const selectChartTypeMsgPlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.chartType.message",
    defaultMessage: "Select chart type",
  });
  const dataSourcePlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.dataSource",
    defaultMessage: "Data Source:",
  });
  const startDatePlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.startDate",
    defaultMessage: "Start Date:",
  });
  const endDatePlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.endDate",
    defaultMessage: "End Date:",
  });
  const problemSolutionTypePlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.problemSolutionType",
    defaultMessage: "Problem Solution Type:",
  });
  const displayRecordLimitPlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.displayRecordLimit",
    defaultMessage: "Display Record Limit:",
  });
  const columnsPlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.columns",
    defaultMessage: "Columns:",
  });
  const rowsPlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.rows",
    defaultMessage: "Rows:",
  });
  const filterOptionsPlaceholder = intl.formatMessage({
    id: "app.dashboardEditor.chartConfig.filterOptions",
    defaultMessage: "Select data to show",
  });
  const savePlaceholder = intl.formatMessage({
    id: "app.save",
    defaultMessage: "Save",
  });
  const cancelPlaceholder = intl.formatMessage({
    id: "app.cancel",
    defaultMessage: "Cancel",
  });
  const okPlaceholder = intl.formatMessage({
    id: "app.ok",
    defaultMessage: "Ok",
  });

  const defaultValues = {
    dataSourceId: "all",
    dataSourceOverride: "false",
    problemSolutionType: "Positive Situations",
    displayLimit: 5,
  //  minWordLimit: 5,
    startDate: "2020-06-01",
    endDate: moment().format("YYYY-MM-DD"),
    autoEndDate: "true",
  };

  useEffect(() => {
    setSourceItem({
      ...defaultValues,
      ...item,
    });
    setTempForm({
      ...defaultValues,
      ...item,
    });
    setSelectedChartType(item.chartType);
  }, [item]);

  useEffect(() => {
    setChartTempFilterOptions(item.filters || {});
  }, [selectedChartType]);

  const updateRecord = (values) => {
    const validKeys = Object.keys(values).filter((key) => {
      return values[key] !== undefined;
    });
    const validValues = validKeys.map((key) => {
      return values[key];
    });
    let validatedObject = _.zipObject(validKeys, validValues);
    if (typeof validatedObject.startDate !== "string") {
      validatedObject.startDate =
        validatedObject.startDate.format("YYYY-MM-DD");
    }
    if (typeof validatedObject.endDate !== "string") {
      validatedObject.endDate = validatedObject.endDate.format("YYYY-MM-DD");
    }

    if (Object.keys(chartTempFilterOptions).length > 0) {
      validatedObject.filters = chartTempFilterOptions;
    }

    setSourceItem((current) => {
      current = {
        ...defaultValues,
        ...current,
        ...validatedObject,
      };
      setItem(current);
      console.log("validatedObject", validatedObject);
      return current;
    });
  };

  const save = () => {
    form.validateFields().then(onFinish).catch(onFinishFailed);
  };

  const remove = () => {
    confirm({
      title: deleteChartPlaceholder,
      icon: <ExclamationCircleOutlined />,
      content: null,
      onOk() {
        deleteItem();
      },
      onCancel() {},
      okText: okPlaceholder,
      cancelText: cancelPlaceholder,
    });
  };

  const onFinish = (values) => {
    setShowConfig(false);
    updateRecord(values);
  };

  const onFormChanged = (changedValues) => {
    setTempForm((current) => {
      return {
        ...defaultValues,
        ...current,
        ...changedValues,
      };
    });
  };

  const onFinishFailed = () => {};

  const renderChartSpecificSettings = () => {
    // todo: these should be broken down in to components to make it neater
    if (selectedChartType == "mood-table") {
      return (
        <>
          <Divider plain>{filterOptionsPlaceholder}</Divider>
          <Form.Item
            label={columnsPlaceholder}
            rules={[
              {
                required: true,
                message: "Please choose a value",
              },
            ]}
          >
            <Select
              dropdownStyle={{
                zIndex: 3000,
              }}
              defaultValue={
                tempForm.filters ? tempForm.filters.comparisonA : null
              }
              onChange={(value) =>
                setChartTempFilterOptions({
                  ...chartTempFilterOptions,
                  ...{ comparisonA: value },
                })
              }
            >
              {taxonomies
                .filter((title) => title != chartTempFilterOptions.comparisonB)
                .map((title, index) => (
                  <Option key={index} value={title}>
                    {title}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={rowsPlaceholder}
            rules={[
              {
                required: true,
                message: "Please choose a value",
              },
            ]}
          >
            <Select
              dropdownStyle={{
                zIndex: 3000,
              }}
              defaultValue={
                tempForm.filters ? tempForm.filters.comparisonB : null
              }
              onChange={(value) =>
                setChartTempFilterOptions({
                  ...chartTempFilterOptions,
                  ...{ comparisonB: value },
                })
              }
            >
              {taxonomies
                .filter((title) => title != chartTempFilterOptions.comparisonA)
                .map((title, index) => (
                  <Option key={index} value={title}>
                    {title}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </>
      );
    }
    if (selectedChartType == "math-table") {
      return (
        <>
          <Divider plain>{filterOptionsPlaceholder}</Divider>
          <Form.Item
            label="Group by"
            rules={[
              {
                required: true,
                message: "Please choose a value",
              },
            ]}
          >
            <Select
              dropdownStyle={{
                zIndex: 3000,
              }}
              defaultValue={tempForm.filters ? tempForm.filters.pointer : null}
              onChange={(value) =>
                setChartTempFilterOptions({
                  ...chartTempFilterOptions,
                  ...{ pointer: value },
                })
              }
            >
              {taxonomies
                .sort((a, b) => {
                  if (a < b) return -1;
                  if (a > b) return 1;
                  return 0;
                })
                .map((title, index) => (
                  <Option key={index} value={title}>
                    {title}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Unique by"
            rules={[
              {
                required: true,
                message: "Please choose a value",
              },
            ]}
          >
            <Select
              dropdownStyle={{
                zIndex: 3000,
              }}
              defaultValue={
                tempForm.filters ? tempForm.filters.distinctOn : null
              }
              onChange={(value) =>
                setChartTempFilterOptions({
                  ...chartTempFilterOptions,
                  ...{ distinctOn: value },
                })
              }
            >
              <Option key={"none"} value={"none"}>
                None
              </Option>
              {taxonomies
                .sort((a, b) => {
                  if (a < b) return -1;
                  if (a > b) return 1;
                  return 0;
                })
                .map((title, index) => (
                  <Option key={index} value={title}>
                    {title}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Divider plain>Columns</Divider>
          {chartTempFilterOptions.columns &&
            chartTempFilterOptions.columns.map((column, index) => (
              <MathTableColumn
                index={index}
                defaultForm={column}
                columnTitles={
                  chartTempFilterOptions.columns
                    ? chartTempFilterOptions.columns.map((r) => r.title)
                    : []
                }
                groupedBy={chartTempFilterOptions.pointer}
                onChange={(valueForm) => {
                  let columns = chartTempFilterOptions.columns;
                  columns[index] = valueForm;
                  setChartTempFilterOptions({
                    ...chartTempFilterOptions,
                    columns: columns,
                  });
                }}
                onDelete={() => {
                  confirm({
                    title: "Are you sure you want to delete this column?",
                    icon: <ExclamationCircleOutlined />,
                    content: null,
                    onOk() {
                      let columns = chartTempFilterOptions.columns;
                      columns.splice(index, 1);
                      setChartTempFilterOptions({
                        ...chartTempFilterOptions,
                        columns: columns,
                      });
                    },
                    onCancel() {},
                    okText: { okPlaceholder },
                    cancelText: { cancelPlaceholder },
                  });
                }}
              />
            ))}
          <Button
            onClick={() =>
              setChartTempFilterOptions({
                ...chartTempFilterOptions,
                ...{ columns: [...(chartTempFilterOptions.columns || []), {}] },
              })
            }
            type="primary"
            style={{ width: "100%", marginTop: "10px" }}
          >
            Add Column
          </Button>
        </>
      );
    }
    if (
      ["problem-solution", "powerful-statements"].includes(selectedChartType)
    ) {
      return (
        <>
          <Divider plain>{filterOptionsPlaceholder}</Divider>
          {/* <Form.Item label={displayRecordLimitPlaceholder}> */}
          {/* <Space> */}
          <Form.Item
            name="displayLimit"
            // noStyle
            initialValue={tempForm.displayLimit}
            rules={[
              {
                required: true,
                message: "Please choose an option",
              },
            ]}
            label={displayRecordLimitPlaceholder}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Select
              style={{ width: 200 }}
              onChange={(value) =>
                setChartTempFilterOptions({
                  ...chartTempFilterOptions,
                  displayLimit: Number(value),
                })
              }
              dropdownStyle={{
                zIndex: 3000,
              }}
            >
              <Option key={"5"} value="5">
                5
              </Option>
              <Option key={"6"} value="6">
                6
              </Option>
              <Option key={"7"} value="7">
                7
              </Option>
              <Option key={"8"} value="8">
                8
              </Option>
              <Option key={"9"} value="9">
                9
              </Option>
              <Option key={"10"} value="10">
                10
              </Option>
            </Select>
          </Form.Item>
          {/* </Space> */}
          {/* </Form.Item> */}
          {/* <Form.Item
            label={"Filter by minimum # words:"}
            name="minWordLimit"
            initialValue={tempForm.minWordLimit}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <InputNumber
              min={5}
              max={20}
              style={{ width: 200 }}
              onChange={(value) =>
                setChartTempFilterOptions({
                  ...chartTempFilterOptions,
                  minWordLimit: Number(value),
                })
              }
            />
          </Form.Item> */}
        </>
      );
    }
  };

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const handleOpenChange = (isOpen) => {
    // Show tooltip for 5 second and then hide it
    if(selectedChartType === undefined)
    {
      setTooltipVisible(isOpen); 
      const timeoutId = setTimeout(() => {
       setTooltipVisible(false);
     }, 5000);
     return () => {
       clearTimeout(timeoutId);
     };
    }
  };
  
  return !sourceItem ? (
    <></>
  ) : (
    <>
      <Space>
        <Button type="dashed" onClick={() => setShowConfig(true)}>
          {sourceItem.text}
        </Button>
        <Button
          className={
            !sourceItem.chartType && !showConfig
              ? "background-pulsate-warning-color"
              : ""
          }
          size="small"
          style={{ cursor: "pointer" }}
          onClick={() => setShowConfig(true)}
        >
          <EditOutlined />
        </Button>
        <Button
          size="small"
          danger
          onClick={remove}
          style={{ cursor: "pointer" }}
        >
          <DeleteOutlined />
        </Button>
      </Space>

      <Drawer
        title={titlePlaceholder}
        width={700}
        closable={true}
        onClose={() => setShowConfig(false)}
        open={showConfig}
        afterOpenChange={handleOpenChange}
        style={{
          zIndex: 2000,
        }}
        extra={
          <Space>
            <Tooltip

              title={
                <ul>
                  <li>{helpText1Placeholder}</li>
                  <li>{helpText2Placeholder}</li>
                  <li>{helpText3Placeholder}</li>
                  <li>{helpText4Placeholder}</li>
                </ul>
              }
              open={tooltipVisible}
              placement="leftBottom"
              onOpenChange={(open) => {
                setTooltipVisible(open); 
              }}
              trigger="hover"
              zIndex="3000"
            >
              <InfoCircleOutlined style={{ fontSize: "22px" }} />
            </Tooltip>
          </Space>
        }
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => setShowConfig(false)}
              style={{ marginRight: 8, borderRadius: "8px", minWidth: "140px" }}
            >
              {cancelPlaceholder}
            </Button>
            <Button
              onClick={save}
              type="primary"
              style={{ float: "right", borderRadius: "8px", minWidth: "140px" }}
            >
              {okPlaceholder}
            </Button>
          </div>
        }
      >
        <Form
          layout="horizontal"
          {...formItemLayout}
          form={form}
          initialValues={{
            layout: "horizontal",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChanged}
        >
          <Row>
            <Col span={16}>
              <Form.Item
                label={chartTitlePlaceholder}
                name="text"
                rules={[
                  {
                    required: true,
                    message: "Please provide a title for this data source",
                  },
                  {
                    min: 3,
                    message:
                      "You must provide a title greater than or equal to 3 characters",
                  },
                  {
                    max: 255,
                    message:
                      "You must provide a title less than or equal to 255 characters",
                  },
                ]}
                initialValue={tempForm.text}
              >
                <Input placeholder="Provide text here ..." />
              </Form.Item>
              <Form.Item
                label={chartTypePlaceholder}
                name="chartType"
                rules={[
                  {
                    required: true,
                    message: "Please choose a chart type",
                  },
                ]}
                initialValue={tempForm.chartType}
              >
                <Select
                  placeholder={selectChartTypeMsgPlaceholder}
                  dropdownStyle={{
                    zIndex: 3000,
                  }}
                  onChange={(value) => setSelectedChartType(value)}
                >
                  <Option key={"sentimented-topics"} value="sentimented-topics">
                    Sentimented Topics
                  </Option>
                  <Option
                    key={"sentimented-entities"}
                    value="sentimented-entities"
                  >
                    Sentimented Entities
                  </Option>
                  {/* <Option key={ 'most-discussed-topics' } value="most-discussed-topics">Most Discussed Topics</Option> */}
                  <Option key={"emojify"} value="emojify">
                    Emotion Chart
                  </Option>
                  {/* <Option key={ 'mood' } value="mood">Sentiment by month</Option>
              <Option key={ 'data-analysed' } value="data-analysed">Documents by month</Option> */}
                  {/* <Option key={ 'topic-ldavis' } value="topic-ldavis">Topic LDAVis</Option> */}
                  <Option key={"problem-solution"} value="problem-solution">
                    Problem Solution
                  </Option>
                  <Option
                    key={"powerful-statements"}
                    value="powerful-statements"
                  >
                    Powerful Statements
                  </Option>
                  {/* <Option key={ 'knowledge-graph' } value="knowledge-graph">Knowledge Graph</Option> */}
                  <Option key={"mood-table"} value="mood-table">
                    {/* Two-way Table */}
                    Heat Map Table
                  </Option>
                  {/* <Option key={ 'math-table' } value="math-table">Metric Table</Option> */}
                </Select>
              </Form.Item>
              
            </Col>
          </Row>
          <Row>
            <Col span={16}>
            {selectedChartType === "problem-solution" && (
                <Form.Item label={problemSolutionTypePlaceholder}>
                  <Space>
                    <Form.Item
                      name="problemSolutionType"
                      noStyle
                      initialValue={tempForm.problemSolutionType}
                      rules={[
                        {
                          required: true,
                          message: "Please choose an option",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: 200 }}
                        dropdownStyle={{
                          zIndex: 3000,
                        }}
                      >
                        <Option
                          key={"positive-situations"}
                          value="Positive Situations"
                        >
                          Positive Situations
                        </Option>
                        <Option
                          key={"negative-situations"}
                          value="Negative Situations"
                        >
                          Negative Situations
                        </Option>
                        <Option key={"solutions"} value="Solutions">
                          Solutions
                        </Option>
                      </Select>
                    </Form.Item>
                  </Space>
                </Form.Item>
              )}
              <Form.Item label={dataSourcePlaceholder}>
                <Space>
                  <Form.Item
                    name="dataSourceOverride"
                    noStyle
                    initialValue={tempForm.dataSourceOverride}
                    rules={[
                      {
                        required: true,
                        message: "Please choose an option",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: 200 }}
                      dropdownStyle={{
                        width: 300,
                        zIndex: 3000,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please choose an option",
                        },
                      ]}
                    >
                      <Option value="false">Inherit from data frame</Option>
                      <Option value="true">Override data frame</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="dataSourceId"
                    noStyle
                    initialValue={tempForm.dataSourceId}
                    rules={[
                      {
                        required: true,
                        message: "Please choose an option",
                      },
                    ]}
                  >
                    {tempForm.dataSourceOverride === "true" && (
                      <Select
                        name="dataSourceId"
                        style={{ width: 200 }}
                        dropdownStyle={{
                          width: 300,
                          zIndex: 3000,
                        }}
                      >
                        <Option key={"all"} value={"all"}>
                          All
                        </Option>
                        {forumDataSources.map((forum) => (
                          <Option key={forum.id} value={forum.id}>
                            {forum.title}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Space>
              </Form.Item>
              <Form.Item
                label={startDatePlaceholder}
                name="startDate"
                initialValue={moment(tempForm.startDate, "YYYY-MM-DD")}
              >
                <DatePicker
                  format={"YYYY-MM-DD"}
                  style={{ width: 200 }}
                  popupStyle={{
                    zIndex: 3000,
                  }}
                />
              </Form.Item>
              <Form.Item label={endDatePlaceholder}>
                <Space>
                  <Form.Item
                    name="autoEndDate"
                    noStyle
                    initialValue={tempForm.autoEndDate}
                    rules={[
                      {
                        required: true,
                        message: "Please choose an option",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: 200 }}
                      dropdownStyle={{
                        zIndex: 3000,
                      }}
                    >
                      <Option value="true">Automatic Today</Option>
                      <Option value="false">Manual Date</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="endDate"
                    noStyle
                    initialValue={moment(tempForm.endDate, "YYYY-MM-DD")}
                    rules={[
                      {
                        required: true,
                        message: "Please choose an option",
                      },
                    ]}
                  >
                    {tempForm.autoEndDate === "false" && (
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        popupStyle={{
                          zIndex: 3000,
                        }}
                      />
                    )}
                  </Form.Item>
                </Space>
              </Form.Item>
            </Col>
            <Col span={8}>
              {selectedChartType === "problem-solution" &&
               <Card title={<span style={{ fontSize: '14px' }}>Info</span>} bodyStyle={{padding: "2px"}} style={{ width: "100%",fontSize:"12px", backgroundColor:"#f5f5f5"}}>
              <ul>
                <li>{helpTextProbSol1Placeholder}</li>
                <li>{helpTextProbSol2Placeholder}</li>
                <li>{helpTextProbSol3Placeholder}</li>
              </ul>
              </Card>
            }
            {selectedChartType === "powerful-statements" &&
            <Card title={<span style={{ fontSize: '14px' }}>Info</span>} bodyStyle={{padding: "2px"}} style={{ width: "100%",fontSize:"12px", backgroundColor:"#f5f5f5"}}>
              <ul>
                <li>{helpTextPowerStat1Placeholder}</li>
              </ul>
            </Card>
            }
            </Col>
          </Row>
          {renderChartSpecificSettings()}
        </Form>
      </Drawer>
    </>
  );
};

export default DashboardChartEditorBlock;
