import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { fetchEmojiLabels } from '../actions/emoji_labels';
import EmojifyChart from '../charts/EmojifyChart';
import Loading from '../components/Loading';
import { ForumIdContext } from '../context/forumId-context';

function EmojifyChartView() {

  const { data, loaded } = useSelector((state) => state.emojiLabels);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { forumId } = useContext(ForumIdContext);

  useEffect(() => {
    dispatch(fetchEmojiLabels(forumId, user.info.clientId));
  }, [forumId]);

  const handleClick = (data) => {
    history.push('/explore/chunks?from=' + location.pathname.slice(1) + '&q=' + encodeURI(data.label.toLowerCase()));
   
  }


  return (
    <div className={classes.sectionContent}>
      <div className={classes.sectionName}>
        Emojify View
      </div>
      <div className={classes.graphWrap}>
        {loaded ?
          <div>
            <EmojifyChart data={data} onClick={handleClick} scale={2} fontSize={18} />
          </div>
          :
          <Loading />
        }
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    border: 0,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 800,
  },
  drawerTopSpace: {
    height: '217px',
  },
  graphWrap: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRight: 'none',
    height: 'calc(100vh - 273px)',
    width: 'calc(100% + 48px)',
  },
  panel: {
    height: 'calc(100vh - 273px)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRight: 'none',
    width: '400px',
  },
  sectionContent: {
    width: '100%',
  },
  sectionName: {
    color: '#516e88',
    fontSize: '34.5px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '41px',
    letterSpacing: '0.25px',
    lineHeight: '41px',
    marginBottom: '24px',
    marginTop: '40px',
    width: '100%',
  },
}));

export default EmojifyChartView;