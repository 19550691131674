import { enumerate } from '../utils';

const MAX_RESULTS = 4;
const NEUTRAL_SENTIMENT = 5;

const excludes = ['no topics', 'no topic', 'no_topic', 'Unknown'];

export function formatMoodTable(
  counts,
  dim1List,
  dim2List,
  dim1,
  dim2
) {

  // initialize table values
  const table = {};

  for (let x of dim1List) {
    table[x] = {};
    for (let y of dim2List) {
      table[x][y] = [];
    }
  }

  for (let item of counts) {
    let x = item[dim1];
    let y = item[dim2];
    let sentiment;
    try {
      sentiment = parseInt(item['CommentTopicsDenorm.sentiment'], 10);
    } catch (e) {
      sentiment = 4;
    }
    if (isNaN(sentiment)) {
      sentiment = 4;
    }
    try {
      table[x][y].push([sentiment, item['CommentTopicsDenorm.count']]);
    } catch (err) {

    }
  }
  const data = [];
  for (let x of dim1List) {
    for (let y of dim2List) {
      data.push({
        x,
        y,
        value: table[x][y].reduce((a, x) => a + x[1], 0), // total count
        data: table[x][y].map((x) => x[1]), // array of counts
        sentiment: table[x][y].map((x) => x[0]), // array of sentiments
      });
    }
  }

  return data;
}

export function transformCommentTextResultSet(rs) {
  const commentsByText = rs.reduce((a, item) => {
    const text = item['CommentTopicsDenorm.text'];
    const topic = item["CommentTopicsDenorm.word"];
    if (!a[text]) {
      a[text] = {
        client: item["CommentTopicsDenorm.client"],
        project: item["CommentTopicsDenorm.forum"],
        emotion: item["CommentTopicsDenorm.sentiment"],
        text: item['CommentTopicsDenorm.text'],
        likes: item["CommentTopicsDenorm.numberLikes"],
        dislikes: item["CommentTopicsDenorm.numberDislikes"],
        thanksForSharing: item["CommentTopicsDenorm.numberThanksForSharing"],
        goodRating: item["CommentTopicsDenorm.numberGoodRating"],
        topics: excludes.includes(topic) ? [] : [topic],
      };
    } else {
      if (!excludes.includes(topic)) {
        a[text].topics.push(item["CommentTopicsDenorm.word"]);
      }
    }
    return a;
  }, {});
  return Object.values(commentsByText);
}

export function transformCommentTopicCountBySentimentResultSet(rs, tsne) {
  const data = {};
  for (const row of rs) {
    const word = row['CommentTopicsDenorm.word'];
    if (excludes.includes(word)) {
      continue;
    }
    let sentiment;
    try {
      sentiment = parseInt(row['CommentTopicsDenorm.sentiment'] || NEUTRAL_SENTIMENT, 10);
    } catch (e) {
      sentiment = NEUTRAL_SENTIMENT;
    }
    if (isNaN(sentiment)) {
      sentiment = NEUTRAL_SENTIMENT;
    }
    if (!data[word]) {
      data[word] = {
        num_times_mentioned: 0,
        sentiment: {
          num_negative_band: 0,
          num_neutral_band: 0,
          num_positive_band: 0,
          avg: 0,
        }
      };
    }
    let sentimentLabel;
    if (sentiment < 4) {
      sentimentLabel = 'num_negative_band';
    } else if (sentiment > 6) {
      sentimentLabel = 'num_positive_band';
    } else {
      sentimentLabel = 'num_neutral_band';
    }
    console.log('exists/word/tsne', tsne[word] !== undefined, word, tsne);
    data[word] = {
      id: word,
      num_times_mentioned: data[word].num_times_mentioned + parseInt(row['CommentTopicsDenorm.count'], 10),
      sentiment: {
        ...data[word].sentiment,
        [sentimentLabel]: data[word].sentiment[sentimentLabel] + sentiment,
      },
      topic: word,
      visualisation: {
        tsne: {
          y: tsne[word]['x'],
          x: tsne[word]['y'],
        }
      },
    };
  }

  return prepData(Object.values(data));
};

function prepData(data) {
  // format positive, negative and neutral data
  let sData1 = data.map((x) => ([
    x.visualisation.tsne.x,
    x.visualisation.tsne.y,
    x.sentiment.num_negative_band,
    x.topic,
    Math.log10(x.num_times_mentioned),
    x.sentiment.num_positive_band,
    x.sentiment.num_neutral_band,
    x.id,
    x.report_id,
  ]));
  sData1.sort((a, b) => {
    if (a[4] < b[4]) {
      return 1;
    }
    else if (a[4] > b[4]) {
      return -1;
    }
    return 0;
  });
  sData1 = sData1.slice(0, 5);

  // x, y, positive
  const sData2 = sData1.map((x) => [x[0], x[1], x[5]]);

  // x, y, neutral
  const sData3 = sData1.map((x) => [x[0], x[1], x[6]]);

  // topic
  const categories = sData1.map((x) => x[3]);

  // x, y, topic, negative
  const nodes = sData1.map((x) => ({
    category: categories.indexOf(x[3]),
    itemStyle: null,
    label: {
      normal: {
        color: '#000',
        show: true,
        textBorderColor: '#eee',
        textBorderWidth: '2px',
      },
    },
    name: x[3],
    symbolSize: x[4],
    value: x[4],
    x: x[0],
    y: x[1],
  }));

  if (nodes.length === 1) {
    nodes[0].symbolSize = 400;
  }

  return {
    categories,
    nodes,
    sData1,
    sData2,
    sData3,
  };
}

export function transformCommentTopicCountByTopicResultSet(rs) {
  const data = [];
  if (rs) {
    rs.sort(sortTopicCounts);
    for (const [i, item] of enumerate(rs)) {
      const label = item['CommentTopicsDenorm.word'];
      if (!excludes.includes(label)) {
        data.push({
          label,
          value: item['CommentTopicsDenorm.wordUuid'],
        });
      }
      if (i === MAX_RESULTS) {
        break;
      }
    }
  }
  return data;
}

function sortTopicCounts(a, b) {
  return b['CommentTopicsDenorm.count'] - a['CommentTopicsDenorm.count'];
}

export function transformEmojifyByTopicAndSentimentResultSet(rs) {
  const data = {};
  for (const row of rs) {
    const topic = row['CommentTopicsDenorm.word'];
    const value = row['CommentTopicsDenorm.sentiment'];
    const count = row['CommentTopicsDenorm.count'];
    if (excludes.includes(topic) || excludes.includes(value) ) {
      continue;
    }
    if (!data[topic]){
      data[topic] = {
        topic,
        value: value * count,
        count: count
      }
    }
    data[topic].value += value * count;
    data[topic].count += count;

  }
  for (const value of Object.values(data)){
    value.avg = Math.round( value.value / value.count *100)/100;
  }

  return Object.values(data);
}
