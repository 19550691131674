import { combineReducers } from 'redux';

import { clients } from './clients';
import { comments } from './comments';
import { cube } from './cube';
import { lda } from '../Overview/reducers/lda';
import { graph } from '../Overview/reducers/knowledge-graph';
import { probsol } from '../Overview/reducers/problem-solution';
import { sentimented_topics } from './sentimented_topics';
import { emojiLabels } from './emoji_labels';
import { emotion } from './emotion';
import { participation } from './participation';
import { platform } from './platform';
import { reports } from './reports';
import { search } from './search';
import { topicClusters } from './topic_clusters';
import { topicSentiment } from './topic_sentiment';
import { topics } from './topics';
import { user } from './user';
import { moodTable } from './mood_table';
import { date_range } from './date_range';
import { entities } from './entities';
import { dashboards } from '../TiledDashboard/reducers';
import { fileUploads } from './s3';
import { cacheLayer } from './cache_layer';
import { strategy_map } from './strategy_map';

export default combineReducers({
  sentimented_topics,
  lda,
  probsol,
  graph,
  clients,
  comments,
  cube,
  emojiLabels,
  emotion,
  participation,
  platform,
  reports,
  search,
  topicClusters,
  topicSentiment,
  topics,
  user,
  moodTable,
  date_range,
  entities,
  dashboards,
  fileUploads,
  cacheLayer,
  strategy_map
});