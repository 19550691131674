import React from 'react';
import { Tooltip } from 'antd';

export const moodListArr = [
  'emoticons-face1',
  'emoticons-face2',
  //'emoticons-face3',
  'emoticons-face4',
  //'emoticons-face5',
  'emoticons-face6',
  'emoticons-face7',
];

export const moodList = [
  {
    id: 0,
    image: 'emoticons-face1',
  },
  {
    id: 1,
    image: 'emoticons-face2',
  },
  // {
  //   id: 2,
  //   image: 'emoticons-face3',
  // },
  {
    id: 2,
    image: 'emoticons-face4',
  },
  // {
  //   id: 4,
  //   image: 'emoticons-face5',
  // },
  {
    id: 3,
    image: 'emoticons-face6',
  },
  {
    id: 4,
    image: 'emoticons-face7',
  },
];

const classes = {
  moodSizeControl: {
    height: '28px',
    width: '28px',
    marginTop: '5px'
  }
};

export function SentimentToEmojiFace({ sentiment, containerStyle={}, emojiStyle={} }) {

  return (
    <Tooltip title={ Math.round(sentiment * 100) / 100 }>
      <div style={{ ... { width: '40px', textAlign: 'center' }, ... containerStyle }}>
        <div className={moodListArr[parseInt(Math.ceil(sentiment)-1)]} style={ { ... classes.moodSizeControl, ... emojiStyle } } />
      </div>
    </Tooltip>
  )
}