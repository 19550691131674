import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
import { Droppable } from "react-beautiful-dnd";

import generateId from './generateId';
import TileBlock from './TileBlock';

export const getDroppableStyles = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "inherit",
  margin: '10px 0'
});

const TiledSet = ({ children, dataSources, onChange, ... props }) => {

  const [ nodes, setNodes ] = useState(null);
  const [ maxNodes, setMaxNodes ] = useState(10000);
  const [ uniqId, setUniqId ] = useState(null);
  const [ typeId, setTypeId ] = useState(null);
  const [ direction, setDirection ] = useState("vertical");

  useEffect(() => {
    setDirection(props.direction || "vertical");
    setNodes(dataSources);
    setUniqId(props.uniqId);
    setTypeId(props.typeId);
    setMaxNodes(props.maxItems || 10000);
  }, [ props ]);

  const setItem = (index) => (value) => {
    dataSources[index] = value;
    onChange(dataSources);
  };

  const updateItem = (index) => (value) => {
    dataSources[index] = {
      ... dataSources[index],
      ... value
    };
    onChange(dataSources);
  };

  const deleteItem = (index) => () => {
    dataSources.splice(index, 1);
    onChange(dataSources);
  };

  const calcSpan = ({ isDraggingOver }) => {
    let arrSize = (nodes.length >= maxNodes) ? nodes.length : (nodes.length + 1);
    return (direction == 'vertical') ? 24 : (24 / arrSize);
  };

  return !(
    uniqId &&
    typeId &&
    nodes
  ) ? <></> :
  (
    <Droppable
      key={ "droppable-" + uniqId }
      droppableId={ "droppable-" + uniqId }
      type={ typeId }
      direction={ direction }
      isDropDisabled={ nodes.length > maxNodes }
    >
      {
        (provided, snapshot) => (
          <>
            <Row
              ref={ provided.innerRef }
              style={ getDroppableStyles(snapshot.isDraggingOver) }
            >
              {
                nodes &&
                nodes.map((node, index) => (
                  <TileBlock
                    span={ calcSpan(snapshot) }
                    direction={ direction }
                    key={ node.id }
                    uniqId={ node.id }
                    index={ index }
                    dataSource={ node }
                    children={ children }
                    setItem={ setItem(index) }
                    deleteItem={ deleteItem(index) }
                    updateItem={ updateItem(index) }
                    renderDraggerOuterItem={ props.renderDraggerOuterItem }
                  />
                ))
              }
              {
                nodes.length < maxNodes &&
                !snapshot.isDraggingOver &&
                props.renderPostArrayItem &&
                <TileBlock
                  span={ calcSpan(snapshot) }
                  direction={ direction }
                  key={ generateId() }
                  uniqId={ generateId() }
                  index={ nodes.length }
                  dataSource={ {} }
                  children={ null }
                  renderDraggerOuterItem={ props.renderPostArrayItem }
                />
              }
              { (direction) == "horizontal" && provided.placeholder }
            </Row>
            { (direction) == "vertical" && provided.placeholder }
          </>
        )
      }
    </Droppable>
  );
};

export default TiledSet;