import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse, Typography, Layout, Input  } from 'antd';
import { useIntl } from "react-intl"; 
import { DemographicsContext } from '../../context/demographics-context';

const { Panel } = Collapse;
const { Title } = Typography;
const { Sider, Content } = Layout;

const classes = {
  filterName: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '15.3px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.24px',
    lineHeight: 'normal',
    padding: '0px 17px 5px 0px',
  },
};

export default ({ children }) => {

  const { taxonomyMap } = useContext(DemographicsContext);
  const [ filteredTaxonomyMap, setFilteredTaxonomyMap ] = useState({});
  const [ filterValue, setFilterValue ] = useState('');
  const [ taxonomyGroups, setTaxonomyGroups ] = useState([]);
  const intl = useIntl();
  const taxonomyExpPlaceholder = intl.formatMessage({id: 'app.taxonomyExplorer.title',defaultMessage: 'Taxonomy Explorer'})
  const filtersPlaceholder = intl.formatMessage({id: 'app.taxonomyExplorer.filterResults',defaultMessage: 'Filter results'})

  useEffect(() => {
    let tempTaxonomyMap = {};
    Object.keys(taxonomyMap).map((index) => {
      tempTaxonomyMap[index] = [];

      const values = taxonomyMap[index];
      values.forEach((value) => {
        if (filterValue.length == 0 || value.value.indexOf(filterValue) > -1) {
          tempTaxonomyMap[index].push(value);
        }
      });
    });
    setFilteredTaxonomyMap(tempTaxonomyMap);
  }, [ taxonomyMap, filterValue ]);

  useEffect(() => {
    let tempTaxonomyGroups = {};
    Object.values(filteredTaxonomyMap).map((values) => {
      values.forEach((value) => {
        const key =  value.category +':'+ value.taxonomy;
        if (!tempTaxonomyGroups[key]) {
          tempTaxonomyGroups[key] = [];
        }
        tempTaxonomyGroups[key].push(value);
      });
    });
    setTaxonomyGroups(tempTaxonomyGroups);
  }, [ filteredTaxonomyMap ]);

  return (
    <div style={{ margin: '20px 0 20px 0'}}>
      <Layout>
        <Sider style={{ background: '#fafafa', padding: '10px' }} width={ 350 }>
          <div style={classes.filterName}>{filtersPlaceholder}</div>
          <Input onChange={ (e) => setFilterValue(e.target.value) } />
        </Sider>
        <Content style={{ background: '#fff', padding: '30px', minWidth:'400px' }}>
          <Title>{taxonomyExpPlaceholder}</Title>
          <Collapse>
            {
              Object.keys(taxonomyGroups).map((groupKey) => (
                <Panel header={ groupKey } key={ groupKey }><ul>
                  {
                    taxonomyGroups[groupKey].map((value) =>(
                      <li>
                        <Link to={ '/explore/chunksBy/demographics/' + encodeURIComponent(value.category) + '/' + encodeURIComponent(value.taxonomy) + '/' + encodeURIComponent(value.value) + '/' }>
                          { value.category }:{ value.taxonomy }:{ value.value }
                        </Link>
                      </li>
                    ))
                  }
                </ul>
                </Panel>
              ))
            }
          </Collapse>
        </Content>
      </Layout>
    </div>
  )
}