import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

import { fetchQuery } from '../actions/cube';
import ParticipationCard from '../components/Cards/ParticipationCard';
import Loading from '../components/Loading';
import { participationByAgeGroup, participationByGender } from '../queries';
import { ForumIdContext } from '../context/forumId-context';

function ParticipationChartWidget() {

  const { data: ageGroupData, loaded: ageGroupDataLoaded } =
      useSelector((state) => state.cube[participationByAgeGroup.type]);

  const { data: genderData, loaded: genderDataLoaded } =
      useSelector((state) => state.cube[participationByGender.type]);

  const user = useSelector((state) => state.user);
  const date_range = useSelector((state) => state.date_range);
  const dispatch = useDispatch();
  const {forumId } = useContext(ForumIdContext);

  useEffect(() => {
    dispatch(fetchQuery(participationByAgeGroup, user.client_id, forumId));
    dispatch(fetchQuery(participationByGender, user.client_id, forumId));
  }, [date_range, forumId]);

  const ageGroupStats = getAgeGroupStats(ageGroupData);
  const genderStats = getGenderStats(genderData);

  return (
    ageGroupDataLoaded && genderDataLoaded ?
      <ParticipationCard data={{ ageGroupStats, genderStats }} />
      :
      <Loading />
  );
}

function getAgeGroupStats(rs) {
  if (isEmpty(rs)) {
    return {
      highest: {
        group: '',
        count: 0,
        percent: 0,
      },
      total: 0,
    };
  }

  const total = rs.reduce((a, [_, count]) => {
    a += count;
    return a;
  }, 0);
  const sorted = [...rs];
  sorted.sort((a, b) => {
    if (a[1] < b[1]) {
      return 1;
    } else if (a[1] > b[1]) {
      return 1;
    }
    return 0;
  });

  return {
    highest: {
      group: sorted[0][0],
      count: sorted[0][1],
      percent: total > 0 ? sorted[0][1] / total : 0,
    },
    total,
  };
}

function getGenderStats(rs) {
  let femaleCount = 0;
  let maleCount = 0;
  for (const [identity, count] of rs) {
    const key = identity.toLowerCase();
    // female must come first
    if (key.indexOf('female') !== -1) {
      femaleCount = count;
    } else if (key.indexOf('male') !== -1) {
      maleCount = count;
    }
  }
  const total = maleCount + femaleCount;
  const malePercent = total > 0 ? maleCount / total : 0;
  const femalePercent = total > 0 ? femaleCount / total : 0;

  return {
    female: { count: femaleCount, percent: femalePercent },
    male: { count: maleCount, percent: malePercent },
    total,
  };
}

export default ParticipationChartWidget;