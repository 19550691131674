import {
  FETCH_TOPIC_SENTIMENT_LOADING,
  FETCH_TOPIC_SENTIMENT_SUCCESS,
  FETCH_TOPIC_SENTIMENT_FAILURE,
} from "../actions/topic_sentiment";
import { transformCommentTopicCountBySentimentResultSet } from '../transformers/comment_topics';

const initialState = {
  data: {},
  error: null,
  loaded: false,
  loading: false,
};

export function topicSentiment(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOPIC_SENTIMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_TOPIC_SENTIMENT_SUCCESS:
      // const tsne = action.payload.tsne.reduce((a, x) => {
      //   a[x.topic] = x;
      //   return a;
      // }, state.tsne || {});
      return {
        data: transformCommentTopicCountBySentimentResultSet(action.payload.data, action.payload.tsne || {}),
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_TOPIC_SENTIMENT_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };

    default:
      return state;
  }
}
