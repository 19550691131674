import React from 'react';
import { Card, Button } from 'antd';
import generateId from './generateId';
import { useIntl } from "react-intl"; 
/**
 * Inject an "Add new data frame" button at the end of the data frame
 * draggable list.
 */
export default (setData, dataSourcesCopy) => () => {
  const intl = useIntl()
  const addNewDataFramePlaceholder = intl.formatMessage({id: 'app.dashboardEditor.addNewDataFrame',defaultMessage: 'Click to add a new window for a set of charts (a Data Frame)'});
  const defaultTitlePlaceholder = intl.formatMessage({id: 'app.dashboardEditor.defaultTitle',defaultMessage: 'Give me a name'})
  return (
  <Card
    style={
      {
        backgroundColor: '#f5f5f5',
        border: '1px dashed #333',
        textAlign: 'center'
      }
    }
    bodyStyle={
      {
        padding: '20px'
      }
    }
  >
    <Button
      type="text"
      style={ { width: '100%' } }
      onClick={ () => {
        setData([ ...dataSourcesCopy, ... [ {
          id: generateId(),
          text: defaultTitlePlaceholder,
          rows: [],
          isNew: true
        } ] ]);
      }}
    >
      {addNewDataFramePlaceholder}
    </Button>
  </Card>
  )
}