import {
  FETCH_EMOTION_LOADING,
  FETCH_EMOTION_SUCCESS,
  FETCH_EMOTION_FAILURE,
} from "../actions/emotion";
import { transformEmotionResultSet } from '../transformers/emotion';

const initialState = {
  data: [],
  error: null,
  loaded: false,
  loading: false,
};

export function emotion(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMOTION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_EMOTION_SUCCESS:
      return {
        data: transformEmotionResultSet(action.payload),
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_EMOTION_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };

    default:
      return state;
  }
}