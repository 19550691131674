import {
  FETCH_COMMENTS_LOADING,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
} from "../actions/comment";

const initialState = {
  data: [],
  error: null,
  loaded: false,
  loading: false,
};

export function comments(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_COMMENTS_SUCCESS:
      return {
        data: action.payload,
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_COMMENTS_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };
  
    default:
      return state;
  }
}