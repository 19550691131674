import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { fade } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { Select } from 'antd';
import get from 'lodash/get';

import { useAuth } from '../context/auth-context';
import { ForumIdContext } from '../context/forumId-context';
import { DemographicsContext } from '../context/demographics-context';
import { fetchDemographicTaxonomies } from '../actions/demographics';

import SimpleDateRangePicker from './SimpleDateRangePicker';
import {Context} from "./LanguageWrapper";
import { useIntl } from "react-intl"; 

const { Option } = Select;

function TopBar({ homePath, platosDataMenuOptions }) {

  const { setForumId, setForumTitle } = useContext(ForumIdContext);
  const { setTaxonomies, setTaxonomyMap } = useContext(DemographicsContext);
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [platosDataAnchor, setPlatosDataAnchor] = useState(null);
  const [accountAnchor, setAccountAnchor] = useState(null);
  const [dateRangeAnchor, setDateRangeAnchor] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [selectedAlgo, setSelectedAlgo] = useState('w2v');
  const [selectedForum, setSelectedForum] = useState('all');
  const user = useSelector((state) => state.user);
  const context = useContext(Context);
  const forumOptions = useSelector((state) => get(state, 'user.info.forums', []).map((f) => ({
    label: f.forumTitle,
    value: f.forumId,
  })));

  const algoOptions = [
    {
      label: 'bm25',
      value: 'bm25',
    },
    {
      label: 'bm25wqe',
      value: 'bm25wqe',
    },
    {
      label: 'bm25-no-stemmer',
      value: 'bm25nostem',
    },
    {
      label: 'bm25wqe-no-stemmer',
      value: 'bm25wqenostem',
    },
    {
      label: 'elasticsearch',
      value: 'es',
    },
    {
      label: 'w2v',
      value: 'w2v',
    },
  ];

  const classes = useStyles();

  useEffect(()=> {
    if (location.pathname.split('/')[1]==='forum') {
      const forumId = location.pathname.split('/')[2];
      setForumId(forumId);
      const forum = platosDataMenuOptions.filter((forum)=>forum.id===forumId);
      const title = forum.length>0? forum[0].title: null;
      setForumTitle(title);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!user || !user.client_id) {
        return;
      }

      const taxonomiesMap = await dispatch(fetchDemographicTaxonomies(user.client_id));
      setTaxonomyMap(taxonomiesMap);
      setTaxonomies(Object.keys(taxonomiesMap));
    })();
  }, [ user ]);

  const closePlatosDataMenu = () => {
    setPlatosDataAnchor(null);
  };

  const closeAccountMenu = () => {
    setAccountAnchor(null);
  };

  const closeDateRangeMenu = () => {
    setDateRangeAnchor(null);
  };

  const openPlatosDataMenu = (event) => {
    setPlatosDataAnchor(platosDataAnchor ? null : event.currentTarget);
  };

  const openAccountMenu = (event) => {
    setAccountAnchor(accountAnchor ? null : event.currentTarget);
  };

  const openDateRangeMenu = (event) => {
    setDateRangeAnchor(dateRangeAnchor ? null : event.currentTarget);
  };

  const handleChange = (ev) => {
    setSearchInput(ev.target.value);
  };

  const handleKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      history.push('/explore/search-results?from=' + encodeURI(location.pathname.slice(1)) + '&q=' + encodeURI(searchInput) + '&sourceId=' + encodeURI(selectedForum) + '&algo=' + encodeURI(selectedAlgo));
      //setSearchInput('');
    }
  };

  const intl = useIntl();
  const searchPlaceholder = intl.formatMessage({id: 'app.top.searchBox',defaultMessage: 'Search…'})

  const platosDataMenuOpen = Boolean(platosDataAnchor);
  const accountMenuOpen = Boolean(accountAnchor);
  const dateRangeMenuOpen = Boolean(dateRangeAnchor);

  const platosDataPopperId = platosDataMenuOpen ? 'platosdata-popper' : undefined;
  const accountPopperId = accountMenuOpen ? 'account-popper' : undefined;
  const dateRangePopperId = dateRangeMenuOpen ? 'dateRange-popper' : undefined;

  return (
    <div className={classes.root}>
      {/* Below code for date filter is not required as it's being handle from edit dashboard section.
      Commenting the code for now so we can reuse it in future if required */}

      {/* <IconButton
          classes={{
            colorPrimary: classes.dateRangeIconStyle,
            root: dateRangeMenuOpen ? classes.dateRangeClickedStyle : classes.dateRangeStyle,
          }}
          aria-describedby={dateRangePopperId}
          aria-owns={dateRangeMenuOpen ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          color="inherit"
          onClick={openDateRangeMenu}
        >
          <DateRangeIcon className={classes.dateRangeIconStyle} />
      </IconButton> */}

      <div className={classes.accountActions}>
        <div className={classes.searchContainer}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={searchPlaceholder}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              value={searchInput}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* <Select
          dropdownStyle={
            {
              zIndex: 3000,
            }
          }
          style={{
            marginTop: 8,
            minWidth: 150,
          }}
          onChange={setSelectedAlgo}
        >
          {algoOptions.map((o) =>
            <Option key={o.value} value={o.value}>{o.label}</Option>
          )}
        </Select>
        <Select
          dropdownStyle={
            {
              zIndex: 3000,
            }
          }
          style={{
            marginTop: 8,
            minWidth: 150,
          }}
          onChange={setSelectedForum}
        >
          {forumOptions.map((o) =>
            <Option key={o.value} value={o.value}>{o.label}</Option>
          )}
        </Select> */}
        <Select dropdownStyle={
            {
              zIndex: 3000,
            }
          }
          style={{
            marginLeft:10,
            marginTop: 8,
            minWidth: 150,
          }}
          value={context.locale} onChange={context.selectLang}>
          <Option value="english">English</Option>
          <Option value="japanese">Japanese</Option>
          <Option value="hindi">Hindi</Option>
        </Select>
        <IconButton
          classes={{
            colorPrimary: classes.accountIconStyle,
            root: accountMenuOpen ? classes.accountClickedStyle : classes.accountStyle,
          }}
          aria-describedby={accountPopperId}
          aria-owns={accountMenuOpen ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          color="inherit"
          onClick={openAccountMenu}
        >
          <AccountCircleIcon className={classes.accountIconStyle} />
        </IconButton>
      </div>
      <Popper id={accountPopperId}
        open={platosDataMenuOpen}
        anchorEl={platosDataAnchor}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow id="platosdata-menu-list-grow"
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper className={classes.menuListStyle}>
              <ClickAwayListener onClickAway={closePlatosDataMenu}>
                <MenuList className={classes.menuListWrap}>
                  {platosDataMenuOptions.map(({ id, path, title }) => (
                    <NavLink key={path} to={path} onClick={(e) => {
                      if(!id){
                        setForumId('');
                        setForumTitle('');

                      }
                      setForumId(path.split('/')[2]);

                      setForumTitle(title);
                    }}>
                      <ListItem key={id}
                        button
                        classes={{
                          root: classes.rootListBtn,
                          select: classes.selectBtn
                        }}
                        onClick={closePlatosDataMenu}
                      >
                        <ListItemText
                          classes={{
                            root: classes.rootTitleListItemText,
                            primary: classes.primaryTitleListItemText
                          }}
                          primary={title}
                        />
                      </ListItem>
                    </NavLink>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Popper id={dateRangePopperId}
        open={dateRangeMenuOpen}
        anchorEl={dateRangeAnchor}
        transition
        disablePortal
        style={ { zIndex: 1300 } }
      >
        {({ TransitionProps, placement }) => (
          <Grow id="date-range-menu-list-grow"
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper className={classes.dateRangeListStyle}>
              <ClickAwayListener onClickAway={closeDateRangeMenu}>
                <MenuList className={classes.dateRangeWrap}>
                  <SimpleDateRangePicker isOpen={dateRangeMenuOpen} closeDateRangeMenu={closeDateRangeMenu}/>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Popper id={platosDataPopperId}
        open={accountMenuOpen}
        anchorEl={accountAnchor}
        transition
        disablePortal
        style={ { zIndex: 1300 } }
      >
        {({ TransitionProps, placement }) => (
          <Grow id="account-menu-list-grow"
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
            {...TransitionProps}
          >
            <Paper className={classes.menuListStyle}>
              <ClickAwayListener onClickAway={closeAccountMenu}>
                <MenuList className={classes.menuListWrap}>
                  <ListItem
                    button
                    classes={{
                      root: classes.rootListBtn,
                      select: classes.selectBtn
                    }}
                    onClick={ () => { window.open("https://support.plaetos.com"); } }
                  >
                    <ListItemText
                      classes={{
                        root: classes.rootTitleListItemText,
                        primary: classes.primaryTitleListItemText
                      }}
                      primary="Help"
                    />
                  </ListItem>
                  <Divider className={classes.dividerStyle} />
                  <ListItem
                    button
                    classes={{
                      root: classes.rootListBtn,
                      select: classes.selectBtn
                    }}
                    onClick={closeAccountMenu}
                  >
                    <ListItemText
                      classes={{
                        root: classes.rootTitleListItemText,
                        primary: classes.primaryTitleListItemText
                      }}
                      primary="Profile settings"
                    />
                  </ListItem>
                  <Divider className={classes.dividerStyle} />
                  <ListItem
                    button
                    classes={{
                      root: classes.rootListBtn,
                      select: classes.selectBtn
                    }}
                    onClick={logout}
                  >
                    <ListItemText
                      classes={{
                        root: classes.rootTitleListItemText,
                        primary: classes.primaryTitleListItemText
                      }}
                      primary="Logout"
                    />
                  </ListItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  accountActions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    // position: 'absolute',
    right: 0,
  },
  accountClickedStyle: {
    backgroundColor: '#1890ff',
    borderRadius: 0,
    borderRight: 'none',
    color: '#ffffff',
    fontSize: '14px',
    opacity: 1,
    padding: '9px 11px',
  },
  accountIconStyle: {
    fontSize: '30px',
  },
  dateRangeIconStyle: {
    fontSize: '30px',
  },
  dateRangeClickedStyle: {
    backgroundColor: '#1890ff',
    borderRight: 'none',
    color: '#ffffff',
    fontSize: '14px',
    opacity: 1,
    padding: '9px 11px',
    borderRadius: 0,
    '&:hover': {
      color: '#555',
    }
  },
  dateRangeStyle: {
    backgroundColor: '#fff',
    borderRight: 'none',
    color: '#555',
    fontSize: '14px',
    opacity: 1,
    padding: '9px 11px',
    '&:hover': {
      borderRadius: 0,
    }
  },
  accountStyle: {
    backgroundColor: '#fff',
    borderRadius: 0,
    borderRight: 'none',
    color: '#555',
    fontSize: '14px',
    opacity: 1,
    padding: '9px 11px',
    '&:hover': {
      borderRadius: 0,
    }
  },
  bottomTextStyle: {
    color: '#ffffff',
    fontSize: '14.2px !important',
    opacity: 1,
    paddingRight: 0,
    textAlign: 'right',
    textDecoration: 'underline',
  },
  dividerStyle: {
    backgroundColor: '#efefef',
    opacity: 0.2,
  },
  logoStyle: {
    color: '#ffffff',
    fontSize: '20px !important',
    paddingLeft: '16px',
    paddingRight: '24px',
    opacity: 1,
  },
  menuClickedStyle: {
    backgroundColor: '#1890ff',
    borderRadius: 0,
    borderRight: 'none',
    color: '#ffffff',
    fontSize: '14px',
    opacity: 1,
    width: '164px',
  },
  menuListStyle: {
    backgroundColor: '#1890ff',
    borderRadius: 0,
    color: '#ffffff',
    // left: '52px',
    left: '5px',
    position: 'relative',
    width: '267px',
  },
  dateRangeListStyle: {
    backgroundColor: '#fff',
    borderRadius: 0,
    color: '#555',
    left: '40px',
    position: 'relative',
    width: 'auto',
    border: '3px solid #1890ff',
    padding: '5px'
  },
  menuListWrap: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  dateRangeWrap: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  menuStyle: {
    backgroundColor: '#fff',
    borderRadius: 0,
    borderRight: 'none',
    color: '#000',
    fontSize: '14px',
    opacity: 1,
    width: '164px',
  },
  primaryTitleListItemText: {
    color: '#ffffff',
    fontSize: '14.2px !important',
  },
  root: {
    backgroundColor: '#fff',
    color: '#555',
    display: 'flex',
    flexDirection: 'row',
    height: '48px',
    lineHeight: '48px',
    zIndex: 1200,
  },
  rootAvatarIcon: {
    height: '28px',
    marginRight: '8px',
    width: '28px',
  },
  rootListBtn: {
    color: '#ffffff',
    fontSize: '14px',
    height: '48px',
    paddingLeft: '24px',
  },
  rootListItemIcon: {
    color: '#ffffff',
    marginRight: '8px',
  },
  rootTitleListItemText: {
    color: '#ffffff',
    padding: 0,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.07),
    },
    height: '32px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    color: '#555',
  },
  searchContainer: {
    height: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#555',
  },
  inputRoot: {
    color: 'inherit',
    display: 'block',
  },
  inputInput: {
    height: '32px',
    padding: theme.spacing(0, 1, 0, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '40ch',
      },
    },
  },
  selectedBtn: {
    color: '#37474f',
  },
}));

export default TopBar;