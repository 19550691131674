import React, { useState } from "react";
import { Form, Table, Row, Col, Button, Empty } from "antd";
import { FormattedMessage,useIntl } from "react-intl"; 
import MainModal from "./modals/MainModal.js";
import moment from 'moment';

const DataTable = (data) => {
  const [form] = Form.useForm();

  const intl = useIntl();
  const listViewPlaceholder = intl.formatMessage({id: 'app.strategyMap.listView',defaultMessage: 'List View'})
  const clusterViewPlaceholder = intl.formatMessage({id: 'app.strategyMap.clusterView',defaultMessage: 'Cluster View'})
  const tableViewPlaceholder = intl.formatMessage({id: 'app.strategyMap.tableView',defaultMessage: 'Table View'})
  const allPlaceholder = intl.formatMessage({id:"app.strategyMap.all", defaultMessage:"All"})
  const positivePlaceholder = intl.formatMessage({id:"app.strategyMap.positive", defaultMessage:"Positive"})
  const negativePlaceholder = intl.formatMessage({id:"app.strategyMap.negative", defaultMessage:"Negative"})
  const neutralPlaceholder = intl.formatMessage({id:"app.strategyMap.neutral", defaultMessage:"Neutral"})
  const goodQuotesPlaceholder = intl.formatMessage({id:"app.strategyMap.goodQuotes", defaultMessage:"Good Quotes"})

  const {
    dataSource,
    columns,
    onSavePhrase,
    forumId,
    client_id,
    table_dim1,
    table_dim2,
    startDate,
    endDate
  } = data;
  const [infoModal, setInfoModal] = useState(false);
  const [props, setProps] = useState({});
  const DEFAULT_SENTIMENT = 3;
  const showModal = async (doc, type, subType) => {
    let updatedValue = {};
    updatedValue = {
      title: doc.text,
      forumId: forumId,
      client_id: client_id,
      uid: doc.uid,
      type: type,
      subType: subType,
      dim1: table_dim1,
      dim2: table_dim2,
      startDate: moment(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      endDate: moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      actions: [<span></span>],
      useContextRules: false,
      filters: {},
    };
    setProps((data) => ({
      ...data,
      ...updatedValue,
    }));
    setInfoModal(true);
  };

  function formatChunks(chunks) {
    // get rid of html tag like words
    let processedChunks = chunks.map((chunk) => ({
      ...chunk,
      text: chunk.text.replace(/<.*>/i, ""),
    }));
    return processedChunks.map((c) => ({
      plutchik_category: c.emoji
        ? c.emoji.plutchik_category
        : c.plutchik_category,
      process_datetime: c.process_datetime,
      sentiment:
        c.sentiment === null
          ? DEFAULT_SENTIMENT
          : c.sentiment.sentiment
          ? c.sentiment.sentiment
          : c.sentiment,
      text: c.text,
      comment_id: c.uid,
      create_datetime: c.create_datetime,
      chunks: c.chunks,
      demographics: c.demographics,
      user: c.user,
      conversation: c.conversation,
      tokens: c.tokens,
    }));
  }

  const onExpandChild = {
    expandedRowRender: (doc) => (
      <>
        <div style={{ width: "40%" }}>
          {doc.status === 0 ? (
            <Empty description={<span>Processing in progress.</span>}></Empty>
          ) : (
            <table>
              <tbody>
                <tr>
                  <td
                    style={{
                      width: "20px",
                      textAlign: "right",
                      paddingRight: "10px",
                    }}
                  >
                   {allPlaceholder}
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    <Button onClick={() => showModal(doc, "List", "All")}>
                     {listViewPlaceholder}
                    </Button>
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    {doc.status === 2 && (
                      <Button onClick={() => showModal(doc, "Cluster", "All")}>
                        {clusterViewPlaceholder}
                      </Button>
                    )}
                    {doc.status === 1 && <Button loading>{clusterViewPlaceholder}</Button>}
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    <Button onClick={() => showModal(doc, "Table", "All")}>
                      {tableViewPlaceholder}
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "20px",
                      textAlign: "right",
                      paddingRight: "10px",
                    }}
                  >
                   {positivePlaceholder}
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    <Button onClick={() => showModal(doc, "List", "Positive")}>
                    {listViewPlaceholder}
                    </Button>
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    {doc.status === 2 && (
                      <Button
                        onClick={() => showModal(doc, "Cluster", "Positive")}
                      >
                        {clusterViewPlaceholder}
                      </Button>
                    )}
                    {doc.status === 1 && <Button loading> {clusterViewPlaceholder}</Button>}
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    <Button onClick={() => showModal(doc, "Table", "Positive")}>
                    {tableViewPlaceholder}
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "20px",
                      textAlign: "right",
                      paddingRight: "10px",
                    }}
                  >
                     {negativePlaceholder}
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    <Button onClick={() => showModal(doc, "List", "Negative")}>
                    {listViewPlaceholder}
                    </Button>
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    {doc.status === 2 && (
                      <Button
                        onClick={() => showModal(doc, "Cluster", "Negative")}
                      >
                         {clusterViewPlaceholder}
                      </Button>
                    )}
                    {doc.status === 1 && <Button loading> {clusterViewPlaceholder}</Button>}
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    <Button onClick={() => showModal(doc, "Table", "Negative")}>
                    {tableViewPlaceholder}
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "20px",
                      textAlign: "right",
                      paddingRight: "10px",
                    }}
                  >
                     {neutralPlaceholder}
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    <Button onClick={() => showModal(doc, "List", "Neutral")}>
                    {listViewPlaceholder}
                    </Button>
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    {doc.status === 2 && (
                      <Button
                        onClick={() => showModal(doc, "Cluster", "Neutral")}
                      >
                         {clusterViewPlaceholder}
                      </Button>
                    )}
                    {doc.status === 1 && <Button loading> {clusterViewPlaceholder}</Button>}
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    <Button onClick={() => showModal(doc, "Table", "Neutral")}>
                    {tableViewPlaceholder}
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "20px",
                      textAlign: "right",
                      paddingRight: "10px",
                    }}
                  >
                    {goodQuotesPlaceholder}
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    <Button
                      onClick={() => showModal(doc, "List", "Good Quotes")}
                    >
                      {listViewPlaceholder}
                    </Button>
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    {doc.status === 2 && (
                      <Button
                        onClick={() => showModal(doc, "Cluster", "Good Quotes")}
                      >
                         {clusterViewPlaceholder}
                      </Button>
                    )}
                    {doc.status === 1 && <Button loading> {clusterViewPlaceholder}</Button>}
                  </td>
                  <td
                    style={{
                      width: "1%",
                      whiteSpace: "nowrap",
                      paddingRight: "10px",
                    }}
                  >
                    <Button
                      onClick={() => showModal(doc, "Table", "Good Quotes")}
                    >
                       {tableViewPlaceholder}
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </>
    ),
  };

  return (
    <>
      {infoModal && (
        <MainModal
          customProps={props}
          infoModal={infoModal}
          setInfoModal={setInfoModal}
        ></MainModal>
      )}

      <Form form={form} onFinish={onSavePhrase}>
        <Table
          rowKey={(dataSource) => dataSource.uid}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          expandable={onExpandChild}
        ></Table>
      </Form>
    </>
  );
};

export default DataTable;
