import React, { useEffect, useState, useReducer } from "react";
import { useDispatch } from "react-redux";
import { Tabs, Card, Tooltip } from "antd";
import {
  CloseOutlined,
  RedoOutlined,
  ArrowsAltOutlined,
  ShrinkOutlined,
} from "@ant-design/icons";
import { useIntl } from "react-intl";
import {
  getTokensSummary,
  getTokensDescription,
  getChunksList,
  getCommentsList,
} from "../../actions/magicwindow";
import Describe from "./Describe";
import Summarize from "./Summarize";
import Source from "./Source";
import History from "./History";
import * as moment from "moment";
import { Rnd } from "react-rnd";
import {
  fetchAnalyzedChunks,
  saveAnalyzedChunks,
  fetchAnalyzedData,
  updateAnalyzedChunks,
} from "../../actions/text_analysis";
import { openNotification } from "../../utils/Notifications";

export default function MainMagicWindow({ ...props }) {
  const dispatch = useDispatch();
  const [isMaxModal, setIsMaxModal] = useState(false);
  const [modalWidth, setModalWidth] = useState("700px");
  const [modalHeight, setModalHeight] = useState("400px");

  const [averageSentiment, setAverageSentiment] = useState(0);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [sourceChunks, setSourceChunks] = useState([]);
  const [chunkIDs, setChunkIds] = useState([]);
  const [totalChunks, setTotalChunks] = useState(0);
  const [historyData, setHistoryData] = useState([]);
  const [data, setData] = useState({});
  const [activeKey, setActiveKey] = useState("1");
  const { Meta } = Card;
  const [textAnalysis, setTextAnalysis] = useState({});
  const [sortTitle, setSortTitle] = useState("Unsorted");
  const [disableDragging, setDisableDragging] = useState(false);
  const intl = useIntl();
  const analyzeChunksPlaceholder = intl.formatMessage({
    id: "app.explorePage.analyzeChunks",
    defaultMessage: "Analyze Chunks",
  });
  const describePlaceholder = intl.formatMessage({
    id: "app.explorePage.analyzeChunks.tab.describe",
    defaultMessage: "Describe",
  });
  const summarizePlaceholder = intl.formatMessage({
    id: "app.explorePage.analyzeChunks.tab.summarize",
    defaultMessage: "Summarize",
  });
  const sourcePlaceholder = intl.formatMessage({
    id: "app.explorePage.analyzeChunks.tab.source",
    defaultMessage: "Source",
  });
  const historyPlaceholder = intl.formatMessage({
    id: "app.explorePage.analyzeChunks.tab.history",
    defaultMessage: "History",
  });
  const maximizePlaceholder = intl.formatMessage({
    id: "app.maximize",
    defaultMessage: "Maximize",
  });
  const minimizePlaceholder = intl.formatMessage({
    id: "app.minimize",
    defaultMessage: "Minimize",
  });
  const refreshPlaceholder = intl.formatMessage({
    id: "app.refresh",
    defaultMessage: "Refresh",
  });
  const closePlaceholder = intl.formatMessage({
    id: "app.close",
    defaultMessage: "Close",
  });

  useEffect(() => {
    initialiseData();
  }, []);
  useEffect(() => {
    (async () => {
      //Load updated history data
      if (activeKey === "4") {
        let result = await dispatch(fetchAnalyzedChunks());
        console.log("data=>", result);
        setHistoryData(result);
      }
    })();
  }, [activeKey]);

  const initialiseData = () => {
    (async () => {
      if (props.source === "chunk")
        setChunkIds(props.selectedChunks.map((doc) => doc.chunk_id));
      else if (props.source === "comment")
        setChunkIds(props.selectedChunks.map((doc) => doc.comment_id));

      setAverageSentiment(
        parseInt(
          Math.round(
            props.selectedChunks
              .map((doc) => doc.sentiment)
              .reduce((partial_sum, n) => partial_sum + n, 0) /
              props.selectedChunks.length
          )
        )
      );
      let sorting = "Unsorted";
      const sortOrder = props.sorter ? props.sorter.order : "";
      if (sortOrder === "ascend")
        sorting =
          props.sorter.column.title.props.defaultMessage +
          " in ascending order";
      else if (sortOrder === "descend")
        sorting =
          props.sorter.column.title.props.defaultMessage +
          " in descending order";
      setSortTitle(sorting);
      const updatedData = {
        forum_uuid: props.forumId ? props.forumId : "",
        activeSet: props.title,
        sorting: sorting,
        sortingOrder: sortOrder,
        sentimentScore: averageSentiment,
        sourceType: props.source,
        describedText: "",
        abstractText: "",
        keypointsText: "",
        name: "",
        chunksId: [],
      };
      console.log(data);
      setData((data) => ({ ...data, ...updatedData }));
      setTotalChunks(props.selectedChunks.length);
      setSourceChunks(props.selectedChunks);
    })();
  };

  const showDescription = () => {
    (async () => {
      let ids;
      let result;
      if (props.source === "chunk") {
        ids = props.selectedChunks.map((x) => x.chunk_id);
        result = await dispatch(
          getTokensDescription(props.client_id, ids, "Chunk")
        );
      } else if (props.source === "comment") {
        ids = props.selectedChunks.map((x) => x.comment_id);
        result = await dispatch(
          getTokensDescription(props.client_id, ids, "Comment")
        );
      }

      let tags = result.payload.map((doc, index) => {
        return {
          ...doc,
          ...{
            key: index,
          },
        };
      });
      const updatedTokens = { describedText: tags };
      setData((data) => ({
        ...data,
        ...updatedTokens,
      }));
    })();
  };

  const showSummary = () => {
    (async () => {
      let chunksText;
      let result;
      chunksText = props.selectedChunks.map((x) => x.text).join(". ");
      setSummaryLoading(true);
      result = await dispatch(getTokensSummary(chunksText));
      if (result && result.absum_text === "" && result.keypoints_text === "") {
        openNotification(
          "",
          "No summary generated. Please select different chunks and try again",
          "error"
        );
      } else {
        const updatedSummary = {
          abstractText: result.absum_text,
          keypointsText: result.keypoints_text,
        };
        setData((data) => ({
          ...data,
          ...updatedSummary,
        }));
      }
      setSummaryLoading(false);
    })();
  };

  const saveAnalysis = () => {
    (async () => {
      let filename =
        props.title.replace(/\s/g, "") +
        "_" +
        moment().format("DD/MM/YYYY, h:mm:ss a");
      let ids;
      if (props.source === "chunk") {
        ids = props.selectedChunks.map((doc) => doc.chunk_id);
      } else if (props.source === "comment") {
        ids = props.selectedChunks.map((doc) => doc.comment_id);
      }
      const _data = {
        forum_uuid: data.forumId ? data.forumId : "",
        activeSet: data.activeSet,
        sorting: data.sorting,
        sortingOrder: data.sortOrder,
        describedText: data.describedText,
        sentimentScore: averageSentiment,
        sourceType: data.sourceType,
        abstractText: data.abstractText,
        keypointsText: data.keypointsText,
        name: filename,
        chunksId: ids,
      };
      //Check if file is already saved. If yes, update else create
      if (data.name === "") {
        const result = await dispatch(saveAnalyzedChunks(_data));
        if (result.payload && result.payload.error)
          openNotification("", result.payload.error.message, "error");
        else openNotification("", "Successfuly updated", "success");
      } else {
        const result = await dispatch(updateAnalyzedChunks(data.id, _data));
        if (result.payload && result.payload.error)
          openNotification("", result.payload.error.message, "error");
        else openNotification("", "Successfuly updated", "success");
      }
    })();
  };

  const loadUserSelectedData = (id) => {
    (async () => {
      let result = await dispatch(fetchAnalyzedData(id));

      const chunksId = result.chunksId;
      let textList;
      if (result.sourceType === "chunk")
      {
        textList = await dispatch(getChunksList(props.client_id, chunksId));
      }
       
      else if (result.sourceType === "comment")
      {
        textList = await dispatch(getCommentsList(props.client_id, chunksId));
      }
       
      console.log("new data=>", result);

      setAverageSentiment(
        parseInt(
          Math.round(
            textList
              .map((doc) => doc.sentiment.sentiment)
              .reduce((partial_sum, n) => partial_sum + n, 0) / textList.length
          )
        )
      );
      setTotalChunks(textList.length);
      setSourceChunks(textList);
      setData((data) => ({ ...data, ...result }));
      setActiveKey("1");
      openNotification("", "Data successfully loaded", "success");
    })();
  };

  const loadSelectedDataToReport = (id) => {
    (async () => {
      let result = await dispatch(fetchAnalyzedData(id));
      let textList;
      const chunksId = result.chunksId;
      if (result.sourceType === "chunk")
        textList = await dispatch(getChunksList(props.client_id, chunksId));
      else if (result.sourceType === "comment")
        textList = await dispatch(getCommentsList(props.client_id, chunksId));

      const avgSentiment =
        textList
          .map((doc) => doc.sentiment.sentiment)
          .reduce((partial_sum, n) => partial_sum + n, 0) / textList.length;

      result["avgSentiment"] = parseInt(Math.round(avgSentiment));
      setTextAnalysis(result);
      return result;
    })();
  };

  const refreshWindow = () => {
    let updatedChunkIDs;
    if (props.source === "chunk")
      updatedChunkIDs = props.selectedChunks.map((doc) => doc.chunk_id);
    else if (props.source === "comment")
      updatedChunkIDs = props.selectedChunks.map((doc) => doc.comment_id);

    if (chunkIDs.sort().join(",") === updatedChunkIDs.sort().join(",")) {
      openNotification("", "No new data found", "success");
    } else {
      initialiseData();
      openNotification("", "Data successfully refreshed", "success");
    }
  };
  let rndTemp;
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const maximise = () => {
    setIsMaxModal(true);
    setModalWidth("98%");
    setModalHeight("95vh");

    rndTemp.draggable.setState({
      x: 15,
      y: 15,
    });
  };
  const minimise = () => {
    setIsMaxModal(false);
    setModalWidth("700px");
    setModalHeight("400px");
    const { left, top } = rndTemp.offsetFromParent;
    const { x, y } = rndTemp.getDraggablePosition();
    rndTemp.draggable.setState({
      x: x - left,
      y: y - top,
    });
  };

  const handleOnChange = (activeKey) => {
    setActiveKey(activeKey);
  };
  const items = [
    {
      label: sourcePlaceholder,
      key: "1",
      children: <Source chunks={sourceChunks} />,
    },
    {
      label: describePlaceholder,
      key: "2",
      children: (
        <Describe
          tokens={data.describedText}
          client_id={props.client_id}
          averageSentiment={averageSentiment}
          totalChunks={totalChunks}
          title={data.activeSet}
          sorter={data.sorting}
          showDescription={showDescription}
          saveAnalysis={saveAnalysis}
        />
      ),
    },

    {
      label: summarizePlaceholder,
      key: "3",
      children: (
        <Summarize
          averageSentiment={averageSentiment}
          totalChunks={totalChunks}
          title={data.activeSet}
          sorter={data.sorting}
          saveAnalysis={saveAnalysis}
          showSummary={showSummary}
          abstractText={data.abstractText}
          keypointsText={data.keypointsText}
          summaryLoading={summaryLoading}
        />
      ),
    },
    {
      label: historyPlaceholder,
      key: "4",
      children: (
        <History
          historyData={historyData}
          setHistoryData={setHistoryData}
          loadUserSelectedData={loadUserSelectedData}
          loadSelectedDataToReport={loadSelectedDataToReport}
          textAnalysis={textAnalysis}
          setDisableDragging={setDisableDragging}
        />
      ),
    },
  ];

  return (
    <Rnd
      ref={(c) => {
        rndTemp = c;
      }}
      default={{
        x: 10,
        y: 10,
      }}
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "solid 1px #ddd",
        background: "#f0f0f0",
        zIndex: "1000",
      }}
      disableDragging={disableDragging}
      size={{ width: modalWidth, height: modalHeight }}
      maxWidth={"100%"}
      maxHeight={"100%"}
      onResizeStop={(e, direction, ref, delta, position) => {
        parseInt(ref.style.width) >= 400
          ? setModalWidth(ref.style.width)
          : setModalWidth("400px");
        parseInt(ref.style.height) >= 200
          ? setModalHeight(ref.style.height)
          : setModalHeight("200px");
      }}
    >
      <Card
        title={analyzeChunksPlaceholder}
        style={{
          width: "100%",
          height: "100%",
          boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
          zIndex: 9,
          overflow: "auto",
        }}
        extra={
          <>
            {isMaxModal ? (
              <Tooltip title={minimizePlaceholder}>
                {" "}
                <ShrinkOutlined
                  style={{
                    marginRight: "14px",
                    fontSize: "18px",
                  }}
                  onClick={minimise}
                />{" "}
              </Tooltip>
            ) : (
              <Tooltip title={maximizePlaceholder}>
                {" "}
                <ArrowsAltOutlined
                  style={{
                    marginRight: "14px",
                    fontSize: "18px",
                  }}
                  onClick={maximise}
                />
              </Tooltip>
            )}
            <Tooltip title={refreshPlaceholder}>
              <RedoOutlined
                style={{
                  marginRight: "14px",
                  fontSize: "18px",
                }}
                onClick={() => refreshWindow()}
              />
            </Tooltip>
            <Tooltip title={closePlaceholder}>
              {" "}
              <CloseOutlined
                style={{
                  marginRight: "14px",
                  fontSize: "18px",
                }}
                onClick={() => props.setIsShowResizableModal(false)}
              />
            </Tooltip>
          </>
        }
      >
        <Meta description={data.name} />
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onChange={handleOnChange}
          items={items}
        />
      </Card>
    </Rnd>
  );
}
