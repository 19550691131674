import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';

function ComplexSelector({
  conversationOptions,
  topicOptions,
  dim1Options,
  dim2Options,
  selectedConversationValue,
  selectedTopicValue,
  selectedDim1Value,
  selectedDim2Value,
  handleOptionChange,
}) {

  const classes = useStyles();

  return (
    <form className={classes.root} autoComplete="off">
      <FormControl className={classes.margin}>
        <InputLabel shrink
          htmlFor="conversation-select"
          className={classes.bootstrapFormLabel}
        >
          In the data from...
        </InputLabel>
        <Select
          value={selectedConversationValue}
          onChange={handleOptionChange('conversation')}
          input={
            <BootstrapInput
              id="conversation-select"
              name="conversation"
              className={classes.firstOptionStyle}
            />
          }
        >
          <MenuItem key="all" value="all">all conversations</MenuItem>
          {conversationOptions.map(({ label, value }) =>
            <MenuItem key={value} value={value}>{label}</MenuItem>
          )}
        </Select>
      </FormControl>
      <FormControl className={classes.margin}>
        <InputLabel shrink
          htmlFor="topic-select"
          className={classes.bootstrapFormLabel}
        >
          I want to find out...
        </InputLabel>
        <Select
          displayEmpty
          value={selectedTopicValue}
          onChange={handleOptionChange('topic')}
          input={
            <BootstrapInput
              id="topic-select"
              name="topic"
              className={classes.secondOptionStyle}
            />
          }
        >
          <MenuItem key="all" value="all">What topics got discussed a lot</MenuItem>
          {topicOptions.map(({ label, value }) =>
            <MenuItem key={value} value={value}>{label}</MenuItem>
          )}
        </Select>
      </FormControl>
      <FormControl className={classes.margin}>
        <InputLabel shrink
          htmlFor="dim1-select"
          className={classes.bootstrapFormLabel}
        >
          By participants in these segments...
        </InputLabel>
        <Select
          value={selectedDim1Value}
          onChange={handleOptionChange('dim1')}
          input={
            <BootstrapInput
              id="dim1-select"
              name="dim1"
              className={classes.thirdOptionStyle}
            />
          }
        >
          <MenuItem key="all" value="all">all genders</MenuItem>
          {dim1Options.map(({ label, value }) =>
            <MenuItem key={value} value={value}>{label}</MenuItem>
          )}
        </Select>
      </FormControl>
      <span className={classes.unitText}> AND </span>
      <FormControl className={classes.margin}>
        <InputLabel shrink
          htmlFor="dim2-select"
          className={classes.bootstrapFormLabel}
        />
        <Select
          value={selectedDim2Value}
          onChange={handleOptionChange('dim2')}
          input={
            <BootstrapInput
              id="dim2-select"
              name="dim2"
              className={classes.fourthOptionStyle}
            />
          }
        >
          <MenuItem key="all" value="all">all positions</MenuItem>
          {dim2Options.map(({ label, value }) =>
            <MenuItem key={value} value={value}>{label}</MenuItem>
          )}
        </Select>
      </FormControl>
    </form>
  );
}

const BootstrapInput = withStyles((theme) => ({
  input: {
    color: 'rgba(0, 0, 0, 0.6)',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    borderRadius: '4px',
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.25,
    padding: '8px',
    position: 'relative',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    width: '100%',
    '&:focus': {
      borderColor: '#80bdff',
      borderRadius: '4px',
      boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
    },
  },
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  bootstrapFormLabel: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.4,
  },
  margin: {
    margin: theme.spacing(0.5),
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: '100px',
    padding: '16px 20px',
  },
  firstOptionStyle: {
    width: '255px',
  },
  secondOptionStyle: {
    width: '341px',
  },
  thirdOptionStyle: {
    width: '240px',
  },
  fourthOptionStyle: {
    width: '240px',
  },
  unitText: {
    color: 'rgba(0, 0, 0, 0.38)',
    display: 'inline-block',
    fontSize: '13px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.4,
    paddingTop: '26px',
    paddingRight: '4px',
    paddingLeft: '4px',
  },
}));

export default ComplexSelector;