import {
  FETCH_TOPIC_CLUSTERS_LOADING,
  FETCH_TOPIC_CLUSTERS_SUCCESS,
  FETCH_TOPIC_CLUSTERS_FAILURE,
} from '../actions/topic_clusters';
import { transformTopicClusters } from '../transformers/topic_clusters';

const initialState = {
  data: [],
  error: null,
  loaded: false,
  loading: true,
};

export function topicClusters(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOPIC_CLUSTERS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_TOPIC_CLUSTERS_SUCCESS:
      return {
        data: transformTopicClusters(action.payload || {}),
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_TOPIC_CLUSTERS_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };

    default:
      return state;
  }
}