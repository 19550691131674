import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash.isempty';

function UserStatisticCard({ classes, data, statistic }) {

  if (isEmpty(data)) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <div className={classes.cardWrap}>
      <div className={classes.mainNum}>
        {data.value}
      </div>
      <div className={classes.mainText}>
        {statistic}
      </div>
      <div className={classes.subText}>
        <span>{Math.round((data.value / data.total) * 1000) / 10}% of invitees</span>
      </div>
    </div>
  );
}

const styles = {
  cardWrap: {
    display: 'inline-block',
    height: '100%',
    marginRight: '56px',
    maxWidth: '200px',
  },
  mainNum: {
    color: '#00a4bd',
    fontSize: '48.8px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },
  mainText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '16.3px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.5,
    lineHeight: 1.72,
  },
  subText: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.4,
    lineHeight: 1.31,
  },
};

export default withStyles(styles)(UserStatisticCard);