import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
// import TextMarkupView from '@markmo/text-markup-view';
import {FormattedMessage} from "react-intl";
import { fetchEmotion } from '../actions/emotion';
import TextMarkupView from './TextMarkupView';

function EmotionViewer({ selectedChunks, isOpen, setIsOpen }) {

  const data = useSelector((state) => state.emotion);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen && selectedChunks && selectedChunks.length) {
      const texts = selectedChunks.map((c) => c.text);
      dispatch(fetchEmotion({ texts }));
    }
  }, [isOpen]);

  const close = () => {
    setIsOpen(false);
  }

  const DialogTransition = React.forwardRef((props, ref) =>
    <Slide direction="up" {...props} ref={ref} />
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={false}
      onClose={() => {}}
      TransitionComponent={DialogTransition}
      aria-labelledby="view-emotion-dialog-slide-title"
      aria-describedby="view-emotion-dialog-slide-description"
    >
      <DialogTitle id="view-emotion-dialog-slide-title">
        <FormattedMessage
          id="app.explorePage.analyzeEmotion"
          defaultMessage="Analyze Emotion"
        />
      </DialogTitle>
      <DialogContent>
        {isOpen ? <TextMarkupView data={data} /> : <div>Loading...</div>}
      </DialogContent>
    </Dialog>
  );

}

export default EmotionViewer;