import {
  FETCH_CULTUREMAP_LOADING,
  FETCH_CULTUREMAP_SUCCESS,
  FETCH_CULTUREMAP_FAILURE,
  CREATE_CULTUREMAP_LOADING,
  CREATE_CULTUREMAP_SUCCESS,
  CREATE_CULTUREMAP_FAILURE,
} from "../actions/strategy_map";

const initialState = {
  data: [],
  error: null,
  loaded: false,
  loading: false,
};

export function strategy_map(state = initialState, action) {
  switch (action.type) {
    case FETCH_CULTUREMAP_LOADING:
      return {
        ...state,
        loading: true,
      };

    case FETCH_CULTUREMAP_SUCCESS:
      return {
        data: action.payload,
        error: null,
        loaded: true,
        loading: false,
      };

    case FETCH_CULTUREMAP_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };
    case CREATE_CULTUREMAP_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CREATE_CULTUREMAP_SUCCESS:
      return {
        data: action.payload,
        error: null,
        loaded: true,
        loading: false,
      };

    case CREATE_CULTUREMAP_FAILURE:
      return {
        data: state.data,
        error: action.payload.error,
        loaded: true,
        loading: false,
      };
    default:
      return state;
  }
}
