import React from 'react';
import { Card, Button } from 'antd';

import generateId from './generateId';
import { useIntl } from "react-intl"; 
/**
 * Inject an "Add new row" button at the end of the row
 * draggable list.
 */
export default (setData, dataSourcesCopy, dsIndex) => () => {
  const intl = useIntl();
  const addNewRowPlaceholder = intl.formatMessage({id: 'app.dashboardEditor.addNewRow',defaultMessage: 'Click to add a new row to hold your charts (up to 3 charts per row)'});
  const defaultTitlePlaceholder = intl.formatMessage({id: 'app.dashboardEditor.defaultTitle',defaultMessage: 'Give me a name'})
  return(
  <Card
    style={
      {
        backgroundColor: '#f5f5f5',
        border: '1px dashed #333',
        textAlign: 'center'
      }
    }
    bodyStyle={
      {
        padding: '10px'
      }
    }
  >
    <Button
      type="text"
      style={ { width: '100%' } }
      onClick={ () => {
        dataSourcesCopy[dsIndex].rows.push({
          id: generateId(),
          text: defaultTitlePlaceholder,
          columns: []
        })
        setData([ ...dataSourcesCopy ]);
      }}
    >
      {addNewRowPlaceholder}
    </Button>
  </Card>
  )
}