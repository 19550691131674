import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Modal, Button, Table, Select,Input, Col, Row,Form,Card, } from 'antd';
import { FormattedMessage,useIntl } from "react-intl"; 
import { Descriptions } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import ChunkSelector from '../../../components/ChunkSelector';
import EmotionViewer from '../../../components/EmotionViewer';
import Loading from '../../../components/Loading';
import NoData from '../../../components/NoData';
import { SentimentToEmojiFace } from '../../../components/SentimentToEmojiFace';
import { getComment } from '../../../actions/comment';
import MainMagicWindow from '../../MagicWindow/MainMagicWindow';
import BookMark from '../../../components/BookMark';

import { facets } from '../../../transformers/emoji_labels';
import '../../../components/Cards/emoticons.css';

const DEFAULT_SENTIMENT = 3;
const { Option } = Select;

const facetsMap = facets.reduce((a, f) => {
  a[f.label] = f.color;
  return a;
}, {});

export default function ChunkList({ ...props }) {

  const [data, setData] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedChunks, setSelectedChunks] = useState([]);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showChunkSelector, setShowChunkSelector] = useState(false);
  const [showEmotionViewer, setShowEmotionViewer] = useState(false);

  const [averageSentiment, setAverageSentiment] = useState(0);
  const [paginationPage, setPaginationPage] = useState(1);
  const [wordSearch, setWordSearch] = useState(0);
  const [isShowResizableModal, setIsShowResizableModal]= useState(false);

  const [tableSortOrder, setTableSortOrder]= useState({});
  
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onPaginationChange = (page) => {
    setPaginationPage(page);
  };
  const intl = useIntl();
  const itemPlaceholder = intl.formatMessage({id: 'app.explorePage.items',defaultMessage: 'items'})
  const analyzeChunksPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks',defaultMessage: 'Analyze Chunks'});
  const analyzeChunksDateSortNewest = intl.formatMessage({id: 'app.explorePage.dateSortNewest',defaultMessage: 'Newest'});
  const analyzeChunksDateSortOldest = intl.formatMessage({id: 'app.explorePage.dateSortOldest',defaultMessage: 'Oldest'});
  const analyzeChunksDateSortNone = intl.formatMessage({id: 'app.explorePage.dateSortNone',defaultMessage: 'None'});
  const okPlaceholder = intl.formatMessage({id: 'app.ok',defaultMessage: 'Ok'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  const [selectValue,setSelectValue]= useState(analyzeChunksDateSortNone);
  
  const onChange = (pagination, filters, sorter, extra) => {
    setTableSortOrder(sorter);
  };

  const selectComponentClass = () => {
    return (
      <div style={{ float: "right" }}>
              <label style={{ marginRight: "15px" }}>{dateSortPlaceholder}</label>
                <Select
                  defaultValue={analyzeChunksDateSortNone}
                  style={{ width: 120 }}
                  onChange={onSortChange}
                  value={selectValue}
                  dropdownStyle={{ zIndex: 2000 }}
                >
                  <Option value="newest">
                  {analyzeChunksDateSortNewest}
                  </Option>
                  <Option value="oldest">
                  {analyzeChunksDateSortOldest}
                  </Option>
                </Select>
      </div>
    );
  };
  //Need to pass option property to avoid a warning of invalid type option passed.
  selectComponentClass.Option = (props) => {
    return (
      <></>
    );
  };
  const paginationOptions = {
    position: ['topLeft', 'bottomLeft'],
    current: paginationPage,
    onChange: onPaginationChange,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} ${itemPlaceholder}`,
    showSizeChanger: true,
    selectComponentClass,
    defaultPageSize: 50,
    style:{height: "55px" , background:"#f5f5f5", padding:"10px 10px"}
  };
 

  const sortDemographics = (list) => {
    if (list.length > 0) {
      list = [...list];
      list.sort(
        (a, b) => {
          if (a.value > b.value) return 1;
          if (a.value < b.value) return -1;
          return 0;
        }
      );
      list.sort(
        (a, b) => {
          if (a.taxonomy > b.taxonomy) return 1;
          if (a.taxonomy < b.taxonomy) return -1;
          return 0
        }
      );
      list.sort(
        (a, b) => {
          if (a.category > b.category) return 1;
          if (a.category < b.category) return -1;
          return 0
        }
      );
    }
    return list;
  }

  useEffect(() => {
    console.log('props.data', [...props.data]);

    const _data = props.data.map((doc) => {
      return {
        ...doc,
        ... {
          key: doc.chunk_id || doc.chunk_uuid,
          text: (
            <p>
              {doc.text.length > 300 ? doc.text.slice(0, 300) + ' ... ' : doc.text}
            </p>
          ),
          original_text: doc.text,
        },
      };
    });

    console.log('_data', _data);
    setDataCopy(_data);
    setData(_data);
    setAverageSentiment(_data.map((doc) => doc.sentiment).reduce((partial_sum, n) => partial_sum + n, 0) / _data.length);
  }, [props.data]);

  useEffect(() => {
    applyWordFilter(wordSearch);
}, [dataCopy]);

  const classes = {
    moodSizeControl: {
      height: '28px',
      width: '28px',
      marginTop: '5px'
    },
    tagIcon: {
      color: '#90a4ae',
    },
    tagText: {
      color: '#fff',
      fontSize: '11px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontWeight: '700',
      letterSpacing: '0.25px',
    },
    emojiWrap: {
      borderRadius: '10px',
      padding: '5px',
      textAlign: 'center',
      width: '100px',
    }
  };

  const handleChunkInspectClick = (item, recurred) => {
    (async () => {
      setModalIsVisible(true);
      if (item.comment && item.comment.text) {
        const cleanCommentText = item.comment.text.replaceAll("\t\t", "\t").replaceAll("\t ", "\t").replace(/\s+/gi, ' ').replace(/\s+/gi, ' ');
        const cleanChunkText = item.original_text.replaceAll("\t\t", "\t").replaceAll("\t ", "\t").replace(/\s+/gi, ' ').replace(/\s+/gi, ' ').trim();
        const startIndex = cleanCommentText.indexOf(cleanChunkText);
        const endIndex = cleanCommentText.indexOf(cleanChunkText) + cleanChunkText.length;
        const highlightFound = startIndex > -1;

        setModalBody(
          <p style={{ whiteSpace: 'pre-line' }}>
            {
              cleanCommentText.slice(0, startIndex)
            }
            {
              highlightFound &&
              <>
                <span style={{ backgroundColor: 'yellow' }}>
                  {cleanChunkText}
                </span>
                {
                  cleanCommentText.slice(endIndex, cleanCommentText.length)
                }
              </>
            }
          </p>
        );
      } else if ((item.comment_uuid || item.comment) && !recurred) {
        console.log('item', item);
        setModalBody(<Loading loading={true}></Loading>);
        const comment = await dispatch(getComment(props.client_id, item.comment_uuid || item.comment.uid));
        console.log('comment', comment);
        if (comment) {
          item = { ...item, ... { comment } };
          handleChunkInspectClick(item, true);
        }
      } else {
        setModalBody(<NoData />);
      }
    })();
  };

  const chunkIdPlaceholder =  <FormattedMessage
  id="app.explorePage.chunkId"
  defaultMessage="chunk id"/>
   const commentPlaceholder=<FormattedMessage
   id="app.explorePage.commentId"
   defaultMessage="comment id"/>

   const commentDatePlaceholder=<FormattedMessage
   id="app.explorePage.commentDate"
   defaultMessage="comment date"/>

   const subProjectPlaceholder=<FormattedMessage
   id="app.explorePage.subProject"
   defaultMessage="Sub-Project"/>

   const userIdPlaceholder=<FormattedMessage
   id="app.explorePage.userId"
   defaultMessage="user id"/>
   
   const emotionPlaceholder =<FormattedMessage
   id="app.explorePage.emotions"
   defaultMessage="Emotion"/>

   const sentimentPlaceholder=<FormattedMessage
   id="app.explorePage.sentiment"
   defaultMessage="Sentiment"/>

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys);
      console.log(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(props.data.filter((doc) => selectedRowKeys.indexOf(doc.chunk_id || doc.chunk_uuid) > -1));
      console.log(props.data.filter((doc) => selectedRowKeys.indexOf(doc.chunk_id || doc.chunk_uuid) > -1));
    }
  };

  const columns = [
    {
      key: "text",
      title: () => (
        <Button.Group>
          <Button
            size="small"
            disabled={selectedRowKeys.length == 0}
            type='primary'
            style={{borderRadius: "8px", minWidth:"140px", minHeight:"32px"}}
            onClick={() => setShowChunkSelector(true)}
          >
            <FormattedMessage
              id="app.explorePage.addToReport"
              defaultMessage="Add to report"
            />
          </Button>
          <Button
            size="small"
            disabled={selectedRowKeys.length == 0}
            type='primary'
            style={{borderRadius: "8px", minWidth:"140px", minHeight:"32px", marginLeft:"15px"}}
            onClick={() => {
              showResizableModal(true);
            }}
          >
           {analyzeChunksPlaceholder}
          </Button>
          <span  style={{paddingTop: "3px" }}>
            &nbsp;&nbsp;
            {selectedRowKeys.length > 0
              ? `Selected ${selectedRowKeys.length} items`
              : ""}
          </span>
        </Button.Group>
      ),
      dataIndex: "text",
    },
    {
      title: emotionPlaceholder,
      key:"emotion",
      render: (record) => (
        <div
          style={{
            ...classes.emojiWrap,
            ...{
              backgroundColor: facetsMap[record.plutchik_category] || "#fff",
            },
          }}
        >
          <span style={classes.tagText}>{record.plutchik_category}</span>
        </div>
      ),
      sorter: (a, b) => {
        if (a.plutchik_category < b.plutchik_category) {
          return -1;
        }
        if (a.plutchik_category > b.plutchik_category) {
          return 1;
        }
        return 0;
      },
      sortOrder: tableSortOrder.columnKey === "emotion" ? tableSortOrder.order : null,
      width: 120,
    },
    {
      title: sentimentPlaceholder,
      key:"sentiment",
      render: (record) => <SentimentToEmojiFace sentiment={record.sentiment} />,
      sorter: (a, b) => a.sentiment - b.sentiment,
      sortOrder: tableSortOrder.columnKey === "sentiment" ? tableSortOrder.order : null,
      width: 80,
    },
    {
      title: "",
      render: (record) => (
        <div style={{ width: "20px", textAlign: "center" }}>
          <FileSearchOutlined onClick={() => handleChunkInspectClick(record)} />
        </div>
      ),
      width: 20,
    },
  ];

  const onExpandChild = {
    expandedRowRender: (doc, index) => (
      <>
        {!(doc.comment && doc.comment.demographics) ? [
            {
              key: 'title',
              text: 
              (<ul>
                <li>{chunkIdPlaceholder}: {doc.chunk_id}</li>
                {doc.comment_uuid && <li>comment id: {doc.comment_uuid}</li>}
                <li>No demographics attached</li>
              </ul>
              ),
            },
          ]
          : [
              <ul key={"title"}>
                <li>{chunkIdPlaceholder}: {doc.chunk_id}</li>
                <li>{commentPlaceholder}: {doc.comment.uid}</li>
                <li>{commentDatePlaceholder}: {doc.comment.create_datetime}</li>
                {doc.comment.user && <li>{userIdPlaceholder}: <Link to={'/explore/chunksBy/userId/' + doc.comment.user.uid + '/'} target="_blank" rel="noopener noreferrer">{doc.comment.user.uid}</Link></li>}
                {doc.comment.conversation && <li>{subProjectPlaceholder}: <Link to={'/explore/chunksBy/subProjectId/' + doc.comment.conversation.uid + '/'} target="_blank" rel="noopener noreferrer">{doc.comment.conversation.text}</Link></li>}
                {
                  doc.comment.demographics.length == 0 ? <li>No demographics attached</li> : (sortDemographics(doc.comment.demographics).map((demo, i) => {
                    return (<li key={i} ><Link to={'/explore/chunksBy/demographics/' + encodeURIComponent(demo.category) + '/' + encodeURIComponent(demo.taxonomy) + '/' + encodeURIComponent(demo.value) + '/'} target="_blank" rel="noopener noreferrer">{demo.category + ':' + demo.taxonomy + ':' + demo.value}</Link></li>
                    );
                  })
                )}
              </ul>,            
          ]}
        </>
    ),
  };

  const onSortChange = (value) => {
    console.log(value);
    setSelectValue(value);
    setData([
      ... data.sort((a, b) => {
        if (value == 'newest') {
          return new Date(a.comment.create_datetime) < new Date(b.comment.create_datetime) ? 1 : -1;
        } else if (value == 'oldest') {
          return new Date(a.comment.create_datetime) > new Date(b.comment.create_datetime) ? 1 : -1;
        }
        return 0;
      })
    ]);
  };
  const onWordCount= (e) => {
    let minWords = 1;
    if(e.target.value !== "" && e.target.value > 0)
      minWords = e.target.value;
    form.setFieldsValue({
      wordSearch: e.target.value 
    });
    setWordSearch(e.target.value);
    applyWordFilter(minWords);

  };

  const applyWordFilter = (minWord) => {
    const new_array =  dataCopy.filter((chunk) => {
      return chunk.original_text.split(' ').length > minWord;
    });
    setData(new_array);
  }

  const resetFilters= () =>{
    setTableSortOrder({});
    setPaginationPage(1);
    setWordSearch(0);
    form.setFieldsValue({
      wordSearch: '',
    });
    setSelectValue(analyzeChunksDateSortNone);
    applyWordFilter(1);
  }
  const showResizableModal= () =>{
    setIsShowResizableModal(!isShowResizableModal)
  }


  const filterTextLengthPlaceholder =  <FormattedMessage
  id="app.explorePage.filterByTextLength"
  defaultMessage="Filter by minimum # words"/>
 
  const avgSentimentPlaceholder =  <FormattedMessage
  id="app.explorePage.averageSentiment"
  defaultMessage="Average sentiment"/>

  const dateSortPlaceholder =  <FormattedMessage
  id="app.explorePage.dateSort"
  defaultMessage="Date Sort"/>

  return (
    <>
      {isShowResizableModal && (
          <MainMagicWindow
            setIsShowResizableModal={setIsShowResizableModal}
            selectedChunks={selectedRows}
            client_id={props.client_id}
            title={props.title}
            forum_id={props.forum_id}
            sorter={tableSortOrder}
            source={"chunk"}
          />
      )}
        <Row style={{ height: "55px" , background:"#f5f5f5", padding:"10px"}}>
          <Col xs={24} sm={24} md={9} lg={9} xl={9} >
            <Form form={form}>
              <Form.Item
                name="wordSearch"
                onChange={onWordCount}
                label={filterTextLengthPlaceholder}
                onClick={(e) => {
                  if (wordSearch === 0) {
                    form.setFieldsValue({
                      wordSearch: "",
                    });
                  }
                }}
              >
                <Input
                  type="number"
                  style={{
                    width:"60px"
                  }}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={24} md={9} lg={9} xl={9}>
            <Row style={{ float: "right"  }}>
              <Col style={{ marginTop: "5px",wordWrap: "break-word" }}>
                <label>{avgSentimentPlaceholder}</label>
              </Col>
              <Col style={{ marginLeft: "20px" }}>
                <SentimentToEmojiFace sentiment={averageSentiment} />
              </Col>
              <Col>
                <p style={{ marginLeft: "10px", marginTop: "7px"}}>
                  {!Number.isNaN(averageSentiment)  && (Math.round(averageSentiment * 100) / 100)}
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} >
          <Button type='primary' style={{ float: "right",borderRadius: "8px", minWidth:"140px"  }} onClick={(e) => resetFilters()}>
              <FormattedMessage
                id="app.explorePage.resetSortFilters"
                defaultMessage="Reset Sort Filters"
              />
            </Button>
          </Col>
        </Row>
      <Table
        pagination={paginationOptions}
        columns={columns}
        dataSource={data}
        expandable={onExpandChild}
        rowSelection={rowSelection}
        onChange={onChange}
      />
      <ChunkSelector
        selectedChunks={selectedRows}
        isOpen={showChunkSelector}
        setIsOpen={setShowChunkSelector}
      />
      <EmotionViewer
        selectedChunks={selectedRows}
        isOpen={showEmotionViewer}
        setIsOpen={setShowEmotionViewer}
      />
      <Modal
        title="Comment"
        open={modalIsVisible}
        onOk={() => setModalIsVisible(false)}
        onCancel={() => setModalIsVisible(false)}
        okText={okPlaceholder}
        cancelText={cancelPlaceholder}
      >
        {modalBody}
      </Modal>
    </>
  );
};