import axios from '../http';

import { cubeapi } from './cube';

export const FETCH_PLATFORM_LOADING = 'FETCH_PLATFORM_LOADING';
export const FETCH_PLATFORM_SUCCESS = 'FETCH_PLATFORM_SUCCESS';
export const FETCH_PLATFORM_FAILURE = 'FETCH_PLATFORM_FAILURE';

const query = (clientId) => ({
  "dimensions": [
    "CommentsDenorm.clientUuid",
    "CommentsDenorm.client",
    "CommentsDenorm.forumUuid",
    "CommentsDenorm.forum",
    "CommentsDenorm.conversationUuid",
    "CommentsDenorm.conversation"
  ],
  "timeDimensions": [],
  "filters": [
    {
      "dimension": "CommentsDenorm.clientUuid",
      "operator": "equals",
      "values": [clientId]
    }
  ]
});

function fetchPlatformLoading() {
  return {
    type: FETCH_PLATFORM_LOADING,
  };
}

function fetchPlatformSuccess(data) {
  return {
    type: FETCH_PLATFORM_SUCCESS,
    data,
  };
}

function fetchPlatformFailure(error) {
  return {
    type: FETCH_PLATFORM_FAILURE,
    error,
  }
}

function transformToTree(data) {
  return data.reduce((a, row) => {
    const clientId = row['CommentsDenorm.clientUuid'];
    const clientTitle = row['CommentsDenorm.client'];
    const forumId = row['CommentsDenorm.forumUuid'];
    const forumTitle = row['CommentsDenorm.forum'];
    const conversationId = row['CommentsDenorm.conversationUuid'];
    const conversationTitle = row['CommentsDenorm.conversation'];
    const forums = [...(a.forums || [])];
    const forum = forums.find((v) => v.forumId === forumId);
    if (forum) {
      const conversations = [...(forum.conversations || [])];
      const conversation = conversations.find((v) => v.conversationId === conversationId);
      if (!conversation) {
        conversations.push({ conversationId, conversationTitle });
      }
      forum.conversations = conversations;
    } else {
      forums.push({
        forumId,
        forumTitle,
        conversations: [
          {
            conversationId,
            conversationTitle,
          },
        ],
      });
    }
    return {
      clientId,
      clientTitle,
      forums
    };
  }, {});
}

export function fetchPlatform() {
  return async (dispatch) => {
    dispatch(fetchPlatformLoading());
    try {
      const platform_rs = await axios.get('/api/v1/platform/');
      if (platform_rs.status !== 200) {
        throw Error(platform_rs.statusText);
      }
      const user = platform_rs.data.data.user;
      const client_rs = await cubeapi.load(query(user.client_id));
      user.info = transformToTree(client_rs.loadResponse.data);
      dispatch(fetchPlatformSuccess({ user }));
    } catch (err) {
      dispatch(fetchPlatformFailure(err));
    }
  }
}
