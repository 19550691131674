import { gql } from '@apollo/client';
import { setupApi } from '../graphql/client';
import { cubeapi } from './cube';
import _ from 'lodash';

const client = setupApi();

export const FETCH_TOPIC_SENTIMENT_LOADING = 'FETCH_TOPIC_SENTIMENT_LOADING';
export const FETCH_TOPIC_SENTIMENT_SUCCESS = 'FETCH_TOPIC_SENTIMENT_SUCCESS';
export const FETCH_TOPIC_SENTIMENT_FAILURE = 'FETCH_TOPIC_SENTIMENT_FAILURE';

function fetchTopicSentimentLoading() {
  return {
    type: FETCH_TOPIC_SENTIMENT_LOADING,
  };
}

function fetchTopicSentimentSuccess(payload) {
  return {
    type: FETCH_TOPIC_SENTIMENT_SUCCESS,
    payload,
  };
}

function fetchTopicSentimentFailure(payload) {
  return {
    type: FETCH_TOPIC_SENTIMENT_FAILURE,
    payload,
  };
}

export function fetchTopicSentiment(client_id, forum_id, query) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(fetchTopicSentimentLoading());
        let gqlQuery;
        if (forum_id == '') {
          gqlQuery = {
            query: gql`
              query GetTopics($client_id: String) {
                topics(client_id: $client_id) {
                  text
                  tsne_coords
                }
              }
            `,
            variables: {
              client_id
            }
          };
        } else {
          gqlQuery = {
            query: gql`
              query GetTopics($client_id: String, $forum_id: String) {
                topics(client_id: $client_id, forum_id: $forum_id) {
                  text
                  tsne_coords
                }
              }
            `,
            variables: {
              client_id,
              forum_id
            }
          };
        }

        const tsne = client.query(gqlQuery);
        const sent = cubeapi.load(query);
        Promise.all([tsne, sent])
          .then(([tsne_rs, sent_rs]) => {
            console.log('tsne_rs', tsne_rs);
            let result = {
              data: sent_rs.loadResponse.data,
              tsne: _.zipObject(tsne_rs.data.topics.map((record) => record.text), tsne_rs.data.topics.map((record) => {
                return JSON.parse(record.tsne_coords.replaceAll("'", '"'))
              })),
            };
            console.log('result', tsne_rs);
            dispatch(fetchTopicSentimentSuccess(result));
            resolve(result);
          })
      } catch (error) {
        let result = {
          error: {
            type: typeof error,
            message: error.toString(),
          },
        };
        dispatch(fetchTopicSentimentFailure(result));
        reject(result);
      }
    });
  };
}
