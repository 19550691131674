import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
} from 'react-beautiful-dnd';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { combineStyles } from '../utils';
import AddSectionMenu from './AddSectionMenu';
import ReportImage from './ReportImage';
import ReportSection from './ReportSection';
import commonStyles from './common_styles';
import ReportChunk from './ReportChunk';
import ReportAnalyse from './ReportAnalyse';

function ReportEdit({ onChange, value: report }) {

  const [sections, setSections] = useState(report.sections || []);
  const [title, setTitle] = useState(report.title || '');
  const [nextOpen, setNextOpen] = useState(0);
  const classes = combinedStyles();

  useEffect(() => {
    const updatedSections = report.sections || [];
    setSections(updatedSections);
    setNextOpen(updatedSections.length);
  }, [report]);

  // TODO
  // this seems inefficient
  const handleChange = (index, value) => {
    const clone = [...sections];
    let content;
    if (isObject(value)) {
      content = {
        ...JSON.parse(clone[index].content || '{}'),
        ...value
      };
    } else {
      content = value;
    }
    clone[index].content = JSON.stringify(content);
    setSections(clone);
    onChange({ sections: clone });
  }

  const handleDelete = (index) => {
    const clone = [...sections];
    clone.splice(index, 1);
    // make sure we don't have gap of index in sections after deleting one
    for (let i = index; i < clone.length; i++) {
      clone[i].index -= 1;
    }
    setSections(clone);
    onChange({ sections: clone });
  }

  const handleDragEnd = (result) => {
    const updated = array_move(sections, result.source.index, result.destination.index);
    setSections(updated);
    onChange({ sections: updated });
  }

  const handleDrop = () => {

  }

  const handleInsertSection = (sectionType, index) => {
    const clone = [...sections];
    clone.splice(index, 0, { section_type: sectionType, index });
    // if the insertion is not at the end, we will increase the index 
    // of every section after the inserted section
    if (index !== sections.length) {
      for (let i = index + 1; i < clone.length; i++) {
        clone[i].index += 1;
      }
    }
    setSections(clone);
    setNextOpen(index + 1);
    onChange({ sections: clone });
  }

  // direction is relative to the page not the index
  const handleSectionDown = (index) => {
    if (index < (sections.length - 1)) {

      // use update_section_index to ensure index there's no duplicated index
      const updated = update_section_index(sections, index, 1);
      setSections(updated);
      onChange({ sections: updated });
    }
  }

  const handleSectionUp = (index) => {
    if (index > 0) {
      const updated = update_section_index(sections, index, -1);
      setSections(updated);
      onChange({ sections: updated });
    }
  }

  const handleTitleChange = (ev) => {
    const title = ev.target.value;
    setTitle(title);
    onChange({ title });
  }

  return (
    <div className={classes.contentWrap}>
      <form className={classes.sectionWrap} noValidate autoComplete="off">
        <TextField
          id="outlined-heading"
          className={classes.textFieldWrap}
          label="Report title"
          variant="outlined"
          value={title}
          onChange={handleTitleChange}
          InputProps={{
            classes: {
              input: classes.textFieldInputStyle,
            },
          }}
          inputProps={{
            className: classes.textFieldInputWrap,
          }}
        />
        <AddSectionMenu
          index={0}
          nextOpen={nextOpen}
          report={report}
          onInsertSection={handleInsertSection}
        />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="drop1">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
          
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="drop2">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sections.map((section, index) => (
                  <Draggable key={index} draggableId={'drag' + index} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {section.section_type === 'image' ?
                          <ReportImage
                            images={report.images || []}
                            index={index}
                            section={section}
                            onChange={handleChange}
                            onDelete={handleDelete}
                            onDrop={handleDrop}
                            onSectionDown={handleSectionDown}
                            onSectionUp={handleSectionUp}
                          />
                          :(
                            section.section_type === 'chunk'?
                            <ReportChunk
                              chunks={report.chunks || []}
                              index={index}
                              section={section}
                              onChange={handleChange}
                              onDelete={handleDelete}
                              onDrop={handleDrop}
                              onSectionDown={handleSectionDown}
                              onSectionUp={handleSectionUp}
                              
                            />: (section.section_type === 'text-analysis'?
                            <ReportAnalyse
                              chunks={report.chunks || []}
                              textAnalysis = {report.textAnalysis || []}
                              index={index}
                              section={section}
                              onChange={handleChange}
                              onDelete={handleDelete}
                              onDrop={handleDrop}
                              onSectionDown={handleSectionDown}
                              onSectionUp={handleSectionUp}
                              />:
                              <ReportSection
                              index={index}
                              section={section}
                              onChange={handleChange}
                              onDelete={handleDelete}
                              onDrop={handleDrop}
                              onSectionDown={handleSectionDown}
                              onSectionUp={handleSectionUp}
                            />
                            )
                          )
                        }
                        <AddSectionMenu
                          index={index + 1}
                          nextOpen={nextOpen}
                          report={report}
                          onInsertSection={handleInsertSection}
                          
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </form>
    </div>
  );
}

function array_move(sections, old_index, new_index) {
  const clone = [...sections];
  clone.splice(new_index, 0, clone.splice(old_index, 1)[0]);
  clone.map((section, index) => section.index = index);
  return clone;
}

function update_section_index(sections, cur_index, offset) {
  const clone = [...sections];
  if (cur_index === clone.length - 1 && offset > 0 || cur_index === 0 && offset < 0) {
    return clone;
  }
  clone[cur_index].index += offset;
  clone[cur_index + offset].index -= offset;
  clone.sort((a, b) => {
    if (a.index < b.index) {
      return 1;
    }
    if (a.index > b.index) {
      return -1;
    }
    return 0;
  });

  return clone;
}

function isObject(value) {
  return (
    (typeof value === 'object' || typeof value === 'function') &&
    (value !== null)
  );
}

const useStyles = (theme) => ({
  addCircleIconStyle: {
    backgroundColor: '#fff',
    color: 'rgba(84, 110, 122, 0.8)',
    left: '50%',
    position: 'absolute',
    top: '-11px',
    transform: 'translate(-50%, 0)',
  },
  clearIconStyle: {
    color: 'rgba(84, 110, 122, 0.8)',
    fontSize: '18px',
    fontWeight: 500,
    position: 'absolute',
    top: '50%',
    right: '16px',
    transform: 'translate(0, -50%)',
  },
  deleteButtonWrap: {
    backgroundColor: '#fff',
    borderRadius: '2px',
    color: '#516e88',
    display: 'flex',
    fontSize: '12.2px',
    padding: '2px 4px',
    position: 'absolute',
    top: '-10px',
    right: '10px',
    textAlign: 'center',
    zIndex: 2,
  },
  deleteIconStyle: {
    opacity: 0.6,
    fontSize: '18px',
  },
  sectionAddArea: {
    border: '1px dashed #90a4ae',
    borderRadius: '4px',
    margin: '20px 0',
    padding: '12px 16px',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
  },
  sectionAddButton: {
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    height: '36px',
    letterSpacing: '1.25px',
    lineHeight: 1.12,
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  sectionAddText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    lineHeight: 1.31,
    marginRight: '16px',
  },
  sectionAddWrap: {
    border: '1px dashed #90a4ae',
    position: 'relative',
    margin: '20px 0',
  },
  sectionGreyWrap: {
    backgroundColor: '#eceff1',
    paddingBottom: '10px',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  sectionWrap: {
    marginBottom: '20px',
    position: 'relative',
    width: '100%',
  },
  textFieldInputStyle: {
    padding: '14px',
  },
  textFieldWrap: {
    marginBottom: '15px',
    width: '100%',
  },
});

const combinedStyles = makeStyles(combineStyles(commonStyles, useStyles));

export default ReportEdit;