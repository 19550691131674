import React, { useState, useEffect } from 'react';
import _ from "lodash";
import { Button, Drawer, Divider } from 'antd';
import { Form, Input, Select, Modal,Space,Tooltip} from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined,InfoCircleOutlined } from '@ant-design/icons';
import { useIntl } from "react-intl"; 
const { Option } = Select;
const { confirm } = Modal;

const DashboardDataSourceEditorBlock = ({ children, item, index, setItem, deleteItem, forumDataSources, ... props }) => {

  const [ showConfig, setShowConfig ] = useState(false);
  const [ form ] = Form.useForm();
  const [ sourceItem, setSourceItem ] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const intl = useIntl();
 
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  const okPlaceholder = intl.formatMessage({id: 'app.ok',defaultMessage: 'Ok'});
  const deleteFramePlaceholder = intl.formatMessage({id: 'app.dashboardEditor.deleteFrame',defaultMessage: 'Are you sure you want to delete this data source and all charts?'});
  const dataFrameConfigPlaceholder = intl.formatMessage({id: 'app.dashboardEditor.dataFrameConfig',defaultMessage: 'Data frame config'});
  const titlePlaceholder = intl.formatMessage({id: 'app.dashboardEditor.dataFrameConfig.title',defaultMessage: 'Title:'});
  const dataSourcePlaceholder = intl.formatMessage({id: 'app.dashboardEditor.dataFrameConfig.dataSource',defaultMessage: 'Data source:'});
 
  const helpText1Placeholder = intl.formatMessage({id: 'app.dashboardEditor.dataFrameConfig.chartHelpText1',defaultMessage: 'Select the data source from the drop-down list above'});
  const helpText2Placeholder = intl.formatMessage({id: 'app.dashboardEditor.dataFrameConfig.chartHelpText2',defaultMessage: 'Remember to name this chart'});
  
  
  const defaultValues = {
    dataSourceId: 'all'
  };

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  useEffect(() => {
    setSourceItem({
      ... defaultValues,
      ... item
    });
  }, [ item ]);

  const updateRecord = (values) => {
    values["isNew"] = false;
    const validKeys = Object.keys(values).filter((key) => {
      return values[key] !== undefined;
    });
    const validValues = validKeys.map((key) => {
      return values[key];
    });

    setSourceItem((current) => {
      current = {
        ... defaultValues,
        ... current,
        ... _.zipObject(validKeys, validValues)
      };
      setItem(current);
      return current;
    });
  };

  const save = () => {
    form.validateFields().then(onFinish).catch(onFinishFailed);
  };

  const remove = () => {
    confirm({
      title: deleteFramePlaceholder,
      icon: <ExclamationCircleOutlined />,
      content: null,
      onOk() {
        deleteItem();
      },
      onCancel() { },
      okText:okPlaceholder,
      cancelText:cancelPlaceholder,
    });
  };

  const onFinish = (values) => {
    setShowConfig(false);
    updateRecord(values);
  };

  const onFinishFailed = () => {

  };

  const handleOpenChange = (isOpen) => {
    if(sourceItem.isNew)
    {
      // Show tooltip for 5 second and then hide it
      setTooltipVisible(isOpen); 
      const timeoutId = setTimeout(() => {
        setTooltipVisible(false);
      }, 5000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  };

  return !sourceItem ? <></> : (
    <>
      <Divider orientation="left" plain>
      <Space>
         <Button type="dashed"  onClick={ () => setShowConfig(true) }>
         { sourceItem.text }
        </Button>
         <Button
          className={ (!sourceItem.dataSourceId && !showConfig) ? "background-pulsate-warning-color": "" }
          //type="text"
          size="small"
          style={{ cursor: "pointer" }}
          onClick={ () => setShowConfig(true) }
        >
          <EditOutlined />
        </Button>
        <Button
          size="small"
          // type="text"
          danger
          onClick={ remove }
          style={{ cursor: "pointer" }}
        >
          <DeleteOutlined />
        </Button>
        </Space>
      </Divider>
      { children && children(sourceItem, index) }
      <Drawer
        title={dataFrameConfigPlaceholder}
        width={ 520 }
        closable={ true }
        onClose={ () => setShowConfig(false) }
        open={ showConfig }
        afterOpenChange={handleOpenChange}
        style={
          {
            zIndex: 2000
          }
        }
        extra={
          <Space>
            <Tooltip title={<ul>
              <li>{helpText1Placeholder}</li>
              <li>{helpText2Placeholder}</li>
            </ul>} 
              open={tooltipVisible}
              placement="leftBottom"
              onOpenChange={(open) => {
                setTooltipVisible(open); 
              }}
              trigger="hover"
              zIndex="3000">
            <InfoCircleOutlined style={{fontSize:"22px"}}/>
            </Tooltip>
          </Space>
        }
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={ () => setShowConfig(false) } style={{ marginRight: 8 ,borderRadius: "8px", minWidth:"140px"}}>
            {cancelPlaceholder}
            </Button>
            <Button onClick={ save } type="primary" style={{ float: "right",borderRadius: "8px", minWidth:"140px"  }}>
              {okPlaceholder}
            </Button>
          </div>
        }
      >
        <Form
          { ... formItemLayout }
          layout="horizontal"
          form={ form }
          initialValues={
            {
              layout: 'horizontal',
            }
          }
          onFinish={ onFinish }
          onFinishFailed={ onFinishFailed }
        >
          <Form.Item
            label={titlePlaceholder}
            name="text"
            rules={
              [
                {
                  required: true,
                  message: 'Please provide a title for this data source',
                },
                {
                  min: 3,
                  message: 'You must provide a title greater than or equal to 3 characters',
                },
                {
                  max: 255,
                  message: 'You must provide a title less than or equal to 255 characters',
                },
              ]
            }
            initialValue={ sourceItem.text }
          >
            <Input placeholder="Provide text here ..." />
          </Form.Item>

          <Form.Item
            label={dataSourcePlaceholder}
            name="dataSourceId"
            rules={
              [
                {
                  required: true,
                  message: 'Please choose a chart type',
                },
              ]
            }
            initialValue={ sourceItem.dataSourceId }
          >
            <Select
              dropdownStyle={
                {
                  zIndex: 3000
                }
              }
            >
              <Option key={ 'all' } value={ 'all' }>All</Option>
              {
                forumDataSources.map((forum) => (
                  <Option key={ forum.id } value={ forum.id }>{ forum.title }</Option>
                ))
              }
            </Select>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default DashboardDataSourceEditorBlock;