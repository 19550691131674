import { gql } from '@apollo/client';

import { setupApi } from '../graphql/client';
const client = setupApi();

export function fetchDemographicTaxonomies(client_id) {
  return async () => {
    const res = await client.query({
      query: gql`
        query getTaxonomies($client_id: String!) {
          userDemographics(where: {client_id: $client_id, category_NOT: "", taxonomy_NOT: "", value_NOT: "" }) {
            client_id,
            taxonomy,
            category,
            value
          }
        }
      `,
      variables: {
        client_id
      }
    });

    let taxonomies = {};
    res.data.userDemographics.forEach((doc) => {
      if (!taxonomies[doc.taxonomy]) {
        taxonomies[doc.taxonomy] = [];
      }
      taxonomies[doc.taxonomy].push(doc);
    });

    return taxonomies;
  }
};

export function fetchMoodByDemographic({ client_id, forum_id, dim1, dim2, topic, startDate = '', endDate = '', uid, sentimentType }) {
  return async () => {
    try {

      forum_id = forum_id == '' ? undefined : forum_id;
      let forumParam="";
      let forumWhereParam="";
      let chunkForumParam ="";
      let chunkForumWhereParam="";
      if(uid && sentimentType)
      {
         chunkForumParam = forum_id ? '$forum_id: String!' : '';
         chunkForumWhereParam = forum_id ? 'forum_id: $forum_id' : 'forum_id: null';
      }
      else{
         forumParam = forum_id ? '$forum_id: String!' : '';
         forumWhereParam = forum_id ? 'forum_id: $forum_id' : 'forum_id: null';
      }
      

      const topicParam = topic ? '$topic: String!' : '';
      const topicWhereParam = topic ? 'topic: $forum_id' : 'topic: null';

      const dateTimeParam = startDate != '' && endDate != '' ? '$startDate: DateTime!\n$endDate: DateTime!' : '';
      const dateTimeWhereParam  = startDate != '' && endDate != '' ? 'date_filter: { create_datetime_GTE: $startDate, create_datetime_LTE: $endDate }' : '';
      const dateTimeSubWhereParam  = startDate != '' && endDate != '' ? '(date_filter: { create_datetime_GTE: $startDate, create_datetime_LTE: $endDate })' : '';

      const uidParam = uid ? '$uid: ID!' : '';
      const uidWhereParam = uid ? 'uid:$uid' : '';
      let filterByPowerfulChunks = "";
      const sentimentParam = sentimentType ? '$sentiment: String!' : '';
      let sentimentWhereParam ="";
      if(sentimentType==="All")
        sentimentWhereParam = 'sentiment: {sentiment_IN:[1, 2, 3, 4, 5]}'
      else if(sentimentType==="Positive")
      sentimentWhereParam = 'sentiment: {sentiment_IN:[ 4, 5]}'
      else if(sentimentType==="Negative")
      sentimentWhereParam = 'sentiment: {sentiment_IN:[1, 2]}'
      else if (sentimentType==="Neutral")
      sentimentWhereParam = 'sentiment: {sentiment_IN:[3]}'
      else if(sentimentType==="Good Quotes")
      filterByPowerfulChunks= "weighted_score_GTE:3"

      let query="";
      
      if(uid && sentimentType)
      {
        const preppedQuery = `
        query getTaxonomySummaryMoods(
          $client_id: String!
          $dim1: String!
          $dim2: String!
          ${uidParam}
          ${chunkForumParam}
          ${topicParam}
          ${dateTimeParam}
        ) {
          mood_summary_by_demographic(
            chunkWhere:{${filterByPowerfulChunks} ${sentimentWhereParam} culturePhrases: {${uidWhereParam} ${chunkForumWhereParam}}}
            client_id: $client_id
            dim1: $dim1
            dim2: $dim2
            
            ${topicWhereParam}
            ${dateTimeWhereParam}
          ) {
            dim1
            dim2
            comment_count
            chunk_count
            avg_mood
            avg_sentiment
          }
        }`;
        console.log('gqlQuery', preppedQuery);

        query = {
          query:  gql`${preppedQuery}`,
          variables: {
            forum_id,
            client_id,
            dim1,
            dim2,
            topic,
            startDate,
            endDate,
            uid
          }
        };
      }
      else{
        const preppedQuery = `
      query getTaxonomySummaryMoods(
        $client_id: String!
        $dim1: String!
        $dim2: String!
        ${forumParam}
        ${topicParam}
        ${dateTimeParam}
      ) {
        mood_summary_by_demographic(
          client_id: $client_id
          dim1: $dim1
          dim2: $dim2
          ${forumWhereParam}
          ${topicWhereParam}
          ${dateTimeWhereParam}
        ) {
          dim1
          dim2
          comment_count
          chunk_count
          avg_mood
          avg_sentiment
        }
      }`;

      console.log('gqlQuery', preppedQuery);

       query = {
        query:  gql`${preppedQuery}`,
        variables: {
          forum_id,
          client_id,
          dim1,
          dim2,
          topic,
          startDate,
          endDate
        }
      };
      }
      console.log('gqlQuery', JSON.stringify(query.variables, null, 2));

      const res = await client.query(query);

      return res.data.mood_summary_by_demographic;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
};


export function fetchCommentDemographicsByCommentIds({ client_id, comment_ids }) {
  return async () => {
    try {
      const preppedQuery = `
        query GetChunks(
          $client_id: String!
          $limit: Int
          $offset: Int
          $commentWhere: CommentWhere
        ) {
          chunks(
            options: { limit: $limit, offset: $offset, sort:{weighted_score:DESC} }
            where: {
              client_id: $client_id
              comment: $commentWhere
            }
          ) {
            uid
            comment {
              uid
              text
              create_datetime
              conversation {
                uid
                text
              }
              user {
                uid
              }
              demographics {
                category
                taxonomy
                value
              }
              __typename
            }
            __typename
          }
        }
      `;

      console.log('gqlQuery', preppedQuery);

      const query = {
        query:  gql`${preppedQuery}`,
        variables: {
          client_id,
          commentWhere: {
            "OR": comment_ids.map((id) => { return { uid: id } })
          },
          offset: 0,
          limit: 1000
        }
      };

      console.log('gqlQuery', JSON.stringify(query.variables, null, 2));

      const res = await client.query(query);

      return res.data.chunks;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
};

