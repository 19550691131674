import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChunksAndFilter } from '../components/ChunkViewer';
import { getChunksMultiFilter } from '../actions/comment';

import PageHeading from '../components/PageHeading';
import NoData from '../components/NoData';
import Loading from '../components/Loading';


export default function ChunksByView({ match, ... props }) {

  const [ loading, setLoading ] = useState(true);
  const [ chunks, setChunks ] = useState([]);
  const [ sectionTitle, setSectionTitle ] = useState('');
  const DEFAULT_SENTIMENT = 3;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!match || !match.params) {
        return;
      }

      let _chunks;
      if (props.filterType == 'userId') {
        setSectionTitle('User Id')
        _chunks = await dispatch(getChunksMultiFilter({
          client_id: user.client_id,
          commentQuery: {
            user: {
              uid: match.params.typeId
            }
          }
        }));
      }

      if (props.filterType == 'subProjectId') {
        setSectionTitle(match.params.category +": " + match.params.taxonomy + ": " + match.params.value)
        _chunks = await dispatch(getChunksMultiFilter({
          client_id: user.client_id,
          commentQuery: {
            conversation: {
              uid: match.params.typeId
            }
          }
        }));
      }

      if (props.filterType == 'demographics') {
        setSectionTitle(match.params.category +": " + match.params.taxonomy + ": " + match.params.value)
        _chunks = await dispatch(getChunksMultiFilter({
          client_id: user.client_id,
          commentQuery: {
            demographics: {
              category: decodeURIComponent(match.params.category),
              taxonomy: decodeURIComponent(match.params.taxonomy),
              value: decodeURIComponent(match.params.value),
            }
          }
        }));
      }

      if (_chunks && _chunks.payload) {
        _chunks = formatChunks(_chunks.payload);
        setChunks(_chunks);
      }

      setLoading(false);
    })();
  }, [  ]);

  useEffect(() => {
    console.log('chunks', chunks);
  }, [ chunks ]);

  function formatChunks(chunks) {
    // get rid of html tag like words
    let processedChunks = chunks.map((chunk) => ({
      ...chunk,
      text: chunk.text.replace(/<.*>/i, "")
    }))
  
    return processedChunks.map((c) => ({
      plutchik_category: c.emoji ? c.emoji.plutchik_category : c.plutchik_category,
      process_datetime: c.process_datetime,
      sentiment: c.sentiment === null ? DEFAULT_SENTIMENT : c.sentiment.sentiment ? c.sentiment.sentiment : c.sentiment,
      text: c.text,
      chunk_id: c.uid,
      comment: c.comment,
    }));
  };
  return (
    <>

      <Loading loading={loading}>
        <PageHeading style={{ fontSize: '38px'}}>
          Chunks by { sectionTitle }
        </PageHeading>
        { !loading && chunks && chunks.length > 0 && <ChunksAndFilter client_id={ user.client_id } data={ chunks } title={"Chunks by " +sectionTitle} forumId={""}/> }
        { (!chunks || chunks.length == 0) && <NoData /> }
      </Loading>
    </>
  )
};