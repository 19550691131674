import React from 'react';
import { TokenAnnotator } from 'react-text-annotate';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Loading from './Loading';

function TextMarkupView({ data }) {

  const classes = useStyles();

  if (notEmpty(data)) {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Text Markup
          </Typography>
          <div className={classes.centerContent}>
            {data.map(({ tokens, value }) => (
              <div style={{ marginTop: 15 }}>
                {/* <blockquote>{tokens.join(' ')}</blockquote> */}
                <TokenAnnotator
                  tokens={tokens}
                  value={value}
                />
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Loading />
    );
  }
}

function notEmpty(obj) {
  if (Array.isArray(obj)) {
    return obj.length;
  }
  return obj && Object.keys(obj).length;
}

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    padding: '4px',
  },
  centerContent: {
    marginTop: '10px',
  },
  contentItem: {
    alignItems: 'center',
    borderBottom: '1px solid #efefef',
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    fontSize: 14.2,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.25,
    lineHeight: '1.41',
    padding: '14px 0',
  },
  itemIcon: {
    color: 'rgba(84, 110, 122, 0.8)',
    display: 'inline-block',
    marginLeft: '32px',
    opacity: 0.6,
  },
  itemNum: {
    color: '#00a4bd',
    display: 'inline-block',
    fontSize: '24.4px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    marginRight: '20px',
    opacity: 0.38,
  },
  itemText: {
    display: 'inline-block',
    width: '90%',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: 2,
    lineHeight: 1.31,
  },
}));

export default TextMarkupView;