import React, { useState, useEffect } from "react";
import { Form, Table, Row, Col, Button, Modal } from "antd";
import FullScreenModal from "../../../components/FullScreenModal.jsx";
import MoodTableWidget from "../../../Overview/MoodTableWidget";
import TwoWayTableModal from "./TwoWayTableModal";
import ExploreModal from "./ExploreModal";
import ClusterModal from "./ClusterModal";
import { BarChartOutlined } from "@ant-design/icons";

const MainModal = ({
  customProps,
  dateRange,
  infoModal,
  setInfoModal,
  modalType,
}) => {
  const [step, setStep] = useState(1);
  const [moodTableProps, setMoodTableProps] = useState({});
  const [ldaProps, setLdaProps] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  // Process to Next Step
  const nextStep = () => {
    setStep((prevActiveStep) => prevActiveStep + 1);
    setIsModalVisible(true);
  };
  // Back to Previous Step
  const prevStep = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
    setIsModalVisible(false);
    setInfoModal(true);
    console.log(step);
  };

  const closeExploreModal = () => {
    setIsModalVisible(false);
    setInfoModal(false);
    setStep(0);
  };

  const showListInfo = () => {
    return (
      <ExploreModal
        customProps={customProps}
        dateRange={dateRange}
        isModalVisible={infoModal}
        closeExploreModal={closeExploreModal}
        prevStep={prevStep}
        type={customProps.type}
      ></ExploreModal>
    );
  };

  const showTableInfo = () => {
    switch (step) {
      case 1:
        return (
          <TwoWayTableModal
            customProps={customProps}
            dateRange={dateRange}
            infoModal={infoModal}
            setInfoModal={setInfoModal}
            nextStep={nextStep}
            setMoodTableProps={setMoodTableProps}
          ></TwoWayTableModal>
        );
      case 2:
        return (
          <ExploreModal
            customProps={moodTableProps}
            dateRange={dateRange}
            isModalVisible={isModalVisible}
            closeExploreModal={closeExploreModal}
            prevStep={prevStep}
            type={customProps.type}
          ></ExploreModal>
        );
      default:
        return <></>;
    }
  };

  const showClusterInfo = () => {
    switch (step) {
      case 1:
        return (
          <ClusterModal
          customProps={customProps}
          dateRange={dateRange}
          isModalVisible={infoModal}
          closeExploreModal={closeExploreModal}
          nextStep={nextStep}
          setLdaProps={setLdaProps}
          type={customProps.type}
        ></ClusterModal>
        );
      case 2:
        return (
          <ExploreModal
            customProps={ldaProps}
            dateRange={dateRange}
            isModalVisible={isModalVisible}
            closeExploreModal={closeExploreModal}
            prevStep={prevStep}
            type={customProps.type}
          ></ExploreModal>
        );
      default:
        return <></>;
    }
  };

  if (customProps.type === "Table") return showTableInfo();
  else if (customProps.type === "List") {
    return showListInfo();
  }
  else if(customProps.type === "Cluster") 
  return showClusterInfo();
  else return null;
};

export default MainModal;
