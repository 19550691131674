import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

function ParticipantCountryBarChart({ chartData }) {

  const classes = useStyles();

  useEffect(() => {
    const chart = createChart();
    chart.data = chartData;

    return function cleanup() {
      chart.dispose();
    }
  }, [chartData]);

  return (
    <React.Fragment>
      <div className={classes.simpleBarChartTitle}>Participants across different countries</div>
      <div id="simpleBarChart" className={classes.simpleBarChart}></div>
    </React.Fragment>
  );
}

function createChart() {
  const chart = am4core.create("simpleBarChart", am4charts.XYChart);
  addCategoryAxis(chart);
  addValueAxis(chart);
  const series = addSeries(chart, "value", "Value");
  addLabel(series);
  chart.cursor = new am4charts.XYCursor();
  return chart;
}

function addCategoryAxis(chart) {
  const axis = chart.yAxes.push(new am4charts.CategoryAxis());
  axis.dataFields.category = "name";
  const renderer = axis.renderer;
  renderer.minGridDistance = 20;
  renderer.inversed = true;
  const template = renderer.grid.template;
  template.location = 0;
  template.strokeOpacity = 0;
  template.strokeWidth = 0;
}

function addValueAxis(chart) {
  const axis = chart.xAxes.push(new am4charts.ValueAxis());
  const renderer = axis.renderer;
  renderer.grid.template.strokeOpacity = 0;
  renderer.grid.template.strokeWidth = 0;
  renderer.labels.template.fontSize = 0;
}

function addSeries(chart, field, name) {
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueX = field;
  series.dataFields.categoryY = "name";
  series.name = name;
  series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
  series.columns.template.height = 40;
  series.sequencedInterpolation = true;
  series.stroke = am4core.color("#01b4d0");
  series.fill = am4core.color("#01b4d0");
  return series;
}

function addLabel(series) {
  const valueLabel = series.bullets.push(new am4charts.LabelBullet());
  const label = valueLabel.label;
  label.text = "{valueX}";
  label.horizontalCenter = "left";
  label.dx = 0;
  label.hideOversized = false;
  label.truncate = false;
  label.fill = am4core.color("rgba(0, 0, 0, 0.38)");
  label.fontSize = '12px';
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  simpleBarChart: {
    height: '300px',
    paddingRight: '20px',
    paddingTop: '10px',
    width: '100%',
  },
  simpleBarChartTitle: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '24.4px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    marginBottom: '10px',
    padding: '24px 24px 0px 24px',
  },
}));

export default ParticipantCountryBarChart;