import { gql } from '@apollo/client';

import { setupApi } from '../graphql/client';

export const FETCH_ENTITIES_LOADING = 'FETCH_ENTITIES_LOADING';
export const FETCH_ENTITIES_SUCCESS = 'FETCH_ENTITIES_SUCCESS';
export const FETCH_ENTITIES_FAILURE = 'FETCH_ENTITIES_FAILURE';

const client = setupApi();

function fetchEntitiesLoading() {
  return {
    type: FETCH_ENTITIES_LOADING,
  };
}

function fetchEntitiesSuccess(payload) {
  return {
    type: FETCH_ENTITIES_SUCCESS,
    payload,
  };
}

function fetchEntitiesFailure(payload) {
  return {
    type: FETCH_ENTITIES_FAILURE,
    payload,
  }
}

export function fetchEntities(forum_id, client_id, startDate = '', endDate = '',demographicsWhere, withCommentData = false) {
  return async (dispatch) => {
    try {
      dispatch(fetchEntitiesLoading());
      forum_id = forum_id == '' ? undefined : forum_id;
      const forumParam = forum_id ? '$forum_id: String!' : '';
      const forumWhereParam = forum_id ? 'forum_id: $forum_id' : '';
      const subForumValue = forum_id ? '$forum_id' : 'null';

      const dateTimeParam = startDate != '' && endDate != '' ? '$startDate: DateTime!\n$endDate: DateTime!' : '';
      const dateTimeWhereParam  = startDate != '' && endDate != '' ? 'create_datetime_GTE: $startDate, create_datetime_LTE: $endDate ' : '';
      const dateTimeSubWhereParam  = startDate != '' && endDate != '' ? ', date_filter: { create_datetime_GTE: $startDate, create_datetime_LTE: $endDate }' : '';
      const commentsTooFields = !withCommentData ? '' : 'comments(where: { create_datetime_GTE: $commentStartDate, create_datetime_LTE: $commentsEndDate }) { create_datetime, sentiment { sentiment } }';
      const commentsTooParams = !withCommentData ? '' : '$commentStartDate: DateTime!\n$commentsEndDate: DateTime!';
      
      const demographicsParam = '$demographicsWhere: UserDemographicsWhere';
      const demographicWhereParam = 'demographics: $demographicsWhere';
      const preppedQuery = `query GetEntities(
        $client_id: String
        ${forumParam}
        ${dateTimeParam}
        ${commentsTooParams}
        ${demographicsParam}
      ){
        entities(
          options: { limit: 500, offset: 0 }
          where: {
            chunks:{
                comment: {
                    client_id: $client_id,
                    ${forumWhereParam}
                    AND: {
                      ${dateTimeWhereParam}
                      ${demographicWhereParam}
                    }
                }
            }
          }
        ) {
          name
          process_datetime
          chunk_avg_sentiment(client_id: $client_id, forum_id: ${subForumValue} ${dateTimeSubWhereParam} chunkWhere: {comment: {demographics: $demographicsWhere}})
          chunk_count(client_id: $client_id, forum_id: ${subForumValue} ${dateTimeSubWhereParam} chunkWhere: {comment: {demographics: $demographicsWhere}})
          entityTypes {
            text
            __typename
          }
          ${commentsTooFields}
          __typename
        }
      }`;

      const query = {
        query:  gql`${preppedQuery}`,
        variables: {
          ... {
            forum_id,
            client_id,
            startDate,
            endDate,
            demographicsWhere
          },
          ... (!withCommentData) ? {} : {
            commentStartDate: withCommentData.startDate,
            commentsEndDate: withCommentData.endDate,
          }
        }
      };

      console.log('gqlQuery', preppedQuery, JSON.stringify(query.variables, null, 2));

      const res = await client.query(query);
      return dispatch(fetchEntitiesSuccess(res.data.entities));

    } catch (error) {
      console.error(error);
      dispatch(fetchEntitiesFailure({
        error: {
          type: typeof error,
          message: error.toString(),
        },
      }));
    }
  }
}
