export function transformParticipationByAgeGroupResultSet(rs) {
  const data = [];
  if (rs) {
    for (let item of rs) {
      data.push([
        item['ParticipationsDenorm.surveyAgeGroup'],
        item['ParticipationsDenorm.count'],
      ]);
    }
  }
  return data;
}

export function transformParticipationByConversationResultSet(rs) {
  const data = [[]];
  if (rs) {
    let i = 0;
    let j = 0;
    for (let item of rs) {
      data[j].push([
        item['ParticipationsDenorm.conversation'],
        item['ParticipationsDenorm.count'],
      ]);
      i += 1;
      if (i % 5 === 0) {
        data.push([]);
        j += 1;
      }
    }
  }
  
  return data;
}

export function transformParticipationByGenderResultSet(rs) {
  const data = [];
  if (rs) {
    for (let item of rs) {
      data.push([
        item['ParticipationsDenorm.surveyGender'],
        item['ParticipationsDenorm.count'],
      ]);
    }
  }
  return data;
}

export function transformUserStatisticResultSet(statistic) {
  return (rs) => {
    const data = { total: 0, value: 0 };
    if (rs) {
      for (let item of rs) {
        let count = parseInt(item['ParticipationsDenorm.count'], 10);
        if (item[`ParticipationsDenorm.${statistic}`] === 1) {
          data.value = count;
        }
        data.total += count;
      }
    }
    return data;
  };
}

export function transformParticipationsByDimAndActiveResultSet(rs) {
  const chartData = {};
  const data = {
    chartData: {},
    title: '',
  };
  if (rs.length) {
    const dimKey = Object.keys(rs[0])[0];
    // TODO
    // rs contains 257 items that are not supposed to appear
    // I guess we get those data due to the amchart map module
    // currently I just slice the first 6
    data.title = dimKey.split('.')[1].slice(6);
    for (let item of rs) {
      const filed = item[dimKey];
      const isActive = parseInt(item['ParticipationsDenorm.active'], 10);
      if (!chartData[filed]) {
        chartData[filed] = {
          filed,
          value1: 0,
          value2: 0,
        };
      }
      const count = item['ParticipationsDenorm.count'];
      if (isActive) {
        chartData[filed].value1 += count;
        chartData[filed].value2 = count;
      } else {
        chartData[filed].value1 += count;
      }
    }
    data.chartData = Object.values(chartData);
  } else {
    data.chartData = chartData;
  }

  return data;
}
