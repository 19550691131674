import React from 'react';

import { LDAvis } from './react_ldavis';

// import LDAvisDataList from './data';

function TopicVis({ data, onSelect, title, subTitle }) {

  const handleSelect = (topicNum) => {
    if (onSelect) {
      onSelect(topicNum);
    }
  };

  if (data && data.mdsDat) {
    return (
      <>
      { title ?
      <h1>LDA Visualisations for {title}</h1>:""}
      {subTitle? <h4>{subTitle}</h4>:""}
      <LDAvis
        // data={data || LDAvisDataList[0]}
        data={data}
        modifyHistory={false}
        style={{ textAlign: "center" }}
        callback={handleSelect}
      />
      </>
    );
  } else {
    return (
      <div>Loading...</div>
    );
  }
}

export default TopicVis;