import React from 'react';
import { Card, Row, Col, Button } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useIntl } from "react-intl"; 
import generateId from './generateId';

const { confirm } = Modal;

/**
 * Inject an "Add new chart" button at the end of the data frame
 * draggable list.
 */
export default (setData, dataSourcesCopy, dsIndex, rowIndex) => () => {
  const row = dataSourcesCopy[dsIndex].rows[rowIndex];
  const cols = (!row || !row.columns) ? [] : row.columns;
  const columnsEmpty = cols.length == 0;
  const intl = useIntl();
  const addNewChartPlaceholder = intl.formatMessage({id: 'app.dashboardEditor.addNewChart',defaultMessage: 'Add new chart'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  const okPlaceholder = intl.formatMessage({id: 'app.ok',defaultMessage: 'Ok'});
  const defaultTitlePlaceholder = intl.formatMessage({id: 'app.dashboardEditor.defaultTitle',defaultMessage: 'Give me a name'})
  const remove = () => {
    confirm({
      title: 'Are you sure you want to delete this row?',
      icon: <ExclamationCircleOutlined />,
      content: null,
      onOk() {
        dataSourcesCopy[dsIndex].rows.splice(rowIndex, 1);
        setData([ ...dataSourcesCopy ]);
      },
      onCancel() { },
      okText:okPlaceholder,
      cancelText:cancelPlaceholder,
    });
  };

  return (
    <Card
      style={
        !columnsEmpty ? {
          backgroundColor: '#f5f5f5',
          border: '1px dashed #333',
          textAlign: 'center'
        } : {}
      }
      bodyStyle={
        {
          padding: '5px'
        }
      }
    >
      <Row>
        {
          columnsEmpty &&
          <Col span={ 1 } style={ { textAlign: 'center' } }>
            <Button
              size="small"
              danger
              onClick={ remove }
              style={
                {
                  marginTop: '3px'
                }
              }
            >
              <DeleteOutlined />
            </Button>
          </Col>
        }
        <Col span={ columnsEmpty ? 23 : 24 }>
          <Button
            type="text"
            style={
              {
                ... {
                  width: '100%',
                },
                ... ((columnsEmpty) ? {
                  backgroundColor: '#f5f5f5',
                  border: '1px dashed #333',
                  textAlign: 'center'
                } : {})
              }
            }
            onClick={ () => {
              if (columnsEmpty) {
                dataSourcesCopy[dsIndex].rows[rowIndex].columns = [];
              }
              dataSourcesCopy[dsIndex].rows[rowIndex].columns.push({
                id: generateId(),
                text: defaultTitlePlaceholder,
                isNew: true
              })
              setData([ ...dataSourcesCopy ]);
            }}
          >
            {addNewChartPlaceholder}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};