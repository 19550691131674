import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';


import { Card, Divider, Row, Col, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { fetchDashboardData } from '../actions';
import { saveDashboardData } from '../actions';
import PageHeading from '../../components/PageHeading.jsx';
import TiledDashboardEditor from '../components/TiledDashboardEditor.jsx';
import FullScreenModal from '../../components/FullScreenModal.jsx';
import ClusterChartWidget from '../../Overview/ClusterChartWidget';
import CommentWidget from '../../Overview/CommentWidget';
import EmojifyWidget from '../../Overview/EmojifyWidget';
import EmojifyTopicWidget from '../../Overview/EmojifyTopicWidget';
import SentimentedEntitiesWidget from '../../Overview/SentimentedEntitiesWidget';
import MoodWidget from '../../Overview/MoodWidget';
import LDAWidget from '../../Overview/LDAWidget';
import KnowledgeGraphWidget from '../../Overview/KnowledgeGraphWidget';
import ProblemSolutionWidget from '../../Overview/ProblemSolutionWidget';
import ParticipationChartWidget from '../../Overview/ParticipationChartWidget';
import MoodTableView from '../../Explore/MoodTableView';
import MoodTableWidget from '../../Overview/MoodTableWidget';
import MathTableWidget from '../../Overview/MathTableWidget';
import ReportImgContextProvider from '../../context/report-context';
import DashboardContextProvider from '../context/DashboardContextProvider';
import { DashboardContext } from '../context/DashboardContextProvider';
import PowerfulStatementsWidget from '../../Overview/PowerfulStatementsWidget';
import { FormattedMessage, useIntl } from "react-intl"; 

function TiledDashboardPage({ forumDataSources }) {

  const { setDashboardConfig } = useContext(DashboardContext);
  const [ dataSources, setDataSources ] = useState([]);
  const [ dataSourcesCopy, setDataSourcesCopy ] = useState([]);
  const [ showEditor, setShowEditor ] = useState(false);
  const data = useSelector((state) => state.dashboards.data);
  const dispatch = useDispatch();

  const intl = useIntl();
  const noDataPlaceholder = intl.formatMessage({id: 'app.dashboard.noData',defaultMessage: 'Your dashboard is empty.'})
  const titlePlaceholder = intl.formatMessage({id: 'app.dashboardEditor.title',defaultMessage: 'Dashboard editor'})
  const titleInfoPlaceholder = intl.formatMessage({id: 'app.dashboardEditor.title.info',defaultMessage: 'This is where you set up your dashboard to hold the charts to visualize your data. You can have up to 3 charts across the page and as many rows of them as you want. \nTo begin with you set up a “data frame”, which selects the source of the data to populate your charts. You then create the spaces where you will insert the charts you want. '})
  const savePlaceholder = intl.formatMessage({id: 'app.save',defaultMessage: 'Save'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  
  useEffect(() => {
    setDataSources(data.content || []);
    setDashboardConfig(data);
  }, [ data ]);

  useEffect(() => {
    (async () => {
      await dispatch(fetchDashboardData());
    })();
  }, []);

  useEffect(() => {
    if (showEditor) {
      // introduce a slight delay because cloneDeep can cause frame lag during
      // the modal setup and animation.
      setTimeout(() => {
        // use cloneDeep to detach the nested arrays of objects so react doesn't
        // accept changes during the editing process before OK is clicked.
        setDataSourcesCopy(_.cloneDeep(dataSources));
      }, 200);
    }
  }, [ showEditor ]);

  const handleEditorShow = () => {
    setShowEditor(true);
  };

  const handleEditorOk = () => {
    // use cloneDeep to create a new distinct copy of the config
    setDataSources(_.cloneDeep(dataSourcesCopy));
    setShowEditor(false);
    dispatch(saveDashboardData({
      ... (data || {}),
      content: dataSourcesCopy
    }));
  };

  const handleEditorCancel = () => {
    setShowEditor(false);
    setDataSourcesCopy([])
  };

  const onChange = (values) => {
    setDataSourcesCopy(values);
  };

  const renderChart = (dataSource, row, column) => {
    let forumId = (column.dataSourceOverride == 'true') ? column.dataSourceId : dataSource.dataSourceId;
    if (forumId === 'all') {
      forumId = undefined;
    }

    const startDate = (column.startDate) ? column.startDate : '2020-06-01';
    const endDate = (column.autoEndDate === 'false') ? column.endDate : moment().format('YYYY-MM-DD');

    // don't pass in the object because it will cause the react state to change on
    // ever re-render, which will in-turn cause the chart to re-query and re-render.
    const dateRange = {
      startDate: startDate,
      endDate: endDate
    };

    const customProps = {
      title: column.text,
      forumId: forumId,
      actions: [ <span>{ startDate } - { endDate }</span> ],
      useContextRules: false,
      filters: column.filters || {}
    };

    switch(column.chartType) {
      case 'sentimented-topics':
        return <EmojifyTopicWidget { ... customProps } { ... dateRange } />;
      case 'sentimented-entities':
        return <SentimentedEntitiesWidget { ... customProps } { ... dateRange } />;
      case 'most-discussed-topics':
        return <ClusterChartWidget { ... customProps } { ... dateRange } />;
      case 'emojify':
        return <EmojifyWidget { ... customProps } { ... dateRange } />;
      case 'mood':
        return <MoodWidget { ... customProps } { ... dateRange } />;
      case 'data-analysed':
        return <CommentWidget { ... customProps } { ... dateRange } />;
      case 'topic-ldavis':
        return <LDAWidget { ... customProps } { ... dateRange } />;
      case 'problem-solution':
        return <ProblemSolutionWidget { ... customProps } { ... dateRange } type= {column.problemSolutionType} />;
      case 'knowledge-graph':
        return <KnowledgeGraphWidget { ... customProps } { ... dateRange } />;
      case 'mood-table':
        return <MoodTableWidget { ... customProps } { ... dateRange } />;
      case 'math-table':
        return <MathTableWidget { ... customProps } { ... dateRange } />;
      case 'powerful-statements':
        return <PowerfulStatementsWidget { ... customProps } { ... dateRange }/>;
    }

    return (
      <Card
        style={ { width: '100%' }}
      >
        { column.text } has no chart type defined.
      </Card>
    )
  };

  const colResponsiveOptions = {
    '1x1': {
      xxl: 24,
      xl: 24,
      lg: 24,
      md: 24,
      sm: 24,
      xs: 24
    },
    '1x1:last': {
      xxl: 24,
      xl: 24,
      lg: 24,
      md: 24,
      sm: 24,
      xs: 24
    },
    '1x2': {
      xxl: 12,
      xl: 12,
      lg: 24,
      md: 24,
      sm: 24,
      xs: 24
    },
    '1x2:last': {
      xxl: 12,
      xl: 12,
      lg: 24,
      md: 24,
      sm: 24,
      xs: 24
    },
    '1x3': {
      xxl: 8,
      xl: 12,
      lg: 12,
      md: 24,
      sm: 24,
      xs: 24
    },
    '1x3:last': {
      xxl: 8,
      xl: 24,
      lg: 24,
      md: 24,
      sm: 24,
      xs: 24
    },
  };

  return (
    <>
      <PageHeading>My Dashboard</PageHeading>
      <FullScreenModal
        title={titlePlaceholder}
        open={showEditor}
        onOk={handleEditorOk}
        onCancel={handleEditorCancel}
        okText={savePlaceholder}
        cancelText={cancelPlaceholder}
        buttonProps={{ style: { minWidth: "140px" } }}
      >
        <>
          <Card style={{ width: "100%", borderStyle: "dashed" }}>
            <p style={{ whiteSpace: "pre-line" }}> {titleInfoPlaceholder}</p>
          </Card>
          {showEditor && (
            <TiledDashboardEditor
              forumDataSources={forumDataSources}
              dataSources={dataSourcesCopy}
              onChange={onChange}
            />
          )}
        </>
      </FullScreenModal>
      {dataSources && dataSources.length == 0 && (
        <Card style={{ width: "100%", borderStyle: "dashed" }}>
          {noDataPlaceholder} <a onClick={handleEditorShow}>Configure</a> it
          now.
        </Card>
      )}
      {dataSources && dataSources.length > 0 && (
        <>
          <div style={{ padding: "10px", textAlign: "right" }}>
            <Button
              type="primary"
              style={{ borderRadius: "8px", minWidth: "150px" }}
              onClick={handleEditorShow}
            >
              <EditOutlined />
              <FormattedMessage id="app.edit" defaultMessage="Edit" />
            </Button>
          </div>
          {dataSources.map((dataSource) => (
            <>
              <Divider
                orientation="left"
                plain
                style={{ color: "rgb(96, 125, 139)", fontSize: "25px" }}
              >
                {dataSource.text}
              </Divider>
              {dataSource.rows &&
                dataSource.rows.map((row) => (
                  <>
                    {row.columns && row.columns.length > 0 && (
                      <>
                        <Row
                          style={{ padding: "0 0 10px 0" }}
                          gutter={[16, 16]}
                        >
                          {row.columns.map((column, index) => (
                            <Col
                              key={index}
                              id={
                                "1x" +
                                row.columns.length.toString() +
                                (index == row.columns.length - 1 ? ":last" : "")
                              }
                              {...colResponsiveOptions[
                                "1x" +
                                  row.columns.length.toString() +
                                  (index == row.columns.length - 1
                                    ? ":last"
                                    : "")
                              ]}
                            >
                              {renderChart(dataSource, row, column)}
                            </Col>
                          ))}
                        </Row>
                      </>
                    )}
                  </>
                ))}
            </>
          ))}
        </>
      )}
    </>
  );
};

function TiledDashboardPageContextWrap(props) {
  return (
    <ReportImgContextProvider>
      <DashboardContextProvider>
        <TiledDashboardPage { ... props } />
      </DashboardContextProvider>
    </ReportImgContextProvider>
  );
};

export default TiledDashboardPageContextWrap;