import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { message } from 'antd';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash.isempty';
import isString from 'lodash.isstring';
import querystring from 'querystring';
import Checkbox from '@material-ui/core/Checkbox';

import { search } from '../actions/search';
import { fetchCommentDemographicsByCommentIds } from '../actions/demographics';
import Loading from '../components/Loading';
import { facets } from '../transformers/emoji_labels';
import BookMark from '../components/BookMark';
import ChunkSelector from '../components/ChunkSelector';
import NoData from '../components/NoData';
import PageHeading from '../components/PageHeading';
import { ChunksAndFilter } from '../components/ChunkViewer';
import '../components/Cards/emoticons.css';

const facetsMap = facets.reduce((a, f) => {
  a[f.label] = f.color;
  return a;
}, {});

const moodList = [
  'emoticons-face1',
  'emoticons-face2',
  'emoticons-face3',
  'emoticons-face4',
  'emoticons-face5',
  'emoticons-face6',
  'emoticons-face7',
];

function SearchResults() {
  const location = useLocation();
  const { algo = 'w2v', from = '', q = '', sourceId = 'all', topicNum } = querystring.parse(location.search.slice(1));

  return (
    <SearchResultsView algo={algo} from={from} q={q} sourceId={sourceId} topicNum={topicNum} />
  );

}

function SearchResultsView({ algo, from, q, sourceId, topicNum }) {

  const [ loading, setLoading ] = useState(true);
  const [searchResultLoaded, setSearchResultLoaded]= useState(false);
  const [selectedChunks, setSelectedChunks] = useState([]);
  const [title, setTitle] = useState(q.charAt(0).toUpperCase() + q.slice(1));
  const [ comments, setComments ] = useState([]);

  const results = useSelector((state) => state.search.data);
  //const loading = useSelector((state) => state.search.loading);
  const user = useSelector((state) => state.user);
  const texts = useSelector((state) => state.lda.data.texts || []);

  let data;
  if (texts && from === 'lda') {
    data = (texts[topicNum] || []).map((text) => ({
      chunk_uuid: '',
      comment_uuid: '',
      emoji_label: '',
      sentiment: '',
      text,
    }));
  }

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    (async () => {
    setLoading(true);
    let data;
    if (from === 'lda') {
      setTitle('Comments');
    } else if (q) {
      if (sourceId && isString(sourceId)) {
       data = await dispatch(search(q, sourceId, algo));
      } else {
        data = await dispatch(search(q, 'all', algo));
      }
      if(data)
      {
        setSearchResultLoaded(true);
      }
      setSelectedChunks([]);
      setTitle(q.charAt(0).toUpperCase() + q.slice(1));
    }})();
  }, [ q, sourceId ]);

  useEffect(() => {
    (async () => {
      if (!searchResultLoaded) {
        return;
      }
      if (results.length == 0 || !user) {
        return;
      }
      setComments(results);
      setLoading(false);

      const hide = message.loading('Building demographic data ...', 0);
      const chunks = await dispatch(fetchCommentDemographicsByCommentIds({
        client_id: user.client_id,
        comment_ids: results.map((result) => result.comment_uuid)
      }));
      console.log('chunks', chunks);
      const _comments = results.map((result) => {
        const chunkData = chunks.filter((chunk) => chunk.comment.uid == result.comment_uuid);
        return {
          ... result,
          ... {
            plutchik_category: result.emoji_label? result.emoji_label:"",
            chunk_id: result.chunk_uuid
          },
          ... (chunkData.length == 0 ? {} : chunkData[0])
        }
      }).filter((record) => record.comment != undefined)
      setComments([ ... _comments ]);
      hide();
      setSearchResultLoaded(false);
    })();
  }, [ searchResultLoaded ]);

  useEffect(() => {
    console.log('comments', comments);
  }, [ comments ]);

  return (
    <div className={classes.outerWrap}>
      <div className={classes.leftWrap}>
        <div className={classes.topSubNav}>
          <KeyboardArrowLeftOutlinedIcon />
          <Link to={'/' + from}>
            <div className={classes.link}> Back </div>
          </Link>
        </div>

        <Loading loading={loading}>
          <PageHeading style={{ fontSize: (title.length > 40) ? '20px' : '38px'}}>
            {from === 'lda' ? 'Comments' : `Search results of query "${q}"`}
          </PageHeading>
          { !loading && comments && comments.length > 0 && <ChunksAndFilter client_id={ user.client_id } data={ comments } title={title} forum_id={''}/> }
          { (!comments || comments.length == 0) && <NoData /> }
        </Loading>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  leftWrap: {
    borderRight: 'solid 1px #efefef',
    paddingBottom: '42px',
    width: '100%',
  },
  link: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  mainText: {
    width: '85%'
  },
  markWrap: {
    alignItems: 'center',
    color: '#516e88',
    marginBottom: '22px',
    marginLeft: '1em',
    opacity: 0.38,
  },
  outerWrap: {
    display: 'flex',
    width: '100%',
  },
  sectionLeft: {
    marginRight: '16px',
    minWidth: '60px',
    width: '4%',
  },
  sectionRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '96%'
  },
  sectionWrap: {
    borderBottom: 'solid 1px #efefef',
    display: 'flex',
    marginRight: '2em',
    padding: '24px 0',
  },
  subSectionName: {
    color: '#516e88',
    display: 'inline-block',
    fontSize: '24.4px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '29px',
    letterSpacing: 'normal',
    lineHeight: '29px',
    marginBottom: '14px',
    marginTop: '34px',
    width: '100%',
  },
  textStyle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 1.41,
    marginBottom: '12px',
  },
  titleWrap: {
    display: 'inline-block',
    width: '100%',
  },
  topSubNav: {
    color: 'rgba(0, 0, 0, 0.38)',
    display: 'flex',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '24px',
    letterSpacing: '0.4px',
    lineHeight: '24px',
    marginTop: '10px',
    width: '100%',
  },
  tagArea: {
    width: '15%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: '1em',
    alignItems: 'center',
    paddingLeft: '1em',
  },
  emojiWrap: {
    borderRadius: '65px',
    marginRight: '4%',
    marginLeft: '4%',
    paddingTop: '17px',
    paddingBottom: '17px',
    height: '65px',
    textAlign: 'center',
    width: '65px',
  },
  sentimentWrap: {
    marginRight: '4%',
    marginLeft: '4%',
    paddingTop: '12px',
    paddingBottom: '12px',
    height: '3.5em',
    textAlign: 'center',
    width: '30%'
  },
  tagText: {
    color: '#fff',
    fontSize: '9px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0.25px',
  },
  moodSizeControl: {
    height: '28px',
    width: '28px',
  },
  menuWrap: {
    color: 'rgba(0, 0, 0, 0.38)',
    display: 'flex',
    //justifyContent: 'space-between',
    flexDirection: 'row',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    lineHeight: 'normal',
  },
  checkboxWrap: {
    margin: '0em 0em 0em 0em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  checkbox: {
    color: '#8da4bc',
    padding: 0,
    '&$checked': {
      color: '#8da4bc',
    },
  },
  optionText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 'normal',
  },
  saveReportWrap: {
    margin: '0em 2em 0em 2em',
    alignItems: 'center',
  },
}));

export default SearchResults;