import React from "react";
import { Table, Button, Row, Col } from "antd";
import { useIntl } from "react-intl"; 
import ActiveSetInfo from "./ActiveSetInfo";
import "./magicWindow.css";
export default function Describe({ ...props }) {
  const intl = useIntl();
  const wordPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.word',defaultMessage: 'Word'});
  const countPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.count',defaultMessage: 'Count'});
  const describePlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.describe',defaultMessage: 'Describe'});
  const savePlaceholder = intl.formatMessage({id: 'app.save',defaultMessage: 'Save'});

  const columns = [
    {
      title: wordPlaceholder,
      dataIndex: "token",
      key: "token",
      width:100
    },
    {
      title: countPlaceholder,
      dataIndex: "frequency",
      key: "frequency",
      width:100
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width:500
    },
  ];
  return (
    <>
      <Row>
        <Col span="14">
          <ActiveSetInfo
            averageSentiment={props.averageSentiment}
            totalChunks={props.totalChunks}
            title={props.title}
            sorter={props.sorter}
          />
        </Col>
        <Col span="10">
          <Row style={{ marginTop: "100px" }}>
            <Col>
            {props.tokens ==="" && <Button
              type="primary"
              style={{ borderRadius: "8px", minWidth:"100px"  }}
              onClick={() => props.showDescription()}
            >
              {describePlaceholder}
            </Button>
            }
            {props.tokens !=="" && 
            <Button
              style={{ marginLeft: "10px" }}
              disabled
            >
              {describePlaceholder}
            </Button>
            }
          </Col>
          <Col>
           <Button
              type="primary"
              style={{ marginLeft: "20px",borderRadius: "8px", minWidth:"100px"  }}
              onClick={() => props.saveAnalysis()}
            >
              {savePlaceholder}
            </Button>
          </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className="customStyle"
        dataSource={props.tokens}
        columns={columns}
        pagination={false}
        style={{ marginTop: "20px" }}
      />
    </>
  );
}
