export const facets = [
  { x: 'Joy scale', label: 'Serenity', a: 80, color: '#E4D144' },
  { x: 'Trust scale', label: 'Acceptance', a: 80, color: '#AEDE3F' },
  { x: 'Fear scale', label: 'Apprehension', a: 80, color: '#57CDBF' },
  { x: 'Surprise scale', label: 'Distraction', a: 80, color: '#44A9DC' },
  { x: 'Sadness scale', label: 'Pensiveness', a: 80, color: '#5957C7' },
  { x: 'Disgust scale', label: 'Boredom', a: 80, color: '#BA3EBF' },
  { x: 'Anger scale', label: 'Annoyance', a: 80, color: '#E86458' },
  { x: 'Anticipation scale', label: 'Interest', a: 80, color: '#F49054' },
  { x: 'Joy scale', label: 'Joy', b: 50, color: '#DAAD07' },
  { x: 'Trust scale', label: 'Trust', b: 50, color: '#82B90F' },
  { x: 'Fear scale', label: 'Fear', b: 50, color: '#1F9989' },
  { x: 'Surprise scale', label: 'Surprise', b: 50, color: '#2171A9' },
  { x: 'Sadness scale', label: 'Sadness', b: 50, color: '#3836A8' },
  { x: 'Disgust scale', label: 'Disgust', b: 50, color: '#89178D' },
  { x: 'Anger scale', label: 'Anger', b: 50, color: '#BB382D' },
  { x: 'Anticipation scale', label: 'Anticipation', b: 50, color: '#DB680D' },
];

export function transformEmojiLabelsResultSet(rs) {

  const facetsMap = facets.reduce((a, f) => {
    a[f.label] = f;
    return a;
  }, {});
  for (const l of rs) {
    const name = l.plutchik_category;
    if (facetsMap[name]) {
      facetsMap[name] = {
        ...facetsMap[name],
        comment_count: l.comment_count,
        cropped_count: l.cropped_count
      };
    }
  }
  return Object.values(facetsMap);
}
