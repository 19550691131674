import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { List } from "antd";
import "./magicWindow.css";
import { useIntl } from "react-intl"; 
export default function ActiveSetInfo({
  averageSentiment,
  totalChunks,
  title,
  sorter,
}) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const activeSetPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.activeSet',defaultMessage: 'Active Set'});
  const sortingPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.sorting',defaultMessage: 'Sorting'});
  const sentimentPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.sentiment',defaultMessage: 'Sentiment'});
  const chunksPlaceholder = intl.formatMessage({id: 'app.explorePage.analyzeChunks.chunks',defaultMessage: 'Chunks:'});

  let sortTitle = "Unsorted";
  const sortOrder = sorter ? sorter.order : "";
  if (sortOrder === "ascend")
    sortTitle = sorter.column.title + " in ascending order";
  else if (sortOrder === "descend")
    sortTitle = sorter.column.title + " in descending order";

  return (
    <div className="customStyle">
      <List>
        <List.Item>
          <div style={{ minWidth: "20%" }}>{activeSetPlaceholder}: </div>
          <div style={{ minWidth: "80%" }}>{title}</div>
        </List.Item>
        <List.Item>
          <div style={{ minWidth: "20%" }}>{sortingPlaceholder}: </div>
          <div style={{ minWidth: "80%" }}>{sorter}</div>
        </List.Item>
        <List.Item>
          <div style={{ minWidth: "20%" }}>{sentimentPlaceholder}: </div>
          <div style={{ minWidth: "80%" }}>
            {Math.round(averageSentiment * 100) / 100}
          </div>
        </List.Item>
        <List.Item>
          <div style={{ minWidth: "20%" }}>{chunksPlaceholder}: </div>
          <div style={{ minWidth: "80%" }}>{totalChunks}</div>
        </List.Item>
      </List>
    </div>
  );
}
