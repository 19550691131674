import isEmpty from 'lodash.isempty';

export function combineStyles(...styles) {
  return (theme) =>
    styles.map((clz) => {
      if (typeof clz === 'function') {
        return clz(theme);
      }
      return clz;
    })
    .reduce((a, v) => Object.assign(a, v));
}

export function* enumerate(iterable) {
  let i = 0;
  for (const x of iterable) {
    yield [i, x];
    i++;
  }
}

export function mapStateToCubeData(state, ...queries) {
  return queries.reduce((a, q) => {
    a[q.type] = state.cube[q.type];
    return a;
  }, {});
}

export function notEmpty(...values) {
  for (const val of values) {
    if (isEmpty(val)) {
      return false;
    }
  }
  return true;
}