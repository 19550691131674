import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Modal, Button, Pagination } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import {FormattedMessage,useIntl} from "react-intl";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash.isempty';
import querystring from 'querystring';
import ChunkSelector from '../components/ChunkSelector';
import Loading from '../components/Loading';
import { fetchComments, getChunksMultiFilter } from '../actions/comment';
import NoData from '../components/NoData';
import PageHeading from '../components/PageHeading';

import BookMark from '../components/BookMark';

import { facets } from '../transformers/emoji_labels';
import {topicMapping} from '../Overview/topicMapping';
import { ChunksAndFilter } from '../components/ChunkViewer';


import '../components/Cards/emoticons.css';

const DEFAULT_SENTIMENT = 3;

const facetsMap = facets.reduce((a, f) => {
  a[f.label] = f.color;
  return a;
}, {});


const queryTypeMap = {
  'graph': 'cluster-element',
  'topics': 'topic',
  'explore': 'topic',
  'emojify-topic': 'topic',
  'emoji': 'emoji',
  'sentimented-topics': 'topic',
  'sentimented-entities': 'entity',
  'problem-solution': 'problem-solution'
}

const sortByOption = {
  0: 'sentiment',
  1: 'plutchik_category'
}

const sum = (ary) => {
  if (!ary || ary.length === 0) return 0;
  return ary.reduce((a, b) => a + b, 0);
}


function ChunksView() {

  const location = useLocation();
  let { from = '', q = 'anger' } = querystring.parse(location.search.slice(1));

  let query_type;
  let forumUuid;
  const fromParts = from.split('/');
  console.log('fromParts', fromParts);

  if (fromParts[0] === 'forums') {
    query_type = queryTypeMap[fromParts[2]];
    forumUuid = fromParts[1];
  } else if (from.indexOf('explore/data-layer/') > -1) {
    query_type = queryTypeMap[fromParts[3]];
    forumUuid = '';
  } else {
    query_type = fromParts[0] === '' ? (Object.keys(facetsMap).includes(capitalizeFirstChar(q)) ?'emoji': (topicMapping.includes(q)?'topic': 'entity')) : queryTypeMap[fromParts[1]];
  }
  const intl = useIntl();
  const commentChunksPlaceholder = intl.formatMessage({ id:"app.explorePage.CommentChunks",
  defaultMessage:"Comment Chunks Related to "})
  const okPlaceholder = intl.formatMessage({id: 'app.ok',defaultMessage: 'Ok'});
  const cancelPlaceholder = intl.formatMessage({id: 'app.cancel',defaultMessage: 'Cancel'});
  // emoji query needs to be capitalized
  q = query_type === 'cluster-element' ? q : capitalizeFirstChar(q);

  const backLink = '/' + from;

  const [ firstOption, setFirstOption ] = useState(0);
  const [ moodFilter, setMoodFilter]  = useState([0, 0, 0, 0, 0, 0, 0]);
  const [ modalIsVisible, setModalIsVisible ] = useState(false);
  const [ modalBody, setModalBody ] = useState(null);
  const [ pageCurrent, setPageCurrent ] = useState(1);
  const [ pagedData, setPagedData ] = useState([]);
  const [ pagePerPageSize, setPageSize ] = useState(20);
  const [ pageTotal, setPageTotal ] = useState(0);
  const [ loading, setLoading ] = useState(true);
  const [ chunks, setChunks ] = useState([]);
  const [ title, setTitle ] = useState([]);
  const [ forumId, setForumId ] = useState('');

  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const history = useHistory();
  let xtitle ='';
  useEffect(() => {
    xtitle =
        query_type === "cluster-element"
          ? chunks[0] &&
            chunks[0].cluster_element &&
            formatTitle(chunks[0], classes)
          : 
            commentChunksPlaceholder
             + q;
    setTitle(xtitle);
  })

  useEffect(() => {
    (async () => {
      //  title =
      //   query_type === "cluster-element"
      //     ? chunks[0] &&
      //       chunks[0].cluster_element &&
      //       formatTitle(chunks[0], classes)
      //     : 
      //       commentChunksPlaceholder
      //        + q;

      const urlParams = new URLSearchParams(window.location.search);
      const client_id = urlParams.get('client_id');
      const forum_id = urlParams.get('forum_id');
      const startDate = urlParams.get('startDate');
      const endDate = urlParams.get('endDate');
      const comparisonType = urlParams.get('comparisonType');
      const subtopic = urlParams.get('subtopic') == 'true';

      const clientId = (client_id !== null) ? client_id : user.client_id;
      const forumId = (forum_id !== null) ? forum_id : forumUuid;

      const taxonomyA = urlParams.get('taxonomyA');
      const taxonomyB = urlParams.get('taxonomyB');
      const comparisonA = urlParams.get('comparisonA');
      const comparisonB = urlParams.get('comparisonB');

      const demographicTaxonomy = urlParams.get('taxonomy');
      const demographicValue = urlParams.get('value');

      let commentQueries = [];
      let emojiQuery = {};
      let entityQuery = {};
      let topicQuery = {};
      let argumentQuery = {};
      setForumId(forumId);
      if(demographicTaxonomy && demographicValue)
      {
        commentQueries.push({
          demographics: {
            taxonomy: demographicTaxonomy,
            value: demographicValue
          }
        });
      }
      if (comparisonType == 'demographic') {
        xtitle = xtitle + ' the demographics ' + taxonomyA + ':' + comparisonA + ' vs ' + taxonomyB + ':' + comparisonB;
        commentQueries.push({
          demographics: {
            taxonomy: taxonomyA,
            value: comparisonA
          }
        });
        commentQueries.push({
          demographics: {
            taxonomy: taxonomyB,
            value: comparisonB
          }
        });
      } else {
        console.log('query_type', query_type);
        console.log('query_type->q', q);
        if (query_type == 'topic') {
          topicQuery = { text: q };
        }
        if (query_type == 'entity') {
          entityQuery = {
            name: q
          };
        }
        if (query_type == 'emoji') {
          emojiQuery = {
            plutchik_category: q
          };          
        }
      }
      if (query_type == 'problem-solution') {
        const arg_type = q === 'Solutions'? 'Solution': 'Situation';
        const flag =  q === 'Solutions' ? 'NA':q === 'Positive Situations'? 'Pros': 'Cons';
        argumentQuery = {
          argument_type: arg_type,
          pro_con_flag: flag
        };
      }

      let commentQuery = {};
      if (commentQueries.length > 0) {
        commentQuery = {
          AND: commentQueries
        };
      }

      let comments = await dispatch(getChunksMultiFilter({
        startDate,
        endDate,
        subtopic,
        forum_id: forumId,
        client_id: clientId,
        commentQuery: commentQuery,
        emojiQuery: emojiQuery,
        entityQuery: entityQuery,
        topicQuery: topicQuery,
        argumentQuery: argumentQuery,
      }));
      if (comments && comments.payload) {
        comments = formatChunks(comments.payload);
        setChunks(comments);
      }
      setLoading(false);
      setTitle(xtitle);
    })();
  }, []);

  return (
    <Loading loading={loading}>
      <div className={classes.outerWrap}>
        <div className={classes.leftWrap}>
          <div className={classes.topSubNav}>
            <KeyboardArrowLeftOutlinedIcon />
            <Link to="#" onClick={() => history.goBack()}>
              <div className={classes.link}>
                <FormattedMessage
                  id="app.explorePage.backToExplore"
                  defaultMessage="Back to explore more"
                />
              </div>
            </Link>
          </div>

          <PageHeading
            style={{ fontSize: title.length > 40 ? "20px" : "38px" }}
          >
            {title}
          </PageHeading>

          <ChunksAndFilter client_id={ user.client_id } data={ chunks } title={title} forum_id={forumId} />

          <Modal
            title="Comment"
            visible={modalIsVisible}
            onOk={() => setModalIsVisible(false)}
            onCancel={() => setModalIsVisible(false)}
            okText={okPlaceholder}
            cancelText={cancelPlaceholder}
          >
            {modalBody}
          </Modal>
        </div>
      </div>
    </Loading>
  );
}

function flipBit(bit) {
  return bit === 0 ? 1 : 0;
}

function formatChunks(chunks) {
  // get rid of html tag like words
  let processedChunks = chunks.map((chunk) => ({
    ...chunk,
    text: chunk.text.replace(/<.*>/i, "")
  }))

  return processedChunks.map((c) => ({
    plutchik_category: c.emoji ? c.emoji.plutchik_category : c.plutchik_category,
    process_datetime: c.process_datetime,
    sentiment: c.sentiment === null ? DEFAULT_SENTIMENT : c.sentiment.sentiment ? c.sentiment.sentiment : c.sentiment,
    text: c.text,
    chunk_id: c.uid,
    comment: c.comment,
  }));
};

function formatTitle(chunk, classes) {
  return (
    <Fragment>
      <h3>{chunk.cluster_element.cluster_head.question.text}</h3>
      <p className={classes.subTitle}>{chunk.cluster_element.text}</p>
    </Fragment>
  )
}

function sortChunks(chunks, sortBy) {
  if(sortBy==='sentiment'){
    // in descending order
    return chunks.sort((a, b) =>  b[sortBy] - a[sortBy])
  }else{
    return chunks.sort((a, b) => {
      // in ascending order
      if (a[sortBy] < b[sortBy]) {
        return -1;
      }
      if (a[sortBy] > b[sortBy]) {
        return 1;
      }
      return 0;
    })
  }
}

function capitalizeFirstChar(q){
  return q.charAt(0).toUpperCase() + q.slice(1);
}


const Input = withStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.6)',
    // Use the system font instead of the default Roboto font
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.25,
    padding: '8px',
    position: 'relative',
    width: '100%',
    '&:focus': {
      borderColor: '#80bdff',
      borderRadius: '4px',
      boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  addTagArea: {
    display: 'inline-flex',
  },
  addTagButton: {
    backgroundColor: '#516e88',
    color: '#fff',
    display: 'inline-block',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    height: '32px',
    letterSpacing: '1.25px',
    lineHeight: 'normal',
    marginLeft: '4px',
    width: '100px',
  },
  addTagIcon: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    lineHeight: 1.31,
    marginLeft: '8px',
    textDecoration: 'underline',
  },
  addTagInput: {
    background: '#fff',
    border: '1px solid #efefef',
    borderRadius: '4px',
    boxSizing: 'border-box',
    color: 'rgba(0, 0, 0, 0.38)',
    display: 'inline-block',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '32px',
    letterSpacing: '0.25px',
    lineHeight: '16px',
    marginRight: '4px',
    opacity: 0.38,
    padding: '7px 8px',
    resize: 'none',
    WebkitBoxSizing: 'border-box',
    width: '180px',
  },
  badWrap: {
    alignItems: 'center',
    color: '#303f9f',
    display: 'inline-flex',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: 'normal',
    marginBottom: '18px',
  },
  checkbox: {
    color: '#8da4bc',
    padding: 0,
    '&$checked': {
      color: '#8da4bc',
    },
  },
  checked: {

  },
  favorWrap: {
    alignItems: 'center',
    color: '#7b1fa2',
    display: 'inline-flex',
    fontSize: '14.2px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: 'normal',
    marginBottom: '18px',
  },
  filterName: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '15.3px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.24px',
    lineHeight: 'normal',
    padding: '0px 17px 5px 17px',
  },
  firstOptionStyle: {
    marginLeft: '8px',
    width: '266px',
  },
  goodWrap: {
    alignItems: 'center',
    color: '#388e3c',
    display: 'inline-flex',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: 'normal',
    marginBottom: '18px',
  },
  greyWrap: {
    backgroundColor: '#b0bec5',
  },
  iconStyle: {
    marginRight: '6px',
  },
  leftWrap: {
    borderRight: 'non 1px #efefef',
    paddingBottom: '42px',
    width: '100%',
  },
  link: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  mainText: {
    width: '85%'
  },
  tagArea: {
    width: '15%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: '1em',
    alignItems: 'center',
    paddingLeft: '1em',
  },
  markWrap: {
    alignItems: 'center',
    color: '#516e88',
    marginBottom: '22px',
    marginLeft: '1em',
    opacity: 0.38,
  },
  menuWrap: {
    color: 'rgba(0, 0, 0, 0.38)',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    lineHeight: 'normal',
  },
  moodItem: {
    alignItems: 'center',
    borderRight: 'solid 1px #efefef',
    display: 'inline-flex',
    height: '40px',
    justifyContent: 'center',
    width: '46px',
  },
  moodList: {
    border: 'solid 1px #efefef',
    borderRadius: '6px',
    display: 'inline-flex',
    margin: '0 17px',
  },
  moodOption: {
    borderBottom: '1px solid #efefef',
    padding: '12px 14px',
  },
  moodSizeControl: {
    height: '28px',
    width: '28px',
  },
  moodSubOption: {
    borderBottom: '1px solid #efefef',
    padding: '12px 38px',
  },
  moodWrap: {
    color: '#00a4bd',
    fontSize: '14.2px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },
  optionText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 'normal',
  },
  outerWrap: {
    display: 'flex',
    width: '100%',
  },
  rightWrap: {
    width: '25%',
  },
  sectionLeft: {
    marginRight: '16px',
    minWidth: '60px',
    width: '4%',
  },
  sectionRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '96%'
  },
  sectionWrap: {
    borderBottom: 'solid 1px #efefef',
    display: 'flex',
    marginRight: '2em',
    padding: '24px 0',
  },
  signStyle: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    lineHeight: 1.31,
    marginBottom: '12px',
  },
  sizeControl: {
    height: '24px',
    width: '24px',
  },
  subSectionName: {
    color: '#516e88',
    display: 'block',
    fontSize: '20px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: 'auto',
    letterSpacing: 'normal',
    marginBottom: '1em',
    marginTop: '1em',
    lineHeight: '16px'
  },
  subOptionTitle: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '16.3px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.5px',
    lineHeight: 'normal',
    padding: '24px 17px 15px 17px',
  },
  tagIcon: {
    color: '#90a4ae',
  },
  tagText: {
    color: '#fff',
    fontSize: '9px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0.25px',
  },
  emojiWrap: {
    borderRadius: '65px',
    marginRight: '4%',
    marginLeft: '4%',
    paddingTop: '17px',
    paddingBottom: '17px',
    height: '65px',
    textAlign: 'center',
    width: '65px',
  },
  sentimentWrap: {
    marginRight: '4%',
    marginLeft: '4%',
    paddingTop: '12px',
    paddingBottom: '12px',
    height: '3.5em',
    textAlign: 'center',
    width: '30%'
  },
  textStyle: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    lineHeight: 1.41,
    marginBottom: '12px',
  },
  titleWrap: {
    display: 'inline-block',
    width: '100%',
  },
  topSubNav: {
    color: 'rgba(0, 0, 0, 0.38)',
    display: 'flex',
    fontSize: '12.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '24px',
    letterSpacing: '0.4px',
    lineHeight: '24px',
    marginTop: '10px',
    width: '100%',
  },
  bookMarkStyle: {
    color: 'rgba(0, 0, 0, 0.38)'
  },
  checkboxWrap: {
    margin: '2em 0.5em 0em 0em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  saveReportWrap: {
    margin: '2em 2em 0em 2em',
    alignItems: 'center',
  },
  sortWrap: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2em auto 0em 1em',
  },
  filterWrap: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0em 6em 0em auto',
  },
  subTitle: {
    fontSize: '18px'
  }
}));

export default ChunksView;