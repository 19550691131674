import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';

function SimpleSelector({
  conversationOptions,
  selectedconversationValue,
  selectedTypeValue,
  handleOptionChange
}) {

  const classes = useStyles();

  return (
    <form className={classes.root} autoComplete="off">
      <FormControl className={classes.margin}>
        <InputLabel shrink htmlFor="first-option" className={classes.bootstrapFormLabel}>
          In the data from...
        </InputLabel>
        <Select
          value={selectedconversationValue}
          onChange={handleOptionChange('conversation')}
          input={<BootstrapInput name="firstOption" id="first-option" className={classes.firstOptionStyle} />}
        >
          <MenuItem key={0} value={0}>all conversations</MenuItem>
          {conversationOptions.map((conv, i) =>
            <MenuItem key={i + 1} value={i + 1}>{conv.title}</MenuItem>
          )}
        </Select>
      </FormControl>
      <FormControl className={classes.margin}>
        <InputLabel shrink htmlFor="second-option" className={classes.bootstrapFormLabel}>
          I want to find out...
        </InputLabel>
        <Select
          value={selectedTypeValue}
          onChange={handleOptionChange('type')}
          input={<BootstrapInput name="secondOption" id="second-option" className={classes.secondOptionStyle} />}
        >
          <MenuItem value={0}>Whether particular segments were more engaged than others</MenuItem>
          <MenuItem value={1}>Where the participants were located</MenuItem>
        </Select>
      </FormControl>
    </form>
  );
}

const BootstrapInput = withStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.6)',
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.25,
    padding: '8px',
    position: 'relative',
    width: '200px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#80bdff',
      borderRadius: '4px',
      boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, .25)',
    },
  },
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  bootstrapFormLabel: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14.2px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0.4,
  },
  firstOptionStyle: {
    width: '255px',
  },
  margin: {
    margin: theme.spacing(0.5),
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: 100,
    padding: '16px 20px',
  },
  secondOptionStyle: {
    width: '466px',
  },
}));

export default SimpleSelector;