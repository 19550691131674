import { UPDATE_DATE_RANGE } from "../actions/date_range";

const initialState = {
  data: {
    startDate: null,
    endDate: null,
  },
};

export function date_range(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATE_RANGE:
      const { startDate, endDate } = action.payload;
      return { data: { startDate, endDate }, };

    default:
      return state;
  }
}