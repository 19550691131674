import axios from '../http';

export function fetchTableData({ dashboardId, forumId, startDate, endDate, tableSpec }) {
  return async () => {
    try {
      const res = await axios.post('/api/v1/dashboard/charts/table/', {
        dashboard_id: dashboardId,
        forum_id: forumId,
        start_date: startDate,
        end_date: endDate,
        tableSpec: tableSpec
      });
      if (res.status !== 200) {
        throw Error(res.statusText);
      }
      return res.data.data ? res.data.data : res.data;
    } catch (error) {
      console.error(error);
    }
  }
};
